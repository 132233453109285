import React from 'react'
import { useDropzone } from 'react-dropzone'
import { DocumentDuplicateIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from 'helpers'

import styles from './Dropzone.module.scss'

const Dropzone = ({
    acceptFormats = 'image/jpeg,image/png',
    disableClick,
    disabled,
    isMultiple,
    hasError,
    className,
    handleUpload,
}: React.PropsWithChildren<{
    acceptFormats: string
    className?: string
    disableClick?: boolean
    hasError?: boolean
    isMultiple: boolean
    disabled?: boolean
    handleUpload: () => {}
}>) => {
    const cx = getCxFromStyles(styles)

    const { getRootProps, isDragActive, isDragAccept, isDragReject } =
        useDropzone({
            accept: acceptFormats,
            disabled,
            multiple: isMultiple,
            noClick: disableClick,
            maxSize: 500 * 1024 * 1024,
            onDrop: handleUpload,
        })

    return (
        <div
            {...getRootProps()}
            className={cx(
                'dropzone',
                {
                    isDragActive: isDragActive,
                    isDragAccept: isDragAccept,
                    isDragReject: isDragReject,
                    error: hasError,
                },
                className
            )}
        >
            <div className="self-center space-y-1 text-center">
                <DocumentDuplicateIcon
                    strokeWidth={1}
                    className="mx-auto w-12 text-gray-800"
                />
                <div className="flex text-sm text-gray-600">
                    <span className="font-medium text-blue-600">
                        Prześlij dokument
                    </span>
                    <p className="pl-1">bądź przeciągnij tutaj</p>
                </div>
                <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 10MB
                </p>
            </div>
        </div>
    )
}

export default Dropzone
