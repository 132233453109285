import React from 'react'
import { useFormikContext } from 'formik'

import { InputText, Label } from 'components/ui'

import type { SurgeryForm } from 'types/SurgeryForm'
import { phoneWithoutPolandPrefix } from '../helpers'

const DoctorSurgeryFormAddress: React.FC<{}> = () => {
    const { values } = useFormikContext<SurgeryForm>()

    if (!values.patient) {
        return null
    }

    return (
        <div>
            <div className="mt-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {!!values.patient.pesel ? (
                        <div className="col-span-1">
                            <Label>PESEL</Label>
                            <InputText
                                name="patient_pesel"
                                value={values.patient.pesel}
                                disabled={true}
                            />
                        </div>
                    ) : !!values.patient.identity_card ? (
                        <div className="col-span-1">
                            <Label>Nr dowodu / paszportu</Label>
                            <InputText
                                name="patient_pesel"
                                value={values.patient.identity_card}
                                disabled={true}
                            />
                        </div>
                    ) : null}
                    <div className="col-span-1">
                        <Label>Telefon</Label>
                        <InputText
                            name="patient_phone"
                            value={phoneWithoutPolandPrefix(
                                values.patient.phone
                            )}
                            disabled={true}
                        />
                        {values.patient.phone_note && (
                            <div className="text-sm leading-tight font-normal text-gray-400 truncate">
                                {values.patient.phone_note}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-6 space-y-1">
                <div className="flex space-x-3 items-center justify-between">
                    <Label>Adres</Label>
                </div>

                <InputText
                    name="address"
                    value={values.patient.address || ''}
                    disabled
                />
            </div>
            <div className="mt-2 grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2">
                    <InputText
                        name="post_code"
                        value={values.patient.post_code || ''}
                        disabled
                    />
                </div>
                <div className="col-span-10">
                    <InputText
                        name="city"
                        value={values.patient.city || ''}
                        disabled
                    />
                </div>
            </div>
        </div>
    )
}

export default DoctorSurgeryFormAddress
