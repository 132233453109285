import {
    usePolicy,
    useProcedurePolicy,
    useProcedureTypePolicy,
    usePayerPolicy,
    useProcedureCategoryPolicy,
} from 'hooks'

export const useProcedureCostPolicyGroup = () => {
    const { user } = usePolicy()
    const procedurePolicy = useProcedurePolicy()
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const procedureTypePolicy = useProcedureTypePolicy()
    const payerPolicy = usePayerPolicy()

    return {
        user,
        canAll:
            procedurePolicy.canIndex &&
            procedurePolicy.canUpdate &&
            procedureTypePolicy.canIndex &&
            procedureCategoryPolicy.canIndex &&
            payerPolicy.canIndex,
    }
}
