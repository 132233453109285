import {
    usePolicy,
    useOperatingRoomPolicy,
    useSchedulePolicy,
    useAnesthesiologistSchedulePolicy,
    useProcedureTypePolicy,
    useScheduleSMSPolicy,
} from 'hooks'

export const useScheduleApprovedPolicyGroup = () => {
    const { user } = usePolicy()
    const operatingRoomPolicy = useOperatingRoomPolicy()
    const anesthesiologistSchedulePolicy = useAnesthesiologistSchedulePolicy()
    const procedureTypePolicy = useProcedureTypePolicy()
    const schedulePolicy = useSchedulePolicy()
    const scheduleSMSPolicy = useScheduleSMSPolicy()

    return {
        user,
        get canIndex() {
            return (
                operatingRoomPolicy.canIndex &&
                schedulePolicy.canIndex &&
                procedureTypePolicy.canIndex
            )
        },
        get canShow() {
            return this.canIndex && schedulePolicy.canShow
        },
        operatingRoom: operatingRoomPolicy,
        anesthesiologistSchedule: anesthesiologistSchedulePolicy,
        schedule: schedulePolicy,
        scheduleSMS: scheduleSMSPolicy,
        procedureType: procedureTypePolicy,
    }
}
