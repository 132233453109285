import React from 'react'
import { useQueryClient } from 'react-query'

import { Button } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import SettingsUser from 'components/SettingsUser'

import type { ResponseData, UserResponse } from 'api/types'

const ProfileContainer = () => {
    const queryClient = useQueryClient()
    const user = queryClient.getQueryData<ResponseData<UserResponse>>('user')

    return (
        <ListLayout
            title="Profil"
            actions={
                <>
                    <Button
                        as="link"
                        to="/profile/edit"
                        variant="tertiary"
                        size="md"
                        className="ml-2"
                    >
                        Edytuj
                    </Button>
                </>
            }
        >
            <SettingsUser adminView={false} user={user!.data} />
        </ListLayout>
    )
}

export default ProfileContainer
