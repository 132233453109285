import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import { ResponseList, ResponseError, ActivityLogEntry } from 'api/types'

import type { ListFilters } from 'helpers'

export const useActivityLogs = <
    F extends ListFilters,
    R = ResponseList<ActivityLogEntry[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['activity-log', filters],
        () =>
            client.get(
                '/activity-logs' + (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )
