import React, { useEffect, useCallback, useContext, useRef } from 'react'
import { UseQueryResult, UseInfiniteQueryResult } from 'react-query'
import moment from 'moment'
import classNames from 'classnames'
import { XIcon, ShieldExclamationIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'

import {
    API_FORMAT_DATE,
    ABBR_NAMES_DAYS_OF_WEEK,
    DATEPICKER_DEFAULT_FROM_YEAR,
    DATEPICKER_DEFAULT_TO_YEAR,
    STATUSES_ALLOWED_TO_EDIT_OPERATION_IN_CAREGIVER_PANEL,
} from 'constants/index'
import { DatePicker, Loader } from 'components/ui'
import { useCaregiverPanelPolicy } from 'hooks'
import mediaContext from 'contexts/media/mediaContext'
import OperationCard from 'containers/OperationCard'
import OperationListItem from 'components/OperationListItem'

import type { Moment } from 'moment'
import type {
    OperationListItem as IOperationListItem,
    OperationTaskFilters,
} from 'types'
import type {
    OperationResponse,
    ResponseList,
    ResponseData,
    ResponseError,
    Pagination,
} from 'api/types'

import { ReactComponent as CaregiverEmptyList } from 'assets/caregiver-empty-list.svg'

const CaregiverPanel: React.FC<{
    operation: UseQueryResult<ResponseData<OperationResponse>, ResponseError>
    operations: UseInfiniteQueryResult<
        ResponseList<
            IOperationListItem[],
            Pagination & { expired_operations: boolean }
        >,
        ResponseError
    >
    filters: OperationTaskFilters
    isCustomDate: boolean
    handleFilterDayChange: (date?: string) => void
    handleFilterExpiredTasksChange: () => void
    currentOperationId?: number
    currentOperationIndex?: number
    onOperationListItemSelect: (index: number) => void
    setCurrentOperationIndex: React.Dispatch<
        React.SetStateAction<number | undefined>
    >
    onOperationCardClose: () => void
}> = ({
    operation,
    operations,
    handleFilterDayChange,
    handleFilterExpiredTasksChange,
    currentOperationId,
    currentOperationIndex,
    setCurrentOperationIndex,
    filters,
    isCustomDate,
    onOperationListItemSelect,
    onOperationCardClose,
}) => {
    const caregiverPanelPolicy = useCaregiverPanelPolicy()

    const observerRef = useRef(null)
    const { isDesktop } = useContext(mediaContext)

    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                operations.hasNextPage &&
                !operations.isFetchingNextPage
            ) {
                operations.fetchNextPage()
            }
        },
        [operations]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }
        const element = observerRef.current
        const option = { threshold: 0 }
        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    return (
        <div className="flex-1 relative z-0 flex">
            <div
                className={classNames(
                    'relative xl:pr-3 flex flex-col flex-shrink-0 w-full xl:w-96',
                    {
                        hidden: !isDesktop && !!currentOperationId,
                    }
                )}
            >
                <nav className="relative z-20">
                    <DayPicker
                        filters={filters}
                        isCustomDate={isCustomDate}
                        meta={{
                            expired_operations:
                                !!operations.data?.pages[0].meta
                                    .expired_operations,
                        }}
                        handleFilterDayChange={handleFilterDayChange}
                        handleFilterExpiredTasksChange={
                            handleFilterExpiredTasksChange
                        }
                    />
                </nav>
                <div className="relative flex flex-col flex-1 overflow-y-auto">
                    <div className="flex-1 relative">
                        {operations.isFetching && (
                            <div className="h-full">
                                <Loader />
                            </div>
                        )}
                        {operations.isSuccess && operations.isFetched && (
                            <div className="absolute inset-0">
                                <ul className="relative z-0">
                                    {operations.data.pages.map(
                                        (page, pageIndex) =>
                                            page.data.map((item, index) => (
                                                <li
                                                    className="mb-1"
                                                    key={
                                                        pageIndex *
                                                            filters.length +
                                                        index
                                                    }
                                                >
                                                    <OperationListItem
                                                        item={item}
                                                        handleClick={() =>
                                                            onOperationListItemSelect(
                                                                pageIndex *
                                                                    filters.length +
                                                                    index
                                                            )
                                                        }
                                                        expired={
                                                            filters.expired_tasks
                                                        }
                                                        active={
                                                            currentOperationIndex ===
                                                            pageIndex *
                                                                filters.length +
                                                                index
                                                        }
                                                    />
                                                </li>
                                            ))
                                    )}
                                    {operations.hasNextPage && (
                                        <li
                                            className="loader"
                                            ref={observerRef}
                                        >
                                            <Loader size="sm" />
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                        {operations.isSuccess &&
                            !operations.isFetching &&
                            operations.data.pages[0].meta.total === 0 && (
                                <>
                                    <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
                                        <p className="text-lg leading-6 font-semibold text-gray-900">
                                            Brak zadań
                                        </p>
                                        <p className="text-sm leading-5 font-normal text-gray-500">
                                            Nie ma zadań lub wszystkie zostały
                                            wykonane
                                        </p>
                                    </div>
                                    <CaregiverEmptyList className="w-2/3 md:w-1/2 xl:w-1/2 h-auto absolute right-0 bottom-0 z-0" />
                                </>
                            )}
                    </div>
                </div>
            </div>
            <div
                className={classNames('flex-1 flex flex-col xl:ml-1', {
                    hidden: !currentOperationId,
                })}
            >
                <div className="relative flex-1">
                    {!!currentOperationId && (
                        <>
                            {operation.isLoading && (
                                <div className="h-full p-4 rounded-md shadow-lg bg-white">
                                    <Loader />
                                </div>
                            )}
                            {operation.isSuccess && (
                                <div className="mb-20 xl:mb-0">
                                    <div className="p-4 pt-2 shadow-lg rounded-md bg-white xl:absolute xl:inset-0 xl:overflow-y-scroll">
                                        <OperationCard
                                            id={Number(currentOperationId)}
                                            onTaskChange={() => {}}
                                            isEditButton={
                                                caregiverPanelPolicy.surgery
                                                    .canUpdate &&
                                                STATUSES_ALLOWED_TO_EDIT_OPERATION_IN_CAREGIVER_PANEL.includes(
                                                    operation.data.data.status
                                                )
                                            }
                                            showComments={
                                                caregiverPanelPolicy.comment
                                                    .canIndex
                                            }
                                            showSMSes={
                                                process.env
                                                    .REACT_APP_FEATURE_FLAG_SHOW_SMSES ===
                                                'true'
                                            }
                                            onClose={onOperationCardClose}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

const DayPicker: React.FC<{
    filters: OperationTaskFilters
    isCustomDate: boolean
    meta: { expired_operations: boolean }
    handleFilterDayChange: (date?: string) => void
    handleFilterExpiredTasksChange: () => void
}> = ({
    filters,
    isCustomDate,
    meta,
    handleFilterDayChange,
    handleFilterExpiredTasksChange,
}) => {
    const { isMobile, isTablet, isDesktop } = useContext(mediaContext)

    return (
        <div
            className="-mt-3 bg-gray-100 "
            style={{
                boxShadow: '0 5px 5px -6px #999',
            }}
        >
            <div
                className="relative h-24 overflow-auto xl:overflow-visible no-scrollbar"
                style={{ scrollbarWidth: 'none' }}
            >
                <ul
                    className="absolute pt-3 right-0 left-0 md:initial grid gap-2 grid-cols-7 "
                    style={{
                        minWidth: isMobile || isTablet ? '440px' : 'none',
                        height: '76px',
                    }}
                >
                    <li className="relative col-span-1 h-full">
                        <ExpiredTaskFilter
                            hasItems={meta.expired_operations}
                            current={!!filters.expired_tasks}
                            handleClick={handleFilterExpiredTasksChange}
                        />
                    </li>
                    <li className="relative col-span-1 h-full">
                        <Day
                            offset={0}
                            current={
                                filters.from
                                    ? moment(filters.from).isSame(
                                          moment().add(0, 'days'),
                                          'day'
                                      )
                                    : false
                            }
                            handleClick={handleFilterDayChange}
                        />
                    </li>
                    <li className="relative col-span-1  h-full">
                        <Day
                            offset={1}
                            current={moment(filters.from).isSame(
                                moment().add(1, 'days'),
                                'day'
                            )}
                            handleClick={handleFilterDayChange}
                        />
                    </li>
                    <li className="relative col-span-1 h-full">
                        <Day
                            offset={2}
                            current={moment(filters.from).isSame(
                                moment().add(2, 'days'),
                                'day'
                            )}
                            handleClick={handleFilterDayChange}
                        />
                    </li>
                    <li className="relative col-span-1  h-full">
                        <Day
                            offset={3}
                            current={moment(filters.from).isSame(
                                moment().add(3, 'days'),
                                'day'
                            )}
                            handleClick={handleFilterDayChange}
                        />
                    </li>
                    <li className="relative col-span-1 h-full">
                        <Day
                            offset={4}
                            current={moment(filters.from).isSame(
                                moment().add(4, 'days'),
                                'day'
                            )}
                            handleClick={handleFilterDayChange}
                        />
                    </li>
                    <li className="relative col-span-1 h-full">
                        {isDesktop ? (
                            <div className="h-full max-w-full">
                                <DatePicker
                                    value={
                                        isCustomDate
                                            ? moment(filters.from).toDate()
                                            : undefined
                                    }
                                    captionLayout="dropdown-buttons"
                                    fromYear={DATEPICKER_DEFAULT_FROM_YEAR}
                                    toYear={DATEPICKER_DEFAULT_TO_YEAR}
                                    stylesPopper={{
                                        width: 'max-content',
                                        left: '50%',
                                        transform: 'translate(-50%, 70px)',
                                    }}
                                    onChange={(date) => {
                                        !!date &&
                                            handleFilterDayChange(
                                                moment(date).format(
                                                    API_FORMAT_DATE
                                                )
                                            )
                                    }}
                                    renderSelector={({
                                        ref,
                                        isOpen,
                                        open,
                                        close,
                                    }) => {
                                        return (
                                            <div
                                                ref={ref}
                                                className="h-full"
                                                onClick={!isOpen ? open : close}
                                            >
                                                <CustomDay
                                                    focus={isOpen}
                                                    date={
                                                        isCustomDate
                                                            ? moment(
                                                                  filters.from
                                                              )
                                                            : undefined
                                                    }
                                                    onReset={() =>
                                                        handleFilterDayChange(
                                                            undefined
                                                        )
                                                    }
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        ) : (
                            <Day
                                offset={5}
                                current={moment(filters.from).isSame(
                                    moment().add(5, 'days'),
                                    'day'
                                )}
                                handleClick={handleFilterDayChange}
                            />
                        )}
                    </li>
                </ul>
            </div>
        </div>
    )
}

const ExpiredTaskFilter: React.FC<{
    hasItems: boolean
    current: boolean
    handleClick: () => void
}> = ({ hasItems, current, handleClick }) => {
    return (
        <div
            className={classNames(
                'flex flex-col items-center justify-center text-xs shadow-sm px-2 rounded bg-white border text-white h-full cursor-pointer',
                {
                    'border-red-700 bg-red-500': hasItems,
                    'text-gray-400': !hasItems,
                }
            )}
            onClick={() => handleClick()}
        >
            {!!current && (
                <div className="absolute z-10 -inset-1">
                    <div className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center text-white rounded-full bg-indigo-800">
                        <XIcon className="w-3.5" />
                    </div>
                </div>
            )}
            <span className="mb-2">Pilne</span>
            <span
                className={classNames(
                    'inline-flex items-center justify-center px-1.5 rounded text-xs w-full xl:w-auto',
                    {
                        'bg-white text-red-500': hasItems,
                        'text-gray-400': !hasItems,
                    }
                )}
            >
                <ShieldExclamationIcon className="h-4" />
            </span>
        </div>
    )
}

const Day: React.FC<{
    offset: number
    current?: boolean
    handleClick: (date?: string) => void
}> = ({ offset, current, handleClick }) => {
    const day = moment().add(offset, 'days')

    return (
        <div
            className="flex flex-col items-center justify-center text-xs shadow-sm px-2 rounded bg-white border border-gray-200 text-gray-400 h-full cursor-pointer"
            onClick={() =>
                handleClick(
                    !current
                        ? moment().add(offset, 'days').format(API_FORMAT_DATE)
                        : undefined
                )
            }
        >
            {!!current && (
                <div className="absolute z-10 -inset-1 rounded border-2 border-indigo-500">
                    <div className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center text-white rounded-full bg-indigo-800">
                        <XIcon className="w-3.5" />
                    </div>
                </div>
            )}
            <span className="mb-2">
                {ABBR_NAMES_DAYS_OF_WEEK[Number(day.format('d'))]}
            </span>
            <span className="inline-flex items-center justify-center px-2.5 w-full xl:w-auto rounded text-xs bg-gray-100">
                {day.format('D')}
            </span>
        </div>
    )
}

const CustomDay: React.FC<{
    focus: boolean
    date: Moment | undefined
    onReset: () => void
}> = ({ focus, date, onReset }) => {
    const isActive = !!date

    return (
        <div
            className={classNames(
                'flex flex-col items-center justify-center text-xs shadow-sm px-2 rounded bg-white border border-gray-200 h-full cursor-pointer',
                {
                    'text-gray-400': !focus || isActive,
                    'text-gray-700': focus && !isActive,
                }
            )}
        >
            {!!isActive && (
                <div className="absolute z-10 -inset-1 rounded border-2 border-indigo-500">
                    <div
                        className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center text-white rounded-full bg-indigo-800"
                        onClick={(e) => {
                            e.stopPropagation()
                            onReset()
                        }}
                    >
                        <XIcon className="w-3.5" />
                    </div>
                </div>
            )}
            <span className="mb-2">Data</span>
            <span className="inline-flex items-center justify-center w-full xl:w-auto rounded text-xs bg-gray-100">
                {isActive ? (
                    <span className="px-1 text-gray-700">
                        {date.format('DD.MM')}
                    </span>
                ) : (
                    <span className="px-2.5">
                        <CalendarIcon className="w-4 h-auto" />
                    </span>
                )}
            </span>
        </div>
    )
}

export default CaregiverPanel
