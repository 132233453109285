import React from 'react'

import { OPERATION_STATUS_REJECTED } from 'constants/index'
import Menu3dots from 'components/Menu3dots'

import type { OperationResponse } from 'api/types'

const Menu3dotsRejectOrRenew: React.FC<{
    item: Pick<OperationResponse, 'id' | 'status'>
    canReject: boolean
    canRenew: boolean
    onReject: () => void
    onRenew: () => void
}> = ({ item, canReject, canRenew, onReject, onRenew }) => {
    return (
        <>
            {item.status !== OPERATION_STATUS_REJECTED && (
                <Menu3dots.Item
                    variant="custom"
                    onClick={onReject}
                    disabled={!canReject}
                >
                    Zrzuć operację
                </Menu3dots.Item>
            )}
            {item.status === OPERATION_STATUS_REJECTED && (
                <Menu3dots.Item
                    variant="custom"
                    onClick={onRenew}
                    disabled={!canRenew}
                >
                    Przywróć operację
                </Menu3dots.Item>
            )}
        </>
    )
}

export default Menu3dotsRejectOrRenew
