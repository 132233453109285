import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const IMPLANT_MANUFACTURE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_IMPLANT_MANUFACTURERS,
    show: PERMISSIONS.SHOW_IMPLANT_MANUFACTURERS,
    create: PERMISSIONS.CREATE_IMPLANT_MANUFACTURERS,
    update: PERMISSIONS.UPDATE_IMPLANT_MANUFACTURERS,
    delete: PERMISSIONS.DELETE_IMPLANT_MANUFACTURERS,
}

export const useImplantManufacturePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(IMPLANT_MANUFACTURE_PERMISSIONS),
    }
}
