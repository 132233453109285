import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { formatDate } from 'helpers'
import UserAvatar from 'components/UserAvatar'
import {
    Item,
    BorderedItem,
    ItemIcon,
    ItemValue,
    ItemRow,
} from 'components/StatisticsDashboard'

import { ZnieczulanieIcon } from 'assets/icons'

import type { UseInfiniteQueryResult } from 'react-query'
import type {
    ResponseError,
    StatisticsAnesthesiologistsResponse,
} from 'api/types'

const StatisticsAnesthesiologists: React.FC<{
    queryResult: UseInfiniteQueryResult<
        StatisticsAnesthesiologistsResponse,
        ResponseError
    >
}> = (props) => {
    const navigate = useNavigate()
    const { queryResult } = props
    const observerRef = useRef(null)
    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                queryResult.hasNextPage &&
                !queryResult.isFetchingNextPage
            ) {
                queryResult.fetchNextPage()
            }
        },
        [queryResult]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }
        const element = observerRef.current
        const option = { threshold: 0 }
        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    const items = useMemo(
        () => queryResult.data?.pages.flatMap((page) => page.data) || [],
        [queryResult.data]
    )

    const itemsNotFound =
        queryResult.isSuccess && queryResult.data.pages.at(0)?.meta.total === 0

    const statisticsData = useMemo(
        () => queryResult.data?.pages[0]?.averages,
        [queryResult.data]
    )

    return (
        <div className="space-y-4">
            <div className="p-5 rounded-md bg-white grid grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-4">
                <BorderedItem>
                    <Item
                        title="Średni czas oczekiwania na znieczulenie"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZnieczulanieIcon className="w-7 h-7 text-yellow-800 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-yellow-500"
                                bgInner="bg-white"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_waiting_for_anesthesia_duration ||
                                        '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
                <BorderedItem>
                    <Item
                        title="Średni czas znieczulania"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZnieczulanieIcon className="w-7 h-7 text-yellow-800 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-yellow-500"
                                bgInner="bg-white"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_anesthesia_procedure_duration ||
                                        '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
                <BorderedItem>
                    <Item
                        title="Średni czas wybudzania"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZnieczulanieIcon className="w-7 h-7 text-yellow-800 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-yellow-500"
                                bgInner="bg-white"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_anesthesia_awakening_duration ||
                                        '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
                <BorderedItem>
                    <Item
                        title="Średni czas całkowity"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZnieczulanieIcon className="w-7 h-7 text-yellow-800 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-yellow-500"
                                bgInner="bg-white"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_total_anesthesia_duration ||
                                        '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
            </div>

            <div className="p-2 rounded-md bg-white">
                <table>
                    <thead className="bg-gray-50 border-b border-b-gray-200 border-md text-gray-500 uppercase h-10 sticky top-16 z-10">
                        <tr className="h-10">
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Data
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Anestezjolog</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                            >
                                <span>Rodzaj zabiegu</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                            >
                                <span>Zabieg</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Czas oczek.</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Czas zniecz.</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Czas wybudz.</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Czas całkowity</span>
                            </th>
                        </tr>
                    </thead>
                    {queryResult.isSuccess && queryResult.isFetched && (
                        <tbody className="text-sm bg-white divide-y divide-gray-200 leading-5">
                            {items.map((item, index) => (
                                <tr
                                    key={`${index}-${
                                        item.anesthesiologist?.id || 0
                                    }-${item.procedure_type.id}-${
                                        item.procedure
                                    }`}
                                    className={classNames(
                                        'h-16 hover:bg-blue-50 cursor-pointer',
                                        {
                                            'bg-gray-50': index % 2,
                                        }
                                    )}
                                    onClick={() =>
                                        navigate(
                                            `/statistics/surgeries/${item.id}`,
                                            {
                                                state: {
                                                    backUrl:
                                                        '/statistics/anesthesiologists',
                                                },
                                            }
                                        )
                                    }
                                >
                                    <td className="px-6 py-1">
                                        <span className="text-gray-500">
                                            {formatDate(item.estimated_date)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-1 whitespace-nowrap">
                                        {!!item.anesthesiologist && (
                                            <UserAvatar
                                                variant="name"
                                                data={{
                                                    first_name:
                                                        item.anesthesiologist
                                                            .first_name,
                                                    last_name:
                                                        item.anesthesiologist
                                                            .last_name,
                                                    avatar_url:
                                                        item.anesthesiologist
                                                            .avatar_url,
                                                }}
                                                size={8}
                                            />
                                        )}
                                    </td>
                                    <td className="px-6 py-1">
                                        <span>{item.procedure_type.name}</span>
                                    </td>
                                    <td className="px-6 py-1">
                                        <span>{item.procedure.name}</span>
                                    </td>
                                    <td className="px-6 py-1">
                                        <span className="whitespace-nowrap">
                                            {
                                                item.waiting_for_anesthesia_duration
                                            }
                                        </span>
                                    </td>
                                    <td className="px-6 py-1">
                                        <span className="whitespace-nowrap">
                                            {item.anesthesia_procedure_duration}
                                        </span>
                                    </td>
                                    <td className="px-6 py-1">
                                        <span className="whitespace-nowrap">
                                            {item.anesthesia_awakening_duration}
                                        </span>
                                    </td>
                                    <td className="px-6 py-1">
                                        <span className="whitespace-nowrap">
                                            {item.total_anesthesia_duration}
                                        </span>
                                    </td>
                                </tr>
                            ))}

                            {!itemsNotFound && queryResult.hasNextPage && (
                                <tr ref={observerRef}>
                                    <td colSpan={10}>
                                        <div className="flex items-center justify-center h-20">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="animate-spin text-gray-600"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 48 48"
                                            >
                                                <path
                                                    fillOpacity="0.3"
                                                    d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                                                />
                                                <path d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>

                {itemsNotFound && (
                    <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                        <div className="relative h-16" />
                        <div className="relative h-16">
                            <div className="absolute inset-0 h-full flex justify-center items-center">
                                <span>Brak wyników</span>
                            </div>
                            <span>&nbsp;</span>
                        </div>
                        <div className="relative h-16" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StatisticsAnesthesiologists
