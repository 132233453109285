import React, { useEffect, useMemo, useReducer, useState } from 'react'
import omit from 'lodash.omit'

import { useGetOperations } from 'api'
import { useDoctorSurgeryDraftPolicyGroup } from 'hooks'
import {
    ListFilterAction,
    ListFilters,
    listReducer,
    parseDateToAPI,
} from 'helpers'
import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { AccessDenied } from 'components'
import { FilterButton } from 'components/ui'
import DoctorSurgeryDraftList from 'components/DoctorSurgeryDraftList'
import Filters from 'components/DoctorSurgeryDraftListFilters'
import ListLayout from 'layouts/ListLayout'

import type { DropdownItem } from 'components/forms'

export type DoctorSurgeryDraftListFilterForm = {
    doctor?: DropdownItem
    dateRange: {
        from: Date | undefined
        to: Date | undefined
    }
}

export type DoctorSurgeryDraftListFilterState = ListFilters & {
    draft: boolean
} & {
    form: DoctorSurgeryDraftListFilterForm
}

export type DoctorSurgeryDraftListFilterAction =
    | ListFilterAction
    | {
          type: 'changeFilters'
          payload: DoctorSurgeryDraftListFilterForm
      }
    | {
          type: 'resetFilters'
          payload?: never
      }

const initialFilters = {
    draft: true,
    sort_by: 'id',
    sort_direction: 'desc',
    length: DEFAULT_LIST_PER_PAGE,
    page: 1,
    form: {
        doctor: undefined,
        dateRange: {
            from: undefined,
            to: undefined,
        },
    },
} as DoctorSurgeryDraftListFilterState

function filterReducer(
    state: DoctorSurgeryDraftListFilterState,
    action: DoctorSurgeryDraftListFilterAction
) {
    switch (action.type) {
        case 'changeFilters':
            return {
                ...state,
                form: action.payload,
            }

        case 'resetFilters':
            return {
                ...state,
                page: 1,
                form: {
                    doctor: undefined,
                    dateRange: {
                        from: undefined,
                        to: undefined,
                    },
                },
            }

        default:
            return listReducer(
                state,
                action
            ) as DoctorSurgeryDraftListFilterState
    }
}

const transformFilterStateToAPI = (
    filters: DoctorSurgeryDraftListFilterState
) => {
    return {
        ...omit(filters, 'form'),
        doctor_id: filters.form.doctor?.id,
        created_from: parseDateToAPI(filters.form.dateRange.from),
        created_to: parseDateToAPI(filters.form.dateRange.to),
    }
}

const DoctorSurgeryDraftListContainer = () => {
    const surgeryDraftPolicyGroup = useDoctorSurgeryDraftPolicyGroup()

    const [filters, dispatchFilterAction] = useReducer(
        filterReducer,
        initialFilters
    )

    const apiFilters = useMemo(() => {
        return transformFilterStateToAPI(filters)
    }, [filters])

    const filtersCount = useMemo(
        () =>
            (filters.form.doctor ? 1 : 0) +
            (filters.form.dateRange.from && filters.form.dateRange.to ? 1 : 0),
        [filters]
    )

    const operationsQueryResult = useGetOperations(apiFilters, {
        enabled: surgeryDraftPolicyGroup.canIndex,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [filters.page])

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)

    if (!surgeryDraftPolicyGroup.canIndex) {
        return (
            <AccessDenied message="Nie masz uprawnień do wyświetlania listy operacji" />
        )
    }

    return (
        <ListLayout
            title="Operacje spoza cennika"
            actions={
                <span>
                    <FilterButton
                        count={filtersCount}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                        filtersExpanded={filtersExpanded}
                        handleReset={() =>
                            dispatchFilterAction({
                                type: 'resetFilters',
                            })
                        }
                    />
                </span>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters
                        values={filters.form}
                        dispatchAction={dispatchFilterAction}
                    />
                )}
            </>
            <DoctorSurgeryDraftList
                queryResult={operationsQueryResult}
                filters={filters}
                filtersCount={filtersCount}
                dispatchFilterAction={dispatchFilterAction}
            />
        </ListLayout>
    )
}

export default DoctorSurgeryDraftListContainer
