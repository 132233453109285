import { listReducer } from './listReducer'

import type { FormProcedureFilters } from 'api/types'
import type { ListFilterAction, ListFilters } from './listReducer'

export type ProcedureListFilterState = {
    filters: FormProcedureFilters
} & ListFilters
export type ProcedureListFilterAction =
    | { type: 'change'; payload: FormProcedureFilters }
    | ListFilterAction

export function procedureFilterReducer(
    state: ProcedureListFilterState,
    action: ProcedureListFilterAction
) {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                filters: action.payload,
                page: 1,
            }

        default:
            return listReducer(state, action) as {
                filters: FormProcedureFilters
            } & ListFilters
    }
}
