import React from 'react'
import classNames from 'classnames'
import { PaperAirplaneIcon } from '@heroicons/react/solid'

import { Spinner } from 'components/ui'

const CommentSubmitButton: React.FC<{
    isSubmitting: boolean
    disabled?: boolean
}> = ({ isSubmitting, disabled = false }) => (
    <button
        type="submit"
        className={classNames(
            'flex w-9 h-9 items-center justify-center rounded-full cursor-pointer',
            {
                'bg-gray-100 pointer-events-none': disabled || isSubmitting,
                'bg-blue-100': !disabled && !isSubmitting,
            }
        )}
    >
        {isSubmitting && <Spinner size="sm" />}
        {!isSubmitting && (
            <PaperAirplaneIcon
                className={classNames('transform rotate-90 w-4 h-4', {
                    'text-gray-400': disabled,
                    'text-blue-600': !disabled,
                })}
            />
        )}
    </button>
)

export default CommentSubmitButton
