import React, { Fragment, ReactElement, useContext } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { ChatAlt2Icon, DotsVerticalIcon, EyeIcon } from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'

import mediaContext from 'contexts/media/mediaContext'
import { useGetAnesthesiologistSchedules } from 'api'
import {
    useModal,
    useScheduleApprovedPolicyGroup,
    useSchedulePlannerPolicyGroup,
} from 'hooks'
import { Button, ButtonPrint, FilterButton } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import Filters from 'components/ScheduleFilters'
import {
    HeaderCenter,
    NavigatorBar,
    OperatingRoomBar,
    HorizontalLines,
    VerticalLines,
    PlannerHorizontalLines,
    HourlyGrid,
    DailyGrid,
} from 'components/schedule'
import ScheduleSendSMSToAllModal from './ScheduleSendSMSToAllModal'
import SchedulePreviewSMSToAllModal from './SchedulePreviewSMSToAllModal'

import type { UseQueryResult } from 'react-query'
import type {
    OperatingRoom,
    ResponseList,
    ScheduleListMeta,
    ScheduleOperationListItem,
} from 'api/types'
import type { FormSubmitFn, ScheduleFiltersForm } from 'types'

const ScheduleApproved: React.FC<{
    view: 'hourly' | 'daily'
    rooms: OperatingRoom[]
    scheduleQueryResult: UseQueryResult<
        ResponseList<ScheduleOperationListItem[], ScheduleListMeta>
    >
    anesthesiologistSchedulesQueryResult: ReturnType<
        typeof useGetAnesthesiologistSchedules
    >
    onChangeDate: (date: Date) => void
    filters: ScheduleFiltersForm
    filtersCount: number
    filtersExpanded: boolean
    setFiltersExpanded: React.Dispatch<React.SetStateAction<boolean>>
    handleResetFilters: () => void
    onChangeFilters: FormSubmitFn<ScheduleFiltersForm>
    onSelectOperation: (id: number) => void
    setRoom: (operating_room: OperatingRoom) => void
    canSendSMS: boolean
    isApprovedDay: boolean
}> = ({
    view,
    rooms,
    scheduleQueryResult,
    anesthesiologistSchedulesQueryResult,
    onChangeDate,
    filters,
    filtersCount,
    filtersExpanded,
    setFiltersExpanded,
    handleResetFilters,
    onChangeFilters,
    onSelectOperation,
    setRoom,
    canSendSMS,
    isApprovedDay,
}) => {
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()
    const { isMobile } = useContext(mediaContext)
    const sendSMSToAll = useModal(false)
    const previewSMSToAll = useModal(false)

    const handleOpenPreview = () => {
        sendSMSToAll.closeModal()
        previewSMSToAll.openModal()
    }

    return (
        <ListLayout
            className={`${view}-schedule ${view}-schedule-approved`}
            renderTitle={
                <span className="flex inline items-center">
                    <Menu
                        as="div"
                        id="schedule-header-menu"
                        className="relative inline-block mr-2 print:hidden"
                    >
                        {({ open }) => (
                            <>
                                <div>
                                    <Menu.Button>
                                        <span>
                                            <DotsVerticalIcon
                                                height={20}
                                                className={classNames(
                                                    'text-gray-500 hover:text-blue-600',
                                                    {
                                                        'text-blue-600': open,
                                                    }
                                                )}
                                            />
                                        </span>
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="origin-bottom-left absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50">
                                        <div className="py-1 flex flex-col">
                                            <div className="py-2 px-3 text-sm leading-5 text-gray-700 font-normal whitespace-nowrap">
                                                Powiadomienia SMS
                                            </div>
                                            <MenuItem
                                                hasPermission={
                                                    canSendSMS &&
                                                    scheduleApprovedPolicyGroup
                                                        .scheduleSMS.canSend
                                                }
                                                onClick={sendSMSToAll.openModal}
                                                text="Wyślij wszystkim"
                                                icon={
                                                    <ChatAlt2Icon height={24} />
                                                }
                                            />
                                            <MenuItem
                                                hasPermission={
                                                    isApprovedDay &&
                                                    scheduleApprovedPolicyGroup
                                                        .scheduleSMS.canSend
                                                }
                                                onClick={
                                                    previewSMSToAll.openModal
                                                }
                                                text="Podgląd"
                                                icon={<EyeIcon height={24} />}
                                            />
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>

                    <span className="font-medium print:font-normal print:text-3xl">
                        Plan operacyjny
                    </span>
                </span>
            }
            renderCenter={
                isMobile ? undefined : (
                    <HeaderCenter
                        date={moment(filters.today)}
                        isLoading={scheduleQueryResult.isLoading}
                        onChangeDate={onChangeDate}
                    />
                )
            }
            actions={
                <div className="flex items-center space-x-4">
                    <ButtonPrint />
                    <Button
                        as="link"
                        size="sm"
                        variant="default"
                        to={`/schedule${view === 'daily' ? '/hourly' : ''}`}
                        state={{ date: filters.today }}
                    >
                        <span className="whitespace-nowrap">
                            {view === 'hourly' && <span>Widok ogólny</span>}
                            {view === 'daily' && <span>Widok godzinowy</span>}
                        </span>
                    </Button>
                    <FilterButton
                        count={filtersCount}
                        filtersExpanded={filtersExpanded}
                        onClick={() => setFiltersExpanded((state) => !state)}
                        handleReset={handleResetFilters}
                    />
                    {!isMobile && schedulePlannerPolicyGroup.canIndex && (
                        <Button
                            as="link"
                            variant="primary"
                            to={`/schedule${
                                view === 'hourly' ? '/hourly' : ''
                            }/edit`}
                            state={{ date: filters.today }}
                        >
                            Edytuj
                        </Button>
                    )}
                </div>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters
                        filters={filters}
                        rooms={rooms}
                        handleChange={onChangeFilters}
                    />
                )}
                <div className="relative z-0">
                    <div className="overflow-y-auto">
                        <div
                            id="schedule-scrolling-component"
                            className="flex flex-auto flex-col overflow-auto bg-white rounded-md"
                            style={{
                                height: isMobile
                                    ? 'calc(100vh - 142px)'
                                    : 'calc(100vh - 182px)',
                            }}
                        >
                            <div className="flex min-h-full max-w-full flex-none flex-col divide-y-2 divide-gray-200 sm:max-w-none md:max-w-full">
                                <div className="sticky z-30 top-0 flex-none bg-white ring-1 ring-gray-200">
                                    {isMobile && (
                                        <NavigatorBar
                                            date={filters.today}
                                            onChangeDate={onChangeDate}
                                        >
                                            <RoomMenu
                                                rooms={rooms}
                                                setRoom={setRoom}
                                            />
                                        </NavigatorBar>
                                    )}

                                    <OperatingRoomBar
                                        editable={false}
                                        columnHour={view === 'hourly'}
                                        columnUnassigned={true}
                                        rooms={rooms}
                                        date={moment(filters.today)}
                                        meta={scheduleQueryResult.data?.meta}
                                        anesthesiologistSchedulesQueryResult={
                                            anesthesiologistSchedulesQueryResult
                                        }
                                        selectedOperatingRoom={
                                            filters.operating_room
                                        }
                                    />
                                </div>
                                <div className="flex flex-auto divide-x-2 divide-gray-200">
                                    {view === 'hourly' && (
                                        <div
                                            id="schedule-sidebar-timeline"
                                            className="w-10 xl:w-24 print:w-24 flex-none bg-white"
                                        />
                                    )}
                                    <div className="grid flex-auto grid-cols-1 grid-rows-1">
                                        {/* Horizontal lines */}
                                        {view === 'hourly' && (
                                            <>
                                                <PlannerHorizontalLines />
                                                <HorizontalLines />
                                            </>
                                        )}

                                        {/* Vertical lines */}
                                        <VerticalLines
                                            columnUnassigned={true}
                                            cols={isMobile ? 1 : rooms.length}
                                        />

                                        {/* Events */}
                                        {view === 'hourly' && (
                                            <HourlyGrid
                                                draggable={false}
                                                columnUnassigned={true}
                                                rooms={rooms}
                                                date={moment(filters.today)}
                                                operations={
                                                    scheduleQueryResult.data
                                                        ?.data
                                                }
                                                onSelect={onSelectOperation}
                                            />
                                        )}
                                        {view === 'daily' && (
                                            <DailyGrid
                                                draggable={false}
                                                date={moment(filters.today)}
                                                rooms={rooms}
                                                operations={
                                                    scheduleQueryResult.data
                                                        ?.data
                                                }
                                                onSelect={onSelectOperation}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {scheduleApprovedPolicyGroup.scheduleSMS.canSend && (
                    <>
                        <ScheduleSendSMSToAllModal
                            modal={sendSMSToAll}
                            handleCancel={sendSMSToAll.closeModal}
                            handlePreview={handleOpenPreview}
                        />
                        <SchedulePreviewSMSToAllModal
                            modal={previewSMSToAll}
                            handleCancel={previewSMSToAll.closeModal}
                            initialDate={filters.today}
                        />
                    </>
                )}
            </>
        </ListLayout>
    )
}

const MenuItem: React.FC<{
    hasPermission: boolean
    onClick: () => void
    text: string
    icon: ReactElement
}> = ({ hasPermission, onClick, text, icon }) => (
    <Menu.Item>
        <button
            onClick={hasPermission ? onClick : undefined}
            className={classNames(
                'inline-flex items-center py-2 px-3 text-sm leading-5 font-normal whitespace-nowrap',
                hasPermission
                    ? 'text-gray-700 hover:bg-blue-100 cursor-pointer'
                    : 'text-gray-200 cursor-default'
            )}
        >
            <span
                className={classNames(
                    'mr-2',
                    hasPermission ? 'text-gray-400' : 'text-gray-200'
                )}
            >
                {icon}
            </span>
            {text}
        </button>
    </Menu.Item>
)

export const RoomMenu = ({
    rooms,
    setRoom,
}: {
    rooms: OperatingRoom[]
    setRoom: (operating_room: OperatingRoom) => void
}) => (
    <Menu as="div" className="relative inline-block">
        {({ open }) => (
            <>
                <div>
                    <Menu.Button className="bg-white ml-6 p-1.5 shadow-sm rounded">
                        <DotsVerticalIcon
                            height={20}
                            className={classNames(
                                'text-gray-500 hover:text-blue-600',
                                {
                                    'text-blue-600': open,
                                }
                            )}
                        />
                    </Menu.Button>
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div>
                        <Menu.Items className="origin-bottom-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            <div className="py-1  divide-y divide-gray-100">
                                {rooms.map((room) => (
                                    <Menu.Item key={room.id}>
                                        <button
                                            className="flex pl-4 py-3 w-56 text-gray-700"
                                            onClick={() => setRoom(room)}
                                        >
                                            {room.name}
                                        </button>
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </div>
                </Transition>
            </>
        )}
    </Menu>
)

export default ScheduleApproved
