import React, { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { useCreatePatient, useGetPatient, useUpdatePatient } from 'api'
import { Loader } from 'components/ui'

import type { FormSubmitFn } from 'types'
import type { Patient, ResponseData } from 'api/types'
import type { PatientForm } from 'components/DoctorPatientForm'

const GENDER_API_VALUES: Record<number, string> = { 1: 'female', 2: 'male' }

const DoctorSurgeryPatientFormContainer: React.FC<{
    id?: string | number
    onSuccess: (patient: Patient) => void
    children: ({
        data,
        handleSubmit,
    }: {
        data?: ResponseData<Patient>
        handleSubmit: FormSubmitFn<PatientForm>
    }) => JSX.Element
}> = ({ id, onSuccess, children }) => {
    const queryClient = useQueryClient()
    const patient = useGetPatient(id, { enabled: !!id })
    const { mutate: create } = useCreatePatient()
    const { mutate: update } = useUpdatePatient()

    const createOrUpdate = useMemo(
        () => (id ? update : create),
        [create, update, id]
    )

    const transformPatientFormToApi = useCallback(
        (values: PatientForm) =>
            Object.assign(
                {},
                values,
                {
                    gender: values.gender
                        ? GENDER_API_VALUES[values.gender.id as number]
                        : '',
                },
                id ? { id } : {}
            ) as Patient,
        [id]
    )

    const handleSubmit: FormSubmitFn<PatientForm> = (values, formikHelpers) => {
        createOrUpdate(transformPatientFormToApi(values), {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries('patients')
                formikHelpers.setSubmitting(false)
                onSuccess(data.data)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    if (patient.isLoading) {
        return <Loader />
    }

    if (patient.isError) {
        return <div>{patient.error.message}</div>
    }

    return children({ data: patient.data, handleSubmit })
}

export default DoctorSurgeryPatientFormContainer
