import React, { useEffect, useState } from 'react'

import { DEFAULT_NAVIGATOR_LANGUAGE } from 'constants/index'

const Clock = () => {
    const [currentDate, setCurrentDate] = useState(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date())
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <>
            {currentDate.toLocaleTimeString(DEFAULT_NAVIGATOR_LANGUAGE, {
                hour: '2-digit',
                minute: '2-digit',
            })}
        </>
    )
}

export default Clock
