import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useOperationAccountancyPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        isAllowed: hasPermission(PERMISSIONS.OPERATION_ACCOUNTANCY),
    }
}
