import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'

import axios from 'api/axios'
import {
    ResponseData,
    Payer,
    GetPayersResponse,
    ResponseError,
    PostPayer,
    UpdatePayer,
} from 'api/types'

export const getPayers = <T>(filters?: {}): Promise<T> =>
    axios.get('/payers?' + new URLSearchParams(filters).toString())

export const useGetPayers = <T = GetPayersResponse>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['payers', filters],
        () => getPayers<T>(filters),
        options
    )

export const createPayer = (data: PostPayer): Promise<ResponseData<Payer>> =>
    axios.post('/payers', data)

export const useCreatePayer = (): UseMutationResult<
    ResponseData<Payer>,
    ResponseError,
    PostPayer
> => useMutation((data) => createPayer(data))

export const updatePayer = (
    id: number,
    data: UpdatePayer
): Promise<ResponseData<Payer>> => axios.patch('/payers/' + id, data)

export const useUpdatePayer = (): UseMutationResult<
    ResponseData<Payer>,
    ResponseError,
    { id: number; data: UpdatePayer }
> => useMutation((param) => updatePayer(param.id, param.data))
