import React from 'react'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { useGetPatient, useUpdatePatient } from 'api'
import ListLayout from 'layouts/ListLayout'
import DoctorPatientDocuments from 'components/DoctorPatientDocuments'

import type { FormSubmitFn } from 'types'
import type { ResponseError } from 'api/types'
import type { DocumentForm } from 'types/DocumentForm'
import type { TTab } from 'components/ListLayoutTabs'

const tabs: TTab[] = [
    {
        name: 'Pacjent',
        to: '/patients/:id/stats',
        current: false,
    },
    {
        name: 'Dane pacjenta',
        to: '/patients/:id/edit',
        current: true,
    },
    {
        name: 'Operacje i zabiegi',
        to: '/patients/:id/surgeries',
        current: false,
    },
    { name: 'Dokumenty', to: '/patients/:id/documents', current: false },
]

const DoctorPatientDocumentsContainer: React.FC = () => {
    const queryClient = useQueryClient()
    const { id } = useParams()
    const patient = useGetPatient(id, {
        refetchOnWindowFocus: false,
    })
    const { mutate: update } = useUpdatePatient<{
        id: string
        documents: {
            add: [DocumentForm]
        }
    }>()

    const handleSubmit: FormSubmitFn<DocumentForm> = (
        values,
        formikHelpers
    ) => {
        update(
            { id: id!, documents: { add: [values] } },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('patients')
                    formikHelpers.setSubmitting(false)
                    formikHelpers.resetForm()
                },
                onError: (error) => {
                    const errors = Object.keys(error.errors).reduce(
                        (acc, key) => {
                            acc[key.replace('documents.add.0.', '')] =
                                error.errors[key]
                            return acc
                        },
                        {} as ResponseError['errors']
                    )

                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(errors)
                },
            }
        )
    }

    return (
        <ListLayout title="Karta pacjenta" id={id} tabs={id ? tabs : null}>
            <DoctorPatientDocuments
                patient={patient}
                handleSubmit={handleSubmit}
            />
        </ListLayout>
    )
}

export default DoctorPatientDocumentsContainer
