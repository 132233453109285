import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useGetSpecializations } from 'api'
import { useModal, useSpecializationPolicy } from 'hooks'
import SettingsPermissionLayout from 'layouts/SettingsPermissionLayout'
import { AccessDenied } from 'components'
import { Button, Loader } from 'components/ui'
import SettingsSpecializationList from 'components/SettingsSpecializationList'
import SettingsSpecializationModal from 'containers/SettingsSpecializationModal'

const SettingsSpecializationListContainer = () => {
    const queryClient = useQueryClient()
    const specializationPolicy = useSpecializationPolicy()
    const modal = useModal()
    const [filters, setFilters] = useReducer(listReducer, {
        sort_by: 'id',
        sort_direction: 'desc',
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const specializations = useGetSpecializations(filters, {
        enabled: specializationPolicy.canAll,
    })

    const handleEdit = (id: number) => {
        modal.setState(id)
        modal.openModal()
    }

    const handleSuccess = async () => {
        const id = modal.getState()
        modal.closeModal()
        await queryClient.invalidateQueries(['specializations', id])
        await queryClient.invalidateQueries('specializations')
    }

    return (
        <SettingsPermissionLayout
            title="Role i uprawnienia"
            actions={
                specializationPolicy.canAll && (
                    <>
                        <Button
                            variant="primary"
                            onClick={() => modal.openModal()}
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    </>
                )
            }
        >
            <>
                {!specializationPolicy.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania specjalizacjami" />
                )}
                {specializations.isLoading && <Loader />}
                {specializations.isError && (
                    <div>{specializations.error.message}</div>
                )}
                {specializations.isSuccess && (
                    <SettingsSpecializationList
                        data={specializations.data}
                        modal={modal}
                        setFilters={setFilters}
                        handleEdit={handleEdit}
                    />
                )}
                <SettingsSpecializationModal
                    modal={modal}
                    handleSuccess={handleSuccess}
                />
            </>
        </SettingsPermissionLayout>
    )
}

export default SettingsSpecializationListContainer
