import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Switch } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

export default function Toggle(props: {
    variant?: 'blue' | 'green'
    checked: boolean
    disabled?: boolean
    handleChange: ((value: boolean) => void) | undefined
}) {
    const { variant = 'blue', disabled = false, handleChange } = props
    const [checked, setChecked] = useState<boolean>(props.checked)

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    return (
        <Switch
            checked={checked}
            onChange={(value) => {
                if (!!handleChange) {
                    setChecked(value)
                    handleChange(value)
                }
            }}
            className={twMerge(
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                variant === 'green' && 'focus:ring-0 focus:ring-offset-0',
                checked && 'bg-blue-500',
                checked && variant === 'green' && 'bg-green-500',
                disabled && 'opacity-50 pointer-events-none'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                className={classNames(
                    checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            >
                <span
                    className={classNames(
                        checked
                            ? 'opacity-0 ease-out duration-100'
                            : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                >
                    <svg
                        className="h-3 w-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                    >
                        <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
                <span
                    className={classNames(
                        checked
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                >
                    <svg
                        className={twMerge(
                            'h-3 w-3 text-blue-500',
                            variant === 'green' && 'text-green-500'
                        )}
                        fill="currentColor"
                        viewBox="0 0 12 12"
                    >
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </span>
            </span>
        </Switch>
    )
}
