import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { IModal } from 'types'

export const MODAL_TRANSITION_LEAVE_DURATION = 200

const Modal = React.forwardRef<
    HTMLDivElement | null,
    {
        children: React.ReactChild
        modal: IModal
        onClickOutside?: () => void
        initialFocus?: React.MutableRefObject<any>
    }
>(({ children, modal, initialFocus, onClickOutside }, ref) => {
    return (
        <Transition.Root show={modal.isOpen} as={Fragment}>
            <Dialog
                ref={ref}
                as="div"
                className="fixed z-20 inset-0 overflow-y-auto"
                initialFocus={initialFocus}
                onClose={onClickOutside ? onClickOutside : modal.closeModal}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {children}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
})

export default Modal
