import React from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { RadioGroup } from '@headlessui/react'

import {
    forOperationDate,
    TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS,
} from 'helpers'
import {
    AdmissionTimeDropdown,
    AvailableDatePickerField,
    DatePickerField,
    ErrorMessage,
} from 'components/forms'
import { Label } from 'components/ui'
import { RadioGroupOption } from 'components/OperationAdmissionDateModal'

import type { ScheduleOperationForm as TScheduleOperationForm } from 'types'

type CaregiverActionsProps = {
    disabled?: boolean
}

export default function CaregiverActions({ disabled }: CaregiverActionsProps) {
    const { values, setFieldValue } = useFormikContext<TScheduleOperationForm>()

    const radioGroupValue = forOperationDate(values.estimated_date).isDW(
        values._admission_date__date
    )
        ? 'dw'
        : forOperationDate(values.estimated_date).isDZ(
              values._admission_date__date
          )
        ? 'dz'
        : undefined

    return (
        <div className="divide-gray-200 divide-y space-y-5">
            <div className="grid grid-cols-1 gap-6">
                <div className="grid-col-1">
                    <Label>Dzień operacji</Label>
                    <AvailableDatePickerField
                        name="estimated_date"
                        placeholder="DD.MM.RRRR"
                        disabledBefore={moment().toDate()}
                        disabled={disabled}
                        onChange={() => {
                            setFieldValue('operating_room', undefined)
                            setFieldValue('start_at', undefined)
                        }}
                    />
                </div>
            </div>
            <div className="pt-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="col-span-1 md:col-span-2 space-y-4">
                        <Label>Dzień</Label>
                        <div className="flex items-center space-x-5">
                            <RadioGroup
                                value={radioGroupValue}
                                className="flex flex-grow space-x-5"
                                onChange={(kind: 'dz' | 'dw' | undefined) => {
                                    setFieldValue(
                                        '_admission_date__date',
                                        forOperationDate(
                                            values.estimated_date
                                        ).absoluteDateFor(kind)
                                    )
                                }}
                            >
                                <div className="flex-grow">
                                    <RadioGroupOption
                                        disabled={disabled}
                                        value="dw"
                                    >
                                        DW
                                    </RadioGroupOption>
                                </div>
                                <div className="flex-grow">
                                    <RadioGroupOption
                                        disabled={disabled}
                                        value="dz"
                                    >
                                        DZ
                                    </RadioGroupOption>
                                </div>
                            </RadioGroup>
                            <div className="flex-shrink">
                                <DatePickerField
                                    disabled={disabled}
                                    tabIndex={-1}
                                    name="_admission_date__date"
                                    disabledBefore={moment().toDate()}
                                    disabledAfter={moment(
                                        values.estimated_date
                                    ).toDate()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-1 space-y-4">
                        <Label>Godzina</Label>
                        <AdmissionTimeDropdown
                            tabIndex={-1}
                            placeholder="--:--"
                            value={values._admission_date__time}
                            items={TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS}
                            disabled={disabled}
                            onChange={(value) =>
                                setFieldValue('_admission_date__time', value)
                            }
                        />
                    </div>
                </div>
                <ErrorMessage name="admission_date" />
                <div className="mt-4">
                    <div className="flex items-center">
                        <input
                            id="patient-informed"
                            name="patient_informed"
                            type="checkbox"
                            className="h-4 w-4 text-blue-500 focus:ring-blue-600 border-gray-300 rounded"
                            checked={values.patient_informed}
                            disabled={disabled}
                            onChange={(value) => {
                                setFieldValue(
                                    'patient_informed',
                                    !values.patient_informed
                                )
                            }}
                        />
                        <label
                            htmlFor="patient-informed"
                            className="ml-2 block text-sm text-gray-700"
                        >
                            Pacjent poinformowany
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
