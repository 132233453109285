import React from 'react'
import classNames from 'classnames'
import { ArrowSmRightIcon } from '@heroicons/react/solid'

import { formatDateTime } from 'helpers'
import { Spinner, TableFooter } from 'components/ui'

import type { UseQueryResult } from 'react-query'
import type { ResponseList, ActivityLogEntry } from 'api/types'
import type { ListFilters, ListFilterAction } from 'helpers'

const ActivityLog: React.FC<{
    activityLogResource: UseQueryResult<ResponseList<ActivityLogEntry[]>>
    filters: ListFilters
    filtersCount: number
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ activityLogResource, filters, filtersCount, setFilters }) => {
    return (
        <div className="p-2 bg-white rounded-lg">
            <>
                <table className="table-fixed min-w-full">
                    <thead className="bg-gray-50 border-b border-b-gray-200 text-xs text-gray-500 uppercase h-10">
                        <tr className="h-10">
                            <th
                                scope="col"
                                className="px-2 text-left font-medium tracking-wider"
                                style={{ width: '5%' }}
                            >
                                Id
                            </th>
                            <th
                                scope="col"
                                className="w-2/12 px-3 text-left font-medium tracking-wider"
                            >
                                Data
                            </th>
                            <th
                                scope="col"
                                className="w-4/12 px-3 text-left font-medium tracking-wider"
                            >
                                Opis
                            </th>
                            <th
                                scope="col"
                                className="w-1/12 px-3 text-center font-medium tracking-wider"
                            >
                                Id&nbsp;obiektu
                            </th>
                            <th
                                scope="col"
                                className="w-4/12 px-3 text-left font-medium tracking-wider"
                            >
                                Szczegóły
                            </th>
                        </tr>
                    </thead>
                    {activityLogResource.isSuccess && (
                        <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                            {activityLogResource.data.data.map(
                                (item, index) => (
                                    <tr
                                        key={item.id}
                                        className={classNames('h-16', {
                                            'bg-gray-50': index % 2,
                                        })}
                                    >
                                        <td className="px-3 py-1">
                                            <span>{item.id}</span>
                                        </td>
                                        <td className="px-3 py-1">
                                            <span className="whitespace-nowrap">
                                                {formatDateTime(
                                                    item.created_at,
                                                    'DD.MM.YYYY HH:mm:ss'
                                                )}
                                            </span>
                                        </td>
                                        <td className="px-3 py-1">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: displayDescription(
                                                        item
                                                    ),
                                                }}
                                            ></span>
                                        </td>
                                        <td className="px-3 py-1 text-center">
                                            {item.object.id}
                                        </td>
                                        <td className="px-3 py-1">
                                            <div className="space-y-0.5">
                                                {renderProperties(item)}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    )}
                </table>
                {activityLogResource.isLoading && (
                    <div className="divide-y divide-gray-200 text-sm leading-5">
                        {Array.from(Array(filters.length).keys()).map(
                            (item, index) => (
                                <div key={index} className="relative h-16">
                                    {index === 5 && (
                                        <div className="absolute inset-0 h-full flex justify-center items-center">
                                            <Spinner className="p-0" />
                                        </div>
                                    )}
                                    <span>&nbsp;</span>
                                </div>
                            )
                        )}
                    </div>
                )}
                {activityLogResource.isSuccess &&
                    activityLogResource.data.meta.total === 0 && (
                        <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                            {Array.from(Array(filters.length).keys()).map(
                                (item, index) => (
                                    <div key={index} className="relative h-16">
                                        {index === 5 && (
                                            <div className="absolute inset-0 h-full flex justify-center items-center">
                                                {filtersCount === 0 && (
                                                    <span>
                                                        Nie ma jeszcze żadnych
                                                        logów.
                                                    </span>
                                                )}
                                                {filtersCount > 0 && (
                                                    <span>
                                                        Nie znaleziono wyników
                                                        wyszukiwania.
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        <span>&nbsp;</span>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                <TableFooter
                    meta={activityLogResource.data?.meta}
                    setFilters={setFilters}
                />
            </>
        </div>
    )
}

const displayDescription = (item: ActivityLogEntry) => {
    const description = []

    if (item.user) {
        description.push(
            '<span class="font-medium text-gray-900">' +
                item.user.full_name +
                '</span>'
        )
    } else {
        description.push('<span class="italic text-gray-900">Nieznany</span>')
    }

    description.push(' ')
    description.push(
        item.event in mapEventToVerb
            ? item.event === 'created' &&
              item.object.type === 'App\\Models\\OperationEvent'
                ? 'zmienił'
                : mapEventToVerb[item.event]
            : item.event
    )

    if (
        ![
            'login',
            'logout',
            'add_member',
            'remove_member',
            'created_event',
            'renew_operation',
        ].includes(item.event)
    ) {
        const translatedType =
            item.object.type in mapEventToNounComplement
                ? mapEventToNounComplement[item.object.type]
                : item.object.type

        if (translatedType) {
            description.push(' ')
            description.push(translatedType)
        }
    }

    description.push('.')

    return description.filter((item) => !!item).join('')
}

const renderProperties = (item: ActivityLogEntry) => {
    const { properties } = item

    if (!('attributes' in properties)) {
        return null
    }

    return Object.keys(properties.attributes).map((key, index) => {
        return (
            <p key={index}>
                <span className="space-x-1">
                    <span className="inline-flex text-xs font-medium leading-5 text-gray-800">
                        {key}:
                    </span>
                    {properties.old && key in properties.old && (
                        <span className="inline-flex">
                            <span className="inline-flex rounded-lg bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                                {JSON.stringify(properties.old[key])}
                            </span>
                            <ArrowSmRightIcon className="inline-flex w-3" />
                        </span>
                    )}
                    <span className="inline-flex rounded-lg bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        {JSON.stringify(properties.attributes[key])}
                    </span>
                </span>
            </p>
        )
    })
}

const mapEventToVerb: Record<string, string> = {
    created: 'dodał',
    updated: 'zaktualizował',
    deleted: 'usunał',
    login: 'zalogował się',
    logout: 'wylogował się',
    approved: 'zatwierdził',
    add_member: 'przypisał osobę do zespołu medycznego',
    remove_member: 'usunął osobę z zespołu medycznego',
    created_event: 'zmienił postęp operacji',
    renew_operation: 'przywrócił operację',
    login_with_mfa: 'zalogował się przy użyciu MFA',
    sent_mfa_code: 'otrzymał kod SMS',
}

const mapEventToNounComplement: Record<string, string> = {
    'App\\Models\\User': '',
    'App\\Models\\Operation': 'operację',
    'App\\Models\\OperationEvent': 'status operacji',
    'App\\Models\\OperationTask': 'zadanie',
    'App\\Models\\Schedule': 'operację w planie operacyjnym',
    'App\\Models\\Comment': 'komentarz',
    'App\\Models\\Document': 'dokument',
    'App\\Models\\ApprovedDay': 'plan operacyjny',
}

export default ActivityLog
