import React from 'react'
import { DotsVerticalIcon } from '@heroicons/react/solid'

import { Button } from 'components/ui'

import styles from './Button3dots.module.scss'

import type { ButtonProps } from 'components/ui/Button'

export type Button3dotsProps = {
    size: ButtonProps['size']
    variant: ButtonProps['variant']
    hovered?: ButtonProps['hovered']
    loading?: ButtonProps['loading']
}

export default function Button3dots({
    loading,
    variant,
    hovered,
    size,
}: Button3dotsProps) {
    return (
        <Button
            type="button"
            variant={variant}
            size={size}
            loading={loading}
            hovered={hovered}
            styles={styles}
        >
            <DotsVerticalIcon className="w-auto h-4" />
        </Button>
    )
}
