import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const LIVE_VIEW_PERMISSIONS = {
    index: PERMISSIONS.VIEW_OPERATION_EVENTS_LIVE_VIEW,
    show: false,
    create: false,
    update: false,
    delete: false,
}

export const useLiveViewPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(LIVE_VIEW_PERMISSIONS),
    }
}
