import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import { useGetEquipmentList, useUpdateEquipment } from 'api'
import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useModal, useEquipmentPolicy, useNotification } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, Loader } from 'components/ui'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsMedicalEquipmentList, {
    FormValues,
} from 'components/SettingsMedicalEquipmentList'
import SettingsMedicalEquipmentModal from './SettingsMedicalEquipmentModal'

import type { FormSubmitFn } from 'types'

export default function SettingsMedicalEquipmentListContainer() {
    const equipmentPolicy = useEquipmentPolicy()
    const showNotification = useNotification()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const queryClient = useQueryClient()
    const modal = useModal()
    const equipmentListQueryResult = useGetEquipmentList(filters, {
        enabled: equipmentPolicy.canAll,
    })
    const { mutate: update } = useUpdateEquipment()

    const handleEdit = (id: number) => {
        modal.setState(id)
        modal.openModal()
    }

    const handleSuccess = () => {
        modal.closeModal()
        queryClient.invalidateQueries('equipment')
    }

    const handleUpdate: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        update(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
                queryClient.invalidateQueries('equipment')
                showNotification({
                    content: 'Sprzęt medyczny został zaktulizowany',
                    type: 'success',
                })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
                showNotification({
                    content:
                        'Wystąpił błąd podczas aktualizacji sprzętu medycznego',
                    type: 'danger',
                })
            },
        })
    }

    return (
        <SettingsPriceListLayout
            actions={
                equipmentPolicy.canAll && (
                    <>
                        <span className="ml-2">
                            <ActionButton
                                handleClick={modal.openModal}
                                rightIcon={PlusIcon}
                            >
                                Dodaj
                            </ActionButton>
                        </span>
                    </>
                )
            }
        >
            <>
                {!equipmentPolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania sprzętem medycznym" />
                )}
                {equipmentListQueryResult.isLoading && <Loader />}
                {equipmentListQueryResult.isSuccess && (
                    <SettingsMedicalEquipmentList
                        data={equipmentListQueryResult.data}
                        modal={modal}
                        handleUpdate={handleUpdate}
                        handleEdit={handleEdit}
                        setFilters={setFilters}
                    />
                )}
                <SettingsMedicalEquipmentModal
                    modal={modal}
                    handleSuccess={handleSuccess}
                />
            </>
        </SettingsPriceListLayout>
    )
}
