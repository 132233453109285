import React from 'react'
import { Outlet } from 'react-router-dom'

import Logo from 'assets/logo-promedheus.png'

export default function LoginLayout() {
    return (
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 flex justify-center">
                    <img src={Logo} className="w-60" alt="Logo Promedheus" />
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 sm:py-12">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
