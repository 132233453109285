import React from 'react'
import { useField } from 'formik'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import { Label, InputTextArea } from 'components/ui'

export default function TextAreaField({
    name,
    labelText,
    placeholder,
    ...rest
}: {
    name: string
    labelText?: string
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>) {
    const [field, meta] = useField(name)

    return (
        <>
            {!!labelText && <Label htmlFor={field.name}>{labelText}</Label>}
            <div className="relative">
                <InputTextArea
                    name={field.name}
                    value={field.value}
                    handleChange={field.onChange}
                    placeholder={placeholder}
                    hasError={meta.touched && !!meta.error}
                    {...rest}
                />
                {meta.touched && meta.error && (
                    <div className="absolute top-2 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </div>
            {meta.touched && meta.error && (
                <p
                    className="mt-1 text-sm text-red-600"
                    id={`${field.name}-error`}
                >
                    {meta.error}
                </p>
            )}
        </>
    )
}
