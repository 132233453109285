import { usePriceListMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const PriceListMenuItemGate: MenuItemGate = ({ children }) => {
    const priceListMenuItemPolicyGroup = usePriceListMenuItemPolicyGroup()

    if (priceListMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
