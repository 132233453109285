import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PERMISSION_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PERMISSIONS,
    show: false,
    create: false,
    update: false,
    delete: false,
}

export const usePermissionPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PERMISSION_PERMISSIONS),
    }
}
