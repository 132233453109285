import React from 'react'
import classNames from 'classnames'

import styles from './InputText.module.scss'

export default function InputTextArea({
    id,
    name,
    autoComplete,
    value,
    placeholder,
    disabled,
    handleChange,
    hasError,
    className,
    rows,
    ...rest
}: {
    handleChange?: React.ChangeEventHandler
    hasError?: boolean
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>) {
    return (
        <textarea
            name={name}
            id={id}
            autoComplete={autoComplete}
            placeholder={placeholder}
            className={classNames(
                styles.root,
                styles.md,
                styles.textarea,
                {
                    [styles.error]: hasError,
                    [styles.disabled]: disabled,
                },
                className
            )}
            disabled={disabled}
            value={value}
            onChange={handleChange}
            rows={rows || 10}
            {...rest}
        />
    )
}
