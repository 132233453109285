import React, { useMemo } from 'react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import {
    ICDCodeField,
    DropdownField,
    PriceField,
    TextField,
} from 'components/forms'
import { ICD_CODE_SEPARATOR } from 'components/forms/ICDCodeField'
import { Label, Button, CancelButton, Toggle } from 'components/ui'

import type {
    ICDCode,
    Payer,
    Procedure,
    ProcedureCategory,
    ProcedureType,
    ResponseData,
} from 'api/types'

export interface PayerCost {
    id: number
    price: string | number
}

export interface FormValues {
    name: string
    icd9?: ICDCode
    recovery_days: string
    base_price: string
    procedure_category?: {
        id: number
        name: string
    }
    procedure_type?: {
        id: number
        name: string
    }
    payer_costs: PayerCost[]
    implants_required: boolean
}

interface SettingsProcedureFormProps {
    id?: string
    data?: ResponseData<Procedure>
    categories: ResponseData<ProcedureCategory[]>
    types: ResponseData<ProcedureType[]>
    payers: ResponseData<Payer[]>
    handleSubmit: (
        values: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => void
}

const SettingsProcedureForm: React.FC<SettingsProcedureFormProps> = ({
    id,
    data,
    categories,
    types,
    payers,
    handleSubmit,
}) => {
    const initialValues = useMemo(() => {
        return data
            ? ({
                  ...data.data,
                  icd9_phrase: data.data.icd9
                      ? data.data.icd9.code +
                        ICD_CODE_SEPARATOR +
                        data.data.icd9.description
                      : '',
                  payer_costs: payers.data.map((i) => ({
                      id: Number(i.id),
                      price: data.data.payer_costs
                          ? data.data.payer_costs.find((pc) => pc.id === i.id)
                                ?.price || ''
                          : '',
                  })),
              } as FormValues)
            : {
                  name: '',
                  icd9: undefined,
                  icd9_phrase: '',
                  recovery_days: '',
                  base_price: '',
                  procedure_category: undefined,
                  procedure_type: undefined,
                  payer_costs: payers.data.map((i) => ({
                      id: Number(i.id),
                      price: '',
                  })),
                  implants_required: false,
              }
    }, [data, payers])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validateOnChange={false}
        >
            {({ values, isSubmitting, setFieldValue }) => (
                <div className="py-6">
                    <div className="px-4 mb-4 sm:px-6 md:px-8">
                        <div className="flex gap-4 items-center justify-between">
                            <div className="flex-none rounded-lg flex items-center justify-center">
                                <h2 className="text-2xl leading-8">
                                    {!id && (
                                        <span className="font-semibold">
                                            Dodaj:
                                        </span>
                                    )}
                                    {!!id && (
                                        <span className="font-semibold">
                                            Edytuj:
                                        </span>
                                    )}
                                    &nbsp;
                                    <span className="font-light">Operację</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mb-6 sm:px-6 md:px-8">
                        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                            <Form>
                                <div className="sm:rounded-md">
                                    <div className="bg-white pt-12 px-6">
                                        <div className="border-b">
                                            <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                                <div className="col-span-6">
                                                    <div className="pr-8">
                                                        <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                            Operacja
                                                        </div>
                                                        <div className="mt-4">
                                                            <TextField
                                                                labelText="Nazwa"
                                                                name="name"
                                                            />
                                                        </div>
                                                        <div className="mt-4">
                                                            <DropdownField
                                                                label="Kategoria"
                                                                name="procedure_category"
                                                                items={
                                                                    categories.data
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-4">
                                                            <DropdownField
                                                                label="Rodzaj zabiegu"
                                                                name="procedure_type"
                                                                items={
                                                                    types.data
                                                                }
                                                            />
                                                        </div>
                                                        <div className="mt-4">
                                                            <TextField
                                                                labelText="Liczba dni hospitalizacji"
                                                                name="recovery_days"
                                                            />
                                                        </div>
                                                        <div className="mt-4">
                                                            <Label>ICD-9</Label>
                                                            <ICDCodeField name="icd9" />
                                                        </div>
                                                        <div className="mt-8">
                                                            <div className="flex flex space-x-3 items-center justify-between">
                                                                <Label>
                                                                    Wymagane
                                                                    implanty
                                                                </Label>
                                                                <Toggle
                                                                    checked={
                                                                        values.implants_required
                                                                    }
                                                                    handleChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            'implants_required',
                                                                            value
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-6">
                                                    <div className="pl-8">
                                                        <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                            Koszty operacji
                                                        </div>
                                                        <div className="mt-4">
                                                            <Label>
                                                                Koszt podstawowy
                                                            </Label>
                                                            <PriceField
                                                                name="base_price"
                                                                placeholder="0,00"
                                                            />
                                                        </div>
                                                        <PayerCostInputs
                                                            payers={payers}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white px-6 py-12 text-right">
                                        <CancelButton
                                            as="link"
                                            to="/settings/procedures"
                                        >
                                            Anuluj
                                        </CancelButton>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                            iconRight={<PlusIcon />}
                                        >
                                            Zapisz
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

const PayerCostInputs: React.FC<{ payers: ResponseData<Payer[]> }> = ({
    payers,
}) => (
    <>
        {payers.data.map((item, index) => (
            <div key={index} className="mt-4">
                <Field type="hidden" name={`payer_costs.${index}.id`} />
                <Label>{item.name}</Label>
                <PriceField name={`payer_costs.${index}.price`} />
            </div>
        ))}
    </>
)

export default SettingsProcedureForm
