import React, { Fragment, useRef, useState } from 'react'
import { useField } from 'formik'
import { useQuery } from 'react-query'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import { useEventListener } from 'hooks'
import { Label, Loader } from 'components/ui'

import type { ResponseData } from 'api/types'

interface DropdownItem {
    id: number
    name: string
}

type RenderDropdownToggleFn = ({
    isOpen,
}: {
    isOpen: boolean
    toggle: () => void
}) => React.ReactNode

type RenderDropdownOptionFn = (
    name: string,
    item: DropdownItem,
    active: boolean,
    selected: boolean
) => React.ReactNode

interface DropdownProps {
    name: string
    label?: string
    queryFn: (filters?: {}) => Promise<ResponseData<DropdownItem[]>>
    queryFilters?: {}
    placeholder?: string
    variant?: 'md' | 'lg'
    bulkOption?: boolean
    bulkOptionName?: string
    renderToggle?: RenderDropdownToggleFn
    renderOption?: RenderDropdownOptionFn
}

const CheckboxDropdownLazyField = ({
    name,
    label,
    queryFn,
    queryFilters,
    placeholder,
    variant = 'md',
    bulkOption = false,
    bulkOptionName,
    renderToggle,
    renderOption,
}: DropdownProps) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [field, , helpers] = useField(name)

    const handleClickOutside = (e: Event) => {
        if (
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target)
        ) {
            setIsOpen(false)
        }
    }

    useEventListener('mousedown', handleClickOutside)

    return (
        <div ref={ref}>
            <Listbox
                value={field.value}
                onChange={(v: DropdownItem | null) => {
                    if (!v) {
                        return helpers.setValue([])
                    }

                    helpers.setValue(
                        field.value.find((fv: DropdownItem) => fv.id === v.id)
                            ? field.value.filter(
                                  (fv: DropdownItem) => fv.id !== v.id
                              )
                            : field.value.concat(v)
                    )
                }}
            >
                {() => (
                    <>
                        {!!label && (
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                                <Label>{label}</Label>
                            </Listbox.Label>
                        )}
                        <div className="relative">
                            {typeof renderToggle === 'function' &&
                                renderToggle({
                                    isOpen,
                                    toggle: () => setIsOpen(!isOpen),
                                })}
                            {typeof renderToggle !== 'function' && (
                                <div
                                    className={classNames(
                                        'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer',
                                        {
                                            'text-sm': variant === 'md',
                                            'text-base': variant === 'lg',
                                            'ring-1 ring-offset-0 ring-blue-500 border-blue-500':
                                                isOpen,
                                        }
                                    )}
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    <span className="block truncate">
                                        {field.value.length > 0 &&
                                            field.value
                                                .map(
                                                    (item: DropdownItem) =>
                                                        item.name
                                                )
                                                .join(', ')}
                                        {!placeholder &&
                                            field.value.length === 0 && (
                                                <span>Wszystko</span>
                                            )}
                                        {!!placeholder &&
                                            field.value.length === 0 && (
                                                <span className="text-gray-400">
                                                    {placeholder}
                                                </span>
                                            )}
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        {!isOpen && (
                                            <ChevronDownIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {isOpen && (
                                            <ChevronUpIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </span>
                                </div>
                            )}

                            <Transition
                                show={isOpen}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    static
                                    className="absolute z-20 mt-1 min-w-full bg-white shadow-lg max-h-60 rounded-md px-1 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200"
                                >
                                    <Options
                                        open={isOpen}
                                        name={name}
                                        queryFn={queryFn}
                                        queryFilters={queryFilters}
                                        renderOption={renderOption}
                                        bulkOption={bulkOption}
                                        bulkOptionName={bulkOptionName}
                                    />
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}

type OptionsProps = {
    name: string
    open: boolean
    placeholder?: string
    bulkOption?: boolean
    bulkOptionName?: string
    queryFn: (filters?: {}) => Promise<ResponseData<DropdownItem[]>>
    queryFilters?: {}
    renderOption?: RenderDropdownOptionFn
}

const Options = ({
    name,
    open,
    queryFn,
    queryFilters = {},
    renderOption,
    bulkOption,
    bulkOptionName,
}: OptionsProps) => {
    // eslint-disable-next-line
    const [field] = useField(name)

    const queryResult = useQuery(
        `dropdown-${name}`,
        () =>
            queryFn({
                ...queryFilters,
                pagination: false,
            }),
        { enabled: open }
    )

    if (queryResult.isLoading) {
        return (
            <div className="p-5">
                <Loader size="sm" />
            </div>
        )
    }

    if (queryResult.isSuccess && queryResult.data) {
        return (
            <>
                {bulkOption && (
                    <Listbox.Option
                        className={({ active }) =>
                            classNames(
                                'cursor-pointer select-none relative py-2 pl-3 pr-3',
                                {
                                    'text-white bg-blue-600': active,
                                    'text-gray-900': !active,
                                }
                            )
                        }
                        value={''}
                    >
                        {({ active }) => (
                            <>
                                <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            name={name}
                                            type="checkbox"
                                            className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded cursor-pointer "
                                            checked={field.value.length === 0}
                                            readOnly
                                        />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label
                                            className={classNames(
                                                'block cursor-pointer ',
                                                {
                                                    'font-medium':
                                                        field.value.length ===
                                                        0,
                                                    'font-normal':
                                                        field.value.length !==
                                                        0,
                                                    'text-white': active,
                                                }
                                            )}
                                        >
                                            <span>{bulkOptionName}</span>
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                    </Listbox.Option>
                )}
                {queryResult.data.data
                    .map((item) => ({ id: item.id, name: item.name }))
                    .map((item) => {
                        const selected = !!field.value.find(
                            (fv: DropdownItem) => fv.id === item.id
                        )

                        return (
                            <Listbox.Option
                                key={item.id}
                                className={({ active }) =>
                                    classNames(
                                        {
                                            'text-white bg-blue-600': active,
                                            'text-gray-900': !active,
                                        },
                                        'cursor-pointer select-none relative py-2 pl-3 pr-3'
                                    )
                                }
                                value={item}
                            >
                                {({ active }) => (
                                    <>
                                        {typeof renderOption === 'function' ? (
                                            renderOption(
                                                name,
                                                item,
                                                active,
                                                selected
                                            )
                                        ) : (
                                            <div className="relative flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        name={name}
                                                        type="checkbox"
                                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded cursor-pointer"
                                                        checked={selected}
                                                        readOnly
                                                    />
                                                </div>
                                                <label
                                                    className={classNames(
                                                        'ml-3 text-sm block cursor-pointer',
                                                        {
                                                            'font-medium':
                                                                selected,
                                                            'font-normal':
                                                                !selected,
                                                            'text-white':
                                                                active,
                                                        }
                                                    )}
                                                >
                                                    {item.name}
                                                </label>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Listbox.Option>
                        )
                    })}
            </>
        )
    }

    return null
}

export default CheckboxDropdownLazyField
