import React from 'react'
import { UseQueryResult } from 'react-query'
import classNames from 'classnames'

import { useCommentPolicy } from 'hooks'
import CommentForm from 'components/CommentForm'
import CommentList from 'components/CommentList'

import type { FormSubmitFn } from 'types'
import type { ICommentForm } from 'types/CommentForm'
import type {
    Pagination,
    Comment as IComment,
    CommentListFilters,
    ResponseList,
    ResponseError,
} from 'api/types'

const OperationCommentForm: React.FC<{
    comments: UseQueryResult<
        ResponseList<
            IComment[],
            Pagination & { has_tasks_with_comments: boolean }
        >,
        ResponseError
    >
    selectedType: CommentListFilters['type']
    handleSubmit: FormSubmitFn<ICommentForm>
    handleChangeType: React.Dispatch<
        React.SetStateAction<CommentListFilters['type']>
    >
    handleClickTaskId?: (id: number) => void
}> = ({
    handleSubmit,
    comments,
    selectedType,
    handleChangeType,
    handleClickTaskId,
}) => {
    const commentPolicy = useCommentPolicy()
    return (
        <>
            <div className="flex mb-4">
                <p className="flex-grow pt-4 text-lg font-medium leading-6">
                    Komentarze
                </p>
                <div className="flex-shrink">
                    <div className="border-b border-gray-200">
                        <nav
                            className="-mb-px flex space-x-2 md:space-x-8"
                            aria-label="Tabs"
                        >
                            <TabItem
                                type="operation"
                                current={selectedType === 'operation'}
                                handleClick={handleChangeType}
                            >
                                Do operacji
                            </TabItem>
                            <TabItem
                                current={selectedType === 'operation_task'}
                                type="operation_task"
                                handleClick={handleChangeType}
                            >
                                Do zadań
                            </TabItem>
                        </nav>
                    </div>
                </div>
            </div>
            <CommentList
                type={selectedType}
                comments={comments}
                displayTaskId={selectedType === 'operation_task'}
                handleClickTaskId={handleClickTaskId}
            />
            {commentPolicy.canCreate && (
                <CommentForm handleSubmit={handleSubmit} />
            )}
        </>
    )
}

const TabItem: React.FC<{
    current: boolean
    type: CommentListFilters['type']
    handleClick: (type: CommentListFilters['type']) => void
}> = ({ children, current, type, handleClick }) => (
    <span
        className={classNames(
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
            {
                'border-blue-600 text-blue-600': current,
                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    !current,
            }
        )}
        onClick={() => handleClick(type)}
    >
        {children}
    </span>
)

export default OperationCommentForm
