import React from 'react'
import { useDragLayer } from 'react-dnd'

import { DropItem } from 'components/schedule'
import type { OnDrop } from 'types/SchedulePlanner'

const DropUnassignedItem: React.FC<{
    date: Date
    onDrop?: OnDrop
}> = ({ date, onDrop }) => {
    const collectedProps = useDragLayer((monitor) => ({
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
    }))

    if (
        typeof onDrop === 'function' &&
        collectedProps.isDragging &&
        collectedProps.item.prev?.col > 0
    ) {
        return (
            <DropItem
                date={date}
                row={0}
                col={0}
                roomId={undefined}
                planQueryResult={undefined}
                onDrop={onDrop}
            />
        )
    }

    return null
}

export default DropUnassignedItem
