import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Switch } from '@headlessui/react'

export default function DoubleSidedToggle(props: {
    checked: boolean
    disabled?: boolean
    handleChange: (value: boolean) => void
}) {
    const { disabled = false, handleChange } = props
    const [checked, setChecked] = useState<boolean>(props.checked)

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    return (
        <Switch
            checked={checked}
            onChange={(value) => {
                setChecked(value)
                handleChange(value)
            }}
            className={classNames(
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-blue-500',
                { 'opacity-50 pointer-events-none': disabled }
            )}
        >
            <span
                className={classNames(
                    checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            >
                <span
                    className={classNames(
                        checked
                            ? 'opacity-0 ease-out duration-100'
                            : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                ></span>
                <span
                    className={classNames(
                        checked
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                ></span>
            </span>
        </Switch>
    )
}
