import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PROCEDURE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PROCEDURES,
    show: PERMISSIONS.SHOW_PROCEDURES,
    create: PERMISSIONS.CREATE_PROCEDURES,
    update: PERMISSIONS.UPDATE_PROCEDURES,
    delete: PERMISSIONS.DELETE_PROCEDURES,
}

export const useProcedurePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PROCEDURE_PERMISSIONS),
    }
}
