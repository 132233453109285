import React from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const ActionButton: React.FC<{
    children: React.ReactNode
    variant?: 'primary' | 'secondary'
    to?: string
    rightIcon?: React.FC<any>
    handleClick?: () => void
    disabled?: boolean
}> = ({
    children,
    variant = 'primary',
    handleClick = () => {},
    to,
    disabled,
    rightIcon: RightIcon,
}) => {
    const navigate = useNavigate()

    return (
        <button
            className={classNames(
                'inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm leading-5 font-normal rounded-md',
                {
                    'text-white bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500':
                        !disabled && variant === 'primary',
                    'text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-indigo-200':
                        !disabled && variant === 'secondary',
                    ' focus:outline-none focus:ring-2 focus:ring-offset-2 ':
                        !disabled,
                    'bg-gray-200 pointer-events-none text-gray-400': disabled,
                }
            )}
            onClick={to ? () => navigate(to) : handleClick}
        >
            {children}
            {!!RightIcon && (
                <RightIcon
                    className="ml-2 -mr-0.5 h-4 w-4"
                    aria-hidden="true"
                />
            )}
        </button>
    )
}

export default ActionButton
