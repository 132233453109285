import React from 'react'
import classNames from 'classnames'
import { Formik } from 'formik'
import moment from 'moment'
import { XIcon } from '@heroicons/react/solid'

import { formatPrice, TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS } from 'helpers'
import { ActionButton, Pagination, Toggle } from 'components/ui'
import {
    AutoSubmitRow,
    AdmissionTimeDropdown,
    ErrorMessage,
    TimeDropdownItem,
} from 'components/forms'
import EmptyList from 'components/EmptyList'

import type { GetProcedureListResponse, Procedure } from 'api/types'
import type { ProcedureDurationListFilterState } from 'helpers'
import type { FormSubmitFn } from 'types'

interface SettingsProcedureDurationListProps {
    procedures: GetProcedureListResponse
    filters: ProcedureDurationListFilterState
    filtersCount: number
    setFilters: React.Dispatch<any>
    handleUpdate: FormSubmitFn<ProcedureFormValues>
    selectedItems: number[]
    toggleAllCurrentPageRowsSelection: () => void
    checkIsRowSelected: (procedure: Procedure) => boolean
    toggleRowSelection: (procedure: Procedure) => void
    areAllRowsOnCurrentPageSelected: boolean
    unselectAllCurrentPageRows: () => void
}

export type ProcedureFormValues = Pick<Procedure, 'id'> & {
    duration_in_minutes: TimeDropdownItem | undefined
    anesthesia_duration_in_minutes: TimeDropdownItem | undefined
    editable_duration: boolean
}

const SettingsProcedureDurationList: React.FC<
    SettingsProcedureDurationListProps
> = ({
    procedures,
    filters,
    filtersCount,
    setFilters,
    handleUpdate,
    selectedItems,
    toggleAllCurrentPageRowsSelection,
    areAllRowsOnCurrentPageSelected,
    unselectAllCurrentPageRows,
    checkIsRowSelected,
    toggleRowSelection,
}) => {
    return (
        <>
            <div className="bg-white p-2 rounded-lg">
                {procedures.meta.total === 0 && filtersCount === 0 && (
                    <EmptyList
                        headline="Nic tu jeszcze nie ma"
                        message="Zacznij od stworzenia pierwszej operacji."
                        to="/settings/procedures/add"
                    />
                )}
                {procedures.meta.total === 0 && filtersCount > 0 && (
                    <EmptyList
                        headline="Nie znaleziono wyników wyszukiwania"
                        message="Możesz dodać operację."
                        to="/settings/procedures/add"
                    />
                )}
                {procedures.meta.total > 0 && (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Id
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    <CollectiveCheckbox
                                        checked={
                                            areAllRowsOnCurrentPageSelected
                                        }
                                        indeterminate={
                                            selectedItems.length > 0 &&
                                            !areAllRowsOnCurrentPageSelected
                                        }
                                        onChange={
                                            selectedItems.length > 0 &&
                                            !areAllRowsOnCurrentPageSelected
                                                ? unselectAllCurrentPageRows
                                                : toggleAllCurrentPageRowsSelection
                                        }
                                    />
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nazwa
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Kategoria
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Czas operacji
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    + Anest
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    C. dorośli
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    C. dzieci
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Edycja
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                            {procedures.data.map(
                                (procedure, procedureIndex) => (
                                    <Formik
                                        key={procedureIndex}
                                        initialValues={{
                                            id: procedure.id,
                                            duration_in_minutes:
                                                TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS.find(
                                                    (item) =>
                                                        procedure.duration_in_minutes
                                                            ? item.id ===
                                                              convertMinutesToTime(
                                                                  procedure.duration_in_minutes
                                                              )
                                                            : false
                                                ),
                                            anesthesia_duration_in_minutes:
                                                TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS.find(
                                                    (item) =>
                                                        procedure.anesthesia_duration_in_minutes
                                                            ? item.id ===
                                                              convertMinutesToTime(
                                                                  procedure.anesthesia_duration_in_minutes
                                                              )
                                                            : false
                                                ),
                                            editable_duration:
                                                procedure.editable_duration,
                                        }}
                                        enableReinitialize
                                        onSubmit={handleUpdate}
                                    >
                                        {({
                                            isSubmitting,
                                            setFieldValue,
                                            values,
                                            errors,
                                        }) => (
                                            <tr
                                                key={procedureIndex}
                                                className={classNames({
                                                    'bg-gray-50':
                                                        procedureIndex % 2,
                                                    'text-gray-400':
                                                        !values.editable_duration,
                                                })}
                                            >
                                                <td className="px-6 py-6 whitespace-nowrap">
                                                    {procedure.id}
                                                </td>
                                                <td className="px-2 py-2">
                                                    <input
                                                        type="checkbox"
                                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                                        disabled={
                                                            !values.editable_duration
                                                        }
                                                        checked={checkIsRowSelected(
                                                            procedure
                                                        )}
                                                        onChange={() =>
                                                            toggleRowSelection(
                                                                procedure
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td className="px-6 py-6">
                                                    <span
                                                        className={classNames(
                                                            'font-medium',
                                                            {
                                                                'text-gray-900':
                                                                    values.editable_duration,
                                                                'text-gray-400':
                                                                    !values.editable_duration,
                                                            }
                                                        )}
                                                    >
                                                        {procedure.name}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-6">
                                                    <span>
                                                        {
                                                            procedure
                                                                .procedure_category
                                                                ?.name
                                                        }
                                                    </span>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <div style={{ width: 106 }}>
                                                        <AdmissionTimeDropdown
                                                            key={`duration-${
                                                                values
                                                                    .duration_in_minutes
                                                                    ?.id || ''
                                                            }`}
                                                            variant="sm"
                                                            name="duration_in_minutes"
                                                            placeholder="--:--"
                                                            disabled={
                                                                values.editable_duration ===
                                                                false
                                                            }
                                                            items={
                                                                TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS
                                                            }
                                                            hasError={
                                                                'duration' in
                                                                errors
                                                            }
                                                            value={
                                                                values.duration_in_minutes
                                                            }
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'duration_in_minutes',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <ErrorMessage name="duration_in_minutes" />
                                                    </div>
                                                </td>
                                                <td className="px-2 py-1">
                                                    <div style={{ width: 106 }}>
                                                        <AdmissionTimeDropdown
                                                            key={`anesthesia-duration-${
                                                                values
                                                                    .anesthesia_duration_in_minutes
                                                                    ?.id || ''
                                                            }`}
                                                            variant="sm"
                                                            name="anesthesia_duration_in_minutes"
                                                            placeholder="--:--"
                                                            disabled={
                                                                values.editable_duration ===
                                                                false
                                                            }
                                                            items={
                                                                TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS
                                                            }
                                                            hasError={
                                                                'duration' in
                                                                errors
                                                            }
                                                            value={
                                                                values.anesthesia_duration_in_minutes
                                                            }
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'anesthesia_duration_in_minutes',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <ErrorMessage name="anesthesia_duration_in_minutes" />
                                                    </div>
                                                </td>
                                                <td className="px-3 py-3">
                                                    {procedure.adult_cost
                                                        ? formatPrice(
                                                              procedure.adult_cost
                                                          )
                                                        : '-'}
                                                </td>
                                                <td className="px-3 py-3">
                                                    {procedure.children_cost
                                                        ? formatPrice(
                                                              procedure.children_cost
                                                          )
                                                        : '-'}
                                                </td>
                                                <td className="px-3 py-1">
                                                    <Toggle
                                                        checked={
                                                            values.editable_duration
                                                        }
                                                        handleChange={
                                                            !isSubmitting
                                                                ? () =>
                                                                      setFieldValue(
                                                                          'editable_duration',
                                                                          !values.editable_duration
                                                                      )
                                                                : undefined
                                                        }
                                                    />
                                                </td>
                                                <AutoSubmitRow delayMs={150} />
                                            </tr>
                                        )}
                                    </Formik>
                                )
                            )}
                        </tbody>
                    </table>
                )}
                {procedures.meta.total > 0 && (
                    <Pagination
                        meta={procedures.meta}
                        handleChange={(page) =>
                            setFilters({ type: 'setPage', payload: page })
                        }
                    />
                )}
            </div>
        </>
    )
}

interface CollectiveCheckboxProps {
    indeterminate?: boolean
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function CollectiveCheckbox({
    indeterminate = false,
    checked,
    onChange,
}: CollectiveCheckboxProps) {
    const checkboxRef = React.useRef<HTMLInputElement>(null)

    React.useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate
        }
    }, [indeterminate])

    return (
        <input
            type="checkbox"
            ref={checkboxRef}
            className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
            checked={checked}
            onChange={onChange}
        />
    )
}

export const SelectedItemActions: React.FC<{
    assignToProceduresVisible: boolean
    assignToProcedureCategoriesVisible: boolean
    assignToProceduresEnabled: boolean
    assignToProcedureCategoriesEnabled: boolean
    selectedItems: number[]
    unselectAllRows: () => void
    onAssignToProcedures: () => void
    onAssignToProcedureCategories: () => void
}> = ({
    assignToProceduresVisible,
    assignToProcedureCategoriesVisible,
    assignToProceduresEnabled,
    assignToProcedureCategoriesEnabled,
    selectedItems,
    unselectAllRows,
    onAssignToProcedures,
    onAssignToProcedureCategories,
}) => {
    return (
        <>
            <div className="flex items-center ml-1">
                {assignToProceduresVisible && (
                    <span className="ml-3">
                        <span className="font-medium text-xs leading-4 tracking-wide uppercase">
                            Wybrane:
                        </span>
                        <span className="ml-2">
                            <span className="inline-flex text-sm leading-5 items-center text-md px-2 pl-3 py-0.5 bg-red-100 text-red-800 rounded-md">
                                {selectedItems.length}
                                <XIcon
                                    className="ml-1 mt-0.5 h-3.5 w-3.5 cursor-pointer"
                                    style={{ color: '#F87171' }}
                                    onClick={unselectAllRows}
                                />
                            </span>
                        </span>
                    </span>
                )}
                {assignToProcedureCategoriesVisible && (
                    <span className="ml-3">
                        <ActionButton
                            variant="secondary"
                            disabled={!assignToProcedureCategoriesEnabled}
                            handleClick={onAssignToProcedureCategories}
                        >
                            Edytuj wszystkie w kategorii
                        </ActionButton>
                    </span>
                )}
                {assignToProceduresVisible && (
                    <span className="ml-3">
                        <ActionButton
                            disabled={!assignToProceduresEnabled}
                            handleClick={onAssignToProcedures}
                        >
                            Edytuj wybrane
                        </ActionButton>
                    </span>
                )}
            </div>
        </>
    )
}

const convertMinutesToTime = (minutes: number): string => {
    return moment.utc().startOf('day').add(minutes, 'minutes').format('HH:mm')
}

export default SettingsProcedureDurationList
