import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { useCreateComment, useGetComments } from 'api'
import OperationCommentForm from 'components/OperationCommentForm'

import type { CommentListFilters } from 'api/types'
import type { FormSubmitFn } from 'types'
import type { ICommentForm } from 'types/CommentForm'

const OperationCommentFormContainer: React.FC<{
    operationId: number | string
    handleClickTaskId?: (id: number) => void
}> = ({ operationId, handleClickTaskId }) => {
    const queryClient = useQueryClient()
    const [selectedType, setSelectedType] =
        useState<CommentListFilters['type']>('operation')
    const filters: CommentListFilters = {
        type: selectedType,
        operation_id: operationId,
    }

    const comments = useGetComments(filters)
    const { mutate: createComment } = useCreateComment()

    const handleSubmit: FormSubmitFn<ICommentForm> = (
        values,
        formikHelpers
    ) => {
        createComment(
            {
                commentable_id: operationId,
                commentable_type: 'operation',
                ...values,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['comments', filters])
                    formikHelpers.resetForm()
                    formikHelpers.setSubmitting(false)
                    setSelectedType('operation')
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <OperationCommentForm
            comments={comments}
            selectedType={selectedType}
            handleSubmit={handleSubmit}
            handleChangeType={setSelectedType}
            handleClickTaskId={handleClickTaskId}
        />
    )
}

export default OperationCommentFormContainer
