import React from 'react'
import { Formik } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import {
    DATEPICKER_BIRTHDAY_FROM_YEAR,
    DATEPICKER_BIRTHDAY_TO_YEAR,
} from 'constants/index'
import {
    DatePickerField,
    DropdownField,
    DropdownItem,
    TextField,
} from 'components/forms'
import { Button, CancelButton, Label, PhoneInput } from 'components/ui'
import { peselDecode } from 'helpers/pesel'
import { parseDateToFormik } from 'components/forms/DatePickers/utils'

import type { FormSubmitFn } from 'types'
import type { Patient, ResponseData } from 'api/types'
import type { PatientForm } from 'components/DoctorPatientForm'

const genders: DropdownItem[] = [
    {
        id: 1,
        name: 'Kobieta',
    },
    {
        id: 2,
        name: 'Mężczyzna',
    },
]

const GENDER_MAP: Record<string, DropdownItem> = {
    female: genders[0],
    male: genders[1],
}

const DoctorSurgeryPatientForm: React.FC<{
    data?: ResponseData<Patient>
    handleSubmit: FormSubmitFn<any>
    handleCancel?: () => void
}> = ({ data, handleSubmit, handleCancel }) => {
    return (
        <Formik
            initialValues={
                {
                    pesel: data?.data.pesel || '',
                    identity_card: data?.data.identity_card || '',
                    first_name: data?.data?.first_name || '',
                    last_name: data?.data?.last_name || '',
                    date_of_birth: data?.data.date_of_birth || '',
                    gender: data?.data.gender
                        ? GENDER_MAP[data.data.gender]
                        : null,
                    phone: data?.data.phone || '',
                    phone_note: data?.data.phone_note || '',
                    email: data?.data.email || '',
                    address: data?.data?.address || '',
                    post_code: data?.data?.post_code || '',
                    city: data?.data?.city || '',
                } as PatientForm
            }
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                values,
                isSubmitting,
                setFieldValue,
                setFieldError,
                submitForm,
                errors,
            }) => (
                <div className="p-4 bg-gray-50 rounded">
                    <div className="text-gray-700 mb-6">
                        <div className="pr-8">
                            <div className="text-lg leading-6 font-medium text-gray-900">
                                {!!data ? 'Edytuj pacjenta' : 'Dodaj pacjenta'}
                            </div>
                        </div>
                        <div className="mt-9 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <TextField
                                    labelText="PESEL"
                                    name="pesel"
                                    pattern="[0-9]{0,11}"
                                    onChange={(e) => {
                                        const test = peselDecode(e.target.value)

                                        if (test.valid) {
                                            setFieldValue(
                                                'gender',
                                                GENDER_MAP[test.gender]
                                            )

                                            setFieldValue(
                                                'date_of_birth',
                                                parseDateToFormik(test.date)
                                            )
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (
                                            !peselDecode(e.target.value).valid
                                        ) {
                                            setFieldError(
                                                'pesel',
                                                'Numer PESEL jest niepoprawny!'
                                            )
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-span-1">
                                <TextField
                                    labelText="Nr dowodu / paszportu"
                                    name="identity_card"
                                />
                            </div>
                            <div className="col-span-1">
                                <DropdownField
                                    label="Płeć"
                                    name="gender"
                                    items={genders}
                                    disabled={peselDecode(values.pesel).valid}
                                />
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <TextField labelText="Imię" name="first_name" />
                            </div>
                            <div className="col-span-1">
                                <TextField
                                    labelText="Nazwisko"
                                    name="last_name"
                                />
                            </div>
                            <div className="col-span-1">
                                <DatePickerField
                                    labelText="Data urodzenia"
                                    name="date_of_birth"
                                    disabled={peselDecode(values.pesel).valid}
                                    captionLayout="dropdown-buttons"
                                    fromYear={DATEPICKER_BIRTHDAY_FROM_YEAR}
                                    toYear={DATEPICKER_BIRTHDAY_TO_YEAR}
                                />
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <PhoneInput
                                    label="Telefon"
                                    onChange={(value) =>
                                        setFieldValue('phone', value)
                                    }
                                    value={values.phone}
                                    error={errors.phone}
                                />
                            </div>
                            <div className="col-span-1">
                                <TextField
                                    labelText="Uwagi do kontaktu"
                                    name="phone_note"
                                />
                            </div>
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-4">
                            <div className="col-span-1">
                                <TextField labelText="E-mail" name="email" />
                            </div>
                        </div>
                        <div className="mt-4">
                            <Label>Adres</Label>
                            <TextField name="address" />
                        </div>
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div className="col-span-2">
                                <TextField
                                    name="post_code"
                                    placeholder="Kod pocztowy"
                                />
                            </div>
                            <div className="col-span-10">
                                <TextField
                                    name="city"
                                    placeholder="Miejscowość"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <CancelButton as="button" onClick={handleCancel}>
                                Anuluj
                            </CancelButton>
                            <Button
                                type="button"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                                onClick={submitForm}
                                iconRight={<PlusIcon />}
                            >
                                {!!data ? 'Zapisz' : 'Dodaj'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default DoctorSurgeryPatientForm
