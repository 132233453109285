import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import classNames from 'classnames'
import { UseInfiniteQueryResult } from 'react-query'
import { ResponseError, StatisticsDoctorsResponse } from 'api/types'
import UserAvatar from 'components/UserAvatar'
import {
    Item,
    BorderedItem,
    ItemIcon,
    ItemValue,
    ItemRow,
} from 'components/StatisticsDashboard'

import { OczekujeIcon, ZabiegIcon } from 'assets/icons'

const StatisticsDoctors: React.FC<{
    doctorsQueryResult: UseInfiniteQueryResult<
        StatisticsDoctorsResponse,
        ResponseError
    >
}> = ({ doctorsQueryResult }) => {
    const observerRef = useRef(null)
    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                doctorsQueryResult.hasNextPage &&
                !doctorsQueryResult.isFetchingNextPage
            ) {
                doctorsQueryResult.fetchNextPage()
            }
        },
        [doctorsQueryResult]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }
        const element = observerRef.current
        const option = { threshold: 0 }
        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    const doctors = useMemo(
        () => doctorsQueryResult.data?.pages.flatMap((page) => page.data) || [],
        [doctorsQueryResult.data]
    )

    const docktorsNotFound =
        doctorsQueryResult.isSuccess &&
        doctorsQueryResult.data.pages.at(0)?.meta.total === 0

    const statisticsData = useMemo(
        () => doctorsQueryResult.data?.pages[0]?.statistics,
        [doctorsQueryResult.data]
    )

    return (
        <div className="space-y-4">
            <div className="p-5 rounded-md bg-white grid grid-cols-1 gap-5 lg:grid-cols-2 2xl:grid-cols-3">
                <BorderedItem>
                    <Item
                        title="Średni czas zabiegu"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZabiegIcon className="w-7 h-7 text-green-900 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-green-900"
                                bgInner="bg-green-50"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_duration || '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
                <BorderedItem>
                    <Item
                        title="Czas najdłuższej operacji"
                        icon={
                            <ItemIcon
                                icon={
                                    <ZabiegIcon className="w-7 h-7 text-green-900 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-green-900"
                                bgInner="bg-green-50"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.max_duration || '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
                <BorderedItem>
                    <Item
                        title="Średni czas oczekiwania na lekarza"
                        icon={
                            <ItemIcon
                                icon={
                                    <OczekujeIcon className="w-7 h-7 text-gray-900 component-disabled:text-gray-400" />
                                }
                                bgOuter="bg-gray-100"
                                bgInner="bg-gray-300"
                                disabledBgOuter="bg-gray-50"
                                disabledBgInner="bg-gray-200"
                            />
                        }
                        left={
                            <ItemRow>
                                <ItemValue>
                                    {statisticsData?.average_waiting_for_doctor_duration ||
                                        '-'}
                                </ItemValue>
                            </ItemRow>
                        }
                    />
                </BorderedItem>
            </div>

            <div className="p-2 rounded-md bg-white">
                <table>
                    <thead className="bg-gray-50 border-b border-b-gray-200 border-md text-gray-500 uppercase h-10 sticky top-16 z-10">
                        <tr className="h-10">
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Operator</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                            >
                                <span>Kategoria</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                            >
                                <span>Zabieg</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Szacowany
                                    <br />
                                    czas
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Czas
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Średni czas
                                    <br />
                                    szpitala
                                </span>
                            </th>
                        </tr>
                    </thead>
                    {doctorsQueryResult.isSuccess &&
                        doctorsQueryResult.isFetched && (
                            <tbody className="text-sm bg-white divide-y divide-gray-200 leading-5">
                                {doctors.map((doctor, index) => (
                                    <tr
                                        key={`${index}-${doctor.operator.id}-${doctor.category}-${doctor.procedure}`}
                                        className={classNames('h-16', {
                                            'bg-gray-50': index % 2,
                                        })}
                                    >
                                        <td className="px-6 py-1 whitespace-normal 2xl:whitespace-nowrap">
                                            <UserAvatar
                                                variant="name"
                                                data={{
                                                    first_name:
                                                        doctor.operator
                                                            .first_name,
                                                    last_name:
                                                        doctor.operator
                                                            .last_name,
                                                    avatar_url:
                                                        doctor.operator.avatar,
                                                }}
                                                size={8}
                                            />
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>{doctor.category}</span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>{doctor.procedure}</span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span className="whitespace-nowrap">
                                                {doctor.estimated_duration}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span className="whitespace-nowrap">
                                                {doctor.average_duration}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span className="whitespace-nowrap">
                                                {doctor.hospital_average}
                                            </span>
                                        </td>
                                    </tr>
                                ))}

                                {!docktorsNotFound &&
                                    doctorsQueryResult.hasNextPage && (
                                        <tr ref={observerRef}>
                                            <td colSpan={10}>
                                                <div className="flex items-center justify-center h-20">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="animate-spin text-gray-600"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            fillOpacity="0.3"
                                                            d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                                                        />
                                                        <path d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z" />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        )}
                </table>

                {docktorsNotFound && (
                    <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                        <div className="relative h-16" />
                        <div className="relative h-16">
                            <div className="absolute inset-0 h-full flex justify-center items-center">
                                <span>Brak wyników</span>
                            </div>
                            <span>&nbsp;</span>
                        </div>
                        <div className="relative h-16" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StatisticsDoctors
