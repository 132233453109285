export const validatePhoneNumber = (phone: string) => {
    return (
        /^\+?\d{9,15}$/.test(phone) &&
        ![
            '111111111',
            '222222222',
            '333333333',
            '444444444',
            '555555555',
            '666666666',
            '777777777',
            '888888888',
            '999999999',
            '000000000',
            '123456789',
            '987654321',
        ].some((item) => phone.includes(item))
    )
}

export const phoneWithoutPolandPrefix = (phone: string) => {
    return phone.replace('+48', '')
}
