import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'

import axios from 'api/axios'
import {
    GetProcedureTypeResponse,
    GetProcedureTypesResponse,
    PostProcedureType,
    PostProcedureTypeResponse,
    ProcedureTypeTaskRequest,
    ResponseError,
} from 'api/types'

export const getProcedureTypes = <T>(filters?: {}): Promise<T> =>
    axios.get('/procedure-types?' + new URLSearchParams(filters).toString())

export const useGetProcedureTypes = <T = GetProcedureTypesResponse>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['procedure-types', filters],
        () => getProcedureTypes<T>(filters),
        options
    )

export const createProcedureType = (
    data: PostProcedureType
): Promise<PostProcedureTypeResponse> => axios.post('/procedure-types', data)

export const useCreateProcedureType = (): UseMutationResult<
    PostProcedureTypeResponse,
    ResponseError,
    PostProcedureType
> => useMutation((data) => createProcedureType(data))

export const getProcedureType = (
    id: number
): Promise<GetProcedureTypeResponse> => axios.get('/procedure-types/' + id)

export const useGetProcedureType = (
    id: number,
    options?: UseQueryOptions<GetProcedureTypeResponse, ResponseError>
): UseQueryResult<GetProcedureTypeResponse, ResponseError> =>
    useQuery<GetProcedureTypeResponse, ResponseError>(
        ['procedure-types', id],
        () => getProcedureType(id),
        options
    )

export const updateProcedureType = (
    data: PostProcedureTypeResponse
): Promise<PostProcedureTypeResponse> =>
    axios.patch('/procedure-types/' + data.id, data)

export const validateProcedureTypeTask = (data: ProcedureTypeTaskRequest) =>
    axios.post('/procedure-types/task', data)

export const useUpdateProcedureType = (): UseMutationResult<
    PostProcedureTypeResponse,
    ResponseError,
    PostProcedureTypeResponse
> => useMutation((data) => updateProcedureType(data))
