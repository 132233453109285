import React from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { Label, Loader } from 'components/ui'
import { SearchIcon } from '@heroicons/react/solid'

import {
    Procedure,
    ResponseData,
    FormImplantFilters,
    ImplantManufacturer,
} from 'api/types'
import { useGetImplantManufacturers, useGetProcedures } from 'api'
import { DropdownField, CheckboxDropdownField } from 'components/forms'

const SettingsImplantFilterContainer = ({
    filters,
    setFilters,
}: {
    filters: FormImplantFilters
    setFilters: React.Dispatch<React.SetStateAction<FormImplantFilters>>
}) => {
    const procedures = useGetProcedures<ResponseData<Procedure[]>>({
        pagination: false,
    })

    const manufacturers = useGetImplantManufacturers<
        ResponseData<ImplantManufacturer[]>
    >({
        active: true,
        pagination: false,
    })

    if (procedures.isLoading || manufacturers.isLoading) {
        return (
            <div className="mb-4 px-3 py-4 h-24 bg-white rounded-lg">
                <Loader />
            </div>
        )
    }

    if (!procedures.data || !manufacturers.data) {
        return null
    }

    return (
        <Filters
            filters={filters}
            procedures={procedures.data}
            manufacturers={manufacturers.data}
            setFilters={setFilters}
        />
    )
}

const Filters: React.FC<{
    filters: FormImplantFilters
    procedures: ResponseData<Procedure[]>
    manufacturers: ResponseData<ImplantManufacturer[]>
    setFilters: React.Dispatch<React.SetStateAction<FormImplantFilters>>
}> = ({ filters, procedures, manufacturers, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => setFilters(values)}
            >
                {({ handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-4 gap-4">
                            <div>
                                <Label>Szukaj</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="query"
                                        className="block w-full pr-10 appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        onChange={handleChange}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Label>Operacja</Label>
                                <CheckboxDropdownField
                                    name="procedures"
                                    items={procedures.data}
                                />
                            </div>
                            <div>
                                <Label>Producent</Label>
                                <DropdownField
                                    name="manufacturer"
                                    items={manufacturers.data}
                                    bulkOption={true}
                                    bulkOptionName="Wszystko"
                                />
                            </div>
                            <div></div>
                        </div>
                        <AutoSubmitToken filters={filters} />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const AutoSubmitToken = ({ filters }: { filters: FormImplantFilters }) => {
    const { values, submitForm } = useFormikContext()
    React.useEffect(() => {
        if (values !== filters) {
            submitForm()
        }
    }, [values, filters, submitForm])
    return null
}

export default SettingsImplantFilterContainer
