import { useDietPolicy } from 'hooks'

import type { MenuItemGate } from './index'

export const DietListMenuItemGate: MenuItemGate = ({ children }) => {
    const dietPolicy = useDietPolicy()

    if (dietPolicy.canIndex) {
        return children
    }

    return null
}
