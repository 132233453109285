import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { DateRange } from 'react-day-picker'

import { currentRangeDate } from 'components/forms/DatePickers/StatisticsRangeDatePicker'
import { statisticsDoctorFilterSchema } from 'types/StatisticsDoctorList'
import {
    exportStatisticAnesthesiologists,
    useInfiniteStatisticsAnesthesiologists,
} from 'api'
import { useStatisticsPolicy } from 'hooks'
import { downloadFileFactory } from 'helpers'
import { ExportButton, FilterButton, Loader } from 'components/ui'
import { StatisticsRangeDatePicker } from 'components/forms'
import ListLayout from 'layouts/ListLayout'
import StatisticsAnesthesiologists from 'components/StatisticsAnesthesiologists'
import { AccessDenied } from 'components'
import Filters from 'components/StatisticsAnesthesiologistsFilters'

import type { StatisticsAnesthesiologistListFilters } from 'types/StatisticsAnesthesiologistList'

import {
    API_FORMAT_DATE,
    DEFAULT_LIST_PER_PAGE,
    SESSION_STORAGE_STATISTICS_ANESTHESIOLOGISTS_FILTER_KEY,
    SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
} from '../constants'

const initialFilters: StatisticsAnesthesiologistListFilters = {
    length: DEFAULT_LIST_PER_PAGE,
    date_range: {
        from: currentRangeDate().from,
        to: currentRangeDate().to,
    },
    operators: [],
    procedure_types: [],
    procedure: undefined,
    procedure_category: undefined,
}

const transformFiltersToApi = (
    filters: StatisticsAnesthesiologistListFilters
) => {
    return Object.assign(
        {},
        {
            length: filters.length,
        },
        filters.date_range.from && filters.date_range.to
            ? {
                  estimated_date_from: moment(filters.date_range.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.date_range.to).format(
                      API_FORMAT_DATE
                  ),
              }
            : {},
        {
            anesthesiologists: filters.operators
                ? filters.operators.map((item) => item.id)
                : [],
        },
        {
            procedures: filters.procedure ? [filters.procedure.id] : undefined,
        },
        {
            procedure_types: filters.procedure_types
                ? filters.procedure_types.map((item) => item.id)
                : [],
        },
        {
            procedure_categories: filters.procedure_category
                ? [filters.procedure_category.id]
                : [],
        }
    )
}

const StatisticsAnesthesiologistsContainer = () => {
    const statisticsPolicy = useStatisticsPolicy()
    const [dateRange, setDateRange] = useState<DateRange>(currentRangeDate())
    const [isExporting, setIsExporting] = useState(false)
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const [filters, setFilters] =
        useState<StatisticsAnesthesiologistListFilters>(() => {
            const storedFilters = window.sessionStorage.getItem(
                SESSION_STORAGE_STATISTICS_ANESTHESIOLOGISTS_FILTER_KEY
            )

            if (storedFilters) {
                try {
                    const restoredFilters = JSON.parse(storedFilters)

                    const result =
                        statisticsDoctorFilterSchema.safeParse(restoredFilters)

                    if (result.success) {
                        return {
                            ...initialFilters,
                            ...result.data,
                            date_range: {
                                from: currentRangeDate().from,
                                to: currentRangeDate().to,
                            },
                        }
                    }
                } catch (e) {}
            }

            return initialFilters
        })

    const queryResult = useInfiniteStatisticsAnesthesiologists(
        transformFiltersToApi(filters),
        {
            enabled: statisticsPolicy.canSee,
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                    ? lastPage.meta.current_page + 1
                    : undefined
            },
            retry: false,
        }
    )

    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            setFilters((prevState) => ({
                ...prevState,
                date_range: {
                    from: dateRange.from,
                    to: dateRange.to,
                },
            }))
        }
    }, [dateRange])

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_STATISTICS_ANESTHESIOLOGISTS_FILTER_KEY,
            JSON.stringify(filters)
        )
        window.sessionStorage.setItem(
            SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
            JSON.stringify(filters.date_range)
        )
    }, [filters])

    const handleChangeDate = (dateRange: DateRange) => {
        setDateRange(dateRange)
    }

    const filtersCount = useMemo(
        () =>
            (filters.operators?.length ? 1 : 0) +
            (filters.procedure ? 1 : 0) +
            (filters.procedure_types?.length ? 1 : 0) +
            (filters.procedure_category ? 1 : 0),
        [filters]
    )

    const exportXlsx = () => {
        setIsExporting(true)
        downloadFileFactory({
            fetchFunction: () =>
                exportStatisticAnesthesiologists(
                    transformFiltersToApi(filters)
                ).finally(() => setIsExporting(false)),
            fileName: `Statystyki anestezjologów ${moment(
                filters.date_range.from
            ).format('yyyy.MM.DD')}-${moment(filters.date_range.to).format(
                'yyyy.MM.DD'
            )}.xlsx`,
        })
    }

    if (!statisticsPolicy.canSee) {
        return <AccessDenied message="Nie masz dostępu do statystyk" />
    }

    return (
        <ListLayout
            title="Anestezjolodzy"
            actions={
                <div className="flex items-center space-x-4">
                    <ExportButton
                        onClick={exportXlsx}
                        isExporting={isExporting}
                    />
                    <FilterButton
                        count={filtersCount}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                        filtersExpanded={filtersExpanded}
                        handleReset={() =>
                            setFilters((prevState) => ({
                                ...initialFilters,
                                date_range: prevState.date_range,
                            }))
                        }
                    />
                    <span className="text-sm text-gray-700 leading-5 font-medium whitespace-nowrap">
                        Zakres dat:
                    </span>
                    <StatisticsRangeDatePicker
                        dateRange={dateRange}
                        onChange={handleChangeDate}
                    />
                </div>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                {queryResult.isLoading && <Loader />}
                {queryResult.isError && <div>{queryResult.error.message}</div>}
                {queryResult.isSuccess && (
                    <StatisticsAnesthesiologists queryResult={queryResult} />
                )}
            </>
        </ListLayout>
    )
}

export default StatisticsAnesthesiologistsContainer
