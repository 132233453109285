import type { SurgeryForm } from 'types'

export function useSurgeryFormPayer() {
    function payersByProcedure(procedure: SurgeryForm['procedure']) {
        return procedure && procedure.payer_costs
            ? procedure.payer_costs.filter((i) => i.active && i.price !== null)
            : []
    }

    function updatePayerPrice(
        procedure: SurgeryForm['procedure'],
        payer: SurgeryForm['payer']
    ) {
        if (!procedure) {
            return null
        }

        if (payer?.id) {
            const foundPayer = payersByProcedure(procedure).find(
                (item) => item.id === payer!.id
            )

            if (foundPayer) {
                return foundPayer
            }
        }

        return null
    }

    return {
        payersByProcedure,
        updatePayerPrice,
    }
}
