import { PERMISSIONS } from 'constants/index'
import { useCurrentUserPermissions } from './useCurrentUserPermissions'

type ResourcePermission = string | string[] | boolean

interface ResourcePermissions {
    index: ResourcePermission
    show: ResourcePermission
    create: ResourcePermission
    update: ResourcePermission
    delete: ResourcePermission
}

const canNothing = () => ({
    canIndex: false,
    canShow: false,
    canCreate: false,
    canUpdate: false,
    canDelete: false,
    canAll: false,
})

const canEverything = () => ({
    canIndex: true,
    canShow: true,
    canCreate: true,
    canUpdate: true,
    canDelete: true,
    canAll: true,
})

export const usePolicy = () => {
    const { user } = useCurrentUserPermissions()

    const hasPermission = (resourcePermission: string) => {
        return (
            user.is_superadmin || user.permissions.includes(resourcePermission)
        )
    }

    const hasEveryPermissions = (
        requiredResourcePermissions: string[]
    ): boolean =>
        requiredResourcePermissions.every((permission) =>
            hasPermission(permission)
        )

    const checkPermissions = (resourcePermissions: ResourcePermissions) => {
        const can = (action: keyof ResourcePermissions): boolean => {
            if (typeof resourcePermissions[action] === 'boolean') {
                return resourcePermissions[action] as boolean
            }

            if (typeof resourcePermissions[action] === 'string') {
                return hasPermission(resourcePermissions[action] as string)
            }

            return hasEveryPermissions(resourcePermissions[action] as string[])
        }

        if (!user) {
            return canNothing()
        }

        if (user.is_superadmin) {
            return canEverything()
        }

        return {
            canIndex: can('index'),
            canShow: can('show'),
            canCreate: can('create'),
            canUpdate: can('update'),
            canDelete: can('delete'),
            canAll:
                can('index') &&
                can('show') &&
                can('create') &&
                can('update') &&
                can('delete'),
        }
    }

    return {
        user: {
            ...user,
            isSuperAdmin: user.is_superadmin,
            isAdmin: user.permissions.includes(PERMISSIONS.ADMIN_FUNCTION),
            isDoctor: user.permissions.includes(PERMISSIONS.DOCTOR_FUNCTION),
            isPlanner: user.permissions.includes(PERMISSIONS.PLANNER_FUNCTION),
            isCaregiver: user.permissions.includes(
                PERMISSIONS.CAREGIVER_FUNCTION
            ),
            isAnesthesiologist: user.permissions.includes(
                PERMISSIONS.ANESTHESIOLOGIST_FUNCTION
            ),
            isAnesthesiologistNurse: user.permissions.includes(
                PERMISSIONS.ANESTHESIOLOGIST_NURSE_FUNCTION
            ),
            isNurse: user.permissions.includes(PERMISSIONS.NURSE_FUNCTION),
            isOperationNurse: user.permissions.includes(
                PERMISSIONS.OPERATION_NURSE_FUNCTION
            ),
            isTv: user.permissions.includes(PERMISSIONS.TV_FUNCTION),
            isTablet: user.permissions.some((permission) =>
                PERMISSIONS.TABLET_PERMISSIONS.some(
                    (tabletPermission) => tabletPermission === permission
                )
            ),
            isRehabilitator: user.permissions.includes(
                PERMISSIONS.REHABILITATOR_FUNCTION
            ),
        },
        hasPermission,
        checkPermissions,
    }
}
