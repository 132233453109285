import React, { useEffect, useState } from 'react'
import moment from 'moment'
import uniqBy from 'lodash.uniqby'
import classNames from 'classnames'
import { ClockIcon } from '@heroicons/react/outline'

import { getCxFromStyles, displayPatientName, isPatientName } from 'helpers'
import { LiveViewOperationEventStatusContainer } from 'components'

import styles from './LiveViewOperationEvent.module.scss'

import { ReactComponent as IconUser } from 'assets/icon-user.svg'
import { ReactComponent as IconTimeCheck } from 'assets/icon-time-check.svg'
import { ReactComponent as IconTimeEstimate } from 'assets/icon-time-estimate.svg'
import { ReactComponent as IconTimeQuarterPast } from 'assets/icon-time-quarterpast.svg'
import { ReactComponent as IconMedicalTeam } from 'assets/icon-medical-team.svg'

import type { OperationEventEstimatedTime } from 'containers/LiveView'
import type {
    PatientResponse,
    OperationEventListMeta,
    OperationEventResponse,
} from 'api/types'
import type { SurgicalTeamMember } from 'types'

export const LiveViewOperationEventContainer: React.FC<{
    data: OperationEventEstimatedTime
    meta?: OperationEventListMeta
    forTv?: boolean
}> = ({ data, meta, forTv }) => {
    const [isFinishedOperationExpanded, setIsFinishedOperationExpanded] =
        useState(false)

    const doctor = data.surgical_team.find((item) => item.role === 'doctor')

    const surgicalTeam = data.surgical_team.filter(
        (item) => item.role !== 'doctor'
    )

    const equipment = data.equipment.filter((item) =>
        meta?.liveview_visible_equipment_ids.includes(item.id)
    )

    if ('finished' === data.live_view_status) {
        return (
            <div
                className="cursor-pointer"
                onClick={() =>
                    setIsFinishedOperationExpanded((prevState) => !prevState)
                }
            >
                {isFinishedOperationExpanded ? (
                    <LiveViewOperationEvent
                        isLocal={data.is_local}
                        isFinishedAndExpanded
                        status={data.live_view_status}
                        startAt={data.real_operation_start}
                        duration={data.duration_without_cleaning}
                        patient={data.patient}
                        procedure={data.procedure.name}
                        diagnosis={data.diagnosis}
                        description={data.description}
                        equipment={equipment}
                        implants={data.implants}
                        implantsApproved={data.implants_approved}
                        doctor={doctor}
                        surgicalTeam={surgicalTeam}
                        color={data.procedure.color}
                        hasFogging={data.fogging}
                        forTv={forTv}
                        finishAt={data.created_at}
                        schedule={{
                            progress: data.progress,
                            accepted: data.accepted,
                            status: data.status,
                        }}
                    />
                ) : (
                    <LiveViewOperationEvent
                        isFinished
                        schedule={{
                            progress: data.progress,
                            accepted: data.accepted,
                            status: data.status,
                        }}
                        status={data.live_view_status}
                        startAt={data.real_operation_start}
                        finishAt={data.created_at}
                        patient={data.patient}
                        forTv={forTv}
                    />
                )}
            </div>
        )
    }

    if ('awaiting_for_call' === data.live_view_status) {
        return (
            <LiveViewOperationEvent
                isFurther={true}
                schedule={{
                    progress: data.progress,
                    accepted: data.accepted,
                    status: data.schedule_status,
                }}
                status={data.live_view_status}
                startAt={data.operation_estimated_start}
                duration={data.duration_without_cleaning}
                patient={data.patient}
                procedure={data.procedure.name}
                diagnosis={data.diagnosis}
                description={data.description}
                equipment={equipment}
                implants={data.implants}
                implantsApproved={data.implants_approved}
                doctor={doctor}
                surgicalTeam={surgicalTeam}
                color={data.procedure.color}
                hasFogging={data.fogging}
                forTv={forTv}
            />
        )
    }

    if (
        [
            'calling_for_patient',
            'in_transfer_to_block',
            'admitted_to_operating_block',
            'initiation_of_anesthesia',
            'patient_anesthetized',
            'procedure_started',
        ].includes(data.live_view_status)
    ) {
        return (
            <LiveViewOperationEvent
                isLocal={data.is_local}
                isNext={true}
                status={data.live_view_status}
                startAt={data.operation_estimated_start}
                duration={data.duration_without_cleaning}
                patient={data.patient}
                procedure={data.procedure.name}
                diagnosis={data.diagnosis}
                description={data.description}
                equipment={equipment}
                implants={data.implants}
                implantsApproved={data.implants_approved}
                doctor={doctor}
                surgicalTeam={surgicalTeam}
                color={data.procedure.color}
                hasFogging={data.fogging}
                forTv={forTv}
            />
        )
    }

    if ('patient_in_recovery_after_procedure' === data.live_view_status) {
        return (
            <LiveViewOperationEvent
                isCurrent={true}
                status={data.live_view_status}
                patient={data.patient}
                doctor={doctor}
                color={data.procedure.color}
                forTv={forTv}
            />
        )
    }

    if (
        ['ready_for_cleaning', 'cleaning', 'cleaning_completed'].includes(
            data.live_view_status
        )
    ) {
        return (
            <LiveViewOperationEvent
                isCurrent={true}
                isCleaning={true}
                status={data.live_view_status}
                message="Sprzątanie"
                color={data.procedure.color}
                forTv={forTv}
                hasFogging={data.fogging}
            />
        )
    }

    return null
}

const LiveViewOperationEvent: React.FC<{
    status: string
    isFinished?: boolean
    isFinishedAndExpanded?: boolean
    isCurrent?: boolean
    isNext?: boolean
    isFurther?: boolean
    isCleaning?: boolean
    isLocal?: boolean
    startAt?: string
    finishAt?: string
    duration?: number
    patient?: Pick<PatientResponse, 'first_name' | 'last_name' | 'age'>
    procedure?: string
    diagnosis?: string
    description?: string
    equipment?: OperationEventResponse['equipment']
    implants?: OperationEventResponse['implants']
    implantsApproved?: boolean
    doctor?: Pick<SurgicalTeamMember, 'first_name' | 'last_name'>
    surgicalTeam?: Pick<
        SurgicalTeamMember,
        'first_name' | 'last_name' | 'role'
    >[]
    message?: string
    color?: string
    schedule?: {
        status: OperationEventEstimatedTime['status']
        accepted: OperationEventEstimatedTime['accepted']
        progress: OperationEventEstimatedTime['progress']
    }
    forTv?: boolean
    hasFogging?: boolean
}> = ({
    status,
    isFinished,
    isFinishedAndExpanded,
    isCleaning,
    isCurrent,
    isNext,
    isFurther,
    isLocal,
    startAt,
    finishAt,
    duration,
    patient,
    procedure,
    diagnosis,
    description,
    equipment,
    implants,
    implantsApproved,
    doctor,
    surgicalTeam,
    message,
    color,
    schedule,
    forTv,
    hasFogging,
}) => {
    const cxStyles = getCxFromStyles(styles)

    const isCancelledOrRejected =
        schedule?.status === 'canceled' || schedule?.status === 'rejected'

    const [progress, setProgress] = useState<number>()
    const [secondsElapsed, setSecondsElapsed] = useState<number>()

    useEffect(() => {
        if (status !== 'procedure_started' || !startAt || !duration) {
            return
        }

        const secondsElapsed = moment().diff(moment(startAt), 'seconds')
        setSecondsElapsed(secondsElapsed)
        setProgress((secondsElapsed / (duration * 60)) * 100)

        const interval = setInterval(() => {
            const secondsElapsed = moment().diff(moment(startAt), 'seconds')
            setSecondsElapsed(secondsElapsed)
            setProgress((secondsElapsed / (duration * 60)) * 100)
        }, 1000)

        return () => clearInterval(interval)
    }, [startAt, duration, status])

    return (
        <div
            className={cxStyles('event', status, {
                tv: forTv,
                finished: isFinished,
                cancelledOrRejected: isCancelledOrRejected,
                current: isCurrent,
                next: isNext,
                further: isFurther,
                oversize: !!progress && progress > 100,
            })}
        >
            <div className={cxStyles('inner')}>
                {(isFinished || isFinishedAndExpanded) && (
                    <>
                        <div className={cxStyles('patient-container')}>
                            <div className={cxStyles('time')}>
                                {!!startAt
                                    ? moment(startAt).format('HH:mm')
                                    : '-'}
                                -
                                {!!finishAt
                                    ? moment(finishAt).format('HH:mm')
                                    : '-'}
                            </div>
                            {!!patient && <PatientName patient={patient} />}
                        </div>
                        {isCancelledOrRejected && (
                            <div
                                className={cxStyles(
                                    'cancelled-or-rejected-info'
                                )}
                            >
                                {schedule?.status === 'rejected'
                                    ? 'Operacja zrzucona'
                                    : 'Operacja odwołana'}
                            </div>
                        )}
                    </>
                )}
                {isCurrent && (
                    <div className={cxStyles('patient-container')}>
                        {!!patient && (
                            <PatientName patient={patient} displayAge={true} />
                        )}
                        {!!message && (
                            <p className={cxStyles('message')}>{message}</p>
                        )}
                    </div>
                )}
                {isFurther && (
                    <div
                        className={cxStyles(
                            'patient-container',
                            schedule
                                ? {
                                      accepted: schedule.accepted,
                                      [schedule.status]:
                                          !schedule.accepted && schedule.status,
                                      hasProgress:
                                          (schedule.progress?.percent || 0) > 0,
                                  }
                                : {}
                        )}
                    >
                        <div>{moment(startAt).format('HH:mm')}</div>
                        {!!patient && (
                            <PatientName patient={patient} displayAge={true} />
                        )}
                        {typeof duration !== 'undefined' && (
                            <Duration duration={duration} />
                        )}
                    </div>
                )}
                {isNext && (
                    <div className={cxStyles('patient-container')}>
                        <div>{moment(startAt).format('HH:mm')}</div>
                        {!!patient && (
                            <PatientName patient={patient} displayAge={true} />
                        )}
                        {typeof duration !== 'undefined' && (
                            <Duration duration={duration} />
                        )}
                    </div>
                )}
                <div className={cxStyles('content-container')}>
                    {(!!procedure ||
                        !!diagnosis ||
                        !!description ||
                        (!!equipment && equipment.length > 0) ||
                        typeof implants !== 'undefined') && (
                        <div className={cxStyles('operation-detail-list')}>
                            {!!procedure && (
                                <div
                                    className={cxStyles(
                                        'operation-detail-list-item'
                                    )}
                                >
                                    <div
                                        className={cxStyles(
                                            'operation-detail-list-item-label'
                                        )}
                                    >
                                        Rodzaj zabiegu
                                    </div>
                                    <p
                                        className={cxStyles(
                                            'operation-detail-list-item-value'
                                        )}
                                    >
                                        {procedure}
                                    </p>
                                </div>
                            )}
                            {!!diagnosis && (
                                <div
                                    className={cxStyles(
                                        'operation-detail-list-item'
                                    )}
                                >
                                    <div
                                        className={cxStyles(
                                            'operation-detail-list-item-label'
                                        )}
                                    >
                                        Rozpoznanie
                                    </div>
                                    <p
                                        className={cxStyles(
                                            'operation-detail-list-item-value'
                                        )}
                                    >
                                        {diagnosis}
                                    </p>
                                </div>
                            )}
                            {!!description && (
                                <div
                                    className={cxStyles(
                                        'operation-detail-list-item'
                                    )}
                                >
                                    <div
                                        className={cxStyles(
                                            'operation-detail-list-item-label'
                                        )}
                                    >
                                        Uwagi
                                    </div>
                                    <p
                                        className={cxStyles(
                                            'operation-detail-list-item-value',
                                            'description'
                                        )}
                                    >
                                        {description}
                                    </p>
                                </div>
                            )}
                            {!!equipment && equipment.length > 0 && (
                                <div
                                    className={cxStyles(
                                        'operation-detail-list-item'
                                    )}
                                >
                                    <div
                                        className={cxStyles(
                                            'operation-detail-list-item-label'
                                        )}
                                    >
                                        Sprzęt medyczny
                                    </div>
                                    <div
                                        className={cxStyles(
                                            'medical-equipment-list'
                                        )}
                                    >
                                        {equipment.map((item) => (
                                            <span
                                                className={cxStyles(
                                                    'medical-equipment-list-item'
                                                )}
                                            >
                                                {item.name}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {typeof implants !== 'undefined' && (
                                <div
                                    className={cxStyles(
                                        'operation-detail-list-item'
                                    )}
                                >
                                    <div
                                        className={cxStyles(
                                            'operation-detail-list-item-label'
                                        )}
                                    >
                                        Implanty
                                    </div>
                                    <div
                                        className={cxStyles(
                                            'implant-list-status'
                                        )}
                                    >
                                        {implantsApproved ? (
                                            <span
                                                className={cxStyles(
                                                    'implant-list-status-label',
                                                    'text-green-700',
                                                    'ring-green-700'
                                                )}
                                            >
                                                Zamówione
                                            </span>
                                        ) : (
                                            <span
                                                className={cxStyles(
                                                    'implant-list-status-label',
                                                    'text-red-700',
                                                    'ring-red-700'
                                                )}
                                            >
                                                Niezamówione
                                            </span>
                                        )}
                                    </div>
                                    <div className={cxStyles('implant-list')}>
                                        {implants.length > 0 &&
                                            implants
                                                .map(
                                                    (item) =>
                                                        item.label || item.name
                                                )
                                                .map((name, index) => (
                                                    <span
                                                        key={index}
                                                        className={cxStyles(
                                                            'implant-list-item'
                                                        )}
                                                    >
                                                        <svg
                                                            className={classNames(
                                                                'ml-1 mt-2 mr-1.5 h-2 w-2 text-gray-400',
                                                                {
                                                                    '2k:ml-2 2k:mt-3 2k:mr-4 2k:w-4 2k:h-4':
                                                                        forTv,
                                                                }
                                                            )}
                                                            fill="currentColor"
                                                            viewBox="0 0 8 8"
                                                        >
                                                            <circle
                                                                cx={4}
                                                                cy={4}
                                                                r={3}
                                                            />
                                                        </svg>
                                                        {name}
                                                    </span>
                                                ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {isCurrent && !isCleaning && (
                        <div className={cxStyles('doctor')}>
                            <IconUser />
                            {!!doctor && (
                                <span>
                                    {doctor.first_name + ' ' + doctor.last_name}
                                </span>
                            )}
                            {!doctor && <span>Nieprzypisany operator</span>}
                        </div>
                    )}
                    {(isNext || isFurther || isFinishedAndExpanded) && (
                        <>
                            <div className={cxStyles('doctor')}>
                                <IconUser />
                                {!!doctor && (
                                    <span>
                                        {doctor.first_name +
                                            ' ' +
                                            doctor.last_name}
                                    </span>
                                )}
                                {!doctor && <span>Nieprzypisany operator</span>}
                            </div>
                            {!!surgicalTeam && surgicalTeam.length > 0 && (
                                <div className={cxStyles('surgicalTeam')}>
                                    <div className="flex">
                                        <span
                                            className={cxStyles(
                                                'icon-container'
                                            )}
                                        >
                                            <IconMedicalTeam />
                                        </span>
                                        <span className="space-y-1 2k:space-y-2">
                                            {uniqBy(
                                                surgicalTeam,
                                                (item) => item.role
                                            ).map(({ role }) => (
                                                <div>
                                                    {role === 'assistant' && (
                                                        <span>Asysta</span>
                                                    )}
                                                    {role ===
                                                        'anesthesiologist' && (
                                                        <span>
                                                            Anestezjolog
                                                        </span>
                                                    )}
                                                    {role ===
                                                        'anesthesiologist_nurse' && (
                                                        <span>
                                                            Pielęgniarki
                                                            anestez.
                                                        </span>
                                                    )}
                                                    {role ===
                                                        'operation_nurse' && (
                                                        <span>
                                                            Pielęgniarki operac.
                                                        </span>
                                                    )}
                                                    :{' '}
                                                    <span className="text-gray-800 font-medium">
                                                        {surgicalTeam
                                                            ?.filter(
                                                                (item) =>
                                                                    item.role ===
                                                                    role
                                                            )
                                                            .map(
                                                                (item) =>
                                                                    `${item.first_name} ${item.last_name}`
                                                            )
                                                            .join(', ')}
                                                    </span>
                                                </div>
                                            ))}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {hasFogging && (
                        <div className={cxStyles('fogging')}>
                            <span className={cxStyles('fogging-label')}>
                                Zamgławianie
                            </span>
                        </div>
                    )}
                    {(isCurrent || isNext) && (
                        <>
                            {status === 'procedure_started' &&
                                !!startAt &&
                                (!!secondsElapsed || secondsElapsed === 0) && (
                                    <div
                                        className={cxStyles('timer-container')}
                                    >
                                        <div className={cxStyles('left')}>
                                            <div
                                                className={cxStyles(
                                                    'row',
                                                    'row-1'
                                                )}
                                            >
                                                <IconTimeCheck />
                                                <span>
                                                    {moment(startAt).format(
                                                        'HH:mm'
                                                    )}
                                                </span>
                                            </div>
                                            <div
                                                className={cxStyles(
                                                    'row',
                                                    'row-2'
                                                )}
                                            >
                                                <IconTimeEstimate />
                                                <span>
                                                    {moment(startAt)
                                                        .add(
                                                            duration,
                                                            'minutes'
                                                        )
                                                        .format('HH:mm')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={cxStyles('right')}>
                                            <IconTimeQuarterPast />
                                            <div className="ml-4 text-right leading-none">
                                                <p
                                                    className={cxStyles(
                                                        'label'
                                                    )}
                                                >
                                                    Czas operacji:
                                                </p>
                                                <p
                                                    className={cxStyles(
                                                        'timer',
                                                        !!progress &&
                                                            progress > 100 &&
                                                            'text-red-700'
                                                    )}
                                                >
                                                    {(moment
                                                        .duration(
                                                            secondsElapsed *
                                                                1000
                                                        )
                                                        .asHours() | 0 ||
                                                        '00') +
                                                        ':' +
                                                        moment
                                                            .utc(
                                                                secondsElapsed *
                                                                    1000
                                                            )
                                                            .format('mm:ss')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            <div className={cxStyles('progress-container')}>
                                <div
                                    className={cxStyles(
                                        'progress-status-container'
                                    )}
                                >
                                    <LiveViewOperationEventStatusContainer
                                        isLocal={isLocal}
                                        status={status}
                                        progress={progress}
                                        tv={forTv}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

const PatientName: React.FC<{
    patient: Pick<PatientResponse, 'first_name' | 'last_name' | 'age'>
    displayAge?: boolean
}> = ({ patient, displayAge }) => {
    const cxStyles = getCxFromStyles(styles)

    return (
        <div className={cxStyles('patient')}>
            <span>
                {isPatientName(patient) ? displayPatientName(patient) : '-'}
            </span>
            {displayAge && (
                <span>
                    ({patient.age || patient.age === 0 ? patient.age : '-'})
                </span>
            )}
        </div>
    )
}

const Duration: React.FC<{ duration: number }> = ({ duration }) => {
    const cxStyles = getCxFromStyles(styles)

    return (
        <div className={cxStyles('duration-container')}>
            <ClockIcon className={cxStyles('clock-icon')} />
            <span>
                {moment()
                    .startOf('day')
                    .add(duration, 'minutes')
                    .format('HH:mm')}
            </span>
        </div>
    )
}
