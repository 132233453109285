import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
} from 'react-query'

import axios from 'api/axios'

import type {
    OperationTask,
    PostOperationTask,
    UpdateOperationTask,
    ResponseData,
    ResponseError,
} from 'api/types'

export const useGetOperationTask = <T = OperationTask, E = ResponseError>(
    id: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['operation-tasks', id],
        () => axios.get(`/operation-tasks/${id}`),
        options
    )

export const useGetOperationTasks = <T = OperationTask, E = ResponseError>(
    id?: number | string,
    options?: UseQueryOptions<ResponseData<T[]>, E>
) =>
    useQuery<ResponseData<T[]>, E>(
        ['operation-tasks', 'operation', id],
        () => axios.get(`/operation-tasks?operation_id=${id}`),
        options
    )

export const createOperationTask = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/operation-tasks', data)

export const useCreateOperationTask = <
    R1 = Partial<PostOperationTask>,
    R2 = ResponseData<OperationTask>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createOperationTask<R1, R2>(data))

export const updateOperationTask = <R1, R2>(
    id: number | string,
    data: R1
): Promise<R2> => axios.patch('/operation-tasks/' + id, data)

export const useUpdateOperationTask = <
    R1 extends { id: OperationTask['id'] } = Partial<UpdateOperationTask> &
        Pick<OperationTask, 'id'>,
    R2 = ResponseData<OperationTask>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateOperationTask<R1, R2>(data.id, data))

export const useDeleteOperationTask = (): UseMutationResult<
    unknown,
    ResponseError,
    number
> => useMutation((id) => axios.delete('/operation-tasks/' + id))
