import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'constants/index'
import { useLogin } from 'api/endpoints/login'
import Login from 'components/Login'
import Login2FA from 'components/Login2FA'

import type { LoginForm, Login2FAForm, FormSubmitFn } from 'types'

export default function LoginContainer() {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { mutate: login } = useLogin()

    const [is2FA, setIs2FA] = useState(false)
    const [loginData, setLoginData] = useState({ email: '', password: '' })

    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

    if (authToken) {
        return <Navigate to="/" />
    }

    const loginInApp = (token: string) => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token)
        queryClient.removeQueries(['user'])
        navigate('/', { replace: true })
    }

    const onLoginSubmit: FormSubmitFn<LoginForm> = (values, formikHelpers) => {
        login(
            { email: values.email, password: values.password },
            {
                onSuccess: ({ data: { token, twoFactorAuthentication } }) => {
                    token && loginInApp(token)

                    if (twoFactorAuthentication) {
                        setLoginData(values)
                        setIs2FA(true)
                    }
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const onLogin2FASubmit: FormSubmitFn<Login2FAForm> = (
        values,
        formikHelpers
    ) => {
        login(
            {
                email: loginData.email,
                password: loginData.password,
                code: values.code,
            },
            {
                onSuccess: ({ data: { token, twoFactorAuthentication } }) => {
                    token && loginInApp(token)
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return is2FA ? (
        <Login2FA
            onSubmit={onLogin2FASubmit}
            onResend={() =>
                login({
                    email: loginData.email,
                    password: loginData.password,
                    resend_code: true,
                })
            }
        />
    ) : (
        <Login onSubmit={onLoginSubmit} initialValues={loginData} />
    )
}
