import React from 'react'

import UserAvatar from 'components/UserAvatar'

import 'react-popper-tooltip/dist/styles.css'

import type { SurgicalTeamMember } from 'types'

const ScheduleOperationSurgicalTeamList: React.FC<{
    items: SurgicalTeamMember[]
    roles?: string[]
}> = ({
    roles = [
        'doctor',
        'assistant',
        'anesthesiologist',
        'anesthesiologist_nurse',
        'operation_nurse',
    ],
    ...props
}) => {
    const items = props.items.filter(
        (item) => item?.role && roles.includes(item.role)
    )

    if (!items.length) {
        return null
    }

    return (
        <div className="isolate inline-flex -space-x-1">
            {items.map((item, index, all) => (
                <div
                    key={index}
                    className="relative inline-block rounded-full overflow-hidden"
                    style={{
                        zIndex: all.length - index,
                    }}
                >
                    <UserAvatar
                        data={item}
                        size={6}
                        variant="avatar"
                        withTooltip
                    />
                </div>
            ))}
        </div>
    )
}

export default ScheduleOperationSurgicalTeamList
