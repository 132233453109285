import { createContext } from 'react'

import type { ReactElement } from 'react'
import type { AlertType } from 'components/Alert'

export interface Alert {
    content: ReactElement | string
    type: AlertType
}

export interface AlertContext {
    alert: Alert | null
    add: (alert: Alert, dismissTime?: number) => void
    dismiss: () => void
}

export default createContext<AlertContext>({
    alert: null,
    add: (_alert: Alert, _dismissTime?: number) => {},
    dismiss: () => {},
})
