import React from 'react'
import { UseQueryResult } from 'react-query'

import { Loader } from 'components/ui'
import Comment from 'components/Comment'

import type {
    Comment as IComment,
    CommentType,
    ResponseData,
    ResponseError,
} from 'api/types'

const CommentList: React.FC<{
    type?: CommentType
    displayTaskId?: boolean
    comments: UseQueryResult<ResponseData<IComment[]>, ResponseError>
    handleClickTaskId?: (id: number) => void
}> = ({ type = 'operation', displayTaskId, handleClickTaskId, comments }) => {
    return (
        <>
            {comments.isLoading && <Loader />}
            {comments.isSuccess && comments.data.data.length === 0 && (
                <p className="mt-1 text-sm text-gray-500">
                    Nie ma jeszcze żadnych komentarzy.
                </p>
            )}
            {comments.isSuccess &&
                comments.data.data.length > 0 &&
                comments.data.data.map((comment) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        type={type}
                        displayTaskId={displayTaskId}
                        handleClickTaskId={handleClickTaskId}
                    />
                ))}
        </>
    )
}

export default CommentList
