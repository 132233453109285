import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

const Menu3dotsItems: React.FC = ({ children }) => {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="origin-bottom-right absolute right-0 w-44 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                {children}
            </Menu.Items>
        </Transition>
    )
}

export default Menu3dotsItems
