import { usePolicy } from 'hooks'
import { VIEW_NOTIFICATIONS } from 'constants/permissions'

export const useNotificationChannelPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canView: hasPermission(VIEW_NOTIFICATIONS),
    }
}
