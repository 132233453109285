import { DEFAULT_NAVIGATOR_LANGUAGE } from 'constants/index'

function defaultFormatter(options = {}) {
    options = {
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 2,
        ...options,
    }

    return new Intl.NumberFormat(DEFAULT_NAVIGATOR_LANGUAGE, options)
}

export function roundedPrice(value: number) {
    return formatCurrency(Math.round(value), {
        minimumFractionDigits: 0,
    })
}

export function formatCurrency(value: number | string, options = {}) {
    value = Number(value.toString().replace(',', ''))
    return defaultFormatter(options).format(value)
}

export function formatPrice(value: number | string, options = {}) {
    value = Number(value.toString().replace(',', ''))

    return defaultFormatter(options)
        .formatToParts(value)
        .map(({ type, value }) => {
            switch (type) {
                case 'minusSign':
                    return '-'
                case 'integer':
                    return value
                case 'decimal':
                    return value
                case 'fraction':
                    return value
                case 'group':
                    return value
                default:
                    return ''
            }
        })
        .reduce((string, part) => string + part)
}
