import React, { useMemo } from 'react'
import moment from 'moment'

import { useGetOperatingRooms, useGetOperationTimes } from 'api'
import { isDateYesterday } from 'helpers'
import BusinessLiveView from 'components/BusinessLiveView'

type BusinessLiveViewContainerProps = {
    date: Date
    view: 'detail' | 'business'
    canChangeView: boolean
    onViewToggle: () => void
    onDateChange: (date: Date) => void
}

export default function BusinessLiveViewContainer({
    date,
    view,
    canChangeView,
    onViewToggle,
    onDateChange,
}: BusinessLiveViewContainerProps) {
    const canMoveToNextDay = useMemo(() => !isDateYesterday(date), [date])
    const operationsTimesResult = useGetOperationTimes({
        date: moment(date).format('YYYY-MM-DD'),
    })
    const roomsQueryResult = useGetOperatingRooms()

    return (
        <BusinessLiveView
            date={date}
            view={view}
            canMoveToNextDay={canMoveToNextDay}
            rooms={roomsQueryResult.data?.data || []}
            operations={operationsTimesResult.data?.data || []}
            isLoadingRooms={roomsQueryResult.isLoading}
            roomsError={
                roomsQueryResult.isError ? roomsQueryResult.error.message : ''
            }
            isLoadingOperations={operationsTimesResult.isLoading}
            operationsError={
                operationsTimesResult.isError
                    ? operationsTimesResult.error.message
                    : ''
            }
            canChangeView={canChangeView}
            onViewToggle={onViewToggle}
            onDateChange={onDateChange}
        />
    )
}
