import React, { useState } from 'react'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import { Equipment } from 'api/types'
import { formatPrice, formatCurrency } from 'helpers/currencies'
import { InputPrice, InputText, Label } from 'components/ui'
import { PriceField } from 'components/forms'

export interface AdvanceDropdownItem {
    name: Equipment['name']
    price: Equipment['price']
}

const AdvanceField: React.FC<{
    name: string
    editPrice?: boolean
    disabled?: boolean
}> = ({ name, editPrice = false, disabled }) => {
    const [inputTextValue, setInputTextValue] = useState('')
    const [inputPriceValue, setInputPriceValue] = useState('')

    return (
        <FieldArray
            name={name}
            render={({ push, remove, form: { values } }) => (
                <div>
                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <div className="flex-grow w-full md:w-auto">
                            <Label>Opis</Label>
                            <InputText
                                value={inputTextValue}
                                placeholder="Opis"
                                onChange={(e) =>
                                    setInputTextValue(e.target.value)
                                }
                                disabled={disabled}
                            />
                        </div>
                        <div className="flex-shrink-0 w-full mt-4 md:mt-0 md:w-2/12 md:ml-2">
                            <Label>Kwota</Label>
                            <InputPrice
                                value={inputPriceValue}
                                handleChange={(value) =>
                                    setInputPriceValue(value)
                                }
                                disabled={disabled}
                            />
                        </div>
                        <div className="flex-shrink-0 md:ml-2">
                            <Label>&nbsp;</Label>
                            <button
                                type="button"
                                className={classNames(
                                    'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                    {
                                        'opacity-30 pointer-events-none': !(
                                            inputTextValue && inputPriceValue
                                        ),
                                    }
                                )}
                                onClick={() => {
                                    if (!inputTextValue || !inputPriceValue) {
                                        return
                                    }

                                    push({
                                        name: inputTextValue,
                                        price: formatPrice(inputPriceValue)
                                            .replace(',', '.')
                                            .replace(' ', '')
                                            .replace(' ', ''), // NBSP char,
                                    })

                                    setInputTextValue('')
                                    setInputPriceValue('')
                                }}
                            >
                                Dodaj
                                <PlusIcon
                                    className="ml-2 h-3 w-3"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="mt-6">
                        {(values[name] as AdvanceDropdownItem[]).map(
                            (item, index) => (
                                <div
                                    key={index}
                                    className="mb-0.5 px-2 py-1 h-11 flex items-center justify-between bg-gray-100 text-sm text-gray-900 leading-5 font-normal"
                                >
                                    <span className="flex-grow truncate">
                                        {item.name}
                                    </span>
                                    {!editPrice &&
                                        (!!item.price || item.price === 0) && (
                                            <div className="ml-2 flex-shrink-0 flex">
                                                {formatCurrency(item.price)}
                                            </div>
                                        )}
                                    {editPrice && (
                                        <div className="ml-2 flex-shrink-0 flex">
                                            <div style={{ width: '75px' }}>
                                                <PriceField
                                                    name={`${name}.${index}.price`}
                                                    variant="xs"
                                                    value={item.price}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!disabled && (
                                        <div
                                            className="ml-2 flex-shrink-0 flex cursor-pointer"
                                            onClick={() => remove(index)}
                                        >
                                            <span className="text-gray-500 hover:text-gray-700">
                                                <XIcon className="h-5 w-5" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}
        />
    )
}

export default AdvanceField
