import {
    useQuery,
    useMutation,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    AnesthesiologistScheduleItem,
    GetAnesthesiologistSchedule,
    PostAnesthesiologistSchedule,
    ResponseData,
    ResponseError,
} from 'api/types'

export const useGetAnesthesiologistSchedules = <
    F extends GetAnesthesiologistSchedule,
    R extends ResponseData<AnesthesiologistScheduleItem[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['anesthesiologist-schedules', filters],
        () =>
            client.get(
                '/anesthesiologist-schedules' +
                    (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const usePostAnesthesiologistSchedule = <
    R1 extends PostAnesthesiologistSchedule,
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/anesthesiologist-schedules', data))
