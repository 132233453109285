import React from 'react'
import classNames from 'classnames'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import { useResendLinkPolicy } from 'hooks'
import { Button, Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { UserListItem } from 'types'
import type { ResponseList } from 'api/types'
import type { ListFilterAction } from 'helpers'
import type { ResendLinkState } from 'containers/SettingsUserList'

const SettingsUserList: React.FC<{
    data: ResponseList<UserListItem[]>
    filtersCount: number
    setFilters: React.Dispatch<ListFilterAction>
    resendingLink: ResendLinkState
    handleResendLink: (id: number) => void
}> = ({ data, filtersCount, setFilters, resendingLink, handleResendLink }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && (
                <Table
                    data={data}
                    resendingLink={resendingLink}
                    handleResendLink={handleResendLink}
                />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && filtersCount === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego użytkownika."
                    to="/settings/users/add"
                />
            )}
            {data.meta.total === 0 && filtersCount > 0 && (
                <EmptyList
                    headline="Nie znaleziono wyników wyszukiwania"
                    message="Możesz dodać użytkownika."
                    to="/settings/users/add"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<UserListItem[]>
    resendingLink: ResendLinkState
    handleResendLink: (id: number) => void
}> = ({ data, resendingLink, handleResendLink }) => {
    const resendLinkPolicy = useResendLinkPolicy()

    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                <tr>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Id
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Użytkownik
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Specjalizacja
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Rola
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-2 py-3 text-left font-medium tracking-wider"
                    >
                        Link aktywacyjny
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        Opcje
                    </th>
                </tr>
            </thead>
            <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span>{item.id}</span>
                        </td>
                        <td className="px-6 py-6">
                            <span className="text-gray-900 font-medium">
                                {item.first_name} {item.last_name}
                            </span>
                        </td>
                        <td className="px-6 py-6">
                            <span className="text-gray-900">
                                {item.specializations
                                    .map((i) => i.name)
                                    .join(', ')}
                            </span>
                        </td>
                        <td className="px-6 py-6">
                            <span
                                className={classNames(
                                    'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
                                    {
                                        'bg-gray-100 text-gray-800':
                                            index % 2 === 0,
                                        'bg-white text-gray-600': index % 2,
                                    }
                                )}
                            >
                                {item.role_name}
                            </span>
                        </td>
                        <td className="px-2 py-6">
                            <Button
                                variant="default"
                                size="sm"
                                className={classNames({
                                    'pointer-events-none':
                                        resendingLink.state === 'loading' &&
                                        resendingLink.userId !== item.id,
                                })}
                                disabled={!resendLinkPolicy.can}
                                loading={
                                    resendingLink.userId === item.id &&
                                    resendingLink.state === 'loading'
                                }
                                iconRight={
                                    resendingLink.userId === item.id ? (
                                        resendingLink.state === 'success' ? (
                                            <CheckCircleIcon className="text-green-500" />
                                        ) : resendingLink.state === 'error' ? (
                                            <XCircleIcon className="text-red-500" />
                                        ) : undefined
                                    ) : undefined
                                }
                                onClick={() => handleResendLink(item.id)}
                            >
                                <span className="font-medium">Wyślij link</span>
                            </Button>
                        </td>
                        <td className="px-6 py-1 text-right">
                            <span className="font-normal">
                                <Button
                                    as="link"
                                    to={`/settings/users/${item.id}`}
                                    variant="tertiary"
                                    size="sm"
                                >
                                    Edytuj
                                </Button>
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SettingsUserList
