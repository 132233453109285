import React, { useCallback, useMemo, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useGetPatients } from 'api'
import { usePatientPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, FilterButton, Loader } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import DoctorPatientList from 'components/DoctorPatientList'
import Filters from 'components/DoctorPatientListFilter'

export type TDoctorPatientListFilter = {
    query: string
    page: number
    length: number
    sort_by: string
    sort_direction: string
}

const initialFilters: TDoctorPatientListFilter = {
    query: '',
    page: 1,
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'created_at',
    sort_direction: 'desc',
}

const DoctorPatientListContainer: React.FC = () => {
    const { canIndex, canCreate } = usePatientPolicy()

    const [filters, setFilters] = useState(initialFilters)
    const setSorting = useCallback(
        (sort_by: string, sort_direction: string) =>
            setFilters((prevState) => ({
                ...prevState,
                sort_by,
                sort_direction,
                page: 1,
            })),
        [setFilters]
    )
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () => (filters.query.length ? 1 : 0),
        [filters]
    )
    const patients = useGetPatients(filters, {
        enabled: canIndex,
        keepPreviousData: true,
    })

    const setPage = (page: number) => {
        setFilters((prevState) => ({
            ...prevState,
            page,
        }))
    }

    const handleSetFilters = (values: TDoctorPatientListFilter) => {
        setFilters({
            ...values,
            page: 1,
        })
    }

    const handleResetFilters = () => {
        setFilters((prevState) => ({
            ...prevState,
            query: '',
            page: 1,
        }))
        setFiltersExpanded(false)
    }

    if (!canIndex) {
        return (
            <AccessDenied message="Nie masz uprawnień do wyświetlania listy pacjentów" />
        )
    }

    return (
        <ListLayout
            title="Lista pacjentów"
            actions={
                <>
                    <span className="ml-2">
                        <FilterButton
                            count={filtersCount}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                            filtersExpanded={filtersExpanded}
                            handleReset={handleResetFilters}
                        />
                    </span>
                    {canCreate && (
                        <span className="ml-4">
                            <ActionButton
                                to="/patients/add"
                                rightIcon={PlusIcon}
                            >
                                Dodaj pacjenta
                            </ActionButton>
                        </span>
                    )}
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={handleSetFilters} />
                )}
                {patients.isLoading && <Loader />}
                {patients.isSuccess && (
                    <DoctorPatientList
                        data={patients.data}
                        filters={filters}
                        filtersCount={filtersCount}
                        setSorting={setSorting}
                        setPage={setPage}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default DoctorPatientListContainer
