import React from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import {
    DATEPICKER_BIRTHDAY_FROM_YEAR,
    DATEPICKER_BIRTHDAY_TO_YEAR,
} from 'constants/index'
import { Button, CancelButton, Label, PhoneInput } from 'components/ui'
import { peselDecode } from 'helpers/pesel'
import {
    TextField,
    DropdownField,
    DropdownItem,
    DatePickerField,
} from 'components/forms'
import { parseDateToFormik } from 'components/forms/DatePickers/utils'

import type { Patient, ResponseData } from 'api/types'
import type { FormSubmitFn } from 'types'

export interface PatientForm {
    pesel: string
    identity_card: string
    gender: DropdownItem | null
    first_name: string
    last_name: string
    date_of_birth: string
    phone: string
    phone_note: string
    email: string
    address: string
    post_code: string
    city: string
}

interface PatientFormProps {
    data?: ResponseData<Patient>
    handleSubmit: FormSubmitFn<PatientForm>
}

const genders: DropdownItem[] = [
    {
        id: 1,
        name: 'Kobieta',
    },
    {
        id: 2,
        name: 'Mężczyzna',
    },
]

const GENDER_MAP: Record<string, DropdownItem> = {
    female: genders[0],
    male: genders[1],
}

const DoctorPatientForm: React.FC<PatientFormProps> = ({
    data,
    handleSubmit,
}) => {
    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="rounded-md bg-white">
                <div className="divide-gray-200 divide-y">
                    <Formik
                        initialValues={
                            {
                                pesel: data?.data.pesel || '',
                                identity_card: data?.data.identity_card || '',
                                first_name: data?.data?.first_name || '',
                                last_name: data?.data?.last_name || '',
                                date_of_birth: data?.data.date_of_birth || '',
                                gender: data?.data.gender
                                    ? GENDER_MAP[data.data.gender]
                                    : null,
                                phone: data?.data.phone || '',
                                phone_note: data?.data.phone_note || '',
                                email: data?.data.email || '',
                                address: data?.data?.address || '',
                                post_code: data?.data?.post_code || '',
                                city: data?.data?.city || '',
                            } as PatientForm
                        }
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ values, setFieldValue, setFieldError, errors }) => (
                            <Form>
                                <div className="mb-16 pt-12 px-6">
                                    <div className="text-gray-700 mb-16">
                                        <div className="pr-8">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Pacjent
                                            </div>
                                        </div>
                                        <div className="mt-9 grid grid-cols-3 gap-4">
                                            <div className="col-span-1">
                                                <TextField
                                                    labelText="PESEL"
                                                    name="pesel"
                                                    pattern="[0-9]{0,11}"
                                                    onChange={(e) => {
                                                        const test =
                                                            peselDecode(
                                                                e.target.value
                                                            )

                                                        if (test.valid) {
                                                            setFieldValue(
                                                                'gender',
                                                                GENDER_MAP[
                                                                    test.gender
                                                                ]
                                                            )

                                                            setFieldValue(
                                                                'date_of_birth',
                                                                parseDateToFormik(
                                                                    test.date
                                                                )
                                                            )
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        if (
                                                            !peselDecode(
                                                                e.target.value
                                                            ).valid
                                                        ) {
                                                            setFieldError(
                                                                'pesel',
                                                                'Numer PESEL jest niepoprawny!'
                                                            )
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    labelText="Nr dowodu / paszportu"
                                                    name="identity_card"
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <DropdownField
                                                    label="Płeć"
                                                    name="gender"
                                                    items={genders}
                                                    disabled={
                                                        peselDecode(
                                                            values.pesel
                                                        ).valid
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-3 gap-4">
                                            <div className="col-span-1">
                                                <TextField
                                                    labelText="Imię"
                                                    name="first_name"
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    labelText="Nazwisko"
                                                    name="last_name"
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <DatePickerField
                                                    labelText="Data urodzenia"
                                                    name="date_of_birth"
                                                    placeholder="DD.MM.RRRR"
                                                    disabled={
                                                        peselDecode(
                                                            values.pesel
                                                        ).valid
                                                    }
                                                    captionLayout="dropdown-buttons"
                                                    fromYear={
                                                        DATEPICKER_BIRTHDAY_FROM_YEAR
                                                    }
                                                    toYear={
                                                        DATEPICKER_BIRTHDAY_TO_YEAR
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 grid grid-cols-2 gap-4">
                                            <div className="col-span-1">
                                                <PhoneInput
                                                    label="Telefon"
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'phone',
                                                            value
                                                        )
                                                    }
                                                    value={values.phone}
                                                    error={errors.phone}
                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <TextField
                                                    labelText="Uwagi do kontaktu"
                                                    name="phone_note"
                                                />
                                            </div>

                                            <div className="col-span-2">
                                                <TextField
                                                    labelText="E-mail"
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="mt-1 grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <Label>Adres</Label>
                                                    <TextField name="address" />
                                                </div>
                                                <div className="col-span-2">
                                                    <Label>Kod pocztowy</Label>
                                                    <TextField name="post_code" />
                                                </div>
                                                <div className="col-span-4">
                                                    <Label>Miejscowość</Label>
                                                    <TextField name="city" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mx-6 py-12">
                                    <ActionBar />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

const ActionBar = () => {
    const { isSubmitting } = useFormikContext()

    return (
        <div className="px-6">
            <div className="py-12 border-t">
                <div className="flex items-center justify-between">
                    <div className="flex-1"></div>
                    <div className="flex-1 flex items-center justify-end ml-3">
                        <CancelButton as="link" to="/patients">
                            Anuluj
                        </CancelButton>
                        <Button
                            type="submit"
                            variant="primary"
                            className="ml-3"
                            loading={isSubmitting}
                            iconRight={<PlusIcon />}
                        >
                            Zapisz
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorPatientForm
