import React from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'
import classNames from 'classnames'
import { Dialog, RadioGroup } from '@headlessui/react'

import { API_FORMAT_DATE } from 'constants/index'
import {
    forOperationDate,
    getCxFromStyles,
    TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS,
} from 'helpers'
import { Modal } from 'components'
import {
    DatePickerField,
    DropdownItem,
    AdmissionTimeDropdown,
} from 'components/forms'
import { Button, Label } from 'components/ui'

import type { OperationResponse } from 'api/types'
import type { FormSubmitFn, IModal, AdmissionDateModalForm } from 'types'

import styles from 'components/Modal.module.scss'

const OperationAdmissionDateModal: React.FC<{
    modal: IModal
    data: OperationResponse
    handleSubmit: FormSubmitFn<AdmissionDateModalForm>
}> = ({ modal, data, handleSubmit }) => {
    const cx = getCxFromStyles(styles)

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Data i godzina przyjęcia
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        _date: data.admission_date
                            ? moment(data.admission_date).format(
                                  API_FORMAT_DATE
                              )
                            : '',
                        _time: data.admission_date
                            ? ({
                                  id: moment(data.admission_date).format(
                                      'HH:mm'
                                  ),
                                  name: moment(data.admission_date).format(
                                      'HH:mm'
                                  ),
                              } as DropdownItem)
                            : undefined,
                        admission_date: '',
                        patient_informed: !!data.patient_informed,
                    }}
                >
                    {({ isSubmitting, values, errors, setFieldValue }) => {
                        const radioGroupValue = forOperationDate(
                            data.estimated_date
                        ).isDW(values._date)
                            ? 'dw'
                            : forOperationDate(data.estimated_date).isDZ(
                                  values._date
                              )
                            ? 'dz'
                            : undefined

                        return (
                            <Form>
                                <div className="mt-6">
                                    <div className="p-4 bg-gray-100 rounded-md">
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            <div className="col-span-1 md:col-span-2 space-y-4">
                                                <Label>Dzień</Label>
                                                <div className="flex items-center space-x-5">
                                                    <RadioGroup
                                                        value={radioGroupValue}
                                                        className="flex flex-grow space-x-5"
                                                        onChange={(
                                                            kind:
                                                                | 'dz'
                                                                | 'dw'
                                                                | undefined
                                                        ) => {
                                                            setFieldValue(
                                                                '_date',
                                                                forOperationDate(
                                                                    data.estimated_date
                                                                ).absoluteDateFor(
                                                                    kind
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        <div className="flex-grow">
                                                            <RadioGroupOption value="dw">
                                                                DW
                                                            </RadioGroupOption>
                                                        </div>
                                                        <div className="flex-grow">
                                                            <RadioGroupOption value="dz">
                                                                DZ
                                                            </RadioGroupOption>
                                                        </div>
                                                    </RadioGroup>
                                                    <div className="flex-shrink">
                                                        <DatePickerField
                                                            tabIndex={-1}
                                                            name="_date"
                                                            disabledBefore={moment().toDate()}
                                                            disabledAfter={moment(
                                                                data.estimated_date
                                                            ).toDate()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 md:col-span-1 space-y-4">
                                                <Label>Godzina</Label>
                                                <AdmissionTimeDropdown
                                                    tabIndex={-1}
                                                    name="_time"
                                                    placeholder="--:--"
                                                    value={values._time}
                                                    items={
                                                        TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS
                                                    }
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            '_time',
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                        {!!errors?.admission_date && (
                                            <p className="mt-1 text-sm text-red-600">
                                                {errors.admission_date}
                                            </p>
                                        )}

                                        <div className="mt-4">
                                            <div className="flex items-center">
                                                <input
                                                    id="patient-informed"
                                                    tabIndex={-1}
                                                    name="patient_informed"
                                                    type="checkbox"
                                                    className="h-4 w-4 text-blue-500 focus:ring-blue-600 border-gray-300 rounded"
                                                    checked={
                                                        values.patient_informed
                                                    }
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'patient_informed',
                                                            !values.patient_informed
                                                        )
                                                    }}
                                                />
                                                <label
                                                    htmlFor="patient-informed"
                                                    className="ml-2 block text-sm text-gray-700 cursor-pointer"
                                                >
                                                    Pacjent poinformowany
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('action-container')}>
                                    <Button
                                        tabIndex={-1}
                                        variant="default"
                                        type="button"
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        tabIndex={-1}
                                        variant="primary"
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        Zapisz
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Modal>
    )
}

export function RadioGroupOption({
    value,
    disabled,
    children,
}: {
    value: string
    disabled?: boolean
    children: React.ReactNode
}) {
    return (
        <RadioGroup.Option
            value={value}
            className={classNames('outline-none', {
                'pointer-events-none cursor-default': disabled,
                'cursor-pointer': !disabled,
            })}
        >
            {({ checked }) => (
                <RadioGroup.Label
                    tabIndex={-1}
                    className="relative flex items-center cursor-pointer"
                >
                    <span
                        className={classNames(
                            'h-4 w-4 mt-0.5 rounded-full border flex items-center justify-center',
                            {
                                'bg-blue-600 border-transparent': checked,
                                'bg-white border-gray-300': !checked,
                            }
                        )}
                        aria-hidden="true"
                    >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span
                        className={classNames(
                            'ml-3 flex flex-col text-sm font-medium',
                            {
                                'text-gray-400': disabled,
                            }
                        )}
                    >
                        {children}
                    </span>
                </RadioGroup.Label>
            )}
        </RadioGroup.Option>
    )
}

export default OperationAdmissionDateModal
