import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const usePricePolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canSee: hasPermission(PERMISSIONS.SEE_PRICES),
    }
}
