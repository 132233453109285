import { useContext } from 'react'
import { useQueryClient } from 'react-query'

import PusherContext from 'contexts/PusherContext'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { useNotification } from 'hooks/useNotification'

export type CaregiverChannelPayload = {
    operation_id?: number
    task_id?: number
    type: string
    user_id: number
}

export const useCaregiverChannel = () => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { pusher } = useContext(PusherContext)
    const user = useCurrentUser()

    const channelName = 'private-caregiver'
    const eventName = 'operation-changed'

    const onCaregiverChannelEvent = (
        callback: (payload: CaregiverChannelPayload) => void
    ) => {
        const channel = pusher!.current?.subscribe(channelName)

        channel?.bind(eventName, callback)

        return {
            channel,
            closeChannel: () => {
                channel?.unbind(eventName)
                channel?.unsubscribe()
            },
        }
    }

    const handleOperationChange =
        (id?: number) => (payload: CaregiverChannelPayload) => {
            if (user && user.id === payload.user_id) {
                return
            }

            if (id !== payload.operation_id) {
                queryClient.invalidateQueries(['operations', 'index'])
                return
            }

            if ('operation' === payload.type) {
                showNotification({
                    content: 'Inny użytkownik właśnie zaktualizował ten zabieg',
                    type: 'warning',
                })

                queryClient.invalidateQueries(['operations', 'index'])
                queryClient.invalidateQueries([
                    'operations',
                    payload.operation_id,
                ])
            }

            if ('operation-comment' === payload.type) {
                showNotification({
                    content:
                        'Inny użytkownik właśnie dodał komentarz do tego zabiegu',
                    type: 'warning',
                })
                queryClient.invalidateQueries([
                    'operations',
                    payload.operation_id,
                ])
                queryClient.invalidateQueries(['comments'])
            }

            if ('task' === payload.type) {
                showNotification({
                    content:
                        'Inny użytkownik właśnie zaktualizował zadanie do tego zabiegu',
                    type: 'warning',
                })
                queryClient.invalidateQueries(['operations', 'index'])
                queryClient.invalidateQueries([
                    'operations',
                    payload.operation_id,
                ])
                queryClient.invalidateQueries(['operation-tasks'])
            }

            if ('task-comment' === payload.type) {
                showNotification({
                    content:
                        'Inny użytkownik właśnie dodał komentarz do zadania do tego zabiegu',
                    type: 'warning',
                })
                queryClient.invalidateQueries(['comments'])
            }
        }

    return {
        onCaregiverChannelEvent,
        handleOperationChange,
    }
}
