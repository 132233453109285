import React from 'react'

import { getCxFromStyles } from 'helpers'

import {
    GotowyDoZabieguIcon,
    OczekujeIcon,
    OdebraniePacjentaIcon,
    PrzyjecieIcon,
    PrzyjecieNaBlokIcon,
    SprzatanieIcon,
    WezwanieIcon,
    WybudzanieIcon,
    ZabiegIcon,
    ZabiegZakonczonyIcon,
    ZakonczonyZabiegIcon,
    ZnieczulanieIcon,
} from 'assets/icons'

import styles from './LiveViewOperationEventStatus.module.scss'

export const LiveViewOperationEventStatusContainer: React.FC<{
    status: string
    isLocal?: boolean
    progress?: number
    iconRight?: React.ReactElement
    tv?: boolean
}> = ({ status, progress, iconRight, isLocal, tv }) => {
    if (status === 'awaiting_for_call') {
        return (
            <LiveViewOperationEventStatus
                theme="common"
                iconLeft={<OczekujeIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Oczekuje na zabieg
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'calling_for_patient') {
        return (
            <LiveViewOperationEventStatus
                theme="light-orange"
                iconLeft={<WezwanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Wezwanie pacjenta
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'in_transfer_to_block') {
        return (
            <LiveViewOperationEventStatus
                theme="cyan"
                iconLeft={<PrzyjecieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Przyjęte wezwanie
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'admitted_to_operating_block') {
        return (
            <LiveViewOperationEventStatus
                theme="cyan-inverse"
                iconLeft={<PrzyjecieNaBlokIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Przyjęcie na blok
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'initiation_of_anesthesia') {
        return (
            <LiveViewOperationEventStatus
                theme="orange"
                iconLeft={<ZnieczulanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Znieczulenie
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'patient_anesthetized') {
        return (
            <LiveViewOperationEventStatus
                theme="blue"
                iconLeft={<GotowyDoZabieguIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                {isLocal
                    ? 'Gotowy do zabiegu'
                    : 'Znieczulony, gotowy do zabiegu'}
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'procedure_started') {
        return (
            <LiveViewOperationEventStatus
                theme="in-progress"
                progress={progress}
                iconLeft={<ZabiegIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                W trakcie zabiegu
            </LiveViewOperationEventStatus>
        )
    }

    if (
        status === 'patient_in_recovery_after_procedure' ||
        status === 'local_procedure_ended'
    ) {
        return (
            <LiveViewOperationEventStatus
                theme="common"
                iconLeft={<ZakonczonyZabiegIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Zabieg zakończony
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'anesthesia_completed') {
        return (
            <LiveViewOperationEventStatus
                theme="cyan"
                iconLeft={<WybudzanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Wybudzony po zabiegu
            </LiveViewOperationEventStatus>
        )
    }

    if (
        status === 'calling_patient_pickup_from_operating_block' ||
        status === 'calling_patient_pickup_from_operating_room'
    ) {
        return (
            <LiveViewOperationEventStatus
                theme="light-purple2"
                iconLeft={<OdebraniePacjentaIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Oczekiwanie na odbiór pacjenta
            </LiveViewOperationEventStatus>
        )
    }

    if (
        status === 'returning_from_operating_block' ||
        status === 'returning_from_local_procedure'
    ) {
        return (
            <LiveViewOperationEventStatus
                theme="light-purple2"
                iconLeft={<OdebraniePacjentaIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Odbieranie pacjenta
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'patient_left_operating_block') {
        return (
            <LiveViewOperationEventStatus
                theme="light-purple2"
                iconLeft={<OdebraniePacjentaIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Pacjent w drodze do pokoju
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'patient_returned_to_room') {
        return (
            <LiveViewOperationEventStatus
                theme="gray"
                iconLeft={<ZabiegZakonczonyIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Zabieg zakończony
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'ready_for_cleaning') {
        return (
            <LiveViewOperationEventStatus
                theme="light-purple"
                iconLeft={<SprzatanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Oczekuje na sprzątanie
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'cleaning') {
        return (
            <LiveViewOperationEventStatus
                theme="purple"
                iconLeft={<SprzatanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Sprzątanie
            </LiveViewOperationEventStatus>
        )
    }

    if (status === 'cleaning_completed') {
        return (
            <LiveViewOperationEventStatus
                theme="gray"
                iconLeft={<SprzatanieIcon />}
                iconRight={iconRight}
                tv={tv}
            >
                Sprzątanie zakończone
            </LiveViewOperationEventStatus>
        )
    }

    return null
}

const LiveViewOperationEventStatus: React.FC<{
    theme: string
    iconLeft?: React.ReactElement
    iconRight?: React.ReactElement
    progress?: number
    tv?: boolean
}> = ({ theme, iconLeft, iconRight, progress, children, tv }) => {
    const cx = getCxFromStyles(styles)

    return (
        <div
            className={cx('root', theme, {
                tv,
                oversize: !!progress && progress > 100,
            })}
        >
            {!!progress && (
                <div
                    className={cx('progress')}
                    style={{ width: `${progress}%` }}
                />
            )}
            <div className={cx('inner')}>
                {iconLeft && <span className={cx('icon')}>{iconLeft}</span>}
                {children}
                {iconRight && (
                    <span className={cx('iconRight')}>{iconRight}</span>
                )}
            </div>
        </div>
    )
}
