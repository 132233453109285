import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    NotificationIndexRequest,
    NotificationIndexResponse,
    ResponseData,
    ResponseError,
} from 'api/types'

export const useGetNotifications = <
    Request extends NotificationIndexRequest,
    Response extends ResponseData<NotificationIndexResponse>
>(
    filters?: Request,
    options?: UseQueryOptions<Response, ResponseError>
): UseQueryResult<Response, ResponseError> =>
    useQuery<Response, ResponseError>(
        ['notifications', filters],
        () =>
            client.get(
                '/notifications' + (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )
