import React, { useState } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { roundedPrice } from 'helpers'
import { StatisticsOperationResponse } from 'api/types'

const StatisticsSurgeryAdvances: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const [advancesExpanded, setAdvancesExpanded] = useState(true)

    return (
        <div>
            <div className="text-lg leading-6 font-medium flex items-center justify-between">
                Zaliczki
                <button
                    className="px-3"
                    onClick={() => setAdvancesExpanded(!advancesExpanded)}
                >
                    <ChevronDownIcon
                        className={classNames(
                            'w-4 text-blue-500',
                            !advancesExpanded && 'transform rotate-180'
                        )}
                    />
                </button>
            </div>

            {advancesExpanded && (
                <div className="flex flex-col gap-0.5 mt-6">
                    {!data.advance || data.advance.length === 0
                        ? '-'
                        : data.advance?.map((item) => (
                              <div
                                  key={item.name}
                                  className="p-2 bg-gray-100 flex items-center justify-between text-gray-900 text-sm leading-5 font-normal"
                              >
                                  <span>{item.name}</span>
                                  <span className="leading-none py-1.5 px-3 inline-block rounded-md bg-white border border-gray-300 shadow-sm">
                                      {roundedPrice(Number(item.price))}
                                  </span>
                              </div>
                          ))}
                </div>
            )}
        </div>
    )
}

export default StatisticsSurgeryAdvances
