import { useOperationMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const ClosureTemplateMenuItemGate: MenuItemGate = ({ children }) => {
    const operationMenuItemPolicyGroup = useOperationMenuItemPolicyGroup()

    if (operationMenuItemPolicyGroup.canCreateClosureTemplate) {
        return children
    }

    return null
}
