import React from 'react'
import pl from 'date-fns/locale/pl'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { DateFormatter, DateRange } from 'react-day-picker'
import { Options as FocusTrapOptions } from 'focus-trap'
import {
    Locale,
    isMonday,
    isTuesday,
    isWednesday,
    isThursday,
    isFriday,
    isSaturday,
    isValid,
} from 'date-fns'
import type {
    ClassNames,
    CustomComponents,
    Formatters,
    DayModifiers,
    ModifiersClassNames,
} from 'react-day-picker'

import styles from '../DatePicker.module.scss'

const DATE_REGEX = new RegExp('^[0-3][0-9].[0-1][0-9].([1-2]\\d{3})$')

interface DatePickerConfig {
    locale: Locale
    modifiers: DayModifiers
    modifiersClassNames: ModifiersClassNames
    classNames: ClassNames
    components: CustomComponents
    formatters: Partial<Formatters>
}

const formatWeekdayName: DateFormatter = (day) => {
    if (isMonday(day)) return 'Pn'
    if (isTuesday(day)) return 'Wt'
    if (isWednesday(day)) return 'Śr'
    if (isThursday(day)) return 'Cz'
    if (isFriday(day)) return 'Pt'
    if (isSaturday(day)) return 'So'

    return 'Nd'
}

export const getDatePickerConfig = (
    blockedDays: Array<DateRange | Date>,
    modifiers?: DayModifiers
): DatePickerConfig => ({
    locale: pl,
    modifiers: { ...modifiers, unavailableDays: blockedDays },
    modifiersClassNames: {
        selected: styles.selected,
        today: styles.today,
        unavailableDays: styles.blockedDay,
        range_start: styles.rangeStart,
        range_end: styles.rangeEnd,
    },
    classNames: {
        root: styles.dayPickerRoot,
        caption_label: styles.captionLabel,
        head_cell: styles.headCell,
        day: styles.day,
        cell: styles.cell,
        nav_button: styles.navButton,
        caption_start: styles.captionStart,
        caption_end: styles.captionEnd,
        caption: styles.caption,
    },
    components: {
        IconRight: () => <ArrowRightIcon className={styles.navIcon} />,
        IconLeft: () => <ArrowLeftIcon className={styles.navIcon} />,
    },
    formatters: { formatWeekdayName },
})

export const getFocusTrapOptions = (
    onDeactivate: () => void
): FocusTrapOptions => ({
    initialFocus: false,
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    onDeactivate,
})

export const getDateFromUiDateString = (ddmmyyyFormat: string): Date | null => {
    if (ddmmyyyFormat.match(DATE_REGEX)) {
        const dateArr = ddmmyyyFormat.split('.')
        const date = new Date(`${dateArr[1]}.${dateArr[0]}.${dateArr[2]}`)

        if (isValid(date)) {
            return date
        }
    }

    return null
}
