import React from 'react'
import moment from 'moment'

import { useGetNotifications } from 'api'
import { API_FORMAT_DATE } from 'constants/index'

const NotificationListContainer: React.FC<{
    children: ({
        queryResult,
    }: {
        queryResult: ReturnType<typeof useGetNotifications>
    }) => JSX.Element
}> = ({ children }) => {
    const today = moment().format(API_FORMAT_DATE)
    const queryResult = useGetNotifications({
        from: today,
        to: today,
        pagination: false,
    })

    return children({ queryResult })
}

export default NotificationListContainer
