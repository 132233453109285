import React, { useRef } from 'react'
import { Form, Formik } from 'formik'

import { TextAreaField } from 'components/forms'
import SubmitButton from 'components/CommentSubmitButton'

import type { FormikProps } from 'formik'
import type { FormSubmitFn } from 'types'
import type { ICommentForm } from 'types/CommentForm'

const CommentForm: React.FC<{
    handleSubmit: FormSubmitFn<ICommentForm>
}> = ({ handleSubmit }) => {
    const formRef = useRef<FormikProps<ICommentForm>>(null)

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            formRef.current?.submitForm()
        }
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                content: '',
            }}
            onSubmit={handleSubmit}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <div className="mt-4 flex space-x-4">
                        <div className="flex-1 min-w-0">
                            <TextAreaField
                                name="content"
                                placeholder="Twój komentarz"
                                rows={4}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        <div className="inline-flex justify-center">
                            <SubmitButton
                                isSubmitting={isSubmitting}
                                disabled={values.content.length === 0}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default CommentForm
