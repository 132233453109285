import React, { useState } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { StatisticsOperationResponse } from 'api/types'
import { roundedPrice } from '../helpers'

const StatisticsSurgeryImplants: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const [implantsExpanded, setImplantsExpanded] = useState(true)

    return (
        <div>
            <div className="text-lg leading-6 font-medium flex items-center justify-between">
                Wszczepy lub implanty
                <button
                    className="px-3"
                    onClick={() => setImplantsExpanded(!implantsExpanded)}
                >
                    <ChevronDownIcon
                        className={classNames(
                            'w-4 text-blue-500',
                            !implantsExpanded && 'transform rotate-180'
                        )}
                    />
                </button>
            </div>

            {implantsExpanded && (
                <div className="flex flex-col gap-2 mt-6">
                    {!data.implants || data.implants.length === 0
                        ? '-'
                        : data.implants?.map((implant) => (
                              <div
                                  key={implant.name}
                                  className="p-2 bg-gray-100 flex items-center justify-between text-gray-900 text-sm leading-5 font-normal"
                              >
                                  <span>{implant.name}</span>
                                  <span>{roundedPrice(implant.price)}</span>
                              </div>
                          ))}
                </div>
            )}
        </div>
    )
}

export default StatisticsSurgeryImplants
