import React from 'react'
import { Menu } from '@headlessui/react'

import Items from './Menu3dotsItems'
import Item from './Menu3dotsItem'
import RejectOrRenew from 'components/Menu3dotsRejectOrRenew'

type MenuRenderPropArg = {
    open: boolean
}

type Menu3dotsProps = {
    children?: React.ReactNode | ((bag: MenuRenderPropArg) => React.ReactNode)
}

function Menu3dots({ children }: Menu3dotsProps) {
    return (
        <Menu as="div" className="relative">
            {(props) =>
                typeof children === 'function' ? children(props) : children
            }
        </Menu>
    )
}

Menu3dots.Items = Items
Menu3dots.Item = Item
Menu3dots.RejectOrRenew = RejectOrRenew
Menu3dots.Button = Menu.Button

export default Menu3dots
