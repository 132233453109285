import React, { Fragment, useRef, useState } from 'react'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import { useEventListener } from 'hooks'

import styles from './DaysCheckboxDropdown.module.scss'

interface DropdownItem {
    id: string
    name: string
}

interface DropdownProps {
    selectedDays: string[]
    setSelectedDays: (days: string[]) => void
}

const days = [
    { id: 'Mo', name: 'Poniedziałek' },
    { id: 'Tu', name: 'Wtorek' },
    { id: 'We', name: 'Środa' },
    { id: 'Th', name: 'Czwartek' },
    { id: 'Fr', name: 'Piątek' },
    { id: 'Sa', name: 'Sobota' },
    { id: 'Su', name: 'Niedziela' },
]

const DaysCheckboxDropdown = (props: DropdownProps) => {
    const ref = useRef<HTMLDivElement | null>(null)

    const selectedDays = days.filter((d) => props.selectedDays?.includes(d.id))
    const setSelectedDays = (di: DropdownItem[]) =>
        props.setSelectedDays(di.map((d) => d.id))

    const [isOpen, setIsOpen] = useState(false)

    // @ts-ignore
    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEventListener('mousedown', handleClickOutside)

    return (
        <div ref={ref} className={styles.root}>
            <Listbox
                value={selectedDays}
                onChange={(v) => {
                    if (!v) {
                        return setSelectedDays([])
                    }

                    setSelectedDays(
                        selectedDays.find(
                            (fv: DropdownItem) =>
                                fv.id === (v as unknown as DropdownItem).id
                        )
                            ? selectedDays.filter(
                                  (fv: DropdownItem) =>
                                      fv.id !==
                                      (v as unknown as DropdownItem).id
                              )
                            : selectedDays.concat(v)
                    )
                }}
            >
                <div className="relative">
                    <Listbox.Button className="bg-gray-100 relative w-full border border-gray-300 rounded-md text-left focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 text-sm">
                        <div
                            className="cursor-pointer py-2 pl-3 pr-10"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span className="block truncate">
                                {selectedDays.length > 0 &&
                                    selectedDays
                                        .map((item: DropdownItem) => item.name)
                                        .join(', ')}
                                {selectedDays.length === 0 && <span>Dni</span>}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                {!isOpen && (
                                    <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                )}
                                {isOpen && (
                                    <ChevronUpIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                )}
                            </span>
                        </div>
                    </Listbox.Button>

                    <Transition
                        show={isOpen}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md px-1 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200">
                            {days.map((day) => {
                                const selected = !!selectedDays.find(
                                    (fv: DropdownItem) => fv.id === day.id
                                )

                                return (
                                    <Listbox.Option
                                        key={day.id}
                                        className={({ active }) =>
                                            classNames(
                                                {
                                                    'text-white bg-blue-600':
                                                        active,
                                                    'text-gray-900': !active,
                                                },
                                                'cursor-pointer select-none relative py-2 pl-3 pr-3'
                                            )
                                        }
                                        value={day}
                                    >
                                        <div className="relative pl-3 flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    type="checkbox"
                                                    className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded cursor-pointer"
                                                    checked={selected}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="ml-3 text-sm">
                                                <label
                                                    className={classNames(
                                                        'block truncate cursor-pointer',
                                                        {
                                                            'font-medium':
                                                                selected,
                                                            'font-normal':
                                                                !selected,
                                                        }
                                                    )}
                                                >
                                                    <span>{day.name}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </Listbox.Option>
                                )
                            })}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default DaysCheckboxDropdown
