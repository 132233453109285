import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import { DateRange } from 'react-day-picker'
import { format } from 'date-fns'

import { InputText } from 'components/ui'
import { getCxFromStyles } from 'helpers'
import { getDateFromUiDateString } from '../utils'
import { CommonDatePickerInputProps, RangeInputType } from '../interfaces'
import { DATE_FORMAT } from '../consts'

import styles from './DatePickerInput.module.scss'

export interface RangeDatePickerInputProps
    extends Omit<
        CommonDatePickerInputProps,
        'value' | 'onClick' | 'onSelect' | 'onChange'
    > {
    placeholderFrom: string
    placeholderTo: string
    rangeValue?: DateRange
    onClick: (rangeInputType: RangeInputType) => void
    onClear: (rangeInputType: RangeInputType) => void
    openedFrom?: RangeInputType
    onSelect: (date?: DateRange) => void
    onChange: (date?: DateRange) => void
}

const RangeDatePickerInput = React.forwardRef<
    HTMLDivElement | null,
    RangeDatePickerInputProps
>(
    (
        {
            placeholderFrom,
            placeholderTo,
            rangeValue,
            onClick,
            onClear,
            openedFrom,
            disabled,
            hasError,
            active,
            onSelect,
            onChange,
        },
        popperRef
    ) => {
        const cx = getCxFromStyles(styles)

        const [isDateValid, setDateValid] = useState(true)

        const [inputValueFrom, setInputValueFrom] = useState<string>(
            rangeValue?.from ? format(rangeValue.from, DATE_FORMAT) : ''
        )

        const [inputValueTo, setInputValueTo] = useState<string>(
            rangeValue?.to ? format(rangeValue.to, DATE_FORMAT) : ''
        )

        useEffect(() => {
            setInputValueFrom(
                rangeValue?.from ? format(rangeValue?.from, DATE_FORMAT) : ''
            )
        }, [rangeValue?.from])

        useEffect(() => {
            setInputValueTo(
                rangeValue?.to ? format(rangeValue?.to, DATE_FORMAT) : ''
            )
        }, [rangeValue?.to])

        const renderInput = (
            inputType: RangeInputType,
            onInputChange: (date?: Date) => void,
            inputValue: string,
            setInputValue: (date: string) => void,
            rangeValueDate?: Date
        ) => {
            return (
                <div
                    className={cx('inputArea', {
                        active: !disabled && openedFrom === inputType,
                    })}
                    onClick={() => onClick(inputType)}
                >
                    <input
                        className={cx('inputAreaInner', {
                            disabled: disabled,
                            active: !disabled && openedFrom === inputType,
                        })}
                        disabled={disabled}
                        placeholder={
                            inputType === 'from'
                                ? placeholderFrom
                                : placeholderTo
                        }
                        readOnly={true}
                        value={inputValue}
                        onChange={(e) => {
                            let newVal = e.target.value

                            setInputValue(newVal)

                            if (!newVal) {
                                onInputChange()
                                setDateValid(true)
                            } else {
                                const date = getDateFromUiDateString(newVal)
                                if (date) {
                                    onInputChange(date)
                                    setDateValid(true)
                                } else {
                                    setDateValid(false)
                                }
                            }
                        }}
                        onBlur={() => {
                            setInputValue(
                                rangeValueDate
                                    ? format(rangeValueDate, DATE_FORMAT)
                                    : ''
                            )
                            setDateValid(true)
                        }}
                    />
                    {!!rangeValueDate && !disabled && (
                        <XIcon
                            className={styles.clearIcon}
                            aria-hidden="true"
                            aria-label="reset date"
                            onClick={(e) => {
                                e.preventDefault()
                                setInputValue('')
                                onClear(inputType)
                            }}
                        />
                    )}
                </div>
            )
        }

        return (
            <>
                <div
                    ref={popperRef}
                    className={cx('root', {
                        active,
                        hasError,
                        isRange: true,
                        disabled: disabled,
                    })}
                >
                    <InputText
                        className={styles.input}
                        readOnly
                        disabled={disabled}
                        hasError={hasError}
                    />
                    <div className={cx('calendarIcon')}>
                        <CalendarIcon className="h-5 w-5 " />
                    </div>
                    <div className={styles.rootRange}>
                        {renderInput(
                            'from',
                            (date) =>
                                onChange({
                                    to: rangeValue?.to,
                                    from: date,
                                }),
                            inputValueFrom,
                            setInputValueFrom,
                            rangeValue?.from
                        )}
                        {renderInput(
                            'to',
                            (date) =>
                                onChange({
                                    to: date,
                                    from: rangeValue?.from,
                                }),
                            inputValueTo,
                            setInputValueTo,
                            rangeValue?.to
                        )}
                    </div>
                </div>
                {!isDateValid && (
                    <p className="mt-1 text-sm text-gray-500">
                        Wprowadź poprawną datę w formacie DD.MM.RRRR
                    </p>
                )}
            </>
        )
    }
)

export default RangeDatePickerInput
