import React, { useMemo } from 'react'

import { OperationItem, Patient, ResponseData } from 'api/types'
import { formatDate } from 'helpers'

interface PatientFormProps {
    data?: ResponseData<Patient>
    rejected: boolean
    onSelect: (id: number) => void
}

const DoctorPatientSurgeries: React.FC<PatientFormProps> = ({
    data,
    rejected,
    onSelect,
}) => {
    const items = useMemo(
        () =>
            data?.data.operations?.filter((item) =>
                rejected
                    ? item.status === 'rejected'
                    : item.status !== 'rejected'
            ) || [],
        [rejected, data?.data.operations]
    )

    const renderPerson = (name: string, role: string, avatarUrl?: string) => (
        <div className="flex items-center" style={{ width: '300px' }}>
            {avatarUrl ? (
                <img
                    className="h-10 w-10 rounded-full"
                    src={avatarUrl}
                    alt=""
                />
            ) : (
                <svg
                    className="h-10 w-10 rounded-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            )}
            <div className="ml-3">
                <div className="text-gray-900 font-medium text-sm">{name}</div>
                <div className="text-gray-500 text-sm">{role}</div>
            </div>
        </div>
    )

    const renderDescription = (label: string, text: string) => (
        <div className="text-sm text-gray-500">
            {label}
            <span className="ml-4 text-gray-700">{text}</span>
        </div>
    )

    const renderOperationCard = (operation: OperationItem) => (
        <div
            key={operation.id}
            className="overflow-hidden bg-white shadow sm:rounded-lg mt-6"
        >
            <div className="px-6 divide-gray-200 divide-y">
                <div className="flex justify-between items-center">
                    <div className="py-4 text-blue-500 text-xl">
                        {operation.procedure.name}
                    </div>
                    <div className="text-base font-normal text-gray-900">
                        {formatDate(operation.estimated_date)}
                    </div>
                </div>
                <div className="py-4">
                    {renderDescription(
                        'Rozpoznanie:',
                        operation.diagnosis || '-'
                    )}
                    {renderDescription(
                        'Rodzaj zabiegu:',
                        operation.procedure_type.name || '-'
                    )}
                    {renderDescription('Cena:', `${operation.total_cost} zł`)}
                </div>
                <div className="py-4 flex">
                    {operation.operator &&
                        renderPerson(
                            `${operation.operator.first_name} ${operation.operator.last_name}`,
                            'Operator',
                            operation.operator.avatar_url
                        )}
                    {operation.caregiver &&
                        renderPerson(
                            `${operation.caregiver.first_name} ${operation.caregiver.last_name}`,
                            'Opiekun pacjenta',
                            operation.caregiver.avatar_url
                        )}
                </div>
            </div>
            <div
                className="px-6 py-2 bg-gray-50 text-blue-600 cursor-pointer"
                onClick={() => onSelect(operation.id)}
            >
                Szczegóły operacji
            </div>
        </div>
    )

    return (
        <div>
            {items.map(renderOperationCard)}
            {items.length === 0 && (
                <div className="py-6 text-gray-500 text-base">
                    Nie ma jeszcze żadnej operacji.
                </div>
            )}
        </div>
    )
}

export default DoctorPatientSurgeries
