import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useNotification } from 'hooks'
import { getOperationEstimationPdf, useUpdateOperation } from 'api'

export function useOperationActions() {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: update } = useUpdateOperation()

    const [isRestoreMutating, setIsRestoreMutating] = useState(false)
    const [isEstimationPdfDownloading, setIsEstimationPdfDownloading] =
        useState(false)

    const onConfirmRestore = (id: number) => {
        setIsRestoreMutating(true)

        update(
            {
                id,
                data: { restored: false },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    showNotification({
                        content: 'Zmiana została zapisana',
                        type: 'success',
                    })
                    setIsRestoreMutating(false)
                },
                onError: () => {
                    setIsRestoreMutating(false)
                    showNotification({
                        content: 'Nie udało się zapisać zmiany',
                        type: 'danger',
                    })
                },
            }
        )
    }

    const downloadEstimationPdf = useCallback(
        async (id: number) => {
            setIsEstimationPdfDownloading(true)

            try {
                const data = await getOperationEstimationPdf(id)
                window.open(data.data.path, '_blank', 'noreferrer')
                setIsEstimationPdfDownloading(false)
            } catch (error) {
                showNotification({
                    content: 'Nie udało się poberać kosztów operacji',
                    type: 'danger',
                })
                setIsEstimationPdfDownloading(false)
            }
        },
        [showNotification]
    )

    return {
        isRestoreMutating,
        onConfirmRestore,
        downloadEstimationPdf,
        isEstimationPdfDownloading,
    }
}
