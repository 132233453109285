import { usePolicy, useLiveViewPolicy, useOperatingRoomPolicy } from 'hooks'

export const useLiveViewPolicyGroup = () => {
    const { user } = usePolicy()
    const liveViewPolicy = useLiveViewPolicy()
    const operatingRoomPolicy = useOperatingRoomPolicy()

    return {
        user,
        canIndex: liveViewPolicy.canIndex && operatingRoomPolicy.canIndex,
    }
}
