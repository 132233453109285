import React from 'react'
import { CalendarIcon } from '@heroicons/react/solid'

import { formatDate, getCxFromStyles } from 'helpers'

import styles from './OperationTaskDate.module.scss'

const OperationTaskDate: React.FC<{
    className?: string
    date?: string
    status?: string
    placeholder?: string
    onClick?: () => void
}> = ({ className, date, status = '', onClick, placeholder }) => {
    const cx = getCxFromStyles(styles)

    return (
        <span className={cx('root', status, className)} onClick={onClick}>
            <CalendarIcon className="w-5 h-5 mr-1" />{' '}
            {!!date && (
                <span className="font-semibold">{formatDate(date)}</span>
            )}
            {!date && (placeholder || 'Termin')}
        </span>
    )
}

export default OperationTaskDate
