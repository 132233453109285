import React from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'

import { ListFilterAction } from 'helpers'
import { Pagination } from 'components/ui'
import { AutoSubmitRow, PriceField } from 'components/forms'
import EmptyList from 'components/EmptyList'

import type { IModal, FormSubmitFn } from 'types'
import type { Expense, ResponseList } from 'api/types'

export interface FormValues extends Pick<Expense, 'id' | 'price'> {}

const SettingsExpenseList: React.FC<{
    data: ResponseList<Expense[]>
    modal: IModal
    handleUpdatePrice: FormSubmitFn<FormValues>
    handleEdit: (id: number) => void
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, modal, handleUpdatePrice, handleEdit, setFilters }) => {
    return (
        <div className="p-2 bg-white rounded-lg">
            {data.meta.total > 0 && (
                <Table
                    data={data}
                    handleUpdatePrice={handleUpdatePrice}
                    handleEdit={handleEdit}
                />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego kosztu."
                    onClick={modal.openModal}
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<Expense[]>
    handleEdit: (id: number) => void
    handleUpdatePrice: FormSubmitFn<FormValues>
}> = ({ data, handleEdit, handleUpdatePrice }) => (
    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
            <tr>
                <th
                    scope="col"
                    className="w-1 px-6 pr-2 py-3 text-left font-medium tracking-wider whitespace-nowrap"
                >
                    Id
                </th>
                <th
                    scope="col"
                    className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                >
                    Nazwa
                </th>
                <th
                    scope="col"
                    className="w-1/12 px-1 py-3 text-center font-medium tracking-wider"
                >
                    Cena
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                >
                    Opcje
                </th>
            </tr>
        </thead>
        <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
            {data.data.map((item, index) => (
                <tr
                    key={item.id}
                    className={classNames({
                        'bg-gray-50': index % 2,
                    })}
                >
                    <td className="px-6 py-6">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-6">
                        <span className="text-gray-900 font-medium">
                            {item.name}
                        </span>
                    </td>
                    <td className="px-1 py-1 text-center">
                        <Formik
                            key={index}
                            initialValues={{
                                id: item.id,
                                price: item.price,
                            }}
                            enableReinitialize
                            onSubmit={handleUpdatePrice}
                        >
                            {({ isSubmitting }) => (
                                <>
                                    <PriceField
                                        name="price"
                                        disabled={isSubmitting}
                                        variant="sm"
                                        showTextError={false}
                                    />
                                    <AutoSubmitRow />
                                </>
                            )}
                        </Formik>
                    </td>
                    <td className="px-6 py-1 text-right">
                        <span className="font-normal">
                            <span
                                className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                                onClick={() => handleEdit(item.id)}
                            >
                                Edytuj
                            </span>
                        </span>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default SettingsExpenseList
