import {
    usePolicy,
    useProcedurePolicy,
    useImplantPolicy,
    useImplantManufacturePolicy,
} from 'hooks'

export const useImplantPolicyGroup = () => {
    const { user } = usePolicy()
    const procedurePolicy = useProcedurePolicy()
    const implantPolicy = useImplantPolicy()
    const implantManufacturePolicy = useImplantManufacturePolicy()

    return {
        user,
        canAll:
            implantPolicy.canAll &&
            procedurePolicy.canIndex &&
            implantManufacturePolicy.canIndex,
    }
}
