import React, { useContext } from 'react'
import moment from 'moment'

import { Button } from 'components/ui'

import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
} from '@heroicons/react/solid'
import mediaContext from 'contexts/media/mediaContext'

const NavigatorBar: React.FC<{
    date: Date
    onChangeDate: (date: Date) => void
}> = ({ date, onChangeDate, children }) => {
    const { isMobile } = useContext(mediaContext)

    return (
        <div
            id="schedule-navigator-bar"
            className="flex items-center justify-between p-2 h-12 bg-gray-700 rounded-t-md text-white print:hidden"
        >
            <div>
                <Button
                    type="button"
                    variant="tertiary"
                    size="sm"
                    className="p-0"
                    onClick={() =>
                        onChangeDate(moment(date).add(-1, 'days').toDate())
                    }
                    iconLeft={isMobile ? undefined : <ArrowNarrowLeftIcon />}
                >
                    {isMobile ? (
                        <ArrowNarrowLeftIcon className="w-3 h-3" />
                    ) : (
                        'Poprzedni'
                    )}
                </Button>
            </div>
            <div>
                {isMobile && (
                    <span className="text-gray-300">
                        {moment(date).format('DD.MM.YY')}
                    </span>
                )}
            </div>
            <div className="flex items-center">
                <Button
                    type="button"
                    variant="tertiary"
                    size="sm"
                    onClick={() =>
                        onChangeDate(moment(date).add(1, 'days').toDate())
                    }
                    iconRight={isMobile ? undefined : <ArrowNarrowRightIcon />}
                >
                    {isMobile ? (
                        <ArrowNarrowRightIcon className="w-3 h-3" />
                    ) : (
                        'Następny'
                    )}
                </Button>
                {children && <div className="ml-2">{children}</div>}
            </div>
        </div>
    )
}

export default NavigatorBar
