import React from 'react'
import classNames from 'classnames'

const EventItem: React.FC<
    {
        pointerEvents: boolean
        row: number
        col: number
        colStart: number
    } & React.LiHTMLAttributes<HTMLLIElement>
> = ({ pointerEvents = true, colStart, col, row, children, ...props }) => {
    return (
        <li
            className={classNames('mt-px ml-0.5 relative flex', {
                'pointer-events-none': !pointerEvents,
            })}
            style={{
                gridColumnStart: colStart,
                gridRow: `${row} / span ${col}`,
            }}
            {...props}
        >
            {children}
        </li>
    )
}

export default EventItem
