import React from 'react'
import classNames from 'classnames'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import type { DropdownItem } from 'components/forms/interfaces/DropdownItem'

const DropdownButton = <
    T extends DropdownItem,
    E extends React.ElementType = 'button'
>({
    as,
    value,
    isOpen,
    variant,
    placeholder,
    bulkOption,
    disabled,
    hasError,
    onClick,
    renderValue,
    tabIndex,
}: {
    as?: E
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    value?: T
    isOpen?: boolean
    variant?: 'md' | 'lg'
    placeholder?: string
    bulkOption?: boolean
    disabled?: boolean
    hasError?: boolean
    renderValue?: (value: string) => void
    tabIndex?: number
}) => {
    const Component = as || 'button'

    return (
        <Component
            tabIndex={tabIndex}
            type="button"
            onClick={onClick}
            className={classNames(
                'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1',
                {
                    'focus:ring-blue-500 focus:border-blue-500':
                        !disabled && !hasError,
                    'border-red-300 text-red-700 ring-1 ring-offset-0 ring-red-300':
                        !disabled && hasError,
                    'bg-gray-100 pointer-events-none': disabled,
                    'cursor-pointer': !disabled,
                    'text-sm': variant === 'md',
                    'text-base': variant === 'lg',
                    'ring-1 ring-offset-0 ring-blue-500 border-blue-500':
                        !disabled && hasError && isOpen,
                }
            )}
        >
            {typeof renderValue === 'function' ? (
                renderValue(value?.name || '')
            ) : (
                <span className="block truncate">
                    {!!value && value.name}
                    {!value && !bulkOption && !placeholder && (
                        <span>&nbsp;</span>
                    )}
                    {((!value && bulkOption) ||
                        (!value && !bulkOption && !!placeholder)) && (
                        <input
                            placeholder={placeholder}
                            className={classNames(
                                'w-full placeholder-gray-400 outline-none pointer-events-none',
                                {
                                    'bg-gray-100': disabled,
                                }
                            )}
                            readOnly
                        />
                    )}
                </span>
            )}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {!isOpen && (
                    <ChevronDownIcon
                        className={classNames('h-5 w-5', {
                            'text-gray-400': !disabled && !hasError,
                            'text-red-500': !disabled && hasError,
                            'text-gray-300': disabled,
                        })}
                        aria-hidden="true"
                    />
                )}
                {isOpen && (
                    <ChevronUpIcon
                        className={classNames('h-5 w-5 text-gray-400', {
                            'text-red-500': hasError,
                        })}
                        aria-hidden="true"
                    />
                )}
            </span>
        </Component>
    )
}

export default DropdownButton
