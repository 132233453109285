import React, { useContext, useMemo } from 'react'
import moment, { Moment } from 'moment'

import { OPERATING_ROOM_START_HOUR } from 'constants/index'
import {
    DraggableEvent,
    DropUnassignedItem,
    Event,
    EventItem,
} from 'components/schedule'
import { useSchedulePlannerPolicyGroup } from 'hooks'
import mediaContext from 'contexts/media/mediaContext'

import type { OperatingRoom, ScheduleOperationListItem } from 'api/types'
import type { OnDragStart, OnDragStop, OnDrop } from 'types/SchedulePlanner'

const HourlyGrid: React.FC<{
    draggable: boolean
    columnUnassigned: boolean
    date: Moment
    rooms: OperatingRoom[]
    operations?: ScheduleOperationListItem[]
    onSelect: (id: number) => void
    onDragStart?: OnDragStart
    onDragStop?: OnDragStop
    onDrop?: OnDrop
}> = ({
    draggable,
    columnUnassigned,
    date,
    rooms,
    operations,
    onSelect,
    onDragStart,
    onDragStop,
    onDrop,
}) => {
    const { isMobile } = useContext(mediaContext)
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    const { canShow } = schedulePlannerPolicyGroup.schedule

    const unassigned = useMemo(() => {
        if (!operations) {
            return []
        }

        return operations
            .filter((item) => !item.final_operation_date)
            .filter((item) => moment(item.estimated_date).isSame(date, 'day'))
    }, [operations, date])

    const assigned = useMemo(() => {
        if (!operations) {
            return []
        }

        return operations
            .filter((item) => !!item.final_operation_date)
            .filter((item) =>
                moment(item.final_operation_date).isSame(date, 'day')
            )
    }, [operations, date])

    const unassigned7_12H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '7-12'
            ),
        [unassigned]
    )

    const unassigned12_15H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '12-15'
            ),
        [unassigned]
    )

    const unassigned15_19H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '15-19'
            ),
        [unassigned]
    )

    const roomOpeningTime = useMemo(
        () =>
            moment(date).set({
                year: date.get('year'),
                month: date.get('month'),
                day: date.get('day'),
                hour: OPERATING_ROOM_START_HOUR,
                minute: 0,
                second: 0,
            }),
        [date]
    )

    const gridStyleValues = {
        numberOfRows: 68,
        heightRow: 2,
        heightRowUnit: 'rem',
    }

    return (
        <ol
            className="hourly-grid-container grid col-start-1 col-end-2 row-start-1"
            style={{
                gridTemplateRows: 'repeat(68, 2rem)',
                gridTemplateColumns: `repeat(${
                    isMobile ? 1 : rooms.length + (columnUnassigned ? 1 : 0)
                }, minmax(0, 1fr))`,
            }}
        >
            {columnUnassigned && (
                <div
                    className="grid grid-cols-1 h-full overflow-y-auto print:hidden"
                    style={{
                        gridAutoRows: `${gridStyleValues.heightRow}${gridStyleValues.heightRowUnit}`,
                        gridRowStart: '1',
                        gridRowEnd: gridStyleValues.numberOfRows,
                    }}
                >
                    <>
                        {unassigned7_12H
                            .concat(unassigned12_15H)
                            .concat(unassigned15_19H)
                            .map((item, index) => {
                                const isAnonymous = !item.can_see_details

                                if (
                                    draggable &&
                                    schedulePlannerPolicyGroup.canUpdate
                                ) {
                                    if (!item.can_be_moved) {
                                        return (
                                            <EventItem
                                                key={item.id}
                                                row={index * 3 + 1}
                                                col={3}
                                                colStart={1}
                                                pointerEvents={
                                                    canShow && !isAnonymous
                                                }
                                                onClick={() =>
                                                    canShow &&
                                                    !isAnonymous &&
                                                    onSelect(item.id)
                                                }
                                            >
                                                <Event
                                                    item={item}
                                                    col={3}
                                                    colStart={1}
                                                    displayPreferredBeginningHour
                                                />
                                            </EventItem>
                                        )
                                    }

                                    return (
                                        <DraggableEvent
                                            key={item.id}
                                            item={item}
                                            row={index * 3 + 1}
                                            colStart={1}
                                            onDragStart={onDragStart}
                                            onDragStop={onDragStop}
                                        >
                                            {({ isDragging, drag }) => (
                                                <EventItem
                                                    row={index * 3 + 1}
                                                    col={3}
                                                    colStart={1}
                                                    pointerEvents={
                                                        canShow && !isDragging
                                                    }
                                                    onClick={() =>
                                                        canShow &&
                                                        onSelect(item.id)
                                                    }
                                                >
                                                    <Event
                                                        ref={drag}
                                                        item={item}
                                                        col={3}
                                                        colStart={1}
                                                        displayPreferredBeginningHour
                                                        isDraggable={true}
                                                        isDragging={isDragging}
                                                    />
                                                </EventItem>
                                            )}
                                        </DraggableEvent>
                                    )
                                }

                                return (
                                    <EventItem
                                        key={item.id}
                                        row={index * 3 + 1}
                                        col={3}
                                        colStart={1}
                                        pointerEvents={canShow && !isAnonymous}
                                        onClick={() =>
                                            canShow &&
                                            !isAnonymous &&
                                            onSelect(item.id)
                                        }
                                    >
                                        <Event
                                            item={item}
                                            col={3}
                                            colStart={1}
                                            displayPreferredBeginningHour
                                        />
                                    </EventItem>
                                )
                            })}
                        {draggable && (
                            <DropUnassignedItem
                                date={date.toDate()}
                                onDrop={onDrop}
                            />
                        )}
                    </>
                </div>
            )}

            {assigned.map((item) => {
                const isAnonymous = !item.can_see_details

                const roomIndex = rooms.findIndex(
                    (room) => room.id === item.operating_room_id
                )

                const diffMinutes =
                    moment(item.final_operation_date).diff(
                        roomOpeningTime,
                        'minutes'
                    ) + 1
                const row = Math.round(diffMinutes / 15) + 1
                const col = Math.ceil(item.duration / 15)

                if (columnUnassigned) {
                    if (
                        draggable &&
                        schedulePlannerPolicyGroup.canUpdate &&
                        (schedulePlannerPolicyGroup.user.isSuperAdmin ||
                            schedulePlannerPolicyGroup.user.isAdmin ||
                            schedulePlannerPolicyGroup.user.isPlanner)
                    ) {
                        if (!item.can_be_moved) {
                            return (
                                <NotDraggableItem
                                    key={item.id}
                                    item={item}
                                    col={col}
                                    row={row}
                                    roomIndex={roomIndex}
                                    pointerEvents={canShow}
                                    onClick={() => canShow && onSelect(item.id)}
                                />
                            )
                        }

                        return (
                            <DraggableEvent
                                key={item.id}
                                item={item}
                                colStart={roomIndex + 2}
                                row={row}
                                onDragStart={onDragStart}
                                onDragStop={onDragStop}
                            >
                                {({ isDragging, drag }) => (
                                    <EventItem
                                        row={row}
                                        col={col}
                                        colStart={roomIndex + 2}
                                        pointerEvents={canShow && !isDragging}
                                        onClick={() =>
                                            canShow && onSelect(item.id)
                                        }
                                    >
                                        <Event
                                            ref={drag}
                                            item={item}
                                            col={col}
                                            colStart={roomIndex + 2}
                                            isDraggable={true}
                                            isDragging={isDragging}
                                        />
                                    </EventItem>
                                )}
                            </DraggableEvent>
                        )
                    }

                    return (
                        <NotDraggableItem
                            key={item.id}
                            item={item}
                            col={col}
                            row={row}
                            roomIndex={roomIndex}
                            pointerEvents={canShow && !isAnonymous}
                            onClick={() =>
                                canShow && !isAnonymous && onSelect(item.id)
                            }
                        />
                    )
                }

                return (
                    <EventItem
                        key={item.id}
                        pointerEvents={canShow && !isAnonymous}
                        row={row}
                        col={col}
                        colStart={isMobile ? 1 : roomIndex + 1}
                        onClick={() =>
                            canShow && !isAnonymous && onSelect(item.id)
                        }
                    >
                        <Event
                            item={item}
                            col={col}
                            colStart={isMobile ? 1 : roomIndex + 1}
                            colored
                        />
                    </EventItem>
                )
            })}
        </ol>
    )
}

const NotDraggableItem: React.FC<{
    item: ScheduleOperationListItem
    row: number
    col: number
    roomIndex: number
    pointerEvents?: boolean
    onClick: () => void
}> = ({ item, row, col, roomIndex, pointerEvents = true, onClick }) => {
    const { isMobile } = useContext(mediaContext)

    return (
        <EventItem
            pointerEvents={pointerEvents}
            row={row}
            col={col}
            colStart={isMobile ? 1 : roomIndex + 2}
            onClick={onClick}
        >
            <Event
                item={item}
                col={col}
                colStart={isMobile ? 1 : roomIndex + 2}
                isDragging={false}
            />
        </EventItem>
    )
}

export default HourlyGrid
