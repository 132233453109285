import React from 'react'
import { useQueryClient } from 'react-query'

import { useCreateComment, useGetComments } from 'api'
import { useCommentPolicy } from 'hooks'
import CommentList from 'components/CommentList'
import CommentForm from 'components/CommentForm'

import type { CommentListFilters } from 'api/types'
import type { FormSubmitFn } from 'types'
import type { ICommentForm } from 'types/CommentForm'

const OperationTaskCommentFormContainer: React.FC<{
    taskId: number | string
}> = ({ taskId }) => {
    const commentPolicy = useCommentPolicy()
    const queryClient = useQueryClient()
    const filters: CommentListFilters = {
        task_id: taskId,
    }

    const comments = useGetComments(filters)
    const { mutate: createComment } = useCreateComment()

    const handleSubmit: FormSubmitFn<ICommentForm> = (
        values,
        formikHelpers
    ) => {
        createComment(
            {
                commentable_id: taskId,
                commentable_type: 'operation_task',
                ...values,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['comments'])
                    await queryClient.invalidateQueries(['operation-tasks'])
                    formikHelpers.resetForm()
                    formikHelpers.setSubmitting(false)
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <>
            <div className="flex-1">
                <p className="mb-2 text-base font-medium leading-6">
                    Komentarze
                </p>
                <CommentList comments={comments} />
            </div>
            {commentPolicy.canCreate && (
                <CommentForm handleSubmit={handleSubmit} />
            )}
        </>
    )
}

export default OperationTaskCommentFormContainer
