import React, { useState } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { StatisticsOperationResponse } from 'api/types'
import { roundedPrice } from 'helpers'

const StatisticsSurgeryFinancing: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const [financingExpanded, setFinancingExpanded] = useState(true)

    return (
        <div>
            <div className="text-lg leading-6 font-medium flex items-center justify-between">
                Finansowanie
                <button
                    className="px-3"
                    onClick={() => setFinancingExpanded(!financingExpanded)}
                >
                    <ChevronDownIcon
                        className={classNames(
                            'w-4 text-blue-500',
                            !financingExpanded && 'transform rotate-180'
                        )}
                    />
                </button>
            </div>

            {financingExpanded && (
                <>
                    <div className="text-sm leading-5 font-medium text-gray-700 mt-6">
                        Płatnik
                        <div className="py-2 px-3 shadow-sm border border-gray-300 bg-gray-50 rounded-md flex items-center justify-between font-normal text-gray-500">
                            <span className="text-gray-900">
                                {data.payer?.name}
                            </span>
                        </div>
                    </div>

                    <div className="text-sm leading-5 font-medium text-gray-700 mt-6">
                        Cena zabiegu
                        <div className="py-2 px-3 shadow-sm border border-gray-300 bg-gray-50 rounded-md flex items-center justify-between font-normal text-gray-500">
                            <span className="text-gray-900">
                                {data.base_cost &&
                                    roundedPrice(Number(data.base_cost))}
                            </span>
                            <span>zł</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default StatisticsSurgeryFinancing
