import React from 'react'

import { FormSubmitFn, IModal } from 'types'
import { useGetProcedures, useMultiSelect } from 'api'
import { Procedure, ResponseData } from 'api/types'
import SettingsImplantListAssignModal, {
    FormValues,
} from 'components/SettingsImplantListAssignModal'

const SettingsImplantListAssignModalContainer: React.FC<{
    modal: IModal
    ids: number[]
    handleSuccess: () => Promise<void>
}> = ({ handleSuccess, ids, modal }) => {
    const { mutate: assign } = useMultiSelect()
    const procedures = useGetProcedures<ResponseData<Procedure[]>>(
        {
            pagination: false,
        },
        {
            enabled: modal.isOpen,
        }
    )

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        assign(
            {
                model: 'implant',
                action: 'assign-procedure',
                ids: ids,
                payload: {
                    procedures_ids: values.procedures.map((item) => item.id),
                },
            },
            {
                onSuccess: async () => {
                    formikHelpers.setSubmitting(false)
                    await handleSuccess()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <SettingsImplantListAssignModal
            modal={modal}
            handleSubmit={handleSubmit}
            procedures={procedures}
        />
    )
}

export default SettingsImplantListAssignModalContainer
