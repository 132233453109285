import {
    usePolicy,
    usePriceListMenuItemPolicyGroup,
    useProcedureMenuItemPolicyGroup,
    usePermissionMenuItemPolicyGroup,
    useUserMenuItemPolicyGroup,
    useActivityLogPolicy,
} from 'hooks'

export const useSettingsMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const priceListMenuItemPolicyGroup = usePriceListMenuItemPolicyGroup()
    const procedureMenuItemPolicyGroup = useProcedureMenuItemPolicyGroup()
    const permissionMenuItemPolicyGroup = usePermissionMenuItemPolicyGroup()
    const userMenuItemPolicyGroup = useUserMenuItemPolicyGroup()
    const activityLogPolicy = useActivityLogPolicy()

    return {
        user,
        canAny:
            priceListMenuItemPolicyGroup.canAny ||
            procedureMenuItemPolicyGroup.canAny ||
            permissionMenuItemPolicyGroup.canAny ||
            userMenuItemPolicyGroup.canAny ||
            activityLogPolicy.canFilter,
    }
}
