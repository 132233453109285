import React, { Fragment, useState } from 'react'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { Menu, Transition, RadioGroup } from '@headlessui/react'
import {
    ChevronDownIcon,
    CalendarIcon,
    PlusIcon,
    ChevronUpIcon,
} from '@heroicons/react/solid'

import { getRoles } from 'api'
import {
    Button,
    InputText,
    Label,
    RadioGroupLabel,
    RadioGroupOption,
} from 'components/ui'
import {
    TextField,
    TextAreaField,
    RadioGroupField,
    RadioGroupDefaultGrid,
    CheckboxDropdownLazyField,
} from 'components/forms'
import { RADIO_GROUP_YES_NO_OPTIONS } from 'constants/index'

import type { FormSubmitFn } from 'types'
import type { ProcedureTypeTaskForm } from 'api/types'

const GENDER_ITEMS = [
    {
        name: 'Wszyscy',
        value: 'both',
    },
    {
        name: 'Kobiety',
        value: 'female',
    },
    {
        name: 'Mężczyźni',
        value: 'male',
    },
]

const PREDEFINED_DAYS_ITEMS = [
    { name: '3 tygodnie', value: 21 },
    { name: 'Miesiąc', value: 30 },
    { name: '3 miesiące', value: 90 },
]

interface TaskFormProps {
    data?: ProcedureTypeTaskForm
    handleSubmit: FormSubmitFn<ProcedureTypeTaskForm>
    handleDelete: () => void
    handleClose: () => void
}

export default function SettingsProcedureTypeTaskForm({
    data,
    handleSubmit,
    handleDelete,
    handleClose,
}: TaskFormProps) {
    return (
        <Formik
            initialValues={
                {
                    id: data?.id,
                    name: data?.name || '',
                    description: data?.description || '',
                    allow_files: !!data?.allow_files,
                    reminder: data?.reminder || 7,
                    reminder_type: data?.reminder_type || 'operation_date',
                    gender: data?.gender || 'both',
                    roles: data?.roles || [],
                } as ProcedureTypeTaskForm
            }
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className="min-h-0 flex-1 flex flex-col">
                    <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="relative flex-1 px-4 sm:px-6">
                            <div>
                                <Label>Zadanie</Label>
                                <TextField name="name" />
                            </div>
                            <div className="mt-4">
                                <Label>Opis zadania</Label>
                                <TextAreaField name="description" rows={3} />
                            </div>
                            <div className="mt-4 pt-4 border-t">
                                <div className="grid gap-2 grid-cols-3 items-center divide-gray-200 divide-x">
                                    <ReminderField
                                        hintText="Przypomnienie od daty dodania"
                                        value={
                                            values.reminder_type ===
                                            'creation_date'
                                                ? values.reminder
                                                : undefined
                                        }
                                        handleChange={(value) => {
                                            setFieldValue('reminder', value)
                                            setFieldValue(
                                                'reminder_type',
                                                'creation_date'
                                            )
                                        }}
                                    />
                                    <div className="pl-2">
                                        <ReminderField
                                            hintText="Przypomnienie od daty operacji"
                                            value={
                                                values.reminder_type ===
                                                'operation_date'
                                                    ? values.reminder
                                                    : undefined
                                            }
                                            handleChange={(value) => {
                                                setFieldValue('reminder', value)
                                                setFieldValue(
                                                    'reminder_type',
                                                    'operation_date'
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="pl-2">
                                        <CheckboxDropdownLazyField
                                            placeholder="Przypisz rolę"
                                            name="roles"
                                            queryFn={getRoles}
                                            queryFilters={{
                                                task_related_roles: true,
                                            }}
                                            bulkOption={false}
                                            renderToggle={({
                                                isOpen,
                                                toggle,
                                            }) => (
                                                <span
                                                    className="flex rounded-md pl-3 pr-10 py-2 text-left cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                                    onClick={toggle}
                                                >
                                                    <span className="block truncate text-sm text-gray-900">
                                                        {values.roles.length >
                                                            0 &&
                                                            values.roles
                                                                .map(
                                                                    (item) =>
                                                                        item.name
                                                                )
                                                                .join(', ')}
                                                        {values.roles.length ===
                                                            0 && (
                                                            <span className="text-gray-400">
                                                                Przypisz rolę
                                                            </span>
                                                        )}
                                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                            {!isOpen && (
                                                                <ChevronDownIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            {isOpen && (
                                                                <ChevronUpIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                        </span>
                                                    </span>
                                                </span>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 pt-4 border-t">
                                    <Label>
                                        Zezwolić na dodawanie dokumentów?
                                    </Label>
                                    <RadioGroupField<boolean>
                                        name="allow_files"
                                        options={RADIO_GROUP_YES_NO_OPTIONS}
                                        Grid={RadioGroupDefaultGrid}
                                    />
                                </div>
                                <div className="mt-4">
                                    <RadioGroup
                                        value={values.gender}
                                        onChange={(value) =>
                                            setFieldValue('gender', value)
                                        }
                                    >
                                        <Label>Ogranicz do</Label>
                                        <div className="bg-white grid gap-2 grid-cols-3 rounded-md -space-y-px">
                                            {GENDER_ITEMS.map((item, index) => (
                                                <RadioGroupOption
                                                    key={index}
                                                    value={item.value}
                                                >
                                                    {(optionRenderProps) => (
                                                        <RadioGroupLabel
                                                            {...optionRenderProps}
                                                        >
                                                            {item.name}
                                                        </RadioGroupLabel>
                                                    )}
                                                </RadioGroupOption>
                                            ))}
                                        </div>
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0 px-6 py-4 flex justify-between shadow-inner">
                        <div>
                            {!!data && (
                                <Button
                                    type="button"
                                    variant="danger"
                                    onClick={handleDelete}
                                >
                                    Usuń zadanie
                                </Button>
                            )}
                        </div>
                        <div className="flex items-center justify-between">
                            <Button
                                type="button"
                                variant="default"
                                onClick={() => handleClose()}
                            >
                                Anuluj
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {!data && 'Dodaj zadanie'}
                                {data && 'Edytuj zadanie'}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

function ReminderField({
    value,
    hintText,
    handleChange,
}: {
    value: number | undefined
    hintText?: string
    handleChange: (value: number) => void
}) {
    const [otherValue, setOtherValue] = useState<number | undefined>(undefined)

    return (
        <Menu as="div" className="relative">
            <Menu.Button className="inline-flex justify-between w-full rounded-md px-4 py-2 text-sm leading-5 font-medium text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                {(!!value || value === 0) && (
                    <span className="inline-flex text-blue-600">
                        <CalendarIcon className="w-5 h-5 mr-1" /> {value} dni
                    </span>
                )}
                {!value && value !== 0 && (
                    <span className="inline-flex">
                        <CalendarIcon className="w-5 h-5 mr-1" /> -
                    </span>
                )}
                <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 z-10 divide-y divide-gray-200 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-7 py-2 text-gray-400 text-xs leading-4 font-medium tracking-wider uppercase whitespace-nowrap">
                        {hintText}
                    </div>
                    <div className="pb-1">
                        <div className="divide-y divide-gray-200">
                            {PREDEFINED_DAYS_ITEMS.map((item, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <span
                                            onClick={() => {
                                                handleChange(item.value)
                                                setOtherValue(undefined)
                                            }}
                                            className={classNames(
                                                item.value === value
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'block px-7 py-2 text-sm leading-5 cursor-pointer'
                                            )}
                                        >
                                            {item.name}
                                        </span>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                        <div className="px-7 py-1 flex border-t border-b border-gray-200">
                            <div className="flex-1">
                                <InputText
                                    variant="sm"
                                    name="other"
                                    defaultValue={otherValue}
                                    type="number"
                                    pattern="[1-9][0-9]+"
                                    placeholder="Liczba dni (min. 0 dni)"
                                    min={0}
                                    max={1000}
                                    handleChange={(e) =>
                                        setOtherValue(Number(e.target.value))
                                    }
                                />
                            </div>
                            <div className="flex px-2 items-center">
                                <PlusIcon
                                    className={classNames(
                                        'w-4 h-4 text-blue-500',
                                        {
                                            'pointer-events-none opacity-50':
                                                isNaN(Number(otherValue)),
                                            'cursor-pointer': !isNaN(
                                                Number(otherValue)
                                            ),
                                        }
                                    )}
                                    onClick={() =>
                                        handleChange(Number(otherValue))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
