import React, { useMemo } from 'react'
import moment from 'moment'
import { ThumbUpIcon } from '@heroicons/react/solid'

import { ANESTHESIA_TYPES } from 'constants/index'
import { StatisticsOperationResponse } from 'api/types'
import { displayAdmissionDate, formatDuration, formatTime } from 'helpers'
import { Toggle } from 'components/ui'

const StatisticsSurgeryInfo: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const anesthesiaType = useMemo(
        () => ANESTHESIA_TYPES.find((item) => item.id === data.anesthesia_type),
        [data.anesthesia_type]
    )

    return (
        <div>
            <div className="flex items-center justify-between gap-6 mb-3">
                <div className="text-2xl leading-8 font-medium text-gray-800">
                    {data.patient.first_name} {data.patient.last_name}
                    <span className="ml-2 text-gray-500">
                        ({data.patient.age})
                    </span>
                </div>

                <div className="flex items-center gap-2 text-sm leading-5 font-medium">
                    <Toggle
                        checked={data.additional_bed}
                        handleChange={() => null}
                        disabled
                    />
                    Dziecko
                </div>
            </div>

            <div>
                <div className="text-base leading-6 font-medium flex items-center h-14 text-gray-900">
                    Planowane
                </div>

                <ul className="border-b border-gray-200 flex justify-between flex-wrap w-full">
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">
                            Rozpoczęcie operacji
                        </span>
                        <span className="text-gray-900">
                            {data.final_operation_date
                                ? moment(data.final_operation_date).format(
                                      'HH:mm'
                                  )
                                : '-'}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">
                            Zakończenie operacji
                        </span>
                        <span className="text-gray-900">
                            {data.finish_at
                                ? moment(data.finish_at).format('HH:mm')
                                : '-'}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: '100%',
                        }}
                    >
                        <span className="text-gray-500">Szacowany czas</span>
                        <span className="text-gray-900">
                            {data.estimated_duration}
                        </span>
                    </li>
                </ul>
            </div>

            <div>
                <div className="text-base leading-6 font-medium flex items-center h-14 text-gray-900">
                    Wykonane
                </div>

                <ul className="border-b border-gray-200 flex justify-between flex-wrap w-full">
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">Data zabiegu</span>
                        <span className="text-gray-900">
                            {moment(data.estimated_date).format('DD.MM.YYYY')}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">Czas zabiegu</span>
                        <span className="text-gray-900">
                            {data.real_operation_start &&
                            data.real_operation_end
                                ? formatDuration(
                                      moment(data.real_operation_start),
                                      moment(data.real_operation_end)
                                  )
                                : '-'}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">
                            Rozpoczęcie operacji
                        </span>
                        <span className="text-gray-900">
                            {data.real_operation_start
                                ? formatTime(data.real_operation_start)
                                : '-'}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: 'calc(50% - 24px)',
                        }}
                    >
                        <span className="text-gray-500">
                            Zakończenie operacji
                        </span>
                        <span className="text-gray-900">
                            {data.real_operation_end
                                ? formatTime(data.real_operation_end)
                                : '-'}
                        </span>
                    </li>
                    <li
                        className="border-t border-gray-200 flex items-center justify-between gap-4 text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                            width: '100%',
                        }}
                    >
                        <span className="text-gray-500">Sala</span>
                        <span className="text-gray-900">
                            {data.operating_room?.name || '-'}
                        </span>
                    </li>
                </ul>
            </div>

            <div>
                <div className="text-base leading-6 font-medium flex items-center h-14 text-gray-900">
                    Opis
                </div>

                <ul className="border-b border-gray-200 flex flex-col">
                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">
                            Rodzaj zabiegu
                        </span>
                        <span className="text-gray-900 flex-1">
                            {data.procedure_type.name}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Operacja</span>
                        <span className="text-gray-900 flex-1">
                            {data.name}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">ICD-9</span>
                        <span className="text-gray-900 flex-1">
                            {data.icd9
                                ? data.icd9?.code +
                                  ' - ' +
                                  data.icd9.description
                                : '-'}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Rozpoznanie</span>
                        <span className="text-gray-900 flex-1">
                            {data.diagnosis}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Znieczulenie</span>
                        <span className="text-gray-900 flex-1">
                            {!!anesthesiaType
                                ? [
                                      [
                                          anesthesiaType.name,
                                          data.anesthesia_info,
                                      ]
                                          .filter(Boolean)
                                          .join('\n'),
                                      data.weight
                                          ? `Waga: ${data.weight} kg`
                                          : null,
                                  ]
                                      .filter(Boolean)
                                      .join('\n')
                                : '-'}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Przyjęcie</span>
                        <span className="text-gray-900 flex-1">
                            {!!data.patient_informed && (
                                <ThumbUpIcon className="mr-2 w-4 text-green-500" />
                            )}
                            <span className="text-sm text-gray-900 leading-5 font-medium">
                                {!data.admission_date && '-'}
                                {!!data.admission_date &&
                                    displayAdmissionDate(
                                        data.admission_date,
                                        data.estimated_date
                                    )}
                            </span>
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Dieta</span>
                        <span className="text-gray-900 flex-1">
                            {data.diet_info || '-'}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">
                            Uwagi do zabiegu
                        </span>
                        <span className="text-gray-900 flex-1">
                            {data.description || '-'}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">Numer pokoju</span>
                        <span className="text-gray-900 flex-1">
                            {data.room || '-'}
                        </span>
                    </li>

                    <li
                        className="flex py-2 justify-between text-sm leading-5 font-medium"
                        style={{
                            minHeight: 44,
                        }}
                    >
                        <span className="text-gray-500 w-40">
                            Uwagi opiekunki
                        </span>
                        <span className="text-gray-900 flex-1">
                            {data.caregivers_comments || '-'}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default StatisticsSurgeryInfo
