import React, { useContext, useMemo } from 'react'
import { UseQueryResult } from 'react-query'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Formik, Form } from 'formik'
import {
    ExclamationCircleIcon,
    PencilIcon,
    PlusIcon,
    ThumbUpIcon,
} from '@heroicons/react/solid'

import mediaContext from 'contexts/media/mediaContext'
import {
    useOperationActions,
    useOperationTaskPolicy,
    useScheduleApprovedPolicyGroup,
    useSurgeryPolicy,
} from 'hooks'
import {
    displayAdmissionDate,
    displayPatientNameOrPESELOrIdentityCard,
    formatCurrency,
    formatDate,
    phoneWithoutPolandPrefix,
} from 'helpers'
import { TextAreaField } from './forms'
import { Button, Label, Toggle, Loader, Spinner } from 'components/ui'
import { PAYER_TYPE_NFZ } from 'constants/payers'
import OperationTaskListItem from 'components/OperationTaskListItem'
import UserAvatar from 'components/UserAvatar'
import AssignButton from 'components/ui/AssignButton'
import OperationCardMenu3dots from './OperationCardMenu3dots'

import type {
    OperationResponse,
    OperationTask as IOperationTask,
    ResponseData,
    ResponseError,
} from 'api/types'
import type { IModal, CaregiverSurgeryForm, FormSubmitFn } from 'types'
import type { TaskStatusTransitionState } from 'components/TaskExpiredModal'
import { ServerIcon } from '@heroicons/react/outline'

const OperationCard: React.FC<{
    id: number | string
    isEditButton: boolean
    data: OperationResponse
    tasks: UseQueryResult<ResponseData<IOperationTask[]>, ResponseError>
    modalCreateTask: IModal
    modalTask: IModal
    modalAssignEmployee: IModal
    modalAdmissionDate: IModal
    modalPatientDiet: IModal
    modalRoom: IModal
    modalDescription: IModal
    handleChildToggleChange: (value: boolean) => void
    handleOpenTask: (id: number) => void
    handleUpdateTaskStatus: (state: TaskStatusTransitionState) => void
    handleCaregiversCommentsSubmit: FormSubmitFn<CaregiverSurgeryForm>
    onClose?: () => void
    setHistoryModalType: (type: string) => void
}> = ({
    data,
    isEditButton,
    tasks,
    modalTask,
    modalCreateTask,
    modalAssignEmployee,
    modalAdmissionDate,
    modalPatientDiet,
    modalRoom,
    modalDescription,
    handleChildToggleChange,
    handleOpenTask,
    handleUpdateTaskStatus,
    handleCaregiversCommentsSubmit,
    onClose,
    setHistoryModalType,
}) => {
    const { isMobile, isTablet } = useContext(mediaContext)
    const operationTask = useOperationTaskPolicy()
    const surgeryPolicy = useSurgeryPolicy()
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const operationActions = useOperationActions()

    const doctor = useMemo(
        () => data.surgical_team?.find((item) => item.role === 'doctor'),
        [data.surgical_team]
    )

    const isCanceled = useMemo(() => data.status === 'canceled', [data.status])

    return (
        <>
            <div className="divide-gray-100 divide-y pb-10 xl:pb-0">
                <div className="mb-2 md:flex md:items-center md:justify-between">
                    <div className="flex flex-1 min-w-0 items-center h-10">
                        <h2
                            className={classNames(
                                'text-lg font-medium leading-6',
                                {
                                    'text-gray-500': isCanceled,
                                }
                            )}
                        >
                            {scheduleApprovedPolicyGroup.canShow && (
                                <Link
                                    to="/schedule"
                                    className="hover:text-blue-500"
                                    state={{
                                        date: data.estimated_date,
                                        scheduleId: data.id,
                                    }}
                                >
                                    {displayPatientNameOrPESELOrIdentityCard(
                                        data.patient
                                    )}
                                    {Boolean(
                                        displayPatientNameOrPESELOrIdentityCard(
                                            data.patient
                                        )
                                    ) && data.patient.age
                                        ? ` (${data.patient.age})`
                                        : ''}
                                </Link>
                            )}
                            {!scheduleApprovedPolicyGroup.canShow && (
                                <>
                                    {displayPatientNameOrPESELOrIdentityCard(
                                        data.patient
                                    )}
                                    {Boolean(
                                        displayPatientNameOrPESELOrIdentityCard(
                                            data.patient
                                        )
                                    ) && data.patient.age
                                        ? ` (${data.patient.age})`
                                        : ''}
                                </>
                            )}
                        </h2>
                        {data.restored && (
                            <span
                                className={classNames(
                                    'ml-4 flex w-9 h-9 items-center justify-center',
                                    {
                                        'border border-gray-100 rounded-md cursor-pointer':
                                            isEditButton &&
                                            surgeryPolicy.canUpdate,
                                    }
                                )}
                                onClick={
                                    isEditButton && surgeryPolicy.canUpdate
                                        ? () =>
                                              operationActions.onConfirmRestore(
                                                  data.id
                                              )
                                        : undefined
                                }
                            >
                                {operationActions.isRestoreMutating ? (
                                    <Spinner size="xs" />
                                ) : (
                                    <ExclamationCircleIcon className="w-auto h-6 text-red-500" />
                                )}
                            </span>
                        )}
                    </div>
                    {isEditButton && (
                        <div className="flex items-center space-x-4 mt-4 md:mt-0">
                            <div className="flex items-center">
                                <Toggle
                                    checked={!!data.additional_bed}
                                    disabled={false}
                                    handleChange={handleChildToggleChange}
                                />
                                <Label className="pl-2 -mb-0">Dziecko</Label>
                            </div>
                            <div className="flex">
                                <Button
                                    type="button"
                                    variant="default"
                                    className="py-1.5"
                                    onClick={modalPatientDiet.openModal}
                                    iconRight={
                                        <input
                                            type="checkbox"
                                            className="flex h-4 w-4 text-blue-500 border-gray-300 rounded pointer-events-none"
                                            checked={!!data.diet_info}
                                            readOnly
                                        />
                                    }
                                >
                                    Dieta
                                </Button>
                            </div>
                            {!isMobile && (
                                <div className="flex">
                                    <Button
                                        as="link"
                                        to={`/caregiver/surgeries/${data.id}/edit`}
                                        state={{
                                            backUrl: '/caregiver',
                                            state: { id: data.id },
                                        }}
                                        disabled={!surgeryPolicy.canUpdate}
                                        variant="tertiary"
                                    >
                                        Edytuj dane
                                    </Button>
                                </div>
                            )}
                            <OperationCardMenu3dots item={data} />
                        </div>
                    )}
                </div>
                <div>
                    <div
                        className={classNames(
                            'mt-3 md:divide-y divide-gray-100 border-b border-gray-100',
                            {
                                'text-gray-400': isCanceled,
                                'text-gray-700': !isCanceled,
                            }
                        )}
                    >
                        <div className="grid md:gap-2 md:grid-cols-3 text-base border-b border-gray-100 divide-y md:divide-y-0 md:border-b-0 md:divide-x divide-gray-100">
                            <dl
                                className="grid grid-cols-2 gap-2 py-2 md:py-0"
                                style={{
                                    gridTemplateColumns: isMobile
                                        ? '3fr 4fr'
                                        : 'auto 1fr',
                                }}
                            >
                                <dt className="text-gray-500 flex items-center">
                                    Telefon:
                                </dt>
                                <dd className="font-medium overflow-auto flex flex-col">
                                    {data.patient?.phone
                                        ? phoneWithoutPolandPrefix(
                                              data.patient.phone
                                          )
                                        : '-'}
                                    {data.patient?.phone_note && (
                                        <div className="text-sm leading-tight font-normal text-gray-400 truncate">
                                            {data.patient?.phone_note}
                                        </div>
                                    )}
                                </dd>
                            </dl>
                            <dl
                                className="grid grid-cols-2 gap-2 py-2 md:py-0 md:pl-6 xl:pl-8"
                                style={{
                                    gridTemplateColumns: isMobile
                                        ? '3fr 4fr'
                                        : 'auto 1fr',
                                }}
                            >
                                <dt className="text-gray-500 flex items-center">
                                    Data operacji:
                                </dt>
                                <dd className="font-medium flex items-center">
                                    {data.estimated_date ? (
                                        <span
                                            className={classNames(
                                                'font-medium',
                                                {
                                                    'line-through': isCanceled,
                                                }
                                            )}
                                        >
                                            {formatDate(data.estimated_date)}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                            </dl>
                            <dl
                                className="grid grid-cols-2 gap-2 py-2 md:py-0 md:pl-6 xl:pl-8 whitespace-nowrap"
                                style={{
                                    gridTemplateColumns: isMobile
                                        ? '3fr 4fr'
                                        : 'auto 1fr',
                                }}
                            >
                                <dt className="text-gray-500 flex items-center">
                                    Koszt zabiegu:
                                </dt>
                                <dd className="font-medium flex items-center">
                                    {formatCurrency(data.total_cost)}
                                    {` (${
                                        data.payer?.type === PAYER_TYPE_NFZ
                                            ? 'NFZ'
                                            : 'Komercyjny'
                                    })`}
                                </dd>
                            </dl>
                        </div>
                        <div className="mt-4 md:mt-2 md:py-2 flex flex-col md:flex-wrap md:flex-row divide-gray-100 md:divide-x text-sm">
                            <div className="md:pr-4">
                                <dl
                                    className="grid grid-cols-2 gap-2"
                                    style={{
                                        gridTemplateColumns: isMobile
                                            ? '3fr 4fr'
                                            : 'auto 1fr',
                                    }}
                                >
                                    <dt className="text-gray-500">
                                        Przyjęcie:
                                    </dt>
                                    <dd className="flex md:justify-end">
                                        <div
                                            onClick={
                                                isEditButton
                                                    ? () =>
                                                          modalAdmissionDate.openModal()
                                                    : undefined
                                            }
                                            className={classNames(
                                                'flex align-items',
                                                {
                                                    'cursor-pointer':
                                                        isEditButton,
                                                }
                                            )}
                                        >
                                            {!!data.patient_informed && (
                                                <ThumbUpIcon className="mr-2 w-4 text-green-500" />
                                            )}
                                            <span className="whitespace-nowrap">
                                                {!data.admission_date && '-'}
                                                {!!data.admission_date && (
                                                    <div>
                                                        {displayAdmissionDate(
                                                            data.admission_date,
                                                            data.estimated_date
                                                        )}
                                                    </div>
                                                )}
                                            </span>
                                            {isEditButton && (
                                                <PencilIcon className="ml-1 w-4 text-gray-400 vertical-middle hover:text-gray-500" />
                                            )}
                                        </div>
                                    </dd>
                                    <dt className="text-gray-500">Pokój:</dt>
                                    <dd
                                        className={classNames(
                                            'flex md:justify-end',
                                            {
                                                'cursor-pointer':
                                                    isEditButton &&
                                                    surgeryPolicy.canUpdate,
                                            }
                                        )}
                                        onClick={
                                            isEditButton &&
                                            surgeryPolicy.canUpdate
                                                ? () => modalRoom.openModal()
                                                : undefined
                                        }
                                    >
                                        {data.room || '-'}
                                        {isEditButton &&
                                            surgeryPolicy.canUpdate && (
                                                <PencilIcon className="ml-1 w-4 text-gray-400 vertical-middle hover:text-gray-500" />
                                            )}
                                    </dd>
                                </dl>
                            </div>
                            <div className="flex-1 mt-2 md:mt-0 md:pl-4">
                                <dl
                                    className="grid grid-cols-2 gap-2"
                                    style={{
                                        gridTemplateColumns: isMobile
                                            ? '3fr 4fr'
                                            : 'auto 1fr',
                                    }}
                                >
                                    <dt className="text-gray-500">Operacja:</dt>
                                    <dd className="truncate">
                                        {data.procedure?.name || '-'}
                                    </dd>
                                    <dt className="text-gray-500">
                                        Rozpoznanie:
                                    </dt>
                                    <dd className="whitespace-pre-line">
                                        {data.diagnosis || '-'}
                                    </dd>
                                </dl>
                            </div>
                            <div
                                className="flex flex items-start mt-4 pb-2 xl:pb-0 xl:mt-0 xl:ml-4 xl:pl-4 md:basis-full"
                                style={{
                                    flexBasis: isTablet ? '100%' : undefined,
                                    borderLeft:
                                        isMobile || isTablet
                                            ? 'none'
                                            : undefined,
                                }}
                            >
                                <div className="flex items-center divide-gray-100 divide-x space-x-4">
                                    {!!doctor && (
                                        <UserAvatar
                                            data={doctor}
                                            roleName="Operator"
                                            variant={
                                                isMobile ? 'avatar' : 'full'
                                            }
                                            withTooltip={isMobile}
                                        />
                                    )}
                                    {isEditButton && !data.user && (
                                        <div className="pl-4 flex">
                                            <AssignButton
                                                onClick={() =>
                                                    modalAssignEmployee.openModal()
                                                }
                                            />
                                        </div>
                                    )}
                                    {!!data.user && (
                                        <div className="pl-4 flex">
                                            <UserAvatar
                                                data={data.user}
                                                variant={
                                                    !!doctor ? 'avatar' : 'full'
                                                }
                                                onClick={() => {
                                                    modalAssignEmployee.setState(
                                                        data.user
                                                    )
                                                    modalAssignEmployee.openModal()
                                                }}
                                                withTooltip={!!doctor}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="py-2">
                            <div className="grid grid-cols-1 lg:grid-cols-2 my-2 p-4 bg-gray-50 rounded-lg lg:divide-x divide-y lg:divide-y-0">
                                <div className="flex flex-col gap-1 pb-4 lg:pb-0 lg:pr-6">
                                    <div className="flex items-center justify-between">
                                        <span className="text-base text-gray-500">
                                            Uwagi do zabiegu:
                                        </span>

                                        {surgeryPolicy.canSeeHistory && (
                                            <button
                                                onClick={() =>
                                                    setHistoryModalType(
                                                        'descriptions'
                                                    )
                                                }
                                                className="px-1.5 py-1 bg-white inline-flex items-center gap-2 text-xs leading-4 font-medium text-gray-500 border border-gray-200 shadow-sm"
                                            >
                                                Historia{' '}
                                                <ServerIcon className="w-4 text-gray-500" />
                                            </button>
                                        )}
                                    </div>
                                    <div
                                        className={classNames(
                                            'flex justify-between',
                                            {
                                                'cursor-pointer':
                                                    isEditButton &&
                                                    surgeryPolicy.canUpdate,
                                            }
                                        )}
                                        onClick={
                                            isEditButton &&
                                            surgeryPolicy.canUpdate
                                                ? modalDescription.openModal
                                                : undefined
                                        }
                                    >
                                        <p className="text-sm text-gray-700 flex-grow whitespace-pre-line leading-5">
                                            {data.description || '-'}
                                        </p>
                                        {isEditButton &&
                                            surgeryPolicy.canUpdate && (
                                                <span className="ml-4">
                                                    <ButtonPencil />
                                                </span>
                                            )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1 pt-4 lg:pt-0 lg:pl-6">
                                    <div className="flex items-center justify-between">
                                        <span className="text-base text-gray-500 mb-1">
                                            Uwagi opiekunki:
                                        </span>

                                        {surgeryPolicy.canSeeHistory && (
                                            <button
                                                onClick={() =>
                                                    setHistoryModalType(
                                                        'caregiver-comments'
                                                    )
                                                }
                                                className="px-1.5 py-1 bg-white inline-flex items-center gap-2 text-xs leading-4 font-medium text-gray-500 border border-gray-200 shadow-sm"
                                            >
                                                Historia{' '}
                                                <ServerIcon className="w-4 text-gray-500" />
                                            </button>
                                        )}
                                    </div>
                                    {isEditButton && (
                                        <Formik
                                            initialValues={{
                                                caregivers_comments:
                                                    data.caregivers_comments ||
                                                    '',
                                            }}
                                            onSubmit={
                                                handleCaregiversCommentsSubmit
                                            }
                                        >
                                            {({ isSubmitting }) => (
                                                <Form>
                                                    <div
                                                        className="grid grid-cols-2 gap-2 items-end"
                                                        style={{
                                                            gridTemplateColumns:
                                                                '1fr auto',
                                                        }}
                                                    >
                                                        <TextAreaField
                                                            name="caregivers_comments"
                                                            rows={3}
                                                            disabled={
                                                                !(
                                                                    isEditButton &&
                                                                    surgeryPolicy.canUpdate
                                                                )
                                                            }
                                                        />
                                                        <Button
                                                            type="submit"
                                                            variant="tertiary"
                                                            loading={
                                                                isSubmitting
                                                            }
                                                            disabled={
                                                                !(
                                                                    isEditButton &&
                                                                    surgeryPolicy.canUpdate
                                                                )
                                                            }
                                                        >
                                                            Zapisz
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                    {!isEditButton && (
                                        <p className="text-sm text-gray-700 flex-grow whitespace-pre-line leading-5">
                                            {data.caregivers_comments || '-'}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <p className="text-lg font-medium leading-6">
                                Zadania
                            </p>
                        </div>
                        {operationTask.canCreate && (
                            <div className="mt-4 hidden md:flex md:mt-0 md:ml-4">
                                <Button
                                    type="button"
                                    variant="default"
                                    iconRight={
                                        <PlusIcon className="w-5 h-5 text-gray-500" />
                                    }
                                    onClick={() => modalCreateTask.openModal()}
                                >
                                    Dodaj zadanie
                                </Button>
                            </div>
                        )}
                    </div>
                    {tasks.isLoading && <Loader />}
                    {tasks.isError && <div>{tasks.error.message}</div>}
                    {tasks.isSuccess && tasks.data.data.length === 0 && (
                        <p className="pt-2 pb-20 text-sm text-gray-500">
                            Nie ma jeszcze żadnych zadań.
                        </p>
                    )}
                    {tasks.isSuccess && tasks.data.data.length > 0 && (
                        <ul className="mt-4 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3">
                            {tasks.data.data.map((item) => (
                                <li key={item.id} className="col-span-1">
                                    <OperationTaskListItem
                                        item={item}
                                        editable={operationTask.canUpdate}
                                        current={
                                            modalTask.getState() === item.id
                                        }
                                        onOpen={handleOpenTask}
                                        onUpdateStatus={handleUpdateTaskStatus}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="fixed xl:hidden bottom-0 right-0 left-0 md:left bg-white flex-shrink-0 px-6 py-4 flex justify-between md:justify-end shadow-inner z-50">
                    <Button type="button" onClick={onClose}>
                        Zamknij
                    </Button>
                    {isMobile && operationTask.canCreate && (
                        <Button
                            type="button"
                            variant="default"
                            iconRight={
                                <PlusIcon className="w-5 h-5 text-gray-500" />
                            }
                            onClick={() => modalCreateTask.openModal()}
                        >
                            Dodaj zadanie
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

const ButtonPencil: React.FC<
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>
> = ({ onClick }) => (
    <button
        type="button"
        className="bg-white rounded-full h-6 w-6 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500"
        onClick={onClick}
    >
        <PencilIcon className="h-4 w-4" aria-hidden="true" />
        <span className="sr-only">Edytuj</span>
    </button>
)

export default OperationCard
