import React, { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import ListLayout from 'layouts/ListLayout'
import {
    useDeleteIdentificationCard,
    useGetIdentificationCards,
    usePatchIdentificationCard,
    usePostIdentificationCard,
} from 'api'
import { useModal, useIdentificationCardPolicy, useNotification } from 'hooks'
import { MESSAGES, DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { AccessDenied } from 'components'
import { ActionButton, FilterButton } from 'components/ui'
import SettingsRFIDCardList from 'components/SettingsRFIDCardList'
import SettingsRFIDCardCreateModal from 'components/SettingsRFIDCardCreateModal'
import SettingsRFIDCardUpdateModal from 'components/SettingsRFIDCardUpdateModal'
import SettingsRFIDCardListFilters from 'containers/SettingsRFIDCardListFilters'

import { FormSubmitFn, RFIDCardForm, IDCardUpdateForm } from 'types'

export type TFilters = {
    page: number
    length: number
    sort_by: string
    sort_direction: string
    query: string
}

const initialFilters: TFilters = {
    page: 1,
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'id',
    sort_direction: 'desc',
    query: '',
}

const SettingsRFIDCardListContainer = () => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const identificationCardPolicy = useIdentificationCardPolicy()

    const [filters, setFilters] = useState(initialFilters)
    const setSorting = useCallback(
        (sort_by: string, sort_direction: string) =>
            setFilters((prevState) => ({
                ...prevState,
                sort_by,
                sort_direction,
                page: 1,
            })),
        [setFilters]
    )
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () => (filters.query.length ? 1 : 0),
        [filters]
    )

    const identificationCardsCreateModal = useModal(false)
    const identificationCardsEditModal = useModal(false)

    const handleResetFilters = () => {
        setFilters((prevState) => ({
            ...prevState,
            query: '',
            page: 1,
        }))
        setFiltersExpanded(false)
    }

    const handleSetFilters = (query: string) => {
        setFilters((prevState) => ({
            ...prevState,
            query,
            page: 1,
        }))
    }

    const handleSetPage = (page: number) => {
        setFilters((prevState) => ({
            ...prevState,
            page,
        }))
    }

    const cardListQueryResult = useGetIdentificationCards({
        ...filters,
    })
    const { mutate: createCard } = usePostIdentificationCard()
    const { mutate: updateCard } = usePatchIdentificationCard()
    const { mutate: deleteCard } = useDeleteIdentificationCard()

    const handleCreateCard: FormSubmitFn<RFIDCardForm> = (
        values,
        formikHelpers
    ) => {
        createCard(
            {
                number: values.number,
                user_id: values.user?.id || null,
                active: values.active,
            },
            {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    identificationCardsCreateModal.closeModal()
                    queryClient.invalidateQueries(['identification-cards'])
                    showNotification({
                        content: MESSAGES.RFID_CARD_CREATED,
                        type: 'success',
                    })
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleUpdateCard: FormSubmitFn<IDCardUpdateForm> = (
        values,
        formikHelpers
    ) => {
        if (values._isDeleting) {
            return deleteCard(
                {
                    id: values.id,
                },
                {
                    onSuccess: () => {
                        identificationCardsEditModal.closeModal()
                        queryClient.invalidateQueries(['identification-cards'])
                        showNotification({
                            content: MESSAGES.RFID_CARD_DELETED,
                            type: 'success',
                        })
                    },
                    onError: (error) => {
                        showNotification({
                            content: MESSAGES.RFID_CARD_DELETE_FAILED,
                            type: 'danger',
                        })
                        formikHelpers.setErrors(error.errors)
                    },
                    onSettled: () => {
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        }

        const data = values._statusActiveIsToggling
            ? {
                  active: !values.active,
              }
            : {
                  number: values.number,
                  user_id: values.user?.id || null,
                  active: values.active,
              }

        updateCard(
            {
                id: values.id,
                data,
            },
            {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    identificationCardsEditModal.closeModal()
                    queryClient.invalidateQueries(['identification-cards'])
                    showNotification({
                        content: values._statusActiveIsToggling
                            ? !data.active
                                ? MESSAGES.RFID_CARD_LOCKED_SUCCESS
                                : MESSAGES.RFID_CARD_UNLOCKED_SUCCESS
                            : MESSAGES.RFID_CARD_UPDATED,
                        type: 'success',
                    })
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <>
            <ListLayout
                title="Karty użytkowników"
                actions={
                    <>
                        {identificationCardPolicy.canIndex && (
                            <FilterButton
                                count={filtersCount}
                                filtersExpanded={filtersExpanded}
                                handleReset={handleResetFilters}
                                onClick={() =>
                                    setFiltersExpanded((state) => !state)
                                }
                            />
                        )}
                        {identificationCardPolicy.canCreate && (
                            <span className="ml-4">
                                <ActionButton
                                    handleClick={
                                        identificationCardsCreateModal.openModal
                                    }
                                    rightIcon={PlusIcon}
                                >
                                    Dodaj
                                </ActionButton>
                            </span>
                        )}
                    </>
                }
            >
                <>
                    {filtersExpanded && (
                        <SettingsRFIDCardListFilters
                            filters={filters}
                            setFilters={handleSetFilters}
                        />
                    )}
                    <div className="relative z-0">
                        <div className="overflow-y-auto">
                            {identificationCardPolicy.canShow ? (
                                <SettingsRFIDCardList
                                    queryResult={cardListQueryResult}
                                    setSorting={setSorting}
                                    filters={filters}
                                    filtersCount={filtersCount}
                                    setPage={handleSetPage}
                                    identificationCardsEditModal={
                                        identificationCardsEditModal
                                    }
                                    canEditAndDelete={
                                        identificationCardPolicy.canDelete &&
                                        identificationCardPolicy.canUpdate
                                    }
                                />
                            ) : (
                                <AccessDenied message="Nie masz uprawnień do wyświetlania kart" />
                            )}
                        </div>
                    </div>
                </>
            </ListLayout>
            <SettingsRFIDCardCreateModal
                modal={identificationCardsCreateModal}
                handleSubmit={handleCreateCard}
            />
            <SettingsRFIDCardUpdateModal
                modal={identificationCardsEditModal}
                handleSubmit={handleUpdateCard}
            />
        </>
    )
}

export default SettingsRFIDCardListContainer
