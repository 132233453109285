import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import {
    useGetAnesthesiologistSchedules,
    useGetApprovedDays,
    useGetOperatingRooms,
    useGetSchedules,
} from 'api'
import { Loader } from 'components/ui'
import {
    useModal,
    useScheduleChannel,
    useScheduleApprovedPolicyGroup,
} from 'hooks'
import { AccessDenied } from 'components'
import { parseDateToFormik } from 'components/forms/DatePickers/utils'
import ScheduleApproved from 'components/ScheduleApproved'
import ScheduleOperationSlideOver from 'components/ScheduleOperationSlideOver'
import mediaContext from 'contexts/media/mediaContext'

import type { FormSubmitFn, ScheduleFiltersForm } from 'types'
import type { OperatingRoom } from 'api/types'

const ScheduleApprovedContainer: React.FC<{
    view: 'hourly' | 'daily'
}> = ({ view }) => {
    const queryClient = useQueryClient()
    const location = useLocation()
    const { isMobile } = useContext(mediaContext)
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)
    const [filters, setFilters] = useState<ScheduleFiltersForm>({
        today: location.state?.date
            ? moment(location.state.date).toDate()
            : moment().toDate(),
        from: location.state?.date
            ? moment(location.state.date).startOf('isoWeek').toDate()
            : moment().startOf('isoWeek').toDate(),
        to: location.state?.date
            ? moment(location.state.date).endOf('isoWeek').toDate()
            : moment().endOf('isoWeek').toDate(),
        procedure_types: [],
        operating_room: undefined,
    })
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const modalScheduleOperation = useModal(false)

    const roomsQueryResult = useGetOperatingRooms(
        { pagination: false },
        { enabled: scheduleApprovedPolicyGroup.canIndex }
    )

    useEffect(() => {
        if (!location.state) {
            return
        }

        if (!location.state?.date || !moment(location.state.date).isValid()) {
            return
        }

        setFilters((prevState) => ({
            ...prevState,
            today: moment(location.state.date).toDate(),
            from: moment(location.state.date).startOf('isoWeek').toDate(),
            to: moment(location.state.date).endOf('isoWeek').toDate(),
        }))

        if (!isNaN(Number(location.state.scheduleId))) {
            modalScheduleOperation.setState(location.state.scheduleId)
            modalScheduleOperation.openModal()
        }

        window.history.replaceState({}, document.title)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filtersCount = useMemo(
        () =>
            (filters.procedure_types.length ? 1 : 0) +
            (filters.operating_room ? 1 : 0),
        [filters]
    )

    const filtersToApi = useMemo(
        () => ({
            operating_room: filters.operating_room?.id,
            procedure_types: filters.procedure_types.map((item) => item.id),
            from: parseDateToFormik(filters.from),
            to: parseDateToFormik(filters.to),
        }),
        [filters]
    )

    const scheduleQueryResult = useGetSchedules(filtersToApi, {
        enabled: scheduleApprovedPolicyGroup.canIndex,
    })

    const anesthesiologistSchedulesQueryResult =
        useGetAnesthesiologistSchedules(
            {
                from: parseDateToFormik(filters.from),
                to: parseDateToFormik(filters.to),
                sort_by: 'from',
                sort_direction: 'asc',
            },
            {
                enabled:
                    scheduleApprovedPolicyGroup.anesthesiologistSchedule
                        .canIndex,
            }
        )

    const approvedDaysQueryResult = useGetApprovedDays({ paginated: false })

    const scheduleChannel = useScheduleChannel()

    useEffect(() => {
        const { onEvent, handleEvent, closeChannel } = scheduleChannel.connect()

        onEvent(handleEvent)

        return () => closeChannel()

        // eslint-disable-next-line
    }, [])

    const handleChangeFilters: FormSubmitFn<ScheduleFiltersForm> = (values) => {
        setFilters(values)
    }

    const handleResetFilters = () => {
        setFilters((prevState) => ({
            ...prevState,
            procedure_types: [],
            operating_room: undefined,
        }))
        setFiltersExpanded(false)
    }

    const handleChangeDate = (date: Date) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            today: date,
            from: moment(date).startOf('isoWeek').toDate(),
            to: moment(date).endOf('isoWeek').toDate(),
        }))
    }

    const handleSelectOperation = (id: number) => {
        modalScheduleOperation.setState(id)
        modalScheduleOperation.openModal()
    }

    const handleSuccessOperationChange = async () => {
        modalScheduleOperation.closeModal()
        await queryClient.invalidateQueries('operations')
        await queryClient.invalidateQueries('schedules')
        await queryClient.invalidateQueries('schedules-plan')
    }

    const setRoom = useCallback(
        (operating_room: OperatingRoom) =>
            setFilters((state) => ({
                ...state,
                operating_room,
            })),
        []
    )

    const { canSendSMS, isApprovedDay } = useMemo(() => {
        if (!approvedDaysQueryResult.data) {
            return {
                canSendSMS: false,
                isApprovedDay: false,
            }
        }

        const lastApprovedDay = moment(
            approvedDaysQueryResult.data.data[0].date
        )
        const lastDayWithSendSMS = moment(
            approvedDaysQueryResult.data.data[0].sms_sent_to
        )

        const isApprovedDay = moment(filters.today).isSameOrBefore(
            lastApprovedDay,
            'days'
        )
        const areApprovedDaysWithoutSentSMS = moment(
            lastDayWithSendSMS
        ).isBefore(lastApprovedDay, 'days')

        return {
            canSendSMS: isApprovedDay && areApprovedDaysWithoutSentSMS,
            isApprovedDay,
        }
    }, [filters.today, approvedDaysQueryResult.data])

    useEffect(() => {
        if (isMobile && !filters.operating_room && roomsQueryResult.data) {
            setRoom(roomsQueryResult.data.data[0])
        }
    }, [isMobile, filters.operating_room, roomsQueryResult.data, setRoom])

    if (!scheduleApprovedPolicyGroup.canIndex) {
        return <AccessDenied message="Nie masz dostępu do planu operacyjnego" />
    }

    if (roomsQueryResult.isLoading) {
        return <Loader />
    }

    if (roomsQueryResult.isError || scheduleQueryResult.isError) {
        if (roomsQueryResult.isError) {
            return <div>{roomsQueryResult.error.message}</div>
        }

        if (scheduleQueryResult.isError) {
            return <div>{scheduleQueryResult.error.message}</div>
        }
    }

    if (!roomsQueryResult.isSuccess) {
        return null
    }

    return (
        <>
            <ScheduleApproved
                view={view}
                rooms={roomsQueryResult.data.data}
                scheduleQueryResult={scheduleQueryResult}
                anesthesiologistSchedulesQueryResult={
                    anesthesiologistSchedulesQueryResult
                }
                onChangeDate={handleChangeDate}
                filters={filters}
                filtersCount={filtersCount}
                filtersExpanded={filtersExpanded}
                setFiltersExpanded={setFiltersExpanded}
                handleResetFilters={handleResetFilters}
                onChangeFilters={handleChangeFilters}
                onSelectOperation={handleSelectOperation}
                setRoom={setRoom}
                canSendSMS={canSendSMS}
                isApprovedDay={isApprovedDay}
            />
            {scheduleApprovedPolicyGroup.schedule.canShow && (
                <ScheduleOperationSlideOver
                    editable={false}
                    modal={modalScheduleOperation}
                    onSuccessChange={handleSuccessOperationChange}
                    onClickOutside={modalScheduleOperation.closeModal}
                />
            )}
        </>
    )
}

export default ScheduleApprovedContainer
