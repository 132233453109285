import React, { ReactElement } from 'react'
import { getCxFromStyles } from 'helpers'

import styles from './OperationTaskStatus.module.scss'

export type OperationTaskStatusProps = {
    status: string
    iconRight?: ReactElement
    variant?: 'sm' | 'md'
}

const OperationTaskStatus: React.FC<OperationTaskStatusProps> = ({
    status,
    variant = 'sm',
    iconRight,
}) => {
    const cx = getCxFromStyles(styles)

    return (
        <span className={cx('root', variant, status)}>
            <span className={cx('label')}>
                {status === 'new' && 'Oczekuje'}
                {status === 'done' && 'Wykonane'}
                {status === 'ordered' && 'Zlecone'}
                {status === 'archive' && 'Archiwum'}
                {status === 'accepted' && 'Zaakceptowane'}
                {status === 'clarify' && 'Dodatkowe badania'}
                {status === 'rejected' && 'Odrzucone'}
            </span>
            {!!iconRight && (
                <span className={cx('children--icon-right')}>{iconRight}</span>
            )}
        </span>
    )
}

export default OperationTaskStatus
