import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const USER_PERMISSIONS = {
    index: PERMISSIONS.INDEX_USERS,
    show: PERMISSIONS.SHOW_USERS,
    create: PERMISSIONS.CREATE_USERS,
    update: PERMISSIONS.UPDATE_USERS,
    delete: PERMISSIONS.DELETE_USERS,
}

export const useUserPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(USER_PERMISSIONS),
    }
}
