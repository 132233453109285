import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ROLE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ROLES,
    show: PERMISSIONS.SHOW_ROLES,
    create: PERMISSIONS.CREATE_ROLES,
    update: PERMISSIONS.UPDATE_ROLES,
    delete: PERMISSIONS.DELETE_ROLES,
}

export const useRolePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(ROLE_PERMISSIONS),
    }
}
