import React from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { Label, Loader } from 'components/ui'
import { SearchIcon } from '@heroicons/react/solid'

import { useGetProcedureCategories } from 'api'
import { CheckboxDropdownField, DropdownField } from 'components/forms'

import type {
    ProcedureCategory,
    ResponseData,
    FormProcedureDurationsFilters,
} from 'api/types'

const FilterContainer = ({
    filters,
    setFilters,
}: {
    filters: FormProcedureDurationsFilters
    setFilters: (filters: FormProcedureDurationsFilters) => void
}) => {
    const categories = useGetProcedureCategories<
        ResponseData<ProcedureCategory[]>
    >({
        pagination: false,
    })

    if (categories.isLoading) {
        return (
            <div className="mb-4 px-3 py-4 h-24 bg-white rounded-lg">
                <Loader />
            </div>
        )
    }

    if (!categories.data) {
        return null
    }

    return (
        <Filters
            categories={categories.data}
            filters={filters}
            setFilters={setFilters}
        />
    )
}

const Filters: React.FC<{
    categories: ResponseData<ProcedureCategory[]>
    filters: FormProcedureDurationsFilters
    setFilters: (filters: FormProcedureDurationsFilters) => void
}> = ({ categories, filters, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => setFilters(values)}
                enableReinitialize
            >
                {({ values, handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-4 gap-4">
                            <div>
                                <Label>Szukaj</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="name"
                                        className="block w-full pr-10 appearance-none px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CheckboxDropdownField
                                    label="Kategoria"
                                    name="procedure_categories"
                                    items={categories.data}
                                />
                            </div>
                            <div>
                                <DropdownField
                                    label="Edycja"
                                    name="editable_duration"
                                    items={[
                                        { id: -1, name: 'Wszystkie' },
                                        { id: 1, name: 'Włączone' },
                                        { id: 0, name: 'Wyłączone' },
                                    ]}
                                />
                            </div>
                        </div>
                        <AutoSubmitToken filters={filters} />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const AutoSubmitToken = ({
    filters,
}: {
    filters: FormProcedureDurationsFilters
}) => {
    const { values, submitForm } = useFormikContext()
    React.useEffect(() => {
        if (values !== filters) {
            submitForm()
        }
    }, [values, filters, submitForm])
    return null
}

export default FilterContainer
