import React from 'react'
import classNames from 'classnames'

import { formatPrice } from 'helpers'

const SummaryColumn: React.FC<{
    cost: number | string
    secondCost?: number | string
    highlight?: boolean
    disabled?: boolean
    costRender?: (cost: number | string) => JSX.Element
    textLine1?: string
    textLine2?: string
}> = ({
    cost,
    secondCost,
    highlight,
    disabled,
    textLine1,
    textLine2,
    costRender,
}) => {
    const displayCost = formatPrice(cost)
    const displaySecondCost =
        typeof secondCost === 'number' ? formatPrice(secondCost) : null

    return (
        <div
            className={classNames('py-5 rounded-md', {
                'px-4 bg-gray-100': highlight,
                'text-gray-500': !disabled,
                'text-gray-300': disabled,
            })}
        >
            <p>
                <span
                    className={classNames(
                        'text-3xl xl:text-5xl leading-none font-normal tracking-tighter',
                        {
                            'text-gray-900': !disabled,
                        }
                    )}
                >
                    {typeof costRender === 'function'
                        ? costRender(displayCost)
                        : displayCost}
                </span>
                <span className="pl-1.5 text-2xl leading-8 font-normal tracking-tighter">
                    zł
                </span>
            </p>

            {displaySecondCost && (
                <p className="text-base leading-6 font-normal text-gray-500">
                    <span>Planowane:</span>
                    <span className="text-xl leading-7 font-normal text-gray-900 px-1.5">
                        {displaySecondCost}
                    </span>
                    <span className="tracking-tighter">zł</span>
                </p>
            )}

            {secondCost === '' && <p className="h-7"></p>}

            <div className="m-0 mt-4 mb-4 xl:mb-0 font-light">
                {!!textLine1 && (
                    <p className="text-lg leading-7">{textLine1}</p>
                )}
                {!!textLine2 && (
                    <p className="text-sm leading-4">{textLine2}</p>
                )}
            </div>
        </div>
    )
}

export default SummaryColumn
