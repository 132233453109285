import { useSurgeryListPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const SurgeryListMenuItemGate: MenuItemGate = ({ children }) => {
    const surgeryListPolicyGroup = useSurgeryListPolicyGroup()

    if (surgeryListPolicyGroup.canIndex) {
        return children
    }

    return null
}
