import { useQuery, UseQueryOptions } from 'react-query'

import axios from 'api/axios'

import type {
    OperationCaregiverCommentHistoryItem,
    OperationDescriptionHistoryItem,
    ResponseError,
} from 'api/types'

type ActivityLogsRequest = {
    sort_by: string
    sort_direction: 'desc' | 'asc'
    length: number
    page: number
    operation_id: number
    event_name: 'description_changed' | 'caregivers_comments_changed'
}

type OperationCaregiverCommentHistoryResponse = {
    data: OperationCaregiverCommentHistoryItem[]
}

type OperationDescriptionHistoryResponse = {
    data: OperationDescriptionHistoryItem[]
}

const getActivityLogs = (params: ActivityLogsRequest) =>
    axios.get('/activity-logs', { params })

export const useGetOperationCaregiverCommentHistory = (
    operationId: number,
    options?: UseQueryOptions<
        OperationCaregiverCommentHistoryResponse,
        ResponseError
    >
) =>
    useQuery<OperationCaregiverCommentHistoryResponse, ResponseError>(
        ['operation-caregiver-comment-history', operationId],
        () =>
            getActivityLogs({
                sort_by: 'created_at',
                sort_direction: 'desc',
                length: 999,
                page: 1,
                operation_id: operationId,
                event_name: 'caregivers_comments_changed',
            }),
        options
    )

export const useGetOperationDescriptionHistory = (
    operationId: number,
    options?: UseQueryOptions<
        OperationDescriptionHistoryResponse,
        ResponseError
    >
) =>
    useQuery<OperationDescriptionHistoryResponse, ResponseError>(
        ['operation-description-history', operationId],
        () =>
            getActivityLogs({
                sort_by: 'created_at',
                sort_direction: 'desc',
                length: 999,
                page: 1,
                operation_id: operationId,
                event_name: 'description_changed',
            }),
        options
    )
