import { useMutation, UseMutationResult } from 'react-query'

import axios from 'api/axios'
import { ResponseError } from 'api/types'

type TMultiSelectRequest = {
    model: string
    action: string
    ids: number[]
    payload: {
        procedures_ids: number[]
    }
}

type TMultiSelectResponse = {}

export const postMultiSelect = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/multi-select', data)

export const useMultiSelect = <
    R1 = TMultiSelectRequest,
    R2 = TMultiSelectResponse
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => postMultiSelect(data))
