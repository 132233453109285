import {
    useQuery,
    useMutation,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    AnesthesiologistCostResponse,
    AnesthesiologistCostsRequest,
    AnesthesiologistCostsResponse,
    CreateAnesthesiologistCostVariables,
    UpdateAnesthesiologistCostVariables,
    ResponseError,
    ResponseNoContent,
} from 'api/types'

export const useGetAnesthesiologistCosts = (
    filters?: AnesthesiologistCostsRequest,
    options?: UseQueryOptions<AnesthesiologistCostsResponse, ResponseError>
): UseQueryResult<AnesthesiologistCostsResponse, ResponseError> =>
    useQuery<AnesthesiologistCostsResponse, ResponseError>(
        ['anesthesiologist-costs', filters],
        () =>
            client.get(
                '/anesthesiologist-costs' +
                    (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const useGetAnesthesiologistCost = (
    id: string,
    options?: UseQueryOptions<AnesthesiologistCostResponse, ResponseError>
): UseQueryResult<AnesthesiologistCostResponse, ResponseError> =>
    useQuery<AnesthesiologistCostResponse, ResponseError>(
        ['anesthesiologist-costs', 'id', id],
        () => client.get(`/anesthesiologist-costs/${id}`),
        options
    )

export const useCreateAnesthesiologistCost = (): UseMutationResult<
    AnesthesiologistCostResponse,
    ResponseError,
    CreateAnesthesiologistCostVariables
> => useMutation((data) => client.post('/anesthesiologist-costs', data))

export const useUpdateAnesthesiologistCost = (): UseMutationResult<
    AnesthesiologistCostResponse,
    ResponseError,
    UpdateAnesthesiologistCostVariables
> =>
    useMutation(({ id, values }) =>
        client.patch(`/anesthesiologist-costs/${id}`, values)
    )

export const useDeleteAnesthesiologistCost = (): UseMutationResult<
    ResponseNoContent,
    ResponseError,
    {
        id: number
    }
> => useMutation(({ id }) => client.delete('/anesthesiologist-costs/' + id))
