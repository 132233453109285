import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Button, Label } from 'components/ui'
import { TextField } from 'components/forms'

import type { FormSubmitFn, Login2FAForm } from 'types'

import { ReactComponent as LockClosedIcon } from 'assets/lock-closed.svg'

const schema = yup.object({
    code: yup
        .string()
        .test(
            'len',
            `Wpisz kod składający się dokładnie z 4 cyfr`,
            (val) => val?.length === 4
        ),
})

const RESEND_SECONDS = 30

export default function Login2FA({
    onSubmit,
    onResend,
}: {
    onSubmit: FormSubmitFn<Login2FAForm>
    onResend: () => void
}) {
    const [counterStartTime, setCounterStartTime] = useState<number | null>(
        Date.now()
    )
    const [counter, setCounter] = useState(RESEND_SECONDS)

    useEffect(() => {
        const interval = counterStartTime
            ? setInterval(() => {
                  setCounter(
                      RESEND_SECONDS -
                          Math.floor((Date.now() - counterStartTime) / 1000)
                  )
              }, 1000)
            : null

        return () => {
            interval && clearInterval(interval)
        }
    }, [counterStartTime])

    useEffect(() => {
        if (counter <= 0) {
            setCounterStartTime(null)
            setCounter(RESEND_SECONDS)
        }
    }, [counter])

    return (
        <Formik
            initialValues={{ code: '' }}
            onSubmit={onSubmit}
            validationSchema={schema}
            validateOnChange={false}
        >
            {({ isSubmitting }) => (
                <Form className="space-y-6 text-center">
                    <LockClosedIcon className="mx-auto" />

                    <div className="text-lg leading-6 font-medium text-gray-900">
                        Potwierdzenie logowania
                    </div>

                    <div className="text-sm leading-5 font-normal text-gray-500">
                        Wpisz kod weryfikacyjny wysłany na numer telefonu
                        przypisany do Twojego konta
                    </div>

                    <div className="text-left">
                        <Label>Kod weryfikacyjny</Label>
                        <TextField id="code" name="code" />
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            className="w-full"
                            type="submit"
                            loading={isSubmitting}
                        >
                            Zweryfikuj i zaloguj się
                        </Button>
                    </div>

                    {!!counterStartTime && (
                        <div className="text-sm leading-5 font-normal text-gray-600">
                            Odczekaj{' '}
                            <span className="font-semibold">
                                {counter} sekund
                                {counter === 1
                                    ? 'ę'
                                    : counter < 5 && counter > 1
                                    ? 'y'
                                    : ''}
                            </span>
                            , zanim poprosisz o nowy kod
                        </div>
                    )}

                    <div>
                        <Button
                            variant="link"
                            disabled={!!counterStartTime}
                            onClick={() => {
                                onResend()
                                setCounterStartTime(Date.now())
                            }}
                        >
                            Potrzebuję nowy kod
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
