import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'

import { Label, RangeDatePicker } from 'components/ui'
import { AutoSubmitRow } from 'components/forms'

export type Filters = {
    date_range: { from: Date | undefined; to: Date | undefined }
    query: string
    page: number
    length: number
    sort_by: string
    sort_direction: string
}

const DietListFilters: React.FC<{
    filters: Filters
    setFilters: (filters: Filters) => void
}> = ({ filters, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg print:hidden">
            <Formik
                initialValues={filters}
                onSubmit={(values) => {
                    const date_range = {
                        from: values.date_range.from,
                        to: values.date_range.to,
                    }

                    if (filters.date_range.from && filters.date_range.to) {
                        if (
                            (!values.date_range.from && values.date_range.to) ||
                            (values.date_range.from && !values.date_range.to)
                        ) {
                            return
                        }
                    }

                    setFilters({ ...values, date_range, page: 1 })
                }}
                enableReinitialize
            >
                {({ values, setFieldValue, handleChange }) => (
                    <Form>
                        <div className={`grid grid-cols-5 gap-4`}>
                            <div className="col-span-3">
                                <Label>Wyszukaj pacjenta</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="query"
                                        className="block w-full pl-10 appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        placeholder="Wyszukaj pacjenta"
                                        value={values.query}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-span-2">
                                <Label>Zakres dat zabiegów</Label>
                                <RangeDatePicker
                                    sideMenu={false}
                                    placeholderFrom="Rozpoczęcie"
                                    placeholderTo="Zakończenie"
                                    value={values.date_range}
                                    onChange={(dateRange) => {
                                        setFieldValue('date_range', dateRange)
                                    }}
                                    onBlur={() => {
                                        if (
                                            !values.date_range.from ||
                                            !values.date_range.to
                                        ) {
                                            setFieldValue('date_range', {
                                                from: undefined,
                                                to: undefined,
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default DietListFilters
