import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import { Dialog } from '@headlessui/react'

import { OPERATION_STATUS_CANCELED } from 'constants/index'
import { useEventListener } from 'hooks'
import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import { Button, Label } from 'components/ui'
import { TextAreaField } from 'components/forms'

import styles from './Modal.module.scss'

import type { FormSubmitFn, IModal } from 'types'
import type { OperationCancelForm } from 'types/OperationCancelForm'
export type OperationCancelModalState = {
    id: number
}

const OperationCancelModal: React.FC<{
    modal: IModal<OperationCancelModalState | undefined>
    onSubmit: FormSubmitFn<OperationCancelForm>
    onCancel: () => void
    onClickOutside?: () => void
}> = ({ modal, onSubmit, onCancel, onClickOutside }) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const cx = getCxFromStyles(styles)

    const handleMouseDown = (e: Event) => {
        if (modal.isOpen) {
            e.stopImmediatePropagation()
        }
    }

    useEventListener('mousedown', handleMouseDown, [modal.isOpen])

    return (
        <Modal
            ref={modalRef}
            modal={modal}
            onClickOutside={onClickOutside ? onClickOutside : () => {}}
        >
            <div className={cx('inner', 'p-6')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Odwołaj operację
                </Dialog.Title>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={
                        {
                            status: OPERATION_STATUS_CANCELED,
                            cancel_reason: '',
                            change_manually: true,
                        } as OperationCancelForm
                    }
                    validateOnChange={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mt-5">
                                <Label>
                                    Podaj przyczynę odwołania operacji
                                </Label>
                                <TextAreaField name="cancel_reason" cols={5} />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    variant="danger"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Odwołaj operację
                                </Button>
                                <Button
                                    variant="default"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Anuluj
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default OperationCancelModal
