import React from 'react'
import { Button } from 'components/ui'
import { PlusIcon } from '@heroicons/react/outline'

const CLASSNAMES_BUTTON = 'pl-4 pr-3.5 py-1.5 text-sm'

const EmptyList: React.FC<{
    headline: string
    message: string
    button?: boolean
    to?: string
    onClick?: () => void
}> = ({ headline, message, to, button = true, onClick }) => {
    return (
        <div className="m-10 text-center">
            <svg
                className="mx-auto mb-2 h-16 w-16 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
            </svg>
            <h3 className="mb-1 text-md font-medium text-gray-900">
                {headline}
            </h3>
            <p className="text-sm text-gray-500">{message}</p>
            {button && (
                <div className="mt-6">
                    {to ? (
                        <Button
                            as="link"
                            variant="primary"
                            className={CLASSNAMES_BUTTON}
                            to={to}
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    ) : onClick ? (
                        <Button
                            type="button"
                            variant="primary"
                            className={CLASSNAMES_BUTTON}
                            onClick={onClick}
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            variant="primary"
                            className={CLASSNAMES_BUTTON}
                            disabled={true}
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}

export default EmptyList
