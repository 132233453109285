import React from 'react'
import { useLocation } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import withScrolling from 'react-dnd-scrolling'
import moment from 'moment'
import { PencilIcon } from '@heroicons/react/solid'

import { useGetAnesthesiologistSchedules } from 'api'
import { useSchedulePlannerPolicyGroup } from 'hooks'
import { Button, ButtonPrint, FilterButton } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import Filters from 'components/ScheduleFilters'

import {
    HourlyGrid,
    DropZone,
    DailyGrid,
    HeaderCenter,
    HorizontalLines,
    OperatingRoomBar,
    PlannerHorizontalLines,
    VerticalLines,
} from 'components/schedule'
import type { UseQueryResult } from 'react-query'
import type {
    OperatingRoom,
    ScheduleOperationListItem,
    SchedulePlanItem,
    ScheduleListMeta,
    ResponseList,
    ResponseData,
    ResponseError,
} from 'api/types'
import type { FormSubmitFn } from 'types'
import type { ScheduleFiltersForm } from 'types/ScheduleFiltersForm'
import type {
    OnDrop,
    OnDragStart,
    OnDragStop,
    OnAssignAnesthesiologist,
} from 'types/SchedulePlanner'

const ScrollingComponent = withScrolling('div')

const SchedulePlanner: React.FC<{
    view: 'hourly' | 'daily'
    lastApprovedDay?: string
    planQueryResult: UseQueryResult<
        ResponseData<SchedulePlanItem[]>,
        ResponseError
    >
    rooms: OperatingRoom[]
    scheduleQueryResult: UseQueryResult<
        ResponseList<ScheduleOperationListItem[], ScheduleListMeta>
    >
    anesthesiologistSchedulesQueryResult: ReturnType<
        typeof useGetAnesthesiologistSchedules
    >
    isScheduleUpdating: boolean
    onChangeDate: (date: Date) => void
    filters: ScheduleFiltersForm
    filtersCount: number
    filtersExpanded: boolean
    setFiltersExpanded: React.Dispatch<React.SetStateAction<boolean>>
    handleResetFilters: () => void
    onConfirmButtonClick: () => void
    onAssignAnesthesiologist: OnAssignAnesthesiologist
    onChangeFilters: FormSubmitFn<ScheduleFiltersForm>
    onSelectOperation: (id: number) => void
    onLockDayChange: (action: 'lock' | 'unlock', date: Date) => void
    onDrop: OnDrop
    onDragStart?: OnDragStart
    onDragStop?: OnDragStop
    draggingItemId?: number
}> = ({
    view,
    lastApprovedDay,
    rooms,
    planQueryResult,
    scheduleQueryResult,
    isScheduleUpdating,
    anesthesiologistSchedulesQueryResult,
    onChangeDate,
    filters,
    filtersCount,
    filtersExpanded,
    handleResetFilters,
    setFiltersExpanded,
    onConfirmButtonClick,
    onAssignAnesthesiologist,
    onChangeFilters,
    onSelectOperation,
    onLockDayChange,
    onDrop,
    onDragStart,
    onDragStop,
    draggingItemId,
}) => {
    const location = useLocation()
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    return (
        <ListLayout
            className={`${view}-schedule ${view}-schedule-edit`}
            renderTitle={
                <span className="flex items-center space-x-2">
                    <PencilIcon className="w-6 h-6 text-blue-500" />
                    <span className="font-medium print:font-normal print:text-3xl">
                        Edycja planu operacyjnego
                    </span>
                </span>
            }
            renderCenter={
                <HeaderCenter
                    date={moment(filters.today)}
                    isLoading={
                        scheduleQueryResult.isLoading ||
                        planQueryResult.isLoading ||
                        planQueryResult.isFetching ||
                        anesthesiologistSchedulesQueryResult.isLoading ||
                        isScheduleUpdating
                    }
                    onChangeDate={onChangeDate}
                />
            }
            actions={
                <div className="flex items-center space-x-4">
                    <ButtonPrint />
                    <Button
                        as="link"
                        size="sm"
                        variant="default"
                        to={`/schedule${
                            view === 'daily' ? '/hourly' : ''
                        }/edit`}
                        state={{ date: filters.today }}
                    >
                        <span className="whitespace-nowrap">
                            {view === 'hourly' && <span>Widok ogólny</span>}
                            {view === 'daily' && <span>Widok godzinowy</span>}
                        </span>
                    </Button>
                    <FilterButton
                        count={filtersCount}
                        filtersExpanded={filtersExpanded}
                        handleReset={handleResetFilters}
                        onClick={() => setFiltersExpanded((state) => !state)}
                    />
                    {!location.state?.date && (
                        <Button
                            className="ml-4"
                            variant="primary"
                            onClick={onConfirmButtonClick}
                            disabled={
                                !schedulePlannerPolicyGroup.approvedDay
                                    .canCreate ||
                                (lastApprovedDay
                                    ? moment(filters.today)
                                          .startOf('day')
                                          .isSameOrBefore(
                                              moment(lastApprovedDay)
                                          )
                                    : false)
                            }
                        >
                            Zatwierdź
                        </Button>
                    )}
                    {!!location.state?.date && (
                        <Button
                            as="link"
                            className="ml-4"
                            variant="primary"
                            to={`/schedule${
                                view === 'hourly' ? '/hourly' : ''
                            }`}
                            state={{ date: filters.today }}
                        >
                            Zakończ
                        </Button>
                    )}
                </div>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters
                        filters={filters}
                        rooms={rooms}
                        handleChange={onChangeFilters}
                    />
                )}
                <div className="relative z-0">
                    <div className="overflow-y-auto">
                        <DndProvider backend={HTML5Backend}>
                            <ScrollingComponent
                                id="schedule-scrolling-component"
                                className="flex flex-auto flex-col overflow-auto bg-white rounded-md"
                                style={{ height: 'calc(100vh - 182px)' }}
                            >
                                <div className="flex min-h-full max-w-full flex-none flex-col divide-y-2 divide-gray-200 sm:max-w-none md:max-w-full">
                                    <div className="sticky z-30 top-0 flex-none bg-white ring-1 ring-gray-200">
                                        <OperatingRoomBar
                                            editable={true}
                                            columnHour={view === 'hourly'}
                                            columnUnassigned={true}
                                            rooms={rooms}
                                            date={moment(filters.today)}
                                            meta={
                                                scheduleQueryResult.data?.meta
                                            }
                                            anesthesiologistSchedulesQueryResult={
                                                anesthesiologistSchedulesQueryResult
                                            }
                                            onLockDayChange={onLockDayChange}
                                            onAssignAnesthesiologist={
                                                onAssignAnesthesiologist
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-auto divide-x-2 divide-gray-200">
                                        {view === 'hourly' && (
                                            <div className="w-24 flex-none bg-white" />
                                        )}
                                        <div className="grid flex-auto grid-cols-1 grid-rows-1">
                                            {view === 'hourly' && (
                                                <>
                                                    <PlannerHorizontalLines />
                                                    <HorizontalLines />
                                                </>
                                            )}

                                            {/* Vertical lines */}
                                            <VerticalLines
                                                columnUnassigned={true}
                                                cols={rooms.length}
                                            />

                                            {scheduleQueryResult.isSuccess && (
                                                <>
                                                    {view === 'hourly' &&
                                                        schedulePlannerPolicyGroup.canUpdate &&
                                                        (schedulePlannerPolicyGroup
                                                            .user
                                                            .isSuperAdmin ||
                                                            schedulePlannerPolicyGroup
                                                                .user.isAdmin ||
                                                            schedulePlannerPolicyGroup
                                                                .user
                                                                .isPlanner) && (
                                                            <DropZone
                                                                date={
                                                                    filters.today
                                                                }
                                                                planQueryResult={
                                                                    planQueryResult
                                                                }
                                                                rooms={rooms}
                                                                onDrop={onDrop}
                                                            />
                                                        )}
                                                    {view === 'hourly' && (
                                                        <HourlyGrid
                                                            draggable
                                                            columnUnassigned={
                                                                true
                                                            }
                                                            rooms={rooms}
                                                            date={moment(
                                                                filters.today
                                                            )}
                                                            operations={
                                                                scheduleQueryResult
                                                                    .data?.data
                                                            }
                                                            onSelect={
                                                                onSelectOperation
                                                            }
                                                            onDragStart={
                                                                onDragStart
                                                            }
                                                            onDragStop={
                                                                onDragStop
                                                            }
                                                            onDrop={onDrop}
                                                        />
                                                    )}
                                                    {view === 'daily' && (
                                                        <DailyGrid
                                                            draggable
                                                            date={moment(
                                                                filters.today
                                                            )}
                                                            rooms={rooms}
                                                            operations={
                                                                scheduleQueryResult
                                                                    .data.data
                                                            }
                                                            onSelect={
                                                                onSelectOperation
                                                            }
                                                            onDragStart={
                                                                onDragStart
                                                            }
                                                            onDragStop={
                                                                onDragStop
                                                            }
                                                            onDrop={onDrop}
                                                            planQueryResult={
                                                                planQueryResult
                                                            }
                                                            draggingItemId={
                                                                draggingItemId
                                                            }
                                                            isScheduleUpdating={
                                                                isScheduleUpdating
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ScrollingComponent>
                        </DndProvider>
                    </div>
                </div>
            </>
        </ListLayout>
    )
}

export default SchedulePlanner
