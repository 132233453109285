export const RADIO_GROUP_YES_NO_OPTIONS = [
    {
        name: 'Tak',
        value: true,
    },
    {
        name: 'Nie',
        value: false,
    },
]

export const PREFERRED_BEGINNING_HOUR_ITEMS = [
    { id: '7-12', name: '7-12' },
    { id: '12-15', name: '12-15' },
    { id: '15-19', name: '15-19' },
]

export const OPERATION_TYPES = [
    { id: 'small', name: 'Operacja mała' },
    { id: 'large', name: 'Operacja duża' },
    { id: 'longterm', name: 'Operacja długoterminowa' },
    { id: 'local', name: 'Operacja miejscowa' },
]

export const ANESTHESIA_TYPES = [
    { id: 'local', name: 'Znieczulenie miejscowe' },
    { id: 'with_anesthesiologist', name: 'Znieczulenie z anestezjologiem' },
]
