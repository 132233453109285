import React from 'react'

import { StatisticsOperationResponse } from 'api/types'
import OperationDurations from './OperationDurations'

const StatisticsSurgeryStatuses: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    return (
        <div>
            <div className="text-lg leading-6 font-medium text-gray-900">
                Czasy operacji
            </div>
            <div className="mt-6">
                <OperationDurations durations={data.durations} />
            </div>
        </div>
    )
}

export default StatisticsSurgeryStatuses
