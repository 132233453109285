import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useGetPayers, useUpdatePayer } from 'api'
import { listReducer } from 'helpers'
import { useModal, usePayerPolicy } from 'hooks'
import SettingsLayout from 'layouts/SettingsLayout'
import { AccessDenied } from 'components'
import { ActionButton } from 'components/ui'
import SettingsPayerList from 'components/SettingsPayerList'

import type { GetPayersResponse } from 'api/types'
import CreatePayerModal from './SettingsPayerCreateModal'

export default function SettingsPayerListContainer() {
    const payerPolicy = usePayerPolicy()
    const queryClient = useQueryClient()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const payers = useGetPayers(filters, {
        enabled: payerPolicy.canAll,
    })
    const updatePayer = useUpdatePayer()
    const modal = useModal()

    const handleActiveToggle = (id: number, active: boolean) => {
        updatePayer.mutate(
            { id, data: { active } },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(['payers', { active: true }])

                    const previousPayers =
                        queryClient.getQueryData<GetPayersResponse>([
                            'payers',
                            filters,
                        ])

                    if (previousPayers) {
                        queryClient.setQueryData<GetPayersResponse>(
                            ['payers', filters],
                            {
                                ...previousPayers,
                                data: previousPayers.data.map((item) =>
                                    item.id === id
                                        ? {
                                              ...item,
                                              ...data.data,
                                          }
                                        : item
                                ),
                            }
                        )
                    }
                },
            }
        )
    }

    const handleEstimatesAvailableToggle = (
        id: number,
        estimates_available: boolean
    ) => {
        updatePayer.mutate(
            { id, data: { estimates_available } },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries([
                        'payers',
                        { estimates_available: true },
                    ])

                    const previousPayers =
                        queryClient.getQueryData<GetPayersResponse>([
                            'payers',
                            filters,
                        ])

                    if (previousPayers) {
                        queryClient.setQueryData<GetPayersResponse>(
                            ['payers', filters],
                            {
                                ...previousPayers,
                                data: previousPayers.data.map((item) =>
                                    item.id === id
                                        ? {
                                              ...item,
                                              ...data.data,
                                          }
                                        : item
                                ),
                            }
                        )
                    }
                },
            }
        )
    }

    const handleSubmit = async () => {
        modal.closeModal()
        await payers.refetch()
    }

    return (
        <SettingsLayout
            actions={
                payerPolicy.canAll && (
                    <ActionButton
                        handleClick={modal.openModal}
                        rightIcon={PlusIcon}
                    >
                        Dodaj
                    </ActionButton>
                )
            }
        >
            <>
                {!payerPolicy.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania kontrahentami" />
                )}
                {payerPolicy.canAll && (
                    <>
                        <SettingsPayerList
                            payers={payers}
                            modal={modal}
                            setFilters={setFilters}
                            handleSubmit={handleSubmit}
                            handleActiveToggle={handleActiveToggle}
                            handleEstimatesAvailableToggle={
                                handleEstimatesAvailableToggle
                            }
                        />
                        <CreatePayerModal
                            modal={modal}
                            handleSubmit={handleSubmit}
                        />
                    </>
                )}
            </>
        </SettingsLayout>
    )
}
