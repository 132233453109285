import React, { useContext, useMemo, useReducer } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
    useGetOperation,
    useGetPatient,
    usePostOperationClosure,
    transformClosureFormToClosurePostRequest,
    usePatchOperationClosure,
    useGetOperationClosure,
    useClosureTemplateList,
    setSurgicalTeamErrors,
} from 'api'
import ListLayout from 'layouts/ListLayout'
import { useDoctorSurgeryPolicyGroup } from 'hooks'
import { API_FORMAT_DATE, API_FORMAT_DATETIME } from 'constants/index'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import { DropdownItem } from 'components/forms'
import DoctorSurgeryClosure, {
    validateHour,
} from 'components/DoctorSurgeryClosure'
import { ICD_CODE_SEPARATOR } from 'components/forms/ICDCodeField'
import alertContext from 'contexts/alerts/alertContext'

import type {
    DoctorSurgeryClosureForm as IDoctorSurgeryClosureForm,
    FormSubmitFn,
} from 'types'
import { OperationResponse, PatientResponse } from 'api/types'

const genders: DropdownItem[] = [
    {
        id: 1,
        name: 'Kobieta',
    },
    {
        id: 2,
        name: 'Mężczyzna',
    },
]

const GENDER_MAP: Record<string, DropdownItem> = {
    female: genders[0],
    male: genders[1],
}

const getHourFromAPIDate = (date?: string | null, format = 'HH:mm') =>
    moment(date, API_FORMAT_DATETIME).format(format)

export type SectionState = Record<string, { open: boolean; editing: boolean }>
export type SectionStateAction = { type: string; payload: string }

const initialSectionState = {
    information: {
        open: true,
        editing: false,
    },
    surgeryTeam: {
        open: false,
        editing: false,
    },
}

export function sectionStateReducer(
    state: SectionState,
    action: SectionStateAction
) {
    switch (action.type) {
        case 'toggle':
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    editing: state[action.payload].open
                        ? false
                        : state[action.payload].editing,
                    open: !state[action.payload].open,
                },
            }

        case 'toggleEdit':
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    editing: !state[action.payload].editing,
                    open: !state[action.payload].editing
                        ? true
                        : state[action.payload].open,
                },
            }

        default:
            return state
    }
}

const DoctorSurgeryClosureContainer = () => {
    const queryClient = useQueryClient()
    const params = useParams()
    const navigate = useNavigate()
    const doctorSurgeryClosurePolicy = useDoctorSurgeryPolicyGroup()
    const { add: alert } = useContext(alertContext)

    const operationId = !isNaN(Number(params.id))
        ? Number(params.id)
        : undefined

    const closureId = !isNaN(Number(params.closureId))
        ? Number(params.closureId)
        : undefined

    const operationQueryResult = useGetOperation(operationId!, {
        enabled: doctorSurgeryClosurePolicy.canShowClosure && !!operationId,
    })

    const patientQueryResult = useGetPatient(
        operationQueryResult.data?.data.patient?.id,
        {
            enabled:
                doctorSurgeryClosurePolicy.canShowClosure &&
                operationQueryResult.isSuccess &&
                !!operationQueryResult.data?.data.patient,
        }
    )

    const closureResultQuery = useGetOperationClosure(
        { id: closureId },
        {
            enabled: doctorSurgeryClosurePolicy.canShowClosure && !!closureId,
        }
    )

    const closureTemplateListQuery = useClosureTemplateList(
        {
            pagination: false,
        },
        {
            enabled: doctorSurgeryClosurePolicy.canShowClosure,
        }
    )

    const { mutate: create } = usePostOperationClosure()
    const { mutate: update } = usePatchOperationClosure()

    const [sectionState, sectionStateDispatch] = useReducer(
        sectionStateReducer,
        initialSectionState
    )

    const handleSubmit: FormSubmitFn<IDoctorSurgeryClosureForm> = (
        values,
        formikHelpers
    ) => {
        if (closureId) {
            const data = transformClosureFormToClosurePostRequest({
                ...values,
                completed: !values._isDraft,
            })

            return update(
                {
                    id: closureId,
                    data,
                },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries(['operations'])
                        await queryClient.invalidateQueries(['patients'])
                        await queryClient.invalidateQueries([
                            'operation-closures',
                        ])
                        alert({
                            content: values._isDraft
                                ? 'Szkic opisu operacji został zapisany.'
                                : 'Opis operacji został podpisany.',
                            type: 'success',
                        })

                        formikHelpers.setSubmitting(false)
                        navigate('/surgeries/performed')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)

                        setSurgicalTeamErrors(
                            data.surgical_team,
                            error.errors,
                            formikHelpers.setFieldError
                        )
                    },
                }
            )
        }

        const data = transformClosureFormToClosurePostRequest({
            ...values,
            completed: !values._isDraft,
        })

        create(data, {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['operations'])
                await queryClient.invalidateQueries(['patients'])
                await queryClient.invalidateQueries(['operation-closures'])
                alert({
                    content: values._isDraft
                        ? 'Szkic opisu operacji został utworzony.'
                        : 'Opis operacji został podpisany.',
                    type: 'success',
                })
                formikHelpers.setSubmitting(false)
                navigate('/surgeries/performed')
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)

                setSurgicalTeamErrors(
                    data.surgical_team,
                    error.errors,
                    formikHelpers.setFieldError
                )
            },
        })
    }

    if (!doctorSurgeryClosurePolicy.canShowClosure) {
        return (
            <AccessDenied message="Nie ma uprawnień do tworzenia opisu operacji" />
        )
    }

    if (
        operationQueryResult.isLoading ||
        patientQueryResult.isLoading ||
        closureResultQuery.isLoading ||
        closureTemplateListQuery.isLoading
    ) {
        return <Loader />
    }

    if (operationQueryResult.isError) {
        return <div>{operationQueryResult.error.message}</div>
    }

    if (patientQueryResult.isError) {
        return <div>{patientQueryResult.error.message}</div>
    }

    if (closureResultQuery.isError) {
        return <div>{closureResultQuery.error.message}</div>
    }

    if (
        !operationQueryResult.data ||
        !patientQueryResult.data ||
        !closureTemplateListQuery.data
    ) {
        return null
    }

    return (
        <ListLayout title="Opis operacji">
            <DoctorSurgeryClosureForm
                closureResultQuery={closureResultQuery}
                operation={operationQueryResult.data.data}
                patient={patientQueryResult.data.data}
                onSubmit={handleSubmit}
            >
                <DoctorSurgeryClosure
                    data={operationQueryResult.data.data}
                    closureTemplateList={closureTemplateListQuery.data.data}
                    sectionState={sectionState}
                    sectionStateDispatch={sectionStateDispatch}
                />
            </DoctorSurgeryClosureForm>
        </ListLayout>
    )
}

const DoctorSurgeryClosureForm = ({
    closureResultQuery,
    operation,
    patient,
    onSubmit,
    children,
}: React.PropsWithChildren<{
    closureResultQuery: ReturnType<typeof useGetOperationClosure>
    operation: OperationResponse
    patient: PatientResponse
    onSubmit: FormSubmitFn<IDoctorSurgeryClosureForm>
    children: React.ReactNode
}>) => {
    const _ended_on = useMemo(() => {
        if (closureResultQuery.data?.data.ended_at) {
            const hour = validateHour(
                getHourFromAPIDate(closureResultQuery.data.data.ended_at)
            )

            if (hour) {
                return hour
            }
        }

        if (operation.real_operation_end) {
            const hour = validateHour(
                getHourFromAPIDate(operation.real_operation_end)
            )

            if (hour) {
                return hour
            }
        }

        return (
            validateHour(getHourFromAPIDate(operation.finish_at)) || undefined
        )
    }, [closureResultQuery.data, operation])

    return (
        <Formik<IDoctorSurgeryClosureForm>
            initialValues={{
                _isDraft: false,
                operation_id: operation.id,
                completed: closureResultQuery.data?.data.completed || false,
                _pesel: patient.pesel || '',
                _identity_card: patient.identity_card || '',
                _first_name: patient.first_name || '',
                _last_name: patient.last_name || '',
                _date_of_birth: patient.date_of_birth || '',
                _gender: patient.gender ? GENDER_MAP[patient.gender] : null,
                _phone: patient.phone || '',
                _email: patient.email || '',
                diagnosis:
                    closureResultQuery.data?.data.diagnosis ||
                    operation.diagnosis ||
                    '',
                ledger: closureResultQuery.data?.data.ledger || '',
                description: closureResultQuery.data?.data.description || '',
                _started_at: closureResultQuery.data?.data.started_at
                    ? moment(closureResultQuery.data.data.started_at).format(
                          API_FORMAT_DATE
                      )
                    : operation.starts_at
                    ? moment(operation.starts_at).format(API_FORMAT_DATE)
                    : operation.estimated_date
                    ? moment(operation.estimated_date).format(API_FORMAT_DATE)
                    : '',
                _started_on:
                    validateHour(
                        getHourFromAPIDate(
                            closureResultQuery.data?.data.started_at
                        )
                    ) ||
                    validateHour(
                        getHourFromAPIDate(operation.real_operation_start)
                    ) ||
                    validateHour(getHourFromAPIDate(operation.starts_at)) ||
                    undefined,
                _ended_on: _ended_on,
                icd9:
                    closureResultQuery.data?.data.icd9 ||
                    operation.procedure?.icd9 ||
                    undefined,
                icd9_phrase: closureResultQuery.data?.data.icd9
                    ? closureResultQuery.data.data.icd9.code +
                      ICD_CODE_SEPARATOR +
                      closureResultQuery.data.data.icd9.description
                    : operation.procedure?.icd9
                    ? operation.procedure.icd9.code +
                      ICD_CODE_SEPARATOR +
                      operation.procedure.icd9.description
                    : '',
                icd_description:
                    closureResultQuery.data?.data.icd_description || '',
                icd10: closureResultQuery.data?.data.icd10 || undefined,
                icd10_phrase: closureResultQuery.data?.data.icd10
                    ? closureResultQuery.data.data.icd10.code +
                      ICD_CODE_SEPARATOR +
                      closureResultQuery.data.data.icd10.description
                    : '',
                icd10_description:
                    closureResultQuery.data?.data.icd10_description || '',
                diagnosis_after_surgery:
                    closureResultQuery.data?.data.diagnosis_after_surgery || '',
                doctor:
                    operation.surgical_team.find(
                        (item) => item.role === 'doctor'
                    ) ?? undefined,
                assistant: operation.surgical_team.find(
                    (item) => item.role === 'assistant'
                )
                    ? operation.surgical_team.filter(
                          (item) => item.role === 'assistant'
                      )
                    : [null],
                anesthesiologist: operation.surgical_team.find(
                    (item) => item.role === 'anesthesiologist'
                )
                    ? operation.surgical_team.filter(
                          (item) => item.role === 'anesthesiologist'
                      )
                    : [null],
                anesthesiologist_nurse: operation.surgical_team.find(
                    (item) => item.role === 'anesthesiologist_nurse'
                )
                    ? operation.surgical_team.filter(
                          (item) => item.role === 'anesthesiologist_nurse'
                      )
                    : [null],
                operation_nurse: operation.surgical_team.find(
                    (item) => item.role === 'operation_nurse'
                )
                    ? operation.surgical_team.filter(
                          (item) => item.role === 'operation_nurse'
                      )
                    : [null],
                staff_recommendation:
                    closureResultQuery.data?.data.staff_recommendation || '',
                post_operation_recommendation:
                    closureResultQuery.data?.data
                        .post_operation_recommendation || '',
                physiotherapist_recommendation:
                    closureResultQuery.data?.data
                        .physiotherapist_recommendation || '',
                discharge_recommendation:
                    closureResultQuery.data?.data.discharge_recommendation ||
                    '',
                in_hospital_visit: closureResultQuery.data?.data
                    .in_hospital_visit
                    ? moment(
                          closureResultQuery.data.data.in_hospital_visit
                      ).format(API_FORMAT_DATE)
                    : '',
                in_hospital_visit_time: closureResultQuery.data?.data
                    .in_hospital_visit_time
                    ? {
                          id: closureResultQuery.data.data
                              .in_hospital_visit_time,
                          name: closureResultQuery.data.data.in_hospital_visit_time.slice(
                              0,
                              5
                          ),
                      }
                    : undefined,
                histopathology_material:
                    closureResultQuery.data?.data.histopathology_material,
                bacteriology_material:
                    closureResultQuery.data?.data.bacteriology_material,
                implants_used: closureResultQuery.data?.data.implants_used,
                _closureTemplate: undefined,
            }}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            {children}
        </Formik>
    )
}

export default DoctorSurgeryClosureContainer
