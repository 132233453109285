import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/solid'

import { useGetComments, useGetStatisticsOperation } from 'api'
import { useCommentPolicy, useStatisticsPolicy } from 'hooks'
import ListLayout from 'layouts/ListLayout'
import { Link, Loader } from 'components/ui'
import { AccessDenied } from 'components'
import StatisticsSurgery from 'components/StatisticsSurgery'

const StatisticsSurgeryContainer = () => {
    const { id } = useParams()
    const location = useLocation()
    const statisticsPolicy = useStatisticsPolicy()
    const commentsPolicy = useCommentPolicy()

    const operationResult = useGetStatisticsOperation(id, {
        enabled: statisticsPolicy.canSee,
    })

    const commentsResult = useGetComments(
        {
            operation_id: id,
            type: 'schedule',
        },
        { enabled: !!id && commentsPolicy.canIndex }
    )

    const backUrl = location.state?.backUrl || '/statistics/surgeries'

    if (!statisticsPolicy.canSee) {
        return <AccessDenied message="Nie masz dostępu do statystyk" />
    }

    return (
        <ListLayout
            title="Karta operacji"
            actions={
                <div className="flex items-center space-x-6">
                    <Link to={backUrl}>
                        <ArrowLeftIcon className="w-4 mr-1.5" /> Wróć
                    </Link>
                    <Link to="/statistics">Statystyki</Link>
                </div>
            }
        >
            <>
                {(operationResult.isLoading || commentsResult.isLoading) && (
                    <Loader />
                )}
                {operationResult.isError && (
                    <p>{operationResult.error.message}</p>
                )}
                {operationResult.isSuccess && (
                    <StatisticsSurgery
                        data={operationResult.data.data}
                        comments={commentsResult.data?.data || []}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default StatisticsSurgeryContainer
