import React from 'react'

import { StatisticsOperationResponse, Comment } from 'api/types'

import StatisticsSurgeryAdvances from 'components/StatisticsSurgeryAdvances'
import StatisticsSurgeryEstimatedCost from 'components/StatisticsSurgeryEstimatedCost'
import StatisticsSurgeryExtraCosts from 'components/StatisticsSurgeryExtraCosts'
import StatisticsSurgeryFinancing from 'components/StatisticsSurgeryFinancing'
import StatisticsSurgeryImplants from 'components/StatisticsSurgeryImplants'
import StatisticsSurgeryInfo from 'components/StatisticsSurgeryInfo'
import StatisticsSurgeryMedicalEquipment from 'components/StatisticsSurgeryMedicalEquipment'
import StatisticsSurgeryMedicalTeam from 'components/StatisticsSurgeryMedicalTeam'
import StatisticsSurgeryStatuses from 'components/StatisticsSurgeryStatuses'

const StatisticsSurgery: React.FC<{
    data: StatisticsOperationResponse
    comments: Comment[]
}> = ({ data, comments }) => {
    return (
        <div className="p-6 rounded-md bg-white">
            <div className="py-6">
                <div className="flex">
                    <div className="w-1/2">
                        <StatisticsSurgeryInfo data={data} />

                        <div className="py-8 border-b border-gray-200">
                            <StatisticsSurgeryFinancing data={data} />
                        </div>
                        <div className="py-8 border-b border-gray-200">
                            <StatisticsSurgeryImplants data={data} />
                        </div>
                        <div className="py-8 border-b border-gray-200">
                            <StatisticsSurgeryMedicalEquipment data={data} />
                        </div>
                        <div className="py-8 border-b border-gray-200">
                            <StatisticsSurgeryExtraCosts data={data} />
                        </div>

                        <div className="pt-8">
                            <StatisticsSurgeryAdvances data={data} />
                        </div>
                    </div>

                    <div className="w-px bg-gray-100 flex-shrink-0 mx-8" />

                    <div className="w-1/2 flex flex-col gap-4">
                        <div className="border-b border-gray-100 pb-4 flex flex-col gap-4">
                            <StatisticsSurgeryMedicalTeam
                                data={data}
                                comments={comments}
                            />
                            <StatisticsSurgeryStatuses data={data} />
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-100 mt-8 pt-8">
                    <StatisticsSurgeryEstimatedCost data={data} />
                </div>
            </div>
        </div>
    )
}

export default StatisticsSurgery
