import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import axios from 'api/axios'

import type {
    ResponseList,
    ResponseError,
    Pagination,
    OperationImplantsHistoryItem,
} from 'api/types'

export const getOperationImplantsHistory = <T>(
    operationId: number,
    filters?: {}
): Promise<T> =>
    axios.get(
        `/operations/${operationId}/implants-history` +
            (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useGetOperationImplantsHistory = <
    T = ResponseList<
        OperationImplantsHistoryItem[],
        Pagination & {
            operation: {
                causer: {
                    id: number
                    first_name: string
                    last_name: string
                    full_name: string
                    avatar_url: string
                }
                implants: {
                    id?: number
                    name: string
                }[]
                created_at: string
            }
        }
    >
>(
    operationId: number,
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['operation-implants-history', operationId, filters],
        () => getOperationImplantsHistory<T>(operationId, filters),
        options
    )
