import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/outline'

import { FormSubmitFn } from 'types'
import {
    PostProcedureCategory,
    ProcedureCategory,
    ResponseData,
} from 'api/types'

import Button from 'components/ui/Button'
import { TextField } from 'components/forms'

const colors = [
    { base: '#A5F3FC', darker: '', border: '', fontColor: '' },
    { base: '#A7F3D0', darker: '', border: '', fontColor: '' },
    { base: '#FED7AA', darker: '', border: '', fontColor: '' },
    { base: '#FBCFE8', darker: '', border: '', fontColor: '' },
    { base: '#F3F4F6', darker: '', border: '', fontColor: '' },
    { base: '#EAFEDB', darker: '', border: '', fontColor: '' },
    { base: '#DACEBE', darker: '', border: '', fontColor: '' },
    { base: '#FDE68A', darker: '', border: '', fontColor: '' },
    { base: '#FECACA', darker: '', border: '', fontColor: '' },
    { base: '#EEEBE9', darker: '', border: '', fontColor: '' },
    { base: '#EDE9FE', darker: '', border: '', fontColor: '' },
    { base: '#DBEAFE', darker: '', border: '', fontColor: '' },
    { base: '#9ca3af', darker: '', border: '', fontColor: '' },
    { base: '#ef4444', darker: '', border: '', fontColor: '' },
    { base: '#f59e0b', darker: '', border: '', fontColor: '' },
    { base: '#10b981', darker: '', border: '', fontColor: '' },
    { base: '#3b82f6', darker: '', border: '', fontColor: '' },
    { base: '#a78bfa', darker: '', border: '', fontColor: '' },
    { base: '#ec4899', darker: '', border: '', fontColor: '' },
    { base: '#991b1b', darker: '', border: '', fontColor: '' },
    { base: '#92400e', darker: '', border: '', fontColor: '' },
    { base: '#065f46', darker: '', border: '', fontColor: '' },
    { base: '#1e40af', darker: '', border: '', fontColor: '' },
    { base: '#5b21b6', darker: '', border: '', fontColor: '' },
]

export default function SettingsProcedureCategoryForm({
    id,
    data,
    handleSubmit,
}: {
    id?: string
    data?: ResponseData<ProcedureCategory>
    handleSubmit: FormSubmitFn<PostProcedureCategory>
}) {
    return (
        <div className="py-6">
            <div className="px-4 mb-4 sm:px-6 md:px-8">
                <div className="flex gap-4 items-center justify-between">
                    <div className="flex-none rounded-lg flex items-center justify-center">
                        <h2 className="text-2xl leading-8">
                            {!id && (
                                <span className="font-semibold">Dodaj:</span>
                            )}
                            {!!id && (
                                <span className="font-semibold">Edytuj:</span>
                            )}
                            &nbsp;<span className="font-light">Kategorię</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="px-4 mb-4 sm:px-6 md:px-8">
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <section aria-labelledby="payment-details-heading">
                        <Formik
                            initialValues={
                                data?.data || { name: '', color: '' }
                            }
                            onSubmit={handleSubmit}
                        >
                            {({
                                isSubmitting,
                                values,
                                errors,
                                setFieldValue,
                            }) => (
                                <Form>
                                    <div className="sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white pt-12 px-6">
                                            <div className="border-b">
                                                <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                                    <div className="col-span-6">
                                                        <div className="pr-8">
                                                            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                                Kategoria
                                                            </div>
                                                            <TextField
                                                                name="name"
                                                                labelText="Nazwa"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6">
                                                        <div className="pl-8">
                                                            <div className="mb-5 text-lg leading-6 font-medium text-gray-900">
                                                                Kolorystyka
                                                            </div>
                                                            <div className="">
                                                                <RadioGroup
                                                                    value={
                                                                        values.color
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            'color',
                                                                            value
                                                                        )
                                                                    }
                                                                >
                                                                    <RadioGroup.Label className="block text-sm font-medium text-gray-700">
                                                                        Kolor
                                                                        kategorii
                                                                    </RadioGroup.Label>
                                                                    <div className="mt-4 flex">
                                                                        <div className="grid grid-cols-6 gap-x-2 gap-y-4">
                                                                            {colors.map(
                                                                                (
                                                                                    color,
                                                                                    index
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <RadioGroup.Option
                                                                                            value={
                                                                                                color.base
                                                                                            }
                                                                                            className={({
                                                                                                active,
                                                                                                checked,
                                                                                            }) =>
                                                                                                classNames(
                                                                                                    '-m-0.5 relative p-0.5 inline-flex items-center justify-center cursor-pointer focus:outline-none',
                                                                                                    {
                                                                                                        'ring-2':
                                                                                                            checked,
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        color.base,
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    'h-10 w-10 border border-black border-opacity-10 rounded-md'
                                                                                                )}
                                                                                            />
                                                                                        </RadioGroup.Option>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </RadioGroup>
                                                                {errors.color && (
                                                                    <p className="mt-1 text-sm text-red-600">
                                                                        {
                                                                            errors.color
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white px-6 py-12 text-right">
                                            <Link
                                                to="/settings/procedure-categories"
                                                className="bg-white py-2 px-4 border border-gray-300 inline-flex rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                                            >
                                                Anuluj
                                            </Link>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={<PlusIcon />}
                                            >
                                                Zapisz
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </section>
                </div>
            </div>
        </div>
    )
}
