import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    ResponseList,
    ResponseError,
    ClosureTemplate,
    ResponseData,
    ResponseNoContent,
} from 'api/types'

import type { ListFilters } from 'helpers'

export const useClosureTemplateList = <
    F = { pagination: boolean } | ListFilters,
    R = ResponseList<ClosureTemplate[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['closure-templates', filters],
        () =>
            client.get(
                '/closure-templates' +
                    (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const useGetClosureTemplate = <R extends ResponseData<ClosureTemplate>>(
    id: number,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['closure-templates', 'id', id],
        () => client.get('/closure-templates/' + id),
        options
    )

export const usePostClosureTemplate = <
    R1 extends { data: Partial<Omit<ClosureTemplate, 'id'>> },
    R2 = ResponseData<ClosureTemplate>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ data }) => client.post('/closure-templates', data))

export const usePatchClosureTemplate = <
    R1 extends { id: number; data: Partial<ClosureTemplate> },
    R2 = ResponseData<ClosureTemplate>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id, data }) =>
        client.patch('/closure-templates/' + id, data)
    )

export const useDeleteClosureTemplate = <
    R1 extends { id: number },
    R2 = ResponseNoContent
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id }) => client.delete('/closure-templates/' + id))
