import React from 'react'
import { Form, Formik } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import { Button, Label } from 'components/ui'
import { ActionBar, TextAreaField, TextField } from 'components/forms'

import type { ResponseData, ClosureTemplate } from 'api/types'
import type {
    ClosureTemplateForm as TClosureTemplateForm,
    FormSubmitFn,
} from 'types'

const ClosureTemplateForm: React.FC<{
    data?: ResponseData<ClosureTemplate>
    onSubmit: FormSubmitFn<TClosureTemplateForm>
    onDelete?: (id: number) => void
}> = ({ data, onSubmit, onDelete }) => {
    return (
        <Formik
            initialValues={{
                name: data?.data.name || '',
                content: data?.data.content || '',
            }}
            onSubmit={onSubmit}
        >
            <Form>
                <div className="mt-4 rounded-md bg-white">
                    <div className="divide-gray-200 divide-y">
                        <div className="mb-8 pt-12 px-6">
                            <div className="text-gray-700">
                                <section>
                                    <div className="text-lg leading-6 font-medium text-gray-900">
                                        Szablon
                                    </div>
                                    <div className="mt-6">
                                        <Label>Nazwa szablonu</Label>
                                        <TextField name="name" />
                                    </div>
                                    <div className="mt-6">
                                        <Label>Treść szablonu</Label>
                                        <TextAreaField
                                            name="content"
                                            rows={23}
                                        />
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="mx-6 py-12">
                            <ActionBar
                                leftSide={({ isSubmitting }) => (
                                    <>
                                        {!!data && !!onDelete && (
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={() =>
                                                    onDelete(data.data.id)
                                                }
                                            >
                                                Usuń
                                            </Button>
                                        )}
                                    </>
                                )}
                                rightSide={({ isSubmitting }) => (
                                    <div className="space-x-3">
                                        <Button
                                            as="link"
                                            to="/closures/templates"
                                        >
                                            Anuluj
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="ml-3"
                                            loading={isSubmitting}
                                            iconRight={<PlusIcon />}
                                        >
                                            Zapisz
                                        </Button>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}

export default ClosureTemplateForm
