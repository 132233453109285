import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
    useCreateProcedureCategory,
    useUpdateProcedureCategory,
    useGetProcedureCategory,
} from 'api'
import { useProcedureCategoryPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsProcedureCategoryForm from 'components/SettingsProcedureCategoryForm'

import type {
    PostProcedureCategory,
    ProcedureCategory,
    ResponseData,
} from 'api/types'
import type { FormSubmitFn } from 'types'

const SettingsProcedureCategoryFormContainer: React.FC<{
    id?: string
    data?: ResponseData<ProcedureCategory>
}> = ({ id, data }) => {
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const { mutate: createProcedureCategory } = useCreateProcedureCategory()
    const { mutate: updateProcedureCategory } = useUpdateProcedureCategory()

    const handleSubmit: FormSubmitFn<PostProcedureCategory> = (
        values,
        formikHelpers
    ) => {
        if (id) {
            return updateProcedureCategory(
                { id: Number(id), ...values } as ProcedureCategory,
                {
                    onSuccess: ({ data }) => {
                        formikHelpers.setSubmitting(false)
                        queryClient.invalidateQueries('procedure-categories')
                        navigate('/settings/procedure-categories')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }
        createProcedureCategory(values, {
            onSuccess: ({ data }) => {
                formikHelpers.setSubmitting(false)
                queryClient.invalidateQueries('procedure-categories')
                navigate('/settings/procedure-categories')
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    if (!procedureCategoryPolicy.canAll) {
        return (
            <AccessDenied message="Nie masz dostępu do zarządzania kategoriami" />
        )
    }

    return (
        <SettingsProcedureCategoryForm
            id={id}
            data={data}
            handleSubmit={handleSubmit}
        />
    )
}

const SettingsProcedureCategoryFormEditContainer = () => {
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const { id } = useParams()
    const procedureCategory = useGetProcedureCategory<ProcedureCategory>(id!, {
        enabled: procedureCategoryPolicy.canAll && !!id,
    })

    if (procedureCategory.isLoading) {
        return <Loader />
    }

    if (procedureCategory.isError) {
        return <div>{procedureCategory.error.message}</div>
    }

    return (
        <SettingsProcedureCategoryFormContainer
            id={id}
            data={procedureCategory.data}
        />
    )
}

export default SettingsProcedureCategoryFormContainer
export { SettingsProcedureCategoryFormEditContainer as SettingsProcedureCategoryFormEdit }
