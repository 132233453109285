import React, { useRef, useState } from 'react'
import { useField } from 'formik'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'

import { Label } from 'components/ui'
import { useEventListener } from 'hooks'
import DropdownButton from './components/DropdownButton'
import DropdownOptions from './components/DropdownOptions'
import DropdownFieldProps from './interfaces/DropdownFieldProps'

import type { DropdownItem } from 'components/forms/interfaces/DropdownItem'

const DropdownField = <T extends DropdownItem>(
    props: DropdownFieldProps<T>
) => {
    const {
        name,
        label,
        placeholder,
        items,
        bulkOption = false,
        bulkOptionName = 'All',
        disabled = false,
        hasError = false,
        variant = 'md',
        renderValue,
        handleChange,
        withEmptyItem,
        tabIndex,
    } = props
    const ref = useRef<HTMLDivElement | null>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [field, meta, helpers] = useField(name)

    // @ts-ignore
    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEventListener('mousedown', handleClickOutside)

    return (
        <div ref={ref}>
            <Listbox
                value={field.value}
                onChange={(value) => {
                    helpers.setValue(value)
                    typeof handleChange === 'function' && handleChange(value)
                    setIsOpen(false)
                }}
                disabled={disabled}
            >
                {() => (
                    <>
                        {!!label && (
                            <Listbox.Label
                                className={classNames(
                                    'block text-sm font-medium text-gray-700',
                                    {
                                        'text-red-500':
                                            !disabled &&
                                            !disabled &&
                                            !!meta.error,
                                    }
                                )}
                            >
                                <Label>{label}</Label>
                            </Listbox.Label>
                        )}
                        <div className="relative">
                            <DropdownButton
                                value={field.value}
                                isOpen={isOpen}
                                variant={variant}
                                hasError={!!meta.error || hasError}
                                disabled={disabled}
                                bulkOption={bulkOption}
                                placeholder={placeholder}
                                onClick={() => setIsOpen(!isOpen)}
                                renderValue={renderValue}
                                tabIndex={tabIndex}
                            />
                            {!disabled && (
                                <DropdownOptions
                                    items={items}
                                    fieldValue={field.value}
                                    show={isOpen}
                                    bulkOption={bulkOption}
                                    bulkOptionName={bulkOptionName}
                                    withEmptyItem={withEmptyItem}
                                />
                            )}
                        </div>
                        {!disabled && !!meta.error && (
                            <p
                                className="mt-1 text-sm text-red-600"
                                id={`${field.name}-error`}
                            >
                                {meta.error}
                            </p>
                        )}
                    </>
                )}
            </Listbox>
        </div>
    )
}

export default DropdownField
