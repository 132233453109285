import React from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Button, Label } from 'components/ui'
import { EmployeePickerField, TextField } from 'components/forms'

import type { FormSubmitFn, RFIDCardForm, IModal } from 'types'

import modalStyles from 'components/Modal.module.scss'

const CARD_ID_LENGTH = 18

const schema = yup.object({
    number: yup
        .string()
        .test(
            'len',
            `Numer karty musi mieć dokładnie ${CARD_ID_LENGTH} znaków`,
            (val) => val?.length === CARD_ID_LENGTH
        ),
})

const SettingsRFIDCardCreateModal: React.FC<{
    modal: IModal
    handleSubmit: FormSubmitFn<RFIDCardForm>
}> = ({ modal, handleSubmit }) => {
    return (
        <Modal modal={modal}>
            <div className={modalStyles.inner}>
                <Dialog.Title as="h3" className={modalStyles.title}>
                    Dodaj kartę
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        number: '',
                        user: modal.getState() || undefined,
                        active: true,
                    }}
                    validationSchema={schema}
                >
                    {({ isSubmitting, submitCount }) => (
                        <Form>
                            <div className="mt-6 w-full">
                                <Label>Numer karty</Label>
                                <TextField
                                    name="number"
                                    errorMessage={submitCount > 0}
                                />
                            </div>
                            <div className="mt-6 w-full">
                                <EmployeePickerField
                                    name="user"
                                    placeholder="Wybierz osobę"
                                    functions={[]}
                                    messageEmptyResult="Nie znaleziono osoby."
                                />
                            </div>
                            <div className={modalStyles['action-container']}>
                                <div />
                                <div className="flex">
                                    <Button
                                        className="w-full mr-2"
                                        variant="default"
                                        type="button"
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        className="w-full"
                                        variant="primary"
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        Dodaj
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default SettingsRFIDCardCreateModal
