import React, { useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { UseQueryResult } from 'react-query'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/solid'
import { DocumentDuplicateIcon } from '@heroicons/react/outline'

import { formatDate } from 'helpers'
import {
    TextAreaField,
    DatePickerField,
    FileUploaderField,
} from 'components/forms'
import { Button, Loader } from 'components/ui'

import type {
    PatientDocument,
    Patient,
    ResponseData,
    ResponseError,
} from 'api/types'
import type { FormSubmitFn } from 'types'
import type { DocumentForm } from 'types/DocumentForm'
import { client } from 'api'

const DoctorPatientDocuments: React.FC<{
    patient: UseQueryResult<ResponseData<Patient>, ResponseError>
    handleSubmit: FormSubmitFn<DocumentForm>
}> = ({ patient, handleSubmit }) => {
    return (
        <div className="space-y-6">
            <div className="rounded-md bg-white">
                <div className="mb-16 pt-12 pb-12 px-6 divide-gray-200 divide-y">
                    <div className="grid grid-cols-2 text-gray-700">
                        <div className="col-span-1 pr-4">
                            <div>
                                <dt className="mb-8 text-lg leading-6 font-medium text-gray-900">
                                    Dokumenty
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <DocumentList patient={patient} />
                                </dd>
                            </div>
                        </div>
                        <div className="col-span-1 pl-4">
                            <Formik
                                initialValues={{
                                    description: '',
                                    document: 0,
                                    expire_date: '',
                                }}
                                onSubmit={handleSubmit}
                            >
                                <Form>
                                    <div className="mb-8 text-lg leading-6 font-medium text-gray-900">
                                        Dodaj dokument
                                    </div>
                                    <div className="mt-8">
                                        <TextAreaField
                                            name="description"
                                            labelText="Opis dokumentu"
                                            rows={2}
                                        />
                                    </div>
                                    <div className="mt-8">
                                        <FileUploaderField />
                                    </div>
                                    <div className="mt-8">
                                        <DatePickerField
                                            name="expire_date"
                                            labelText="Wybierz datę dokumentu"
                                        />
                                    </div>
                                    <div className="mt-8">
                                        <ButtonSubmit />
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ButtonSubmit: React.FC<
    Omit<React.ComponentProps<typeof Button>, 'children'>
> = ({ size = 'md', variant = 'primary' }) => {
    const { isSubmitting } = useFormikContext()

    return (
        <Button
            type="submit"
            variant={variant}
            size={size}
            iconRight={<PlusIcon />}
            loading={isSubmitting}
        >
            Dodaj dokument
        </Button>
    )
}

const DocumentList: React.FC<{
    patient: UseQueryResult<ResponseData<Patient>, ResponseError>
}> = ({ patient }) => {
    if (patient.isLoading || patient.isRefetching) {
        return <Loader />
    }

    if (patient.isError) {
        return <div></div>
    }

    if (patient.isSuccess) {
        if (patient.data.data.documents!.length === 0) {
            return (
                <p className="text-sm text-gray-500">
                    Lista dokumentów jest pusta.
                </p>
            )
        }

        return (
            <ul>
                {patient.data.data.documents!.map((document, index) => (
                    <li
                        key={document.id}
                        className={classNames({
                            'mt-2': index > 0,
                        })}
                    >
                        <FileItem item={document} />
                    </li>
                ))}
            </ul>
        )
    }

    return null
}

const FileItem: React.FC<{
    item: PatientDocument
}> = ({ item }) => {
    const [isProcessing, setIsProcessing] = useState(false)

    const handleOpenPreview = async () => {
        setIsProcessing(true)

        try {
            const {
                data: { path: documentUrl },
            } = await client.get(item.preview)
            setIsProcessing(false)
            window.open(documentUrl, '_blank', 'noreferrer')
        } catch {
            setIsProcessing(false)
        }
    }

    return (
        <div className="p-6 flex items-start justify-between text-sm border-2 border-gray-200 border-dashed rounded-md">
            <div className="w-0 flex-1 flex items-center">
                <DocumentDuplicateIcon
                    strokeWidth={1}
                    className="flex-shrink-0 w-12 text-blue-100"
                />
                <div className="ml-2 flex-1 w-0">
                    <p className="mb-0.5 text-sm text-gray-900 leading-5 font-medium break-all">
                        {item.name}
                    </p>
                    <div>
                        <p className="text-gray-500 whitespace-pre-wrap">
                            {item.description}
                        </p>
                        {item.expire_date !== null && (
                            <span className="text-gray-400 pr-2">
                                {formatDate(item.expire_date)}
                            </span>
                        )}
                        <span className="text-gray-500 uppercase">
                            {item.preview.split('.').pop()}
                        </span>
                    </div>
                </div>
            </div>
            <Button
                className="ml-4"
                type="button"
                onClick={handleOpenPreview}
                loading={isProcessing}
            >
                Podgląd
            </Button>
        </div>
    )
}

export default DoctorPatientDocuments
export { ButtonSubmit, FileItem }
