import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import type { DateRange } from 'react-day-picker'

import {
    API_FORMAT_DATE,
    DEFAULT_LIST_PER_PAGE,
    SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
    SESSION_STORAGE_STATISTICS_SURGERIES_FILTER_KEY,
} from '../constants'
import ListLayout from 'layouts/ListLayout'
import { currentRangeDate } from 'components/forms/DatePickers/StatisticsRangeDatePicker'

import { downloadFileFactory } from 'helpers'
import { exportStatisticOperations, useInfiniteStatisticsOperations } from 'api'
import { useStatisticsPolicy } from 'hooks'
import { ExportButton, FilterButton, Loader } from 'components/ui'
import { StatisticsRangeDatePicker } from 'components/forms'
import { AccessDenied } from 'components'
import Filters from 'components/StatisticsSurgeryFilters'
import StatisticsSurgeries from 'components/StatisticsSurgeries'

import type { StatisticsSurgeryListFilters } from 'types/StatisticsSurgeryList'
import { statisticsSurgeryListFilterSchema } from 'types/StatisticsSurgeryList'

const initialFilters: StatisticsSurgeryListFilters = {
    length: DEFAULT_LIST_PER_PAGE,
    date_range: {
        from: currentRangeDate().from,
        to: currentRangeDate().to,
    },
    operator: undefined,
    patient: '',
    payer_type: undefined,
    procedure_types: [],
    actual_state: [],
    procedure: undefined,
    procedure_category: undefined,
    operating_room: undefined,
    exceeded: undefined,
    unclosed: undefined,
}

const transformStatisticsSurgeriesFiltersToApi = (
    filters: StatisticsSurgeryListFilters
) => {
    return Object.assign(
        {},
        {
            length: filters.length,
        },
        filters.date_range.from && filters.date_range.to
            ? {
                  estimated_date_from: moment(filters.date_range.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.date_range.to).format(
                      API_FORMAT_DATE
                  ),
              }
            : {},
        {
            doctor_id: filters.operator?.id,
        },
        {
            patient: filters.patient,
        },
        {
            procedures: filters.procedure ? [filters.procedure.id] : undefined,
        },
        {
            procedure_types: filters.procedure_types
                ? [...filters.procedure_types.map((item) => item.id)]
                : [],
        },
        {
            procedure_categories: filters.procedure_category
                ? [filters.procedure_category.id]
                : [],
        },
        {
            statuses: filters.actual_state
                ? [...filters.actual_state.map((item) => item.id)]
                : [],
        },
        {
            payer_type: filters.payer_type ? filters.payer_type.id : undefined,
        },
        {
            operating_room: filters.operating_room
                ? filters.operating_room.id
                : undefined,
        },
        {
            exceeded: filters.exceeded || undefined,
        },
        {
            unclosed: filters.unclosed || undefined,
        }
    )
}

const StatisticsSurgeriesContainer = () => {
    const statisticsPolicy = useStatisticsPolicy()
    const [dateRange, setDateRange] = useState<DateRange>(currentRangeDate())
    const [isExporting, setIsExporting] = useState(false)
    const [filtersExpanded, setFiltersExpanded] = useState(true)
    const [filters, setFilters] = useState<StatisticsSurgeryListFilters>(() => {
        const storedFilters = window.sessionStorage.getItem(
            SESSION_STORAGE_STATISTICS_SURGERIES_FILTER_KEY
        )

        if (storedFilters) {
            try {
                const restoredFilters = JSON.parse(storedFilters)

                const result =
                    statisticsSurgeryListFilterSchema.safeParse(restoredFilters)

                if (result.success) {
                    return {
                        ...initialFilters,
                        ...result.data,
                        date_range: {
                            from: currentRangeDate().from,
                            to: currentRangeDate().to,
                        },
                    }
                }
            } catch (e) {}
        }

        return initialFilters
    })

    const operationsResultQuery = useInfiniteStatisticsOperations(
        transformStatisticsSurgeriesFiltersToApi(filters),
        {
            enabled: statisticsPolicy.canSee,
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                    ? lastPage.meta.current_page + 1
                    : undefined
            },
            retry: false,
        }
    )

    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            setFilters((prevState) => ({
                ...prevState,
                date_range: {
                    from: dateRange.from,
                    to: dateRange.to,
                },
            }))
        }
    }, [dateRange])

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_STATISTICS_SURGERIES_FILTER_KEY,
            JSON.stringify(filters)
        )
        window.sessionStorage.setItem(
            SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
            JSON.stringify(filters.date_range)
        )
    }, [filters])

    const handleChangeDate = (dateRange: DateRange) => {
        setDateRange(dateRange)
    }

    const filtersCount = useMemo(
        () =>
            (filters.operator ? 1 : 0) +
            (filters.patient ? 1 : 0) +
            (filters.procedure ? 1 : 0) +
            (filters.procedure_types?.length ? 1 : 0) +
            (filters.procedure_category ? 1 : 0) +
            (filters.actual_state?.length ? 1 : 0) +
            (filters.payer_type ? 1 : 0) +
            (filters.operating_room ? 1 : 0) +
            (filters.exceeded ? 1 : 0) +
            (filters.unclosed ? 1 : 0),
        [filters]
    )

    const exportXlsx = () => {
        setIsExporting(true)

        downloadFileFactory({
            fetchFunction: () =>
                exportStatisticOperations(
                    transformStatisticsSurgeriesFiltersToApi(filters)
                ).finally(() => setIsExporting(false)),
            fileName: `Statystyki zabiegów ${moment(
                filters.date_range.from
            ).format('yyyy.MM.DD')}-${moment(filters.date_range.to).format(
                'yyyy.MM.DD'
            )}.xlsx`,
        })
    }

    if (!statisticsPolicy.canSee) {
        return <AccessDenied message="Nie masz dostępu do statystyk" />
    }

    return (
        <ListLayout
            title="Zabiegi"
            actions={
                <div className="flex items-center space-x-4">
                    <ExportButton
                        onClick={exportXlsx}
                        isExporting={isExporting}
                    />
                    <FilterButton
                        count={filtersCount}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                        filtersExpanded={filtersExpanded}
                        handleReset={() =>
                            setFilters((prevState) => ({
                                ...initialFilters,
                                date_range: prevState.date_range,
                            }))
                        }
                    />
                    <span className="text-sm text-gray-700 leading-5 font-medium whitespace-nowrap">
                        Zakres dat:
                    </span>
                    <StatisticsRangeDatePicker
                        dateRange={dateRange}
                        onChange={handleChangeDate}
                    />
                </div>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                {operationsResultQuery.isLoading && <Loader />}
                {operationsResultQuery.isError && (
                    <div>{operationsResultQuery.error.message}</div>
                )}
                {operationsResultQuery.isSuccess && (
                    <StatisticsSurgeries
                        operationsQueryResult={operationsResultQuery}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default StatisticsSurgeriesContainer
