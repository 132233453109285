import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

import { getCxFromStyles } from 'helpers'

import styles from './Link.module.scss'

type BaseProps = {
    children: React.ReactNode
    className?: string
    disabled?: boolean
}

type ButtonAsButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as: 'button'
    }

type ButtonAsSpan = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLSpanElement>, keyof BaseProps> & {
        as: 'span'
    }

type ButtonAsLink = BaseProps &
    Omit<LinkProps, keyof BaseProps> & {
        as?: 'link'
    }

type ButtonProps = ButtonAsButton | ButtonAsSpan | ButtonAsLink

const Link: React.FC<ButtonProps> = ({ disabled, ...props }) => {
    const cx = getCxFromStyles(styles)

    if (props.as === 'button') {
        const { as, type, className, ...rest } = props
        return (
            <button
                className={cx('root', className, { disabled })}
                type="button"
                disabled={disabled}
                {...rest}
            />
        )
    }

    if (props.as === 'span') {
        const { as, type, className, ...rest } = props
        return (
            <span className={cx('root', className, { disabled })} {...rest} />
        )
    }

    const { as, className, ...rest } = props
    return (
        <RouterLink className={cx('root', className, { disabled })} {...rest} />
    )
}

export default Link
