import type { Patient } from 'api/types'

export function isPatientName(
    patient?: Pick<Patient, 'first_name' | 'last_name'>
) {
    if (!patient) {
        return false
    }

    if (patient.first_name || patient.last_name) {
        return true
    }

    return false
}

export function displayPatientName(
    patient?: Pick<Patient, 'first_name' | 'last_name'>
): string {
    const output = []

    if (!patient) {
        return ''
    }

    if (patient.first_name) {
        output.push(patient.first_name)
    }

    if (patient.last_name) {
        output.push(patient.last_name)
    }

    if (output.length) {
        return output.join(' ')
    }

    return ''
}

export function displayPatientNameOrPESELOrIdentityCard(
    patient: Pick<
        Patient,
        'first_name' | 'last_name' | 'pesel' | 'identity_card'
    >
): string {
    if (!patient) {
        return ''
    }

    if (patient.first_name || patient.last_name) {
        return displayPatientName(patient)
    }

    if (patient.pesel) {
        return patient.pesel
    }

    if (patient.identity_card) {
        return patient.identity_card
    }

    return ''
}
