import React, { useMemo } from 'react'

import { DropItem } from 'components/schedule'

import type { UseQueryResult } from 'react-query'
import type {
    OperatingRoom,
    SchedulePlanItem,
    ResponseData,
    ResponseError,
} from 'api/types'
import type { OnDrop } from 'types/SchedulePlanner'

const DropZone: React.FC<{
    date: Date
    rooms: OperatingRoom[]
    planQueryResult: UseQueryResult<
        ResponseData<SchedulePlanItem[]>,
        ResponseError
    >
    onDrop: OnDrop
}> = ({ date, rooms, planQueryResult, onDrop }) => {
    const cols = useMemo(() => Array.from(Array(rooms.length).keys()), [rooms])
    const rows = useMemo(() => Array.from(Array(17 * 4).keys()), [])

    return (
        <ol
            className="grid col-start-1 col-end-2 row-start-1"
            style={{
                gridTemplateRows: 'repeat(68, 2rem)',
                gridTemplateColumns: `repeat(${
                    rooms.length + 1
                }, minmax(0, 1fr))`,
            }}
        >
            {cols.map((col) => (
                <React.Fragment key={col}>
                    {rows.map((row) => (
                        <DropItem
                            key={`${col}-${row}`}
                            date={date}
                            row={row}
                            col={col + 1}
                            roomId={rooms[col]?.id}
                            planQueryResult={planQueryResult}
                            onDrop={onDrop}
                        />
                    ))}
                </React.Fragment>
            ))}
        </ol>
    )
}

export default DropZone
