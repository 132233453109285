export const OPERATION_STATUS_NEW = 'new'
export const OPERATION_STATUS_REJECTED = 'rejected'
export const OPERATION_STATUS_TO_DESCRIPTION = 'to_description'
export const OPERATION_STATUS_DURING_DESCRIPTION = 'during_description'
export const OPERATION_STATUS_DESCRIPTED = 'descripted'
export const OPERATION_STATUS_CANCELED = 'canceled'
export const OPERATION_STATUS_DRAFT = 'draft'
export const OPERATION_ALLOWED_STATUSES_TO_CHANGE = [
    OPERATION_STATUS_NEW,
    OPERATION_STATUS_REJECTED,
]
export const STATUSES_ALLOWED_TO_EDIT_OPERATION_IN_CAREGIVER_PANEL = [
    OPERATION_STATUS_NEW,
    OPERATION_STATUS_REJECTED,
    OPERATION_STATUS_TO_DESCRIPTION,
    OPERATION_STATUS_DURING_DESCRIPTION,
]
export const STATUSES_ALLOWED_TO_EDIT_OPERATION = [
    OPERATION_STATUS_NEW,
    OPERATION_STATUS_DRAFT,
    OPERATION_STATUS_REJECTED,
]

export const OPERATION_REJECT_TYPE_HEALTH_RELATED = 'health_related'
export const OPERATION_REJECT_TYPE_FAMILY_RELATED = 'family_related'
export const OPERATION_REJECT_TYPE_MONEY_RELATED = 'money_related'
export const OPERATION_REJECT_TYPE_OTHER = 'other'
export const OPERATION_REJECT_REASONS = [
    {
        type: OPERATION_REJECT_TYPE_HEALTH_RELATED,
        label: 'Zdrowotna',
    },
    {
        type: OPERATION_REJECT_TYPE_MONEY_RELATED,
        label: 'Finansowa',
    },
    {
        type: OPERATION_REJECT_TYPE_FAMILY_RELATED,
        label: 'Rodzinna',
    },
    {
        type: OPERATION_REJECT_TYPE_OTHER,
        label: 'Inna',
    },
]
