import React from 'react'

import {
    useCreateImplantManufacturer,
    useUpdateImplantManufacturer,
    useDeleteImplantManufacturer,
    useGetImplantManufacturer,
} from 'api'
import { FormSubmitFn, IModal } from 'types'
import { DeleteButton } from 'components/forms'
import SettingsImplantManufacturerModal, {
    FormValues,
} from 'components/SettingsImplantManufacturerModal'

export default function SettingsImplantManufacturerModalContainer(props: {
    modal: IModal
    handleSubmit: () => void
}) {
    const { modal } = props
    const id = modal.getState() as number
    const implantManufacturer = useGetImplantManufacturer(id, {
        enabled: !!id,
    })
    const { mutate: create } = useCreateImplantManufacturer()
    const { mutate: update } = useUpdateImplantManufacturer()
    const deleteImplant = useDeleteImplantManufacturer()

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        if (id) {
            return update(
                {
                    ...values,
                    id,
                },
                {
                    onSuccess: () => {
                        formikHelpers.setSubmitting(false)
                        props.handleSubmit()
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }

        create(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
                props.handleSubmit()
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <SettingsImplantManufacturerModal
            id={id}
            modal={modal}
            data={implantManufacturer.data}
            handleSubmit={handleSubmit}
            buttonDelete={
                <DeleteButton
                    id={id}
                    query={deleteImplant}
                    handleSuccess={props.handleSubmit}
                />
            }
        />
    )
}
