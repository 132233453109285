import { usePolicy, useSurgeryPolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useSurgeryPolicyGroup = () => {
    const { user, hasPermission } = usePolicy()
    const surgeryPolicy = useSurgeryPolicy()

    return {
        user,
        get canUpdate() {
            return surgeryPolicy.canUpdate
        },
        get canCancel() {
            return (
                hasPermission(PERMISSIONS.UPDATE_OPERATIONS) &&
                hasPermission(PERMISSIONS.CANCEL_OPERATIONS)
            )
        },
        get canReject() {
            return (
                hasPermission(PERMISSIONS.UPDATE_OPERATIONS) &&
                hasPermission(PERMISSIONS.REJECT_OPERATIONS)
            )
        },
        get canRenew() {
            return hasPermission(PERMISSIONS.RENEW_OPERATIONS)
        },
    }
}
