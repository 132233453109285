import React, { useCallback } from 'react'
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/solid'
import { format, addDays, isBefore } from 'date-fns'
import pl from 'date-fns/locale/pl'

import {
    DATEPICKER_DEFAULT_FROM_YEAR,
    DATEPICKER_DEFAULT_TO_YEAR,
} from 'constants/index'
import { Button, DatePicker, Label, Loader, Toggle } from './ui'
import { getCxFromStyles } from 'helpers'
import { LiveViewOperationEventContainer } from 'components'
import Clock from 'components/Clock'

import styles from './LiveView.module.scss'

import type { OperatingRoom, OperationEventListMeta } from 'api/types'
import type { OperationsByRoom } from 'containers/LiveView'

export const LiveView: React.FC<{
    forTv: boolean
    forTablet: boolean
    canChangeView: boolean
    rooms: OperatingRoom[]
    data: OperationsByRoom
    meta?: OperationEventListMeta
    date: Date
    view: 'detail' | 'business'
    onViewToggle: () => void
    onChangeDate: (date: Date) => void
    isLoadingRooms: boolean
    isLoadingEvents: boolean
    canMoveToNextDay: boolean
    errorRooms?: string
    errorEvents?: string
}> = ({
    rooms,
    data,
    meta,
    forTv,
    forTablet,
    canChangeView,
    onViewToggle,
    onChangeDate,
    date,
    view,
    isLoadingRooms,
    isLoadingEvents,
    errorRooms,
    errorEvents,
    canMoveToNextDay,
}) => {
    const cxStyles = getCxFromStyles(styles)

    return (
        <div className={cxStyles('root', { tv: forTv })}>
            {forTv && (
                <div className={cxStyles('clock')}>
                    <Clock />
                </div>
            )}
            {!forTablet && !forTv && (
                <LiveViewTopBar
                    date={date}
                    view={view}
                    onViewToggle={onViewToggle}
                    onDateChange={onChangeDate}
                    canMoveToNextDay={canMoveToNextDay}
                    canChangeView={canChangeView}
                />
            )}
            {isLoadingRooms ? (
                <Loader theme="darker" />
            ) : errorRooms ? (
                <div>{errorRooms}</div>
            ) : (
                <div className={cxStyles('content')}>
                    <div className={cxStyles('columns-container')}>
                        {rooms.map((item, index) => (
                            <div
                                key={index}
                                className={cxStyles('operating-room-header')}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                    {isLoadingEvents ? (
                        <Loader theme="darker" />
                    ) : errorEvents ? (
                        <div>{errorEvents}</div>
                    ) : (
                        <div className={cxStyles('columns-container')}>
                            {rooms.map((room, index) => (
                                <div
                                    key={rooms.length + index}
                                    className={cxStyles(
                                        'operating-room-column'
                                    )}
                                >
                                    <ul
                                        className={cxStyles(
                                            'operating-room-list'
                                        )}
                                    >
                                        {data.byId[room.id]?.map(
                                            (item, index) => (
                                                <li
                                                    key={item.id}
                                                    className={cxStyles(
                                                        'operating-room-list-item'
                                                    )}
                                                >
                                                    <LiveViewOperationEventContainer
                                                        data={item}
                                                        meta={meta}
                                                        forTv={forTv}
                                                    />
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

type LiveViewTopBarProps = {
    date: Date
    maxDate?: Date
    view: 'detail' | 'business'
    canMoveToNextDay: boolean
    canChangeView: boolean
    onViewToggle: () => void
    onDateChange: (date: Date) => void
}

export function LiveViewTopBar(props: LiveViewTopBarProps) {
    const {
        date,
        view,
        maxDate,
        canChangeView,
        canMoveToNextDay,
        onDateChange,
        onViewToggle,
    } = props
    const cxStyles = getCxFromStyles(styles)

    const handleToggle = useCallback(() => {
        onViewToggle()
    }, [onViewToggle])

    const isMaxDateBeforeToday = maxDate && isBefore(maxDate, new Date())

    return (
        <div className="flex items-center justify-between bg-gray-900 text-white">
            <div className="flex-1"></div>
            <div className={cxStyles('topbar')}>
                <ArrowNarrowLeftIcon
                    className={cxStyles('arrow-icon')}
                    onClick={() => onDateChange(addDays(date, -1))}
                />
                <div className={cxStyles('topbar-content')}>
                    <div
                        className={cxStyles(
                            'topbar-item',
                            'relative text-gray-900'
                        )}
                    >
                        <DatePicker
                            value={date}
                            captionLayout="dropdown-buttons"
                            fromYear={DATEPICKER_DEFAULT_FROM_YEAR}
                            toYear={DATEPICKER_DEFAULT_TO_YEAR}
                            stylesPopper={{
                                width: 'max-content',
                                left: '50%',
                                transform: 'translate(-50%, 42px)',
                            }}
                            onChange={(date) => {
                                !!date && onDateChange(date)
                            }}
                            disabledAfter={maxDate}
                            renderSelector={({ ref, isOpen, open, close }) => {
                                return (
                                    <div
                                        ref={ref}
                                        className="cursor-pointer flex items-center justify-center text-lg leading-tight text-white whitespace-nowrap space-x-2"
                                        onClick={!isOpen ? open : close}
                                    >
                                        <span>
                                            {format(date, 'd.MM, cccc', {
                                                locale: pl,
                                            })}
                                        </span>
                                        <span>
                                            {!isOpen && (
                                                <ChevronDownIcon
                                                    className="h-5 w-5 text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            )}
                                            {isOpen && (
                                                <ChevronUpIcon
                                                    className="h-5 w-5 text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </span>
                                    </div>
                                )
                            }}
                        />
                    </div>
                    <div className={cxStyles('topbar-item-divider')} />
                    <span className={cxStyles('topbar-item', 'topbar-clock')}>
                        <Clock />
                    </span>
                    <div className={cxStyles('topbar-item-divider')} />
                    <span className={cxStyles('topbar-item')}>
                        <Button
                            onClick={() => onDateChange(new Date())}
                            variant="tertiary"
                            size="sm"
                            disabled={isMaxDateBeforeToday}
                        >
                            Dziś
                        </Button>
                    </span>
                </div>
                <ArrowNarrowRightIcon
                    className={cxStyles('arrow-icon', {
                        disabled: !canMoveToNextDay,
                    })}
                    onClick={() =>
                        canMoveToNextDay && onDateChange(addDays(date, 1))
                    }
                />
            </div>
            <div className="flex flex-1 items-center justify-end pr-4">
                {canChangeView && (
                    <>
                        <Toggle
                            variant="green"
                            checked={view === 'detail'}
                            disabled={false}
                            handleChange={handleToggle}
                        />
                        <Label className="pl-2 mb-0 text-gray-400">
                            Czasy zabiegów
                        </Label>
                    </>
                )}
            </div>
        </div>
    )
}
