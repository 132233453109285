import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const IMPLANT_PERMISSIONS = {
    index: PERMISSIONS.INDEX_IMPLANTS,
    show: PERMISSIONS.SHOW_IMPLANTS,
    create: PERMISSIONS.CREATE_IMPLANTS,
    update: PERMISSIONS.UPDATE_IMPLANTS,
    delete: PERMISSIONS.DELETE_IMPLANTS,
}

export const useImplantPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(IMPLANT_PERMISSIONS),
    }
}
