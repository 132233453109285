import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
    defaultFormImplantFilters,
    transformImplantFiltersToApi,
    useCreateImplant,
    useGetImplant,
    useGetImplants,
    useUpdateImplant,
} from 'api'
import { useImplantPolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsImplantSetForm, {
    type FormValues,
} from 'components/SettingsImplantSetForm'

import type { FormSubmitFn } from 'types'
import type { ImplantSet, ResponseData, UpdateImplantSet } from 'api/types'

interface ImplantSetFormProps {
    id?: string
    data?: ResponseData<ImplantSet>
}

export default function SettingsImplantSetFormContainer({
    id,
    data,
}: ImplantSetFormProps) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const implantPolicyGroup = useImplantPolicyGroup()
    const implants = useGetImplants(
        transformImplantFiltersToApi(
            defaultFormImplantFilters({
                pagination: false,
            })
        ),
        {
            enabled: implantPolicyGroup.canAll,
        }
    )
    const { mutate: create } = useCreateImplant<Omit<UpdateImplantSet, 'id'>>()
    const { mutate: update } = useUpdateImplant<UpdateImplantSet>()

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        if (id) {
            return update(
                {
                    ...values,
                    procedures: values.procedures.map((i) => ({ id: i.id })),
                    implants: values.implants.map((i) => ({ id: i.id })),
                    id: Number(id),
                },
                {
                    onSuccess: async () => {
                        formikHelpers.setSubmitting(false)
                        await queryClient.invalidateQueries('implants', {
                            refetchInactive: true,
                        })
                        navigate('/settings/price-list/implant-sets')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        const { procedures, ...errors } = error.errors
                        formikHelpers.setErrors(errors)
                        formikHelpers.setFieldError('_procedures', procedures)
                    },
                }
            )
        }

        create(
            {
                ...values,
                procedures: values.procedures.map((i) => ({ id: i.id })),
                implants: values.implants.map((i) => ({ id: i.id })),
            },
            {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    queryClient.invalidateQueries('implants')
                    navigate('/settings/price-list/implant-sets')
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    const { procedures, ...errors } = error.errors
                    formikHelpers.setErrors(errors)
                    formikHelpers.setFieldError('_procedures', procedures)
                },
            }
        )
    }

    if (!implantPolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
        )
    }

    if (implants.isLoading) {
        return <Loader />
    }

    if (implants.isError) {
        return null
    }

    if (!implants.data) {
        return null
    }

    return (
        <SettingsImplantSetForm
            id={id}
            data={data}
            implants={implants.data}
            handleSubmit={handleSubmit}
        />
    )
}

const SettingsImplantSetEditFormContainer = () => {
    const implantPolicyGroup = useImplantPolicyGroup()
    const { id } = useParams()
    const implant = useGetImplant<ImplantSet>(id!, {
        enabled: implantPolicyGroup.canAll,
    })

    if (!implantPolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
        )
    }

    if (implant.isLoading) {
        return <Loader />
    }

    if (implant.isError) {
        return <div>{implant.error.message}</div>
    }

    if (!implant.data) {
        return <div></div>
    }

    return <SettingsImplantSetFormContainer id={id} data={implant.data} />
}

export { SettingsImplantSetEditFormContainer as SettingsImplantSetEditForm }
