import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import axios from 'api/axios'
import {
    ResponseList,
    ResponseError,
    ResponseData,
    Implant,
    PostImplant,
    UpdateImplant,
    ImplantFilters,
    Procedure,
    FormImplantFilters,
} from 'api/types'

export const defaultFormImplantFilters = (
    overrides = {} as Partial<FormImplantFilters>
): FormImplantFilters => {
    return Object.assign(
        {},
        {
            pagination: undefined,
            page: 1,
            length: DEFAULT_LIST_PER_PAGE,
            sort_by: 'label_or_name',
            sort_direction: 'asc',
            set: false,
            query: '',
            procedures: [] as Procedure[],
            globally_available: undefined,
        },
        overrides
    )
}

export const transformImplantFiltersToApi = (
    filters: FormImplantFilters
): ImplantFilters => {
    const transformedFilters: ImplantFilters = {
        ...filters,
        procedures: filters.procedures.map((item) => item.id),
        manufacturer: undefined,
        manufacturer_id: filters.manufacturer?.id
            ? filters.manufacturer?.id
            : undefined,
    }

    return transformedFilters
}

export const getDropdownImplants = <T>(filters?: {}): Promise<
    ResponseList<T[]>
> => {
    return axios.get(
        '/implants' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )
}

export const getImplants = <T>(
    filters: ImplantFilters = transformImplantFiltersToApi(
        defaultFormImplantFilters()
    )
): Promise<T> =>
    axios.get('/implants' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetImplants = <T = ResponseList<Implant[]>>(
    filters?: ImplantFilters,
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['implants', filters],
        () => getImplants<T>(filters),
        options
    )

export const useGetImplant = <T = Implant, E = ResponseError>(
    id: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['implants', id],
        () => axios.get(`/implants/${id}`),
        options
    )

export const createImplant = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/implants', data)

export const useCreateImplant = <
    R1 = PostImplant,
    R2 = ResponseData<Implant>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createImplant<R1, R2>(data))

export const updateImplant = <R1, R2>(id: number, data: R1): Promise<R2> =>
    axios.patch('/implants/' + id, data)

export const useUpdateImplant = <
    R1 extends { id: number } = UpdateImplant,
    R2 = ResponseData<Implant>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateImplant<R1, R2>(data.id, data))

export const deleteImplant = <R>(id: number): Promise<R> =>
    axios.delete(`/implants/${id}`)

export const useDeleteImplant = <R = Implant>(): UseMutationResult<
    R,
    ResponseError,
    number
> => useMutation((id: number) => deleteImplant<R>(id))
