import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'

import { Label } from 'components/ui'
import { AutoSubmitRow } from 'components/forms'

const SettingsRFIDCardListFilters: React.FC<{
    filters: { query: string }
    setFilters: (query: string) => void
}> = ({ filters, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => setFilters(values.query)}
            >
                {({ handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                            <div>
                                <Label>Wyszukaj kartę</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="query"
                                        className="block w-full pr-10 appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        placeholder="Nr karty, Imię, Nazwisko..."
                                        onChange={handleChange}
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SettingsRFIDCardListFilters
