import React from 'react'
import { Comment, StatisticsOperationResponse } from 'api/types'
import {
    displayRejectReason,
    formatDateNoYear,
    formatTime,
    getSurgicalTeamRoleLabel,
} from 'helpers'
import UserAvatar from 'components/UserAvatar'

const StatisticsSurgeryMedicalTeam: React.FC<{
    data: StatisticsOperationResponse
    comments: Comment[]
}> = ({ data, comments }) => {
    return (
        <div className="border-b border-gray-100 pb-4 flex flex-col gap-4">
            <div className="text-lg leading-6 font-medium text-gray-900">
                Zespół medyczny
            </div>

            <div className="border-t border-gray-100">
                {data.surgical_team.map((person) => (
                    <div
                        className="py-3 border-b border-gray-100 flex gap-4 items-center justify-between"
                        key={person.id}
                    >
                        <UserAvatar
                            variant="avatar"
                            data={{
                                avatar_url: person.avatar_url,
                                first_name: person.first_name,
                                last_name: person.last_name,
                            }}
                            size={6}
                        />
                        <div className="flex-1 flex flex-col">
                            <span className="text-sm leading-5 font-medium text-gray-900">
                                {person.first_name} {person.last_name}
                            </span>
                        </div>
                        <div className="rounded-xl bg-blue-100 py-0.5 px-3 text-sm leading-5 font-medium text-blue-800">
                            {getSurgicalTeamRoleLabel(person.role)}
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <div className="text-base leading-6 font-medium text-gray-900">
                    Komentarze
                </div>

                <div className="flex flex-col gap-1 mt-2">
                    {comments.length === 0
                        ? '-'
                        : comments.map((comment) => (
                              <div
                                  className="p-4 bg-gray-50 rounded-lg flex gap-4 items-center"
                                  key={comment.id}
                              >
                                  <UserAvatar
                                      variant="avatar"
                                      data={comment.author}
                                      size={8}
                                  />
                                  <div className="flex-1 flex flex-col">
                                      <span className="text-sm leading-5 font-medium text-gray-900">
                                          {comment.author.first_name}{' '}
                                          {comment.author.last_name}
                                      </span>
                                      <span className="text-xs leading-5 font-normal text-gray-600">
                                          {comment.content}
                                      </span>
                                  </div>
                                  <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                      {!!comment.created_at && (
                                          <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                              {formatDateNoYear(
                                                  comment.created_at
                                              )}
                                              <span className="text-gray-500 ml-2">
                                                  {formatTime(
                                                      comment.created_at
                                                  )}
                                              </span>
                                          </div>
                                      )}
                                  </div>
                              </div>
                          ))}
                </div>
            </div>

            <div>
                <div className="text-base leading-6 font-medium text-gray-900">
                    Przyczyny zrzucenia
                </div>

                <div className="flex flex-col gap-1 mt-2">
                    {!data.historical_data || data.historical_data.length === 0
                        ? '-'
                        : data.historical_data.map((item) => (
                              <div
                                  className="p-4 bg-gray-50 rounded-lg flex gap-4 items-center"
                                  key={item.id}
                              >
                                  <UserAvatar
                                      variant="avatar"
                                      data={item.causer}
                                      size={8}
                                  />
                                  <div className="flex-1 flex flex-col">
                                      <span className="text-sm leading-5 font-medium text-gray-900">
                                          {item.causer.first_name}{' '}
                                          {item.causer.last_name}
                                      </span>
                                      <span className="text-xs leading-5 font-normal text-gray-600">
                                          {displayRejectReason(
                                              item.reject_type?.type,
                                              item.reject_reason
                                          ) || '-'}
                                      </span>
                                  </div>
                                  <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                      {!!item.created_at && (
                                          <div className="text-xs leading-none font-normal text-gray-400 self-start">
                                              {formatDateNoYear(
                                                  item.created_at
                                              )}
                                              <span className="text-gray-500 ml-2">
                                                  {formatTime(item.created_at)}
                                              </span>
                                          </div>
                                      )}
                                  </div>
                              </div>
                          ))}
                </div>
            </div>
        </div>
    )
}

export default StatisticsSurgeryMedicalTeam
