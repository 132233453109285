import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    PostRole,
    UpdateRole,
    Role,
    ResponseData,
    ResponseList,
    ResponseError,
} from 'api/types'

export const getRoles = <T>(filters?: {}): Promise<T> =>
    axios.get('/roles' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetRoles = <R = ResponseList<Role[]>>(
    filters?: {},
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['roles', filters],
        () => getRoles<R>(filters),
        options
    )

export const useGetRole = <T = Role>(
    id: number,
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
) =>
    useQuery<ResponseData<T>, ResponseError>(
        ['roles', id],
        () => axios.get(`/roles/${id}`),
        options
    )

export const createRole = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/roles', data)

export const useCreateRole = <
    R1 = PostRole,
    R2 = ResponseData<Role>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createRole<R1, R2>(data))

export const updateRole = <R1, R2>(
    id: number | string,
    data: R1
): Promise<R2> => axios.patch('/roles/' + id, data)

export const useUpdateRole = <
    R1 extends Pick<UpdateRole, 'id'> = UpdateRole,
    R2 = ResponseData<Role>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateRole<R1, R2>(data.id, data))
