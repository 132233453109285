import React from 'react'
import { useParams } from 'react-router-dom'

import { useGetUserById } from 'api'
import ListLayout from 'layouts/ListLayout'
import { Button, Loader } from 'components/ui'
import SettingsUser from 'components/SettingsUser'

const SettingsUserContainer = () => {
    const { id } = useParams()
    const user = useGetUserById(id!, { enabled: !!id })

    return (
        <ListLayout
            title=""
            actions={
                <>
                    <Button
                        as="link"
                        to={`/settings/users/${id}/edit/`}
                        variant="tertiary"
                        size="md"
                        className="ml-2"
                    >
                        Edytuj
                    </Button>
                </>
            }
        >
            <>
                {user.isLoading && <Loader />}
                {user.isError && <div>{user.error.message}</div>}
                {user.isSuccess && (
                    <SettingsUser adminView={true} user={user.data.data} />
                )}
            </>
        </ListLayout>
    )
}

export default SettingsUserContainer
