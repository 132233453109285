import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import { FormSubmitFn } from 'types'
import { Implant, ResponseData } from 'api/types'
import {
    useCreateImplant,
    useGetImplant,
    useGetImplantManufacturers,
    useUpdateImplant,
} from 'api'
import { useImplantPolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsImplantForm, {
    type FormValues,
} from 'components/SettingsImplantForm'

interface ImplantFormProps {
    id?: string
    data?: ResponseData<Implant>
}

export default function SettingsImplantFormContainer({
    id,
    data,
}: ImplantFormProps) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const implantPolicyGroup = useImplantPolicyGroup()
    const manufacturers = useGetImplantManufacturers(
        { pagination: false },
        { enabled: implantPolicyGroup.canAll }
    )
    const { mutate: create } = useCreateImplant()
    const { mutate: update } = useUpdateImplant()

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        if (id) {
            return update(
                {
                    ...values,
                    manufacturer: values.manufacturer?.id,
                    procedures: values.procedures.map((i) => ({ id: i.id })),
                    id: Number(id),
                },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries('implants', {
                            refetchInactive: true,
                        })
                        formikHelpers.setSubmitting(false)
                        navigate('/settings/price-list/implants')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }

        create(
            {
                ...values,
                manufacturer: values.manufacturer?.id,
                procedures: values.procedures.map((i) => ({ id: i.id })),
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('implants', {
                        refetchInactive: true,
                    })
                    formikHelpers.setSubmitting(false)
                    navigate('/settings/price-list/implants')
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    if (!implantPolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
        )
    }

    if (manufacturers.isLoading) {
        return <Loader />
    }

    if (manufacturers.isError) {
        return null
    }

    if (!manufacturers.data) {
        return null
    }

    return (
        <SettingsImplantForm
            id={id}
            data={data}
            manufacturers={manufacturers.data}
            handleSubmit={handleSubmit}
        />
    )
}

const SettingsImplantFormEditContainer = () => {
    const { id } = useParams()
    const implantPolicyGroup = useImplantPolicyGroup()
    const implant = useGetImplant(id!, {
        enabled: implantPolicyGroup.canAll && !!id,
    })

    if (!implantPolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
        )
    }

    if (implant.isLoading) {
        return <Loader />
    }

    if (implant.isError) {
        return <div>{implant.error.message}</div>
    }

    if (!implant.data) {
        return <div></div>
    }

    return <SettingsImplantFormContainer id={id} data={implant.data} />
}

export { SettingsImplantFormEditContainer as SettingsImplantFormEdit }
