import React, { useMemo } from 'react'

import {
    useGetSpecialization,
    useDeleteSpecialization,
    useCreateSpecialization,
    useUpdateSpecialization,
} from 'api'
import { DeleteButton } from 'components/forms'
import SettingsSpecializationModal from 'components/SettingsSpecializationModal'

import type { FormSubmitFn, IModal } from 'types'
import type { PostSpecialization, Specialization } from 'api/types'

const SettingsSpecializationModalContainer: React.FC<{
    modal: IModal
    handleSuccess: () => void
}> = ({ modal, handleSuccess }) => {
    const id = modal.getState() as number
    const specializations = useGetSpecialization(id, { enabled: !!id })
    const { mutate: create } = useCreateSpecialization()
    const { mutate: update } = useUpdateSpecialization()
    const deleteSpecialization = useDeleteSpecialization()

    const createOrUpdate = useMemo(
        () => (id ? update : create),
        [create, update, id]
    )

    const handleSubmit: FormSubmitFn<PostSpecialization> = (
        values,
        formikHelpers
    ) => {
        createOrUpdate(
            Object.assign({}, values, !!id ? { id } : {}) as Specialization,
            {
                onSuccess: async () => {
                    formikHelpers.setSubmitting(false)
                    handleSuccess()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <SettingsSpecializationModal
            id={id}
            modal={modal}
            data={specializations.data}
            handleSubmit={handleSubmit}
            buttonDelete={
                <DeleteButton
                    id={id}
                    query={deleteSpecialization}
                    handleSuccess={handleSuccess}
                />
            }
        />
    )
}

export default SettingsSpecializationModalContainer
