import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'
import { useInfiniteQuery } from 'react-query'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import { useEventListener } from 'hooks'
import { InputText, Label, Loader } from 'components/ui'
import { DropdownItem } from 'components/forms'

import type { ResponseList } from 'api/types'
import type { InputTextProps } from 'components/ui'

interface DropdownProps<T> {
    name: string
    label?: string
    inputProps?: InputTextProps
    placeholder?: string
    tabIndex?: number
    variant?: 'sm' | 'md' | 'lg'
    disabled?: boolean
    hasError?: boolean
    queryFieldName?: string
    messageEmptyResult?: string
    queryFn: (filters?: {}) => Promise<ResponseList<T[]>>
    queryFilters?: {}
    useSearchPhraseField?: boolean
    valueToSearchPhrase?: (value: T) => string
    renderOptions: (options: JSX.Element) => React.ReactNode
    renderOption: (item: T, selected: boolean) => React.ReactNode
    renderCurrentOption?: (
        item: T,
        setOpen: React.Dispatch<React.SetStateAction<boolean>>
    ) => React.ReactNode
    handleInputChange?: (value: string) => void
    handleChange?: (value: T) => void
    handleRemove?: () => void
}

const SearchableDropdownField = <T extends { id?: DropdownItem['id'] | null }>({
    name,
    label,
    placeholder,
    tabIndex,
    variant = 'md',
    disabled = false,
    hasError,
    useSearchPhraseField = true,
    valueToSearchPhrase,
    renderOptions,
    renderOption,
    renderCurrentOption,
    handleChange,
    handleRemove,
    queryFieldName = 'query',
    queryFn,
    queryFilters = {},
    inputProps,
    messageEmptyResult = 'Nie znaleziono wyników wyszukiwania.',
}: React.PropsWithChildren<DropdownProps<T>>) => {
    const { values } = useFormikContext<Record<string, unknown>>()

    const [searchPhrase, setSearchPhrase] = useState<string>(
        useSearchPhraseField ? (values[`${name}_phrase`] as string) : ''
    )

    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const observerRef = useRef(null)
    const queryResult = useInfiniteQuery(
        `dropdown-${name}`,
        ({ pageParam }) =>
            queryFn({
                ...queryFilters,
                page: pageParam,
                pagination: true,
                [queryFieldName]: inputRef.current?.value,
            }),
        {
            enabled: open,
            staleTime: 0,
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                    ? lastPage.meta.current_page + 1
                    : undefined
            },
        }
    )

    const debounceRefetch = useRef(
        debounce(() => {
            queryResult.remove()
            queryResult.refetch({
                refetchPage: () => true,
            })
        }, 750)
    ).current

    useEffect(() => {
        if (useSearchPhraseField) {
            setSearchPhrase((values[`${name}_phrase`] as string) || '')
        }
    }, [useSearchPhraseField, values, name])

    useEffect(() => {
        open && debounceRefetch()
        !open && !useSearchPhraseField && searchPhrase.length && setOpen(true)
    }, [searchPhrase]) // eslint-disable-line

    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                queryResult.hasNextPage &&
                !queryResult.isFetchingNextPage
            ) {
                queryResult.fetchNextPage()
            }
        },
        [queryResult]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }

        const element = observerRef.current
        const option = { threshold: 0 }

        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    useEffect(() => {
        if (!open) {
            queryResult.remove()
        }
    }, [open]) // eslint-disable-line

    const handleClickOutside = (e: Event) => {
        if (
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target)
        ) {
            setOpen(false)
        }
    }

    useEventListener('mousedown', handleClickOutside)

    return (
        <Field
            name={name}
            children={({ form, field, meta }: FieldProps) => (
                <div
                    className={classNames('relative', {
                        'cursor-default pointer-events-none': disabled,
                    })}
                    ref={ref}
                >
                    {!!label && (
                        <div
                            className={classNames(
                                'block text-sm font-medium text-gray-700',
                                { 'text-red-500': !!meta.error }
                            )}
                        >
                            <Label>{label}</Label>
                        </div>
                    )}
                    <div className="relative">
                        {typeof renderCurrentOption === 'function' &&
                            !open &&
                            !!field.value &&
                            renderCurrentOption(field.value, setOpen)}
                        {((typeof renderCurrentOption === 'function' && open) ||
                            (typeof renderCurrentOption === 'function' &&
                                !field.value &&
                                !open) ||
                            !renderCurrentOption) && (
                            <InputText
                                style={{
                                    paddingRight:
                                        !disabled &&
                                        (!!handleRemove
                                            ? true
                                            : searchPhrase || field.value)
                                            ? 65
                                            : 40,
                                }}
                                tabIndex={tabIndex}
                                autoFocus={open && !!field.value}
                                inputRef={inputRef}
                                variant={variant}
                                placeholder={placeholder}
                                value={
                                    !open &&
                                    typeof valueToSearchPhrase === 'function'
                                        ? valueToSearchPhrase(field.value)
                                        : searchPhrase
                                }
                                onClick={(e) => {
                                    setOpen(true)
                                }}
                                disabled={disabled}
                                hasError={hasError}
                                handleChange={(e) => {
                                    useSearchPhraseField &&
                                        form.setFieldValue(
                                            `${name}_phrase`,
                                            e.target.value
                                        )
                                    !useSearchPhraseField &&
                                        setSearchPhrase(e.target.value)
                                }}
                                {...inputProps}
                            />
                        )}
                        {!disabled && (
                            <>
                                {(searchPhrase || field.value) && (
                                    <div className="absolute inset-y-0 right-6 pr-3 flex items-center">
                                        <TrashIcon
                                            className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => {
                                                form.setFieldValue(
                                                    name,
                                                    undefined
                                                )
                                                useSearchPhraseField &&
                                                    form.setFieldValue(
                                                        `${name}_phrase`,
                                                        ''
                                                    )
                                                !useSearchPhraseField &&
                                                    setSearchPhrase('')
                                                setOpen(false)
                                                !!handleRemove && handleRemove()
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                    {open && (
                                        <ChevronUpIcon
                                            className="h-5 w-5 text-gray-400 cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => setOpen(false)}
                                        />
                                    )}
                                    {!open && (
                                        <ChevronDownIcon
                                            className="h-5 w-5 text-gray-400 cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => setOpen(true)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <Listbox
                        value={field.value}
                        onChange={(value) => {
                            form.setFieldValue(name, value)
                            useSearchPhraseField &&
                                form.setFieldValue(
                                    `${name}_phrase`,
                                    valueToSearchPhrase
                                        ? valueToSearchPhrase(value)
                                        : value.name || ''
                                )
                            !useSearchPhraseField &&
                                setSearchPhrase(
                                    valueToSearchPhrase
                                        ? valueToSearchPhrase(value)
                                        : value.name || ''
                                )
                            handleChange && handleChange(value)
                            setOpen(false)
                        }}
                    >
                        <Listbox.Options
                            static={
                                open &&
                                (queryResult.isLoading || queryResult.isSuccess)
                            }
                            className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm"
                        >
                            {queryResult.isLoading && (
                                <div className="p-5">
                                    <Loader size="sm" />
                                </div>
                            )}
                            {queryResult.isSuccess &&
                                queryResult.data.pages[0].meta.total === 0 && (
                                    <div className="p-5 text-center">
                                        {messageEmptyResult}
                                    </div>
                                )}
                            {queryResult.isSuccess &&
                                queryResult.data.pages[0].meta.total > 0 && (
                                    <>
                                        {renderOptions(
                                            <div className="divide-y divide-gray-200">
                                                {queryResult.data.pages.map(
                                                    (page) =>
                                                        page.data.map(
                                                            (item) => (
                                                                <Listbox.Option
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    className={classNames(
                                                                        'cursor-pointer select-none relative py-2 pl-7 pr-2 hover:bg-gray-50'
                                                                    )}
                                                                    value={item}
                                                                >
                                                                    {renderOption(
                                                                        item,
                                                                        field
                                                                            .value
                                                                            ?.id ===
                                                                            item.id
                                                                    )}
                                                                </Listbox.Option>
                                                            )
                                                        )
                                                )}
                                            </div>
                                        )}
                                        {queryResult.hasNextPage && (
                                            <div
                                                className="loader"
                                                ref={observerRef}
                                            >
                                                <Loader size="sm" />
                                            </div>
                                        )}
                                    </>
                                )}
                        </Listbox.Options>
                    </Listbox>
                </div>
            )}
        />
    )
}

export default SearchableDropdownField
