import React from 'react'
import { RadioGroup } from '@headlessui/react'

import { Label, RadioGroupLabel, RadioGroupOption } from 'components/ui'

const items = [
    {
        name: 'Tak',
        value: true,
    },
    {
        name: 'Nie',
        value: false,
    },
]

const AllowDocumentsRadioGroup: React.FC<{
    value: boolean
    handleChange: (value: boolean) => void
}> = ({ value, handleChange }) => {
    return (
        <RadioGroup
            className="mt-4"
            value={value}
            onChange={(value) => handleChange(value)}
        >
            <Label>Zezwolić na dodawanie dokumentów?</Label>
            <div className="mt-1 bg-white grid gap-2 grid-cols-2 rounded-md -space-y-px">
                {items.map((item, index) => (
                    <RadioGroupOption key={index} value={item.value}>
                        {(optionRenderProps) => (
                            <RadioGroupLabel {...optionRenderProps}>
                                {item.name}
                            </RadioGroupLabel>
                        )}
                    </RadioGroupOption>
                ))}
            </div>
        </RadioGroup>
    )
}

export default AllowDocumentsRadioGroup
