import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useClosureTemplateList } from 'api'
import { useOperationClosurePolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Button, Loader } from 'components/ui'
import { listReducer } from 'helpers'
import ListLayout from 'layouts/ListLayout'
import List from 'components/ClosureTemplateList'

const ClosureTemplateList = () => {
    const operationClosurePolicy = useOperationClosurePolicy()

    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })

    const templateListQueryResult = useClosureTemplateList(filters, {
        enabled: operationClosurePolicy.canCreate,
    })

    if (!operationClosurePolicy.canCreate) {
        return <AccessDenied message="Nie masz dostępu do szablonów" />
    }

    if (templateListQueryResult.isLoading) {
        return <Loader />
    }

    if (templateListQueryResult.isError) {
        return <div>{templateListQueryResult.error.message}</div>
    }

    if (!templateListQueryResult.isSuccess) {
        return null
    }

    return (
        <ListLayout
            title="Szablony"
            actions={
                <>
                    <Button
                        as="link"
                        to="/closures/templates/create"
                        variant="primary"
                        iconRight={<PlusIcon />}
                        size="md"
                    >
                        Dodaj szablon
                    </Button>
                </>
            }
        >
            <List data={templateListQueryResult.data} setFilters={setFilters} />
        </ListLayout>
    )
}

export default ClosureTemplateList
