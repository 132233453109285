import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    OperationEventResponse,
    OperationEventListMeta,
    ResponseData,
    ResponseError,
    ResponseList,
} from 'api/types'

type OperationEventFilters = {
    date?: string
    live_view?: boolean
}

export const useGetOperatingEvents = <
    T = OperationEventResponse,
    E = ResponseError
>(
    filters?: OperationEventFilters,
    options?: UseQueryOptions<ResponseList<T[], OperationEventListMeta>, E>
) =>
    useQuery<ResponseList<T[], OperationEventListMeta>, E>(
        ['operation-events', filters?.date],
        () =>
            axios.get(
                '/operation-events' +
                    (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )

export const usePatchOperationEvent = <
    R1 extends {
        id: number
        data: {
            action: string
            change_manually: boolean
        }
    },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id, data }) => axios.patch('/operation-events/' + id, data))
