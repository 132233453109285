import { usePolicy, usePatientPolicy } from 'hooks'

export const usePatientMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const patientPolicy = usePatientPolicy()

    return {
        user,
        canIndex: patientPolicy.canIndex,
        canCreate: patientPolicy.canCreate,
        get canAny() {
            return this.canIndex || this.canCreate
        },
    }
}
