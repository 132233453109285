import { usePolicy, useUserPolicyGroup } from 'hooks'

export const useUserMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const useRolePolicyGroup = useUserPolicyGroup()

    return {
        user,
        canAny: useRolePolicyGroup.canAll,
    }
}
