import {
    usePayerPolicy,
    usePolicy,
    useProcedureCategoryPolicy,
    useProcedureCostPolicyGroup,
    useProcedureDurationsPolicyGroup,
    useProcedurePolicyGroup,
    useProcedureTypePolicyGroup,
} from 'hooks'

export const useProcedureMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const procedureCategoryPolicyGroup = useProcedureTypePolicyGroup()
    const payerPolicy = usePayerPolicy()
    const procedurePolicyGroup = useProcedurePolicyGroup()
    const procedureDurationsPolicy = useProcedureDurationsPolicyGroup()
    const procedureCostPolicyGroup = useProcedureCostPolicyGroup()

    return {
        user,
        canAny:
            procedureCategoryPolicy.canAll ||
            procedureCategoryPolicyGroup.canAll ||
            payerPolicy.canAll ||
            procedurePolicyGroup.canAll ||
            procedureDurationsPolicy.canAll ||
            procedureCostPolicyGroup.canAll,
    }
}
