import { useMutation, UseMutationResult } from 'react-query'

import axios from 'api/axios'
import { ResponseError, ResetPassword, ResponseData } from 'api/types'

export const resetPassword = (
    data: ResetPassword
): Promise<ResponseData<ResetPassword>> => axios.post('/reset-password', data)

export const useResetPassword = (): UseMutationResult<
    ResponseData<ResetPassword>,
    ResponseError,
    ResetPassword
> => useMutation((data) => resetPassword(data))
