import React from 'react'
import { Form, Formik } from 'formik'

import { Label, RangeDatePicker } from 'components/ui'
import { AutoSubmitRow, EmployeePickerField } from 'components/forms'
import { PERMISSIONS } from 'constants/index'

import type {
    DoctorSurgeryDraftListFilterAction,
    DoctorSurgeryDraftListFilterForm,
} from 'containers/DoctorSurgeryDraftList'

const DoctorSurgeryDraftListFilters: React.FC<{
    values: DoctorSurgeryDraftListFilterForm
    dispatchAction: React.Dispatch<DoctorSurgeryDraftListFilterAction>
}> = ({ values, dispatchAction }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={values}
                enableReinitialize
                onSubmit={(values) =>
                    dispatchAction({ type: 'changeFilters', payload: values })
                }
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                            <div>
                                <Label>Lekarz</Label>
                                <EmployeePickerField
                                    name="doctor"
                                    placeholder="Szukaj lekarza"
                                    functions={[PERMISSIONS.DOCTOR_FUNCTION]}
                                    messageEmptyResult="Nie znaleziono lekarza."
                                />
                            </div>
                            <div>
                                <Label>Data</Label>
                                <RangeDatePicker
                                    placeholderFrom="Od:"
                                    placeholderTo="Do:"
                                    value={values.dateRange}
                                    onChange={(dateRange) => {
                                        setFieldValue('dateRange', dateRange)
                                    }}
                                />
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default DoctorSurgeryDraftListFilters
