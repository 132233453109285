import React from 'react'
import { FormikHelpers } from 'formik'

import { useCreatePayer } from 'api'
import { PostPayer } from 'api/types'
import SettingsPayerCreateModal from 'components/SettingsPayerCreateModal'
import { IModal } from 'types'

export default function SettingsPayerCreateModalContainer(props: {
    modal: IModal
    handleSubmit: () => void
}) {
    const { modal } = props
    const { mutate } = useCreatePayer()

    const handleSubmit = (
        values: PostPayer,
        formikHelpers: FormikHelpers<PostPayer>
    ) => {
        mutate(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
                props.handleSubmit()
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <SettingsPayerCreateModal modal={modal} handleSubmit={handleSubmit} />
    )
}
