import {
    usePolicy,
    useUserPolicy,
    useRolePolicy,
    useSpecializationPolicy,
    useOperatingRoomPolicy,
} from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useUserPolicyGroup = () => {
    const { user, hasPermission } = usePolicy()
    const userPolicy = useUserPolicy()
    const rolePolicy = useRolePolicy()
    const specializationPolicy = useSpecializationPolicy()
    const operatingRoomPolicy = useOperatingRoomPolicy()

    return {
        user,
        canAll:
            userPolicy.canAll &&
            rolePolicy.canIndex &&
            specializationPolicy.canIndex &&
            operatingRoomPolicy.canIndex &&
            hasPermission(PERMISSIONS.ASSIGN_ROLES) &&
            hasPermission(PERMISSIONS.ACTIVATE_USERS),
    }
}
