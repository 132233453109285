import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Label({
    children,
    htmlFor,
    className,
}: React.LabelHTMLAttributes<HTMLLabelElement>) {
    return (
        <label
            htmlFor={htmlFor}
            className={twMerge(
                'mb-1 block text-sm font-medium text-gray-700',
                className
            )}
        >
            {children}
        </label>
    )
}
