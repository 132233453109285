import React from 'react'
import classNames from 'classnames'
import { PlusSmIcon } from '@heroicons/react/solid'

const AssignButton: React.FC<{
    onClick?: () => void
    size?: 'sm' | 'md'
}> = ({ onClick, size = 'md' }) => {
    return (
        <span
            className={classNames(
                'rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-400 cursor-pointer',
                { 'w-5 h-5': size === 'sm', 'w-8 h-8': size === 'md' }
            )}
            onClick={onClick}
        >
            <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
        </span>
    )
}

export default AssignButton
