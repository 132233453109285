import React from 'react'
import { PrinterIcon } from '@heroicons/react/outline'

import { Button } from 'components/ui'

import styles from './ButtonPrint.module.scss'

export default function ButtonPrint() {
    return (
        <Button
            type="button"
            variant="default"
            size="sm"
            styles={styles}
            iconLeft={<PrinterIcon className="w-4 h-4 text-gray-500" />}
            onClick={() => window.print()}
        >
            <span className={styles.label}>Drukuj</span>
        </Button>
    )
}
