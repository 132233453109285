export const SESSION_STORAGE_SURGERY_LIST_FILTER_KEY =
    'promedheusSurgeryListFilters'
export const SESSION_STORAGE_DIET_LIST_FILTER_KEY = 'promedheusDietListFilters'
export const SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY =
    'promedheusStatisticsDateRangeFilter'
export const SESSION_STORAGE_STATISTICS_SURGERIES_FILTER_KEY =
    'promedheusStatisticsSurgeryFilters'
export const SESSION_STORAGE_STATISTICS_SURGERIES_REJECTED_FILTER_KEY =
    'promedheusStatisticsSurgeryRejectedFilters'
export const SESSION_STORAGE_STATISTICS_DOCTORS_FILTER_KEY =
    'promedheusStatisticsDoctorFilters'
export const SESSION_STORAGE_STATISTICS_ANESTHESIOLOGISTS_FILTER_KEY =
    'promedheusStatisticsAnesthesiologistsFilters'
export const SESSION_STORAGE_PERFORMED_SURGERY_LIST_FILTER_KEY =
    'promedheusPerformedSurgeryListFilters'
