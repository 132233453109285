import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    Comment,
    PostComment,
    CommentCreated,
    CommentListFilters,
    ResponseData,
    ResponseList,
    ResponseError,
    Pagination,
} from 'api/types'

export const createComment = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/comments', data)

export const useCreateComment = <
    R1 = PostComment,
    R2 = ResponseData<CommentCreated>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createComment<R1, R2>(data))

export const useGetComments = <
    T = ResponseList<
        Comment[],
        Pagination & { has_tasks_with_comments: boolean }
    >
>(
    filters: CommentListFilters,
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['comments', filters],
        () =>
            axios.get(
                '/comments' +
                    (filters
                        ? '?' +
                          qs.stringify(
                              {
                                  ...filters,
                                  pagination: false,
                              },
                              {}
                          )
                        : '')
            ),
        options
    )
