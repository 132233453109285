import React from 'react'
import { Form, Formik } from 'formik'
import classNames from 'classnames'
import { UseQueryResult } from 'react-query'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Label, Button, Loader } from 'components/ui'

import type { FormSubmitFn, IModal } from 'types'
import type { Procedure, ResponseData, ResponseError } from 'api/types'
import type { ProcedureDropdownItem } from 'components/forms'
import { ProcedureFieldArray } from 'components/SettingsImplantSetForm'

export interface FormValues {
    procedures: ProcedureDropdownItem[]
    _procedure_item?: ProcedureDropdownItem
    _procedure_item_phrase: string
}

const SettingsImplantListAssignModal: React.FC<{
    modal: IModal
    procedures: UseQueryResult<ResponseData<Procedure[]>, ResponseError>
    handleSubmit: FormSubmitFn<FormValues>
}> = ({ modal, procedures, handleSubmit }) => {
    return (
        <Modal modal={modal}>
            <div className={styles.modalInner}>
                <Dialog.Title as="h3" className={styles.dialogTitle}>
                    Przypisz do:
                </Dialog.Title>
                {procedures.isLoading && <Loader />}
                {procedures.isSuccess && (
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={
                            {
                                procedures: [],
                                _procedure_item: undefined,
                                _procedure_item_phrase: '',
                            } as FormValues
                        }
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <div className="mt-6">
                                    <Label>Operacja</Label>
                                    <ProcedureFieldArray name="procedures" />
                                </div>
                                <div className={styles.actionContainer}>
                                    <Button
                                        variant="primary"
                                        className="col-start-2"
                                        type="submit"
                                        loading={isSubmitting}
                                        disabled={
                                            values.procedures.length === 0
                                        }
                                    >
                                        Zapisz
                                    </Button>
                                    <button
                                        type="button"
                                        className={styles.buttonCancel}
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Modal>
    )
}

export default SettingsImplantListAssignModal

const styles = {
    modalInner: classNames(
        'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl',
        'transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
    ),
    dialogTitle: 'text-lg leading-6 font-medium text-gray-900 text-center',
    actionContainer:
        'mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
    buttonCancel: classNames(
        'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2',
        'bg-white text-base font-medium text-gray-700 sm:mt-0 sm:col-start-1 sm:text-sm',
        'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    ),
}
