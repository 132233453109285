import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'constants/index'
import { useGetUser } from 'api'
import { Loader } from 'components/ui'

export default function AuthRoutes() {
    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
    const user = useGetUser({ enabled: !!authToken })

    if (!authToken) {
        return <Navigate to="/login" />
    }

    if (user.isLoading) {
        return <Loader />
    }

    if (user.isError) {
        return <div>{user.error.message}</div>
    }

    if (user.isSuccess) {
        return <Outlet />
    }

    return null
}
