import React from 'react'
import { Formik, Form } from 'formik'

import { TextField } from 'components/forms'
import { Label, Button } from 'components/ui'
import { ReactComponent as IconSuccess } from 'assets/icon-success.svg'

import type { ForgotPasswordForm, FormSubmitFn } from 'types'

interface ForgotPasswordProps {
    isSuccess: boolean
    handleSubmit: FormSubmitFn<ForgotPasswordForm>
}

export default function ForgotPassword({
    isSuccess,
    handleSubmit,
}: ForgotPasswordProps) {
    if (isSuccess) {
        return (
            <div className="space-y-6 flex flex-col text-center items-center">
                <IconSuccess />
                <p className="mt-7 text-lg leading-6 font-medium">
                    Link został wysłany
                </p>
                <p className="mt-4 text-sm leading-5 font-normal text-gray-500">
                    Przejdź do swojej skrzynki pocztowej <br />i potwierdź
                    zmianę hasła, klikając w otrzymany link.
                </p>
            </div>
        )
    }

    return (
        <Formik initialValues={{ email: '' }} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form className="space-y-6">
                    <div>
                        <Label>Podaj adres e-mail</Label>
                        <TextField name="email" />
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-full"
                            loading={isSubmitting}
                        >
                            Wyślij link do przypomnienia hasła
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
