import React from 'react'
import { DayPicker } from 'react-day-picker'
import FocusTrap from 'focus-trap-react'
import moment from 'moment'
import classNames from 'classnames'

import { getCxFromStyles } from 'helpers'
import { API_FORMAT_DATE } from 'constants/index'
import {
    getDatePickerConfig,
    CommonDatePickerProps,
    useDatePickerPopper,
    getFocusTrapOptions,
} from './DatePickerBase'
import OperationTaskDate from 'components/OperationTaskDate'

import 'react-day-picker/dist/style.css'
import styles from './DatePicker.module.scss'

export interface TermDatePickerProps extends CommonDatePickerProps<Date> {
    placeholder?: string
    status?: string
}

const TermDatePicker: React.FC<TermDatePickerProps> = ({
    placeholder = 'Termin',
    blockedDays = [],
    value,
    onChange,
    disabled,
    status = '',
}) => {
    const cx = getCxFromStyles(styles)

    const {
        popperRef,
        setPopperElement,
        popperState,
        popper,
        handleClosePopper,
        handleOpenPopper,
    } = useDatePickerPopper()

    const handleOnClick = () => {
        handleOpenPopper()
    }

    const datePickerConfig = getDatePickerConfig(blockedDays)

    const focusTrapOptions = getFocusTrapOptions(handleClosePopper)

    const onSelect = (date?: Date) => {
        onChange(date)
        handleClosePopper()
    }

    return (
        <div>
            <div ref={popperRef}>
                <OperationTaskDate
                    className={classNames({ 'cursor-pointer': !disabled })}
                    date={value ? moment(value).format(API_FORMAT_DATE) : ''}
                    onClick={handleOnClick}
                    placeholder={placeholder}
                    status={status}
                />
            </div>
            {!disabled && popperState.isOpen && (
                <FocusTrap active focusTrapOptions={focusTrapOptions}>
                    <div
                        tabIndex={-1}
                        className="z-10 text-base text-gray-700"
                        style={popper.styles.popper}
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <div
                            className={cx('root', {
                                isSingleDate: true,
                            })}
                        >
                            <DayPicker
                                {...datePickerConfig}
                                mode="single"
                                defaultMonth={value}
                                selected={value}
                                onSelect={onSelect}
                                fromDate={new Date()}
                            />
                        </div>
                    </div>
                </FocusTrap>
            )}
        </div>
    )
}

export default TermDatePicker
