import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    ResponseList,
    ResponseError,
    DietListItem,
    DietListFilters,
} from 'api/types'

export const useDiets = <
    F extends DietListFilters,
    R = ResponseList<DietListItem[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['diets', filters],
        () =>
            client.get('/diets' + (filters ? '?' + qs.stringify(filters) : '')),
        options
    )
