export type ListFilters = {
    sort_by?: string
    sort_direction?: string
    length?: number
    page?: number
}

export type ListFilterAction =
    | { type: 'setPage'; payload: number }
    | { type: 'setSorting'; payload: string }
    | { type: 'setSortingDirection'; payload: string }

export function listReducer(state: ListFilters, action: ListFilterAction) {
    switch (action.type) {
        case 'setSorting':
            return {
                ...state,
                sort_by: action.payload,
                page: 1,
            }

        case 'setSortingDirection':
            return {
                ...state,
                sort_direction: action.payload,
                page: 1,
            }

        case 'setPage':
            return {
                ...state,
                page: action.payload,
            }

        default:
            return state
    }
}
