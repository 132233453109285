import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { InputPrice, Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import { Implant, ResponseList } from 'api/types'

export interface FormValues {
    id: number
    price: number | string
}

interface ImplantSetListProps {
    data: ResponseList<Implant[]>
    filtersCount: number
    setPage: React.Dispatch<React.SetStateAction<number>>
}

export default function SettingsImplantSetList({
    data,
    filtersCount,
    setPage,
}: ImplantSetListProps) {
    return (
        <>
            <div className="bg-white p-2 rounded-lg">
                {data.meta.total > 0 && (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Id
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nazwa
                                </th>
                                <th
                                    scope="col"
                                    className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Występowanie
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Cena
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Opcje
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                            {data.data.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={classNames({
                                        'bg-gray-50': index % 2,
                                    })}
                                >
                                    <td className="px-6 py-6 whitespace-nowrap">
                                        <span className="text-gray-500 font-normal">
                                            {item.id}
                                        </span>
                                    </td>
                                    <td className="px-6 py-6">
                                        <span className="font-medium">
                                            {item.name}
                                        </span>
                                    </td>
                                    <td className="px-6 py-6">
                                        <div className="inline-flex w-60 items-center justify-between text-gray-500">
                                            <span className="truncate">
                                                {item.procedures
                                                    .filter(
                                                        (_, index) =>
                                                            index === 0
                                                    )
                                                    .map((i) => i.name)}
                                            </span>
                                            <div className="ml-2 flex-shrink-0 flex">
                                                <span className="text-gray-500 hover:text-gray-700">
                                                    {item.procedures.length >
                                                        1 &&
                                                        '+' +
                                                            (item.procedures
                                                                .length -
                                                                1)}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-1 py-1 text-center">
                                        <InputPrice
                                            value={(
                                                Number(item.price) +
                                                Number(item.surcharge)
                                            ).toFixed(2)}
                                            disabled={true}
                                            variant="sm"
                                            className="w-11/12 text-gray-500"
                                        />
                                    </td>
                                    <td className="px-6 py-1 whitespace-nowrap text-right">
                                        <span className="font-normal">
                                            <Link
                                                to={`/settings/price-list/implant-sets/${item.id}`}
                                                className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Edytuj
                                            </Link>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {data.meta.total > 0 && (
                    <Pagination meta={data.meta} handleChange={setPage} />
                )}
                {data.meta.total === 0 && filtersCount === 0 && (
                    <EmptyList
                        headline="Nic tu jeszcze nie ma"
                        message="Zacznij od stworzenia pierwszego zestawu implantów."
                        to="/settings/price-list/implant-sets/add"
                    />
                )}
                {data.meta.total === 0 && filtersCount > 0 && (
                    <EmptyList
                        headline="Nie znaleziono wyników wyszukiwania"
                        message="Możesz utworzyć nowy zestaw implantów."
                        to="/settings/price-list/implant-sets/add"
                    />
                )}
            </div>
        </>
    )
}
