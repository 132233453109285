import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import type { Moment } from 'moment'
import type {
    OperationsByRoom,
    OperationEventEstimatedTime,
} from 'containers/LiveView'

const LiveViewOperationMonitor: React.FC<{
    operationsByRoom: OperationsByRoom
    children: ({
        operationsByRoom,
    }: {
        operationsByRoom: OperationsByRoom
    }) => JSX.Element
}> = ({ children, ...props }) => {
    const navigate = useNavigate()
    const [operationsByRoom, setOperationByRooms] = useState(() =>
        estimateNewOperationStartTimesAllRooms(props.operationsByRoom)
    )
    const [nextReloadLiveViewDate] = useState(() => moment().endOf('day'))

    useEffect(() => {
        const interval = setInterval(() => {
            if (moment().diff(nextReloadLiveViewDate, 'seconds') > 0) {
                return navigate(0)
            }
        }, 1000)

        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setOperationByRooms(
            estimateNewOperationStartTimesAllRooms(props.operationsByRoom)
        )

        const interval = setInterval(async () => {
            const operationNextStartTimeState =
                estimateNewOperationStartTimesAllRooms(props.operationsByRoom)

            await setOperationByRooms(operationNextStartTimeState)
        }, 1000)

        return () => clearInterval(interval)
    }, [props.operationsByRoom])

    return children({ operationsByRoom })
}

const estimateNewOperationStartTimesAllRooms = (
    operationsByRoom: OperationsByRoom
) => {
    return {
        byId: operationsByRoom.allIds.reduce((acc, id) => {
            acc[id] = estimateStartTime(operationsByRoom)(id)
            return acc
        }, {} as OperationsByRoom['byId']),
        allIds: operationsByRoom.allIds,
    }
}

const estimateStartTime =
    (operationsByRoom: OperationsByRoom) => (id: number) => {
        const operationNotFinished = operationsByRoom.byId[id]
            .filter((item) => item.live_view_status !== 'finished')
            .reduce((acc, item, index) => {
                if (
                    index === 0 &&
                    item.live_view_status === 'procedure_started'
                ) {
                    const realStartTime = moment(
                        item.real_operation_start
                    ).format('YYYY-MM-DD HH:mm:ss')

                    const estimatedEndTime = moment(realStartTime)
                        .add(item.duration, 'minutes')
                        .format('YYYY-MM-DD HH:mm:ss')

                    const estimatedEndTimeWithoutCleaning = moment(
                        realStartTime
                    )
                        .add(item.duration_without_cleaning, 'minutes')
                        .format('YYYY-MM-DD HH:mm:ss')

                    const isTimeOverrun =
                        moment().diff(
                            estimatedEndTimeWithoutCleaning,
                            'seconds'
                        ) > 0

                    acc[index] = {
                        ...item,
                        operation_estimated_start: realStartTime,
                        operation_estimated_finish: isTimeOverrun
                            ? moment()
                                  .add(
                                      item.duration -
                                          item.duration_without_cleaning,
                                      'minutes'
                                  )
                                  .format('YYYY-MM-DD HH:mm:ss')
                            : estimatedEndTime,
                    }

                    return acc
                }

                if (
                    index === 0 &&
                    item.live_view_status === 'ready_for_cleaning'
                ) {
                    const estimatedEndTime = moment()
                        .add(
                            item.duration - item.duration_without_cleaning,
                            'minutes'
                        )
                        .format('YYYY-MM-DD HH:mm:ss')

                    acc[index] = {
                        ...item,
                        operation_estimated_finish: estimatedEndTime,
                    }

                    return acc
                }

                if (index === 0 && item.live_view_status === 'cleaning') {
                    const estimatedEndTime = moment(item.created_at).add(
                        item.duration - item.duration_without_cleaning,
                        'minutes'
                    )

                    acc[index] = {
                        ...item,
                        operation_estimated_start: item.created_at,
                        operation_estimated_finish: (moment().diff(
                            estimatedEndTime,
                            'seconds'
                        ) > 0
                            ? moment()
                            : estimatedEndTime
                        ).format('YYYY-MM-DD HH:mm:ss'),
                    }

                    return acc
                }

                if (
                    index === 0 &&
                    item.live_view_status !== 'procedure_started'
                ) {
                    if (
                        [
                            'calling_for_patient',
                            'in_transfer_to_block',
                            'admitted_to_operating_block',
                        ].includes(item.live_view_status)
                    ) {
                        let estimatedStartTime = alignTimeToNext5minutes(
                            moment(item.patient_called_at).add(30, 'minutes')
                        ).format('YYYY-MM-DD HH:mm:ss')

                        const isDelayed =
                            moment().diff(
                                moment(estimatedStartTime),
                                'seconds'
                            ) > 0

                        estimatedStartTime = isDelayed
                            ? alignTimeToNext5minutesFromNow().format(
                                  'YYYY-MM-DD HH:mm:ss'
                              )
                            : estimatedStartTime

                        const estimatedEndTime = moment(estimatedStartTime)
                            .add(item.duration, 'minutes')
                            .format('YYYY-MM-DD HH:mm:ss')

                        acc[index] = {
                            ...item,
                            operation_estimated_start: estimatedStartTime,
                            operation_estimated_finish: estimatedEndTime,
                        }

                        return acc
                    }

                    const isDelayed =
                        moment().diff(moment(item.operation_start), 'seconds') >
                        0

                    const startTime = isDelayed
                        ? alignTimeToNext5minutesFromNow().format(
                              'YYYY-MM-DD HH:mm:ss'
                          )
                        : item.operation_start

                    const estimatedEndTime = moment(startTime)
                        .add(item.duration, 'minutes')
                        .format('YYYY-MM-DD HH:mm:ss')

                    acc[index] = {
                        ...item,
                        operation_estimated_start: startTime,
                        operation_estimated_finish: estimatedEndTime,
                    }

                    return acc
                }

                const isOverlap =
                    moment(acc[index - 1].operation_estimated_finish).diff(
                        item.operation_estimated_start,
                        'seconds'
                    ) > 0

                if (isOverlap) {
                    const estimatedStartTime = moment(
                        acc[index - 1].operation_estimated_finish
                    ).add('5', 'minutes')

                    const alignedEstimatedStartTime = alignTimeToNext5minutes(
                        estimatedStartTime
                    ).format('YYYY-MM-DD HH:mm:ss')

                    acc[index] = {
                        ...item,
                        operation_estimated_start: alignedEstimatedStartTime,
                        operation_estimated_finish: moment(
                            alignedEstimatedStartTime
                        )
                            .add(item.duration, 'minutes')
                            .format('YYYY-MM-DD HH:mm:ss'),
                    }

                    return acc
                }

                const isDelayed =
                    moment().diff(moment(item.operation_start), 'seconds') > 0

                if (isDelayed) {
                    const startTime = isDelayed
                        ? alignTimeToNext5minutes(
                              moment(
                                  acc[index - 1].operation_estimated_finish
                              ).add('5', 'minutes')
                          ).format('YYYY-MM-DD HH:mm:ss')
                        : item.operation_start

                    const estimatedEndTime = moment(startTime)
                        .add(item.duration, 'minutes')
                        .format('YYYY-MM-DD HH:mm:ss')

                    acc[index] = {
                        ...item,
                        operation_estimated_start: startTime,
                        operation_estimated_finish: estimatedEndTime,
                    }

                    return acc
                }

                acc[index] = item
                return acc
            }, [] as OperationEventEstimatedTime[])

        return [
            ...operationsByRoom.byId[id].filter(
                (item) => item.live_view_status === 'finished'
            ),
            ...operationNotFinished,
        ]
    }

const alignTimeToNext5minutes = (time: Moment): Moment => {
    return time.set({
        minutes: 5 * Math.ceil(time.minutes() / 5),
        seconds: 0,
    })
}

const alignTimeToNext5minutesFromNow = () => {
    return alignTimeToNext5minutes(moment())
}

export default LiveViewOperationMonitor
