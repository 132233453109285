import { useState, useMemo, useCallback, useEffect } from 'react'

export interface SelectedRow {
    id: string | number
}

export interface UseSelectedRowsProps<T extends SelectedRow> {
    initialSelections: T[]
    currentPageRows: T[]
    checkIfRowIsEnabled?: (row: T) => boolean
}

const useKeyDown = (key: string) => {
    const [isKeyDown, setIsKeyDown] = useState<boolean>(false)

    const setKeyDown = useCallback(
        (event: KeyboardEvent) =>
            event.key === key ? setIsKeyDown(true) : undefined,
        [key, setIsKeyDown]
    )

    const setKeyUp = useCallback(
        (event: KeyboardEvent) =>
            event.key === key ? setIsKeyDown(false) : undefined,
        [key, setIsKeyDown]
    )

    useEffect(() => {
        document.addEventListener('keydown', setKeyDown)
        document.addEventListener('keyup', setKeyUp)

        return () => {
            document.removeEventListener('keydown', setKeyDown)
            document.removeEventListener('keyup', setKeyUp)
        }
    }, [key, setKeyUp, setKeyDown])

    return isKeyDown
}

const uniqueRowFilter = <T extends SelectedRow>(
    value: T,
    index: number,
    rows: T[]
) => rows.findIndex(({ id }) => id === value.id) === index

const getRowsIds = <T extends SelectedRow>(rows: T[]) =>
    rows.map(({ id }) => id)

const useSelectedRows = <T extends SelectedRow>({
    initialSelections = [],
    currentPageRows = [],
    checkIfRowIsEnabled,
}: UseSelectedRowsProps<T>) => {
    const [selectedRows, setSelectedRows] = useState<T[]>(initialSelections)
    const [previousSelectedRow, setPreviousSelectedRow] = useState<T | null>(
        null
    )
    const isShiftPressed = useKeyDown('Shift')

    const currenPageRowsIds = useMemo(
        () => getRowsIds(currentPageRows),
        [currentPageRows]
    )

    const currentPageSelectedRows = useMemo(
        () => selectedRows.filter((row) => currenPageRowsIds.includes(row.id)),
        [selectedRows, currenPageRowsIds]
    )

    const isAnyRowSelected = useMemo(
        () => selectedRows.length > 0,
        [selectedRows]
    )
    const isAnyRowOnCurrentPageSelected = useMemo(
        () => currentPageSelectedRows.length > 0,
        [currentPageSelectedRows]
    )

    const areAllRowsOnCurrentPageSelected = useMemo(
        () =>
            isAnyRowOnCurrentPageSelected &&
            currentPageSelectedRows.length === currentPageRows.length,
        [
            currentPageSelectedRows,
            currentPageRows,
            isAnyRowOnCurrentPageSelected,
        ]
    )

    const checkIsRowSelected = useCallback(
        ({ id: currentSelectedRowId }: T) =>
            selectedRows.some(({ id }: T) => id === currentSelectedRowId),
        [selectedRows]
    )

    const _getEnabledRows = useCallback(
        (rows: T[]) =>
            checkIfRowIsEnabled ? rows.filter(checkIfRowIsEnabled) : rows,
        [checkIfRowIsEnabled]
    )

    const selectRow = useCallback(
        (currentSelectedRow: T) => {
            const isRowEnabled = checkIfRowIsEnabled
                ? checkIfRowIsEnabled(currentSelectedRow)
                : true

            if (!checkIsRowSelected(currentSelectedRow) && isRowEnabled) {
                setSelectedRows([...selectedRows, currentSelectedRow])
                setPreviousSelectedRow(currentSelectedRow)
            }
        },
        [checkIsRowSelected, checkIfRowIsEnabled, selectedRows]
    )

    const selectRows = useCallback(
        (rows: T[]) =>
            setSelectedRows([...selectedRows, ...rows].filter(uniqueRowFilter)),
        [selectedRows]
    )

    const selectAllCurrentPageRows = useCallback(
        () => selectRows(_getEnabledRows(currentPageRows)),
        [_getEnabledRows, currentPageRows, selectRows]
    )

    const unselectRow = useCallback(
        (currentSelectedRow: T) => {
            setSelectedRows(
                selectedRows.filter(({ id }: T) => id !== currentSelectedRow.id)
            )
            setPreviousSelectedRow(currentSelectedRow)
        },
        [selectedRows]
    )

    const unselectRows = useCallback(
        (rows: T[]) => {
            const newSelectedRowsId = getRowsIds(rows)
            setSelectedRows(
                selectedRows.filter(
                    ({ id }: T) => !newSelectedRowsId.includes(id)
                )
            )
        },
        [selectedRows]
    )

    const unselectAllCurrentPageRows = useCallback(() => {
        unselectRows(currentPageRows)
        setPreviousSelectedRow(null)
    }, [currentPageRows, unselectRows])

    const unselectAllRows = useCallback(() => {
        setSelectedRows([])
        setPreviousSelectedRow(null)
    }, [])

    const _getSelectedRowsInRange = useCallback(
        (currentSelectedRow: T, previousSelectedRow: T) => {
            const lastSelectedRowIndex = currentPageRows.findIndex(
                (row) => previousSelectedRow.id === row.id
            )
            const selectedRowIndex = currentPageRows.findIndex(
                (row) => currentSelectedRow.id === row.id
            )

            const [startIndex, endIndex] =
                selectedRowIndex > lastSelectedRowIndex
                    ? [lastSelectedRowIndex, selectedRowIndex]
                    : [selectedRowIndex, lastSelectedRowIndex]

            return currentPageRows.slice(startIndex, endIndex + 1)
        },
        [currentPageRows]
    )

    const toggleMultiRowSelection = useCallback(
        (currentSelectedRow: T, previousSelectedRow: T) => {
            const newSelectedRows = _getEnabledRows(
                _getSelectedRowsInRange(currentSelectedRow, previousSelectedRow)
            )
            const selectedRowsIds = getRowsIds(selectedRows)

            const areAllRowsInRangeSelected = newSelectedRows
                .filter((row) => row.id !== previousSelectedRow.id)
                .some((row) => !selectedRowsIds.includes(row.id))

            areAllRowsInRangeSelected
                ? selectRows(newSelectedRows)
                : unselectRows(newSelectedRows)

            setPreviousSelectedRow(currentSelectedRow)
        },
        [
            _getEnabledRows,
            _getSelectedRowsInRange,
            selectedRows,
            selectRows,
            unselectRows,
        ]
    )

    const toggleSingleRowSelection = useCallback(
        (currentSelectedRow: T) =>
            checkIsRowSelected(currentSelectedRow)
                ? unselectRow(currentSelectedRow)
                : selectRow(currentSelectedRow),
        [checkIsRowSelected, selectRow, unselectRow]
    )

    const toggleRowSelection = useCallback(
        (currentSelectedRow: T) =>
            isShiftPressed &&
            previousSelectedRow &&
            previousSelectedRow.id !== currentSelectedRow.id
                ? toggleMultiRowSelection(
                      currentSelectedRow,
                      previousSelectedRow
                  )
                : toggleSingleRowSelection(currentSelectedRow),
        [
            isShiftPressed,
            previousSelectedRow,
            toggleMultiRowSelection,
            toggleSingleRowSelection,
        ]
    )

    const toggleAllCurrentPageRowsSelection = useCallback(
        () =>
            areAllRowsOnCurrentPageSelected
                ? unselectAllCurrentPageRows()
                : selectAllCurrentPageRows(),
        [
            areAllRowsOnCurrentPageSelected,
            unselectAllCurrentPageRows,
            selectAllCurrentPageRows,
        ]
    )

    return {
        selectedRows,
        isAnyRowSelected,
        isAnyRowOnCurrentPageSelected,
        areAllRowsOnCurrentPageSelected,
        setSelectedRows,
        checkIsRowSelected,
        selectRow,
        unselectRow,
        toggleRowSelection,
        selectAllCurrentPageRows,
        unselectAllRows,
        toggleAllCurrentPageRowsSelection,
        unselectAllCurrentPageRows,
        selectedRowsCount: selectedRows.length,
    }
}

export default useSelectedRows
