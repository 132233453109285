import React from 'react'

import { getCxFromStyles } from 'helpers'

import { ReactComponent as IconOperationCancelled } from 'assets/operation-cancelled.svg'

import styles from 'components/LiveViewOperationEventStatus.module.scss'

const ScheduleOperationStatusCancelled = () => {
    const cx = getCxFromStyles(styles)

    return (
        <div className="flex-1 w-full h-16">
            <div className={cx('root', 'cancelled')}>
                <div className={cx('inner')}>
                    <span className={cx('icon')}>
                        <IconOperationCancelled />
                    </span>
                    Zabieg anulowany
                </div>
            </div>
        </div>
    )
}

export default ScheduleOperationStatusCancelled
