import {
    usePolicy,
    useSchedulePlannerPolicyGroup,
    useScheduleApprovedPolicyGroup,
} from 'hooks'

export const useScheduleMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    return {
        user,
        canIndexApproved: scheduleApprovedPolicyGroup.canIndex,
        canIndexPlanner: schedulePlannerPolicyGroup.canIndex,
        get canAny() {
            return this.canIndexApproved || this.canIndexPlanner
        },
    }
}
