import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLogout } from 'api/endpoints/logout'
import Spinner from 'components/ui/Spinner'

export default function LogoutContainer() {
    const navigate = useNavigate()
    const { mutate } = useLogout()

    useEffect(() => {
        mutate(
            {},
            {
                onSettled: () => {
                    localStorage.clear()
                    sessionStorage.clear()
                    navigate('/login', { replace: true })
                },
            }
        )
        // eslint-disable-next-line
    }, [])

    return (
        <div className="w-full h-full flex justify-center items-center">
            <Spinner />
        </div>
    )
}
