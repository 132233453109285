import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import {
    useGetImplantManufacturers,
    useUpdateImplantManufacturerActive,
} from 'api'
import { listReducer } from 'helpers'
import { useModal, useImplantManufacturePolicy } from 'hooks'
import { AccessDenied } from 'components'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsImplantManufacturerModal from 'containers/SettingsImplantManufacturerModal'
import SettingsImplantManufactureList from 'components/SettingsImplantManufactureList'
import { ActionButton, Loader } from 'components/ui'

export default function SettingsImplantManufactureListContainer() {
    const implantManufacturePolicy = useImplantManufacturePolicy()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const manufacturerModal = useModal()
    const queryClient = useQueryClient()
    const manufacturers = useGetImplantManufacturers(filters, {
        enabled: implantManufacturePolicy.canAll,
    })
    const { mutate: update } = useUpdateImplantManufacturerActive()

    const handleEdit = (id: number) => {
        manufacturerModal.setState(id)
        manufacturerModal.openModal()
    }

    const handleSubmit = async () => {
        manufacturerModal.closeModal()
        await queryClient.invalidateQueries('implant-manufacturers')
    }

    const handleToggleActive = async (id: number, active: boolean) => {
        try {
            await update({ id, active })
        } catch (e) {}

        await queryClient.invalidateQueries('implant-manufacturers')
    }

    return (
        <SettingsPriceListLayout
            actions={
                implantManufacturePolicy.canAll && (
                    <>
                        <ActionButton
                            handleClick={manufacturerModal.openModal}
                            rightIcon={PlusIcon}
                        >
                            Dodaj
                        </ActionButton>
                    </>
                )
            }
        >
            <>
                {!implantManufacturePolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania producentami" />
                )}
                {manufacturers.isLoading && <Loader />}
                {manufacturers.isError && <div>{manufacturers.error}</div>}
                {manufacturers.isSuccess && (
                    <SettingsImplantManufactureList
                        data={manufacturers.data}
                        modal={manufacturerModal}
                        setFilters={setFilters}
                        handleEdit={handleEdit}
                        handleToggleActive={handleToggleActive}
                    />
                )}
                <SettingsImplantManufacturerModal
                    modal={manufacturerModal}
                    handleSubmit={handleSubmit}
                />
            </>
        </SettingsPriceListLayout>
    )
}
