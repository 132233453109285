import React from 'react'
import { useGetSMS } from 'api'
import classNames from 'classnames'

import { Loader } from 'components/ui'
import { formatDateNoYear, formatTime } from 'helpers'

import type { SMSListFilters } from 'api/types'

export type OperationSMSListProps = {
    operationId: number
}

export default function OperationSMSList(props: OperationSMSListProps) {
    const filters: SMSListFilters = {
        operation_id: props.operationId,
    }

    const queryResult = useGetSMS(filters)

    return (
        <>
            <div className="flex mb-4">
                <p className="flex-grow pt-4 text-lg font-medium leading-6">
                    SMS
                </p>
            </div>
            <div>
                {queryResult.isLoading && <Loader />}
                {queryResult.isSuccess &&
                    queryResult.data.data.length === 0 && (
                        <p className="mt-1 mb-4 text-sm text-gray-500">
                            Nie wysłano jeszcze żadnego SMS-a do pacjenta.
                        </p>
                    )}
                {queryResult.isSuccess &&
                    queryResult.data.data.length > 0 &&
                    queryResult.data.data.map((sms) => (
                        <div key={sms.id}>
                            <div
                                className={classNames(
                                    'mt-2 border rounded-md px-4 py-4 border-gray-100 bg-gray-50'
                                )}
                            >
                                <div className="flex space-x-3">
                                    <div className="min-w-0 flex-1">
                                        <p className="text-sm font-medium text-gray-900">
                                            Szpital Orthos
                                        </p>
                                        <p className="text-sm text-gray-500 whitespace-pre-line">
                                            {sms.content}
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 flex">
                                        {!!sms.created_at && (
                                            <div className="text-xs leading-none font-normal">
                                                <span className="text-gray-400">
                                                    {formatDateNoYear(
                                                        sms.created_at
                                                    )}
                                                </span>{' '}
                                                <span className="text-gray-600">
                                                    {formatTime(sms.created_at)}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    )
}
