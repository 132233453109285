import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { Dialog } from '@headlessui/react'
import { ShieldCheckIcon } from '@heroicons/react/solid'

import { Modal } from 'components'
import { Button, Label } from 'components/ui'
import { DatePickerField } from 'components/forms'
import { getCxFromStyles } from 'helpers'

import type { FormSubmitFn, IModal, ScheduleConfirmModalForm } from 'types'

import styles from 'components/Modal.module.scss'

const SchedulePlannerConfirmModal: React.FC<{
    modal: IModal
    lastApprovedDay?: string
    from?: Date
    to?: Date
    handleSubmit: FormSubmitFn<ScheduleConfirmModalForm>
    handleCancel: () => void
}> = ({ modal, lastApprovedDay, from, to, handleSubmit, handleCancel }) => {
    const cx = getCxFromStyles(styles)

    const initialValues: ScheduleConfirmModalForm = {
        from: from,
        to: to,
    }

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-4">
                                <ShieldCheckIcon className="m-auto w-12 text-green-500" />
                            </div>

                            <Dialog.Title className={cx('title')}>
                                <span>Zatwierdź plan operacyjny</span>
                            </Dialog.Title>

                            <div className="mt-4 text-center">
                                <p className="text-sm text-gray-500 leading-5 font-normal">
                                    Wybierz zakres dat publikowanego planu
                                    operacyjnego
                                </p>
                            </div>

                            <div className="mt-4">
                                <div className="grid grid-cols-2 gap-12">
                                    <div className="grid-col-1">
                                        <Label>Od:</Label>
                                        <DatePickerField
                                            tabIndex={-1}
                                            name="from"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="grid-col-1">
                                        <Label>Do:</Label>
                                        <DatePickerField
                                            tabIndex={-1}
                                            name="to"
                                            disabledBefore={moment(
                                                lastApprovedDay
                                            )
                                                .add(1, 'days')
                                                .toDate()}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={handleCancel}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zatwierdź
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default SchedulePlannerConfirmModal
