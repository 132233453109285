import React from 'react'

const VerticalLines: React.FC<{
    columnUnassigned: boolean
    cols: number
}> = ({ columnUnassigned, cols }) => {
    return (
        <div
            id="schedule-vertical-lines"
            className="hidden col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x-2 divide-gray-200 sm:grid"
            style={{
                gridTemplateColumns: `repeat(${
                    cols + (columnUnassigned ? 1 : 0)
                }, minmax(0, 1fr))`,
            }}
        >
            {columnUnassigned && (
                <div className="col-start-1 row-span-full bg-gray-50 print:hidden" />
            )}
            {Array.from(Array(cols).keys()).map((index) => (
                <div
                    key={index}
                    className={`col-start-${
                        index + (columnUnassigned ? 2 : 1)
                    } row-span-full`}
                />
            ))}
        </div>
    )
}

export default VerticalLines
