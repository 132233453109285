import React, { useState } from 'react'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import { getEquipmentList } from 'api'
import { Equipment } from 'api/types'
import { formatCurrency, formatPrice } from 'helpers/currencies'
import { InputPrice, Label } from 'components/ui'
import { SearchableDropdownField } from 'components/forms'
import PriceField from './PriceField'

interface EquipmentDropdownItem {
    id: Equipment['id'] | null
    name: Equipment['name']
    price: Equipment['price']
}

const EquipmentField: React.FC<{
    name: string
    label?: string
    editPrice?: boolean
    disabled?: boolean
}> = ({ label, name, editPrice = false, disabled }) => {
    const [inputPriceValue, setInputPriceValue] = useState('')

    return (
        <FieldArray
            name={name}
            render={({
                push,
                remove,
                form: { values, errors, setFieldValue },
            }) => {
                return (
                    <div>
                        <div className="flex flex-col md:flex-row items-start md:items-center">
                            <div className="flex-grow w-full md:w-auto">
                                <Label>
                                    Dodaj bądź wybierz sprzęt medyczny
                                </Label>
                                <SearchableDropdownField<EquipmentDropdownItem>
                                    name={`_${name}_item`}
                                    disabled={disabled}
                                    queryFn={getEquipmentList}
                                    queryFieldName="name"
                                    renderOption={({ name, price }) => (
                                        <div>
                                            <p className="text-sm leading-5 font-medium">
                                                {name}
                                            </p>
                                            <p className="text-sm leading-5 font-normal text-gray-500">
                                                Cena: {formatCurrency(price)}
                                            </p>
                                        </div>
                                    )}
                                    renderOptions={(options) => (
                                        <div>
                                            <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                                Podpowiedzi
                                            </div>
                                            {options}
                                        </div>
                                    )}
                                    handleChange={(value) => {
                                        setInputPriceValue(
                                            value.price as string
                                        )
                                    }}
                                    handleRemove={() => {
                                        setInputPriceValue('')
                                    }}
                                />
                            </div>
                            {editPrice && (
                                <div className="flex-shrink-0 w-full mt-4 md:mt-0 md:w-2/12 md:ml-2">
                                    <Label>Kwota</Label>
                                    <InputPrice
                                        value={inputPriceValue}
                                        handleChange={(value) =>
                                            setInputPriceValue(value)
                                        }
                                        disabled={disabled}
                                    />
                                </div>
                            )}
                            <div className="flex-shrink-0 md:ml-2">
                                <Label>&nbsp;</Label>
                                <button
                                    type="button"
                                    className={classNames(
                                        'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                        {
                                            'opacity-30 cursor-default': !(
                                                values[
                                                    `_${name}_item_phrase`
                                                ] && inputPriceValue.length > 0
                                            ),
                                        }
                                    )}
                                    onClick={() => {
                                        if (
                                            !(
                                                values[
                                                    `_${name}_item_phrase`
                                                ] && inputPriceValue.length > 0
                                            )
                                        ) {
                                            return
                                        }

                                        push({
                                            id:
                                                values[`_${name}_item`]?.id ||
                                                null,
                                            name: values[
                                                `_${name}_item_phrase`
                                            ],
                                            price: formatPrice(inputPriceValue)
                                                .replace(',', '.')
                                                .replace(' ', '')
                                                .replace(' ', ''), // NBSP char
                                        })

                                        setInputPriceValue('')
                                        setFieldValue(
                                            `_${name}_item_phrase`,
                                            ''
                                        )
                                        setFieldValue(
                                            `_${name}_item`,
                                            undefined
                                        )
                                    }}
                                    disabled={
                                        disabled ||
                                        !values[`_${name}_item_phrase`]
                                    }
                                >
                                    Dodaj
                                    <PlusIcon
                                        className="ml-2 h-3 w-3"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                        {!!errors[name] && (
                            <p className="mt-1 text-sm text-red-600">
                                {errors[name]}
                            </p>
                        )}
                        {values[name].length > 0 && (
                            <div className="mt-6">
                                <EquipmentList
                                    data={values[name]}
                                    editPrice={editPrice}
                                    showPrice={!editPrice}
                                    renderPriceField={({ index, item }) => (
                                        <div style={{ width: '75px' }}>
                                            <PriceField
                                                name={`${name}.${index}.price`}
                                                variant="xs"
                                                disabled={disabled}
                                                value={item.price}
                                            />
                                        </div>
                                    )}
                                    onRemove={!disabled ? remove : undefined}
                                />
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}

export const EquipmentList: React.FC<{
    data: EquipmentDropdownItem[]
    editPrice?: boolean
    showPrice?: boolean
    renderPriceField?: ({
        item,
        index,
    }: {
        item: EquipmentDropdownItem
        index: number
    }) => JSX.Element
    onRemove?: (index: number) => void
}> = ({ data, editPrice, showPrice, renderPriceField, onRemove }) => {
    return (
        <>
            {data.map((item, index) => (
                <div
                    key={index}
                    className="mb-0.5 px-2 py-1 h-11 flex items-center justify-between bg-gray-100 text-sm text-gray-900 leading-5 font-normal"
                >
                    <span className="flex-grow truncate">{item.name}</span>
                    {showPrice && (!!item.price || item.price === 0) && (
                        <div className="ml-2 flex-shrink-0 flex">
                            {formatCurrency(item.price)}
                        </div>
                    )}
                    {typeof renderPriceField === 'function' && editPrice && (
                        <div className="ml-2 flex-shrink-0 flex">
                            {renderPriceField({ item, index })}
                        </div>
                    )}
                    {typeof onRemove === 'function' && (
                        <div
                            className="ml-2 flex-shrink-0 flex cursor-pointer"
                            onClick={() => onRemove(index)}
                        >
                            <span className="text-gray-500 hover:text-gray-700">
                                <XIcon className="h-5 w-5" />
                            </span>
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default EquipmentField
