import { useQuery, UseQueryOptions } from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type { OperatingRoom, ResponseData, ResponseError } from 'api/types'

export const useGetOperatingRooms = <T = OperatingRoom, E = ResponseError>(
    filters?: {},
    options?: UseQueryOptions<ResponseData<T[]>, E>
) =>
    useQuery<ResponseData<T[]>, E>(
        'operating-rooms',
        () =>
            axios.get(
                '/operating-rooms' +
                    (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )
