import React from 'react'
import { Form, Formik } from 'formik'

import { PAYER_TYPE_NFZ, OPERATION_REJECT_REASONS } from 'constants/index'
import { getProcedureTypes, useGetProcedureCategories } from 'api'
import { Label } from 'components/ui'
import {
    AutoSubmitRow,
    CheckboxDropdownLazyField,
    DropdownField,
    EmployeePickerField,
    ProcedureField,
} from 'components/forms'

import type { StatisticsSurgeryRejectedListFilters } from 'types/StatisticsSurgeryRejectedList'

const StatisticsSurgeryRejectedFilters: React.FC<{
    filters: StatisticsSurgeryRejectedListFilters
    setFilters: React.Dispatch<
        React.SetStateAction<StatisticsSurgeryRejectedListFilters>
    >
}> = ({ filters, setFilters }) => {
    const procedureCategoriesResult = useGetProcedureCategories()

    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => {
                    setFilters(values)
                }}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <div className="grid grid-cols-1 xl:grid-cols-6 gap-4">
                            <div className="col-span-1">
                                <Label>Wyszukaj operatora</Label>
                                <EmployeePickerField
                                    name="operator"
                                    placeholder="Wyszukaj operatora"
                                    functions={['doctor function']}
                                    messageEmptyResult="Nie znaleziono operatora."
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Operacja</Label>
                                <ProcedureField
                                    name="procedure"
                                    placeholder="Wszystkie"
                                />
                            </div>

                            <div className="col-span-1">
                                <div className="shadow-sm">
                                    <Label>Rodzaj zabiegu</Label>
                                    <CheckboxDropdownLazyField
                                        bulkOption={true}
                                        bulkOptionName="Wszystkie"
                                        placeholder="Wszystkie"
                                        name="procedure_types"
                                        queryFn={getProcedureTypes}
                                    />
                                </div>
                            </div>

                            <div className="col-span-1">
                                <Label>Kategoria zabiegu</Label>
                                <DropdownField
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    name="procedure_category"
                                    items={
                                        procedureCategoriesResult.isSuccess
                                            ? procedureCategoriesResult.data
                                                  .data
                                            : []
                                    }
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Płatnik</Label>
                                <DropdownField
                                    name="payer_type"
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    items={[
                                        { id: PAYER_TYPE_NFZ, name: 'NFZ' },
                                        {
                                            id: 'private',
                                            name: 'Prywatny',
                                        },
                                    ]}
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Przyczyna zrzucenia</Label>
                                <DropdownField
                                    name="reject_type"
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    items={OPERATION_REJECT_REASONS.map(
                                        (item) => ({
                                            id: item.type,
                                            name: item.label,
                                        })
                                    )}
                                />
                            </div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default StatisticsSurgeryRejectedFilters
