import React from 'react'
import { useFormikContext } from 'formik'

import FileUploader from 'components/FileUploader'
import { ErrorMessage } from 'components/forms'

import type { DocumentForm } from 'types'
import type { UploaderState } from 'components/FileUploader'

const FileUploaderField = () => {
    const { values, errors, setFieldValue } = useFormikContext<DocumentForm>()

    return (
        <>
            <FileUploader
                fileId={values.document}
                hasError={'document' in errors}
                handleUploadSuccess={(value: UploaderState) =>
                    setFieldValue('document', value.addedFile.id)
                }
                handleRemovePhoto={() => {
                    setFieldValue('document', 0)
                }}
            />
            {'document' in errors && <ErrorMessage name="document" />}
        </>
    )
}

export default FileUploaderField
