import React, { ReactNode } from 'react'
import classNames from 'classnames'

import {
    OPERATION_STATUS_CANCELED,
    OPERATION_STATUS_REJECTED,
} from 'constants/index'

import type { OperationListItem } from 'types'

type StatusBackgroundProps = {
    children?: ReactNode | undefined
    item: {
        accepted: OperationListItem['accepted']
        status: OperationListItem['status']
        progress?: OperationListItem['progress']
    }
}

export const classNamesWidth = 'w-16 xl:w-18'

export function twBgColor(item: StatusBackgroundProps['item']) {
    if (item.status === OPERATION_STATUS_REJECTED) {
        return 'bg-gray-100'
    }

    if (item.status === OPERATION_STATUS_CANCELED) {
        return 'bg-gray-500'
    }

    if (item.accepted) {
        return 'bg-green-100'
    }

    if ((item.progress?.percent || 0) === 0) {
        return 'bg-red-100'
    }

    if ((item.progress?.percent || 0) > 0) {
        return 'bg-yellow-100'
    }

    return ''
}

export default function StatusBackground({
    item,
    children,
}: StatusBackgroundProps) {
    return (
        <div
            className={classNames(
                'inline-flex h-12 xl:h-16 items-center justify-center',
                classNamesWidth,
                twBgColor(item)
            )}
        >
            {children}
        </div>
    )
}
