export const downloadFileFactory = async ({
    fetchFunction,
    fileName,
}: {
    fetchFunction: () => Promise<{ data: Blob }>
    fileName: string
}) => {
    let blob: Blob

    try {
        const { data } = await fetchFunction()
        blob = data as Blob
    } catch (e) {
        throw e
    }

    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style.display = 'none'
    a.href = URL.createObjectURL(blob)
    a.download = fileName
    a.click()
    a.remove()
}
