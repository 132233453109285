import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import type { DateRange } from 'react-day-picker'

import {
    API_FORMAT_DATE,
    SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
} from 'constants/index'
import { useStatisticsPolicy } from 'hooks'
import { useStatisticsDashboard } from 'api'
import { StatisticsRangeDatePicker } from 'components/forms'
import { currentRangeDate } from 'components/forms/DatePickers/StatisticsRangeDatePicker'
import { Loader } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import StatisticsDashboard from 'components/StatisticsDashboard'
import { AccessDenied } from 'components'

type StatisticsDashboardFilters = {
    from: Date | undefined
    to: Date | undefined
}

const initialFilters: StatisticsDashboardFilters = {
    from: currentRangeDate().from,
    to: currentRangeDate().to,
}

const transformStatisticsDashboardFiltersToApi = (
    filters: StatisticsDashboardFilters
) => {
    return Object.assign(
        {},
        filters.from && filters.to
            ? {
                  estimated_date_from: moment(filters.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.to).format(API_FORMAT_DATE),
              }
            : {
                  estimated_date_from: '',
                  estimated_date_to: '',
              }
    )
}

const StatisticsDashboardContainer = () => {
    const statisticsPolicy = useStatisticsPolicy()
    const [dateRange, setDateRange] = useState<DateRange>(currentRangeDate())
    const [filters, setFilters] = useState<StatisticsDashboardFilters>({
        ...initialFilters,
        from: currentRangeDate().from,
        to: currentRangeDate().to,
    })
    const dashboardResultQuery = useStatisticsDashboard(
        transformStatisticsDashboardFiltersToApi(filters),
        { enabled: statisticsPolicy.canSee }
    )

    useEffect(() => {
        if (dateRange.from && dateRange.to) {
            setFilters((prevState) => ({
                ...prevState,
                ...dateRange,
            }))
        }
    }, [dateRange])

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
            JSON.stringify({
                from: filters.from,
                to: filters.to,
            })
        )
    }, [filters])

    const handleChangeDate = (dateRange: DateRange) => {
        setDateRange(dateRange)
    }

    if (!statisticsPolicy.canSee) {
        return <AccessDenied message="Nie masz dostępu do statystyk" />
    }

    return (
        <ListLayout
            title="Podsumowanie"
            actions={
                <div className="flex items-center space-x-4">
                    <span className="text-sm text-gray-700 leading-5 font-medium">
                        Zakres dat:
                    </span>
                    <StatisticsRangeDatePicker
                        dateRange={dateRange}
                        onChange={handleChangeDate}
                    />
                </div>
            }
        >
            <>
                {dashboardResultQuery.isLoading && <Loader />}
                {dashboardResultQuery.isError && (
                    <div>{dashboardResultQuery.error.message}</div>
                )}
                {dashboardResultQuery.isSuccess && (
                    <StatisticsDashboard
                        data={dashboardResultQuery.data.data}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default StatisticsDashboardContainer
