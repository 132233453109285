import React, { useEffect } from 'react'

import { useNotification } from 'hooks'

const InternetConnectionMonitor: React.FC<{
    children: JSX.Element
}> = ({ children }) => {
    const showNotification = useNotification()
    useEffect(() => {
        window.addEventListener('offline', () => {
            showNotification(
                {
                    content: 'Brak połączenia z Internetem',
                    type: 'danger',
                },
                0
            )
        })

        window.addEventListener('online', () => {
            showNotification({
                content: 'Połączono z Internetem',
                type: 'success',
            })
        })

        // eslint-disable-next-line
    }, [])

    return children
}

export default InternetConnectionMonitor
