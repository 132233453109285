import React from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'
import classNames from 'classnames'
import { PencilIcon, PlusIcon } from '@heroicons/react/solid'

import { OPERATION_TASK_STATUS_OPTIONS } from 'constants/index'
import { useCommentPolicy, useOperationTaskPolicy } from 'hooks'
import { Label, Button, AssignButton } from 'components/ui'
import { TermDatePicker } from 'components/ui/DatePickers'
import { parseDateToFormik } from 'components/forms/DatePickers/utils'
import {
    TextAreaField,
    DatePickerField,
    AllowDocumentsField,
    FileUploaderField,
    OperationTaskStatusMenu,
    ErrorMessage,
    TextAreaAutosizeField,
} from 'components/forms'
import CommentForm from 'containers/OperationTaskCommentForm'
import UserAvatar from 'components/UserAvatar'
import { ButtonSubmit, FileItem } from 'components/DoctorPatientDocuments'
import OperationTaskStatus from 'components/OperationTaskStatus'

import type { FormSubmitFn, IModal, OperationTaskForm } from 'types'
import type {
    OperationResponse,
    OperationTask as IOperationTask,
} from 'api/types'
import type { TaskStatusTransitionState } from 'components/TaskExpiredModal'

const OperationTask: React.FC<{
    formRef: any
    task?: IOperationTask
    operation: OperationResponse
    isEditing?: boolean
    setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
    modalAssignEmployee: IModal
    handleSubmit: FormSubmitFn<OperationTaskForm>
    handleSubmitFile?: FormSubmitFn<any>
    handleUpdateDate?: (
        field: 'reminder' | 'patient_term',
        date: string
    ) => void
    onUpdateStatus?: (state: TaskStatusTransitionState) => void
    onDelete?: (id: number) => void
    handleClose: () => void
}> = ({
    formRef,
    task,
    operation,
    isEditing,
    setIsEditing,
    modalAssignEmployee,
    handleSubmit,
    handleSubmitFile,
    handleUpdateDate,
    onUpdateStatus,
    onDelete,
    handleClose,
}) => {
    const operationTaskPolicy = useOperationTaskPolicy()
    const commentPolicy = useCommentPolicy()

    return (
        <Formik
            innerRef={(f) => (formRef.current = f)}
            initialValues={
                {
                    operation: operation.id,
                    name: task?.name || '',
                    description: task?.description || '',
                    reminder: task?.reminder
                        ? task.reminder
                        : !isEditing
                        ? operation.estimated_date + ' 00:00'
                        : '',
                    patient_term: task?.patient_term || '',
                    allow_files: task?.allow_files || false,
                    status: task?.status || 'new',
                    user: task?.user || undefined,
                } as OperationTaskForm
            }
            enableReinitialize={!isEditing}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, setFieldValue, values, errors }) => (
                <div className="min-h-0 flex-1 flex flex-col">
                    <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                        <div className="relative flex-1 flex flex-col px-4 sm:px-6 space-between">
                            <div className="divide-gray-200 divide-y">
                                {(!task || isEditing) && (
                                    <div>
                                        <div>
                                            <Label>Zadanie</Label>
                                            <TextAreaAutosizeField
                                                name="name"
                                                maxRows={3}
                                                invalid={'name' in errors}
                                            />
                                            <ErrorMessage name="name" />
                                        </div>
                                        <div className="mt-4">
                                            <Label>Opis zadania</Label>
                                            <TextAreaField
                                                name="description"
                                                rows={3}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!!task && !isEditing && (
                                    <div>
                                        <div className="flex justify-between">
                                            <p className="text-2xl leading-8 text-gray-800">
                                                {task.name}
                                            </p>
                                            {operationTaskPolicy.canUpdate && (
                                                <button
                                                    type="button"
                                                    className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    onClick={() =>
                                                        !!setIsEditing &&
                                                        setIsEditing(true)
                                                    }
                                                >
                                                    <PencilIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="sr-only">
                                                        Edytuj
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                        {!!task.description && (
                                            <div className="mt-4 flex items-center justify-between">
                                                <p className="flex-1 text-sm leading-5 text-gray-500 font-light whitespace-pre-line">
                                                    {task.description}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="mt-4">
                                    <div className="mt-4 flex items-center divide-gray-200 divide-x">
                                        <div className="flex flex-1">
                                            <div className="flex-1 leading-none">
                                                <div className="mb-1 text-sm leading-5 text-gray-400">
                                                    Termin pacjenta:
                                                </div>
                                                <TermDatePicker
                                                    disabled={
                                                        !operationTaskPolicy.canUpdate
                                                    }
                                                    status={
                                                        errors['patient_term']
                                                            ? 'error'
                                                            : undefined
                                                    }
                                                    placeholder="Uzupełnij"
                                                    value={
                                                        values.patient_term
                                                            ? moment(
                                                                  values.patient_term
                                                              ).toDate()
                                                            : undefined
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'patient_term',
                                                            date
                                                                ? parseDateToFormik(
                                                                      date
                                                                  ) + ' 00:00'
                                                                : ''
                                                        )
                                                        handleUpdateDate &&
                                                            handleUpdateDate(
                                                                'patient_term',
                                                                date
                                                                    ? parseDateToFormik(
                                                                          date
                                                                      ) +
                                                                          ' 00:00'
                                                                    : ''
                                                            )
                                                    }}
                                                />
                                                <ErrorMessage name="patient_term" />
                                            </div>
                                            <div className="flex-1 leading-none pl-4">
                                                <div className="mb-1 text-sm leading-5 text-gray-400">
                                                    Termin zadania:
                                                </div>
                                                <TermDatePicker
                                                    status={
                                                        task
                                                            ? task.status ===
                                                              'done'
                                                                ? task.status
                                                                : task.deadline
                                                            : errors['reminder']
                                                            ? 'error'
                                                            : undefined
                                                    }
                                                    disabled={
                                                        !operationTaskPolicy.canUpdate
                                                    }
                                                    value={
                                                        values.reminder
                                                            ? moment(
                                                                  values.reminder
                                                              ).toDate()
                                                            : undefined
                                                    }
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'reminder',
                                                            date
                                                                ? parseDateToFormik(
                                                                      date
                                                                  ) + ' 00:00'
                                                                : ''
                                                        )
                                                        handleUpdateDate &&
                                                            handleUpdateDate(
                                                                'reminder',
                                                                date
                                                                    ? parseDateToFormik(
                                                                          date
                                                                      ) +
                                                                          ' 00:00'
                                                                    : ''
                                                            )
                                                    }}
                                                />
                                                <ErrorMessage name="reminder" />
                                            </div>
                                        </div>
                                        {!!task && (
                                            <div className="mt-0 ml-4 pl-4">
                                                {!operationTaskPolicy.canUpdate && (
                                                    <OperationTaskStatus
                                                        variant="md"
                                                        status={values.status}
                                                    />
                                                )}
                                                {operationTaskPolicy.canUpdate && (
                                                    <OperationTaskStatusMenu
                                                        value={values.status}
                                                        options={
                                                            OPERATION_TASK_STATUS_OPTIONS
                                                        }
                                                        onChange={(
                                                            nextStatus
                                                        ) => {
                                                            !!onUpdateStatus &&
                                                                onUpdateStatus({
                                                                    item: task,
                                                                    nextStatus,
                                                                })
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {operationTaskPolicy.canUpdate &&
                                            !task &&
                                            !values.user && (
                                                <div className="ml-4 pl-4 flex items-center">
                                                    <AssignButton
                                                        onClick={() =>
                                                            modalAssignEmployee.openModal()
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {operationTaskPolicy.canUpdate &&
                                            !!task &&
                                            !task.user && (
                                                <div className="ml-4 pl-4 flex items-center">
                                                    <AssignButton
                                                        onClick={() =>
                                                            modalAssignEmployee.openModal()
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {!task && !!values.user && (
                                            <div className="ml-4 pl-4 flex items-center">
                                                <UserAvatar
                                                    data={values.user}
                                                    size={8}
                                                    onClick={
                                                        operationTaskPolicy.canUpdate
                                                            ? () => {
                                                                  modalAssignEmployee.setState(
                                                                      values.user
                                                                  )
                                                                  modalAssignEmployee.openModal()
                                                              }
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        )}
                                        {!!task && !!task.user && (
                                            <div className="ml-4 pl-4 flex items-center">
                                                <UserAvatar
                                                    data={task.user}
                                                    size={8}
                                                    onClick={
                                                        operationTaskPolicy.canUpdate
                                                            ? () => {
                                                                  modalAssignEmployee.setState(
                                                                      task.user
                                                                  )
                                                                  modalAssignEmployee.openModal()
                                                              }
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!task && (
                                    <div className="mt-4">
                                        <AllowDocumentsField name="allow_files" />
                                    </div>
                                )}
                                {!!task?.allow_files && (
                                    <div className="mt-4 pt-4">
                                        {(operationTaskPolicy.canUpdate ||
                                            task.documents.length > 0) && (
                                            <p className="text-base font-medium leading-6">
                                                Dokumenty
                                            </p>
                                        )}
                                        {operationTaskPolicy.canUpdate && (
                                            <Formik
                                                initialValues={{
                                                    description: '',
                                                    document: 0,
                                                    expire_date: '',
                                                    patient_id:
                                                        operation.patient.id,
                                                }}
                                                onSubmit={handleSubmitFile!}
                                            >
                                                <Form>
                                                    <div className="mt-4">
                                                        <FileUploaderField />
                                                    </div>
                                                    <div className="mt-4">
                                                        <DatePickerField
                                                            name="expire_date"
                                                            labelText="Wybierz datę ważności dokumentu"
                                                        />
                                                    </div>
                                                    <div className="mt-4 flex justify-end">
                                                        <ButtonSubmit
                                                            size="sm"
                                                            variant="tertiary"
                                                        />
                                                    </div>
                                                </Form>
                                            </Formik>
                                        )}
                                        {task.documents.length > 0 && (
                                            <ul className="mt-4">
                                                {task.documents.map(
                                                    (document, index) => (
                                                        <li
                                                            key={document.id}
                                                            className={classNames(
                                                                {
                                                                    'mt-2':
                                                                        index >
                                                                        0,
                                                                }
                                                            )}
                                                        >
                                                            <FileItem
                                                                item={document}
                                                            />
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                )}
                            </div>
                            {commentPolicy.canIndex && !!task && (
                                <div className="mt-4 flex-1 flex flex-col">
                                    <CommentForm taskId={task.id} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex-shrink-0 px-6 py-4 flex justify-between shadow-inner">
                        <div>
                            {!!onDelete && !!task && (
                                <Button
                                    type="button"
                                    variant="danger"
                                    onClick={() => onDelete(Number(task.id))}
                                    disabled={!operationTaskPolicy.canDelete}
                                >
                                    Usuń zadanie
                                </Button>
                            )}
                        </div>
                        <div className="flex">
                            {!isEditing && (
                                <Button
                                    type="button"
                                    onClick={() => handleClose()}
                                >
                                    Zamknij
                                </Button>
                            )}
                            {isEditing && (
                                <Button
                                    type="button"
                                    onClick={() =>
                                        !!setIsEditing && setIsEditing(false)
                                    }
                                >
                                    Anuluj
                                </Button>
                            )}
                            {(!task || isEditing) && (
                                <Form>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="ml-3"
                                        iconRight={<PlusIcon />}
                                        loading={isSubmitting}
                                    >
                                        Zapisz
                                    </Button>
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default OperationTask
