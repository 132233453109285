import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { getCxFromStyles } from 'helpers'
import { usePolicy } from 'hooks'

import styles from './NotificationListItem.module.scss'

import type { Notification } from 'api/types'

const NotificationListItem: React.FC<{
    data: Notification
    handleClose: () => void
    danger?: boolean
}> = ({ data, handleClose, danger }) => {
    const navigate = useNavigate()
    const { user } = usePolicy()
    const cx = getCxFromStyles(styles)

    return (
        <div
            className={cx('root', { regular: !danger, danger })}
            onClick={() => {
                if (!data.operation_id || isNaN(Number(data.operation_id))) {
                    return null
                }

                if (user.isCaregiver) {
                    navigate('/caregiver', {
                        state: {
                            id: data.operation_id,
                        },
                    })
                }

                if (!user.isCaregiver) {
                    navigate('/surgeries/' + data.operation_id + '/card')
                }

                handleClose()
            }}
        >
            <div className="flex flex-col">
                <p className={cx('title')}>{data.title}</p>
                <p className={cx('description')}>{data.description}</p>
            </div>
            <div>
                <span className="text-gray-500">
                    {moment(data.date).format('MM.DD')}
                </span>
            </div>
        </div>
    )
}

export default NotificationListItem
