import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PAYER_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PAYERS,
    show: PERMISSIONS.SHOW_PAYERS,
    create: PERMISSIONS.CREATE_PAYERS,
    update: PERMISSIONS.UPDATE_PAYERS,
    delete: PERMISSIONS.DELETE_PAYERS,
}

export const usePayerPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PAYER_PERMISSIONS),
    }
}
