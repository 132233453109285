import React from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'

import { Pagination } from 'components/ui'
import { PriceField, AutoSubmitRow } from 'components/forms'
import EmptyList from 'components/EmptyList'

import type { IModal, FormSubmitFn } from 'types'
import type { Equipment, ResponseList } from 'api/types'
import type { ListFilterAction } from 'helpers'

export interface FormValues
    extends Pick<Equipment, 'id' | 'price' | 'liveview_visible'> {}

const SettingsMedicalEquipmentList: React.FC<{
    data: ResponseList<Equipment[]>
    modal: IModal
    handleUpdate: FormSubmitFn<FormValues>
    handleEdit: (id: number) => void
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, modal, handleUpdate, handleEdit, setFilters }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && (
                <Table
                    data={data}
                    handleUpdate={handleUpdate}
                    handleEdit={handleEdit}
                />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego sprzętu medycznego."
                    onClick={modal.openModal}
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<Equipment[]>
    handleEdit: (id: number) => void
    handleUpdate: FormSubmitFn<FormValues>
}> = ({ data, handleEdit, handleUpdate }) => (
    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
            <tr>
                <th
                    scope="col"
                    className="w-1 px-6 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                >
                    Id
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Wyś.&nbsp;TV
                </th>
                <th
                    scope="col"
                    className="w-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Nazwa
                </th>
                <th
                    scope="col"
                    className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Cena
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                    Opcje
                </th>
            </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
            {data.data.map((item, index) => (
                <Formik
                    key={index}
                    initialValues={{
                        id: item.id,
                        price: item.price,
                        liveview_visible: item.liveview_visible,
                    }}
                    enableReinitialize
                    onSubmit={handleUpdate}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <>
                            <tr
                                key={item.id}
                                className={classNames({
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                <td className="px-6 py-6">
                                    <span>{item.id}</span>
                                </td>
                                <td className="px-6 py-1 text-center">
                                    <input
                                        type="checkbox"
                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                        checked={values.liveview_visible}
                                        disabled={isSubmitting}
                                        onChange={() =>
                                            setFieldValue(
                                                'liveview_visible',
                                                !values.liveview_visible
                                            )
                                        }
                                    />
                                </td>
                                <td className="px-6 py-6">
                                    <span className="text-gray-900 font-medium">
                                        {item.name}
                                    </span>
                                </td>
                                <td className="px-1 py-1 text-center">
                                    <PriceField
                                        name="price"
                                        disabled={isSubmitting}
                                        variant="sm"
                                        showTextError={false}
                                    />
                                </td>
                                <td className="px-6 py-1 text-right">
                                    <span className="font-normal">
                                        <span
                                            className="cursor-pointer inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => handleEdit(item.id)}
                                        >
                                            Edytuj
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <AutoSubmitRow />
                        </>
                    )}
                </Formik>
            ))}
        </tbody>
    </table>
)

export default SettingsMedicalEquipmentList
