import { useScheduleMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const ScheduleMenuItemGate: MenuItemGate = ({ children }) => {
    const scheduleMenuItemPolicyGroup = useScheduleMenuItemPolicyGroup()

    if (scheduleMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
