import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { PlusIcon } from '@heroicons/react/solid'

import { useSurgeryFormPayer } from 'hooks'
import { ActionBar } from 'components/forms'
import { Button } from 'components/ui'
import DoctorSurgeryFormMain from 'components/DoctorSurgeryFormMain'
import DoctorSurgeryFormFinances from 'components/DoctorSurgeryFormFinances'

import type { SurgeryForm } from 'types'

const DoctorSurgeryForm: React.FC<{}> = () => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    const { updatePayerPrice } = useSurgeryFormPayer()

    useEffect(() => {
        const payer = updatePayerPrice(values.procedure, values.payer)

        setFieldValue('payer', payer)
        setFieldValue('base_cost', payer ? payer.price : 0)
        setFieldValue(
            'contains_implants',
            values.procedure?.implants_required
                ? true
                : values.contains_implants
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.procedure])

    return (
        <div className="space-y-6 px-0 lg:col-span-9">
            <div className="rounded-md bg-white">
                <div className="sm:mb-16 pt-8 lg:pt-12 px-4 sm:px-6 divide-gray-200 divide-y">
                    <DoctorSurgeryFormMain />
                    <DoctorSurgeryFormFinances />
                    <div className="py-12">
                        <ActionBar
                            rightSide={({ isSubmitting }) => (
                                <>
                                    <Button as="link" to="/surgeries">
                                        Anuluj
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="ml-3"
                                        loading={isSubmitting}
                                        iconRight={<PlusIcon />}
                                    >
                                        Zapisz
                                    </Button>
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorSurgeryForm
