import React, { useEffect } from 'react'
import ReactInternationalPhone from 'react-phone-number-input/input'
import { Label, InputText } from 'components/ui'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

type PhoneInputProps = {
    label: string
    value: string
    onChange: (value: string) => void
    error?: string
    isDisabled?: boolean
}

export const PhoneInput = ({
    value,
    onChange,
    isDisabled,
    error,
    label,
}: PhoneInputProps) => {
    useEffect(() => {
        if (value && !value.startsWith('+')) {
            onChange(`+48${value}`)
        }
    }, [value, onChange])

    return (
        <>
            {label && <Label>{label}</Label>}

            <div className="relative">
                <ReactInternationalPhone
                    value={value}
                    onChange={(value) => onChange(value || '')}
                    placeholder=""
                    disabled={isDisabled}
                    defaultCountry="PL"
                    inputComponent={CustomInput}
                    hasError={!!error}
                />

                {error && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </div>

            {error ? (
                <div className="mt-1 text-sm text-red-600">{error}</div>
            ) : (
                <div className="mt-1 text-sm text-gray-400">
                    Dopuszczalne cyfry i znak "+"
                </div>
            )}
        </>
    )
}

const CustomInput: any = React.forwardRef((props: any, ref: any) => {
    return (
        <InputText {...props} inputRef={ref} className="border focus:ring-1" />
    )
})
