import React from 'react'

import { useForgotPassword } from 'api'
import ForgotPassword from 'components/ForgotPassword'

import { ForgotPasswordForm, FormSubmitFn } from 'types'

export default function ForgotPasswordContainer() {
    const { mutate: requestResetPassword, isSuccess } = useForgotPassword()

    const handleSubmit: FormSubmitFn<ForgotPasswordForm> = (
        values,
        formikHelpers
    ) => {
        requestResetPassword(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return <ForgotPassword handleSubmit={handleSubmit} isSuccess={isSuccess} />
}
