import React from 'react'
import ReactDOM from 'react-dom'
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import Routes from './routes/Routes'
import reportWebVitals from './reportWebVitals'

import { AlertContextProvider } from 'contexts/alerts'
import MediaContextProvider from 'contexts/media/MediaContextProvider'
import PusherContextProvider from 'contexts/PusherContextProvider'
import { NotificationProvider } from 'contexts/NotificationProvider'
import InternetConnectionMonitor from './containers/InternetConnectionMonitor'

import 'moment/locale/pl'

import './index.css'
import './print.css'

const { REACT_APP_SENTRY_DSN_URL, REACT_APP_SENTRY_ENVIRONMENT } = process.env

if (REACT_APP_SENTRY_DSN_URL && REACT_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN_URL,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],
        environment: REACT_APP_SENTRY_ENVIRONMENT,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 120_000,
        },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <PusherContextProvider>
                    <NotificationProvider>
                        <MediaContextProvider>
                            <AlertContextProvider>
                                <InternetConnectionMonitor>
                                    <Routes />
                                </InternetConnectionMonitor>
                            </AlertContextProvider>
                        </MediaContextProvider>
                    </NotificationProvider>
                </PusherContextProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
