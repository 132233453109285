import { PERMISSIONS } from 'constants/index'
import {
    usePolicy,
    useSurgeryPolicy,
    usePatientPolicy,
    useOperationTaskPolicy,
    useOperationClosurePolicy,
    useIcdCodePolicy,
    useSurgeryListPolicyGroup,
} from 'hooks'

export const useDoctorSurgeryPolicyGroup = () => {
    const { user, hasPermission } = usePolicy()
    const surgeryPolicy = useSurgeryPolicy()
    const surgeryListPolicyGroup = useSurgeryListPolicyGroup()
    const operationTaskPolicy = useOperationTaskPolicy()
    const patientPolicy = usePatientPolicy()
    const operationClosurePolicy = useOperationClosurePolicy()
    const icdCodePolicy = useIcdCodePolicy()

    return {
        user,
        canIndex: surgeryListPolicyGroup.canIndex,
        canShow:
            surgeryPolicy.canShow &&
            operationTaskPolicy.canIndex &&
            operationTaskPolicy.canShow,
        canIndexPerformed: surgeryListPolicyGroup.canIndex,
        canShowClosure:
            operationClosurePolicy.canShow &&
            surgeryPolicy.canShow &&
            patientPolicy.canShow,
        canCreateUpdateClosure:
            operationClosurePolicy.canShow &&
            operationClosurePolicy.canCreate &&
            operationClosurePolicy.canUpdate &&
            surgeryPolicy.canShow &&
            patientPolicy.canShow &&
            icdCodePolicy.canIndex,
        canUpdateStatus: hasPermission(
            PERMISSIONS.CHANGE_PERFORMED_OPERATION_STATUS
        ),
    }
}
