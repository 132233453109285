import React from 'react'
import { useQueryClient } from 'react-query'

import { useGetPatient, useUpdatePatient } from 'api'
import { useNotification } from 'hooks'
import PatientNote from 'components/PatientNote'
import { MESSAGES } from 'constants/index'

import { FormSubmitFn, PatientNoteForm } from 'types'
import { UpdateNotePatient } from 'api/types'

const PatientNoteContainer: React.FC<{
    patient: ReturnType<typeof useGetPatient>
}> = ({ patient }) => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: update } = useUpdatePatient<UpdateNotePatient>()

    const handleSubmit: FormSubmitFn<PatientNoteForm> = (
        values,
        formikHelpers
    ) => {
        update(values, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['patients', values.id])
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: MESSAGES.PATIENT_NOTE_UPDATED,
                    type: 'success',
                })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return <PatientNote patient={patient} handleSubmit={handleSubmit} />
}

export default PatientNoteContainer
