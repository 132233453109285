import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/solid'

interface DropdownItem {
    id: number | string
    name: string
}

interface DropdownOptionsProps {
    show: boolean
    items: DropdownItem[]
    fieldValue?: DropdownItem
    bulkOption?: boolean
    bulkOptionName?: string
    withEmptyItem?: boolean
}

const DropdownOptions = ({
    show,
    items,
    fieldValue,
    bulkOption,
    bulkOptionName,
    withEmptyItem,
}: DropdownOptionsProps) => {
    return (
        <Transition
            show={show}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <Listbox.Options
                static
                className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200divide-y divide-gray-200"
            >
                {bulkOption && (
                    <Listbox.Option
                        className={({ active }) =>
                            classNames(
                                active
                                    ? 'text-white bg-blue-600'
                                    : 'text-gray-900',
                                'cursor-pointer select-none relative py-2 pl-3 pr-9'
                            )
                        }
                        value={undefined}
                    >
                        {({ selected, active }) => (
                            <>
                                <span
                                    className={classNames(
                                        selected
                                            ? 'font-medium'
                                            : 'font-normal',
                                        'block truncate'
                                    )}
                                >
                                    {bulkOptionName}
                                </span>

                                {selected ? (
                                    <span
                                        className={classNames(
                                            active
                                                ? 'text-white'
                                                : 'text-blue-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                    >
                                        <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                ) : null}
                            </>
                        )}
                    </Listbox.Option>
                )}
                {withEmptyItem && (
                    <Listbox.Option
                        className="cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-300 hover:bg-gray-200 hover:text-white"
                        value={undefined}
                    >
                        Brak
                    </Listbox.Option>
                )}
                {items.map((item) => {
                    const selected = fieldValue?.id === item.id

                    return (
                        <Listbox.Option
                            key={item.id}
                            className={({ active }) =>
                                classNames(
                                    active
                                        ? 'text-white bg-blue-600'
                                        : 'text-gray-900',
                                    'cursor-pointer select-none relative py-2 pl-3 pr-9'
                                )
                            }
                            value={item}
                        >
                            {({ active }) => (
                                <>
                                    <span
                                        className={classNames(
                                            'block truncate',
                                            {
                                                'font-medium': selected,
                                                'font-normal': !selected,
                                            }
                                        )}
                                    >
                                        {item.name}
                                    </span>
                                    {selected && (
                                        <span
                                            className={classNames(
                                                active
                                                    ? 'text-white'
                                                    : 'text-blue-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                        >
                                            <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    )}
                                </>
                            )}
                        </Listbox.Option>
                    )
                })}
            </Listbox.Options>
        </Transition>
    )
}

export default DropdownOptions
