import React from 'react'
import { UseMutationResult } from 'react-query'

import { ResponseError } from 'api/types'
import { Button } from 'components/ui'

const DeleteButton: React.FC<{
    id: number
    query: UseMutationResult<{ id: number }, ResponseError, number>
    handleSuccess: () => void
}> = ({ id, query, handleSuccess }) => {
    const handleDelete = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()

            query.mutate(id, {
                onSuccess: () => {
                    handleSuccess()
                },
                onError: (error) => {},
            })
        },
        [id, query, handleSuccess]
    )

    return (
        <Button
            type="button"
            variant="danger"
            onClick={handleDelete}
            loading={query.isLoading}
        >
            Usuń
        </Button>
    )
}

export default DeleteButton
