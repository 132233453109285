import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import {
    addDays,
    endOfISOWeek,
    isAfter,
    isBefore,
    isSameDay,
    startOfDay,
    startOfISOWeek,
} from 'date-fns'
import {
    ArrowNarrowRightIcon,
    ArrowNarrowLeftIcon,
} from '@heroicons/react/solid'

import {
    useGetApprovedDays,
    useGetWorkPlanSchedule,
    useSendScheduleSMS,
} from 'api'
import { DATEPICKER_DEFAULT_FROM_YEAR } from 'constants/index'
import {
    formatDate,
    getCxFromStyles,
    parseDateToAPI,
    parseStringApiDateToDate,
} from 'helpers'
import { Modal } from 'components'
import alertContext from 'contexts/alerts'
import { Button, DatePicker, Loader } from 'components/ui'
import { ReactComponent as BulbIcon } from 'assets/bulb.svg'

import type { IModal } from 'types'

import Avatar from './Avatar'
import styles from './SchedulePreviewSMSToAllModal.module.scss'

const ROLES = [
    { label: 'Operatorzy', key: 'doctor' },
    { label: 'Asysty', key: 'assistant' },
    { label: 'Anestezjolodzy', key: 'anesthesiologist' },
    { label: 'Pielęgniarki anestezjologiczne', key: 'anesthesiologist_nurse' },
    { label: 'Pielęgniarki operacyjne', key: 'operation_nurse' },
] as const

const SchedulePreviewSMSToAllModal: React.FC<{
    modal: IModal
    initialDate: Date
    handleCancel: () => void
}> = ({ modal, handleCancel, initialDate }) => {
    const cx = getCxFromStyles(styles)
    const { data: approvedDays, isFetching: isFetchingApprovedDays } =
        useGetApprovedDays({ paginated: false })
    const { add: alert } = useContext(alertContext)
    const [date, setDate] = useState<Date>(startOfDay(initialDate))
    const queryClient = useQueryClient()

    const { mutateAsync: sendSMS, isLoading: isSendingSMS } =
        useSendScheduleSMS({
            onSuccess: async () => {
                alert({
                    content: 'SMSy z planu operacyjnego zostały wysłane',
                    type: 'success',
                })
                await queryClient.invalidateQueries('approved-days')
                modal.closeModal()
            },
            onError: () => {
                alert({
                    content: 'Podczas wysyłania SMSów wystąpił błąd',
                    type: 'danger',
                })
                modal.closeModal()
            },
        })

    const lastApprovedDay = useMemo(
        () =>
            approvedDays
                ? parseStringApiDateToDate(approvedDays.data[0].date)
                : addDays(
                      startOfDay(new Date(DATEPICKER_DEFAULT_FROM_YEAR, 0)),
                      -1
                  ),
        [approvedDays]
    )
    const firstDayWithoutSentSMS = useMemo(
        () =>
            addDays(
                approvedDays
                    ? parseStringApiDateToDate(approvedDays.data[0].sms_sent_to)
                    : startOfDay(new Date(DATEPICKER_DEFAULT_FROM_YEAR, 0)),
                1
            ),
        [approvedDays]
    )
    const rangeDate = {
        from: startOfDay(new Date(DATEPICKER_DEFAULT_FROM_YEAR, 0)),
        to: lastApprovedDay,
    }

    const nextDay = addDays(date, 1)
    const previousDay = addDays(date, -1)
    const goToNextDay = () => setDate(nextDay)
    const goToPreviousDay = () => setDate(previousDay)

    const { data: workPlanForWeek, isFetching: isFetchingWorkPlanSchedule } =
        useGetWorkPlanSchedule(
            {
                from: parseDateToAPI(startOfISOWeek(date)),
                to: parseDateToAPI(
                    isAfter(endOfISOWeek(date), lastApprovedDay)
                        ? lastApprovedDay
                        : endOfISOWeek(date)
                ),
            },
            {
                enabled: modal.isOpen,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            }
        )

    const currentDay = useMemo(
        () =>
            workPlanForWeek?.data.find((workPlanForDay) =>
                isSameDay(parseStringApiDateToDate(workPlanForDay.date), date)
            ),
        [workPlanForWeek, date]
    )

    const workPlanListByRole = useMemo(() => {
        if (!currentDay) {
            return []
        }

        return ROLES.map((role) => ({
            ...role,
            work_plan: currentDay.work_plan.filter(
                (item) => item.role === role.key
            ),
        }))
    }, [currentDay])

    const isFetching = useMemo(
        () => isFetchingWorkPlanSchedule || isFetchingApprovedDays,
        [isFetchingWorkPlanSchedule, isFetchingApprovedDays]
    )

    const canSendSMS =
        isAfter(date, firstDayWithoutSentSMS) ||
        isSameDay(date, firstDayWithoutSentSMS)

    useEffect(() => {
        setDate(startOfDay(initialDate))
    }, [initialDate])

    const onSubmit = () =>
        sendSMS({
            from: parseDateToAPI(firstDayWithoutSentSMS) || '',
            to: parseDateToAPI(lastApprovedDay) || '',
        })

    return (
        <Modal
            modal={
                isSendingSMS
                    ? {
                          ...modal,
                          closeModal: () => {},
                      }
                    : modal
            }
        >
            <div className={cx('inner')}>
                <div className="flex flex-col h-full sm:pb-3 pb-6">
                    <div className="flex justify-center sm:justify-between gap-2">
                        <div className="hidden sm:block">
                            <Button
                                variant="tertiary"
                                iconLeft={<ArrowNarrowLeftIcon />}
                                onClick={goToPreviousDay}
                                disabled={
                                    isBefore(previousDay, rangeDate.from) ||
                                    isSendingSMS
                                }
                            >
                                Poprzedni
                            </Button>
                        </div>
                        <div className="relative">
                            <DatePicker
                                onChange={(date) => date && setDate(date)}
                                inputReadOnly
                                value={date}
                                stylesPopper={{
                                    width: 'max-content',
                                    left: '50%',
                                    transform: 'translate(-50%, 40px)',
                                }}
                                fromYear={DATEPICKER_DEFAULT_FROM_YEAR}
                                disabledAfter={rangeDate.to}
                                disabled={isSendingSMS}
                            />
                        </div>
                        <div className="hidden sm:block">
                            <Button
                                variant="tertiary"
                                iconRight={<ArrowNarrowRightIcon />}
                                onClick={goToNextDay}
                                disabled={
                                    isAfter(nextDay, rangeDate.to) ||
                                    isSendingSMS
                                }
                            >
                                Następny
                            </Button>
                        </div>
                    </div>
                    {isFetching ? (
                        <Loader />
                    ) : currentDay?.work_plan.length === 0 ? (
                        <div className="flex flex-col h-full justify-center items-center py-4">
                            <BulbIcon className="w-9 h-9 text-yellow-200" />
                            <h2 className="font-medium text-lg pt-6">
                                BRAK OPERACJI
                            </h2>
                            <p className="pt-4 text-gray-500 text-center">
                                W tym dniu nie odbywają się żadne operacje.
                            </p>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-8 mt-9 mb-9 overflow-y-auto -mx-4 px-4">
                            {workPlanListByRole.map((item) => (
                                <div className="flex flex-col">
                                    <h2
                                        className={cx(
                                            'role-header',
                                            'flex items-center text-gray-800 font-semibold font-medium text-center mb-1'
                                        )}
                                    >
                                        <span className="flex-shrink-0">
                                            {item.label}
                                        </span>
                                    </h2>
                                    {item.work_plan.length === 0 ? (
                                        <div className="text-gray-500 my-auto">
                                            Brak przypisanych osób
                                        </div>
                                    ) : (
                                        <ul>
                                            {item.work_plan.map((item) => (
                                                <li
                                                    className="flex items-center w-full h-10 gap-3 rounded border-b border-gray-100 border-solid text-sm"
                                                    key={item.id}
                                                >
                                                    <Avatar
                                                        url={item.avatar_url}
                                                        size={6}
                                                        abbreviation={
                                                            item.first_name.charAt(
                                                                0
                                                            ) +
                                                            item.last_name.charAt(
                                                                0
                                                            )
                                                        }
                                                    />
                                                    <span>
                                                        {item.first_name}{' '}
                                                        {item.last_name}
                                                    </span>
                                                    <span className="ml-auto">
                                                        <span
                                                            className={
                                                                'text-gray-500'
                                                            }
                                                        >
                                                            Rozpoczęcie:
                                                        </span>{' '}
                                                        {item.start_at}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex justify-center mt-auto mb-3 sm:mb-6 gap-3">
                        <Button
                            variant="default"
                            className="w-56"
                            onClick={handleCancel}
                            disabled={isSendingSMS}
                        >
                            Anuluj
                        </Button>
                        <Button
                            variant={canSendSMS ? 'primary' : 'quaternary'}
                            className="w-56"
                            onClick={onSubmit}
                            disabled={isFetching || !canSendSMS}
                            loading={isSendingSMS}
                        >
                            Wyślij
                        </Button>
                    </div>
                    {canSendSMS && (
                        <div className="flex justify-center mb-3 sm:mb-6 text-center">
                            Plan operacyjny:{' '}
                            {formatDate(firstDayWithoutSentSMS.toDateString())}-
                            {formatDate(lastApprovedDay.toDateString())}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default SchedulePreviewSMSToAllModal
