import React from 'react'

import {
    useCaregiverPanelPolicy,
    useDoctorSurgeryPolicyGroup,
    useScheduleApprovedPolicyGroup,
    usePatientPolicy,
    useModal,
    usePolicy,
} from 'hooks'
import { Button3dots } from 'components/ui'
import Menu3dots from 'components/Menu3dots'
import ScheduleOperationSlideOver from 'components/ScheduleOperationSlideOver'

import type { OperationListItem } from 'types'

const DoctorSurgeryPerformedListItemMenu3dots: React.FC<{
    item: OperationListItem
}> = ({ item }) => {
    const {
        user: { isRehabilitator },
    } = usePolicy()
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()
    const caregiverPanelPolicy = useCaregiverPanelPolicy()
    const patientPolicy = usePatientPolicy()
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()

    const modalOperationPreview = useModal(false)

    const handleOpenOperationPreview = () => {
        modalOperationPreview.setState(item.id)
        modalOperationPreview.openModal()
    }

    return (
        <>
            <div className="flex align-center justify-end space-x-4">
                <Menu3dots>
                    {({ open }) => (
                        <>
                            <Menu3dots.Button as="span">
                                <Button3dots
                                    variant="tertiary"
                                    size="sm"
                                    hovered={open}
                                />
                            </Menu3dots.Button>
                            <Menu3dots.Items>
                                {!isRehabilitator && (
                                    <>
                                        <div className="border-b border-gray-100 py-1">
                                            <Menu3dots.Item
                                                as="link"
                                                to={`/caregiver/surgeries/${item.id}/edit`}
                                                state={{
                                                    id: item.id,
                                                }}
                                                disabled={
                                                    !caregiverPanelPolicy
                                                        .surgery.canUpdate
                                                }
                                            >
                                                Edytuj operację
                                            </Menu3dots.Item>
                                            <Menu3dots.Item
                                                as="link"
                                                to={`/caregiver/surgeries/${item.id}/preview`}
                                                state={{
                                                    id: item.id,
                                                }}
                                                disabled={
                                                    !caregiverPanelPolicy
                                                        .surgery.canUpdate
                                                }
                                            >
                                                Podgląd formularza
                                            </Menu3dots.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu3dots.Item
                                                as="link"
                                                to={`/surgeries/${item.id}/card`}
                                                disabled={
                                                    !doctorSurgeryPolicyGroup.canShow
                                                }
                                            >
                                                Karta operacji
                                            </Menu3dots.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu3dots.Item
                                                as="link"
                                                to={`/patients/${item.patient.id}/edit`}
                                                disabled={
                                                    !patientPolicy.canUpdate
                                                }
                                            >
                                                Karta pacjenta
                                            </Menu3dots.Item>
                                        </div>
                                    </>
                                )}
                                <div className="py-1">
                                    <Menu3dots.Item
                                        onClick={handleOpenOperationPreview}
                                        disabled={
                                            !scheduleApprovedPolicyGroup
                                                .schedule.canShow
                                        }
                                    >
                                        Podgląd operacji
                                    </Menu3dots.Item>
                                </div>
                                <div className="border-t border-gray-100 py-1">
                                    <Menu3dots.Item
                                        as="link"
                                        disabled={
                                            !doctorSurgeryPolicyGroup.canCreateUpdateClosure &&
                                            !(
                                                item.status === 'descripted' &&
                                                doctorSurgeryPolicyGroup.canShowClosure
                                            )
                                        }
                                        to={
                                            item.closure_id
                                                ? `/surgeries/${item.id}/closures/${item.closure_id}/edit`
                                                : `/surgeries/${item.id}/closures/create`
                                        }
                                    >
                                        Opis operacji
                                    </Menu3dots.Item>
                                </div>
                            </Menu3dots.Items>
                        </>
                    )}
                </Menu3dots>
            </div>
            <ScheduleOperationSlideOver
                hideSMSButton
                modal={modalOperationPreview}
                onSuccessChange={() => {}}
                editable={false}
            />
        </>
    )
}

export default DoctorSurgeryPerformedListItemMenu3dots
