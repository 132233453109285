import React, { useState, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'

import mediaContext from './mediaContext'

import type { MediaContext } from './mediaContext'

export const BREAKPOINT_MD = 768
export const BREAKPOINT_XL = 1280

const MediaContextProvider: React.FC = ({ children }) => {
    const { Provider } = mediaContext

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    })

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            })
        }, 100)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    const contextValue: MediaContext = useMemo(
        () => ({
            height: dimensions.height,
            width: dimensions.width,
            isMobile: dimensions.width < BREAKPOINT_MD,
            isTablet:
                dimensions.width >= BREAKPOINT_MD &&
                dimensions.width < BREAKPOINT_XL,
            isDesktop: dimensions.width >= BREAKPOINT_XL,
        }),
        [dimensions]
    )

    return <Provider value={contextValue}>{children}</Provider>
}

export default MediaContextProvider
