import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { Dialog } from '@headlessui/react'

import { useEventListener } from 'hooks'
import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import { Button, Label } from 'components/ui'
import { DatePickerField } from 'components/forms'

import styles from './Modal.module.scss'

import type { FormSubmitFn, IModal } from 'types'
import type { OperationRenewForm } from 'types/OperationRenewForm'

export type OperationRenewModalState = {
    id: number
    estimated_date: string
}

const OperationRenewModal: React.FC<{
    modal: IModal<OperationRenewModalState | undefined>
    onSubmit: FormSubmitFn<OperationRenewForm>
    onCancel: () => void
    onClickOutside?: () => void
}> = ({ modal, onSubmit, onCancel, onClickOutside }) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const modalInnerRef = useRef<HTMLDivElement>(null)

    const cx = getCxFromStyles(styles)

    const handleMouseDown = (e: Event) => {
        if (
            modalInnerRef.current &&
            e.target instanceof Node &&
            !modalInnerRef.current.contains(e.target) &&
            modal.isOpen
        ) {
            e.stopImmediatePropagation()
        }
    }

    useEventListener('mousedown', handleMouseDown, [modal.isOpen])

    return (
        <Modal
            ref={modalRef}
            modal={modal}
            onClickOutside={onClickOutside ? onClickOutside : () => {}}
        >
            <div ref={modalInnerRef} className={cx('inner', 'p-6')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Przwróć operację
                </Dialog.Title>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={
                        {
                            estimated_date: modal.getState()?.estimated_date,
                        } as OperationRenewForm
                    }
                    validateOnChange={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mt-5">
                                <Label>Dzień operacji</Label>
                                <DatePickerField
                                    tabIndex={-1}
                                    name="estimated_date"
                                    placeholder="DD.MM.RRRR"
                                    disabledBefore={moment().toDate()}
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Przywróć operację
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default OperationRenewModal
