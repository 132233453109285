import { useCaregiverPanelMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const PreparedOperationMenuItemGate: MenuItemGate = ({ children }) => {
    const caregiverPanelMenuItemPolicyGroup =
        useCaregiverPanelMenuItemPolicyGroup()

    if (caregiverPanelMenuItemPolicyGroup.canIndex) {
        return children
    }

    return null
}
