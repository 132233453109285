import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import moment from 'moment'

import { API_FORMAT_DATETIME } from 'constants/index'
import client from 'api/axios'

import type { DoctorSurgeryClosureForm } from 'types'
import type { OperationClosure, ResponseData, ResponseError } from 'api/types'

export const useGetOperationClosure = <
    Request extends { id?: number },
    Response extends ResponseData<OperationClosure>
>(
    data: Request,
    options?: UseQueryOptions<Response, ResponseError>
): UseQueryResult<Response, ResponseError> =>
    useQuery<Response, ResponseError>(
        ['operation-closures', data.id],
        () => client.get('/operation-closures/' + data.id),
        options
    )

export const getOperationClosurePdf = (
    id: number
): Promise<ResponseData<{ path: string }>> =>
    client.get('/operation-closures/' + id + '/pdf')

export const usePostOperationClosure = <
    R1 extends Partial<OperationClosure>,
    R2 = ResponseData<{ id: number }>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/operation-closures', data))

export const usePatchOperationClosure = <
    R1 extends { id: number; data: Partial<OperationClosure> },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id, data }) =>
        client.patch('/operation-closures/' + id, data)
    )

export const transformClosureFormToClosurePostRequest = (
    values: DoctorSurgeryClosureForm
): Partial<OperationClosure> => {
    const nextValues = Object.keys(values)
        .filter((key) => !key.startsWith('_'))
        .filter(
            (key) =>
                ![
                    'icd9_phrase',
                    'icd10_phrase',
                    'doctor',
                    'assistant',
                    'anesthesiologist',
                    'anesthesiologist_nurse',
                    'operation_nurse',
                ].includes(key)
        )
        .reduce((acc, key) => {
            return {
                ...acc,
                [key]: values[key as keyof DoctorSurgeryClosureForm],
            }
        }, {} as OperationClosure)

    nextValues['started_at'] = values['_started_at']
    nextValues['ended_at'] = values['_started_at']

    if (values['_started_on']) {
        nextValues['started_at'] += ' ' + values['_started_on'].id + ':00'
    }

    if (values['_ended_on']) {
        nextValues['ended_at'] += ' ' + values['_ended_on'].id + ':00'

        if (
            moment(
                nextValues['started_at'],
                API_FORMAT_DATETIME,
                true
            ).isValid() &&
            moment(
                nextValues['ended_at'],
                API_FORMAT_DATETIME,
                true
            ).isValid() &&
            moment(nextValues['ended_at']).isBefore(
                moment(nextValues['started_at'])
            )
        ) {
            nextValues['ended_at'] = moment(nextValues['ended_at'])
                .add('1', 'day')
                .format(API_FORMAT_DATETIME)
        }
    }

    return {
        ...nextValues,
        in_hospital_visit: values.in_hospital_visit || '',
        in_hospital_visit_time: values.in_hospital_visit_time?.id || '',
        surgical_team: [
            values.doctor && { id: values.doctor.id, role: 'doctor' },
            ...values.assistant
                .filter((item) => !!item)
                .map((item) => ({
                    id: item!.id,
                    role: 'assistant',
                })),
            ...values.anesthesiologist
                .filter((item) => !!item)
                .map((item) => ({
                    id: item!.id,
                    role: 'anesthesiologist',
                })),
            ...values.anesthesiologist_nurse
                .filter((item) => !!item)
                .map((item) => ({
                    id: item!.id,
                    role: 'anesthesiologist_nurse',
                })),
            ...values.operation_nurse
                .filter((item) => !!item)
                .map((item) => ({
                    id: item!.id,
                    role: 'operation_nurse',
                })),
        ].filter((item) => !!item),
    }
}
