import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { CheckIcon } from '@heroicons/react/solid'

import { formatCurrency } from 'helpers'
import { NAMES_DAYS_OF_WEEK } from 'constants/index'

import type { SurgeryForm } from 'types'

const FinanceSummary: React.FC<{
    total: number
    values: SurgeryForm
    disabled: boolean
}> = ({ total, values, disabled }) => {
    return (
        <div
            className={classNames({
                'text-gray-500': !disabled,
                'text-gray-300': disabled,
            })}
        >
            <div className="p-5 sm:p-10">
                <p className="sm:mt-6">
                    <span
                        className={classNames(
                            'text-3xl sm:text-6xl leading-none font-bold',
                            {
                                'text-gray-900': !disabled,
                            }
                        )}
                    >
                        {formatCurrency(total).replace('zł', '')}
                    </span>
                    <span className="pl-1 text-2xl leading-8 font-normal">
                        zł
                    </span>
                </p>
                <p className="mt-5 sm:mt-6 text-lg leading-7 font-light">
                    Całkowity koszt operacji (w przypadku ręcznego
                    konfigurowania jest to koszt sugerowany)
                </p>
            </div>
            <div className="p-5 pb-3 sm:p-10 bg-gray-50">
                <div
                    className={classNames('text-lg leading-6 font-medium', {
                        'text-gray-900': !disabled,
                    })}
                >
                    <h6>Wybrane elementy</h6>
                    {!!values.procedure && (
                        <div className="mt-6">
                            <div className="flex items-center text-base leading-6 font-normal">
                                <span>
                                    <CheckIcon
                                        style={{
                                            color: disabled
                                                ? '#AAA'
                                                : '#10B981',
                                        }}
                                        className="mr-2 w-4"
                                    />
                                </span>
                                {values.procedure?.name}
                            </div>
                        </div>
                    )}
                    <div className="mt-4 sm:mt-6">
                        <div className="flex items-center text-base leading-6 font-normal">
                            <span>
                                <CheckIcon
                                    style={{
                                        color: disabled ? '#AAA' : '#10B981',
                                    }}
                                    className="mr-2 w-4"
                                />
                            </span>
                            Dni hospitalizacji:{' '}
                            {values.recovery_days || values.recovery_days === 0
                                ? values.recovery_days
                                : '-'}
                        </div>
                    </div>
                    {values.implants.length > 0 && (
                        <div className="mt-4 sm:mt-6">
                            <div className="flex items-center text-base leading-6 font-normal">
                                <span>
                                    <CheckIcon
                                        style={{
                                            color: disabled
                                                ? '#AAA'
                                                : '#10B981',
                                        }}
                                        className="mr-2 w-4"
                                    />
                                </span>
                                Implanty:{' '}
                                {values.implants
                                    .map((item) => item.name)
                                    .join(', ')}
                            </div>
                        </div>
                    )}
                    <div className="mt-4 sm:mt-6">
                        <div className="flex items-center text-base leading-6 font-normal">
                            <span>
                                <CheckIcon
                                    style={{
                                        color: disabled ? '#AAA' : '#10B981',
                                    }}
                                    className="mr-2 w-4"
                                />
                            </span>
                            Sugerowany dzień:{' '}
                            {
                                NAMES_DAYS_OF_WEEK[
                                    moment(values.estimated_date).day()
                                ]
                            }
                            , {moment(values.estimated_date).format('DD.MM')},
                            godz: {values.preferred_beginning_hour?.name || '-'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceSummary
