import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { Dialog } from '@headlessui/react'
import { useQueryClient } from 'react-query'

import { useGetApprovedDays, useSendScheduleSMS } from 'api'
import { API_FORMAT_DATE } from 'constants/index'
import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import alertContext from 'contexts/alerts'
import { Button, Loader } from 'components/ui'

import { ReactComponent as IconSendSms } from '../assets/icon-send-sms.svg'
import styles from 'components/Modal.module.scss'

import type { IModal } from 'types'

const ScheduleSendSMSToAllModal: React.FC<{
    modal: IModal
    handleCancel: () => void
    handlePreview: () => void
}> = ({ modal, handleCancel, handlePreview }) => {
    const cx = getCxFromStyles(styles)
    const { add: alert } = useContext(alertContext)

    const approvedDays = useGetApprovedDays({ paginated: false })
    const queryClient = useQueryClient()

    const { mutateAsync: sendSMS } = useSendScheduleSMS({
        onSuccess: async () => {
            alert({
                content: 'SMSy z planu operacyjnego zostały wysłane',
                type: 'success',
            })
            await queryClient.invalidateQueries('approved-days')
            modal.closeModal()
        },
        onError: () => {
            alert({
                content: 'Podczas wysyłania SMSów wystąpił błąd',
                type: 'danger',
            })
            modal.closeModal()
        },
    })

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                {approvedDays.isFetching && <Loader />}
                {approvedDays.isSuccess && approvedDays.isFetched && (
                    <Formik
                        initialValues={{
                            from: moment(approvedDays.data?.data[0].sms_sent_to)
                                .add(1, 'days')
                                .format(API_FORMAT_DATE),
                            to: approvedDays.data?.data[0].date,
                        }}
                        onSubmit={({ from, to }) => sendSMS({ from, to })}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="mb-4">
                                    <IconSendSms className="m-auto w-12" />
                                </div>

                                <Dialog.Title className={cx('title')}>
                                    <span>Wyślij plan operacyjny</span>
                                </Dialog.Title>

                                <div className="mt-4 text-center">
                                    <p className="text-sm text-gray-500 leading-5 font-normal">
                                        Wysyłka planu operacyjnego od{' '}
                                        {formatApprovedDate(
                                            moment(
                                                approvedDays.data?.data[0]
                                                    .sms_sent_to
                                            )
                                                .add(1, 'days')
                                                .format(API_FORMAT_DATE)
                                        )}
                                        {' do '}
                                        {formatApprovedDate(
                                            approvedDays.data?.data[0].date
                                        )}
                                    </p>
                                </div>

                                <div
                                    className={cx(
                                        'action-container',
                                        'grid-cols-5'
                                    )}
                                >
                                    <Button
                                        variant="default"
                                        type="button"
                                        onClick={handleCancel}
                                        disabled={isSubmitting}
                                        className="col-span-1"
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        variant="tertiary"
                                        type="button"
                                        onClick={handlePreview}
                                        disabled={isSubmitting}
                                        className="col-span-2"
                                    >
                                        Podgląd
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        loading={isSubmitting}
                                        className="col-span-2"
                                    >
                                        Wyślij
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Modal>
    )
}

const formatApprovedDate = (date: string) =>
    date ? moment(date).format('DD.MM') : '-'

export default ScheduleSendSMSToAllModal
