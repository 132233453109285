import { PERMISSIONS } from 'constants/index'
import { usePolicy, useUserPolicy, useSurgeryPolicy } from 'hooks'

export const useDoctorSurgeryDraftPolicyGroup = () => {
    const { user, hasPermission } = usePolicy()
    const userPolicy = useUserPolicy()
    const surgeryPolicy = useSurgeryPolicy()

    const canIndex =
        surgeryPolicy.canIndex && surgeryPolicy.canUpdate && userPolicy.canIndex

    const canCreate =
        surgeryPolicy.canIndex &&
        hasPermission(PERMISSIONS.CREATE_OPERATIONS) &&
        hasPermission(PERMISSIONS.INDEX_BLOCKED_DAYS) &&
        hasPermission(PERMISSIONS.INDEX_IMPLANTS) &&
        hasPermission(PERMISSIONS.INDEX_EQUIPMENT) &&
        hasPermission(PERMISSIONS.INDEX_EXPENSES)

    return {
        user,
        canIndex,
        canCreate,
    }
}
