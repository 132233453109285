import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useStatisticsPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canSee: hasPermission(PERMISSIONS.SEE_STATISTICS),
        viewTimeView: hasPermission(PERMISSIONS.VIEW_TIME_VIEW),
    }
}
