import React, { useMemo } from 'react'
import { Form, Formik, FieldArray } from 'formik'
import classNames from 'classnames'
import { Dialog } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/solid'

import { getCxFromStyles, timeEvery15minutesPeriodGenerator } from 'helpers'
import { Modal } from 'components'
import { Button, Label } from 'components/ui'
import {
    EmployeePickerField,
    DropdownField,
    ErrorMessage,
} from 'components/forms'

import type {
    FormSubmitFn,
    IModal,
    SurgicalTeamMember,
    AssignAnesthesiologistForm,
} from 'types'
import type { OperatingRoom } from 'api/types'
import type { DropdownItem } from 'components/forms'

import styles from 'components/Modal.module.scss'

export type AssignAnesthesiologistModalState = {
    date: string
    operatingRoom: OperatingRoom
    data: {
        id: number | null
        operating_room_id: number
        user?: SurgicalTeamMember
        from?: DropdownItem
        to?: DropdownItem
    }[]
}

const ScheduleAssignAnesthesiologistModal: React.FC<{
    modal: IModal<AssignAnesthesiologistModalState | undefined>
    handleSubmit: FormSubmitFn<AssignAnesthesiologistForm>
}> = ({ modal, handleSubmit }) => {
    const cx = getCxFromStyles(styles)

    const hours: DropdownItem[] = useMemo(
        () => [
            {
                id: '06:45:00',
                name: '06:45',
            },
            ...timeEvery15minutesPeriodGenerator().map((minutes) => ({
                id: minutes + ':00',
                name: minutes,
            })),
        ],
        []
    )

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Przypisz Anestezjologa:{' '}
                    {modal.getState()?.operatingRoom.name}
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={
                        {
                            operating_room_id:
                                modal.getState()?.operatingRoom.id,
                            date: modal.getState()?.date,
                            data:
                                modal.getState()?.data &&
                                modal.getState()!.data.length > 0
                                    ? modal.getState()!.data
                                    : [
                                          {
                                              user: undefined,
                                              from: undefined,
                                              to: undefined,
                                          },
                                      ],
                        } as AssignAnesthesiologistForm
                    }
                >
                    {({ isSubmitting, values, errors, setFieldValue }) => (
                        <Form>
                            <FieldArray
                                name="data"
                                render={({ push, remove, replace }) => (
                                    <>
                                        <div className="divide-gray-200 divide-y space-y-6">
                                            {values.data.map((item, index) => (
                                                <div key={index}>
                                                    <div
                                                        className={classNames({
                                                            'mt-4': index === 0,
                                                            'mt-6': index > 0,
                                                        })}
                                                    >
                                                        <EmployeePickerField
                                                            tabIndex={-1}
                                                            name={`data.${index}.user`}
                                                            placeholder="Wybierz anestezjologa"
                                                            hasError={
                                                                `data.${index}.user_id` in
                                                                errors
                                                            }
                                                            functions={[
                                                                'anesthesiologist function',
                                                            ]}
                                                            handleRemove={
                                                                values.data
                                                                    .length > 1
                                                                    ? () =>
                                                                          remove(
                                                                              index
                                                                          )
                                                                    : !!values
                                                                          .data[0]
                                                                          .user
                                                                    ? () =>
                                                                          replace(
                                                                              index,
                                                                              {
                                                                                  user: undefined,
                                                                                  from: undefined,
                                                                                  to: undefined,
                                                                              }
                                                                          )
                                                                    : undefined
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name={`data.${index}.user_id`}
                                                        />
                                                    </div>
                                                    <div className="mt-2">
                                                        <div className="grid grid-cols-2 gap-4">
                                                            <div className="grid-col-1">
                                                                <Label>
                                                                    Rozpoczęcie
                                                                </Label>
                                                                <DropdownField
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    name={`data.${index}.from`}
                                                                    placeholder="--:--"
                                                                    hasError={
                                                                        `data.${index}.from` in
                                                                        errors
                                                                    }
                                                                    items={
                                                                        hours
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name={`data.${index}.from`}
                                                                />
                                                            </div>
                                                            <div className="grid-col-1">
                                                                <Label>
                                                                    Zakończenie
                                                                </Label>
                                                                <DropdownField
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    name={`data.${index}.to`}
                                                                    placeholder="--:--"
                                                                    hasError={
                                                                        `data.${index}.to` in
                                                                        errors
                                                                    }
                                                                    items={
                                                                        hours
                                                                    }
                                                                />
                                                                <ErrorMessage
                                                                    name={`data.${index}.to`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mt-6">
                                            <Button
                                                tabIndex={-1}
                                                variant="tertiary"
                                                type="button"
                                                size="sm"
                                                className="w-full"
                                                disabled={values.data.some(
                                                    (item) => !item.user
                                                )}
                                                onClick={() =>
                                                    push({
                                                        user: undefined,
                                                        from: undefined,
                                                        to: undefined,
                                                    })
                                                }
                                            >
                                                <div className="flex items-center">
                                                    <span>
                                                        Dodaj następnego
                                                    </span>
                                                    <PlusIcon className="ml-2 inline-flex w-4 h-4" />
                                                </div>
                                            </Button>
                                        </div>
                                    </>
                                )}
                            />
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={() => modal.closeModal()}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default ScheduleAssignAnesthesiologistModal
