import React, { useMemo, useReducer, useState } from 'react'
import { useQueryClient } from 'react-query'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { procedureFilterReducer } from 'helpers'
import { useProcedureCostPolicyGroup } from 'hooks'
import SettingsLayout from 'layouts/SettingsLayout'
import {
    countActiveFilters,
    transformProcedureFiltersToApi,
    useGetPayers,
    useGetProcedures,
    useUpdateProcedure,
} from 'api'
import { AccessDenied } from 'components'
import { FilterButton, Loader } from 'components/ui'
import SettingsProcedureCostList from 'components/SettingsProcedureCostList'
import Filters from './SettingsProcedureListFilters'

import type { FormSubmitFn } from 'types'
import type { UpdateProcedureCosts } from 'api/types'
import type { FormProcedure } from 'components/SettingsProcedureCostList'

const SettingsProcedureCostListContainer = () => {
    const procedureCostPolicy = useProcedureCostPolicyGroup()
    const [filters, setFilters] = useReducer(procedureFilterReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
        filters: {
            name: '',
            procedure_categories: [],
            procedure_types: [],
            procedure_payers: [],
        },
    })
    const queryClient = useQueryClient()
    const filtersToApi = useMemo(
        () => transformProcedureFiltersToApi(filters),
        [filters]
    )
    const procedures = useGetProcedures(filtersToApi, {
        enabled: procedureCostPolicy.canAll,
    })
    const payers = useGetPayers(
        { active: true },
        {
            enabled: procedureCostPolicy.canAll,
        }
    )
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () => countActiveFilters(filtersToApi),
        [filtersToApi]
    )
    const { mutate: updateProcedure } =
        useUpdateProcedure<UpdateProcedureCosts>()

    const handleUpdate: FormSubmitFn<FormProcedure> = (
        values,
        formikHelpers
    ) => {
        updateProcedure(
            {
                id: values.id,
                base_price: values.base_price,
                payer_costs: { update: values.payer_costs },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('procedures')
                    formikHelpers.setSubmitting(false)
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    const errors = Object.keys(error.errors).reduce(
                        (acc, key) => {
                            acc[
                                key.replace('payer_costs.update', 'payer_costs')
                            ] = error.errors[key]
                            return acc
                        },
                        {} as Record<string, any>
                    )

                    formikHelpers.setErrors(errors)
                },
            }
        )
    }

    return (
        <SettingsLayout
            actions={
                procedureCostPolicy.canAll && (
                    <FilterButton
                        count={filtersCount}
                        filtersExpanded={filtersExpanded}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                    />
                )
            }
        >
            <>
                {!procedureCostPolicy.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania kosztami operacji" />
                )}
                {filtersExpanded && (
                    <Filters
                        filters={filters.filters}
                        setFilters={(values) =>
                            setFilters({ type: 'change', payload: values })
                        }
                    />
                )}
                {(procedures.isLoading || payers.isLoading) && <Loader />}
                {(procedures.isError || payers.isError) && <div>Error</div>}
                {procedures.isSuccess && payers.isSuccess && (
                    <SettingsProcedureCostList
                        filters={filters}
                        filtersCount={filtersCount}
                        procedures={procedures.data}
                        payers={payers.data}
                        handleUpdate={handleUpdate}
                        setFilters={setFilters}
                    />
                )}
            </>
        </SettingsLayout>
    )
}

export default SettingsProcedureCostListContainer
