import React from 'react'
import classNames from 'classnames'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import type { ListFilters } from 'helpers'

export type SetSortingFn = (sort_by: string, sort_direction: string) => void

const Sort: React.FC<{
    name: string
    children: React.ReactNode
    filters: ListFilters
    setSorting: SetSortingFn
    active?: boolean
    up?: boolean
}> = ({ name, up, active, children, filters, setSorting }) => {
    return (
        <span
            className={classNames(
                'flex items-center whitespace-nowrap cursor-pointer',
                {
                    'font-bold': filters.sort_by === name,
                }
            )}
            onClick={() => {
                if (filters.sort_by !== name) {
                    return setSorting(name, up ? 'desc' : 'asc')
                }

                const newDirection =
                    filters.sort_direction === 'asc' ? 'desc' : 'asc'
                setSorting(name, newDirection)
            }}
        >
            <span>{children}</span>
            <span className="ml-1 w-4 h-4 print:hidden">
                {filters.sort_by === name ? (
                    filters.sort_direction === 'asc' ? (
                        <ChevronDownIcon />
                    ) : (
                        <ChevronUpIcon />
                    )
                ) : (
                    <ChevronDownIcon />
                )}
            </span>
        </span>
    )
}

export default Sort
