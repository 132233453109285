import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useOperationDurationsPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canSee: hasPermission(PERMISSIONS.SEE_OPERATION_DURATIONS),
    }
}
