import React, { useState, useMemo } from 'react'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import { PlusIcon, XIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import { getDropdownImplants } from 'api'
import { formatCurrency, formatPrice } from 'helpers/currencies'
import {
    ErrorMessage,
    PriceField,
    SearchableDropdownField,
} from 'components/forms'
import { Label } from 'components/ui'

import type { Procedure } from 'api/types'
import type { ImplantDropdownItem, PayerCost } from 'types/SurgeryForm'

const ImplantField: React.FC<{
    name: string
    procedure: Partial<Procedure<PayerCost>> | null
    disabled?: boolean
    initialData?: ImplantDropdownItem[]
}> = ({ name, procedure, disabled, initialData }) => {
    const [tab, setTab] = useState(0)

    const items = useMemo(
        () =>
            [
                {
                    name: 'Manualna konfiguracja',
                    visible: true,
                },
                { name: 'Zestawy', visible: !!procedure },
            ].filter((item) => item.visible),
        [procedure]
    )

    return (
        <FieldArray
            name={name}
            render={({
                push,
                remove,
                form: { values, errors, setFieldValue },
            }) => {
                const implantsWithChangeInfo: Array<
                    ImplantDropdownItem & { used?: boolean }
                > = cloneDeep(initialData || [])

                return (
                    <div
                        className={classNames({
                            'pointer-events-none': disabled,
                        })}
                    >
                        <div
                            className={classNames(
                                'mb-5 p-0.5 grid bg-gray-100 rounded-lg text-sm font-medium',
                                {
                                    'grid-cols-1': items.length === 1,
                                    'grid-cols-2 gap-2': items.length === 2,
                                }
                            )}
                        >
                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className={classNames(
                                        'col-span-1 p-2 text-center border border-transparent rounded-lg flex justify-center items-center',
                                        {
                                            'hover:text-gray-500':
                                                tab !== index,
                                            'border border-gray-100 bg-white rounded-lg shadow-md':
                                                tab === index,
                                            'cursor-pointer':
                                                items.length === 2,
                                            'pointer-events-none opacity-50':
                                                index === 1 &&
                                                !procedure?.contains_implants_set,
                                            'opacity-50': disabled,
                                        }
                                    )}
                                    onClick={() => setTab(index)}
                                >
                                    {item.name}
                                </div>
                            ))}
                        </div>

                        {tab === 0 && (
                            <>
                                <div className="flex flex-col md:flex-row md:items-center">
                                    <div className="flex-grow w-full md:w-auto">
                                        <Label>Wybierz implanty</Label>
                                        <SearchableDropdownField<ImplantDropdownItem>
                                            name="_implant_item"
                                            disabled={disabled}
                                            queryFn={getDropdownImplants}
                                            queryFilters={{
                                                procedures: procedure
                                                    ? [
                                                          {
                                                              id: procedure.id,
                                                          },
                                                      ]
                                                    : [],
                                                is_being_manufactured: true,
                                                set: false,
                                            }}
                                            renderOption={({ name, price }) => (
                                                <div>
                                                    <p className="text-sm leading-5 font-medium">
                                                        {name}
                                                    </p>
                                                    <p className="text-sm leading-5 font-normal text-gray-500">
                                                        Cena:{' '}
                                                        {formatCurrency(price)}
                                                    </p>
                                                </div>
                                            )}
                                            renderOptions={(options) => (
                                                <div>
                                                    <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                                        Podpowiedzi
                                                    </div>
                                                    {options}
                                                </div>
                                            )}
                                            handleChange={(value) => {
                                                setFieldValue(
                                                    '_implant_price',
                                                    formatPrice(value.price)
                                                        .replace(',', '.')
                                                        .replace(' ', '')
                                                        .replace(' ', '') // NBSP char
                                                )
                                            }}
                                            handleRemove={() => {
                                                setFieldValue(
                                                    '_implant_price',
                                                    ''
                                                )
                                            }}
                                            hasError={
                                                name in errors && !disabled
                                            }
                                            messageEmptyResult="Nie znaleziono implantu."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 w-full mt-4 md:mt-0 md:w-2/12 md:ml-2">
                                        <Label>Kwota</Label>
                                        <PriceField
                                            name="_implant_price"
                                            disabled={disabled}
                                        />
                                    </div>
                                    <div className="flex-shrink-0 mt-2 md:ml-2 md:mt-0">
                                        <Label className="hidden md:block">
                                            &nbsp;
                                        </Label>
                                        <button
                                            type="button"
                                            className={classNames(
                                                'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                                {
                                                    'opacity-30 pointer-events-none':
                                                        !(
                                                            values[
                                                                `_implant_item_phrase`
                                                            ] &&
                                                            values[
                                                                '_implant_price'
                                                            ]
                                                        ),
                                                }
                                            )}
                                            onClick={() => {
                                                push({
                                                    name: values[
                                                        `_implant_item_phrase`
                                                    ],
                                                    price: formatPrice(
                                                        values['_implant_price']
                                                    )
                                                        .replace(',', '.')
                                                        .replace(' ', '')
                                                        .replace(' ', ''), // NBSP char
                                                    manufacturer: values[
                                                        '_implant_item'
                                                    ]
                                                        ? values[
                                                              '_implant_item'
                                                          ].manufacturer
                                                        : undefined,
                                                })

                                                setFieldValue(
                                                    '_implant_price',
                                                    ''
                                                )
                                                setFieldValue(
                                                    '_implant_item_phrase',
                                                    ''
                                                )
                                                setFieldValue(
                                                    '_implant_item',
                                                    undefined
                                                )
                                            }}
                                        >
                                            Dodaj
                                            <PlusIcon
                                                className="ml-2 h-3 w-3"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {tab === 1 && (
                            <>
                                <div className="flex flex-col md:flex-row md:items-center">
                                    <div className="flex-grow w-full md:w-auto">
                                        <Label>Wybierz zestaw</Label>
                                        <SearchableDropdownField<ImplantDropdownItem>
                                            name="_implant_set_item"
                                            disabled={disabled}
                                            queryFn={getDropdownImplants}
                                            queryFilters={{
                                                procedures: procedure
                                                    ? [
                                                          {
                                                              id: procedure.id,
                                                          },
                                                      ]
                                                    : [],
                                                is_being_manufactured: true,
                                                set: true,
                                            }}
                                            renderOption={({
                                                name,
                                                price,
                                                surcharge,
                                            }) => (
                                                <div>
                                                    <p className="text-sm leading-5 font-medium">
                                                        {name}
                                                    </p>
                                                    <p className="text-sm leading-5 font-normal text-gray-500">
                                                        Cena:{' '}
                                                        {formatCurrency(
                                                            price +
                                                                (surcharge || 0)
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                            renderOptions={(options) => (
                                                <div>
                                                    <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                                        Podpowiedzi
                                                    </div>
                                                    {options}
                                                </div>
                                            )}
                                            hasError={
                                                name in errors && !disabled
                                            }
                                            messageEmptyResult="Nie znaleziono zestawu."
                                        />
                                    </div>
                                    <div className="flex-shrink-0 mt-2 md:ml-2 md:mt-0">
                                        <Label className="hidden md:block">
                                            &nbsp;
                                        </Label>
                                        <button
                                            type="button"
                                            className={classNames(
                                                'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                                {
                                                    'opacity-30 cursor-default':
                                                        !values._implant_set_item,
                                                }
                                            )}
                                            onClick={() => {
                                                if (!values._implant_set_item) {
                                                    return
                                                }

                                                push({
                                                    ...values._implant_set_item,
                                                    price: formatPrice(
                                                        values._implant_set_item
                                                            .price +
                                                            (values
                                                                ._implant_set_item
                                                                .surcharge || 0)
                                                    )
                                                        .replace(',', '.')
                                                        .replace(' ', '')
                                                        .replace(' ', ''), // NBSP char
                                                    manufacturer: values[
                                                        '_implant_item'
                                                    ]
                                                        ? values[
                                                              '_implant_item'
                                                          ].manufacturer
                                                        : undefined,
                                                })
                                                setFieldValue(
                                                    '_implant_set_item_phrase',
                                                    ''
                                                )
                                                setFieldValue(
                                                    '_implant_set_item',
                                                    undefined
                                                )
                                            }}
                                            disabled={!values._implant_set_item}
                                        >
                                            Dodaj
                                            <PlusIcon
                                                className="ml-2 h-3 w-3"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {!disabled && <ErrorMessage name={name} />}

                        <div className="mt-6">
                            {(values[name] as ImplantDropdownItem[]).map(
                                (p, index) => {
                                    const itemInInitialData =
                                        implantsWithChangeInfo.find(
                                            (item: any) => isEqual(item, p)
                                        )

                                    if (itemInInitialData) {
                                        itemInInitialData.used = true
                                    }
                                    const isChange =
                                        !disabled && !itemInInitialData

                                    return (
                                        <div
                                            key={index}
                                            className={twMerge(
                                                'mb-0.5 px-2 py-1 h-11 flex items-center justify-between bg-gray-100 text-sm text-gray-900 leading-5 font-normal',
                                                isChange && 'bg-blue-100'
                                            )}
                                        >
                                            <span className="flex-grow truncate">
                                                {p.name}
                                            </span>
                                            <div className="ml-2 flex-shrink-0 flex">
                                                <div style={{ width: '75px' }}>
                                                    <PriceField
                                                        name={`${name}.${index}.price`}
                                                        variant="xs"
                                                        value={p.price}
                                                        disabled={disabled}
                                                    />
                                                </div>
                                            </div>
                                            {!disabled && (
                                                <div
                                                    className="ml-2 flex-shrink-0 flex cursor-pointer"
                                                    onClick={() =>
                                                        remove(index)
                                                    }
                                                >
                                                    <span
                                                        className={twMerge(
                                                            'text-gray-500 hover:text-gray-700',
                                                            isChange &&
                                                                'text-blue-600 hover:text-blue-800'
                                                        )}
                                                    >
                                                        <XIcon className="h-5 w-5" />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default ImplantField
