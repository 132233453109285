import React from 'react'
import classNames from 'classnames'

import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
} from '@heroicons/react/outline'

import { Pagination as IPagination } from 'api/types'

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

const range = (from: number, to: number, step = 1) => {
    let i = from
    const range: number[] = []

    while (i <= to) {
        range.push(i)
        i += step
    }

    return range
}

const fetchPageNumbers = (
    totalPages: number,
    currentPage: number,
    pageNeighbours: number
) => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
        const startPage = Math.max(2, currentPage - pageNeighbours)
        const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
        let pages = range(startPage, endPage)

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 2
        const hasRightSpill = totalPages - endPage > 1
        const spillOffset = totalNumbers - (pages.length + 1)

        // handle: (1) < {5 6} [7] {8 9} (10)
        if (hasLeftSpill && !hasRightSpill) {
            return [
                1,
                ...[
                    LEFT_PAGE,
                    ...range(startPage - spillOffset, startPage - 1),
                    ...pages,
                ],
                totalPages,
            ]
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        if (!hasLeftSpill && hasRightSpill) {
            return [
                1,
                ...[
                    ...pages,
                    ...range(endPage + 1, endPage + spillOffset),
                    RIGHT_PAGE,
                ],
                totalPages,
            ]
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        return [1, ...[LEFT_PAGE, ...pages, RIGHT_PAGE], totalPages]
    }

    return range(1, totalPages)
}

export default function Pagination({
    meta,
    handleChange,
}: {
    meta: IPagination
    handleChange: (page: number) => void
}) {
    if (meta.total < 2) {
        return null
    }

    const pageNeighbours = 2
    const pages = fetchPageNumbers(meta.last_page, meta.current_page, 2)

    return (
        <nav className="mb-5 px-4 flex items-center justify-between sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
                <span
                    className={classNames(
                        'pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500',
                        {
                            'cursor-pointer hover:text-gray-700':
                                meta.current_page > 1,
                        }
                    )}
                    onClick={() =>
                        meta.current_page > 1 &&
                        handleChange(meta.current_page - 1)
                    }
                >
                    <ArrowNarrowLeftIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    Poprzednia
                </span>
            </div>
            <div className="hidden md:-mt-px md:flex">
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE) {
                        return (
                            <span
                                key={index}
                                className="border-transparent border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
                                onClick={() =>
                                    handleChange(
                                        meta.current_page - pageNeighbours - 1
                                    )
                                }
                            >
                                ...
                            </span>
                        )
                    }

                    if (page === RIGHT_PAGE) {
                        return (
                            <span
                                key={index}
                                className="border-transparent border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
                                onClick={() =>
                                    handleChange(
                                        meta.current_page + pageNeighbours + 1
                                    )
                                }
                            >
                                ...
                            </span>
                        )
                    }

                    return (
                        <span
                            key={index}
                            className={classNames(
                                'border-transparent border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer',
                                {
                                    'text-gray-500 hover:text-gray-700 hover:border-gray-300':
                                        page !== meta.current_page,
                                    'border-blue-500 text-blue-500 hover:text-blue-600 hover:border-blue-600':
                                        page === meta.current_page,
                                }
                            )}
                            onClick={() => {
                                handleChange(page as number)
                            }}
                        >
                            {page}
                        </span>
                    )
                })}
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
                <span
                    className={classNames(
                        'pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500',
                        {
                            'hover:text-gray-700 cursor-pointer':
                                meta.current_page < meta.last_page,
                        }
                    )}
                    onClick={() =>
                        meta.current_page < meta.last_page &&
                        handleChange(meta.current_page + 1)
                    }
                >
                    Następna
                    <ArrowNarrowRightIcon
                        className="ml-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </div>
        </nav>
    )
}
