import React from 'react'
import moment from 'moment'

interface Duration {
    duration: string
    from: string
    to: string
}

interface Durations {
    transfer_to_block?: Duration
    anesthesia?: Duration
    waiting_for_doctor?: Duration
    operation?: Duration
    pickup_patient?: Duration
    cleaning?: Duration
}

const statuses: {
    key: keyof Durations
    name: string
}[] = [
    {
        key: 'transfer_to_block',
        name: 'Przewiezienie na blok',
    },
    {
        key: 'anesthesia',
        name: 'Znieczulenie pacjenta',
    },
    {
        key: 'waiting_for_doctor',
        name: 'Oczekiwanie na lekarza',
    },
    {
        key: 'operation',
        name: 'Czas zabiegu',
    },
    {
        key: 'cleaning',
        name: 'Sprzątanie',
    },
    {
        key: 'pickup_patient',
        name: 'Odebranie pacjenta ',
    },
]

const OperationDurations: React.FC<{
    durations: Durations
}> = ({ durations }) => {
    return (
        <div className="flex flex-col gap-1">
            {statuses.map((status) => (
                <div
                    className="flex items-center justify-between"
                    key={status.key}
                >
                    <div className="text-sm font-medium p-1">{status.name}</div>

                    <div className="text-sm leading-5 font-medium w-48">
                        {durations[status.key]?.from &&
                        durations[status.key]?.to &&
                        durations[status.key]?.duration ? (
                            <div className="text-gray-400 flex items-center justify-between">
                                {moment(durations[status.key]?.from).format(
                                    'HH:mm'
                                )}{' '}
                                -{' '}
                                {moment(durations[status.key]?.to).format(
                                    'HH:mm'
                                )}{' '}
                                <span className="text-gray-700">
                                    {durations[status.key]?.duration}
                                </span>
                            </div>
                        ) : (
                            <>
                                {status.key === 'cleaning' &&
                                durations[status.key] === null ? (
                                    <span className="text-red-700">
                                        Pominięty
                                    </span>
                                ) : (
                                    '-'
                                )}
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default OperationDurations
