import React from 'react'
import { useQueryClient } from 'react-query'

import { useNotification } from 'hooks'
import { useBatchUpdateProcedureDurations } from 'api'
import SettingsProcedureDurationListAssignModal, {
    FormValues,
} from 'components/SettingsProcedureDurationListAssignModal'
import { timeToMinutes } from 'helpers/time'

import type { FormSubmitFn, IModal } from 'types'

const SettingsProcedureDurationListAssignModalContainer: React.FC<{
    modal: IModal
    selectedProcedureIds: number[]
    selectedProcedureCategoryIds: number[]
    handleSuccess: () => Promise<void>
}> = ({
    selectedProcedureIds,
    selectedProcedureCategoryIds,
    modal,
    handleSuccess,
}) => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: assign } = useBatchUpdateProcedureDurations()

    const assignTo = modal.getState()?.assignTo || ''

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        const data = {
            duration_in_minutes: values.duration_in_minutes
                ? timeToMinutes(values.duration_in_minutes.id).toString()
                : '',
            anesthesia_duration_in_minutes:
                values.anesthesia_duration_in_minutes
                    ? timeToMinutes(
                          values.anesthesia_duration_in_minutes.id
                      ).toString()
                    : '',
            procedures: assignTo === 'procedures' ? selectedProcedureIds : null,
            procedure_categories:
                assignTo === 'procedure_categories'
                    ? selectedProcedureCategoryIds
                    : null,
        }

        assign(data, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['procedures'])
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany zostały zapisane',
                    type: 'success',
                })
                await handleSuccess()
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
                showNotification({
                    content: error.message,
                    type: 'danger',
                })
            },
        })
    }

    return (
        <SettingsProcedureDurationListAssignModal
            modal={modal}
            selectedProcedureIds={selectedProcedureIds}
            selectedProcedureCategoryIds={selectedProcedureCategoryIds}
            assignTo={assignTo}
            handleSubmit={handleSubmit}
        />
    )
}

export default SettingsProcedureDurationListAssignModalContainer
