import React from 'react'
import classNames from 'classnames'
import { ExclamationCircleIcon, ThumbUpIcon } from '@heroicons/react/solid'

import {
    displayPatientNameOrPESELOrIdentityCard,
    formatDateNoYear,
} from 'helpers'
import OperationStatus from 'components/OperationStatus'

import type { OperationListItem as IOperationListItem } from 'types/entities'

const OperationListItem: React.FC<{
    item: IOperationListItem
    expired?: boolean
    status?: string
    active?: boolean
    handleClick: () => void
}> = ({ item, handleClick, active = false, expired = false }) => {
    const isCanceled = item.status === 'canceled'

    return (
        <div
            className={classNames(
                'relative px-4 py-3 flex items-center space-x-3 border border-transparent bg-white rounded cursor-pointer',
                {
                    'border-blue-700 bg-blue-500 text-white shadow-md':
                        !isCanceled && active,
                    'border-blue-200 hover:bg-gray-50':
                        !isCanceled && !active && !expired,
                    'border-red-200': !isCanceled && !active && expired,
                    'border-gray-900 bg-gray-900 text-white':
                        isCanceled && !active,
                    'border-gray-900 bg-gray-500 text-gray-400':
                        isCanceled && active,
                }
            )}
            onClick={handleClick}
        >
            <div className="flex-shrink-0">
                <OperationStatus
                    progress={(item?.progress?.percent || 0) * 100}
                    rejected={isCanceled}
                />
            </div>
            <div className="flex-1 min-w-0">
                <p
                    className={classNames(
                        'flex items-center text-sm leading-5',
                        {
                            'text-white': isCanceled && active,
                            'font-semibold text-white': !isCanceled && active,
                            'text-gray-400': isCanceled && !active,
                            'text-gray-900 font-medium': !isCanceled && !active,
                        }
                    )}
                >
                    <span>
                        {item.patient
                            ? displayPatientNameOrPESELOrIdentityCard(
                                  item.patient
                              )
                            : '-'}
                    </span>
                    {item.restored && (
                        <span className="ml-2.5">
                            <ExclamationCircleIcon className="w-auto h-4 text-red-500 cursor-pointer" />
                        </span>
                    )}
                </p>
                <div className="flex items-center">
                    {!!item.progress && (
                        <span
                            className={classNames('text-xs', {
                                'text-white': active,
                                'text-gray-500': !active,
                            })}
                        >
                            {item.progress.finished_tasks_count} /{' '}
                            {item.progress.tasks_count}
                        </span>
                    )}
                    {!!item.patient_informed && (
                        <ThumbUpIcon className="ml-2 w-4 text-green-500" />
                    )}
                </div>
            </div>
            <div className="flex-shrink-0 self-start">
                <span
                    className={classNames(
                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                        {
                            'bg-white text-gray-600': active,
                            'bg-blue-100 text-blue-800':
                                !isCanceled && !active && !expired,
                            'bg-gray-400 text-gray-800':
                                isCanceled && !active && !expired,
                            'bg-red-100 text-red-800': expired,
                        }
                    )}
                >
                    {item.reminder ? formatDateNoYear(item.reminder) : '-'}
                </span>
            </div>
        </div>
    )
}

export default OperationListItem
