import React from 'react'
import { FieldArray } from 'formik'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

import { getExpenses } from 'api'
import { Equipment } from 'api/types'
import { formatPrice, formatCurrency } from 'helpers/currencies'
import { Label } from 'components/ui'
import { PriceField, SearchableDropdownField } from 'components/forms'

interface DropdownItem {
    id: Equipment['id']
    name: Equipment['name']
    price: Equipment['price']
}

const AdditionalCostField: React.FC<{
    name: string
    editPrice?: boolean
    disabled?: boolean
    initialData?: DropdownItem[]
}> = ({ name, editPrice = false, disabled, initialData }) => (
    <FieldArray
        name={name}
        render={({ push, remove, form: { values, errors, setFieldValue } }) => {
            const costsWithChangeInfo: Array<
                DropdownItem & { used?: boolean }
            > = cloneDeep(initialData || [])

            return (
                <div>
                    <div className="flex flex-col md:flex-row items-start md:items-center">
                        <div className="flex-grow w-full md:w-auto">
                            <Label>Opis</Label>
                            <SearchableDropdownField<DropdownItem>
                                name={`_${name}_item`}
                                placeholder="Opis"
                                disabled={disabled}
                                queryFn={getExpenses}
                                queryFieldName="name"
                                renderOption={({ name, price }) => (
                                    <div>
                                        <p className="text-sm leading-5 font-medium">
                                            {name}
                                        </p>
                                        <p className="text-sm leading-5 font-normal text-gray-500">
                                            Cena: {formatCurrency(price)}
                                        </p>
                                    </div>
                                )}
                                renderOptions={(options) => (
                                    <div>
                                        <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                            Podpowiedzi
                                        </div>
                                        {options}
                                    </div>
                                )}
                                handleChange={(value) => {
                                    setFieldValue('_expense_price', value.price)
                                }}
                                handleRemove={() => {
                                    setFieldValue('_expense_price', '')
                                }}
                            />
                        </div>
                        <div className="flex-shrink-0 w-full mt-4 md:mt-0 md:w-2/12 md:ml-2">
                            <Label>Kwota</Label>
                            <PriceField
                                name="_expense_price"
                                negative
                                disabled={disabled}
                            />
                        </div>
                        <div className="flex-shrink-0 md:ml-2">
                            <Label>&nbsp;</Label>
                            <button
                                type="button"
                                className={classNames(
                                    'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                    {
                                        'opacity-30 pointer-events-none': !(
                                            values[`_${name}_item_phrase`] &&
                                            values['_expense_price']
                                        ),
                                    }
                                )}
                                onClick={() => {
                                    if (
                                        !(
                                            values[`_${name}_item_phrase`] &&
                                            values['_expense_price']
                                        )
                                    ) {
                                        return
                                    }

                                    push({
                                        name: values[`_${name}_item_phrase`],
                                        price: formatPrice(
                                            values['_expense_price']
                                        )
                                            .replace(',', '.')
                                            .replace(' ', '')
                                            .replace(' ', ''), // NBSP char,,
                                    })

                                    setFieldValue('_expense_price', '')
                                    setFieldValue(`_${name}_item_phrase`, '')
                                    setFieldValue(`_${name}_item`, undefined)
                                }}
                            >
                                Dodaj
                                <PlusIcon
                                    className="ml-2 h-3 w-3"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                    <div className="mt-6">
                        {(values[name] as DropdownItem[]).map((item, index) => {
                            const itemInInitialData = costsWithChangeInfo.find(
                                (costWithChangeInfo: any) =>
                                    isEqual(costWithChangeInfo, item)
                            )

                            if (itemInInitialData) {
                                itemInInitialData.used = true
                            }
                            const isChange = !disabled && !itemInInitialData
                            return (
                                <div
                                    key={index}
                                    className={twMerge(
                                        'mb-0.5 px-2 py-1 h-11 flex items-center justify-between bg-gray-100 text-sm text-gray-900 leading-5 font-normal',
                                        isChange && 'bg-blue-100'
                                    )}
                                >
                                    <span className="flex-grow truncate">
                                        {item.name}
                                    </span>
                                    {!editPrice &&
                                        (!!item.price || item.price === 0) && (
                                            <div className="ml-2 flex-shrink-0 flex">
                                                {formatCurrency(item.price)}
                                            </div>
                                        )}
                                    {editPrice && (
                                        <div className="ml-2 flex-shrink-0 flex">
                                            <div style={{ width: '75px' }}>
                                                <PriceField
                                                    name={`${name}.${index}.price`}
                                                    value={item.price}
                                                    negative
                                                    variant="xs"
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!disabled && (
                                        <div
                                            className="ml-2 flex-shrink-0 flex cursor-pointer"
                                            onClick={() => remove(index)}
                                        >
                                            <span
                                                className={twMerge(
                                                    'text-gray-500 hover:text-gray-700',
                                                    isChange &&
                                                        'text-blue-600 hover:text-blue-800'
                                                )}
                                            >
                                                <XIcon className="h-5 w-5" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }}
    />
)

export default AdditionalCostField
