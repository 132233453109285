import React from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
    usePostClosureTemplate,
    usePatchClosureTemplate,
    useDeleteClosureTemplate,
    useGetClosureTemplate,
} from 'api'
import { useModal, useNotification, useOperationClosurePolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import ClosureTemplateForm from 'components/ClosureTemplateForm'
import ConfirmDeleteModal from 'components/ConfirmDeleteModal'
import ListLayout from 'layouts/ListLayout'

import type {
    FormSubmitFn,
    ClosureTemplateForm as TClosureTemplateForm,
} from 'types'
import type { CancelDeleteModalForm } from 'types'

const ClosureTemplateContainer: React.FC<{}> = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const showNotification = useNotification()
    const params = useParams()
    const id = !isNaN(Number(params.id)) ? Number(params.id) : undefined
    const operationClosurePolicy = useOperationClosurePolicy()
    const modalConfirmDelete = useModal<number>()

    const templateQueryResult = useGetClosureTemplate(id!, {
        enabled: operationClosurePolicy.canCreate && !!id,
    })
    const { mutate: create } = usePostClosureTemplate()
    const { mutate: update } = usePatchClosureTemplate()
    const { mutate: deleteTemplate } = useDeleteClosureTemplate()

    const handleSubmit: FormSubmitFn<TClosureTemplateForm> = (
        values,
        formikHelpers
    ) => {
        if (id) {
            return update(
                {
                    id,
                    data: values,
                },
                {
                    onSuccess: (data) => {
                        showNotification({
                            content: 'Szablon został zaktualizowany',
                            type: 'success',
                        })
                        queryClient.invalidateQueries(['closure-templates'])
                        navigate('/closures/templates')
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                    },
                    onSettled: () => {
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        }

        create(
            {
                data: values,
            },
            {
                onSuccess: (data) => {
                    showNotification({
                        content: 'Szablon został utworzony',
                        type: 'success',
                    })
                    queryClient.invalidateQueries(['closure-templates'])
                    navigate('/closures/templates')
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleDelete = (id: number) => {
        modalConfirmDelete.setState(id)
        modalConfirmDelete.openModal()
    }

    const handleDeleteConfirmed: FormSubmitFn<CancelDeleteModalForm> = (
        values,
        formikHelpers
    ) => {
        const id = modalConfirmDelete.getState()

        if (!id) {
            return
        }

        deleteTemplate(
            { id },
            {
                onSuccess: () => {
                    showNotification({
                        content: 'Szablon został usunięty',
                        type: 'success',
                    })
                    modalConfirmDelete.closeModal()
                    queryClient.invalidateQueries(['closure-templates'])
                    navigate('/closures/templates')
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    if (!operationClosurePolicy.canCreate) {
        return <AccessDenied message="Nie masz dostępu do szablonów" />
    }

    if (templateQueryResult.isLoading) {
        return <Loader />
    }

    if (templateQueryResult.isError) {
        return <div>{templateQueryResult.error.message}</div>
    }

    return (
        <ListLayout
            renderTitle={
                <>
                    <span className="font-semibold">
                        {id ? 'Edytuj' : 'Dodaj'}
                        {': '}
                    </span>
                    <span className="font-light">Szablon opisu operacji</span>
                </>
            }
        >
            <ClosureTemplateForm
                data={templateQueryResult.data}
                onSubmit={handleSubmit}
                onDelete={id ? handleDelete : undefined}
            />
            <ConfirmDeleteModal
                title="Czy na pewno chcesz usunąć ten szablon?"
                modal={modalConfirmDelete}
                onSubmit={handleDeleteConfirmed}
                onCancel={modalConfirmDelete.closeModal}
            />
        </ListLayout>
    )
}

export default ClosureTemplateContainer
