const getDurationArray = (
    hours: number,
    interval: 5 | 15
): Array<{ id: string; name: string }> => {
    const minutes = [
        { id: '5m', name: '5min' },
        { id: '10m', name: '10min' },
        { id: '15m', name: '15min' },
        { id: '20m', name: '20min' },
        { id: '25m', name: '25min' },
        { id: '30m', name: '30min' },
        { id: '35m', name: '35min' },
        { id: '40m', name: '40min' },
        { id: '45m', name: '45min' },
        { id: '50m', name: '50min' },
        { id: '55m', name: '55min' },
    ].filter((item) => parseInt(item.id) % interval === 0)

    return Array.from(Array(hours).keys())
        .map((i) => {
            if (!i) {
                return [
                    ...minutes,
                    {
                        id: `${i + 1}h`,
                        name: `${i + 1}h`,
                    },
                ]
            }

            return [
                ...minutes.map((m) => ({
                    id: `${i}h ${m.id}`,
                    name: `${i}h ${m.name}`,
                })),
                {
                    id: `${i + 1}h`,
                    name: `${i + 1}h`,
                },
            ]
        })
        .flat()
}

export default getDurationArray
