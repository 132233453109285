import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const SURGERY_PERMISSIONS = {
    index: PERMISSIONS.INDEX_OPERATIONS,
    show: PERMISSIONS.SHOW_OPERATIONS,
    create: [
        PERMISSIONS.CREATE_OPERATIONS,
        PERMISSIONS.INDEX_BLOCKED_DAYS,
        PERMISSIONS.INDEX_PATIENTS,
        PERMISSIONS.CREATE_PATIENTS,
        PERMISSIONS.UPDATE_PATIENTS,
        PERMISSIONS.SHOW_PATIENTS,
        PERMISSIONS.INDEX_PAYERS,
        PERMISSIONS.INDEX_PROCEDURES,
        PERMISSIONS.SHOW_PROCEDURES,
        PERMISSIONS.INDEX_IMPLANTS,
        PERMISSIONS.INDEX_EQUIPMENT,
        PERMISSIONS.INDEX_EXPENSES,
    ],
    update: [
        PERMISSIONS.SHOW_OPERATIONS,
        PERMISSIONS.UPDATE_OPERATIONS,
        PERMISSIONS.INDEX_BLOCKED_DAYS,
        PERMISSIONS.SHOW_PATIENTS,
        PERMISSIONS.UPDATE_PATIENTS,
        PERMISSIONS.INDEX_PROCEDURES,
        PERMISSIONS.SHOW_PROCEDURES,
        PERMISSIONS.INDEX_PAYERS,
        PERMISSIONS.INDEX_IMPLANTS,
        PERMISSIONS.INDEX_EQUIPMENT,
        PERMISSIONS.INDEX_EXPENSES,
    ],
    delete: PERMISSIONS.DELETE_OPERATIONS,
}

export const useSurgeryPolicy = () => {
    const { user, checkPermissions, hasPermission } = usePolicy()

    return {
        user,
        ...checkPermissions(SURGERY_PERMISSIONS),
        canIndexOthers: hasPermission(PERMISSIONS.INDEX_OTHERS_OPERATIONS),
        canExport: hasPermission(PERMISSIONS.EXPORT_OPERATIONS),
        canSeeHistory: hasPermission(PERMISSIONS.VIEW_COMMENT_HISTORY),
    }
}
