import React from 'react'

import { Loader } from 'components/ui'
import {
    transformScheduleOperationFormToAPI,
    setSurgicalTeamErrors,
    useGetOperatingRooms,
    useGetScheduleOperation,
    useUpdateScheduleOperation,
    useGetComments,
} from 'api'
import { useChildToggle, useCommentPolicy } from 'hooks'

import type {
    Comment as IComment,
    OperatingRoom,
    ScheduleOperation,
} from 'api/types'
import type { FormSubmitFn, ScheduleOperationForm } from 'types'

const ScheduleOperationFormContainer: React.FC<{
    id: number
    children: ({
        id,
        data,
        rooms,
        comments,
        handleSubmit,
    }: {
        id: number
        data: ScheduleOperation
        rooms: OperatingRoom[]
        comments?: IComment[]
        handleSubmit: FormSubmitFn<ScheduleOperationForm>
        handleChildToggleChange: ReturnType<typeof useChildToggle>
    }) => JSX.Element
    onSuccessChange?: () => void
}> = ({ id, children, onSuccessChange }) => {
    const commentsPolicy = useCommentPolicy()

    const rooms = useGetOperatingRooms({ pagination: false })
    const operation = useGetScheduleOperation(id, {
        enabled: !!id,
    })
    const comments = useGetComments(
        {
            operation_id: id,
            type: 'schedule',
        },
        { enabled: !!id && commentsPolicy.canIndex }
    )
    const { mutate: update } = useUpdateScheduleOperation()
    const handleChildToggleChange = useChildToggle(id, 'schedule-operation')

    const handleSubmit: FormSubmitFn<ScheduleOperationForm> = (
        values,
        formikHelpers
    ) => {
        const data = transformScheduleOperationFormToAPI(values)

        update(
            { id, data },
            {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    typeof onSuccessChange === 'function' && onSuccessChange()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)

                    setSurgicalTeamErrors(
                        data.surgical_team,
                        error.errors,
                        formikHelpers.setFieldError
                    )
                },
            }
        )
    }

    if (operation.isLoading || rooms.isLoading) {
        return <Loader />
    }

    if (operation.isError || rooms.isError) {
        return (
            <>
                {operation.isError && <div>{operation.error.message}</div>}
                {rooms.isError && <div>{rooms.error.message}</div>}
            </>
        )
    }

    if (operation.isSuccess && rooms.isSuccess) {
        return children({
            id,
            data: operation.data.data,
            rooms: rooms.data.data,
            comments: comments.data?.data,
            handleSubmit,
            handleChildToggleChange,
        })
    }

    return null
}

export default ScheduleOperationFormContainer
