import React, { useEffect, useState } from 'react'

import { Pagination } from '.'

import type { Pagination as IPagination } from 'api/types'
import type { ListFilterAction } from 'helpers'

const TableFooter: React.FC<{
    meta?: IPagination
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ meta, setFilters }) => {
    const [cachedMeta, setCachedMeta] = useState(meta)

    useEffect(() => {
        if (meta) {
            setCachedMeta(meta)
        }
    }, [meta])

    if (!cachedMeta) {
        return null
    }

    if (!cachedMeta.total) {
        return null
    }

    return (
        <div className="border-t border-gray-200">
            <div className="mt-4 text-right hidden print:block">
                Strona: {cachedMeta.current_page}/{cachedMeta.last_page}
            </div>
            <div className="print:hidden">
                <Pagination
                    meta={cachedMeta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            </div>
        </div>
    )
}

export default TableFooter
