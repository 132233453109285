import React from 'react'
import classNames from 'classnames'
import { ExclamationCircleIcon, ThumbUpIcon } from '@heroicons/react/solid'

import {
    formatDate,
    displayAdmissionDate,
    displayPatientNameOrPESELOrIdentityCard,
} from 'helpers'
import { TableFooter } from 'components/ui'
import Sort, { SetSortingFn } from 'components/Sort'

import type { UseQueryResult } from 'react-query'
import type { DietListItem, ResponseList } from 'api/types'
import type { Filters } from 'components/DietListFilters'

type SurgeryListProps = {
    dietListQueryResult: UseQueryResult<ResponseList<DietListItem[]>>
    filtersCount: number
    filters: Filters
    setFilters: React.Dispatch<React.SetStateAction<Filters>>
    setSorting: SetSortingFn
}

const DietList: React.FC<SurgeryListProps> = ({
    dietListQueryResult,
    filters,
    setFilters,
    filtersCount,
    setSorting,
}) => {
    return (
        <div className="p-2 bg-white rounded-lg">
            <table className="w-full">
                <thead className="bg-gray-50 border-b border-b-gray-200 text-xs text-gray-500 leading-normal uppercase">
                    <tr>
                        <th
                            scope="col"
                            className="w-26 px-1 md:px-2 xl:px-2 xl:pr-0 py-3 text-left font-medium tracking-wider truncate"
                            style={{ width: '106px' }}
                        >
                            <Sort
                                name="estimated_date"
                                filters={filters}
                                setSorting={setSorting}
                                up
                            >
                                D. zabiegu
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className=" px-1 md:px-2 py-3 text-center font-medium tracking-wider"
                            style={{ width: '130px' }}
                        >
                            Przyjęcie
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider"
                            style={{ width: '268px' }}
                        >
                            Pacjent
                        </th>
                        <th
                            scope="col"
                            className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3 text-left font-medium tracking-wider"
                        >
                            Dieta
                        </th>
                    </tr>
                </thead>
                {dietListQueryResult.isSuccess && (
                    <tbody
                        className={classNames(
                            'text-sm bg-white divide-y leading-5 divide-gray-200'
                        )}
                    >
                        {dietListQueryResult.data.data.map((item, index) => (
                            <tr
                                key={item.id}
                                className={classNames({
                                    'bg-gray-50': index % 2,
                                })}
                                style={{ height: '72px' }}
                            >
                                <td className="px-1 md:px-2 xl:px-2 xl:pr-0 py-3">
                                    <div className="text-sm leading-5 font-normal text-gray-900">
                                        {formatDate(item.estimated_date)}
                                    </div>
                                </td>
                                <td className="px-1 md:px-2 py-1 text-center text-gray-500">
                                    {item.patient_informed && (
                                        <div className="mb-1">
                                            <ThumbUpIcon className="inline w-4 text-green-500" />
                                        </div>
                                    )}
                                    <div className="w-20 mx-auto">
                                        {item.admission_date ? (
                                            <span className="font-medium text-center">
                                                {displayAdmissionDate(
                                                    item.admission_date,
                                                    item.estimated_date
                                                )}
                                            </span>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </td>
                                <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3">
                                    <span
                                        className={classNames(
                                            'font-medium inline-flex items-center gap-1'
                                        )}
                                    >
                                        {item.patient
                                            ? displayPatientNameOrPESELOrIdentityCard(
                                                  item.patient
                                              )
                                            : '-'}
                                        {item.restored ? (
                                            <ExclamationCircleIcon
                                                className="w-4 h-4 text-red-500"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </td>
                                <td className="px-1 md:px-2 xl:px-6 xl:pr-0 py-3">
                                    <span>{item.diet_info || '-'}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
            {dietListQueryResult.isSuccess &&
                dietListQueryResult.data.meta.total === 0 && (
                    <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                        {Array.from(
                            Array(Object.keys(filters).length).keys()
                        ).map((item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 3 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        {filtersCount === 0 && (
                                            <span>
                                                Nie ma jeszcze żadnych diet.
                                            </span>
                                        )}
                                        {filtersCount > 0 && (
                                            <span>
                                                Nie znaleziono wyników
                                                wyszukiwania.
                                            </span>
                                        )}
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        ))}
                    </div>
                )}
            <TableFooter
                meta={dietListQueryResult.data?.meta}
                setFilters={(action) => {
                    if (action.type === 'setPage') {
                        setFilters((prevState: Filters) => ({
                            ...prevState,
                            page: action.payload,
                        }))
                    }
                }}
            />
        </div>
    )
}

export default DietList
