import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const COMMENT_PERMISSIONS = {
    index: PERMISSIONS.INDEX_COMMENTS,
    show: false,
    create: PERMISSIONS.CREATE_COMMENTS,
    update: false,
    delete: false,
}

export const useCommentPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(COMMENT_PERMISSIONS),
    }
}
