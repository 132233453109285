import { z } from 'zod'
import { DateRange } from 'react-day-picker'

import {
    DropdownItem,
    ProcedureDropdownItem,
    UserDropdownItem,
} from 'components/forms'

export type StatisticsDoctorListFilters = {
    length: number
    date_range: DateRange
    operators?: UserDropdownItem[]
    procedure?: ProcedureDropdownItem | null
    procedure_phrase?: string
    procedure_types?: DropdownItem[]
    procedure_category?: DropdownItem
}

export const statisticsDoctorFilterSchema = z.object({
    date_range: z.object({
        from: z.string().datetime(),
        to: z.string().datetime(),
    }),
    operators: z
        .array(
            z.object({
                id: z.number(),
                first_name: z.string(),
                last_name: z.string(),
                avatar_url: z.string(),
            })
        )
        .optional(),
    procedure: z.object({ id: z.number(), name: z.string() }).optional(),
    procedure_phrase: z.string().optional(),
    procedure_types: z
        .array(z.object({ id: z.number(), name: z.string() }))
        .optional(),
    procedure_category: z
        .object({ id: z.number(), name: z.string() })
        .optional(),
})
