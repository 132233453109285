import React from 'react'
import { useQueryClient } from 'react-query'
import classNames from 'classnames'

import {
    Comment as IComment,
    CommentType,
    ResponseData,
    UserResponse,
} from 'api/types'
import { formatDateNoYear, formatTime } from 'helpers'
import UserAvatar from 'components/UserAvatar'

const Comment: React.FC<{
    comment: Partial<Omit<IComment, 'id'>>
    type: CommentType
    displayTaskId?: boolean
    handleClickTaskId?: (id: number) => void
}> = ({ type, comment, displayTaskId, handleClickTaskId }) => {
    const queryClient = useQueryClient()

    const user = queryClient.getQueryData<ResponseData<UserResponse>>('user')

    return (
        <div
            className={classNames('mt-2 border rounded-md px-4 py-4', {
                'border-gray-100 bg-gray-50':
                    user!.data.id !== comment?.author?.id,
                'border-blue-100 bg-blue-50':
                    user!.data.id === comment?.author?.id,
            })}
        >
            <div className="flex space-x-3">
                {!!comment.author && (
                    <div className="flex-shrink-0">
                        <UserAvatar
                            variant="avatar"
                            data={comment.author}
                            size={10}
                        />
                    </div>
                )}
                <div className="min-w-0 flex-1">
                    {!!comment.author && (
                        <p className="text-sm font-medium text-gray-900">
                            {comment.author.first_name}{' '}
                            {comment.author.last_name}
                        </p>
                    )}
                    <p className="text-sm text-gray-500 whitespace-pre-line">
                        {comment.content}
                    </p>
                </div>
                <div className="flex-shrink-0 flex">
                    {displayTaskId && !!comment.commentable_id && (
                        <div className="-mt-2 mr-4">
                            <span
                                className={classNames(
                                    'inline-flex items-center px-3 py-0.5 rounded-full text-xs bg-indigo-100 text-indigo-800',
                                    {
                                        'cursor-pointer': !!handleClickTaskId,
                                    }
                                )}
                                onClick={() =>
                                    !!handleClickTaskId &&
                                    handleClickTaskId(comment.commentable_id!)
                                }
                            >
                                Z-{comment.commentable_id}
                            </span>
                        </div>
                    )}
                    {!!comment.created_at && (
                        <div className="text-xs leading-none font-normal">
                            <span className="text-gray-400">
                                {formatDateNoYear(comment.created_at)}
                            </span>{' '}
                            <span className="text-gray-600">
                                {formatTime(comment.created_at)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Comment
