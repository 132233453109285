import { useMutation, UseMutationResult } from 'react-query'

import axios from 'api/axios'

import type { ResponseError, LoginRequest, LoginResponse } from 'api/types'

export const login = (data: LoginRequest): Promise<LoginResponse> =>
    axios.post('/login/token', data)

export const useLogin = (): UseMutationResult<
    LoginResponse,
    ResponseError,
    {
        email: string
        password: string
        code?: string
        resend_code?: boolean
    }
> => useMutation((data) => login({ ...data, device_name: 'web' }))
