import React, { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { useGetOperation, useInfiniteOperations } from 'api'
import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useCaregiverPanelPolicy } from 'hooks'
import mediaContext from 'contexts/media/mediaContext'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { Link } from 'components/ui'
import CaregiverPanel from 'components/CaregiverPanel'
import CaregiverPanelFilters, {
    FILTER_OPERATION_TYPE_DEFAULT,
} from 'components/CaregiverPanelFilters'

import type { FormikState } from 'formik'
import type { FormSubmitFn, OperationTaskFilters } from 'types'
import type { OperationItem } from 'api/types'
import type { CaregiverPanelFilter } from 'components/CaregiverPanelFilters'

const CaregiverPanelContainer = () => {
    const location = useLocation()
    const caregiverPanelPolicy = useCaregiverPanelPolicy()
    const filtersFormRef = useRef<FormikState<CaregiverPanelFilter>>(null)

    const [currentOperationId, setCurrentOperationId] = useState<number>()
    const [currentOperationIndex, setCurrentOperationIndex] = useState<number>()
    const [filters, setFilters] = useState<OperationTaskFilters>({
        query: '',
        procedure_types: [],
        sort_by: 'task_reminder',
        sort_direction: 'asc',
        pagination: true,
        length: DEFAULT_LIST_PER_PAGE,
        operator: undefined,
        expired_tasks: undefined,
        new_operations: undefined,
        my_operations: undefined,
        longterm_operations: undefined,
    })

    const { isDesktop } = useContext(mediaContext)

    const operations = useInfiniteOperations(filters, {
        cacheTime: 0,
        keepPreviousData: true,
        enabled: caregiverPanelPolicy.canIndex,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                ? lastPage.meta.current_page + 1
                : undefined
        },
    })

    const operation = useGetOperation(currentOperationId!, {
        enabled: caregiverPanelPolicy.canIndex && !!currentOperationId,
    })

    const combinedOperationList = useMemo(
        () =>
            operations.isSuccess && operations.isFetched
                ? operations.data.pages.reduce((acc, page, index) => {
                      acc = [...acc, ...page.data]

                      return acc
                  }, [] as OperationItem[])
                : [],
        [operations]
    )

    useEffect(() => {
        if (!location.state) {
            return
        }

        if (!isNaN(Number(location.state.id))) {
            setCurrentOperationId(location.state.id)
            window.history.replaceState({}, document.title)
        }
    }, [location.state])

    useEffect(() => {
        if (combinedOperationList.length === 0) {
            return
        }

        if (
            currentOperationIndex === undefined &&
            currentOperationId === undefined
        ) {
            if (isDesktop) {
                setCurrentOperationIndex(0)
                setCurrentOperationId(combinedOperationList[0].id)

                return
            }
        }

        if (
            currentOperationIndex === undefined &&
            currentOperationId !== undefined
        ) {
            const foundIndex = combinedOperationList.findIndex(
                (i) => i.id === currentOperationId
            )
            if (foundIndex !== -1) {
                setCurrentOperationIndex(foundIndex)
            }

            return
        }

        if (
            currentOperationIndex !== undefined &&
            combinedOperationList[currentOperationIndex] === undefined
        ) {
            setCurrentOperationIndex(undefined)

            return
        }

        if (
            currentOperationIndex !== undefined &&
            currentOperationId !== undefined
        ) {
            if (
                combinedOperationList[currentOperationIndex].id !==
                currentOperationId
            ) {
                const foundIndex = combinedOperationList.findIndex(
                    (i) => i.id === currentOperationId
                )
                if (foundIndex !== -1) {
                    setCurrentOperationIndex(foundIndex)
                } else {
                    setCurrentOperationIndex(undefined)
                }

                return
            }
        }
    }, [
        isDesktop,
        currentOperationId,
        currentOperationIndex,
        combinedOperationList,
    ])

    const isCustomDate = useMemo(() => {
        if (!filters.from) {
            return false
        }

        return !moment(filters.from).isBetween(
            moment(),
            moment().add(4, 'days'),
            'date',
            '[]'
        )
    }, [filters.from])

    const resetOperationCard = () => {
        setCurrentOperationIndex(undefined)
        setCurrentOperationId(undefined)
    }

    const handleFilterChange: FormSubmitFn<CaregiverPanelFilter> = (values) => {
        const isOperationTypeSelected =
            values.operation_type.id !== 'all' ? true : false

        setFilters((prevState) => ({
            ...prevState,
            query: values.query,
            doctor_id: values.operator?.id,
            procedure_types: values.procedure_types.map((i) => i.id),
            sort_by:
                isOperationTypeSelected || filters.expired_tasks
                    ? 'id'
                    : 'task_reminder',
            expired_tasks: isOperationTypeSelected
                ? undefined
                : filters.expired_tasks,
            new_operations:
                values.operation_type.id === 'new_operations' || undefined,
            my_operations:
                values.operation_type.id === 'my_operations' || undefined,
            longterm_operations:
                values.operation_type.id === 'longterm_operations' || undefined,
            from:
                isOperationTypeSelected || filters.expired_tasks
                    ? undefined
                    : prevState.from,
            to:
                isOperationTypeSelected || filters.expired_tasks
                    ? undefined
                    : prevState.to,
        }))

        resetOperationCard()
    }

    const handleFilterDayChange = (date?: string) => {
        if (filtersFormRef.current) {
            filtersFormRef.current.values.operation_type =
                FILTER_OPERATION_TYPE_DEFAULT
        }

        setFilters((prevState) => ({
            ...prevState,
            from: date,
            to: date,
            sort_by: 'task_reminder',
            expired_tasks: undefined,
            new_operations: undefined,
            my_operations: undefined,
            longterm_operations: undefined,
        }))

        resetOperationCard()
    }

    const handleFilterExpiredTasksChange = async () => {
        const nextState = !filters.expired_tasks

        if (filtersFormRef.current) {
            filtersFormRef.current.values.operation_type =
                FILTER_OPERATION_TYPE_DEFAULT
        }

        await setFilters((prevState) => ({
            ...prevState,
            sort_by: nextState ? 'id' : 'task_reminder',
            expired_tasks: nextState ? nextState : undefined,
            from: undefined,
            to: undefined,
        }))

        resetOperationCard()
    }

    const handleOperationCardClose = () => {
        resetOperationCard()
    }

    const handleOperationListItemSelect = (index: number) => {
        if (combinedOperationList[index]) {
            setCurrentOperationIndex(index)
            setCurrentOperationId(Number(combinedOperationList[index].id))
        }
    }

    if (!caregiverPanelPolicy.canIndex) {
        return (
            <AccessDenied message="Nie masz uprawnień dostępu do panelu opiekunki" />
        )
    }

    return (
        <ListLayout
            title={
                !isDesktop
                    ? !currentOperationId
                        ? 'Zadania'
                        : undefined
                    : 'Zadania'
            }
            actions={
                !isDesktop ? (
                    !currentOperationId ? (
                        <Link to="/surgeries">Lista operacji</Link>
                    ) : undefined
                ) : (
                    <Link to="/surgeries">Lista operacji</Link>
                )
            }
        >
            <>
                {!isDesktop ? (
                    !currentOperationId ? (
                        <CaregiverPanelFilters
                            formRef={filtersFormRef}
                            handleChange={handleFilterChange}
                        />
                    ) : undefined
                ) : (
                    <CaregiverPanelFilters
                        formRef={filtersFormRef}
                        handleChange={handleFilterChange}
                    />
                )}
                <CaregiverPanel
                    filters={filters}
                    isCustomDate={isCustomDate}
                    operation={operation}
                    operations={operations}
                    handleFilterDayChange={handleFilterDayChange}
                    currentOperationIndex={currentOperationIndex}
                    currentOperationId={currentOperationId}
                    handleFilterExpiredTasksChange={
                        handleFilterExpiredTasksChange
                    }
                    onOperationCardClose={handleOperationCardClose}
                    onOperationListItemSelect={handleOperationListItemSelect}
                    setCurrentOperationIndex={setCurrentOperationIndex}
                />
            </>
        </ListLayout>
    )
}

export default CaregiverPanelContainer
