import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import {
    useGetAnesthesiologistSchedules,
    useGetApprovedDays,
    usePostApprovedDays,
    useGetOperatingRooms,
    useGetSchedules,
    usePostBlockedDays,
    useDeleteBlockedDays,
    useUpdateScheduleOperation,
    useGetSchedulePlan,
    usePostAnesthesiologistSchedule,
    useSwapScheduleOperations,
} from 'api'
import { OPERATING_ROOM_START_HOUR, API_FORMAT_DATE } from 'constants/index'
import {
    useModal,
    useNotification,
    useScheduleActions,
    useScheduleChannel,
    useSchedulePlannerPolicyGroup,
} from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import { parseDateToFormik } from 'components/forms/DatePickers/utils'
import SchedulePlanner from 'components/SchedulePlanner'
import ScheduleOperationSlideOver from 'components/ScheduleOperationSlideOver'
import SchedulePlannerConfirmModal from 'components/SchedulePlannerConfirmModal'
import SchedulePlannerBlockDayModal from 'components/SchedulePlannerBlockDayModal'
import ScheduleAssignAnesthesiologistModal, {
    AssignAnesthesiologistModalState,
} from 'components/ScheduleAssignAnesthesiologistModal'
import OperationRejectModal from 'components/OperationRejectModal'
import OperationRenewModal from 'components/OperationRenewModal'
import OperationCancelModal from 'components/OperationCancelModal'

import type {
    OnDrop,
    OnDragStart,
    DragItem,
    OnAssignAnesthesiologist,
    OnDragStop,
} from 'types/SchedulePlanner'
import type {
    FormSubmitFn,
    ScheduleFiltersForm,
    ScheduleConfirmModalForm,
    ScheduleBlockDayForm,
    AssignAnesthesiologistForm,
} from 'types'
import type {
    ResponseList,
    ScheduleListMeta,
    ScheduleOperationListItem,
} from 'api/types'
import alertContext from 'contexts/alerts/alertContext'

const SchedulePlannerContainer: React.FC<{
    view: 'hourly' | 'daily'
}> = ({ view }) => {
    const { add: alert } = useContext(alertContext)
    const queryClient = useQueryClient()
    const location = useLocation()
    const showNotification = useNotification()
    const scheduleActions = useScheduleActions('schedule-operation')

    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    const [draggingOperation, setDraggingOperation] = useState<DragItem | null>(
        null
    )
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const [filters, setFilters] = useState<ScheduleFiltersForm>({
        today: moment().toDate(),
        from: moment().startOf('isoWeek').toDate(),
        to: moment().endOf('isoWeek').toDate(),
        procedure_types: [],
        operating_room: undefined,
    })

    const modalScheduleOperation = useModal<number | null>(false)
    const modalScheduleConfirm = useModal(false)
    const modalAssignAnesthesiologist =
        useModal<AssignAnesthesiologistModalState>(false)
    const modalScheduleBlockDay =
        useModal<{ action: 'lock' | 'unlock'; date: Date }>(false)

    const roomsQueryResult = useGetOperatingRooms(
        { pagination: false },
        {
            enabled: schedulePlannerPolicyGroup.canIndex,
        }
    )
    const approvedDaysQueryResult = useGetApprovedDays({ paginated: false })
    const planQueryResult = useGetSchedulePlan(
        draggingOperation?.id!,
        { date: moment(filters.today).format(API_FORMAT_DATE) },
        {
            enabled: schedulePlannerPolicyGroup.canIndex && !!draggingOperation,
        }
    )

    const { mutate: updateOperation, isLoading: isScheduleUpdating } =
        useUpdateScheduleOperation()
    const { mutate: swapOperations, isLoading: isScheduleSwapping } =
        useSwapScheduleOperations()
    const { mutate: lockDay } = usePostBlockedDays()
    const { mutate: unlockDay } = useDeleteBlockedDays()
    const { mutate: approveDay } = usePostApprovedDays()
    const { mutate: assignAnesthesiologist } = usePostAnesthesiologistSchedule()

    const scheduleChannel = useScheduleChannel()

    useEffect(() => {
        const { onEvent, handleEvent, closeChannel } = scheduleChannel.connect()

        onEvent(handleEvent)

        return () => closeChannel()
        // eslint-disable-next-line
    }, [])

    const lastApprovedDay = useMemo(() => {
        if (approvedDaysQueryResult.data) {
            if (approvedDaysQueryResult.data.data.length) {
                return approvedDaysQueryResult.data.data[0].date
            }
        }

        return undefined
    }, [approvedDaysQueryResult.data])

    useEffect(() => {
        if (location.state?.date) {
            const currentDate = location.state.date

            setFilters((prevState) => ({
                ...prevState,
                today: currentDate,
                from: moment(currentDate).startOf('isoWeek').toDate(),
                to: moment(currentDate).endOf('isoWeek').toDate(),
            }))
        }
    }, [location.state])

    const filtersCount = useMemo(
        () =>
            (filters.procedure_types.length ? 1 : 0) +
            (filters.operating_room ? 1 : 0),
        [filters]
    )

    const filtersToApi = useMemo(
        () => ({
            operating_room: filters.operating_room?.id,
            procedure_types: filters.procedure_types.map((item) => item.id),
            from: parseDateToFormik(filters.from),
            to: parseDateToFormik(filters.to),
        }),
        [filters]
    )

    const scheduleQueryResult = useGetSchedules(filtersToApi)
    const anesthesiologistSchedulesQueryResult =
        useGetAnesthesiologistSchedules({
            from: parseDateToFormik(filters.from),
            to: parseDateToFormik(filters.to),
            sort_by: 'from',
            sort_direction: 'asc',
        })

    const handleChangeFilters: FormSubmitFn<ScheduleFiltersForm> = (values) => {
        setFilters(values)
    }

    const handleResetFilters = () => {
        setFilters((prevState) => ({
            ...prevState,
            procedure_types: [],
            operating_room: undefined,
        }))
        setFiltersExpanded(false)
    }

    const handleChangeDate = (date: Date) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            today: date,
            from: moment(date).startOf('isoWeek').toDate(),
            to: moment(date).endOf('isoWeek').toDate(),
        }))
    }

    const handleChangePosition: OnDrop = async (item) => {
        return view === 'daily'
            ? handleChangePositionDaly(item)
            : handleChangePositionHourly(item)
    }

    const handleChangePositionHourly: OnDrop = async (item) => {
        const rooms = roomsQueryResult.data?.data

        if (item.col === item.prev?.col && item.row === item.prev?.row) {
            return
        }

        if (!rooms) {
            return
        }

        if (item.col > 0 && !rooms[item.col - 1]) {
            return
        }

        const data = {
            id: item.id,
            data: {
                estimated_date: moment(item.estimatedDate).format(
                    API_FORMAT_DATE
                ),
                final_operation_date: item.col
                    ? moment(item.estimatedDate)
                          .set({
                              hours: OPERATING_ROOM_START_HOUR,
                              minutes: 0,
                              seconds: 0,
                              millisecond: 0,
                          })
                          .add(item.row * 15, 'minutes')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : '',
                operating_room: item.col ? rooms[item.col - 1].id : null,
            },
        }

        const prevSchedule = queryClient.getQueryData<
            ResponseList<ScheduleOperationListItem[], ScheduleListMeta>
        >(['schedules', filtersToApi])

        const foundIndex = prevSchedule?.data.findIndex(
            (item) => item.id === data.id
        )

        if (foundIndex === -1) {
            return
        }

        const nextData = Object.assign([], prevSchedule!.data, {
            [foundIndex!]: {
                ...prevSchedule!.data[foundIndex!],
                ...data.data,
                optimisticUpdate: true,
                status: 'new',
                operating_room_id: data.data.operating_room,
            },
        }) as ScheduleOperationListItem[]

        const nextSchedule = {
            ...prevSchedule,
            data: ([] as ScheduleOperationListItem[])
                .concat(nextData.filter((item) => !item.final_operation_date))
                .concat(
                    nextData
                        .filter((item) => !!item.final_operation_date)
                        .sort((a, b) =>
                            a.final_operation_date! > b.final_operation_date!
                                ? 1
                                : -1
                        )
                ),
        }

        queryClient.setQueryData(['schedules', filtersToApi], nextSchedule)

        updateOperation(data, {
            onSuccess: () => {
                showNotification({
                    content: 'Plan operacyjny zaktualizowany',
                    type: 'success',
                })
                queryClient.invalidateQueries(['schedules', filtersToApi])
                queryClient.resetQueries(['schedules', item.id])
                queryClient.resetQueries(['operations'])
            },
            onError: (error) => {},
        })
    }

    const handleChangePositionDaly: OnDrop = async (item) => {
        const rooms = roomsQueryResult.data?.data

        if (
            item.col === item.prev?.col &&
            (item.row === item.prev?.row || item.row - 1 === item.prev?.row)
        ) {
            return
        }

        if (!rooms) {
            return
        }

        if (item.col > 0 && !rooms[item.col - 1]) {
            return
        }

        const firstAvailableDate =
            item.col > 0
                ? planQueryResult?.data?.data
                      .filter(
                          (plan) =>
                              plan.operating_room_id === rooms[item.col - 1].id
                      )
                      .filter(
                          (item) =>
                              !item.date.endsWith('07:00:00') &&
                              !item.date.endsWith('07:15:00') &&
                              !item.date.endsWith('07:30:00') &&
                              !item.date.endsWith('07:45:00')
                      )?.[0]?.date
                : ''

        if (typeof firstAvailableDate === 'undefined') {
            return
        }

        const data = {
            id: item.id,
            data: {
                estimated_date: moment(item.estimatedDate).format(
                    API_FORMAT_DATE
                ),
                final_operation_date: item.col ? firstAvailableDate : '',
                operating_room: item.col ? rooms[item.col - 1].id : null,
            },
        }

        const prevSchedule = queryClient.getQueryData<
            ResponseList<ScheduleOperationListItem[], ScheduleListMeta>
        >(['schedules', filtersToApi])

        const foundIndex = prevSchedule?.data.findIndex(
            (item) => item.id === data.id
        )

        if (foundIndex === -1) {
            return
        }

        const nextData = Object.assign([], prevSchedule!.data, {
            [foundIndex!]: {
                ...prevSchedule!.data[foundIndex!],
                ...data.data,
                optimisticUpdate: true,
                status: 'new',
                operating_room_id: data.data.operating_room,
            },
        }) as ScheduleOperationListItem[]

        const nextSchedule = {
            ...prevSchedule,
            data: ([] as ScheduleOperationListItem[])
                .concat(nextData.filter((item) => !item.final_operation_date))
                .concat(
                    nextData
                        .filter((item) => !!item.final_operation_date)
                        .sort((a, b) =>
                            a.final_operation_date! > b.final_operation_date!
                                ? 1
                                : -1
                        )
                ),
        }

        queryClient.setQueryData(['schedules', filtersToApi], nextSchedule)

        item.col !== item.prev?.col
            ? updateOperation(data, {
                  onSuccess: () => {
                      showNotification({
                          content: 'Plan operacyjny zaktualizowany',
                          type: 'success',
                      })
                  },
                  onError: (error) => {},
                  onSettled: () => {
                      queryClient.invalidateQueries(['schedules', filtersToApi])
                      queryClient.resetQueries(['schedules', item.id])
                      queryClient.resetQueries(['operations'])
                  },
              })
            : swapOperations(
                  {
                      id: item.id,
                      data: { after_operation: item.afterOperationId || null },
                  },
                  {
                      onSuccess: () => {
                          showNotification({
                              content: 'Plan operacyjny zaktualizowany',
                              type: 'success',
                          })
                      },
                      onError: (error) => {
                          alert({
                              content: error.message || 'Niepoprawne dane',
                              type: 'danger',
                          })
                      },
                      onSettled: () => {
                          queryClient.invalidateQueries([
                              'schedules',
                              filtersToApi,
                          ])
                          queryClient.resetQueries(['schedules', item.id])
                          queryClient.resetQueries(['operations'])
                      },
                  }
              )
    }

    const handleSelectOperation = (id: number) => {
        modalScheduleOperation.setState(id)
        modalScheduleOperation.openModal()
    }

    const handleSuccessOperationChange = () => {
        const id = modalScheduleOperation.getState()
        modalScheduleOperation.closeModal()
        queryClient.invalidateQueries(['schedules', filtersToApi])
        queryClient.resetQueries(['schedules', id])
        queryClient.resetQueries('operations')
        queryClient.invalidateQueries('schedules-plan')
    }

    const handleScheduleConfirm: FormSubmitFn<ScheduleConfirmModalForm> = (
        values,
        formikHelpers
    ) => {
        approveDay(
            {
                from: moment(values.from).format(API_FORMAT_DATE),
                to: moment(values.to).format(API_FORMAT_DATE),
            },
            {
                onSuccess: async () => {
                    formikHelpers.setSubmitting(false)
                    modalScheduleConfirm.closeModal()
                    showNotification({
                        type: 'success',
                        content: `Opublikowano plan operacyjny od: ${moment(
                            values.from
                        ).format('DD.MM')} do: ${moment(values.to).format(
                            'DD.MM'
                        )}`,
                    })
                    await queryClient.invalidateQueries('schedules')
                    await queryClient.invalidateQueries('approved-days')
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleLockDayChange = (action: 'lock' | 'unlock', date: Date) => {
        modalScheduleBlockDay.setState({
            action,
            date,
        })
        modalScheduleBlockDay.openModal()
    }

    const handleLockDaySubmit: FormSubmitFn<ScheduleBlockDayForm> = (
        values,
        formikHelpers
    ) => {
        if (values._action === 'lock') {
            lockDay(
                { date: moment(values.date).format(API_FORMAT_DATE) },
                {
                    onSuccess: async () => {
                        await queryClient.invalidateQueries('schedules')
                        await queryClient.resetQueries('blocked-days')
                        formikHelpers.setSubmitting(false)
                        modalScheduleBlockDay.closeModal()
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }

        if (values._action === 'unlock') {
            unlockDay(moment(values.date).format(API_FORMAT_DATE), {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('schedules')
                    await queryClient.resetQueries('blocked-days')
                    formikHelpers.setSubmitting(false)
                    modalScheduleBlockDay.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                },
            })
        }
    }

    const handleDragStart: OnDragStart = (item) => {
        setDraggingOperation(item)
    }

    const handleDragStop: OnDragStop = () => {
        setDraggingOperation(null)
    }

    const handleAssignAnesthesiologistFormSubmit: FormSubmitFn<
        AssignAnesthesiologistForm
    > = (values, formikHelpers) => {
        assignAnesthesiologist(
            {
                ...values,
                data: values.data
                    .filter((item) => !(!item.user && !item.from && !item.to))
                    .map((item) => ({
                        id: item.id || null,
                        user_id: item.user?.id,
                        operating_room_id: values.operating_room_id,
                        from: item.from?.id
                            ? moment(filters.today).format(API_FORMAT_DATE) +
                              ' ' +
                              item.from.id
                            : undefined,
                        to: item.to?.id
                            ? moment(filters.today).format(API_FORMAT_DATE) +
                              ' ' +
                              item.to.id
                            : undefined,
                    })),
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(
                        'anesthesiologist-schedules'
                    )
                    formikHelpers.setSubmitting(false)
                    modalAssignAnesthesiologist.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleAssignAnesthesiologistClick: OnAssignAnesthesiologist = (
        data
    ) => {
        modalAssignAnesthesiologist.setState({
            ...data,
            date: moment(filters.today).format(API_FORMAT_DATE),
            data: data.data.map((item) => ({
                ...item,
                operating_room_id: data.operatingRoom.id,
                from: {
                    id: moment(item.from).format('HH:mm:00'),
                    name: moment(item.from).format('HH:mm'),
                },
                to: {
                    id: moment(item.to).format('HH:mm:00'),
                    name: moment(item.to).format('HH:mm'),
                },
            })),
        })
        modalAssignAnesthesiologist.openModal()
    }

    const handleReject = () => {
        const id = modalScheduleOperation.getState()
        if (!id) return

        scheduleActions.handleReject(id)
    }

    if (!schedulePlannerPolicyGroup.canIndex) {
        return (
            <AccessDenied message="Nie masz dostępu do przygotowywanego planu operacyjnego" />
        )
    }

    if (roomsQueryResult.isLoading || approvedDaysQueryResult.isLoading) {
        return <Loader />
    }

    if (
        roomsQueryResult.isError ||
        scheduleQueryResult.isError ||
        approvedDaysQueryResult.isError ||
        anesthesiologistSchedulesQueryResult.isError
    ) {
        if (roomsQueryResult.isError) {
            return <div>{roomsQueryResult.error.message}</div>
        }

        if (scheduleQueryResult.isError) {
            return <div>{scheduleQueryResult.error.message}</div>
        }

        if (approvedDaysQueryResult.isError) {
            return <div>{approvedDaysQueryResult.error.message}</div>
        }

        if (anesthesiologistSchedulesQueryResult.isError) {
            return (
                <div>{anesthesiologistSchedulesQueryResult.error.message}</div>
            )
        }
    }

    if (roomsQueryResult.isSuccess && approvedDaysQueryResult.isSuccess) {
        return (
            <>
                <SchedulePlanner
                    view={view}
                    lastApprovedDay={lastApprovedDay}
                    rooms={roomsQueryResult.data.data}
                    planQueryResult={planQueryResult}
                    scheduleQueryResult={scheduleQueryResult}
                    anesthesiologistSchedulesQueryResult={
                        anesthesiologistSchedulesQueryResult
                    }
                    isScheduleUpdating={
                        isScheduleUpdating || isScheduleSwapping
                    }
                    onChangeDate={handleChangeDate}
                    filters={filters}
                    filtersCount={filtersCount}
                    filtersExpanded={filtersExpanded}
                    setFiltersExpanded={setFiltersExpanded}
                    handleResetFilters={handleResetFilters}
                    onConfirmButtonClick={modalScheduleConfirm.openModal}
                    onAssignAnesthesiologist={handleAssignAnesthesiologistClick}
                    onChangeFilters={handleChangeFilters}
                    onSelectOperation={handleSelectOperation}
                    onLockDayChange={handleLockDayChange}
                    onDrop={handleChangePosition}
                    onDragStart={handleDragStart}
                    onDragStop={handleDragStop}
                    draggingItemId={draggingOperation?.id}
                />
                <ScheduleOperationSlideOver
                    modal={modalScheduleOperation}
                    isRejecting={!!scheduleActions.rejectedId}
                    isRenewing={!!scheduleActions.renewedId}
                    onSuccessChange={handleSuccessOperationChange}
                    onReject={handleReject}
                    onRenew={scheduleActions.handleRenew}
                    onCancel={scheduleActions.handleCancel}
                    onClickOutside={
                        !(
                            scheduleActions.modalReject.isOpen ||
                            scheduleActions.modalRenew.isOpen ||
                            scheduleActions.modalCancel.isOpen
                        )
                            ? modalScheduleOperation.closeModal
                            : undefined
                    }
                />
                <SchedulePlannerConfirmModal
                    modal={modalScheduleConfirm}
                    lastApprovedDay={lastApprovedDay}
                    from={
                        lastApprovedDay
                            ? moment(lastApprovedDay).add(1, 'days').toDate()
                            : filters.from
                    }
                    to={filters.to}
                    handleCancel={modalScheduleConfirm.closeModal}
                    handleSubmit={handleScheduleConfirm}
                />
                <SchedulePlannerBlockDayModal
                    modal={modalScheduleBlockDay}
                    onCancel={modalScheduleBlockDay.closeModal}
                    onSubmit={handleLockDaySubmit}
                />
                <ScheduleAssignAnesthesiologistModal
                    modal={modalAssignAnesthesiologist}
                    handleSubmit={handleAssignAnesthesiologistFormSubmit}
                />
                <OperationRejectModal
                    modal={scheduleActions.modalReject}
                    onSubmit={scheduleActions.handleRejectFormSubmit}
                    onCancel={scheduleActions.handleResignRejecting}
                />
                <OperationRenewModal
                    modal={scheduleActions.modalRenew}
                    onSubmit={scheduleActions.handleRenewFormSubmit}
                    onCancel={scheduleActions.handleResignRenewing}
                />
                <OperationCancelModal
                    modal={scheduleActions.modalCancel}
                    onSubmit={scheduleActions.handleCancelFormSubmit}
                    onCancel={scheduleActions.handleResignCancelling}
                />
            </>
        )
    }

    return null
}

export default SchedulePlannerContainer
