import React, { Fragment } from 'react'
import OperationTaskStatus from 'components/OperationTaskStatus'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import type { OperationTaskStatusProps } from 'components/OperationTaskStatus'

export type OperationTaskStatusOption = { label: string; value: string }
export type OperationTaskStatusMenuProps = {
    value: OperationTaskStatusOption['value']
    options: OperationTaskStatusOption[]
    variant?: OperationTaskStatusProps['variant']
    onChange?: (
        newStatus: OperationTaskStatusOption['value'],
        prevStatus: OperationTaskStatusOption['value']
    ) => void
}

export const OperationTaskStatusMenu = ({
    value,
    options,
    variant,
    onChange,
}: React.PropsWithChildren<OperationTaskStatusMenuProps>) => {
    if (options.length === 0) {
        return <OperationTaskStatus variant={variant} status={value} />
    }

    return (
        <Menu as="div" className="relative">
            <Menu.Button>
                <OperationTaskStatus
                    variant={variant}
                    iconRight={<ChevronDownIcon />}
                    status={value}
                />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-20 focus:outline-none">
                    <div className="py-1">
                        {options.map((item) => (
                            <Menu.Item key={item.value}>
                                {({ active }) => (
                                    <span
                                        onClick={() => {
                                            !!onChange &&
                                                onChange(item.value, value)
                                        }}
                                        className={classNames(
                                            'block px-4 py-2 text-sm cursor-pointer',
                                            {
                                                'bg-gray-100 text-gray-900':
                                                    active,
                                                'text-gray-700': !active,
                                            }
                                        )}
                                    >
                                        {item.label}
                                    </span>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
