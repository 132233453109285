import React from 'react'

import { formatDateNoYear, formatTime, getCxFromStyles } from 'helpers'

import styles from './CaregiverSurgeryForm.module.scss'

const DraftAnswers: React.FC<{
    body: string
    date?: string
}> = ({ body, date }) => {
    const cx = getCxFromStyles(styles)

    return (
        <div className={cx('comment', 'note')}>
            <div className="flex space-x-3">
                <div className="min-w-0 flex-1">
                    <div className={cx('body')}>
                        <p
                            className="text-sm text-gray-500 whitespace-pre-line"
                            dangerouslySetInnerHTML={{
                                __html: body,
                            }}
                        ></p>
                    </div>
                </div>
                {!!date && (
                    <div className="flex-shrink-0 flex">
                        <div className="-mt-2 mr-4"></div>
                        <div className="text-xs leading-none font-normal">
                            <span className="text-gray-400">
                                {formatDateNoYear(date)}
                            </span>{' '}
                            <span className="text-gray-600">
                                {formatTime(date)}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DraftAnswers
