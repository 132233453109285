import React, { useMemo } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import classNames from 'classnames'

import Avatar from 'components/Avatar'

import type { SurgicalTeamMember } from 'types'

import 'react-popper-tooltip/dist/styles.css'
import popperStyles from 'components/ui/Tooltip.module.scss'

const UserAvatar: React.FC<{
    onClick?: React.MouseEventHandler<HTMLDivElement>
    data: Pick<
        SurgicalTeamMember,
        'first_name' | 'last_name' | 'avatar_url'
    > & { role?: SurgicalTeamMember['role'] }
    variant?: 'avatar' | 'name' | 'full'
    size?: number
    roleName?: string
    withTooltip?: boolean
    tooltipText?: string
    withBorder?: boolean
}> = ({
    onClick,
    data,
    variant = 'full',
    size = 10,
    withTooltip,
    tooltipText,
    withBorder,
    ...restProps
}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement: 'top-start',
    })

    const roleName = useMemo(
        () => restProps.roleName || data?.role || '',
        [data?.role, restProps.roleName]
    )

    return (
        <div
            className={classNames('flex', {
                'cursor-pointer': !!onClick,
            })}
            onClick={onClick}
        >
            <div ref={setTriggerRef} className="flex-shrink-0 relative">
                <Avatar
                    url={data.avatar_url}
                    abbreviation={
                        data.first_name.charAt(0) + data.last_name.charAt(0)
                    }
                    size={size}
                    withBorder={withBorder}
                />
                {withTooltip && variant !== 'full' && visible && (
                    <div
                        ref={setTooltipRef}
                        {...getTooltipProps({
                            className: classNames(
                                'tooltip-container',
                                popperStyles.tooltip
                            ),
                        })}
                    >
                        <div
                            {...getArrowProps({
                                className: classNames(
                                    'tooltip-arrow',
                                    popperStyles.arrow
                                ),
                            })}
                        />
                        {tooltipText
                            ? tooltipText
                            : `${data.first_name} ${data.last_name}`}
                    </div>
                )}
            </div>
            {variant === 'full' && (
                <div className="ml-3 min-w-0 flex flex-col flex-1 justify-center">
                    <p className="text-sm font-medium">
                        {data.first_name} {data.last_name}
                    </p>
                    {!!roleName && (
                        <p className="text-sm font-normal text-gray-500">
                            {roleName}
                        </p>
                    )}
                </div>
            )}
            {variant === 'name' && (
                <div className="ml-3 min-w-0 flex flex-col flex-1 justify-center">
                    <p className="text-sm">
                        {data.first_name} {data.last_name}
                    </p>
                </div>
            )}
        </div>
    )
}

export default UserAvatar
