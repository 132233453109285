import { usePolicy, useUserPolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ACTIVITY_LOGS_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ACTIVITY_LOGS,
    show: false,
    create: false,
    update: false,
    delete: false,
}

export const useActivityLogPolicy = () => {
    const { user, checkPermissions } = usePolicy()
    const userPolicy = useUserPolicy()

    const resourcePermission = checkPermissions(ACTIVITY_LOGS_PERMISSIONS)

    return {
        user,
        ...resourcePermission,
        canFilter: resourcePermission.canIndex && userPolicy.canIndex,
    }
}
