import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useGetAnesthesiologistCosts } from 'api'
import { listReducer } from 'helpers'
import { useAnesthesiologistCostsPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, Loader } from 'components/ui'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsAnesthesiologistCostList from 'components/SettingsAnesthesiologistCostList'

export default function SettingsAnesthesiologistCostListContainer() {
    const anesthesiologistCostsPolicy = useAnesthesiologistCostsPolicy()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const { isLoading, isSuccess, isError, data, error } =
        useGetAnesthesiologistCosts(filters, {
            enabled: anesthesiologistCostsPolicy.canAll,
        })

    return (
        <SettingsPriceListLayout
            actions={
                anesthesiologistCostsPolicy.canAll && (
                    <ActionButton
                        to="/settings/price-list/anesthesiologist-costs/add"
                        rightIcon={PlusIcon}
                    >
                        Dodaj
                    </ActionButton>
                )
            }
        >
            <>
                {!anesthesiologistCostsPolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania kosztami anestezjologa." />
                )}
                {isLoading && <Loader />}
                {isError && <span>Error: {error?.message}</span>}
                {isSuccess && !!data && (
                    <SettingsAnesthesiologistCostList
                        data={data}
                        setFilters={setFilters}
                    />
                )}
            </>
        </SettingsPriceListLayout>
    )
}
