import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'

import { RADIO_GROUP_YES_NO_OPTIONS, PAYER_TYPE_NFZ } from 'constants/index'
import { useSurgeryFormPayer } from 'hooks'
import { InputPrice, Label } from 'components/ui'
import {
    RadioGroupField,
    RadioGroupDefaultGrid,
    ImplantField,
    DropdownField,
    AdditionalCostField,
} from 'components/forms'
import FinanceSummary from 'components/DoctorSurgeryFormFinanceSummary'

import type { SurgeryForm, PayerDropdownItem } from 'types/SurgeryForm'

const DoctorSurgeryFormFinances: React.FC<{}> = () => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()
    const { payersByProcedure } = useSurgeryFormPayer()

    const total = useMemo(() => {
        return (
            (values.implants.reduce((acc, item) => {
                acc += Number(item.price)
                return acc
            }, 0) +
                values.costs.reduce((acc, item) => {
                    acc += Number(item.price)
                    return acc
                }, 0) +
                (values.payer ? Number(values.payer.price) : 0)) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1)
        )
    }, [values])

    const payers = useMemo(
        () => payersByProcedure(values.procedure),
        [values.procedure, payersByProcedure]
    )

    return (
        <div className="pt-8 lg:pt-12">
            <div className="text-gray-700 sm:mb-16">
                <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-700 lg:divide-gray-200 lg:divide-x">
                    <div className="col-span-1 lg:pr-8">
                        <div className="text-lg leading-6 font-medium text-gray-900">
                            Finansowanie
                        </div>
                        <div className="divide-gray-200 divide-y">
                            <section className="pb-8">
                                <div className="mt-6">
                                    <Label>Płatnik</Label>
                                    <DropdownField<PayerDropdownItem>
                                        name="payer"
                                        items={payers}
                                        disabled={!values.procedure}
                                        handleChange={(value) => {
                                            setFieldValue(
                                                'base_cost',
                                                value.price
                                            )
                                        }}
                                    />
                                </div>
                                <div className="mt-6">
                                    <Label>Cena bazowa</Label>
                                    <InputPrice
                                        value={values.payer?.price || '0.00'}
                                        disabled
                                    />
                                </div>
                            </section>
                            <section className="pb-8">
                                <div className="mt-6 text-lg leading-6 font-medium text-gray-900">
                                    Implanty
                                </div>
                                <div className="mt-6">
                                    <RadioGroupField<boolean>
                                        name="contains_implants"
                                        options={RADIO_GROUP_YES_NO_OPTIONS}
                                        handleChange={(value) => {
                                            !value &&
                                                setFieldValue(
                                                    '_implant_item',
                                                    undefined
                                                ) &&
                                                setFieldValue(
                                                    '_implant_item_phrase',
                                                    ''
                                                ) &&
                                                setFieldValue(
                                                    '_implant_set_item',
                                                    undefined
                                                ) &&
                                                setFieldValue(
                                                    '_implant_set_item_phrase',
                                                    ''
                                                )
                                        }}
                                        disabled={
                                            !values.procedure ||
                                            values.procedure?.implants_required
                                        }
                                        Grid={RadioGroupDefaultGrid}
                                    />
                                </div>
                            </section>
                            <section className="pb-8">
                                <div className="mt-6">
                                    <ImplantField
                                        name="implants"
                                        procedure={values.procedure || null}
                                        disabled={!values.contains_implants}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="col-span-1 lg:pl-8">
                        <div className="text-lg leading-6 font-medium text-gray-900">
                            Koszty dodatkowe
                        </div>
                        <div className="divide-gray-200 divide-y">
                            <section className="pb-8">
                                <div className="mt-6">
                                    <AdditionalCostField name="costs" />
                                </div>
                            </section>
                            <section className="pb-8">
                                <div className="mt-6 text-lg leading-6 font-medium text-gray-900">
                                    Podsumowanie
                                </div>
                                <div className="mt-6">
                                    <div className="shadow-lg">
                                        <FinanceSummary
                                            total={total}
                                            values={values}
                                            disabled={
                                                values.payer?.type ===
                                                PAYER_TYPE_NFZ
                                            }
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoctorSurgeryFormFinances
