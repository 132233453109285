import React from 'react'
import { useField } from 'formik'
import moment from 'moment'

import { API_FORMAT_DATE } from 'constants/index'
import { Label, DatePicker } from 'components/ui'
import type { DatePickerProps } from 'components/ui/DatePickers/DatePicker'

export type DatePickerFieldProps = {
    name: string
    labelText?: string
    hasError?: boolean
} & Pick<
    DatePickerProps,
    | 'placeholder'
    | 'disabled'
    | 'tabIndex'
    | 'captionLayout'
    | 'fromYear'
    | 'toYear'
    | 'onMonthChange'
    | 'disabledBefore'
    | 'disabledAfter'
>

export default function DatePickerField({
    name,
    labelText,
    placeholder,
    disabled,
    captionLayout,
    fromYear,
    toYear,
    disabledBefore,
    disabledAfter,
    onMonthChange,
    hasError,
    tabIndex,
}: DatePickerFieldProps) {
    const [field, meta, fieldHelper] = useField<string>(name)

    const valueDate = moment(field.value, API_FORMAT_DATE, true).isValid()
        ? moment(field.value, API_FORMAT_DATE).toDate()
        : undefined

    const onChange = (date?: Date) => {
        const dateString = date
            ? moment(date, API_FORMAT_DATE).format(API_FORMAT_DATE)
            : ''
        fieldHelper.setValue(dateString)
    }

    return (
        <>
            {!!labelText && <Label htmlFor={field.name}>{labelText}</Label>}
            <DatePicker
                tabIndex={tabIndex}
                value={valueDate}
                placeholder={placeholder}
                disabled={disabled}
                captionLayout={captionLayout}
                fromYear={fromYear}
                toYear={toYear}
                hasError={
                    typeof hasError !== 'undefined' ? hasError : !!meta.error
                }
                onChange={onChange}
                onMonthChange={onMonthChange}
                disabledBefore={disabledBefore}
                disabledAfter={disabledAfter}
            />
            {!!meta.error && (
                <p className="mt-1 text-sm text-red-600">{meta.error}</p>
            )}
        </>
    )
}
