import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useField } from 'formik'
import classNames from 'classnames'

import styles from 'components/ui/InputText.module.scss'

const TextAreaAutosizeField: React.FC<
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
        name: string
        maxRows: number
        invalid?: boolean
    }
> = ({ name, maxRows, className, disabled, style, invalid, ...props }) => {
    const [field] = useField(name)

    return (
        <TextareaAutosize
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            className={classNames(
                styles.root,
                styles.md,
                styles.textarea,
                {
                    [styles.disabled]: disabled,
                    [styles.error]: invalid,
                },
                className
            )}
            disabled={disabled}
            maxRows={maxRows}
            {...props}
        />
    )
}

export default TextAreaAutosizeField
