import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { useNotification } from 'hooks'
import { usePatchOperationEvent, useRestoreScheduleEvent } from 'api'
import ScheduleOperationStatus from 'components/ScheduleOperationStatus'

import type { ScheduleOperationEvent, ScheduleProcedureType } from 'api/types'

const ScheduleOperationStatusContainer: React.FC<{
    operationId: number
    editable: boolean
    isFullWidth: boolean
    event: ScheduleOperationEvent
    procedureType: ScheduleProcedureType
    restoreStatuses: string[]
}> = ({
    editable,
    event,
    procedureType,
    isFullWidth,
    restoreStatuses,
    operationId,
}) => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: update } = usePatchOperationEvent()
    const { mutate: restoreStatus } = useRestoreScheduleEvent()

    const [isMoveStatusForwardLoading, setIsMoveStatusForwardLoading] =
        useState(false)
    const [restoringStatus, setRestoringStatus] = useState<string | null>(null)
    const [buttonFinishNowIsLoading, setButtonFinishNowIsLoading] =
        useState(false)

    const handleUMoveStatusForward = (event: ScheduleOperationEvent) => {
        setIsMoveStatusForwardLoading(true)

        update(
            {
                id: event.id,
                data: {
                    action: findActionForStatus(event.status),
                    change_manually: true,
                },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    await queryClient.invalidateQueries(['schedules'])
                    setIsMoveStatusForwardLoading(false)
                },
                onError: () => {
                    showNotification({
                        content: 'Nie udało się zmienić statusu',
                        type: 'danger',
                    })
                    setIsMoveStatusForwardLoading(false)
                },
            }
        )
    }

    const handleRestoreStatus = (status: string) => {
        setRestoringStatus(status)

        restoreStatus(
            {
                id: operationId,
                data: {
                    status,
                },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    await queryClient.invalidateQueries(['schedules'])
                    setRestoringStatus(status)
                },
                onError: () => {
                    showNotification({
                        content: 'Nie udało się zmienić statusu',
                        type: 'danger',
                    })
                    setRestoringStatus(status)
                },
            }
        )
    }

    const handleFinishNow = (event: ScheduleOperationEvent) => {
        const isStatusForOperatingRoom = [
            'ready_for_cleaning',
            'cleaning',
        ].includes(event.status)

        const action = isStatusForOperatingRoom ? 'skipCleanup' : 'finishNow'

        setButtonFinishNowIsLoading(true)

        update(
            {
                id: event.id,
                data: {
                    action: action,
                    change_manually: true,
                },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operations'])
                    await queryClient.invalidateQueries(['schedules'])
                    setButtonFinishNowIsLoading(false)
                },
                onError: () => {
                    showNotification({
                        content: 'Nie udało się zmienić statusu',
                        type: 'danger',
                    })
                    setButtonFinishNowIsLoading(false)
                },
            }
        )
    }

    return (
        <ScheduleOperationStatus
            event={event}
            isFullWidth={isFullWidth}
            procedureType={procedureType}
            editable={editable}
            isMoveStatusForwardLoading={isMoveStatusForwardLoading}
            restoringStatus={restoringStatus}
            buttonFinishNowIsLoading={buttonFinishNowIsLoading}
            handleUMoveStatusForward={handleUMoveStatusForward}
            handleFinishNow={handleFinishNow}
            handleRestoreStatus={handleRestoreStatus}
            restoreStatuses={restoreStatuses}
        />
    )
}

const findActionForStatus = (status: ScheduleOperationEvent['status']) => {
    if (status === 'awaiting_for_call') {
        return 'callForPatient'
    }

    if (status === 'calling_for_patient') {
        return 'patientInTransfer'
    }

    if (status === 'in_transfer_to_block') {
        return 'admitToOperationBlock'
    }

    if (status === 'admitted_to_operating_block') {
        return 'initiateAnesthesia'
    }

    if (status === 'initiation_of_anesthesia') {
        return 'patientReady'
    }

    if (status === 'patient_anesthetized') {
        return 'startProcedure'
    }

    if (status === 'procedure_started') {
        return 'endProcedure'
    }

    if (status === 'local_procedure_ended') {
        return 'callForPatientPickUp'
    }

    if (status === 'patient_in_recovery_after_procedure') {
        return 'endAnesthesia'
    }

    if (status === 'anesthesia_completed') {
        return 'callForPatientPickUp'
    }

    if (status === 'calling_patient_pickup_from_operating_block') {
        return 'patientReturningFromOperatingBlock'
    }

    if (status === 'calling_patient_pickup_from_operating_room') {
        return 'patientReturningFromOperatingBlock'
    }

    if (status === 'returning_from_operating_block') {
        return 'admitPatientFromOperatingBlock'
    }

    if (status === 'returning_from_local_procedure') {
        return 'admitPatientFromOperatingBlock'
    }

    if (status === 'patient_left_operating_block') {
        return 'patientReturnedToRoom'
    }

    if (status === 'patient_returned_to_room') {
        return ''
    }

    if (status === 'ready_for_cleaning') {
        return 'startCleanup'
    }

    if (status === 'cleaning') {
        return 'endCleanup'
    }

    if (status === 'cleaning_completed') {
        return ''
    }

    return ''
}

export default ScheduleOperationStatusContainer
