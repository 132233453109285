import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'

import { Label } from 'components/ui'
import { AutoSubmitRow } from 'components/forms'

import type { TDoctorPatientListFilter } from 'containers/DoctorPatientList'

const DoctorPatientListFilters: React.FC<{
    filters: TDoctorPatientListFilter
    setFilters: (values: TDoctorPatientListFilter) => void
}> = ({ filters, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => setFilters(values)}
            >
                {({ handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-4 gap-4">
                            <div>
                                <Label>Wyszukaj pacjenta</Label>
                                <div className="shadow-sm rounded-md">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name="query"
                                            className="block w-full pr-10 appearance-none block w-full px-3 py-2 focus:outline-none text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                            autoComplete="off"
                                            placeholder="Imię, Nazwisko, PESEL, Nr dowodu..."
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <SearchIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default DoctorPatientListFilters
