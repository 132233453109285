import { z } from 'zod'

import {
    DropdownItem,
    ProcedureDropdownItem,
    UserDropdownItem,
} from 'components/forms'

export interface StatisticsSurgeryRejectedListFilters {
    length: number
    date_range: {
        from: Date | undefined
        to: Date | undefined
    }
    operator?: UserDropdownItem
    procedure?: ProcedureDropdownItem
    procedure_types?: DropdownItem[]
    procedure_category?: DropdownItem
    payer_type?: DropdownItem
    reject_type?: DropdownItem
}

export const statisticsSurgeryRejectedListFilterSchema = z.object({
    date_range: z.object({
        from: z.string().datetime(),
        to: z.string().datetime(),
    }),
    operator: z
        .object({
            id: z.number(),
            first_name: z.string(),
            last_name: z.string(),
            avatar_url: z.string(),
        })
        .optional(),
    procedure: z.object({ id: z.number(), name: z.string() }).optional(),
    procedure_types: z
        .array(z.object({ id: z.number(), name: z.string() }))
        .optional(),
    procedure_category: z
        .object({ id: z.number(), name: z.string() })
        .optional(),
    payer_type: z.object({ id: z.string(), name: z.string() }).optional(),
    reject_type: z.object({ id: z.string(), name: z.string() }).optional(),
})
