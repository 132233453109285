import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import Pusher from 'pusher-js'

import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'constants/index'
import pusherContext from 'contexts/PusherContext'

import type { PusherContext, SubscribeToChannel } from 'contexts/PusherContext'

const PusherContextProvider: React.FC = ({ children }) => {
    const { Provider } = pusherContext

    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
    const pusherInstance = useRef<Pusher | undefined>(undefined)

    const connectToPusher = useCallback(async () => {
        pusherInstance.current = new Pusher(
            process.env.REACT_APP_PUSHER_APP_KEY || '',
            {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                channelAuthorization: {
                    transport: 'ajax',
                    endpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT || '',
                    headers: {
                        Accept: 'application/json',
                        ContentType: 'application/json',
                        Authorization: `Bearer ${authToken}`,
                    },
                },
            }
        )
    }, [authToken])

    const disconnectFromPusher = useCallback(() => {
        pusherInstance.current?.disconnect()
    }, [])

    const subscribeToChannel: SubscribeToChannel = useCallback(
        ({ channelName, callback, event }) => {
            const channel = pusherInstance.current?.subscribe(channelName)

            channel?.bind('pusher:subscription_succeeded', () => {
                channel.bind(event, callback)
            })

            channel?.bind('pusher:subscription_error', (error: unknown) => {
                // ToDo: Add error flash notification
                // eslint-disable-next-line no-console
                console.error(error)
            })

            return channel
        },
        []
    )

    useEffect(() => {
        authToken ? connectToPusher() : disconnectFromPusher()
    }, [connectToPusher, disconnectFromPusher, authToken])

    const contextValue: PusherContext = useMemo(
        () => ({
            pusher: pusherInstance,
            disconnectFromPusher,
            subscribeToChannel,
        }),
        [disconnectFromPusher, subscribeToChannel]
    )

    return <Provider value={contextValue}>{children}</Provider>
}

export default PusherContextProvider
