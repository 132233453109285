import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

export type TTab = {
    name: string
    to: string
    current: boolean
}

export default function SettingsTabs({ tabs }: { tabs: TTab[] }) {
    return (
        <div>
            <div className="lg:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Wybierz zakładkę
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-600 focus:border-blue-600 sm:text-sm rounded-md"
                    defaultValue={tabs.find((tab) => tab.current)?.name}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden lg:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-6" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <NavLink
                                key={tab.name}
                                to={tab.to}
                                className={({ isActive }) =>
                                    classNames(
                                        isActive
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                    )
                                }
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                {tab.name}
                            </NavLink>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}
