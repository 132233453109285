import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'

import type {
    PostUser,
    UserResponse,
    ResponseData,
    ResponseError,
} from 'api/types'
import type { ResponseList } from 'api/types'

export const getUser = <T>(): Promise<ResponseData<T>> => axios.get('/user')

export const useGetUser = <T = UserResponse>(
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
): UseQueryResult<ResponseData<T>, ResponseError> =>
    useQuery<ResponseData<T>, ResponseError>(['user'], () => getUser<T>(), {
        ...options,
        cacheTime: Infinity,
    })

export const useGetUserById = <T = UserResponse>(
    id: string,
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
): UseQueryResult<ResponseData<T>, ResponseError> =>
    useQuery<ResponseData<T>, ResponseError>(
        ['users', id],
        () => axios.get('/users/' + id),
        options
    )

export const useCreateUser = <
    R1 = PostUser,
    R2 = ResponseData<UserResponse>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.post('/users', data))

export const useUpdateUser = <R1 = PostUser, R2 = ResponseData<UserResponse>>(
    id: string | number
): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.patch('/users/' + id, data))

export const getUsers = <T>(filters?: {}): Promise<T> =>
    axios.get('/users' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetUsers = <T = ResponseList<UserResponse[]>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['users', filters],
        () => getUsers<T>(filters),
        options
    )
