import React from 'react'
import { FieldArray } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import CategoryField from './CategoryField'

import type { DropdownItem } from './interfaces/DropdownItem'

export const CategoryFieldArray: React.FC<{
    name: string
}> = ({ name }) => {
    return (
        <FieldArray
            name={name}
            render={({
                push,
                remove,
                form: { values, errors, setFieldValue, setErrors },
            }) => {
                return (
                    <div>
                        <div className="flex items-center">
                            <div className="flex-grow w-10/12">
                                <CategoryField
                                    name="_category_item"
                                    hasError={name in errors}
                                />
                            </div>
                            <div className="flex-shrink-0 ml-2">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none"
                                    onClick={() => {
                                        if (!values._category_item) {
                                            return
                                        }
                                        push(values._category_item)
                                        setFieldValue(
                                            '_category_item',
                                            undefined
                                        )
                                        setFieldValue(
                                            '_category_item_phrase',
                                            ''
                                        )
                                    }}
                                >
                                    Dodaj
                                    <PlusIcon
                                        className="ml-2 h-3 w-3"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                        {name in errors && (
                            <p className="mt-1 text-sm text-red-600">
                                {errors[name]}
                            </p>
                        )}
                        <div className="mt-6">
                            {(values[name] as DropdownItem[]).map(
                                (p, index) => (
                                    <div key={index}>
                                        <div
                                            className={classNames(
                                                'mb-0.5 p-2 flex items-center justify-between text-gray-900' +
                                                    ' bg-gray-100 leading-5' +
                                                    ' font-normal',
                                                {
                                                    'text-red-500':
                                                        `${name}.${index}.id` in
                                                        errors,
                                                }
                                            )}
                                        >
                                            <span className="text-sm truncate">
                                                {p.name}
                                            </span>
                                            <div
                                                className="ml-2 flex-shrink-0 flex cursor-pointer"
                                                onClick={() => {
                                                    const {
                                                        [`${name}.${index}.id`]:
                                                            removed,
                                                        ...rest
                                                    } = errors
                                                    setErrors(rest)
                                                    remove(index)
                                                }}
                                            >
                                                <span className="text-gray-500 hover:text-gray-700">
                                                    <XIcon className="ml-2 h-5 w-5" />
                                                </span>
                                            </div>
                                        </div>
                                        {`${name}.${index}.id` in errors && (
                                            <div className="mb-1 text-sm text-red-600">
                                                {errors[`${name}.${index}.id`]}
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )
            }}
        />
    )
}
