import React from 'react'
import { Form, Formik } from 'formik'

import { Label, Button } from 'components/ui'
import { ActionBar, TextAreaField } from 'components/forms'

import type { PatientNoteForm as TPatientNoteForm, FormSubmitFn } from 'types'
import type { PatientResponse } from 'api/types'

const PatientNoteForm: React.FC<{
    data: PatientResponse
    handleSubmit: FormSubmitFn<TPatientNoteForm>
}> = ({ data, handleSubmit }) => {
    const initialValues: TPatientNoteForm = {
        id: data.id,
        historical_operations: data.historical_operations || '',
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <div>
                    <Label>Historia leczenia</Label>
                    <TextAreaField name="historical_operations" rows={5} />
                </div>
                <div className="mt-6">
                    <ActionBar
                        rightSide={({ isSubmitting }) => (
                            <Button
                                type="submit"
                                variant="primary"
                                loading={isSubmitting}
                            >
                                Zapisz
                            </Button>
                        )}
                    />
                </div>
            </Form>
        </Formik>
    )
}

export default PatientNoteForm
