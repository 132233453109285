import React, { useCallback, useEffect, useMemo, useState } from 'react'
import omit from 'lodash.omit'
import moment from 'moment/moment'
import { z } from 'zod'

import { useDiets } from 'api'
import {
    API_FORMAT_DATE,
    DEFAULT_LIST_PER_PAGE,
    SESSION_STORAGE_DIET_LIST_FILTER_KEY,
} from 'constants/index'
import { ButtonPrint, FilterButton, Loader } from 'components/ui'
import { AccessDenied } from 'components'
import ListLayout from 'layouts/ListLayout'
import DietListFilters from 'components/DietListFilters'
import DietList from 'components/DietList'
import { useDietPolicy } from 'hooks'

import type { Filters } from 'components/DietListFilters'

const dietListFilterSchema = z.object({
    sort_by: z.literal('estimated_date'),
    sort_direction: z.union([z.literal('asc'), z.literal('desc')]),
    query: z.string(),
    date_range: z.union([
        z.object({
            from: z.string().datetime(),
            to: z.string().datetime(),
        }),
        z.object({
            from: z.undefined(),
            to: z.undefined(),
        }),
    ]),
})

const INIT_FILTERS: Filters = {
    date_range: {
        from: moment().toDate(),
        to: moment().add(7, 'days').toDate(),
    },
    query: '',
    page: 1,
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'estimated_date',
    sort_direction: 'asc',
}

const transformFiltersToApi = (filters: Filters) => {
    return Object.assign(
        {},
        { ...omit(filters, 'date_range') },
        filters.date_range.from && filters.date_range.to
            ? {
                  estimated_date_from: moment(filters.date_range.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.date_range.to).format(
                      API_FORMAT_DATE
                  ),
              }
            : {}
    )
}

const countFilters = (filters: Filters) =>
    (filters.query.length ? 1 : 0) +
    (filters.date_range.from && filters.date_range.to ? 1 : 0)

const sortingState =
    (sort_by: string, sort_direction: string) => (prevState: Filters) => ({
        ...prevState,
        sort_by,
        sort_direction,
        page: 1,
    })

const resetFilterState = () => ({
    ...INIT_FILTERS,
    date_range: {
        from: undefined,
        to: undefined,
    },
})

const DietListContainer = () => {
    const dietPolicy = useDietPolicy()
    const [filtersExpanded, setFiltersExpanded] = useState(true)
    const [filters, setFilters] = useState<Filters>(() => {
        const storedFilters = window.sessionStorage.getItem(
            SESSION_STORAGE_DIET_LIST_FILTER_KEY
        )

        if (storedFilters) {
            try {
                const restoredFilters = JSON.parse(storedFilters)

                const result = dietListFilterSchema.safeParse(restoredFilters)

                if (result.success) {
                    return {
                        ...INIT_FILTERS,
                        ...result.data,
                        date_range:
                            result.data.date_range.from &&
                            result.data.date_range.to
                                ? {
                                      from: new Date(
                                          Date.parse(
                                              result.data.date_range.from
                                          )
                                      ),
                                      to: new Date(
                                          Date.parse(result.data.date_range.to)
                                      ),
                                  }
                                : {
                                      from: undefined,
                                      to: undefined,
                                  },
                    }
                }
            } catch (e) {}
        }

        return INIT_FILTERS
    })

    const filtersCount = useMemo(() => countFilters(filters), [filters])

    const handleResetFilters = () => setFilters(resetFilterState)

    const setSorting = useCallback(
        (sort_by: string, sort_direction: string) => {
            setFilters(sortingState(sort_by, sort_direction))
        },
        [setFilters]
    )

    const dietListQueryResult = useDiets(transformFiltersToApi(filters), {
        enabled: dietPolicy.canIndex,
        keepPreviousData: true,
    })

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_DIET_LIST_FILTER_KEY,
            JSON.stringify(filters)
        )
    }, [filters])

    return (
        <ListLayout
            title="Lista diet"
            actions={
                <>
                    <ButtonPrint />
                    <span className="ml-6">
                        <FilterButton
                            count={filtersCount}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                            filtersExpanded={filtersExpanded}
                            handleReset={handleResetFilters}
                        />
                    </span>
                </>
            }
        >
            <>
                {!dietPolicy.canIndex && (
                    <AccessDenied message="Nie masz uprawnień do wyświetlania listy diet" />
                )}
                {filtersExpanded && (
                    <DietListFilters
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
                {dietListQueryResult.isLoading && <Loader />}
                {dietListQueryResult.isSuccess && (
                    <DietList
                        setSorting={setSorting}
                        dietListQueryResult={dietListQueryResult}
                        filtersCount={1}
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default DietListContainer
