import React, { useMemo, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import {
    countActiveFilters,
    defaultFormImplantFilters,
    transformImplantFiltersToApi,
    useGetImplants,
} from 'api'
import { useImplantPolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import { ActionButton, FilterButton, Loader } from 'components/ui'
import SettingsImplantSetList from 'components/SettingsImplantSetList'
import Filters from './SettingsImplantListFilters'

import type { FormImplantFilters, Implant, ResponseList } from 'api/types'

const initialFilters = defaultFormImplantFilters({ set: true })

export default function SettingsImplantSetListContainer() {
    const implantPolicyGroup = useImplantPolicyGroup()
    const [page, setPage] = useState<number>(1)
    const [filters, setFilters] = useState<FormImplantFilters>(initialFilters)
    const filtersToApi = useMemo(
        () => transformImplantFiltersToApi({ ...filters, page }),
        [filters, page]
    )
    const implants = useGetImplants<ResponseList<Implant[]>>(filtersToApi, {
        enabled: implantPolicyGroup.canAll,
    })
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () =>
            countActiveFilters(filtersToApi, [
                'query',
                'procedures',
                'manufacturer_id',
            ]),
        [filtersToApi]
    )

    return (
        <SettingsPriceListLayout
            actions={
                implantPolicyGroup.canAll && (
                    <>
                        <FilterButton
                            count={filtersCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                        />
                        <span className="ml-4">
                            <ActionButton
                                to="/settings/price-list/implant-sets/add"
                                rightIcon={PlusIcon}
                            >
                                Dodaj
                            </ActionButton>
                        </span>
                    </>
                )
            }
        >
            <>
                {!implantPolicyGroup.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
                )}
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                {implants.isLoading && <Loader />}
                {implants.isError && <div>{implants.error.message}</div>}
                {implants.isSuccess && (
                    <SettingsImplantSetList
                        data={implants.data}
                        filtersCount={filtersCount}
                        setPage={setPage}
                    />
                )}
            </>
        </SettingsPriceListLayout>
    )
}
