import React, { useContext, useMemo } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid'

import { useAnesthesiologistSchedulePolicy, useBlockedDayPolicy } from 'hooks'
import { API_FORMAT_DATE } from 'constants/index'
import { getCxFromStyles } from 'helpers'
import { useGetAnesthesiologistSchedules } from 'api'
import { AssignButton, Button } from 'components/ui'
import { DropdownItem } from 'components/forms'
import UserAvatar from 'components/UserAvatar'
import mediaContext from 'contexts/media/mediaContext'

import styles from './OperatingRoomBar.module.scss'

import type { Moment } from 'moment'
import type { OperatingRoom, ScheduleListMeta } from 'api/types'
import type { OnAssignAnesthesiologist } from 'types/SchedulePlanner'

const OperatingRoomBar: React.FC<{
    rooms: OperatingRoom[]
    columnHour: boolean
    columnUnassigned: boolean
    editable: boolean
    date: Moment
    meta?: ScheduleListMeta
    anesthesiologistSchedulesQueryResult?: ReturnType<
        typeof useGetAnesthesiologistSchedules
    >
    onLockDayChange?: (action: 'lock' | 'unlock', date: Date) => void
    onAssignAnesthesiologist?: OnAssignAnesthesiologist
    selectedOperatingRoom?: DropdownItem
}> = ({
    columnHour,
    columnUnassigned,
    editable,
    rooms,
    date,
    meta,
    anesthesiologistSchedulesQueryResult,
    onLockDayChange,
    onAssignAnesthesiologist,
    selectedOperatingRoom,
}) => {
    const cx = getCxFromStyles(styles)

    const { isMobile } = useContext(mediaContext)
    const blockedDayPolicy = useBlockedDayPolicy()

    const [occupancy, isLocked] = useMemo(() => {
        const formattedDate = date.format(API_FORMAT_DATE)

        if (!meta) {
            return [null, null]
        }

        if (!meta.days_informations) {
            return [null, null]
        }

        if (!meta.days_informations[formattedDate]) {
            return [null, null]
        }

        return [
            Math.round(meta.days_informations[formattedDate].day_usage * 100),
            meta.days_informations[formattedDate].is_blocked,
        ]
    }, [date, meta])

    return (
        <div className="divide-x-2 divide-gray-200 flex">
            {columnHour && !isMobile && (
                <div
                    id="schedule-operating-room-bar-column-0"
                    className="flex items-center justify-center py-2 w-10 xl:w-24 bg-gray-200 text-gray-600 text-sm print:w-24 print:text-base print:text-gray-900 print:font-normal"
                >
                    <span>Godzina</span>
                </div>
            )}
            <div
                className="schedule-room-list-container flex-auto grid grid-rows-1 divide-x-2 divide-gray-200"
                style={{
                    gridTemplateColumns: `repeat(${
                        isMobile ? 1 : rooms.length + (columnUnassigned ? 1 : 0)
                    }, minmax(0, 1fr))`,
                }}
            >
                {columnUnassigned && (
                    <div className="flex items-center justify-center py-2 bg-gray-100 text-lg font-medium leading-7 text-gray-500 print:hidden">
                        <span>Zajętość:</span>
                        {(!!occupancy || occupancy === 0) && (
                            <div
                                className={cx(styles.occupancyContainer, {
                                    locked: occupancy > 100 || isLocked,
                                })}
                            >
                                <div className={cx(styles.occupancy)}>
                                    {occupancy}%
                                </div>
                                {editable && (
                                    <div className={styles.lockButtonContainer}>
                                        {blockedDayPolicy.canCreate &&
                                            !isLocked && (
                                                <Button
                                                    variant="tertiary"
                                                    onClick={() =>
                                                        !!onLockDayChange &&
                                                        onLockDayChange(
                                                            'lock',
                                                            date.toDate()
                                                        )
                                                    }
                                                    iconRight={
                                                        <LockOpenIcon className="w-4 h-4" />
                                                    }
                                                >
                                                    Zablokuj
                                                </Button>
                                            )}
                                        {blockedDayPolicy.canDelete &&
                                            isLocked && (
                                                <Button
                                                    variant="danger"
                                                    onClick={() =>
                                                        !!onLockDayChange &&
                                                        onLockDayChange(
                                                            'unlock',
                                                            date.toDate()
                                                        )
                                                    }
                                                    iconRight={
                                                        <LockClosedIcon className="w-4 h-4" />
                                                    }
                                                >
                                                    Odblokuj
                                                </Button>
                                            )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {rooms
                    .filter((operatingRoom) =>
                        isMobile && selectedOperatingRoom
                            ? operatingRoom.id === selectedOperatingRoom?.id
                            : true
                    )
                    .map((operatingRoom) => (
                        <div
                            key={operatingRoom.id}
                            className="grid grid-cols-1 text-center divide-y divide-gray-200"
                        >
                            <div className="py-1 text-xl print:font-normal bg-gray-50">
                                {operatingRoom.name}
                            </div>
                            <div className="py-1 flex items-center justify-center h-9">
                                <AnesthesiologistList
                                    editable={editable}
                                    date={date}
                                    operatingRoom={operatingRoom}
                                    queryResult={
                                        anesthesiologistSchedulesQueryResult
                                    }
                                    onAssignAnesthesiologist={
                                        onAssignAnesthesiologist
                                    }
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

const AnesthesiologistList: React.FC<{
    editable: boolean
    date: Moment
    operatingRoom: OperatingRoom
    queryResult?: ReturnType<typeof useGetAnesthesiologistSchedules>
    onAssignAnesthesiologist?: OnAssignAnesthesiologist
}> = ({
    editable,
    date,
    operatingRoom,
    queryResult,
    onAssignAnesthesiologist,
}) => {
    const anesthesiologistSchedulePolicy = useAnesthesiologistSchedulePolicy()

    if (!queryResult) {
        return null
    }

    if (!queryResult.isSuccess) {
        return null
    }

    const data = queryResult.data.data.filter(
        (item) =>
            item.operating_room_id === operatingRoom.id &&
            moment(date.format(API_FORMAT_DATE)).isSame(
                moment(item.from).format(API_FORMAT_DATE)
            )
    )

    return (
        <div
            className={classNames('flex', {
                'cursor-pointer': editable,
            })}
            onClick={() =>
                anesthesiologistSchedulePolicy.canCreate &&
                !!onAssignAnesthesiologist &&
                onAssignAnesthesiologist({
                    date,
                    operatingRoom,
                    data,
                })
            }
        >
            {data.map((item, index) => (
                <div
                    key={index}
                    className={classNames('flex items-center', {
                        'ml-2': index > 0,
                    })}
                >
                    <UserAvatar
                        data={item.user}
                        size={5}
                        variant="avatar"
                        withTooltip
                        tooltipText={`${item.user.first_name} ${
                            item.user.last_name
                        } ${moment(item.from).format('HH:mm')}-${moment(
                            item.to
                        ).format('HH:mm')}`}
                    />
                    <div className="ml-1">
                        <p className="text-sm font-medium leading-5">
                            {moment(item.from).format('HH:mm')}
                        </p>
                    </div>
                </div>
            ))}
            {editable &&
                anesthesiologistSchedulePolicy.canCreate &&
                data.length === 0 && (
                    <div className="ml-3">
                        <AssignButton size="sm" />
                    </div>
                )}
        </div>
    )
}

export default OperatingRoomBar
