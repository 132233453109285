import React from 'react'
import { useField } from 'formik'

import AllowDocumentsRadioGroup from './AllowDocumentsRadioGroup'

const AllowDocumentsField: React.FC<{
    name: string
}> = ({ name }) => {
    const [field, , helpers] = useField(name)

    return (
        <AllowDocumentsRadioGroup
            value={field.value}
            handleChange={(value) => helpers.setValue(value)}
        />
    )
}

export default AllowDocumentsField
