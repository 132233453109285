import React from 'react'

import { getProcedures } from 'api'
import { SearchableDropdownField } from 'components/forms'

export type ProcedureDropdownItem = {
    id: number
    name: string
}

export const ProcedureField: React.FC<{
    name: string
    label?: string
    placeholder?: string
    onChange?: (value: ProcedureDropdownItem) => void
    onRemove?: () => void
    hasError?: boolean
    disabled?: boolean
    queryFilters?: {}
}> = ({
    label,
    name,
    placeholder,
    onChange: handleChange,
    onRemove: handleRemove,
    hasError,
    disabled,
    queryFilters,
}) => {
    return (
        <SearchableDropdownField<ProcedureDropdownItem>
            label={label}
            name={name}
            placeholder={placeholder}
            queryFieldName="name"
            queryFn={getProcedures}
            queryFilters={queryFilters}
            hasError={hasError}
            renderOption={({ name }) => (
                <p className="text-sm leading-5">{name}</p>
            )}
            renderOptions={(options) => (
                <div>
                    <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                        Podpowiedzi
                    </div>
                    {options}
                </div>
            )}
            handleChange={handleChange}
            handleRemove={handleRemove}
            messageEmptyResult="Nie znaleziono operacji."
            disabled={disabled}
        />
    )
}

export default ProcedureField
