import {
    usePolicy,
    useProcedurePolicy,
    useProcedureCategoryPolicy,
} from 'hooks'

export const useProcedureDurationsPolicyGroup = () => {
    const { user } = usePolicy()
    const procedurePolicy = useProcedurePolicy()
    const procedureCategoryPolicy = useProcedureCategoryPolicy()

    return {
        user,
        canAll:
            procedurePolicy.canIndex &&
            procedurePolicy.canUpdate &&
            procedureCategoryPolicy.canIndex,
    }
}
