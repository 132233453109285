import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetPatient } from 'api'
import { useModal } from 'hooks'
import ListLayout from 'layouts/ListLayout'
import { Label, Loader, DoubleSidedToggle } from 'components/ui'
import PatientNote from 'containers/PatientNote'
import DoctorPatientSurgeries from 'components/DoctorPatientSurgeries'
import ScheduleOperationSlideOver from 'components/ScheduleOperationSlideOver'

import type { TTab } from 'components/ListLayoutTabs'
import { twMerge } from 'tailwind-merge'

const tabs: TTab[] = [
    {
        name: 'Pacjent',
        to: '/patients/:id/stats',
        current: false,
    },
    {
        name: 'Dane pacjenta',
        to: '/patients/:id/edit',
        current: true,
    },
    {
        name: 'Operacje i zabiegi',
        to: '/patients/:id/surgeries',
        current: false,
    },
    { name: 'Dokumenty', to: '/patients/:id/documents', current: false },
]

const DoctorPatientSurgeriesContainer: React.FC = () => {
    const { id } = useParams()

    const [checked, setChecked] = useState(false)

    const patient = useGetPatient(id, { enabled: !!id })

    const modalOperationDetails = useModal(false)

    const handleSelectOperation = (id: number) => {
        modalOperationDetails.setState(id)
        modalOperationDetails.openModal()
    }

    if (patient.isLoading) {
        return <Loader />
    }

    if (patient.isError) {
        return <div>{patient.error.message}</div>
    }

    return (
        <>
            <ListLayout
                title={id ? 'Karta pacjenta' : 'Dodaj pacjenta'}
                id={id}
                tabs={id ? tabs : null}
            >
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <div className="rounded-md bg-white px-6 py-6">
                        <div className="flex items-center justify-between">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                Podsumowanie
                            </h2>
                            <div className="flex items-center">
                                <Label
                                    className={twMerge(
                                        'pr-3',
                                        !checked && 'text-blue-600'
                                    )}
                                >
                                    Wykonane
                                </Label>
                                <DoubleSidedToggle
                                    checked={checked}
                                    handleChange={() =>
                                        setChecked((checked) => !checked)
                                    }
                                />
                                <Label
                                    className={twMerge(
                                        'pl-3',
                                        checked && 'text-blue-600'
                                    )}
                                >
                                    Zrzucone
                                </Label>
                            </div>
                        </div>

                        <DoctorPatientSurgeries
                            data={patient.data}
                            rejected={checked}
                            onSelect={handleSelectOperation}
                        />
                    </div>
                    <div className="rounded-md bg-white px-6 py-6">
                        <PatientNote patient={patient} />
                    </div>
                </div>
            </ListLayout>
            <ScheduleOperationSlideOver
                editable={false}
                modal={modalOperationDetails}
                onSuccessChange={modalOperationDetails.closeModal}
                onClickOutside={modalOperationDetails.closeModal}
            />
        </>
    )
}

export default DoctorPatientSurgeriesContainer
