import { useSettingsMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const SettingsMenuItemGate: MenuItemGate = ({ children }) => {
    const settingsMenuItemPolicyGroup = useSettingsMenuItemPolicyGroup()

    if (settingsMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
