import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'

import { useGetSchedulePlan } from 'api'

import type { ScheduleOperationForm } from 'types'
import type { OperatingRoom } from 'api/types'
import type { DropdownItem } from 'components/forms'

const ScheduleOperationFormPlanContainer: React.FC<{
    id: number
    enabled: boolean
    rooms: OperatingRoom[]
    hours: DropdownItem[]
    children: ({
        filteredRooms,
        filteredHours,
    }: {
        filteredRooms: OperatingRoom[]
        filteredHours: DropdownItem[]
    }) => JSX.Element
}> = ({ id, enabled, rooms, hours, children }) => {
    const { values } = useFormikContext<ScheduleOperationForm>()

    const plan = useGetSchedulePlan(
        id,
        {
            date: values.estimated_date,
        },
        { enabled: enabled }
    )

    const filteredRooms = useMemo(() => {
        if (!plan.isSuccess) {
            return []
        }

        return rooms.filter((item) =>
            plan.data.data
                .map((item) => item.operating_room_id)
                .filter((value, index, self) => self.indexOf(value) === index)
                .includes(item.id)
        )
    }, [plan, rooms])

    const filteredHours = useMemo(() => {
        if (!plan.isSuccess) {
            return []
        }

        if (!values.operating_room) {
            return []
        }

        return hours.filter((item) =>
            plan.data.data
                .filter(
                    (item) =>
                        item.operating_room_id === values.operating_room!.id
                )
                .map((item) => item.date)
                .map((date) => date.split(' ')[1])
                .includes(item.id as string)
        )
    }, [plan.isSuccess, plan.data, values.operating_room, hours])

    return children({
        filteredRooms,
        filteredHours,
    })
}

export default ScheduleOperationFormPlanContainer
