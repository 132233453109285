import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const APPROVED_DAY_PERMISSIONS = {
    index: PERMISSIONS.INDEX_APPROVED_DAYS,
    show: false,
    create: PERMISSIONS.CREATE_APPROVED_DAYS,
    update: false,
    delete: false,
}

export const useApprovedDayPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(APPROVED_DAY_PERMISSIONS),
    }
}
