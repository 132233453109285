import { usePermissionMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const PermissionMenuItemGate: MenuItemGate = ({ children }) => {
    const permissionMenuItemPolicyGroup = usePermissionMenuItemPolicyGroup()

    if (permissionMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
