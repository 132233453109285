import React, { useContext, useState } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import moment from 'moment'
import classNames from 'classnames'

import { isPatientName, displayPatientName, forOperationDate } from 'helpers'
import mediaContext from 'contexts/media/mediaContext'
import UserAvatar from 'components/UserAvatar'
import ScheduleOperationSurgicalTeamList from 'components/ScheduleOperationSurgicalTeamList'
import ScheduleOperationTooltip from 'components/ScheduleOperationTooltip'

import type { ScheduleOperationListItem } from 'api/types'

const Event = React.forwardRef<
    HTMLDivElement | null,
    {
        item: ScheduleOperationListItem
        colStart: number
        col?: number
        isDraggable?: boolean
        isDragging?: boolean
        displayPreferredBeginningHour?: boolean
        colored?: boolean
    }
>(
    (
        {
            colStart,
            col = 1,
            displayPreferredBeginningHour,
            colored,
            isDragging,
            isDraggable,
            item,
        },
        ref
    ) => {
        const [clicked, setClicked] = useState(false)

        const isAnonymous = !item.can_see_details
        const isRejected = item.status === 'rejected'
        const isCanceled = item.status === 'canceled'
        const isScheduled = item.is_scheduled
        const isAccepted = item.accepted

        const { isDesktop } = useContext(mediaContext)
        const popperTooltip = usePopperTooltip(
            { placement: 'auto' },
            { strategy: 'fixed' }
        )

        return (
            <div
                ref={ref}
                onMouseDown={() => {
                    setClicked(true)
                }}
                onMouseEnter={() => {
                    setClicked(false)
                }}
                className={classNames(
                    'schedule-event px-2 absolute inset-y-1 inset-x-2 flex flex-1 flex-col rounded print:border-gray-400 print:border-solid print:shadow-none',
                    {
                        'px-1 xl:pl-5 xl:pr-1.5': colored,
                        'py-1.5': col > 1,
                        'opacity-50': isDragging,
                        'border-dashed': !isScheduled,
                        'border border-gray-900 border-opacity-10': !isCanceled,
                        'bg-red-100':
                            !isCanceled &&
                            !isAnonymous &&
                            !isRejected &&
                            item.progress.percent === 0,
                        'bg-yellow-100':
                            !isCanceled &&
                            !isAnonymous &&
                            !isRejected &&
                            item.progress.percent > 0,
                        'bg-green-100':
                            !isCanceled && !isAnonymous && isAccepted,
                        'bg-gray-100':
                            (!isCanceled && !isAnonymous && isRejected) ||
                            (!isCanceled && isAnonymous),
                        'text-gray-900': !isCanceled,
                        'bg-gray-500 text-white': isCanceled,
                        'hover:shadow-lg': colored && !isAnonymous,
                        'cursor-pointer': !isDraggable && !isAnonymous,
                        'cursor-move': isDraggable,
                    }
                )}
            >
                <div
                    className={classNames('flex items-center justify-between', {
                        'flex-1': col === 1,
                    })}
                >
                    <div className="text-sm leading-4 space-x-1.5 whitespace-nowrap truncate">
                        {!isCanceled &&
                            !colored &&
                            !!item.estimated_duration && (
                                <span className="text-xs text-gray-500">
                                    {item.estimated_duration}
                                </span>
                            )}
                        {displayPreferredBeginningHour &&
                            !!item.preferred_beginning_hour && (
                                <span className="font-medium">
                                    {item.preferred_beginning_hour}
                                </span>
                            )}
                        {!!item.final_operation_date && (
                            <span className={classNames('font-medium')}>
                                {moment(item.final_operation_date).format(
                                    'HH:mm'
                                )}
                                {colored &&
                                    !!item.finish_at &&
                                    `-${moment(item.finish_at).format(
                                        'HH:mm'
                                    )}`}
                            </span>
                        )}
                        {!!item.patient && (isDesktop || isCanceled) && (
                            <span className="font-medium">
                                {isPatientName(item.patient)
                                    ? displayPatientName(item.patient)
                                    : '-'}
                            </span>
                        )}
                        {!!item.patient && (
                            <span className="text-xs font-medium">
                                (
                                {item.patient.age || item.patient.age === 0
                                    ? item.patient.age
                                    : '-'}
                                )
                            </span>
                        )}
                    </div>
                    <div className="flex items-center text-xs leading-none space-x-1">
                        {item.admission_date && (
                            <span
                                className={classNames('print:text-gray-900', {
                                    'text-gray-600': !isCanceled,
                                })}
                            >
                                {forOperationDate(item.estimated_date).isDZ(
                                    item.admission_date
                                ) ? (
                                    <span>DZ</span>
                                ) : forOperationDate(item.estimated_date).isDW(
                                      item.admission_date
                                  ) ? (
                                    <span>DW</span>
                                ) : (
                                    <span>
                                        {moment(item.admission_date).format(
                                            'DD.MM'
                                        )}
                                    </span>
                                )}
                                <span className="pl-0.5">
                                    {moment(item.admission_date).format(
                                        'HH:mm'
                                    )}
                                </span>
                            </span>
                        )}
                        <span
                            className={classNames(
                                'px-1 py-0.5 bg-gray-500 text-white rounded-sm print:border print:bg-white print:border-gray-400 print:text-gray-900'
                            )}
                        >
                            {item.room || '-'}
                        </span>
                    </div>
                </div>
                {!!item.patient && !isDesktop && !isCanceled && (
                    <span className="text-sm font-medium mt-0.5">
                        {isPatientName(item.patient)
                            ? displayPatientName(item.patient)
                            : '-'}
                    </span>
                )}
                {!isCanceled && (
                    <>
                        {col > 1 && (
                            <div className="mt-1.5">
                                <p
                                    className={classNames(
                                        'flex items-center text-sm leading-5 print:text-gray-800',
                                        {
                                            'text-gray-500': !isCanceled,
                                        }
                                    )}
                                >
                                    <span
                                        className="truncate"
                                        ref={popperTooltip.setTriggerRef}
                                    >
                                        <span
                                            className="inline-block w-2.5 h-2.5 rounded-full mt-px mr-2 print:hidden"
                                            style={{
                                                backgroundColor: item.color,
                                            }}
                                            aria-hidden="true"
                                        />
                                        {item.name || '-'}
                                    </span>
                                </p>
                            </div>
                        )}
                        {col > 2 && (
                            <div className="mt-1.5 leading-none">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {!!item.operator && (
                                            <UserAvatar
                                                data={item.operator}
                                                size={6}
                                                variant="avatar"
                                            />
                                        )}
                                        {!item.operator && (
                                            <div className="flex w-6 h-6 bg-gray-500 rounded-full text-white text-sm leading-6 items-center justify-center print:text-gray-800">
                                                -
                                            </div>
                                        )}
                                    </div>
                                    <div className="ml-2 min-w-0 flex-1">
                                        <span
                                            className={classNames(
                                                'text-sm leading-5 print:text-gray-800',
                                                {
                                                    'text-gray-600':
                                                        !isCanceled,
                                                }
                                            )}
                                        >
                                            {!!item.operator &&
                                                item.operator.first_name}{' '}
                                            {!!item.operator &&
                                                item.operator.last_name}
                                            {!item.operator && (
                                                <span>
                                                    Nieprzypisany operator
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!!item.surgical_team && col > 3 && (
                            <div className="mt-1.5 flex">
                                <ScheduleOperationSurgicalTeamList
                                    items={item.surgical_team}
                                    roles={[
                                        'assistant',
                                        'anesthesiologist',
                                        'anesthesiologist_nurse',
                                        'operation_nurse',
                                    ]}
                                />
                            </div>
                        )}
                        <ScheduleOperationTooltip
                            equipment={item.equipment}
                            implants={item.implants}
                            isVisible={popperTooltip.visible && !clicked}
                            setTooltipRef={popperTooltip.setTooltipRef}
                            getTooltipProps={popperTooltip.getTooltipProps}
                            getArrowProps={popperTooltip.getArrowProps}
                            surgicalTeam={item.surgical_team}
                        />
                    </>
                )}
            </div>
        )
    }
)

export default Event
