import React from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useUpdateUser } from 'api'
import { useCurrentUser, useNotification } from 'hooks'
import ProfileEdit from 'components/ProfileEdit'
import { Button } from 'components/ui'
import ListLayout from 'layouts/ListLayout'

import { FormSubmitFn, ProfileForm } from 'types'

const ProfileEditContainer = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const user = useCurrentUser()

    const { mutate: update } = useUpdateUser(user!.id)

    const handleSubmit: FormSubmitFn<ProfileForm> = (values, formikHelpers) => {
        update(values, {
            onSuccess: async () => {
                await queryClient.invalidateQueries('user')
                formikHelpers.resetForm()
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany zostały zapisane',
                    type: 'success',
                })
                navigate(`/profile`)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <ListLayout
            title="Profil"
            actions={
                <>
                    <Button
                        as="link"
                        to="/profile"
                        variant="default"
                        size="md"
                        className="ml-2"
                    >
                        Anuluj
                    </Button>
                </>
            }
        >
            <ProfileEdit user={user!} handleSubmit={handleSubmit} />
        </ListLayout>
    )
}

export default ProfileEditContainer
