import React, { useMemo } from 'react'
import { StatisticsOperationResponse } from 'api/types'
import { PAYER_TYPE_NFZ } from '../constants'

const sum = <T extends { price: number | string }>(items: T[]) =>
    items.reduce((acc, item) => {
        acc += Number(item.price)
        return acc
    }, 0)

const StatisticsSurgeryEstimatedCost: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const implantTotal = useMemo(
        () =>
            sum(data.implants || []) *
            (data.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [data.payer, data.implants]
    )

    const additionalCostTotal = useMemo(
        () =>
            sum(data.costs || []) *
            (data.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [data.payer, data.costs]
    )

    const advanceTotal = useMemo(
        () =>
            sum(data.advance || []) *
            (data.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [data.payer, data.advance]
    )

    const total = useMemo(
        () =>
            ((data.base_cost && !isNaN(Number(data.base_cost))
                ? Number(data.base_cost)
                : 0) +
                implantTotal +
                additionalCostTotal) *
            (data.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [implantTotal, additionalCostTotal, data.base_cost, data.payer]
    )

    return (
        <div>
            <div className="text-lg leading-6 font-medium text-gray-900">
                Szacunkowy koszt zabiegu
            </div>

            <div className="mt-8 p-6 bg-white rounded-lg shadow-lg flex">
                <div className="w-1/5 flex-shrink-0 flex flex-col justify-between gap-5 p-4">
                    <div className="text-5xl leading-none font-normal text-gray-900">
                        {total}
                        <span className="text-2xl leading-8 font-medium text-gray-500 pl-1">
                            zł
                        </span>
                    </div>
                    <div className="text-lg leading-7 font-normal text-gray-500">
                        Koszt zabiegu
                    </div>
                </div>

                <div className="w-1/5 flex-shrink-0 flex flex-col justify-between gap-5 p-4">
                    <div className="text-5xl leading-none font-normal text-gray-900">
                        {implantTotal}
                        <span className="text-2xl leading-8 font-medium text-gray-500 pl-1">
                            zł
                        </span>
                    </div>
                    <div className="text-lg leading-7 font-normal text-gray-500">
                        Implanty
                    </div>
                </div>

                <div className="w-1/5 flex-shrink-0 flex flex-col justify-between gap-5 p-4">
                    <div className="text-5xl leading-none font-normal text-gray-900">
                        {additionalCostTotal}
                        <span className="text-2xl leading-8 font-medium text-gray-500 pl-1">
                            zł
                        </span>
                    </div>
                    <div className="text-lg leading-7 font-normal text-gray-500">
                        Koszty dodatkowe
                    </div>
                </div>

                <div className="w-1/5 flex-shrink-0 flex flex-col justify-between gap-5 p-4">
                    <div className="text-5xl leading-none font-normal text-gray-900">
                        {advanceTotal}
                        <span className="text-2xl leading-8 font-medium text-gray-500 pl-1">
                            zł
                        </span>
                    </div>
                    <div className="text-lg leading-7 font-normal text-gray-500">
                        Suma zaliczek
                    </div>
                </div>

                <div className="w-1/5 flex-shrink-0 flex flex-col justify-between gap-5 bg-gray-100 p-4 rounded-lg">
                    <div className="text-5xl leading-none font-extrabold tracking-tight text-gray-900">
                        {total - advanceTotal}
                        <span className="text-2xl leading-8 font-medium text-gray-500 pl-1">
                            zł
                        </span>
                    </div>
                    <div className="text-lg leading-7 font-normal text-gray-500">
                        Bilans
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsSurgeryEstimatedCost
