import React from 'react'

import {
    useGetExpense,
    useCreateExpense,
    useUpdateExpense,
    useDeleteExpense,
} from 'api'
import { PostExpense } from 'api/types'
import { FormSubmitFn, IModal } from 'types'
import { DeleteButton } from 'components/forms'
import SettingsExpenseModal from 'components/SettingsExpenseModal'

const SettingsExpenseModalContainer: React.FC<{
    modal: IModal
    handleSuccess: () => void
}> = ({ modal, handleSuccess }) => {
    const id = modal.getState() as number
    const equipment = useGetExpense(id, { enabled: !!id })
    const { mutate: create } = useCreateExpense()
    const { mutate: update } = useUpdateExpense()
    const deleteEquipment = useDeleteExpense()

    const handleSubmit: FormSubmitFn<PostExpense> = (values, formikHelpers) => {
        if (id) {
            return update(
                {
                    ...values,
                    id,
                },
                {
                    onSuccess: () => {
                        formikHelpers.setSubmitting(false)
                        handleSuccess()
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }

        create(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
                handleSuccess()
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <SettingsExpenseModal
            id={id}
            modal={modal}
            data={equipment.data}
            handleSubmit={handleSubmit}
            buttonDelete={
                <DeleteButton
                    id={id}
                    query={deleteEquipment}
                    handleSuccess={handleSuccess}
                />
            }
        />
    )
}

export default SettingsExpenseModalContainer
