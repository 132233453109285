import React, { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/solid'
import { CalendarIcon } from '@heroicons/react/outline'
import { twMerge } from 'tailwind-merge'

import { useOperationAccountancyPolicy, useSurgeryFormPayer } from 'hooks'
import { formatDateTime, getDurationArray } from 'helpers'
import {
    PREFERRED_BEGINNING_HOUR_ITEMS,
    ANESTHESIA_TYPES,
    PAYER_TYPE_NFZ,
    RADIO_GROUP_YES_NO_OPTIONS,
    STATUSES_ALLOWED_TO_EDIT_OPERATION,
} from 'constants/index'
import {
    ActionBar,
    AdditionalCostField,
    RadioGroupField,
    RadioGroupDefaultGrid,
    AvailableDatePickerField,
    DropdownField,
    EquipmentField,
    ImplantField,
    ProcedureField,
    TextAreaField,
    TextField,
    EmployeePickerField,
    AdvanceField,
} from 'components/forms'
import { Button, Disclosure, InputPrice, Label } from 'components/ui'
import DoctorSurgeryFormPatientField from 'components/DoctorSurgeryFormPatientField'
import DraftAnswers from 'components/DraftAnswers'
import SummaryColumn from 'components/CaregiverSurgeryFormSummaryColumn'
import {
    ProcedureLoader,
    ProcedureDependentFields,
} from 'components/DoctorSurgeryFormMain'
import { AssistantFieldArray } from 'components/ScheduleOperationForm'

import type { ResponseData, OperationResponse } from 'api/types'
import type { SurgeryForm, PayerDropdownItem } from 'types/SurgeryForm'

const sum = <T extends { price: number | string }>(items: T[]) =>
    items.reduce((acc, item) => {
        acc += Number(item.price)
        return acc
    }, 0)

const CaregiverSurgeryForm: React.FC<{
    isPreview?: boolean
    isDraft: boolean
    data: ResponseData<OperationResponse>
    backUrl: string
}> = ({ isDraft, data, backUrl, isPreview }) => {
    const location = useLocation()

    const { errors, values, submitForm, setFieldValue, resetForm } =
        useFormikContext<SurgeryForm>()

    const implantTotal = useMemo(
        () =>
            sum(values.implants) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [values.payer, values.implants]
    )

    const implantUsedTotal = useMemo(
        () =>
            sum(values.implants_used || []) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [values.payer, values.implants_used]
    )

    const additionalCostTotal = useMemo(
        () =>
            sum(values.costs) * (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [values.payer, values.costs]
    )

    const additionalCostResolvedTotal = useMemo(
        () =>
            sum(values.costs_resolved || []) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [values.payer, values.costs_resolved]
    )

    const advanceTotal = useMemo(
        () =>
            sum(values.advance!) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [values.payer, values.advance]
    )

    const total = useMemo(
        () =>
            ((values.base_cost && !isNaN(Number(values.base_cost))
                ? Number(values.base_cost)
                : 0) +
                implantTotal +
                additionalCostTotal) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [implantTotal, additionalCostTotal, values.base_cost, values.payer]
    )

    const finalTotal = useMemo(
        () =>
            ((values.base_cost && !isNaN(Number(values.base_cost))
                ? Number(values.base_cost)
                : 0) +
                implantUsedTotal +
                additionalCostResolvedTotal) *
            (values.payer?.type === PAYER_TYPE_NFZ ? 0 : 1),
        [
            implantUsedTotal,
            additionalCostResolvedTotal,
            values.base_cost,
            values.payer,
        ]
    )

    const { payersByProcedure, updatePayerPrice } = useSurgeryFormPayer()

    const payers = useMemo(
        () => payersByProcedure(values.procedure),
        [values.procedure, payersByProcedure]
    )

    useEffect(() => {
        const payer = updatePayerPrice(values.procedure, values.payer)

        setFieldValue('payer', payer)
        setFieldValue(
            'base_cost',
            data.data.procedure?.id === values.procedure?.id &&
                data.data.payer?.id === payer?.id
                ? data.data.base_cost
                : payer
                ? payer.price
                : 0
        )
        setFieldValue(
            'contains_implants',
            values.procedure?.implants_required
                ? true
                : values.contains_implants
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.procedure])

    const isAllowedToEditOperation = useMemo(
        () => STATUSES_ALLOWED_TO_EDIT_OPERATION.includes(data.data.status),
        [data.data.status]
    )

    const { isAllowed: isOperationAccountancy } =
        useOperationAccountancyPolicy()

    useEffect(() => {
        if (isPreview) {
            resetForm()
        }

        if (!isPreview && isOperationAccountancy && !data.data.implants_used) {
            setFieldValue('implants_used', values.implants)
        }

        if (!isPreview && isOperationAccountancy && !data.data.costs_resolved) {
            setFieldValue('costs_resolved', values.costs)
        }
    }, [isPreview, isOperationAccountancy]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="pt-4 px-4 xl:pt-4 xl:px-6">
                <div className="mb-4 xl:mb-3 text-xs text-right text-gray-500 space-x-1">
                    <span>Dodana przez:</span>
                    <span>
                        {data.data.creator
                            ? data.data.creator.first_name +
                              ' ' +
                              data.data.creator.last_name
                            : '-'}
                    </span>
                    <span>({formatDateTime(data.data.created_at)})</span>
                </div>
                <div className="text-gray-700 divide-gray-200 divide-y">
                    <div className="xl:mb-8">
                        <div className="grid grid-cols-1 xl:grid-cols-2 text-gray-700 xl:divide-gray-200 xl:divide-x">
                            <div className="col-span-1 xl:pr-8">
                                <Disclosure
                                    title="Informacje o pacjencie"
                                    defaultOpen={true}
                                >
                                    <div className="divide-gray-200 divide-y">
                                        <section className="pb-8">
                                            <div className="mt-6">
                                                <DoctorSurgeryFormPatientField
                                                    changePatient={
                                                        !isPreview &&
                                                        isAllowedToEditOperation
                                                    }
                                                    createPatient={
                                                        !isPreview &&
                                                        isAllowedToEditOperation
                                                    }
                                                />
                                            </div>
                                        </section>
                                        <section className="pb-8">
                                            <div className="mt-8">
                                                <Label>Rozpoznanie</Label>
                                                <TextAreaField
                                                    name="diagnosis"
                                                    rows={3}
                                                    disabled={
                                                        isPreview ||
                                                        !isAllowedToEditOperation
                                                    }
                                                />
                                            </div>
                                            <div className="mt-8">
                                                <Label>Znieczulenie</Label>
                                                <DropdownField
                                                    placeholder="Wybierz rodzaj znieczulenia"
                                                    name="anesthesia_type"
                                                    items={ANESTHESIA_TYPES}
                                                    disabled={
                                                        isPreview ||
                                                        !isAllowedToEditOperation
                                                    }
                                                />
                                            </div>
                                            {values.anesthesia_type?.id ===
                                                'with_anesthesiologist' && (
                                                <AnesthesiaDependentFields
                                                    disabled={
                                                        isPreview ||
                                                        !isAllowedToEditOperation
                                                    }
                                                />
                                            )}
                                        </section>
                                    </div>
                                </Disclosure>
                            </div>
                            <div className="col-span-1 xl:pl-8">
                                <div className="divide-gray-200 divide-y">
                                    <Disclosure
                                        title="Informacje o zabiegu"
                                        defaultOpen={true}
                                    >
                                        <div className="divide-gray-200 divide-y">
                                            <section className="pb-8">
                                                <div className="mt-6">
                                                    <Label>Operator</Label>
                                                    <EmployeePickerField
                                                        name="doctor"
                                                        disabled={
                                                            isPreview ||
                                                            !isAllowedToEditOperation
                                                        }
                                                        placeholder="Wyszukaj lekarza"
                                                        functions={[
                                                            'doctor function',
                                                        ]}
                                                        messageEmptyResult="Nie znaleziono lekarza."
                                                        onChange={(user) => {
                                                            setFieldValue(
                                                                'surgical_team',
                                                                [
                                                                    {
                                                                        id: user.id,
                                                                        role: 'doctor',
                                                                    },
                                                                    ...values.surgical_team.filter(
                                                                        (i) =>
                                                                            i.role !==
                                                                            'doctor'
                                                                    ),
                                                                ]
                                                            )
                                                        }}
                                                        handleRemove={() => {
                                                            setFieldValue(
                                                                'surgical_team',
                                                                values.surgical_team.filter(
                                                                    (i) =>
                                                                        i.role !==
                                                                        'doctor'
                                                                )
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div className="mt-6">
                                                    <Label>Asysta</Label>
                                                    <AssistantFieldArray
                                                        disabled={
                                                            isPreview ||
                                                            !isAllowedToEditOperation
                                                        }
                                                    />
                                                </div>
                                                <div className="mt-8">
                                                    <div className="grid grid-cols-6 gap-6">
                                                        <div className="col-span-2">
                                                            <Label>
                                                                Data operacji
                                                            </Label>
                                                            <AvailableDatePickerField
                                                                name="estimated_date"
                                                                placeholder="DD.MM.RRRR"
                                                                disabled={
                                                                    isPreview ||
                                                                    !isAllowedToEditOperation
                                                                }
                                                                disabledBefore={moment().toDate()}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <Label>
                                                                Preferowana pora
                                                            </Label>
                                                            <DropdownField
                                                                name="preferred_beginning_hour"
                                                                items={
                                                                    PREFERRED_BEGINNING_HOUR_ITEMS
                                                                }
                                                                disabled={
                                                                    isPreview ||
                                                                    !isAllowedToEditOperation
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <Label>
                                                                Szacowany czas
                                                            </Label>
                                                            <DropdownField
                                                                name="estimated_duration"
                                                                items={getDurationArray(
                                                                    12,
                                                                    15
                                                                )}
                                                                disabled={
                                                                    isPreview ||
                                                                    !isAllowedToEditOperation
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-8">
                                                        <Label>Operacja</Label>
                                                        <ProcedureField
                                                            name="_procedure_item"
                                                            queryFilters={
                                                                !isDraft
                                                                    ? {
                                                                          procedure_types:
                                                                              [
                                                                                  data
                                                                                      .data
                                                                                      .procedure_type!
                                                                                      .id,
                                                                              ],
                                                                      }
                                                                    : {}
                                                            }
                                                            onChange={() => {
                                                                setFieldValue(
                                                                    'implants',
                                                                    []
                                                                )
                                                            }}
                                                            onRemove={() => {
                                                                setFieldValue(
                                                                    'implants',
                                                                    []
                                                                )
                                                                setFieldValue(
                                                                    'contains_implants',
                                                                    false
                                                                )
                                                            }}
                                                            disabled={
                                                                isPreview ||
                                                                !isAllowedToEditOperation
                                                            }
                                                            hasError={
                                                                !!errors.procedure
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mt-8">
                                                        <ProcedureLoader>
                                                            {({
                                                                procedure,
                                                            }) => (
                                                                <ProcedureDependentFields
                                                                    procedure={
                                                                        procedure
                                                                    }
                                                                />
                                                            )}
                                                        </ProcedureLoader>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="pb-8">
                                                <div className="mt-8">
                                                    <Label>
                                                        Uwagi do zabiegu
                                                    </Label>
                                                    <TextAreaField
                                                        name="description"
                                                        rows={5}
                                                        disabled={
                                                            isPreview ||
                                                            !isAllowedToEditOperation
                                                        }
                                                    />
                                                </div>
                                                {isDraft &&
                                                    !!data.data.draft_form && (
                                                        <div className="mt-8">
                                                            <Label>
                                                                Dodatkowe
                                                                informacje o
                                                                pacjencie i
                                                                zabiegu
                                                            </Label>
                                                            <DraftAnswers
                                                                body={
                                                                    data.data
                                                                        .draft_form
                                                                }
                                                                date={
                                                                    data.data
                                                                        .created_at
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                            </section>
                                        </div>
                                    </Disclosure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="xl:mb-8 pt-8">
                        <div className="grid grid-cols-1 xl:grid-cols-2 text-gray-700 xl:divide-gray-200 xl:divide-x">
                            <div className="col-span-1 xl:pr-8">
                                <div className="divide-gray-200 divide-y">
                                    <Disclosure
                                        title="Finansowanie"
                                        defaultOpen
                                    >
                                        <section className="pb-8">
                                            <div className="mt-8">
                                                <Label>Płatnik</Label>
                                                <DropdownField<PayerDropdownItem>
                                                    name="payer"
                                                    items={payers}
                                                    disabled={
                                                        payers.length === 0 ||
                                                        isPreview ||
                                                        !isAllowedToEditOperation
                                                    }
                                                    handleChange={(value) =>
                                                        setFieldValue(
                                                            'base_cost',
                                                            data.data.procedure
                                                                ?.id ===
                                                                values.procedure
                                                                    ?.id &&
                                                                data.data.payer
                                                                    ?.id ===
                                                                    value.id
                                                                ? data.data
                                                                      .base_cost
                                                                : value.price
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="mt-8">
                                                <Label>Cena bazowa</Label>
                                                <InputPrice
                                                    value={values.base_cost}
                                                    disabled
                                                />
                                            </div>
                                        </section>
                                    </Disclosure>
                                    <Disclosure
                                        className="pt-8"
                                        title="Zaliczki"
                                        defaultOpen={true}
                                    >
                                        <section className="pb-8">
                                            <div className="mt-8">
                                                <AdvanceField
                                                    name="advance"
                                                    editPrice
                                                    disabled={isPreview}
                                                />
                                            </div>
                                        </section>
                                    </Disclosure>
                                </div>
                            </div>
                            <div className="col-span-1 xl:pl-8">
                                <Disclosure title="Sprzęt medyczny" defaultOpen>
                                    <section className="pb-8">
                                        <div className="mt-8">
                                            <EquipmentField
                                                name="equipment"
                                                editPrice
                                                disabled={isPreview}
                                            />
                                        </div>
                                    </section>
                                </Disclosure>
                            </div>
                        </div>
                    </div>
                    <div className="xl:mb-8 pt-8">
                        <div className="grid grid-cols-1 xl:grid-cols-2 text-gray-700 xl:divide-gray-200 xl:divide-x">
                            <div className="col-span-1 xl:pr-8">
                                <div className="divide-gray-200 divide-y">
                                    <Disclosure
                                        title={
                                            isPreview ||
                                            isAllowedToEditOperation ||
                                            (!isAllowedToEditOperation &&
                                                !isOperationAccountancy)
                                                ? 'Koszty dodatkowe'
                                                : 'Koszty dodatkowe rozliczone'
                                        }
                                        defaultOpen
                                    >
                                        <section className="pb-8">
                                            <div className="mt-8">
                                                <AdditionalCostField
                                                    name={
                                                        isPreview ||
                                                        isAllowedToEditOperation ||
                                                        (!isAllowedToEditOperation &&
                                                            !isOperationAccountancy)
                                                            ? 'costs'
                                                            : 'costs_resolved'
                                                    }
                                                    editPrice
                                                    initialData={
                                                        !isPreview &&
                                                        !isAllowedToEditOperation &&
                                                        isOperationAccountancy
                                                            ? data.data
                                                                  .costs_resolved
                                                            : undefined
                                                    }
                                                    disabled={isPreview}
                                                />
                                            </div>
                                        </section>
                                    </Disclosure>
                                </div>
                            </div>
                            <div className="col-span-1 xl:pl-8">
                                {isPreview && !!data.data.costs_resolved && (
                                    <div className="divide-gray-200 divide-y">
                                        <Disclosure
                                            title="Koszty dodatkowe rozliczone"
                                            defaultOpen
                                        >
                                            <section className="pb-8">
                                                <div className="mt-8">
                                                    <AdditionalCostField
                                                        name="costs_resolved"
                                                        editPrice
                                                        disabled
                                                    />
                                                </div>
                                            </section>
                                        </Disclosure>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="xl:mb-8 pt-8">
                        <div className="grid grid-cols-1 xl:grid-cols-2 text-gray-700 xl:divide-gray-200 xl:divide-x">
                            <div className="col-span-1 xl:pr-8">
                                <div className="divide-gray-200 divide-y">
                                    {isPreview ||
                                    isAllowedToEditOperation ||
                                    (!isAllowedToEditOperation &&
                                        !isOperationAccountancy) ? (
                                        <Disclosure
                                            title="Implanty"
                                            defaultOpen
                                        >
                                            <div className="xl:divide-gray-200 xl:divide-y">
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <RadioGroupField<boolean>
                                                            name="contains_implants"
                                                            disabled={
                                                                !values.procedure ||
                                                                values.procedure
                                                                    ?.implants_required ||
                                                                isPreview ||
                                                                !isAllowedToEditOperation
                                                            }
                                                            options={
                                                                RADIO_GROUP_YES_NO_OPTIONS
                                                            }
                                                            Grid={
                                                                RadioGroupDefaultGrid
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <ImplantField
                                                            name="implants"
                                                            disabled={
                                                                !values.contains_implants ||
                                                                isPreview ||
                                                                !isAllowedToEditOperation
                                                            }
                                                            procedure={
                                                                values.procedure ||
                                                                null
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </Disclosure>
                                    ) : (
                                        <Disclosure
                                            title="Implanty użyte"
                                            defaultOpen
                                        >
                                            <div className="xl:divide-gray-200 xl:divide-y">
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <RadioGroupField<boolean>
                                                            name="contains_implants_used"
                                                            options={
                                                                RADIO_GROUP_YES_NO_OPTIONS
                                                            }
                                                            Grid={
                                                                RadioGroupDefaultGrid
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <ImplantField
                                                            name="implants_used"
                                                            disabled={
                                                                !values.contains_implants_used
                                                            }
                                                            procedure={
                                                                values.procedure ||
                                                                null
                                                            }
                                                            initialData={
                                                                data.data
                                                                    .implants_used
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </Disclosure>
                                    )}
                                </div>
                            </div>
                            <div className="col-span-1 xl:pl-8">
                                {isPreview && !!data.data.implants_used && (
                                    <div className="divide-gray-200 divide-y">
                                        <Disclosure
                                            title="Implanty użyte"
                                            defaultOpen
                                        >
                                            <div className="xl:divide-gray-200 xl:divide-y">
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <RadioGroupField<boolean>
                                                            name="contains_implants_used"
                                                            disabled
                                                            options={
                                                                RADIO_GROUP_YES_NO_OPTIONS
                                                            }
                                                            Grid={
                                                                RadioGroupDefaultGrid
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                                <section className="xl:pb-8">
                                                    <div className="mt-8">
                                                        <ImplantField
                                                            name="implants_used"
                                                            disabled
                                                            procedure={
                                                                values.procedure ||
                                                                null
                                                            }
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </Disclosure>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={twMerge('pt-8', isPreview && 'pb-8')}>
                        <div className="text-lg leading-6 font-medium text-gray-900">
                            Podsumowanie
                        </div>
                        <div className="mt-8 shadow-lg rounded-lg">
                            <div className="p-5 xl:p-6">
                                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                                    <SummaryColumn
                                        cost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? finalTotal
                                                : total
                                        }
                                        secondCost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? total
                                                : undefined
                                        }
                                        disabled={
                                            values.payer?.type ===
                                            PAYER_TYPE_NFZ
                                        }
                                        textLine1="Koszt zabiegu"
                                    />
                                    <SummaryColumn
                                        cost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? implantUsedTotal
                                                : implantTotal
                                        }
                                        secondCost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? implantTotal
                                                : undefined
                                        }
                                        disabled={
                                            values.payer?.type ===
                                            PAYER_TYPE_NFZ
                                        }
                                        textLine1="Implanty"
                                    />
                                    <SummaryColumn
                                        cost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? additionalCostResolvedTotal
                                                : additionalCostTotal
                                        }
                                        secondCost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? additionalCostTotal
                                                : undefined
                                        }
                                        disabled={
                                            values.payer?.type ===
                                            PAYER_TYPE_NFZ
                                        }
                                        textLine1="Koszty dodatkowe"
                                    />
                                    <SummaryColumn
                                        cost={advanceTotal}
                                        secondCost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? ''
                                                : undefined
                                        }
                                        disabled={
                                            values.payer?.type ===
                                            PAYER_TYPE_NFZ
                                        }
                                        textLine1="Suma zaliczek"
                                    />
                                    <SummaryColumn
                                        highlight
                                        cost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? finalTotal - advanceTotal
                                                : total - advanceTotal
                                        }
                                        secondCost={
                                            !isAllowedToEditOperation &&
                                            isOperationAccountancy
                                                ? total - advanceTotal
                                                : undefined
                                        }
                                        costRender={(total) => (
                                            <span className="font-semibold">
                                                {total}
                                            </span>
                                        )}
                                        disabled={
                                            values.payer?.type ===
                                            PAYER_TYPE_NFZ
                                        }
                                        textLine1="Bilans"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isPreview && (
                <div className="mx-6 py-12">
                    <ActionBar
                        rightSide={({ isSubmitting }) => (
                            <>
                                <Button
                                    as="link"
                                    to={backUrl}
                                    state={location.state?.state}
                                >
                                    Anuluj
                                </Button>
                                {!isDraft && (
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="ml-3"
                                        loading={isSubmitting}
                                        iconRight={<PlusIcon />}
                                    >
                                        Zapisz
                                    </Button>
                                )}
                                {isDraft && (
                                    <Button
                                        type="submit"
                                        variant="tertiary"
                                        className="ml-3"
                                        loading={
                                            values._isDraftSaving
                                                ? isSubmitting
                                                : false
                                        }
                                        iconRight={<PlusIcon />}
                                        onClick={async () => {
                                            setFieldValue(
                                                '_isDraftSaving',
                                                true,
                                                false
                                            )
                                            await submitForm()
                                        }}
                                    >
                                        Zapisz
                                    </Button>
                                )}
                                {isDraft && (
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="ml-3"
                                        loading={
                                            values._isDraftSaving
                                                ? false
                                                : isSubmitting
                                        }
                                        iconRight={<CalendarIcon />}
                                        onClick={async () => {
                                            setFieldValue(
                                                '_isDraftSaving',
                                                false,
                                                false
                                            )
                                            await submitForm()
                                        }}
                                    >
                                        Dodaj do planu operacyjnego
                                    </Button>
                                )}
                            </>
                        )}
                    />
                </div>
            )}
        </>
    )
}

const AnesthesiaDependentFields: React.FC<{
    disabled?: boolean
}> = ({ disabled }) => {
    return (
        <>
            <div className="mt-8">
                <Label>Sposób znieczulenia</Label>
                <TextAreaField
                    name="anesthesia_info"
                    rows={3}
                    disabled={disabled}
                />
            </div>
            <div className="mt-8">
                <Label>Waga pacjenta</Label>
                <TextField
                    name="weight"
                    trailingAddOn="KG"
                    disabled={disabled}
                />
            </div>
        </>
    )
}

export default CaregiverSurgeryForm
