import React, { useEffect, useState } from 'react'
import { AnnotationIcon } from '@heroicons/react/outline'

import { OPERATION_TASK_STATUS_OPTIONS } from 'constants/index'
import { getCxFromStyles } from 'helpers'
import { OperationTaskStatusMenu } from 'components/forms'
import UserAvatar from 'components/UserAvatar'
import AvatarPlaceholder from 'components/AvatarPlaceholder'
import OperationTaskStatus from 'components/OperationTaskStatus'
import OperationTaskDate from 'components/OperationTaskDate'

import styles from './OperationTaskListItem.module.scss'

import type { OperationTask as IOperationTask } from 'api/types'
import type { TaskStatusTransitionState } from 'components/TaskExpiredModal'

const OperationTaskListItem: React.FC<{
    current: boolean
    item: IOperationTask
    editable?: boolean
    onOpen: (id: number) => void
    onUpdateStatus: (state: TaskStatusTransitionState) => void
}> = ({ current, item, editable, onOpen, onUpdateStatus }) => {
    const cx = getCxFromStyles(styles)

    const [currentStatus, setCurrentStatus] = useState(item.status)

    useEffect(() => setCurrentStatus(item.status), [item.status])

    return (
        <div
            className={cx('root', {
                current,
                [item.deadline!]: !!item.deadline,
                done: item.status === 'done' || item.status === 'ordered',
                rejected: item.status === 'rejected',
            })}
            onClick={() => onOpen(item.id as number)}
        >
            <div className="relative flex flex-col content-between w-full h-full">
                <div className="flex flex-grow space-x-3 pr-6">
                    <h3 className={cx('name')}>{item.name}</h3>
                </div>
                {item.has_comments && (
                    <div className="absolute top-0 right-0">
                        <AnnotationIcon className="w-5 text-gray-400" />
                    </div>
                )}
                <div className="mt-4 flex items-center space-x-4">
                    <div className="flex">
                        {!!item.user && (
                            <UserAvatar
                                variant="avatar"
                                data={item.user}
                                size={6}
                                withBorder
                                withTooltip
                            />
                        )}
                        {!item.user && (
                            <AvatarPlaceholder size={6} withBorder />
                        )}
                    </div>
                    <span className={cx('date')}>
                        <OperationTaskDate date={item.patient_term} />
                    </span>
                    <div
                        className={cx('status')}
                        onClick={(e) => editable && e.stopPropagation()}
                    >
                        {!editable && (
                            <OperationTaskStatus
                                variant="sm"
                                status={item.status}
                            />
                        )}
                        {editable && (
                            <OperationTaskStatusMenu
                                value={currentStatus}
                                variant="sm"
                                options={OPERATION_TASK_STATUS_OPTIONS}
                                onChange={(nextStatus) => {
                                    onUpdateStatus({
                                        item,
                                        nextStatus,
                                    })
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OperationTaskListItem
