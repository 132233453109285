import React, { useState, useMemo } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'

import { useGetBlockedDays } from 'api'
import { API_FORMAT_DATE } from 'constants/index'
import { DatePicker } from 'components/ui'
import { parseDateToFormik } from './utils'

import type { DatePickerFieldProps } from 'components/forms/DatePickers/DatePickerField'

export type AvailableDatePickerFieldProps = {
    name: string
    labelText?: string
    disabledBefore?: Date
    onChange?: (date: Date) => void
} & DatePickerFieldProps

export default function AvailableDatePickerField({
    name,
    labelText,
    placeholder,
    disabled,
    captionLayout,
    fromYear,
    toYear,
    disabledBefore,
    ...props
}: AvailableDatePickerFieldProps) {
    const { values, errors, setFieldValue } = useFormikContext<any>()
    const value = name in values ? new Date(values[name]) : undefined
    const [blockedDays, handleMonthChange] = useBlockedDays(value)

    const onChange = (value?: Date) => {
        if (!value) {
            return
        }

        const date = parseDateToFormik(value)
        setFieldValue(name, date)
        !!props.onChange && props.onChange(value)
    }

    return (
        <DatePicker
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            inputReadOnly
            captionLayout={captionLayout}
            fromYear={fromYear}
            toYear={toYear}
            hasError={!!errors[name]}
            blockedDays={blockedDays}
            disabledBefore={disabledBefore}
            onChange={onChange}
            onMonthChange={handleMonthChange}
            onDatePickerOpen={() => {
                !!value && handleMonthChange(value)
            }}
        />
    )
}

type UseBlockedDays = (date?: Date) => [Date[], (date: Date) => void]

const useBlockedDays: UseBlockedDays = (date) => {
    const [filters, setFilters] = useState(() => {
        return {
            from: date
                ? moment(date).startOf('month').format(API_FORMAT_DATE)
                : '',
            to: date ? moment(date).endOf('month').format(API_FORMAT_DATE) : '',
        }
    })

    const handleMonthChange = (date: Date) => {
        const from = moment(date).startOf('month').format(API_FORMAT_DATE)
        const to = moment(date).endOf('month').format(API_FORMAT_DATE)
        setFilters({ from, to })
    }

    const blockedDays = useGetBlockedDays(
        { from: filters.from, to: filters.to },
        { enabled: !!filters.from && !!filters.to }
    )

    const data = useMemo(
        () =>
            blockedDays.data
                ? blockedDays.data.data.map((item) => new Date(item.date))
                : [],
        [blockedDays.data]
    )

    return [data, handleMonthChange]
}
