import {
    usePolicy,
    useSurgeryPolicy,
    useProcedureTypePolicy,
    usePatientPolicy,
} from 'hooks'

export const useSurgeryListPolicyGroup = () => {
    const { user } = usePolicy()
    const surgeryPolicy = useSurgeryPolicy()
    const patientPolicy = usePatientPolicy()
    const procedureTypePolicy = useProcedureTypePolicy()

    return {
        user,
        canIndex:
            surgeryPolicy.canIndex &&
            patientPolicy.canIndex &&
            procedureTypePolicy.canIndex,
    }
}
