import {
    usePolicy,
    useDoctorSurgeryPolicyGroup,
    useDoctorSurgeryDraftPolicyGroup,
    useSurgeryPolicy,
    useOperationClosurePolicy,
} from 'hooks'

export const useOperationMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const doctorSurgeryPolicy = useDoctorSurgeryPolicyGroup()
    const doctorSurgeryDraftPolicy = useDoctorSurgeryDraftPolicyGroup()
    const surgeryPolicy = useSurgeryPolicy()
    const operationClosurePolicy = useOperationClosurePolicy()

    return {
        user,
        canCreate: surgeryPolicy.canCreate,
        canCreateOther: doctorSurgeryDraftPolicy.canCreate,
        canIndexPerformed: doctorSurgeryPolicy.canIndexPerformed,
        canIndexDraft: doctorSurgeryDraftPolicy.canIndex,
        canCreateClosureTemplate: operationClosurePolicy.canCreate,
        get canAny() {
            return (
                this.canCreate ||
                this.canCreateOther ||
                this.canIndexPerformed ||
                this.canIndexDraft ||
                this.canCreateClosureTemplate
            )
        },
    }
}
