import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import {
    ResponseList,
    ResponseError,
    ResponseData,
    Equipment,
    PostEquipment,
    UpdateEquipment,
} from 'api/types'

export const getEquipmentList = <T>(filters?: {}): Promise<T> =>
    axios.get('/equipment' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetEquipmentList = <T = ResponseList<Equipment[]>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['equipment', filters],
        () => getEquipmentList<T>(filters),
        options
    )

export const useGetEquipment = <T = Equipment, E = ResponseError>(
    id: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['equipment', id],
        () => axios.get(`/equipment/${id}`),
        options
    )

export const createEquipment = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/equipment', data)

export const useCreateEquipment = <
    R1 = PostEquipment,
    R2 = ResponseData<Equipment>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createEquipment<R1, R2>(data))

export const updateEquipment = <R1, R2>(id: number, data: R1): Promise<R2> =>
    axios.patch('/equipment/' + id, data)

export const useUpdateEquipment = <
    R1 extends Pick<UpdateEquipment, 'id'> = Pick<UpdateEquipment, 'id'> &
        Partial<UpdateEquipment>,
    R2 = ResponseData<Equipment>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateEquipment<R1, R2>(data.id, data))

export const deleteEquipment = <R>(id: number): Promise<R> =>
    axios.delete(`/equipment/${id}`)

export const useDeleteEquipment = <R = Equipment>(): UseMutationResult<
    R,
    ResponseError,
    number
> => useMutation((id: number) => deleteEquipment<R>(id))
