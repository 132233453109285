import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import {
    useCreateAnesthesiologistCost,
    useDeleteAnesthesiologistCost,
    useGetAnesthesiologistCost,
    useUpdateAnesthesiologistCost,
} from 'api'
import {
    useAnesthesiologistCostsPolicy,
    useModal,
    useNotification,
} from 'hooks'
import ConfirmDeleteModal from 'components/ConfirmDeleteModal'
import { Loader } from 'components/ui'
import SettingsAnesthesiologistCostForm, {
    type FormValues,
} from 'components/SettingsAnesthesiologistCostForm'

import type { CancelDeleteModalForm, FormSubmitFn } from 'types'
import type {
    AnesthesiologistCostResponse,
    AnesthesiologistCostVariables,
} from 'api/types'
import { AccessDenied } from 'components'

interface AnesthesiologistCostFormProps {
    id?: string
    data?: AnesthesiologistCostResponse
}

export default function SettingsAnesthesiologistCostContainer({
    id,
    data,
}: AnesthesiologistCostFormProps) {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const anesthesiologistCostsPolicy = useAnesthesiologistCostsPolicy()
    const showNotification = useNotification()
    const modalConfirmDelete = useModal<number>()

    const { mutate: create } = useCreateAnesthesiologistCost()
    const { mutate: update } = useUpdateAnesthesiologistCost()
    const { mutate: deleteCost } = useDeleteAnesthesiologistCost()

    const handleSubmit: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        const transformedValues = transformFormToAPIValues(values)

        if (id) {
            return update(
                {
                    id: id,
                    values: transformedValues,
                },
                {
                    onSuccess: async () => {
                        showNotification({
                            content: 'Koszt został zaktualizowany',
                            type: 'success',
                        })
                        await queryClient.invalidateQueries([
                            'anesthesiologist-costs',
                        ])
                        formikHelpers.setSubmitting(false)
                        navigate('/settings/price-list/anesthesiologist-costs')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                        showNotification({
                            content: error.message,
                            type: 'danger',
                        })
                    },
                }
            )
        }

        return create(transformedValues, {
            onSuccess: async () => {
                showNotification({
                    content: 'Koszt został utworzony',
                    type: 'success',
                })
                await queryClient.invalidateQueries(['anesthesiologist-costs'])
                formikHelpers.setSubmitting(false)
                navigate('/settings/price-list/anesthesiologist-costs')
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
                showNotification({
                    content: error.message,
                    type: 'danger',
                })
            },
        })
    }

    const handleDelete = (id: number) => {
        modalConfirmDelete.setState(id)
        modalConfirmDelete.openModal()
    }

    const handleDeleteConfirmed: FormSubmitFn<CancelDeleteModalForm> = (
        values,
        formikHelpers
    ) => {
        const id = modalConfirmDelete.getState()

        if (!id) {
            return false
        }

        deleteCost(
            { id },
            {
                onSuccess: () => {
                    showNotification({
                        content: 'Koszt został usunięty',
                        type: 'success',
                    })
                    modalConfirmDelete.closeModal()
                    queryClient.invalidateQueries(['anesthesiologist-costs'])
                    navigate('/settings/price-list/anesthesiologist-costs')
                },
                onSettled: () => {
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    if (!anesthesiologistCostsPolicy.canAll) {
        return (
            <AccessDenied message="Nie masz uprawnień do zarządzania kosztami anestezjologa." />
        )
    }

    return (
        <>
            <SettingsAnesthesiologistCostForm
                id={id}
                data={data}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
            />
            <ConfirmDeleteModal
                title="Czy na pewno chcesz usunąć ten koszt?"
                modal={modalConfirmDelete}
                onSubmit={handleDeleteConfirmed}
                onCancel={modalConfirmDelete.closeModal}
            />
        </>
    )
}

const SettingsAnesthesiologistCostEditForm = () => {
    const { id } = useParams()
    const anesthesiologistCostsPolicy = useAnesthesiologistCostsPolicy()
    const implant = useGetAnesthesiologistCost(id!, {
        enabled: anesthesiologistCostsPolicy.canAll && !!id,
    })

    if (implant.isLoading) {
        return <Loader />
    }

    if (implant.isError) {
        return <div>{implant.error.message}</div>
    }

    if (!implant.data) {
        return <div></div>
    }

    return <SettingsAnesthesiologistCostContainer id={id} data={implant.data} />
}

function transformFormToAPIValues(
    values: FormValues
): AnesthesiologistCostVariables {
    return {
        ...(Object.fromEntries(
            Object.entries(values).filter(([key]) => !key.startsWith('_'))
        ) as FormValues),
        adult_started_after: values.adult_started_after?.id.toString() || '',
        children_started_after:
            values.children_started_after?.id?.toString() || '',
        procedure_categories: values.procedure_categories.map((item) => ({
            id: item.id,
        })),
    }
}

export { SettingsAnesthesiologistCostEditForm }
