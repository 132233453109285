import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useGetProcedureCategories } from 'api'
import { listReducer } from 'helpers'
import { useProcedureCategoryPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, Loader } from 'components/ui'
import SettingsLayout from 'layouts/SettingsLayout'
import SettingsProcedureCategoryList from 'components/SettingsProcedureCategoryList'

export default function SettingsProcedureCategoryListContainer() {
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const { isLoading, isSuccess, isError, data, error } =
        useGetProcedureCategories(filters, {
            enabled: procedureCategoryPolicy.canAll,
        })

    return (
        <SettingsLayout
            actions={
                procedureCategoryPolicy.canCreate && (
                    <ActionButton
                        to="/settings/procedure-categories/add"
                        rightIcon={PlusIcon}
                    >
                        Dodaj
                    </ActionButton>
                )
            }
        >
            <>
                {!procedureCategoryPolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania kategoriami " />
                )}
                {isLoading && <Loader />}
                {isError && <span>Error: {error?.message}</span>}
                {isSuccess && !!data && (
                    <SettingsProcedureCategoryList
                        data={data}
                        setFilters={setFilters}
                    />
                )}
            </>
        </SettingsLayout>
    )
}
