import React from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import qs from 'qs'
import classNames from 'classnames'
import { ArrowNarrowRightIcon, XIcon } from '@heroicons/react/solid'

import { Pagination, ActionButton } from 'components/ui'
import { AutoSubmitRow, PriceField } from 'components/forms'
import EmptyList from 'components/EmptyList'

import { FormSubmitFn } from 'types'
import { Implant, ResponseList } from 'api/types'

export interface FormValues {
    id: number
    price: number
}

interface ImplantListProps {
    data: ResponseList<Implant[]>
    filtersCount: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    handleUpdate: FormSubmitFn<FormValues>
    selectedItems: number[]
    toggleAllCurrentPageRowsSelection: () => void
    checkIsRowSelected: (implant: Implant) => boolean
    toggleRowSelection: (implant: Implant) => void
    areAllRowsOnCurrentPageSelected: boolean
}

export default function SettingsImplantList({
    data,
    filtersCount,
    setPage,
    handleUpdate,
    selectedItems,
    toggleAllCurrentPageRowsSelection,
    areAllRowsOnCurrentPageSelected,
    checkIsRowSelected,
    toggleRowSelection,
}: ImplantListProps) {
    return (
        <>
            <div className="bg-white p-2 rounded-lg">
                {data.meta.total > 0 && (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Id
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    <input
                                        type="checkbox"
                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                        checked={
                                            areAllRowsOnCurrentPageSelected
                                        }
                                        onChange={
                                            toggleAllCurrentPageRowsSelection
                                        }
                                    />
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nazwa
                                </th>
                                <th
                                    scope="col"
                                    className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Występowanie
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Producent
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Cena
                                </th>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Opcje
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                            {data.data.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={classNames({
                                        'bg-gray-50': index % 2,
                                    })}
                                >
                                    <td className="px-6 py-6 whitespace-nowrap">
                                        <span className="text-gray-500 font-normal">
                                            {item.id}
                                        </span>
                                    </td>
                                    <td className="px-2 py-2">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                            checked={checkIsRowSelected(item)}
                                            onChange={() =>
                                                toggleRowSelection(item)
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-6">
                                        <span
                                            className={classNames(
                                                'font-medium',
                                                {
                                                    'text-blue-600':
                                                        selectedItems.includes(
                                                            item.id
                                                        ),
                                                }
                                            )}
                                        >
                                            {item.label || item.name}
                                        </span>
                                    </td>
                                    <td className="px-6 py-6">
                                        <div className="inline-flex w-60 items-center justify-between text-gray-500">
                                            <span className="truncate">
                                                {item.procedures
                                                    .filter(
                                                        (_, index) =>
                                                            index === 0
                                                    )
                                                    .map((i) => i.name)}
                                            </span>
                                            <div className="ml-2 flex-shrink-0 flex">
                                                <span className="text-gray-500 hover:text-gray-700">
                                                    {item.procedures.length >
                                                        1 &&
                                                        '+' +
                                                            (item.procedures
                                                                .length -
                                                                1)}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-6">
                                        <div className="w-40 text-gray-500 truncate">
                                            {item.manufacturer?.name || '-'}
                                        </div>
                                    </td>
                                    <td className="px-1 py-1 text-center">
                                        <Formik
                                            key={index}
                                            initialValues={{
                                                id: item.id,
                                                price: item.price,
                                            }}
                                            enableReinitialize
                                            onSubmit={handleUpdate}
                                        >
                                            {({ isSubmitting }) => (
                                                <>
                                                    <PriceField
                                                        name="price"
                                                        disabled={isSubmitting}
                                                        variant="sm"
                                                        showTextError={false}
                                                    />
                                                    <AutoSubmitRow />
                                                </>
                                            )}
                                        </Formik>
                                    </td>
                                    <td className="px-6 py-1 whitespace-nowrap text-right">
                                        <span className="font-normal">
                                            <Link
                                                to={`/settings/price-list/implants/${item.id}`}
                                                className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Edytuj
                                            </Link>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                {data.meta.total > 0 && (
                    <Pagination meta={data.meta} handleChange={setPage} />
                )}
                {data.meta.total === 0 && filtersCount === 0 && (
                    <EmptyList
                        headline="Nic tu jeszcze nie ma"
                        message="Zacznij od stworzenia pierwszego implantu."
                        to="/settings/price-list/implants/add"
                    />
                )}
                {data.meta.total === 0 && filtersCount > 0 && (
                    <EmptyList
                        headline="Nie znaleziono wyników wyszukiwania"
                        message="Możesz dodać implant."
                        to="/settings/price-list/implants/add"
                    />
                )}
            </div>
        </>
    )
}

const SelectedItemActions: React.FC<{
    handleAssign: () => void
    selectedItems: number[]
    unselectAllRows: () => void
}> = ({ handleAssign, selectedItems, unselectAllRows }) => {
    return (
        <>
            <div className="flex items-center">
                <ActionButton variant="secondary" handleClick={handleAssign}>
                    Przypisz
                </ActionButton>
                <span className="ml-4">
                    <span className="font-medium text-xs leading-4 tracking-wide uppercase">
                        Wybierz:
                    </span>
                    <span className="ml-2">
                        <span className="inline-flex text-sm leading-5 items-center text-md px-2 pl-3 py-0.5 bg-red-100 text-red-800 rounded-md">
                            {selectedItems.length}
                            <XIcon
                                className="ml-1 mt-0.5 h-3.5 w-3.5 cursor-pointer"
                                style={{ color: '#F87171' }}
                                onClick={unselectAllRows}
                            />
                        </span>
                    </span>
                </span>
                <span className="ml-4">
                    <ActionButton
                        to={
                            '/settings/price-list/implant-sets/add?' +
                            qs.stringify(
                                { implants: selectedItems },
                                { indices: false }
                            )
                        }
                        rightIcon={ArrowNarrowRightIcon}
                    >
                        Stwórz zestaw
                    </ActionButton>
                </span>
            </div>
        </>
    )
}

export { SelectedItemActions }
