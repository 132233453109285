import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useGetProcedureTypes } from 'api'
import { useProcedureTypePolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, Loader } from 'components/ui'
import SettingsProcedureTypeList from 'components/SettingsProcedureTypeList'
import SettingsLayout from 'layouts/SettingsLayout'

export default function SettingsProcedureTypeListContainer() {
    const procedureTypePolicyGroup = useProcedureTypePolicyGroup()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const procedureType = useGetProcedureTypes(filters, {
        enabled: procedureTypePolicyGroup.canAll,
    })

    return (
        <SettingsLayout
            actions={
                procedureTypePolicyGroup.canAll && (
                    <ActionButton
                        to="/settings/procedure-types/add"
                        rightIcon={PlusIcon}
                    >
                        Dodaj
                    </ActionButton>
                )
            }
        >
            <>
                {!procedureTypePolicyGroup.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania rodzajami zabiegów" />
                )}
                {procedureType.isLoading && <Loader />}
                {procedureType.isError && (
                    <span>Error: {procedureType.error.message}</span>
                )}
                {procedureType.isSuccess && (
                    <SettingsProcedureTypeList
                        procedureType={procedureType}
                        setFilters={setFilters}
                    />
                )}
            </>
        </SettingsLayout>
    )
}
