import React from 'react'
import { ConnectDragSource, useDrag } from 'react-dnd'

import type { ScheduleOperationListItem } from 'api/types'
import type { OnDragStart, OnDragStop } from 'types/SchedulePlanner'

const DraggableEvent: React.FC<{
    item: ScheduleOperationListItem
    colStart: number
    row: number
    onDragStart?: OnDragStart
    onDragStop?: OnDragStop
    children: ({
        isDragging,
        drag,
    }: {
        isDragging: boolean
        drag: ConnectDragSource
    }) => JSX.Element
}> = ({ item, colStart, row, onDragStart, onDragStop, children, ...props }) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'operation',
            item: () => {
                const dragItem = {
                    id: item.id,
                    estimatedDate: item.estimated_date,
                    duration: item.duration,
                    prev: {
                        row: row - 1,
                        col: colStart - 1,
                    },
                }

                !!onDragStart && onDragStart(dragItem)

                return dragItem
            },
            end: () => {
                !!onDragStop && onDragStop()
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [item]
    )

    return children({ isDragging, drag })
}

export default DraggableEvent
