export const OPERATION_TASK_STATUS_OPTIONS = [
    {
        label: 'Oczekuje',
        value: 'new',
    },
    {
        label: 'Wykonane',
        value: 'done',
    },
    {
        label: 'Zlecone',
        value: 'ordered',
    },
    {
        label: 'Odrzuć',
        value: 'rejected',
    },
]
