import { useContext, useRef } from 'react'
import { useQueryClient } from 'react-query'

import PusherContext from 'contexts/PusherContext'

import type { Channel } from 'contexts/PusherContext'

export type SchedulePayload = {}

export const useScheduleChannel = () => {
    const queryClient = useQueryClient()
    const { pusher } = useContext(PusherContext)
    const channelRef = useRef<Channel>()

    const channelName = 'private-live-view'
    const eventName = 'status-changed'

    const connect = () => {
        channelRef.current = pusher!.current?.subscribe(channelName)

        return {
            channel: channelRef.current,
            onEvent: onEvent,
            handleEvent: handleEvent,
            closeChannel: () => {
                channelRef.current?.unbind(eventName)
                channelRef.current?.unsubscribe()
            },
        }
    }

    const onEvent = (callback: (payload: SchedulePayload) => void) => {
        channelRef.current?.bind(eventName, callback)
    }

    const handleEvent = async (payload: SchedulePayload) => {
        await queryClient.invalidateQueries(['operations'])
        await queryClient.invalidateQueries(['schedules'])
    }

    return {
        connect,
        onEvent,
        handleEvent,
    }
}
