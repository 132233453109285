import {
    usePolicy,
    useProcedurePolicy,
    useProcedureTypePolicy,
    useProcedureCategoryPolicy,
    usePayerPolicy,
    useIcdCodePolicy,
} from 'hooks'

export const useProcedurePolicyGroup = () => {
    const { user } = usePolicy()
    const procedurePolicy = useProcedurePolicy()
    const procedureCategoryPolicy = useProcedureCategoryPolicy()
    const procedureTypePolicy = useProcedureTypePolicy()
    const payerPolicy = usePayerPolicy()
    const icdCodePolicy = useIcdCodePolicy()

    return {
        user,
        canAll:
            procedurePolicy.canAll &&
            procedureCategoryPolicy.canIndex &&
            procedureTypePolicy.canIndex &&
            payerPolicy.canIndex &&
            icdCodePolicy.canIndex,
    }
}
