import React from 'react'
import classNames from 'classnames'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { Button, Pagination } from 'components/ui'
import { ListFilterAction } from 'helpers'
import EmptyList from 'components/EmptyList'

import type { ClosureTemplate, ResponseList } from 'api/types'

const ClosureTemplateList: React.FC<{
    data: ResponseList<ClosureTemplate[]>
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, setFilters }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && <Table data={data} />}
            {data.meta.total > DEFAULT_LIST_PER_PAGE && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nie masz jeszcze szablonów"
                    message="Zacznij od stworzenia pierwszego."
                    to="/closures/templates/create"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<ClosureTemplate[]>
}> = ({ data }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                <tr>
                    <th
                        scope="col"
                        className="px-6 py-6 text-left font-medium tracking-wider"
                        style={{ width: '30%' }}
                    >
                        Nazwa szablonu
                    </th>
                    <th
                        scope="col"
                        className="px-6 py-6 text-left font-medium tracking-wider"
                    >
                        Treść
                    </th>
                    <th
                        scope="col"
                        className="px-6 py-1 text-left font-medium tracking-wider"
                        style={{ width: '1%' }}
                    >
                        Opcje
                    </th>
                </tr>
            </thead>
            <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span>{item.name}</span>
                        </td>
                        <td className="px-6 py-6 truncate max-w-0">
                            <span className="truncate">{item.content}</span>
                        </td>
                        <td className="px-6 py-1 text-right">
                            <span className="font-normal">
                                <Button
                                    as="link"
                                    to={`/closures/templates/${item.id}`}
                                    variant="tertiary"
                                    size="sm"
                                >
                                    Edytuj
                                </Button>
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ClosureTemplateList
