import React, { useState } from 'react'
import { useFormikContext } from 'formik'

interface AutoSubmitRowProps {
    delayMs?: number
}

const AutoSubmitRow: React.FC<AutoSubmitRowProps> = ({ delayMs = 1000 }) => {
    const { values, initialValues, submitForm } = useFormikContext()
    const [th, setTh] = useState<any>(null)

    React.useEffect(() => {
        if (values !== initialValues) {
            if (th) {
                clearTimeout(th)
            }

            setTh(setTimeout(() => submitForm(), delayMs))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, initialValues, submitForm])

    return null
}

export default AutoSubmitRow
