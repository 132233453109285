import React from 'react'
import { Field, FieldProps } from 'formik'

import { InputPrice } from 'components/ui'

import type { InputPriceProps } from 'components/ui'

export default function PriceField({
    name,
    showTextError = true,
    negative,
    ...rest
}: InputPriceProps & {
    name: string
    negative?: boolean
    showTextError?: boolean
    currency?: string | boolean
}) {
    return (
        <Field
            name={name}
            children={({ form, field }: FieldProps) => (
                <>
                    <InputPrice
                        name={name}
                        value={field.value}
                        negative={negative}
                        disabled={form.isSubmitting}
                        handleChange={(value) =>
                            form.setFieldValue(name, value)
                        }
                        hasError={!!form.errors[name]}
                        {...rest}
                    />
                    {!!form.errors[name] && showTextError && (
                        <p className="mt-1 text-sm text-red-600">
                            {form.errors[name]}
                        </p>
                    )}
                </>
            )}
        />
    )
}
