import React, { useState, useEffect, Fragment } from 'react'
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'

import type { Operation } from 'api/types'

const OperationDescriptionStatus: React.FC<{
    status: string
    onChange: (status: Operation['status']) => void
    disabled?: boolean
}> = ({ status, disabled, onChange }) => {
    const [currentStatus, setCurrentStatus] = useState(status)

    useEffect(() => {
        setCurrentStatus(status)
    }, [status])

    return (
        <div
            className="relative inline-block text-left"
            onClick={(e) => e.stopPropagation()}
        >
            <Menu as={Fragment}>
                <Menu.Button
                    className={classNames(
                        'flex items-center space-x-2 rounded-full px-2 text-xs leading-5',
                        {
                            'bg-green-100 text-green-800':
                                currentStatus === 'descripted',
                            'bg-yellow-100 text-yellow-800':
                                currentStatus === 'during_description',
                            'bg-red-100 text-red-800':
                                currentStatus === 'to_description',
                            'cursor-pointer': !disabled,
                            'cursor-default': !!disabled,
                        }
                    )}
                >
                    {currentStatus === 'descripted' && <span>Opisana</span>}
                    {currentStatus === 'during_description' && (
                        <span>W trakcie</span>
                    )}
                    {currentStatus === 'to_description' && (
                        <span>Nieopisana</span>
                    )}
                    {!disabled && (
                        <svg
                            width="6"
                            height="3"
                            className="ml-2 overflow-visible"
                            aria-hidden="true"
                        >
                            <path
                                d="M0 0L3 3L6 0"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            ></path>
                        </svg>
                    )}
                </Menu.Button>
                {!disabled && (
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1">
                                {(
                                    [
                                        {
                                            id: 'during_description',
                                            name: 'W&nbsp;trakcie',
                                        },
                                        {
                                            id: 'descripted',
                                            name: 'Opisana',
                                        },
                                    ] as {
                                        id: Operation['status']
                                        name: string
                                    }[]
                                ).map((item) => (
                                    <Menu.Item key={item.id}>
                                        {({ active }) => (
                                            <span
                                                onClick={async (e) => {
                                                    await setCurrentStatus(
                                                        item.id
                                                    )
                                                    onChange(item.id)
                                                }}
                                                className={classNames(
                                                    active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.name,
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                )}
            </Menu>
        </div>
    )
}

export default OperationDescriptionStatus
