import React from 'react'
import classNames from 'classnames'

import { ListFilterAction } from 'helpers'
import { Pagination, Toggle } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { IModal } from 'types'
import type { ResponseList, ImplantManufacturer } from 'api/types'

type ToggleActiveFn = (id: number, active: boolean) => void

const SettingsImplantManufactureList: React.FC<{
    data: ResponseList<ImplantManufacturer[]>
    modal: IModal
    handleEdit: (id: number) => void
    handleToggleActive: ToggleActiveFn
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ data, modal, handleEdit, setFilters, handleToggleActive }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && (
                <Table
                    data={data}
                    handleEdit={handleEdit}
                    handleToggleActive={handleToggleActive}
                />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego producenta."
                    onClick={modal.openModal}
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<ImplantManufacturer[]>
    handleEdit: (id: number) => void
    handleToggleActive: ToggleActiveFn
}> = ({ data, handleEdit, handleToggleActive }) => {
    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        className="w-1 px-6 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                        Id
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Nazwa
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Aktywny
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Opcje
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span>{item.id}</span>
                        </td>
                        <td className="px-6 py-6">
                            <span className="text-gray-900 font-medium">
                                {item.name}
                            </span>
                        </td>
                        <td className="px-6 py-1 text-center">
                            <Toggle
                                checked={item.active}
                                handleChange={(value) =>
                                    handleToggleActive(item.id, value)
                                }
                            />
                        </td>
                        <td className="px-6 py-1 text-right">
                            <span className="font-normal">
                                <span
                                    className="cursor-pointer inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => handleEdit(item.id)}
                                >
                                    Edytuj
                                </span>
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SettingsImplantManufactureList
