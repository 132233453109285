import { createNotificationContext } from 'react-notification-provider'

export interface Notification {
    id: number
    operation_id?: number
    title?: number
    description?: string
    date?: string
    type?: string
    receivedAt: Date
}

export const TYPE_OPERATION_CHANGED =
    'App\\Notifications\\ChangeOperationDateNotification'
export const TYPE_OPERATION_CANCELED =
    'App\\Notifications\\CancelOperationNotification'

const { NotificationProvider, useNotificationQueue } =
    createNotificationContext<Notification>()

export { NotificationProvider, useNotificationQueue }
