import React from 'react'
import { useNavigate } from 'react-router-dom'
import { XIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from 'helpers'

import styles from './NotificationToast.module.scss'

import type { Notification } from 'contexts/NotificationProvider'

const NotificationToast: React.FC<{
    id: string
    item: Notification
    variant?: 'regular' | 'danger'
    onRemove: (id: string) => void
}> = ({ id, item, variant = 'regular', onRemove }) => {
    const navigate = useNavigate()
    const cx = getCxFromStyles(styles)

    return (
        <div
            className={cx('root', variant, {
                'cursor-pointer': !!item.operation_id,
            })}
            onClick={() => {
                if (!!item.operation_id) {
                    navigate('/surgeries/' + item.operation_id + '/card')
                    onRemove(id)
                }
            }}
        >
            {!!item.title && <p className={cx('title')}>{item.title}</p>}
            {!!item.description && (
                <p className={cx('description')}>{item.description}</p>
            )}
            <button
                type="button"
                className={cx('close')}
                onClick={(e) => {
                    e.stopPropagation()
                    onRemove(id)
                }}
            >
                <span className="sr-only">Close</span>
                <XIcon className="h-3 w-3" aria-hidden="true" />
            </button>
        </div>
    )
}

export default NotificationToast
