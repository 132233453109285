import React from 'react'
import classNames from 'classnames'

import AvatarPlaceholder from 'components/AvatarPlaceholder'

const Avatar: React.FC<{
    url: string | undefined
    abbreviation?: string
    size: number
    withBorder?: boolean
}> = ({ url, abbreviation, size, withBorder }) => {
    return (
        <div
            className={classNames(
                `w-${size} h-${size}`,
                'rounded-full overflow-hidden',
                {
                    'ring-2 ring-white': withBorder,
                }
            )}
        >
            {!url && !!abbreviation && (
                <div
                    className={classNames(
                        'flex w-full h-full bg-gray-500 text-white leading-6 items-center justify-center uppercase',
                        {
                            'text-xs': size <= 5,
                            'text-sm': size === 6,
                            'text-md': size > 6 && size < 10,
                            'text-lg': size >= 10 && size < 13,
                            'text-xl': size >= 13 && size < 16,
                            'text-2xl': size >= 16,
                        }
                    )}
                >
                    {abbreviation}
                </div>
            )}
            {!url && !abbreviation && <AvatarPlaceholder size={size} />}
            {!!url && <img className="w-full h-full" src={url} alt="" />}
        </div>
    )
}

export default Avatar
