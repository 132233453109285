import { usePolicy, useCaregiverPanelPolicy } from 'hooks'

export const useCaregiverPanelMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const caregiverPanelPolicy = useCaregiverPanelPolicy()

    return {
        user,
        canIndex: caregiverPanelPolicy.canIndex,
    }
}
