import { useCurrentUser } from './useCurrentUser'
import { UserResponse } from 'api/types'

export type UserPermissions = {
    permissions: string[]
}

export type CurrentUserPermissions = {
    user: Partial<UserResponse> & UserPermissions
}

export const useCurrentUserPermissions = (): CurrentUserPermissions => {
    const user = useCurrentUser()

    return {
        user: {
            ...user,
            permissions: user?.role?.permissions.map((item) => item.name) || [],
        },
    }
}
