import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useQueryClient } from 'react-query'
import { z } from 'zod'
import { DownloadIcon } from '@heroicons/react/outline'
import moment from 'moment/moment'
import omit from 'lodash.omit'

import { downloadFileFactory } from '../helpers'
import alertContext from 'contexts/alerts/alertContext'
import {
    useDoctorSurgeryPolicyGroup,
    useOperationAccountancyPolicy,
} from 'hooks'
import {
    API_FORMAT_DATE,
    DEFAULT_LIST_PER_PAGE,
    SESSION_STORAGE_PERFORMED_SURGERY_LIST_FILTER_KEY,
} from 'constants/index'
import {
    exportSurgeryPerformedList,
    getOperationClosurePdf,
    useGetOperations,
    useUpdateOperation,
} from 'api'
import { AccessDenied } from 'components'
import { Button, FilterButton, Loader } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import Filters from 'containers/DoctorSurgeryPerformedListFilters'
import DoctorSurgeryPerformedList from 'components/DoctorSurgeryPerformedList'

import { DropdownItem, UserDropdownItem } from 'components/forms'
import type { FormSubmitFn, OperationListItem } from 'types'
import type { Operation } from 'api/types'

export interface SurgeryPerformedListFilters {
    page: number
    length: number
    sort_by: string
    sort_direction: string
    name: string
    query: string
    procedure_types: DropdownItem[]
    statuses: DropdownItem[]
    performed_operations: boolean
    operator?: UserDropdownItem
    date_range: {
        from: Date | undefined
        to: Date | undefined
    }
}

export const initialFilters: SurgeryPerformedListFilters = {
    page: 1,
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'estimated_date',
    sort_direction: 'desc',
    name: '',
    query: '',
    procedure_types: [],
    statuses: [],
    performed_operations: true,
    operator: undefined,
    date_range: {
        from: undefined,
        to: undefined,
    },
}

export const transformSurgeryPerformedListFiltersToApi = (
    filters: SurgeryPerformedListFilters
) => {
    return Object.assign(
        {},
        { ...omit(filters, 'date_range') },
        { doctor_id: filters.operator?.id },
        filters.date_range.from && filters.date_range.to
            ? {
                  estimated_date_from: moment(filters.date_range.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.date_range.to).format(
                      API_FORMAT_DATE
                  ),
              }
            : {},
        {
            procedure_types: filters.procedure_types
                ? [...filters.procedure_types.map((item) => item.id)]
                : [],
            statuses: filters.statuses
                ? [...filters.statuses.map((item) => item.id)]
                : [],
        }
    )
}

const filterSchema = z.object({
    sort_by: z.union([z.literal('estimated_date'), z.literal('patient_name')]),
    sort_direction: z.union([z.literal('asc'), z.literal('desc')]),
    query: z.string(),
    name: z.string(),
    procedure_types: z
        .array(z.object({ id: z.number(), name: z.string() }))
        .optional(),
    statuses: z
        .array(z.object({ id: z.string(), name: z.string() }))
        .optional(),
    operator: z
        .object({
            id: z.number(),
            first_name: z.string(),
            last_name: z.string(),
            avatar_url: z.string(),
        })
        .optional(),
    date_range: z.union([
        z.object({
            from: z.string().datetime(),
            to: z.string().datetime(),
        }),
        z.object({
            from: z.undefined(),
            to: z.undefined(),
        }),
    ]),
})

const SurgeryPerformedListContainer: React.FC = () => {
    const queryClient = useQueryClient()
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()
    const { add: alert } = useContext(alertContext)
    const [downloading, setDownloading] = useState(-1)
    const [isExporting, setIsExporting] = useState(false)

    const { isAllowed: isOperationAccountancy } =
        useOperationAccountancyPolicy()

    const [filters, setFilters] = useState(() => {
        const storedFilters = window.sessionStorage.getItem(
            SESSION_STORAGE_PERFORMED_SURGERY_LIST_FILTER_KEY
        )

        if (storedFilters) {
            try {
                const restoredFilters = JSON.parse(storedFilters)

                const result = filterSchema.safeParse(restoredFilters)

                if (result.success) {
                    return {
                        ...initialFilters,
                        ...result.data,
                        date_range:
                            result.data.date_range.from &&
                            result.data.date_range.to
                                ? {
                                      from: new Date(
                                          Date.parse(
                                              result.data.date_range.from
                                          )
                                      ),
                                      to: new Date(
                                          Date.parse(result.data.date_range.to)
                                      ),
                                  }
                                : {
                                      from: undefined,
                                      to: undefined,
                                  },
                    }
                }
            } catch (e) {}
        }

        return initialFilters
    })

    const exportXlsx = () => {
        setIsExporting(true)
        downloadFileFactory({
            fetchFunction: () =>
                exportSurgeryPerformedList(
                    transformSurgeryPerformedListFiltersToApi(filters)
                ).finally(() => setIsExporting(false)),
            fileName: `Zestawienie implantów.xlsx`,
        })
    }

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_PERFORMED_SURGERY_LIST_FILTER_KEY,
            JSON.stringify(filters)
        )
    }, [filters])

    const setSorting = useCallback(
        (sort_by: string, sort_direction: string) =>
            setFilters((prevState) => ({
                ...prevState,
                sort_by,
                sort_direction,
                page: 1,
            })),
        [setFilters]
    )
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () =>
            (filters.name.length ? 1 : 0) +
            (filters.operator ? 1 : 0) +
            (filters.date_range.from && filters.date_range.to ? 1 : 0) +
            (filters.query.length ? 1 : 0) +
            (filters.procedure_types.length ? 1 : 0) +
            (filters.statuses.length ? 1 : 0),
        [filters]
    )

    const surgeries = useGetOperations(
        transformSurgeryPerformedListFiltersToApi(filters),
        {
            enabled: doctorSurgeryPolicyGroup.canIndexPerformed,
            keepPreviousData: true,
        }
    )

    const { mutate: updateOperation } = useUpdateOperation()

    const handleChangeFilters: FormSubmitFn<SurgeryPerformedListFilters> = (
        values
    ) => {
        setFilters({
            ...values,
            page: 1,
        })
    }

    const handleResetFilters = () => {
        setFilters((prevState) => ({
            ...prevState,
            name: '',
            query: '',
            procedure_types: [],
            statuses: [],
            operator: undefined,
            date_range: {
                from: undefined,
                to: undefined,
            },
            page: 1,
        }))
    }

    const handleDownload = async (item: OperationListItem) => {
        if (!item.closure_id) return

        setDownloading(item.closure_id!)

        try {
            const data = await getOperationClosurePdf(item.closure_id)
            setDownloading(-1)
            window.open(data.data.path, '_blank', 'noreferrer')
        } catch {
            setDownloading(-1)
        }
    }

    const handleUpdateStatus = async (
        id: number,
        status: Operation['status']
    ) => {
        return updateOperation(
            { id, data: { status } },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries('operations')
                    alert({
                        content: 'Status został zmieniony.',
                        type: 'success',
                    })
                },
                onError: (error) => {
                    alert({
                        content: 'Nie udało się zmienić statusu.',
                        type: 'danger',
                    })
                },
            }
        )
    }

    return (
        <ListLayout
            title="Wykonane operacje"
            actions={
                doctorSurgeryPolicyGroup.canIndexPerformed && (
                    <>
                        {isOperationAccountancy && (
                            <span className="ml-2">
                                <Button
                                    size="sm"
                                    iconLeft={<DownloadIcon />}
                                    onClick={exportXlsx}
                                    loading={isExporting}
                                >
                                    Zestawienie implantów
                                </Button>
                            </span>
                        )}
                        <span className="ml-2">
                            <FilterButton
                                count={filtersCount}
                                onClick={() =>
                                    setFiltersExpanded(!filtersExpanded)
                                }
                                filtersExpanded={filtersExpanded}
                                handleReset={handleResetFilters}
                            />
                        </span>
                    </>
                )
            }
        >
            <>
                {!doctorSurgeryPolicyGroup.canIndexPerformed && (
                    <AccessDenied message="Nie masz uprawnień do wyświetlania wykonanych operacji" />
                )}
                {filtersExpanded && (
                    <Filters
                        filters={filters}
                        onSubmit={handleChangeFilters}
                        showDateRange={isOperationAccountancy}
                        showOperator={isOperationAccountancy}
                    />
                )}
                {surgeries.isLoading && <Loader />}
                {surgeries.isSuccess && (
                    <DoctorSurgeryPerformedList
                        data={surgeries.data}
                        setFilters={setFilters}
                        filters={filters}
                        filtersCount={filtersCount}
                        setSorting={setSorting}
                        downloading={downloading}
                        handleDownload={handleDownload}
                        onUpdateStatus={handleUpdateStatus}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default SurgeryPerformedListContainer
