import classNames from 'classnames/bind'

export const getCxFromStyles = (
    baseStyles: Record<string, string>,
    customStyles?: Record<string, string>
): typeof classNames => {
    const combinedStyles = customStyles
        ? Object.keys(baseStyles).reduce<Record<string, string>>((acc, key) => {
              acc[key] = baseStyles[key]

              if (key in customStyles) {
                  acc[key] += ' ' + customStyles[key]
              }

              return acc
          }, {})
        : baseStyles

    return classNames.bind(combinedStyles)
}
