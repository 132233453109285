import React, { useState } from 'react'
import moment from 'moment'

import { useStatisticsPolicy } from 'hooks'
import LiveViewContainer from 'containers/LiveView'
import BusinessLiveViewContainer from 'containers/BusinessLiveView'

export default function LiveViewSwitcher() {
    const [view, setView] = useState<'detail' | 'business'>('detail')
    const [date, setDate] = useState<Date>(new Date())

    const statisticsPolicy = useStatisticsPolicy()

    const handleViewToggle = () => {
        if (view === 'business') {
            setView('detail')
        } else {
            if (!moment(date).isBefore(moment().startOf('day'))) {
                // Set yesterday's date unless today is Sunday or Monday, then set the last Friday
                const newDate = new Date(
                    moment()
                        .day(
                            [0, 1].includes(moment().day())
                                ? -2
                                : moment().day() - 1
                        )
                        .format('YYYY-MM-DD')
                )

                setDate(newDate)
            }

            setView('business')
        }
    }

    const handleDateChange = (date: Date) => {
        setDate(date)
    }

    return view === 'business' ? (
        <BusinessLiveViewContainer
            date={date}
            view={view}
            canChangeView={statisticsPolicy.viewTimeView}
            onDateChange={handleDateChange}
            onViewToggle={handleViewToggle}
        />
    ) : (
        <LiveViewContainer
            date={date}
            view={view}
            canChangeView={statisticsPolicy.viewTimeView}
            onDateChange={handleDateChange}
            onViewToggle={handleViewToggle}
        />
    )
}
