import { listReducer } from './listReducer'

import type { FormProcedureDurationsFilters } from 'api/types'
import type { ListFilterAction, ListFilters } from './listReducer'

export type ProcedureDurationListFilterState = {
    filters: FormProcedureDurationsFilters
} & ListFilters

export type ProcedureDurationListFilterAction =
    | { type: 'change'; payload: FormProcedureDurationsFilters }
    | { type: 'reset'; payload: ProcedureDurationListFilterState }
    | ListFilterAction

export function procedureDurationFilterReducer(
    state: ProcedureDurationListFilterState,
    action: ProcedureDurationListFilterAction
) {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                filters: action.payload,
                page: 1,
            }

        case 'reset':
            return action.payload

        default:
            return listReducer(state, action) as {
                filters: FormProcedureDurationsFilters
            } & ListFilters
    }
}
