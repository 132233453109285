import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'

import { useGetExpenses, useUpdateExpense } from 'api'
import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer, ListFilters, ListFilterAction } from 'helpers'
import { useExpensePolicy } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsExpenseOtherList from 'components/SettingsExpenseOtherList'

import type { FormSubmitFn } from 'types'
import type { FormValues } from 'components/SettingsExpenseList'

type ListFilterState = ListFilters & {
    hospitalization_costs: boolean
}

function expenseOtherListReducer(
    state: ListFilterState,
    action: ListFilterAction
): ListFilterState {
    return listReducer(state, action) as ListFilterState
}

const SettingsExpenseOtherListContainer: React.FC = () => {
    const expensePolicy = useExpensePolicy()
    const [filters, dispatchFilterAction] = useReducer(
        expenseOtherListReducer,
        {
            page: 1,
            hospitalization_costs: true,
            length: DEFAULT_LIST_PER_PAGE,
        }
    )
    const queryClient = useQueryClient()
    const expenses = useGetExpenses(filters, {
        enabled: expensePolicy.canAll,
    })
    const { mutate: update } = useUpdateExpense()

    const handleUpdatePrice: FormSubmitFn<FormValues> = (
        values,
        formikHelpers
    ) => {
        update(values, {
            onSuccess: () => {
                queryClient.invalidateQueries(['expenses'])
            },
            onError: (error) => {
                formikHelpers.setErrors(error.errors)
            },
            onSettled: () => {
                formikHelpers.setSubmitting(false)
            },
        })
    }

    return (
        <SettingsPriceListLayout>
            <>
                {!expensePolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania kosztami" />
                )}
                {expenses.isLoading && <Loader />}
                {expenses.isSuccess && (
                    <SettingsExpenseOtherList
                        data={expenses.data}
                        handleUpdatePrice={handleUpdatePrice}
                        dispatchFilterAction={dispatchFilterAction}
                    />
                )}
            </>
        </SettingsPriceListLayout>
    )
}

export default SettingsExpenseOtherListContainer
