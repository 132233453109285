import React, { useContext } from 'react'
import classNames from 'classnames'
import { FilterIcon, XIcon } from '@heroicons/react/solid'
import mediaContext from 'contexts/media/mediaContext'

const FilterButton: React.FC<{
    count: number
    onClick: () => void
    handleReset?: () => void
    filtersExpanded?: boolean
}> = ({ count, filtersExpanded, onClick, handleReset }) => {
    const { isMobile } = useContext(mediaContext)

    return (
        <div className="relative inline-flex">
            {!!count && !!handleReset && (
                <div
                    className="absolute z-10 -top-2.5 -right-2.5"
                    onClick={handleReset}
                >
                    <div className="flex w-5 h-5 items-center justify-center text-white rounded-full bg-indigo-800 cursor-pointer">
                        <XIcon className="w-3.5" />
                    </div>
                </div>
            )}
            <button
                type="button"
                className="relative flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm leading-4 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                onClick={onClick}
            >
                {!!count && (
                    <span className="absolute -top-2.5 -left-2.5 w-5 h-5 shadow-md inline-flex items-center justify-center rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                        {count}
                    </span>
                )}
                <FilterIcon
                    className={classNames('-ml-0.5 md:mr-2 h-4 w-4', {
                        'text-gray-300': !filtersExpanded,
                        'text-gray-500': filtersExpanded,
                    })}
                    aria-hidden="true"
                />
                {!isMobile && 'Filtry'}
            </button>
        </div>
    )
}

export default FilterButton
