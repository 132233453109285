import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
    useGetRoles,
    useGetSpecializations,
    useCreateUser,
    useUpdateUser,
    useGetUserById,
    useGetOperatingRooms,
} from 'api'
import { useUserPolicyGroup } from 'hooks'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsUserForm from 'components/SettingsUserForm'

import type { FormSubmitFn } from 'types'
import type { UserForm } from 'types/UserForm'

const SettingsUserFormContainer = () => {
    const { id } = useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const userPolicyGroup = useUserPolicyGroup()
    const roles = useGetRoles(
        { pagination: false },
        {
            enabled: userPolicyGroup.canAll,
        }
    )
    const rooms = useGetOperatingRooms(
        { pagination: false },
        {
            enabled: userPolicyGroup.canAll,
        }
    )
    const specializations = useGetSpecializations(
        { pagination: false },
        {
            enabled: userPolicyGroup.canAll,
        }
    )
    const user = useGetUserById(id!, {
        enabled: userPolicyGroup.canAll && !!id,
    })
    const { mutate: create } = useCreateUser()
    const { mutate: update } = useUpdateUser(id!)

    const createOrUpdate = useMemo(
        () => (id ? update : create),
        [create, update, id]
    )

    const handleSubmit: FormSubmitFn<UserForm> = (values, formikHelpers) => {
        createOrUpdate(
            {
                ...values,
                role: values.role?.id,
                specializations: values.specializations.map((item) => ({
                    id: item.id,
                })),
                operating_room_id:
                    (values.operating_room?.id as number) || null,
            },
            {
                onSuccess: async (data) => {
                    await queryClient.invalidateQueries('users')
                    formikHelpers.resetForm()
                    formikHelpers.setSubmitting(false)
                    navigate(`/settings/users/${data.data.id}`)
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    return (
        <>
            {!userPolicyGroup.canAll && (
                <AccessDenied message="Nie masz uprawnień do zarządzania użytkownikami" />
            )}
            {user.isLoading && <Loader />}
            {user.isError && <div>{user.error.message}</div>}
            {userPolicyGroup.canAll && (!id || user.isSuccess) && (
                <ListLayout
                    renderTitle={
                        <>
                            <span className="font-semibold">
                                {id
                                    ? 'Edytuj użytkownika: '
                                    : 'Dodaj użytkownika'}
                            </span>
                            {!!id && (
                                <span>
                                    {user.data!.data.first_name}{' '}
                                    {user.data!.data.last_name}
                                </span>
                            )}
                        </>
                    }
                >
                    <>
                        {(roles.isLoading ||
                            specializations.isLoading ||
                            rooms.isLoading) && <Loader />}
                        {roles.isError && <div>{roles.error.message}</div>}
                        {specializations.isError && (
                            <div>{specializations.error.message}</div>
                        )}
                        {rooms.isError && <div>{rooms.error.message}</div>}
                        {roles.isSuccess &&
                            specializations.isSuccess &&
                            rooms.isSuccess && (
                                <SettingsUserForm
                                    user={id ? user.data : undefined}
                                    roles={roles.data.data}
                                    specializations={specializations.data.data}
                                    rooms={rooms.data.data}
                                    handleSubmit={handleSubmit}
                                />
                            )}
                    </>
                </ListLayout>
            )}
        </>
    )
}

export default SettingsUserFormContainer
