import React from 'react'
import classNames from 'classnames'
import { RadioGroup } from '@headlessui/react'

import type { OptionRenderPropArg } from './RadioGroupOption'

const RadioGroupLabel: React.FC<OptionRenderPropArg> = ({
    checked,
    active,
    disabled,
    children,
}) => {
    return (
        <RadioGroup.Label
            className={classNames(
                'flex-1 rounded-md relative border p-3 flex focus:outline-none',
                {
                    'pointer-events-none cursor-default bg-gray-50': disabled,
                    'cursor-pointer': !disabled,
                    'bg-blue-50 border-blue-200': checked,
                    'border-gray-200': !checked,
                }
            )}
        >
            <span
                className={classNames(
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                    {
                        'bg-blue-600 border-transparent': checked,
                        'bg-white border-gray-300': !checked,
                        'ring-2 ring-offset-2 ring-indigo-500': active,
                    }
                )}
                aria-hidden="true"
            >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <div className="ml-3 flex flex-col">
                <span
                    className={classNames('block text-sm font-medium', {
                        'text-indigo-900': checked,
                        'text-gray-900': !checked && !disabled,
                        'text-gray-400': !checked && disabled,
                    })}
                >
                    {children}
                </span>
            </div>
        </RadioGroup.Label>
    )
}

export default RadioGroupLabel
