import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { UseInfiniteQueryResult } from 'react-query'
import uniqBy from 'lodash.uniqby'

import { formatDate, formatTime, roundedPrice } from 'helpers'
import { ResponseError, StatisticsOperationsResponse } from 'api/types'
import {
    Item,
    BorderedItem,
    ItemIcon,
    ItemValue,
    ItemLabelledValue,
} from 'components/StatisticsDashboard'

import { OperationIcon, ZrzuconyIcon, CompanyIcon } from 'assets/icons'

const StatisticsSurgeries: React.FC<{
    operationsQueryResult: UseInfiniteQueryResult<
        StatisticsOperationsResponse,
        ResponseError
    >
}> = ({ operationsQueryResult }) => {
    const navigate = useNavigate()
    const observerRef = useRef(null)
    const handleObserver = useCallback(
        (entries) => {
            const [target] = entries
            if (
                target.isIntersecting &&
                operationsQueryResult.hasNextPage &&
                !operationsQueryResult.isFetchingNextPage
            ) {
                operationsQueryResult.fetchNextPage()
            }
        },
        [operationsQueryResult]
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }
        const element = observerRef.current
        const option = { threshold: 0 }
        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    const summaryData: Record<
        string,
        StatisticsOperationsResponse['statistics'][0]
    > = useMemo(
        () =>
            operationsQueryResult.data?.pages[0]?.statistics.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.type]: item,
                }),
                {}
            ) || {},
        [operationsQueryResult.data]
    )

    const payersData = useMemo(
        () =>
            operationsQueryResult.data?.pages[0]?.payers.filter(
                (item) => !!item.name
            ) || [],
        [operationsQueryResult.data]
    )

    const operations = useMemo(
        () =>
            uniqBy(
                operationsQueryResult.data?.pages.flatMap(
                    (page) => page.data
                ) || [],
                'id'
            ),
        [operationsQueryResult.data]
    )

    const surgeriesNotFound =
        operationsQueryResult.isSuccess &&
        operationsQueryResult.data.pages.at(0)?.meta.total === 0

    return (
        <div className="space-y-4">
            <div className="p-5 rounded-md bg-white">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                    Podsumowanie
                </h2>

                <div className="mt-5 mb-10 grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <BorderedItem>
                        <Item
                            title="Liczba zabiegów"
                            icon={
                                <ItemIcon
                                    icon={
                                        <OperationIcon className="w-7 h-7 text-white" />
                                    }
                                    bgOuter="bg-blue-50"
                                    bgInner="bg-blue-800"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                />
                            }
                            left={
                                <ItemValue>
                                    {summaryData.all?.count || 0}
                                </ItemValue>
                            }
                            right={
                                <ItemLabelledValue label="Suma:">
                                    <ItemValue>
                                        {roundedPrice(
                                            summaryData.all?.total_sum || 0
                                        )}
                                    </ItemValue>
                                </ItemLabelledValue>
                            }
                        />
                    </BorderedItem>
                    <BorderedItem>
                        <Item
                            title="Zrzucone zabiegi"
                            icon={
                                <ItemIcon
                                    icon={
                                        <ZrzuconyIcon className="w-7 h-7 text-gray-900" />
                                    }
                                    bgOuter="bg-gray-700"
                                    bgInner="bg-gray-300"
                                    disabledBgOuter="bg-gray-50"
                                    disabledBgInner="bg-gray-200"
                                    disabled={false}
                                />
                            }
                            left={
                                <ItemValue>
                                    {summaryData.rejected?.count || 0}
                                </ItemValue>
                            }
                            right={
                                <ItemLabelledValue label="Suma:">
                                    <ItemValue>
                                        {roundedPrice(
                                            summaryData.rejected?.total_sum || 0
                                        )}
                                    </ItemValue>
                                </ItemLabelledValue>
                            }
                        />
                    </BorderedItem>
                </div>

                {payersData.length > 0 && (
                    <>
                        <h2 className="text-lg leading-6 font-medium text-gray-900">
                            Operacje per kontrahenci
                        </h2>
                        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            {payersData.map((payer) => (
                                <BorderedItem key={payer.name}>
                                    <Item
                                        title={payer.name || ''}
                                        icon={
                                            <ItemIcon
                                                icon={
                                                    <CompanyIcon className="h-7 w-7 text-white" />
                                                }
                                                bgOuter={
                                                    payer.type === 'nfz'
                                                        ? 'bg-gray-50'
                                                        : 'bg-blue-50'
                                                }
                                                bgInner={
                                                    payer.type === 'nfz'
                                                        ? 'bg-gray-800'
                                                        : 'bg-blue-800'
                                                }
                                                disabledBgOuter="bg-gray-50"
                                                disabledBgInner="bg-gray-200"
                                                disabled={false}
                                            />
                                        }
                                        left={
                                            <ItemValue>
                                                {payer.count || 0}
                                            </ItemValue>
                                        }
                                        right={
                                            <ItemLabelledValue label="Suma:">
                                                <ItemValue className="text-lg">
                                                    {roundedPrice(
                                                        payer.total_cost || 0
                                                    )}
                                                </ItemValue>
                                            </ItemLabelledValue>
                                        }
                                    />
                                </BorderedItem>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <div className="p-2 rounded-md bg-white">
                <table>
                    <thead className="bg-gray-50 border-b border-b-gray-200 border-md text-gray-500 uppercase h-10 sticky top-16 z-10">
                        <tr className="h-10">
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Data
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Operator</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Pacjent</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                            >
                                <span>Operacja</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>Płatnik</span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Cena
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span className="whitespace-nowrap">
                                    Czas rozp.
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Czas
                                    <br />
                                    pomiędzy
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Oczekiwanie
                                    <br />
                                    na lekarza
                                </span>
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium tracking-wider"
                                style={{ width: '1%' }}
                            >
                                <span>
                                    Czas
                                    <br />
                                    zabiegu
                                </span>
                            </th>
                        </tr>
                    </thead>
                    {operationsQueryResult.isSuccess &&
                        operationsQueryResult.isFetched && (
                            <tbody className="text-sm bg-white divide-y divide-gray-200 leading-5">
                                {operations.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={classNames(
                                            'h-16 hover:bg-blue-50 cursor-pointer',
                                            {
                                                'bg-gray-50': index % 2,
                                            }
                                        )}
                                        onClick={() =>
                                            navigate(
                                                `/statistics/surgeries/${item.id}`
                                            )
                                        }
                                    >
                                        <td className="px-6 py-1">
                                            <span className="text-gray-500">
                                                {formatDate(
                                                    item.estimated_date
                                                )}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span className="font-medium">
                                                {item.operator?.first_name}{' '}
                                                {item.operator?.last_name}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span className="font-medium">
                                                {item.patient?.first_name}{' '}
                                                {item.patient?.last_name}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <div>
                                                <p>{item.procedure?.name}</p>
                                            </div>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>{item.payer?.name}</span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>
                                                {roundedPrice(item.total_cost)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>
                                                {item.real_operation_start &&
                                                    formatTime(
                                                        item.real_operation_start
                                                    )}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>{item.break_duration}</span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span>
                                                {
                                                    item.waiting_for_doctor_duration
                                                }
                                            </span>
                                        </td>
                                        <td className="px-6 py-1">
                                            <span
                                                className={classNames({
                                                    'font-semibold text-red-700':
                                                        item.is_exceeded,
                                                })}
                                            >
                                                {item.real_duration}
                                            </span>
                                        </td>
                                    </tr>
                                ))}

                                {!surgeriesNotFound &&
                                    operationsQueryResult.hasNextPage && (
                                        <tr ref={observerRef}>
                                            <td colSpan={10}>
                                                <div className="flex items-center justify-center h-20">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="animate-spin text-gray-600"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 48 48"
                                                    >
                                                        <path
                                                            fillOpacity="0.3"
                                                            d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                                                        />
                                                        <path d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z" />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                            </tbody>
                        )}
                </table>
                {surgeriesNotFound && (
                    <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                        <div className="relative h-16" />
                        <div className="relative h-16">
                            <div className="absolute inset-0 h-full flex justify-center items-center">
                                <span>Brak wyników</span>
                            </div>
                            <span>&nbsp;</span>
                        </div>
                        <div className="relative h-16" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default StatisticsSurgeries
