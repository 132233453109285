import { z } from 'zod'

import {
    DropdownItem,
    ProcedureDropdownItem,
    UserDropdownItem,
} from 'components/forms'

export interface StatisticsSurgeryListFilters {
    length: number
    date_range: {
        from: Date | undefined
        to: Date | undefined
    }
    operator?: UserDropdownItem
    patient: string
    payer_type?: DropdownItem
    procedure_types?: DropdownItem[]
    actual_state?: DropdownItem[]
    procedure?: ProcedureDropdownItem
    procedure_category?: DropdownItem
    operating_room?: DropdownItem
    procedure_phrase?: string
    exceeded?: boolean
    unclosed?: boolean
}

export const statisticsSurgeryListFilterSchema = z.object({
    date_range: z.object({
        from: z.string().datetime(),
        to: z.string().datetime(),
    }),
    operator: z
        .object({
            id: z.number(),
            first_name: z.string(),
            last_name: z.string(),
            avatar_url: z.string(),
        })
        .optional(),
    patient: z.string(),
    payer_type: z.object({ id: z.string(), name: z.string() }).optional(),
    procedure_types: z
        .array(z.object({ id: z.number(), name: z.string() }))
        .optional(),
    actual_state: z
        .array(z.object({ id: z.string(), name: z.string() }))
        .optional(),
    procedure: z.object({ id: z.number(), name: z.string() }).optional(),
    procedure_category: z
        .object({ id: z.number(), name: z.string() })
        .optional(),
    operating_room: z.object({ id: z.number(), name: z.string() }).optional(),
    procedure_phrase: z.string().optional(),
})
