import React, { memo, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal: React.FC<{
    id: string
}> = memo(({ id, children }) => {
    const elRef = useRef(
        document.getElementById(id) || document.createElement('div')
    )
    const [dynamic] = useState(!elRef.current.parentElement)

    useEffect(() => {
        const el = elRef.current

        if (dynamic) {
            el.id = id
            document.body.appendChild(el)
        }
        return () => {
            if (dynamic && el.parentElement) {
                el.parentElement.removeChild(el)
            }
        }
    }, [id, dynamic])

    return createPortal(children, elRef.current)
})

export default Portal
