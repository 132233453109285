import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import {
    DropdownField,
    PriceField,
    TextField,
    CategoryFieldArray,
    DropdownItem,
    ActionBar,
} from 'components/forms'
import { Button, CancelButton, Label, Toggle } from 'components/ui'

import type { FormSubmitFn } from 'types'
import type { AnesthesiologistCostResponse, ProcedureCategory } from 'api/types'

export type FormValues = {
    name: string
    adult_costs_enabled: boolean
    adult_base_cost: string
    adult_started_cost: string
    adult_started_after: DropdownItem | undefined
    children_costs_enabled: boolean
    children_base_cost: string
    children_started_cost: string
    children_started_after: DropdownItem | undefined
    procedure_categories: ProcedureCategory[]
    _category_item: DropdownItem | undefined
    _category_item_phrase: string
}

interface SettingsAnesthesiologistCostProps {
    id?: string
    data?: AnesthesiologistCostResponse
    onSubmit: FormSubmitFn<FormValues>
    onDelete?: (id: number) => void
}

const SettingsAnesthesiologistCostForm: React.FC<
    SettingsAnesthesiologistCostProps
> = ({ id, data, onSubmit, onDelete }) => {
    const durationItems = useMemo(
        () => [
            { id: '15m', name: '15 min' },
            { id: '30m', name: '30 min' },
            { id: '45m', name: '45 min' },
            { id: '1h', name: '1h' },
            { id: '1h15m', name: '1h 15 min' },
            { id: '1h30m', name: '1h 30 min' },
            { id: '1h45', name: '1h 45 min' },
            { id: '2h', name: '2h' },
        ],
        []
    )

    const initialValues: FormValues = useMemo(
        () =>
            data?.data
                ? {
                      name: data.data.name,
                      adult_costs_enabled: data.data.adult_costs_enabled,
                      adult_base_cost: data.data.adult_base_cost.toString(),
                      adult_started_cost:
                          data.data.adult_started_cost.toString(),
                      adult_started_after: durationItems.find(
                          (item) => item.id === data.data.adult_started_after
                      ),
                      children_costs_enabled: data.data.children_costs_enabled,
                      children_base_cost:
                          data.data.children_base_cost.toString(),
                      children_started_cost:
                          data.data.children_started_cost.toString(),
                      children_started_after: durationItems.find(
                          (item) => item.id === data.data.children_started_after
                      ),
                      procedure_categories: data.data.procedure_categories,
                      _category_item: undefined,
                      _category_item_phrase: '',
                  }
                : {
                      name: '',
                      adult_costs_enabled: false,
                      adult_base_cost: '',
                      adult_started_cost: '',
                      adult_started_after: undefined,
                      children_costs_enabled: false,
                      children_base_cost: '',
                      children_started_cost: '',
                      children_started_after: undefined,
                      procedure_categories: [],
                      _category_item: undefined,
                      _category_item_phrase: '',
                  },
        [data, durationItems]
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            {({ values, setFieldValue }) => (
                <div className="py-6">
                    <div className="px-4 mb-4 sm:px-6 md:px-8">
                        <div className="flex gap-4 items-center justify-between">
                            <div className="flex-none rounded-lg flex items-center justify-center">
                                <h2 className="text-2xl leading-8">
                                    {!id && (
                                        <span className="font-semibold">
                                            Dodaj:
                                        </span>
                                    )}
                                    {!!id && (
                                        <span className="font-semibold">
                                            Edytuj:
                                        </span>
                                    )}
                                    &nbsp;
                                    <span className="font-light">
                                        Koszt anestezjologa
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mb-6 sm:px-6 md:px-8">
                        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                            <Form>
                                <div className="">
                                    <div className="bg-white rounded-t-md pt-12 px-6">
                                        <div className="border-b">
                                            <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                                <div className="col-span-6">
                                                    <div className="pr-8">
                                                        <div className="mt-4">
                                                            <TextField
                                                                labelText="Nazwa własna"
                                                                name="name"
                                                            />
                                                        </div>
                                                        <div className="mt-6 flex justify-between items-center w-full text-sm font-medium text-left">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <span className="text-lg leading-6 font-medium text-gray-900">
                                                                    Osoby
                                                                    dorosłe
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0 flex space-x-4 items-center">
                                                                <Toggle
                                                                    checked={
                                                                        values.adult_costs_enabled
                                                                    }
                                                                    handleChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            'adult_costs_enabled',
                                                                            !values.adult_costs_enabled
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <Label>
                                                                Koszt do 2
                                                                godzin
                                                            </Label>
                                                            <PriceField
                                                                placeholder="0,00"
                                                                name="adult_base_cost"
                                                            />
                                                        </div>
                                                        <div className="mt-6">
                                                            <div className="grid grid-cols-2 sm:grid-cols-6 gap-6">
                                                                <div className="col-span-2">
                                                                    <Label>
                                                                        Rozpoczęte
                                                                    </Label>
                                                                    <DropdownField
                                                                        name="adult_started_after"
                                                                        placeholder="0 min"
                                                                        items={
                                                                            durationItems
                                                                        }
                                                                        bulkOption={
                                                                            true
                                                                        }
                                                                        bulkOptionName="0 min"
                                                                    />
                                                                </div>
                                                                <div className="col-span-4">
                                                                    <Label>
                                                                        &nbsp;
                                                                    </Label>
                                                                    <PriceField
                                                                        placeholder="0,00"
                                                                        name="adult_started_cost"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-6 flex justify-between items-center w-full text-sm font-medium text-left">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <span className="text-lg leading-6 font-medium text-gray-900">
                                                                    Dzieci
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0 flex space-x-4 items-center">
                                                                <Toggle
                                                                    checked={
                                                                        values.children_costs_enabled
                                                                    }
                                                                    handleChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            'children_costs_enabled',
                                                                            !values.children_costs_enabled
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-6">
                                                            <Label>
                                                                Koszt do 2
                                                                godzin
                                                            </Label>
                                                            <PriceField
                                                                placeholder="0,00"
                                                                name="children_base_cost"
                                                            />
                                                        </div>
                                                        <div className="mt-6">
                                                            <div className="grid grid-cols-2 sm:grid-cols-6 gap-6">
                                                                <div className="col-span-2">
                                                                    <Label>
                                                                        Rozpoczęte
                                                                    </Label>
                                                                    <DropdownField
                                                                        name="children_started_after"
                                                                        placeholder="0 min"
                                                                        items={
                                                                            durationItems
                                                                        }
                                                                        bulkOption={
                                                                            true
                                                                        }
                                                                        bulkOptionName="0 min"
                                                                    />
                                                                </div>
                                                                <div className="col-span-4">
                                                                    <Label>
                                                                        &nbsp;
                                                                    </Label>
                                                                    <PriceField
                                                                        placeholder="0,00"
                                                                        name="children_started_cost"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-6">
                                                    <div className="pl-8">
                                                        <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                            Występowanie
                                                        </div>
                                                        <div className="mt-4">
                                                            <Label>
                                                                Kategoria
                                                                operacji
                                                            </Label>
                                                            <CategoryFieldArray name="procedure_categories" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white px-6 py-12 rounded-b-md">
                                        <ActionBar
                                            leftSide={({ isSubmitting }) => (
                                                <>
                                                    {!!data && !!onDelete && (
                                                        <Button
                                                            type="button"
                                                            variant="danger"
                                                            onClick={() =>
                                                                onDelete(
                                                                    data.data.id
                                                                )
                                                            }
                                                        >
                                                            Usuń
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                            rightSide={({
                                                isSubmitting,
                                            }: {
                                                isSubmitting: boolean
                                            }) => (
                                                <div className="space-x-3">
                                                    <CancelButton
                                                        as="link"
                                                        to="/settings/price-list/anesthesiologist-costs"
                                                    >
                                                        Anuluj
                                                    </CancelButton>
                                                    <Button
                                                        type="submit"
                                                        variant="primary"
                                                        className="ml-3"
                                                        loading={isSubmitting}
                                                        iconRight={<PlusIcon />}
                                                    >
                                                        Zapisz
                                                    </Button>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default SettingsAnesthesiologistCostForm
