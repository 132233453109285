import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PATIENT_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PATIENTS,
    show: PERMISSIONS.SHOW_PATIENTS,
    create: PERMISSIONS.CREATE_PATIENTS,
    update: [PERMISSIONS.SHOW_PATIENTS, PERMISSIONS.UPDATE_PATIENTS],
    delete: PERMISSIONS.DELETE_PATIENTS,
}

export const usePatientPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PATIENT_PERMISSIONS),
    }
}
