import React from 'react'
import { RadioGroup } from '@headlessui/react'

export interface OptionRenderPropArg {
    checked: boolean
    active: boolean
    disabled: boolean
}

const RadioGroupOption: React.FC<{
    value: unknown
    disabled?: boolean
    children: (props: OptionRenderPropArg) => JSX.Element
}> = ({ value, disabled, children }) => (
    <RadioGroup.Option value={value} disabled={disabled}>
        {(props) => children(props)}
    </RadioGroup.Option>
)

export default RadioGroupOption
