import React from 'react'
import { useParams } from 'react-router-dom'

import ListLayout from 'layouts/ListLayout'
import { useDoctorSurgeryPolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Link } from 'components/ui'
import OperationCard from 'containers/OperationCard'

const DoctorOperationCard = () => {
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()
    const { id } = useParams()

    if (!id) {
        return null
    }

    if (!doctorSurgeryPolicyGroup.canShow) {
        return (
            <AccessDenied message="Nie masz uprawnień dostępu do karty operacji" />
        )
    }

    return (
        <ListLayout
            title="Karta operacji"
            actions={<Link to="/surgeries">Lista operacji</Link>}
        >
            <div className="p-4 pb-5 rounded-md shadow-lg bg-white">
                <OperationCard id={Number(id)} />
            </div>
        </ListLayout>
    )
}

export default DoctorOperationCard
