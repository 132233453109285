import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { Dialog } from '@headlessui/react'
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/solid'

import { NAMES_DAYS_OF_WEEK } from 'constants/index'
import { Modal } from 'components'
import { Button } from 'components/ui'
import { formatMomentDate, getCxFromStyles } from 'helpers'

import type { Moment } from 'moment'
import type { FormSubmitFn, IModal, ScheduleBlockDayForm } from 'types'

import styles from 'components/Modal.module.scss'

const SchedulePlannerBlockDayModal: React.FC<{
    modal: IModal<{ action: 'lock' | 'unlock'; date: Date } | undefined>
    onSubmit: FormSubmitFn<ScheduleBlockDayForm>
    onCancel: () => void
}> = ({ modal, onSubmit, onCancel }) => {
    const cx = getCxFromStyles(styles)

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                <Formik
                    initialValues={
                        {
                            _action: modal.getState()?.action,
                            date: modal.getState()?.date,
                        } as ScheduleBlockDayForm
                    }
                    onSubmit={onSubmit}
                >
                    {({ values, isSubmitting }) => (
                        <Form>
                            <div className="mb-4">
                                <div className="m-auto w-12">
                                    {values._action === 'lock' && (
                                        <LockClosedIcon className="text-yellow-500" />
                                    )}
                                    {values._action === 'unlock' && (
                                        <LockOpenIcon className="text-green-500" />
                                    )}
                                </div>
                            </div>

                            <Dialog.Title className={cx('title')}>
                                <span>Dodawanie operacji</span>
                            </Dialog.Title>

                            <div className="mt-4 text-center">
                                <p className="text-sm text-gray-500 leading-5 font-normal">
                                    {values._action === 'unlock' &&
                                        'Odblokować'}
                                    {values._action === 'lock' && 'Zablokować'}{' '}
                                    możliwość dodawania operacji dla dnia
                                    <br />
                                    {!!values.date &&
                                        displayDate(moment(values.date))}
                                    ?
                                </p>
                            </div>

                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Tak
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

const displayDate = (date: Moment) => (
    <b className="font-medium">
        {NAMES_DAYS_OF_WEEK[Number(date.format('d'))]}, {formatMomentDate(date)}
    </b>
)

export default SchedulePlannerBlockDayModal
