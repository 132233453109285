import { usePolicy, useProcedureTypePolicy, useRolePolicy } from 'hooks'

export const useProcedureTypePolicyGroup = () => {
    const { user } = usePolicy()
    const procedureTypePolicy = useProcedureTypePolicy()
    const rolePolicy = useRolePolicy()

    return {
        user,
        canAll: procedureTypePolicy.canAll && rolePolicy.canIndex,
    }
}
