import React from 'react'
import { UserIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from 'helpers'

import styles from './OperationStatus.module.scss'

const cx = getCxFromStyles(styles)

const RADIUS = 18
const STROKE = 6

const OperationStatus: React.FC<{
    progress: number
    rejected?: boolean
}> = ({ progress, rejected = false }) => {
    const normalizedRadius = RADIUS - Math.ceil(STROKE / 2)
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset =
        circumference - (rejected ? 1 : progress / 100) * circumference

    return (
        <div
            className={cx('root', {
                undone: !rejected && progress === 0,
                'in-progress': !rejected && progress > 0 && progress < 100,
                done: !rejected && progress === 100,
                rejected: rejected,
            })}
        >
            <svg
                className={cx('circle-progress-bar')}
                height={RADIUS * 2}
                width={RADIUS * 2}
            >
                <circle
                    className={cx('circle')}
                    fill="transparent"
                    strokeWidth={STROKE}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{ strokeDashoffset }}
                    r={normalizedRadius}
                    cx={RADIUS}
                    cy={RADIUS}
                />
            </svg>
            <div className={cx('icon-container')}>
                <div className={cx('icon-circle')}>
                    <UserIcon className={cx('icon')} />
                </div>
            </div>
        </div>
    )
}

export default OperationStatus
