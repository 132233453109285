import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
} from 'react-query'
import moment from 'moment'
import qs from 'qs'

import axios from 'api/axios'
import { API_FORMAT_DATE } from 'constants/index'
import { getDurationArray } from 'helpers'

import {
    OperatingRoom,
    PatchScheduleOperation,
    ResponseData,
    ResponseError,
    ResponseList,
    ScheduleListMeta,
    ScheduleOperation,
    ScheduleOperationUpdateVariables,
    ScheduleOperationListItem,
    SchedulePlanItem,
    SendScheduleSMSPayload,
    WorkPlanSchedule,
    ScheduleOperationSwapVariables,
} from 'api/types'
import type {
    ScheduleOperationForm,
    SurgicalTeam,
    SurgicalTeamMember,
    EquipmentDropdownItem,
} from 'types'
import type { DropdownItem } from 'components/forms'

export const useGetSchedules = <
    T = ScheduleOperationListItem,
    E = ResponseError
>(
    filters?: {},
    options?: UseQueryOptions<ResponseList<T[], ScheduleListMeta>, E>
) =>
    useQuery<ResponseList<T[], ScheduleListMeta>, E>(
        ['schedules', filters],
        () =>
            axios.get(
                '/schedules' + (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )

export const useGetScheduleOperation = <
    T = ScheduleOperation,
    E = ResponseError
>(
    id: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['schedules', id],
        () => axios.get(`/schedules/${id}`),
        options
    )

export const useUpdateScheduleOperation = <
    R1 extends ScheduleOperationUpdateVariables,
    R2 = ResponseData<ScheduleOperation>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.patch('/schedules/' + data.id, data.data))

export const useSwapScheduleOperations = <
    R1 extends ScheduleOperationSwapVariables,
    R2 = void
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.patch(`/schedules/${data.id}/swap`, data.data))

export const transformScheduleOperationFormToAPI = (
    values: ScheduleOperationForm
): PatchScheduleOperation => ({
    accepted: values.accepted,
    implants_approved: values.implants_approved,
    change_manually: true,
    estimated_date: values.estimated_date,
    estimated_duration: values.estimated_duration?.id,
    final_operation_date: values.start_at?.id
        ? values.estimated_date + ' ' + values.start_at.id
        : '',
    operating_room: values.operating_room?.id || null,
    doctor_starts_at: values.doctor_starts_at?.id
        ? values.estimated_date + ' ' + values.doctor_starts_at.id
        : '',
    equipment: values.equipment,
    surgical_team: [
        values.doctor && { id: values.doctor.id, role: 'doctor' },
        ...values.assistant
            .filter((item) => !!item)
            .map((item) => ({
                id: item!.id,
                role: 'assistant',
            })),
        ...values.anesthesiologist
            .filter((item) => !!item)
            .map((item) => ({
                id: item!.id,
                role: 'anesthesiologist',
            })),
        ...values.anesthesiologist_nurse
            .filter((item) => !!item)
            .map((item) => ({
                id: item!.id,
                role: 'anesthesiologist_nurse',
            })),
        ...values.operation_nurse
            .filter((item) => !!item)
            .map((item) => ({
                id: item!.id,
                role: 'operation_nurse',
            })),
    ].filter((item) => !!item),
    description: values.description,
    room: values.room,
    admission_date:
        values._admission_date__date || values._admission_date__time
            ? values._admission_date__date +
              (values._admission_date__time
                  ? ' ' + values._admission_date__time.id
                  : '')
            : undefined,
    patient_informed: values.patient_informed,
    fogging: values.fogging,
})

export const transformSurgicalTeamFromApiToForm = (
    surgicalTeam: SurgicalTeamMember[]
) => {
    return surgicalTeam.reduce(
        (acc, item) => {
            if (item.role === 'doctor') {
                acc[item.role] = item
            }

            if (
                [
                    'assistant',
                    'anesthesiologist',
                    'anesthesiologist_nurse',
                    'operation_nurse',
                ].includes(item.role)
            ) {
                acc[item.role as keyof Omit<SurgicalTeam, 'doctor'>].push(item)
            }

            return acc
        },
        {
            doctor: undefined,
            assistant: [],
            anesthesiologist: [],
            anesthesiologist_nurse: [],
            operation_nurse: [],
        } as SurgicalTeam
    )
}

export const toScheduleOperationFormValues = ({
    data,
    rooms,
}: {
    data: ScheduleOperation
    rooms: OperatingRoom[]
}): ScheduleOperationForm => {
    const surgeryTeamByRole = transformSurgicalTeamFromApiToForm(
        data.surgical_team
    )

    return {
        accepted: data.accepted,
        implants_approved: data.implants_approved,
        estimated_date: data.estimated_date,
        estimated_duration: data.estimated_duration
            ? getDurationArray(12, 15).find(
                  (item) => item.id === data.estimated_duration
              ) || undefined
            : undefined,
        start_at:
            (data.final_operation_date && {
                id: moment(data.final_operation_date).format('HH:mm:00'),
                name: moment(data.final_operation_date).format('HH:mm'),
            }) ||
            undefined,
        finish_at:
            (data.finish_at && moment(data.finish_at).format('HH:mm')) ||
            undefined,
        operating_room:
            (data.operating_room_id &&
                rooms.find((item) => item.id === data.operating_room_id)) ||
            undefined,
        doctor_starts_at:
            (data.doctor_starts_at && {
                id: moment(data.doctor_starts_at).format('HH:mm:00'),
                name: moment(data.doctor_starts_at).format('HH:mm'),
            }) ||
            undefined,
        doctor: surgeryTeamByRole.doctor,
        equipment: data.equipment || ([] as EquipmentDropdownItem[]),
        _equipment_item: undefined,
        _equipment_item_phrase: '',
        assistant:
            surgeryTeamByRole.assistant.length > 0
                ? surgeryTeamByRole.assistant
                : [null],
        anesthesiologist:
            surgeryTeamByRole.anesthesiologist.length > 0
                ? surgeryTeamByRole.anesthesiologist
                : [null],
        anesthesiologist_nurse:
            surgeryTeamByRole.anesthesiologist_nurse.length > 0
                ? surgeryTeamByRole.anesthesiologist_nurse
                : [null],
        operation_nurse:
            surgeryTeamByRole.operation_nurse.length > 0
                ? surgeryTeamByRole.operation_nurse
                : [null],
        description: data.description || '',
        room: data.room || '',
        admission_date: data.admission_date,
        _admission_date__date: data.admission_date
            ? moment(data.admission_date).format(API_FORMAT_DATE)
            : '',
        _admission_date__time: data.admission_date
            ? ({
                  id: moment(data.admission_date).format('HH:mm'),
                  name: moment(data.admission_date).format('HH:mm'),
              } as DropdownItem)
            : undefined,
        patient_informed: data.patient_informed,
        _isRejecting: false,
        cancel_reason: '',
        _isCancelling: false,
        fogging: data.fogging,
    }
}

export const setSurgicalTeamErrors = (
    data: PatchScheduleOperation['surgical_team'],
    errors: ResponseError['errors'],
    setFieldError: (field: string, message: string | undefined) => void
) => {
    const dataWithErrors = data!.map((item, index) => ({
        ...item,
        error: errors[`surgical_team.${index}.id`] || null,
    }))

    const setFieldErrorIfExists = (
        item: { role?: string | undefined; error: string | null } | undefined,
        index: number
    ) => {
        if (item && item.role && item.error) {
            setFieldError(item.role, item.error)
        }
    }

    const setFieldArrayErrorIfExists = (
        item: Parameters<typeof setFieldErrorIfExists>[0],
        index: Parameters<typeof setFieldErrorIfExists>[1]
    ) => {
        if (item && item.role && item.error) {
            setFieldError(`${item.role}.${index}`, item.error)
        }
    }

    dataWithErrors
        .filter((item) => item.role === 'assistant')
        .forEach(setFieldArrayErrorIfExists)

    dataWithErrors
        .filter((item) => item.role === 'anesthesiologist')
        .forEach(setFieldArrayErrorIfExists)

    dataWithErrors
        .filter((item) => item.role === 'anesthesiologist_nurse')
        .forEach(setFieldArrayErrorIfExists)

    dataWithErrors
        .filter((item) => item.role === 'operation_nurse')
        .forEach(setFieldArrayErrorIfExists)
}

export const useGetSchedulePlan = <T = SchedulePlanItem, E = ResponseError>(
    id: number | string,
    filters: { date: string },
    options?: UseQueryOptions<ResponseData<T[]>, E>
) =>
    useQuery<ResponseData<T[]>, E>(
        ['schedules-plan', id, filters],
        () =>
            axios.get(
                `/schedules/${id}/plan` +
                    (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )

export const useSendScheduleSMS = <
    R1 extends SendScheduleSMSPayload,
    R2 = ResponseData<{}>
>(
    options?: UseMutationOptions<R2, ResponseError, R1>
): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.post('/schedules/sms', data), options)

export const useSendScheduleUpdateSMS = <
    R1 extends { id: number },
    R2 = ResponseData<{}>
>(
    options?: UseMutationOptions<R2, ResponseError, R1>
): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id }) => axios.post(`/schedules/${id}/sms`), options)

export const useGetWorkPlanSchedule = <E = ResponseError>(
    body?: { from?: string; to?: string },
    options?: UseQueryOptions<ResponseData<WorkPlanSchedule[]>, E>
) =>
    useQuery<ResponseData<WorkPlanSchedule[]>, E>(
        ['schedules', 'work-plan', body],
        () => axios.post('/schedules/work-plan', body),
        options
    )

export const useRestoreScheduleEvent = <
    R1 extends {
        id: number
        data: {
            status: string
        }
    },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id, data }) =>
        axios.post(`/schedules/${id}/restore-event`, data)
    )
