import React from 'react'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'

import { Button, Label } from 'components/ui'
import { TextField } from 'components/forms'

import type { FormSubmitFn, LoginForm } from 'types'

export default function Login({
    onSubmit,
    initialValues,
}: {
    onSubmit: FormSubmitFn<LoginForm>
    initialValues: LoginForm
}) {
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
                <Form className="space-y-6">
                    <div>
                        <Label>E-mail</Label>
                        <TextField
                            id="email"
                            name="email"
                            autoComplete="email"
                        />
                    </div>

                    <div>
                        <Label>Hasło</Label>
                        <TextField
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 text-blue-500 focus:ring-blue-600 border-gray-300 rounded"
                            />
                            <label
                                htmlFor="remember-me"
                                className="ml-2 block text-sm text-gray-900"
                            >
                                Zapamiętaj mnie
                            </label>
                        </div>

                        <div className="text-sm">
                            <Link
                                to="/forgot-password"
                                className="text-blue-500 hover:text-blue-600"
                            >
                                Nie pamiętam hasła
                            </Link>
                        </div>
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            className="w-full"
                            type="submit"
                            loading={isSubmitting}
                        >
                            Zaloguj się
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
