import React from 'react'

import SettingsTabs, { TTab } from 'components/SettingsTabs'

const tabs: TTab[] = [
    {
        name: 'Implanty',
        to: '/settings/price-list/implants',
        current: true,
    },
    {
        name: 'Zestawy implantów',
        to: '/settings/price-list/implant-sets',
        current: false,
    },
    {
        name: 'Producenci',
        to: '/settings/price-list/implant-manufacturers',
        current: false,
    },
    {
        name: 'Sprzęt medyczny',
        to: '/settings/price-list/medical-equipment',
        current: false,
    },
    {
        name: 'Koszty anestez.',
        to: '/settings/price-list/anesthesiologist-costs',
        current: false,
    },
    {
        name: 'Inne koszty',
        to: '/settings/price-list/expenses',
        current: false,
    },
    {
        name: 'Koszty hospitalizacji',
        to: '/settings/price-list/expenses-other',
        current: false,
    },
]

export default function SettingsPriceListLayout({
    children,
    actions,
}: {
    children?: React.ReactChild
    actions?: React.ReactChild | boolean
}) {
    return (
        <div className="py-6">
            <div className="px-4 mb-4 sm:px-6 md:px-8">
                <div className="flex items-center justify-between">
                    <div className="flex-1">
                        <h2 className="text-2xl leading-8">
                            <span className="font-semibold">Cennik</span>
                        </h2>
                    </div>
                    <div className="flex-1 grow">
                        <SettingsTabs tabs={tabs} />
                    </div>
                    <div className="flex-1 flex items-center justify-end md:mt-0 md:ml-4">
                        {actions}
                    </div>
                </div>
            </div>
            <div className="px-4 sm:px-6 md:px-8">{children}</div>
        </div>
    )
}
