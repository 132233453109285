import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

import { getCxFromStyles } from 'helpers'

import styles from './Alert.module.scss'

export type AlertType = 'success' | 'warning' | 'danger'

const TRANSITION_STYLES = {
    enter: styles.alertEnter,
    enterActive: styles.alertEnterActive,
    exit: styles.alertExit,
    exitActive: styles.alertExitActive,
    appear: styles.alertAppear,
    appearActive: styles.alertAppearActive,
}

const Alert: React.FC<{
    dismiss?: () => void
    type?: AlertType
    show?: boolean
}> = ({ dismiss, type = 'success', children, show }) => {
    const cx = getCxFromStyles(styles)

    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames={TRANSITION_STYLES}
            unmountOnExit
            appear
        >
            <div
                className={cx(
                    'transition duration-300 inline-flex flex-row items-center p-4 text-sm leading-5 font-medium rounded-md ',
                    styles[type]
                )}
            >
                <span className={cx('mr-3', styles.alertIcon)}>
                    <CheckCircleIcon className={`h-5 w-5`} />
                </span>
                <div className="mr-7">{children}</div>
                {dismiss && (
                    <div
                        className="ml-auto flex-shrink-0 flex cursor-pointer"
                        role="button"
                        onClick={dismiss}
                    >
                        <span className={styles.closeIcon}>
                            <XIcon className="h-4 w-4" />
                        </span>
                    </div>
                )}
            </div>
        </CSSTransition>
    )
}

export default Alert
