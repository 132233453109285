import { MutableRefObject, createContext } from 'react'

import type Pusher from 'pusher-js'
import type { Channel } from 'pusher-js'
export type RoomChannel = 'private-live-view' | 'private-caregiver'
export type RoomEvent = 'status-changed' | 'operation-changed'

export type { Channel }

export type SubscribeToChannel = ({
    channelName,
    event,
    callback,
}: {
    channelName: RoomChannel
    event: RoomEvent
    callback: Function
}) => Channel | void

export interface PusherContext {
    pusher?: MutableRefObject<Pusher | undefined>
    disconnectFromPusher?: () => void
    subscribeToChannel?: SubscribeToChannel
}

export default createContext<PusherContext>({
    pusher: undefined,
    disconnectFromPusher: undefined,
    subscribeToChannel: undefined,
})
