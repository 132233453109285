import React from 'react'

const HorizontalLines: React.FC = () => {
    return (
        <div
            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-transparent"
            style={{
                gridTemplateRows: 'repeat(68, minmax(2rem, 1fr))',
            }}
        >
            {[
                7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
            ].map((h) => (
                <React.Fragment key={h}>
                    <div
                        className="schedule-horizontal-line-column-0 -ml-10 xl:-ml-24 print:-ml-24"
                        style={{ borderTopColor: '#E5E7EB' }}
                    >
                        <div className="w-10 xl:w-24 print:w-24 pl-0.5 xl:pl-2 mt-1 text-left text-sm xl:text-base leading-6 text-gray-800 print:text-gray-900 print:font-normal">
                            {h < 10 ? '0' + h : h}:00
                        </div>
                    </div>
                    <div className="" style={{ borderTopColor: '#f7f7f7' }} />
                    <div className="" style={{ borderTopColor: '#f7f7f7' }} />
                    <div className="" style={{ borderTopColor: '#f7f7f7' }} />
                </React.Fragment>
            ))}
        </div>
    )
}

export default HorizontalLines
