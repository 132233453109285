import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const OPERATING_ROOM_PERMISSIONS = {
    index: PERMISSIONS.INDEX_OPERATING_ROOMS,
    show: PERMISSIONS.SHOW_OPERATING_ROOMS,
    create: PERMISSIONS.CREATE_OPERATING_ROOMS,
    update: PERMISSIONS.UPDATE_OPERATING_ROOMS,
    delete: PERMISSIONS.DELETE_OPERATING_ROOMS,
}

export const useOperatingRoomPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(OPERATING_ROOM_PERMISSIONS),
    }
}
