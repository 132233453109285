import React, { Fragment, useMemo } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { Label } from 'components/ui'

interface DropdownItem {
    id: number | string
    name: string
    className?: string
}

interface CheckboxDropdownProps {
    name: string
    label?: string
    variant?: 'md' | 'lg'
    placeholder?: string
    items: DropdownItem[]
}

const CheckboxDropdownField = (props: CheckboxDropdownProps) => {
    const { name, label, variant = 'md', placeholder = '' } = props
    // eslint-disable-next-line
    const [field, _, helpers] = useField(name)

    const items = useMemo(
        () =>
            props.items.map((i) => ({
                id: i.id,
                name: i.name,
                className: i.className,
            })),
        [props.items]
    )

    return (
        <Listbox
            value={field.value}
            onChange={(v: DropdownItem | null) => {
                if (!v) {
                    return helpers.setValue([])
                }

                helpers.setValue(
                    field.value.find((fv: DropdownItem) => fv.id === v.id)
                        ? field.value.filter(
                              (fv: DropdownItem) => fv.id !== v.id
                          )
                        : field.value.concat(v)
                )
            }}
        >
            {({ open }) => (
                <>
                    {!!label && (
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                            <Label>{label}</Label>
                        </Listbox.Label>
                    )}
                    <div className="relative">
                        <Listbox.Button
                            className={classNames(
                                'bg-white relative w-full border border-gray-300 rounded-md py-2 pl-3 pr-10 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                                {
                                    'text-sm': variant === 'md',
                                    'text-base': variant === 'lg',
                                }
                            )}
                        >
                            <span className="block truncate">
                                {field.value.length > 0 &&
                                    field.value
                                        .map((item: DropdownItem) => item.name)
                                        .join(', ')}
                                {!placeholder && field.value.length === 0 && (
                                    <span>Wszystko</span>
                                )}
                                {!!placeholder && field.value.length === 0 && (
                                    <span className="text-gray-400">
                                        {placeholder}
                                    </span>
                                )}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md px-1 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm divide-y divide-gray-200">
                                <Listbox.Option
                                    className={({ active }) =>
                                        classNames(
                                            'cursor-pointer select-none relative py-2 pl-3 pr-3',
                                            {
                                                'text-gray-900': !active,
                                                'text-gray-700': active,
                                            }
                                        )
                                    }
                                    value={''}
                                >
                                    {({ active }) => (
                                        <>
                                            <div className="relative pl-3 flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        name={name}
                                                        type="checkbox"
                                                        className="cursor-pointer focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                                        defaultChecked={
                                                            field.value
                                                                .length === 0
                                                        }
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label
                                                        className={classNames(
                                                            'block cursor-pointer',
                                                            {
                                                                'font-medium':
                                                                    field.value
                                                                        .length ===
                                                                    0,
                                                                'font-normal':
                                                                    field.value
                                                                        .length !==
                                                                    0,
                                                            }
                                                        )}
                                                    >
                                                        <span>
                                                            {placeholder ||
                                                                'Wszystko'}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </Listbox.Option>
                                {items.map((item) => {
                                    const selected = !!field.value.find(
                                        (fv: DropdownItem) => fv.id === item.id
                                    )

                                    return (
                                        <Listbox.Option
                                            key={item.id}
                                            className={({ active }) =>
                                                classNames(
                                                    {
                                                        'text-gray-700': active,
                                                        'text-gray-900':
                                                            !active,
                                                    },
                                                    'cursor-pointer select-none relative py-2 pl-3 pr-3',
                                                    item.className
                                                )
                                            }
                                            value={item}
                                        >
                                            {({ active }) => (
                                                <>
                                                    <div className="relative pl-3 flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                name={name}
                                                                type="checkbox"
                                                                className="cursor-pointer focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                                                defaultChecked={
                                                                    selected
                                                                }
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                className={classNames(
                                                                    'block cursor-pointer',
                                                                    {
                                                                        'font-medium':
                                                                            selected,
                                                                        'font-normal':
                                                                            !selected,
                                                                    }
                                                                )}
                                                            >
                                                                <span>
                                                                    {item.name}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    )
                                })}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default CheckboxDropdownField
