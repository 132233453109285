import moment from 'moment'
import { parse } from 'date-fns'

import type { Moment } from 'moment'

import {
    OPERATING_ROOM_START_HOUR,
    OPERATING_ROOM_END_HOUR,
    API_FORMAT_DATE,
} from 'constants/index'

export function formatMomentDate(date: Moment): string {
    return date.format('DD.MM.YYYY')
}

export function formatDate(dateString: string): string {
    return moment(dateString).format('DD.MM.YYYY')
}

export function formatDateNoYear(dateString: string): string {
    return moment(dateString).format('DD.MM')
}

export function formatDateTime(
    dateString: string,
    dateFormat = 'DD.MM.YYYY HH:mm'
): string {
    return moment(dateString).format(dateFormat)
}

export function formatTime(dateString: string): string {
    return moment(dateString).format('HH:mm')
}

export const parseDateToAPI = (date: Date | undefined) => {
    if (typeof date === 'undefined') {
        return undefined
    }

    return moment(date).format(API_FORMAT_DATE)
}

export const formatDuration = (startAt: Moment, endAt: Moment) => {
    const duration = moment.duration(endAt.diff(startAt))
    const hours = duration.hours()
    const minutes = duration.minutes()

    if (hours === 0) {
        return `${minutes}min`
    }

    return `${hours}h ${minutes}min`
}

export const parseStringApiDateToDate = (dateString: string) => {
    return parse(
        dateString,
        API_FORMAT_DATE.replace('YYYY', 'yyyy').replace('DD', 'dd'),
        new Date()
    )
}

export function timePeriodGenerator(
    interval: number[],
    from?: number,
    to?: number
) {
    from = from ?? OPERATING_ROOM_START_HOUR
    to = to ?? OPERATING_ROOM_END_HOUR

    return Array.from(
        {
            length: to <= from ? 24 + to - from : to - from,
        },
        (v, hour) => {
            return interval.map((minute) => {
                return moment({
                    hour,
                    minute,
                })
                    .add(from, 'hours')
                    .format('HH:mm')
            })
        }
    ).flat()
}

export function timeEvery5minutesPeriodGenerator(from?: number, to?: number) {
    return timePeriodGenerator(
        [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
        from,
        to
    )
}

export function timeEvery15minutesPeriodGenerator(from?: number, to?: number) {
    return timePeriodGenerator([0, 15, 30, 45], from, to)
}

export const TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS =
    timeEvery5minutesPeriodGenerator(0, 24).map((minutes) => ({
        id: minutes,
        name: minutes,
    }))

export const TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS =
    timeEvery15minutesPeriodGenerator(0, 24).map((minutes) => ({
        id: minutes,
        name: minutes,
    }))

export function isDateYesterday(date: Date): boolean {
    const inputDate = moment(date)
    const yesterday = moment().subtract(1, 'days').startOf('day')
    const today = moment().startOf('day')

    return inputDate.isSame(yesterday, 'day') && inputDate.isBefore(today)
}
