const SURGICAL_TEAM_ROLE_LABELS = {
    doctor: 'Lekarz',
    assistant: 'Asysta',
    anesthesiologist: 'Anestezjolog',
    anesthesiologist_nurse: 'Pielęgniarka anestezjologiczna',
    operation_nurse: 'Pielęgniarka operacyjna',
}

const isValidRole = (
    role: string
): role is keyof typeof SURGICAL_TEAM_ROLE_LABELS => {
    return Object.keys(SURGICAL_TEAM_ROLE_LABELS).includes(role)
}

export const getSurgicalTeamRoleLabel = (role: string) => {
    return isValidRole(role) ? SURGICAL_TEAM_ROLE_LABELS[role] : '-'
}
