import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PROCEDURE_CATEGORY_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PROCEDURE_CATEGORIES,
    show: PERMISSIONS.SHOW_PROCEDURE_CATEGORIES,
    create: PERMISSIONS.CREATE_PROCEDURE_CATEGORIES,
    update: PERMISSIONS.UPDATE_PROCEDURE_CATEGORIES,
    delete: PERMISSIONS.DELETE_PROCEDURE_CATEGORIES,
}

export const useProcedureCategoryPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PROCEDURE_CATEGORY_PERMISSIONS),
    }
}
