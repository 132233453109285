import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import classNames from 'classnames'

import { getSurgicalTeamRoleLabel } from 'helpers'

import Portal from './Portal'
import UserAvatar from './UserAvatar'
import popperStyles from './ui/Tooltip.module.scss'

import type { SurgicalTeamMember, EquipmentDropdownItem } from 'types'

type UsePopperTooltip = ReturnType<typeof usePopperTooltip>

const ACCEPTED_ROLE_TO_DISPLAY = [
    'doctor',
    'assistant',
    'anesthesiologist',
    'anesthesiologist_nurse',
    'operation_nurse',
]

const ScheduleOperationSurgicalTeamList: React.FC<{
    isVisible: UsePopperTooltip['visible']
    setTooltipRef: UsePopperTooltip['setTooltipRef']
    getTooltipProps: UsePopperTooltip['getTooltipProps']
    getArrowProps: UsePopperTooltip['getArrowProps']
    surgicalTeam: SurgicalTeamMember[]
    equipment: EquipmentDropdownItem[]
    implants: { id: number; name?: string; label?: string }[]
}> = ({
    isVisible,
    setTooltipRef,
    getTooltipProps,
    getArrowProps,
    equipment,
    implants,
    ...props
}) => {
    const surgicalTeam = props.surgicalTeam.filter(
        (item) => item?.role && ACCEPTED_ROLE_TO_DISPLAY.includes(item.role)
    )

    if (!isVisible) {
        return null
    }

    return (
        <Portal id="schedule-operation-tooltip">
            <div
                ref={setTooltipRef}
                {...getTooltipProps({
                    className: classNames(
                        'tooltip-container',
                        popperStyles.tooltip
                    ),
                })}
            >
                <div
                    {...getArrowProps({
                        className: classNames(
                            'tooltip-arrow',
                            popperStyles.arrow
                        ),
                    })}
                />
                <dl className="w-96 mb-4">
                    {surgicalTeam.length === 0 ? (
                        <div className="flex flex-row items-center w-full">
                            <div className="flex-shrink-0 w-6 h-6 bg-gray-500 rounded-full text-white text-sm leading-6 flex items-center justify-center">
                                -
                            </div>
                            <div className="ml-4 min-w-0 flex-1">
                                <p className="text-xs leading-5">
                                    Nieprzypisany operator
                                </p>
                            </div>
                        </div>
                    ) : (
                        surgicalTeam.map((item) => (
                            <div
                                key={item.id}
                                className="py-1 flex flex-row items-center"
                            >
                                <dt className="flex-1 text-sm text-white w-full">
                                    <div className="flex flex-row items-center w-full">
                                        <div className="flex-shrink-0">
                                            <UserAvatar
                                                data={item}
                                                size={8}
                                                variant="avatar"
                                                withTooltip
                                            />
                                        </div>
                                        <div className="ml-4 min-w-0 flex-1">
                                            <p className="text-xs leading-5">
                                                {item.first_name}{' '}
                                                {item.last_name}
                                            </p>
                                        </div>
                                    </div>
                                </dt>
                                <dd className="flex">
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs bg-blue-100 text-blue-800">
                                        {getSurgicalTeamRoleLabel(item.role)}
                                    </span>
                                </dd>
                            </div>
                        ))
                    )}
                    <div className="mt-3">
                        <h3 className="font-medium">Sprzęt medyczny</h3>
                        <div className="flex flex-wrap gap-1 mt-1.5 text-xs">
                            {equipment.length === 0
                                ? '-'
                                : equipment.map((item) => (
                                      <div
                                          className="p-1 bg-gray-200 text-gray-900 rounded"
                                          key={item.name}
                                      >
                                          {item.name}
                                      </div>
                                  ))}
                        </div>
                    </div>
                    <div className="mt-4 overflow-hidden whitespace-normal">
                        <h3 className="font-medium">Implanty</h3>
                        <span className="inline-block mt-1.5 ">
                            {implants
                                .map((item) => item.label || item.name)
                                .join(', ') || '-'}
                        </span>
                    </div>
                </dl>
            </div>
        </Portal>
    )
}

export default ScheduleOperationSurgicalTeamList
