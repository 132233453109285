import React from 'react'
import { Formik, Form, Field, FieldProps, FormikHelpers } from 'formik'
import { Dialog } from '@headlessui/react'

import { PostPayer } from 'api/types'
import { Modal } from 'components'
import { IModal } from 'types'
import Button from './ui/Button'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export default function SettingPayerCreateModal({
    modal,
    handleSubmit,
}: {
    modal: IModal
    handleSubmit: (
        values: PostPayer,
        formikHelpers: FormikHelpers<PostPayer>
    ) => void
}) {
    return (
        <Modal modal={modal}>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: '',
                        active: false,
                        estimates_available: false,
                    }}
                    enableReinitialize
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div>
                                <div className="text-center">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg leading-6 font-medium text-gray-900"
                                    >
                                        Dodaj kontrahenta
                                    </Dialog.Title>
                                    <div className="mt-6">
                                        <Field name="name">
                                            {({
                                                field,
                                                meta,
                                                form,
                                            }: FieldProps) => (
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Nazwa"
                                                        id="name"
                                                        className={
                                                            meta.touched &&
                                                            meta.error
                                                                ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
                                                                : 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:border-blue-600 sm:text-sm'
                                                        }
                                                        value={field.value}
                                                        onChange={
                                                            form.handleChange
                                                        }
                                                    />
                                                    {meta.touched &&
                                                        meta.error && (
                                                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <ExclamationCircleIcon
                                                                    className="h-5 w-5 text-red-500"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <Button
                                    variant="primary"
                                    className="col-start-2"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zapisz
                                </Button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={() => modal.closeModal()}
                                >
                                    Anuluj
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
