import {
    usePolicy,
    useCommentPolicy,
    useSurgeryPolicy,
    useOperationTaskPolicy,
    useProcedureTypePolicy,
    useSurgeryListPolicyGroup,
} from 'hooks'

export const useCaregiverPanelPolicy = () => {
    const { user } = usePolicy()
    const surgeryPolicy = useSurgeryPolicy()
    const commentPolicy = useCommentPolicy()
    const operationTaskPolicy = useOperationTaskPolicy()
    const procedureTypePolicy = useProcedureTypePolicy()
    const surgeryListPolicyGroup = useSurgeryListPolicyGroup()

    return {
        user,
        canIndex:
            surgeryPolicy.canIndex &&
            surgeryPolicy.canIndexOthers &&
            surgeryPolicy.canShow &&
            operationTaskPolicy.canIndex &&
            operationTaskPolicy.canShow &&
            procedureTypePolicy.canIndex,
        canIndexSurgery: surgeryListPolicyGroup.canIndex,
        surgery: surgeryPolicy,
        comment: commentPolicy,
    }
}
