import React, { useEffect } from 'react'

import { useNotification } from 'hooks'

const AccessDenied: React.FC<{ message: string }> = ({ message }) => {
    const showNotification = useNotification()

    useEffect(() => {
        showNotification({
            content: message,
            type: 'danger',
        })
    }, [message, showNotification])

    return null
}

export default AccessDenied
