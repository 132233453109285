import React, { useEffect, useMemo, useReducer, useState } from 'react'
import omit from 'lodash.omit'
import moment from 'moment'

import { useActivityLogs } from 'api'
import { ListFilterAction, ListFilters, listReducer } from 'helpers'
import { useActivityLogPolicy } from 'hooks'
import { DEFAULT_LIST_PER_PAGE, API_FORMAT_DATE } from 'constants/index'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { FilterButton } from 'components/ui'
import ActivityLogs from 'components/ActivityLog'
import Filters from 'components/ActivityLogFilters'

import type { ActivityLogFilterForm } from 'types'

const initialFilters = {
    sort_by: 'id',
    sort_direction: 'desc',
    length: DEFAULT_LIST_PER_PAGE,
    page: 1,
    form: {
        user: undefined,
        user_phrase: '',
        operation_id: '',
        dateRange: {
            from: undefined,
            to: undefined,
        },
    },
}

export type ActivityLogFilterState = {
    form: ActivityLogFilterForm
} & ListFilters

export type ActivityLogFilterAction =
    | { type: 'change'; payload: ActivityLogFilterForm }
    | { type: 'resetForm'; payload?: never }
    | ListFilterAction

export function activityLogFilterReducer(
    state: ActivityLogFilterState,
    action: ActivityLogFilterAction
) {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                form: action.payload,
                page: 1,
            }

        case 'resetForm':
            return {
                ...state,
                form: { ...initialFilters['form'] },
                page: 1,
            }

        default:
            return listReducer(state, action) as {
                form: ActivityLogFilterForm
            } & ListFilters
    }
}

const ActivityLogContainer = () => {
    const activityLogPolicy = useActivityLogPolicy()

    const [filters, setFilters] = useReducer(
        activityLogFilterReducer,
        initialFilters
    )

    const apiFilters = useMemo(() => {
        return {
            ...omit(filters, 'form'),
            causer_id: filters.form.user?.id,
            operation_id: filters.form.operation_id || undefined,
            from:
                filters.form.dateRange.from && filters.form.dateRange.to
                    ? moment(filters.form.dateRange.from).format(
                          API_FORMAT_DATE
                      )
                    : undefined,
            to:
                filters.form.dateRange.from && filters.form.dateRange.to
                    ? moment(filters.form.dateRange.to).format(API_FORMAT_DATE)
                    : undefined,
        }
    }, [filters])

    const activityLogResource = useActivityLogs(apiFilters, {
        enabled: activityLogPolicy.canIndex,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [filters.page])

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)

    const filtersCount = useMemo(
        () =>
            (filters.form.user ? 1 : 0) +
            (filters.form.dateRange.from && filters.form.dateRange.to ? 1 : 0) +
            (filters.form.operation_id ? 1 : 0),
        [filters.form]
    )

    const handleResetFilters = () => {
        setFilters({ type: 'resetForm' })
    }

    return (
        <ListLayout
            title="Aktywność użytkowników"
            actions={
                <span>
                    {activityLogPolicy.canFilter && (
                        <FilterButton
                            count={filtersCount}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                            filtersExpanded={filtersExpanded}
                            handleReset={handleResetFilters}
                        />
                    )}
                </span>
            }
        >
            <>
                {activityLogPolicy.canFilter && filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                {!activityLogPolicy.canIndex && (
                    <AccessDenied message="Nie masz uprawnień dostępu do aktywności użytkowników" />
                )}
                {activityLogPolicy.canIndex && (
                    <ActivityLogs
                        activityLogResource={activityLogResource}
                        filters={filters}
                        filtersCount={filtersCount}
                        setFilters={setFilters}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default ActivityLogContainer
