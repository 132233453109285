import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Button, Label, Loader } from 'components/ui'
import { TextField, PriceField } from 'components/forms'

import type { FormSubmitFn, IModal } from 'types'
import type { Equipment, PostEquipment, ResponseData } from 'api/types'

export interface FormValues extends PostEquipment {}

const SettingsMedicalEquipmentModal: React.FC<{
    id?: number
    data?: ResponseData<Equipment>
    modal: IModal
    handleSubmit: FormSubmitFn<FormValues>
    buttonDelete: React.ReactChild
}> = ({ id, data, modal, handleSubmit, buttonDelete }) => {
    const isEdit = useMemo(() => !!id, [id])

    return (
        <Modal modal={modal}>
            <div className={styles.modalInner}>
                <Dialog.Title as="h3" className={styles.dialogTitle}>
                    {!id && 'Dodaj sprzęt medyczny'}
                    {id && 'Edytuj sprzęt medyczny'}
                </Dialog.Title>
                {isEdit && !data?.data && <Loader />}
                {((isEdit && data?.data) || !isEdit) && (
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            name: data?.data.name || '',
                            price: data?.data.price || '',
                            liveview_visible: data
                                ? data.data.liveview_visible
                                : false,
                        }}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="mt-6">
                                    <TextField labelText="Nazwa" name="name" />
                                </div>
                                <div className="mt-6">
                                    <Label>Koszty</Label>
                                    <PriceField
                                        name="price"
                                        placeholder="0,00"
                                    />
                                </div>
                                {isEdit && (
                                    <div className="mt-4">{buttonDelete}</div>
                                )}
                                <div className={styles.actionContainer}>
                                    <Button
                                        variant="primary"
                                        className="col-start-2"
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        Zapisz
                                    </Button>
                                    <button
                                        type="button"
                                        className={styles.buttonCancel}
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Modal>
    )
}

export default SettingsMedicalEquipmentModal

const styles = {
    modalInner: classNames(
        'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl',
        'transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
    ),
    dialogTitle: 'text-lg leading-6 font-medium text-gray-900 text-center',
    actionContainer:
        'mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
    buttonCancel: classNames(
        'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2',
        'bg-white text-base font-medium text-gray-700 sm:mt-0 sm:col-start-1 sm:text-sm',
        'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    ),
}
