import React, { useMemo } from 'react'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Button, Loader } from 'components/ui'
import { TextField } from 'components/forms'

import type { FormSubmitFn, IModal } from 'types'
import type {
    PostSpecialization,
    Specialization,
    ResponseData,
} from 'api/types'
import ActionBar from './forms/ActionBar'

const SettingsSpecializationModal: React.FC<{
    id?: number
    data?: ResponseData<Specialization>
    modal: IModal
    handleSubmit: FormSubmitFn<PostSpecialization>
    buttonDelete: React.ReactChild
}> = ({ id, data, modal, handleSubmit, buttonDelete }) => {
    const isEdit = useMemo(() => !!id, [id])

    return (
        <Modal modal={modal}>
            <div className={styles.modalInner}>
                {isEdit && !data?.data && <Loader />}
                {((isEdit && data?.data) || !isEdit) && (
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={{
                            name: data?.data.name || '',
                        }}
                        enableReinitialize
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Dialog.Title
                                    as="h3"
                                    className={styles.dialogTitle}
                                >
                                    {!id && 'Dodaj specjalizację'}
                                    {id && 'Edytuj specjalizację'}
                                </Dialog.Title>
                                <div className="mt-6">
                                    <TextField name="name" />
                                </div>
                                {!!isEdit && (
                                    <div className="mt-6">
                                        <ActionBar
                                            leftSide={() => <>{buttonDelete}</>}
                                            rightSide={() => (
                                                <>
                                                    <Button
                                                        type="button"
                                                        onClick={() =>
                                                            modal.closeModal()
                                                        }
                                                    >
                                                        Anuluj
                                                    </Button>
                                                    <Button
                                                        className="ml-3"
                                                        variant="primary"
                                                        type="submit"
                                                        loading={isSubmitting}
                                                    >
                                                        Zapisz
                                                    </Button>
                                                </>
                                            )}
                                        />
                                    </div>
                                )}
                                {!isEdit && (
                                    <div className={styles.actionContainer}>
                                        <Button
                                            type="button"
                                            className="col-span-1"
                                            variant="default"
                                            onClick={() => modal.closeModal()}
                                        >
                                            Anuluj
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="col-span-1"
                                            variant="primary"
                                            loading={isSubmitting}
                                        >
                                            Zapisz
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Modal>
    )
}

export default SettingsSpecializationModal

const styles = {
    modalInner: classNames(
        'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl',
        'transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
    ),
    dialogTitle: 'text-lg leading-6 font-medium text-gray-900 text-center',
    actionContainer:
        'mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
}
