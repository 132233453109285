import React from 'react'
import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import moment from 'moment'

import { Modal } from 'components'
import { useGetOperationImplantsHistory } from 'api'
import styles from './Modal.module.scss'
import UserAvatar from './UserAvatar'
import { ChatAltIcon } from '@heroicons/react/solid'
import { Spinner } from './ui'
import { IModal } from '../types'
import { OperationImplantsHistoryItem } from '../api/types'
import { twMerge } from 'tailwind-merge'

const ImplantsHistoryModal: React.FC<{
    modal: IModal
    operationId: number
    onClose: () => void
}> = ({ operationId, onClose, modal }) => {
    const { data: implantsHistoryData, isLoading: isImplantsHistoryLoading } =
        useGetOperationImplantsHistory(operationId, {
            page: 1,
            length: 999,
        })

    const renderItem = (
        item: OperationImplantsHistoryItem['modifications']['added'][0],
        index: number,
        color?: 'red' | 'green'
    ) => (
        <div
            className={twMerge(
                'flex items-center justify-between gap-2 p-2 text-sm bg-gray-100 text-gray-900 w-full border border-gray-100',
                color === 'red' && 'border-red-400',
                color === 'green' && 'border-green-400'
            )}
            key={`${index}-${item.id}`}
        >
            {item.name}
            <span className="border border-gray-300 bg-white rounded py-1 px-3 shadow-sm text-gray-600 leading-none">
                {item.count} szt.
            </span>
        </div>
    )

    return (
        <>
            <Modal modal={modal} onClickOutside={onClose}>
                <div className={classNames(styles.inner, 'p-6 my-2 max-w-4xl')}>
                    <Dialog.Title as="h3" className={styles.title}>
                        Historia implantów
                    </Dialog.Title>

                    <div className="flex gap-4 mt-6 flex-col md:flex-row">
                        {isImplantsHistoryLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <div className="w-full">
                                    <span className="text-base leading-none font-normal text-gray-500 w-full text-center inline-block">
                                        Aktualne implanty
                                    </span>

                                    <ul className="flex flex-col gap-2 md:max-h-96 md:overflow-auto mt-3">
                                        <li className="flex justify-between gap-2">
                                            <div className="flex flex-col gap-2 items-center">
                                                <div className="relative">
                                                    <UserAvatar
                                                        data={{
                                                            first_name:
                                                                implantsHistoryData
                                                                    ?.meta
                                                                    .operation
                                                                    .causer
                                                                    .first_name ||
                                                                '',
                                                            last_name:
                                                                implantsHistoryData
                                                                    ?.meta
                                                                    .operation
                                                                    .causer
                                                                    .last_name ||
                                                                '',
                                                            avatar_url:
                                                                implantsHistoryData
                                                                    ?.meta
                                                                    .operation
                                                                    .causer
                                                                    .avatar_url ||
                                                                '',
                                                        }}
                                                        variant="avatar"
                                                    />
                                                    <ChatAltIcon className="absolute bottom-0 right-0 w-1/2 p-0 bg-white rounded-tl text-gray-400 pl-0.5" />
                                                </div>

                                                <div className="bg-gray-200 flex-1 w-0.5" />
                                            </div>

                                            <div className="flex-1 flex flex-col">
                                                <span className="text-sm leading-5 font-medium text-gray-900">
                                                    {
                                                        implantsHistoryData
                                                            ?.meta.operation
                                                            .causer.full_name
                                                    }
                                                </span>

                                                <span className="text-xs leading-none font-normal text-gray-500">
                                                    {moment(
                                                        implantsHistoryData
                                                            ?.meta.operation
                                                            .created_at
                                                    ).format('LLL')}
                                                </span>

                                                {!implantsHistoryData?.meta
                                                    .operation.implants
                                                    .length ? (
                                                    '-'
                                                ) : (
                                                    <div className="flex flex-col items-start gap-2 mt-2">
                                                        {implantsHistoryData?.meta.operation.implants.map(
                                                            (item, index) => (
                                                                <div
                                                                    className="flex items-center justify-between gap-2 p-2 text-sm bg-gray-100 text-gray-900 w-full border border-gray-100"
                                                                    key={`${index}-${item.id}`}
                                                                >
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="w-px bg-gray-200" />

                                <div className="w-full">
                                    <span className="text-base leading-none font-normal text-gray-500 w-full text-center inline-block">
                                        Zmiany na implantach
                                    </span>

                                    <ul className="flex flex-col gap-2 md:max-h-96 md:overflow-auto mt-3 pr-0.5">
                                        {implantsHistoryData?.data.map(
                                            (item, index) => (
                                                <>
                                                    {item.modifications.changed.filter(
                                                        (changedItem) =>
                                                            !!changedItem.diff
                                                    ).length +
                                                        item.modifications.added
                                                            .length +
                                                        item.modifications
                                                            .removed.length >
                                                        0 && (
                                                        <li
                                                            className="flex justify-between gap-2"
                                                            key={`${index}-${item.id}`}
                                                        >
                                                            <div className="flex flex-col gap-2 items-center">
                                                                <div className="relative">
                                                                    <UserAvatar
                                                                        data={{
                                                                            first_name:
                                                                                item
                                                                                    .causer
                                                                                    .first_name,
                                                                            last_name:
                                                                                item
                                                                                    .causer
                                                                                    .last_name,
                                                                            avatar_url:
                                                                                item
                                                                                    .causer
                                                                                    .avatar_url ||
                                                                                '',
                                                                        }}
                                                                        variant="avatar"
                                                                    />
                                                                    <ChatAltIcon className="absolute bottom-0 right-0 w-1/2 p-0 bg-white rounded-tl text-gray-400 pl-0.5" />
                                                                </div>

                                                                <div className="bg-gray-200 flex-1 w-0.5" />
                                                            </div>

                                                            <div className="flex-1 flex flex-col">
                                                                <span className="text-sm leading-5 font-medium text-gray-900">
                                                                    {
                                                                        item
                                                                            .causer
                                                                            .full_name
                                                                    }
                                                                </span>

                                                                <span className="text-xs leading-none font-normal text-gray-500">
                                                                    {moment(
                                                                        item.created_at
                                                                    ).format(
                                                                        'LLL'
                                                                    )}
                                                                </span>

                                                                {item.modifications.changed.filter(
                                                                    (
                                                                        changedItem
                                                                    ) =>
                                                                        !!changedItem.diff
                                                                ).length >
                                                                    0 && (
                                                                    <div className="flex flex-col items-start gap-2 mt-2">
                                                                        <span className="text-xs leading-none text-gray-500">
                                                                            Zmieniono
                                                                        </span>

                                                                        {item.modifications.changed
                                                                            .filter(
                                                                                (
                                                                                    changedItem
                                                                                ) =>
                                                                                    !!changedItem.diff
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) =>
                                                                                    renderItem(
                                                                                        item,
                                                                                        index
                                                                                    )
                                                                            )}
                                                                    </div>
                                                                )}

                                                                {item
                                                                    .modifications
                                                                    .added
                                                                    .length >
                                                                    0 && (
                                                                    <div className="flex flex-col items-start gap-2 mt-2">
                                                                        <span className="text-xs leading-none text-gray-500">
                                                                            Dodano
                                                                        </span>

                                                                        {item.modifications.added.map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) =>
                                                                                renderItem(
                                                                                    item,
                                                                                    index,
                                                                                    'green'
                                                                                )
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {item
                                                                    .modifications
                                                                    .removed
                                                                    .length >
                                                                    0 && (
                                                                    <div className="flex flex-col items-start gap-2 mt-2">
                                                                        <span className="text-xs leading-none text-gray-500">
                                                                            Usunięto
                                                                        </span>

                                                                        {item.modifications.removed.map(
                                                                            (
                                                                                item,
                                                                                index
                                                                            ) =>
                                                                                renderItem(
                                                                                    item,
                                                                                    index,
                                                                                    'red'
                                                                                )
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    )}
                                                </>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>

                    <button
                        onClick={() => onClose()}
                        className="shadow-sm w-full rounded-md border border-gray-300 px-4 py-2 mt-6"
                    >
                        Zamknij
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default ImplantsHistoryModal
