import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import omit from 'lodash.omit'

import {
    API_FORMAT_DATE,
    DEFAULT_LIST_PER_PAGE,
    SESSION_STORAGE_SURGERY_LIST_FILTER_KEY,
} from 'constants/index'
import { exportOperationList, useInfiniteOperations } from 'api'
import { useSurgeryListPolicyGroup, useSurgeryPolicy } from 'hooks'
import { AccessDenied } from 'components'
import { FilterButton, ExportButton, ButtonPrint } from 'components/ui'
import ListLayout from 'layouts/ListLayout'
import Filters from 'components/SurgeryListFilters'
import SurgeryList from 'components/SurgeryList'
import { downloadFileFactory } from 'helpers'

import { surgeryListFilterSchema } from 'types/SurgeryList'
import type { ISurgeryListFilters } from 'types/SurgeryList'

export const initialFilters: ISurgeryListFilters = {
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'estimated_date',
    sort_direction: 'asc',
    query: '',
    operator: undefined,
    date_range: {
        from: moment().toDate(),
        to: moment().add(moment().daysInMonth(), 'days').toDate(),
    },
    payer_type: undefined,
    procedure_types: [],
    actual_state: [],
    progress_status: [],
}

export const transformSurgeryListFiltersToApi = (
    filters: ISurgeryListFilters
) => {
    return Object.assign(
        {},
        { ...omit(filters, 'date_range') },
        { doctor_id: filters.operator?.id },
        filters.date_range.from && filters.date_range.to
            ? {
                  estimated_date_from: moment(filters.date_range.from).format(
                      API_FORMAT_DATE
                  ),
                  estimated_date_to: moment(filters.date_range.to).format(
                      API_FORMAT_DATE
                  ),
              }
            : {},
        {
            procedure_types: filters.procedure_types
                ? [...filters.procedure_types.map((item) => item.id)]
                : [],
        },
        {
            payer_type: filters.payer_type?.id,
        },
        {
            actual_state: filters.actual_state
                ? [...filters.actual_state.map((item) => item.id)]
                : [],
        },
        {
            progress_status: filters.progress_status
                ? [...filters.progress_status.map((item) => item.id)]
                : [],
        }
    )
}

export const sortingState =
    (sort_by: string, sort_direction: string) =>
    (prevState: ISurgeryListFilters) => ({
        ...prevState,
        sort_by,
        sort_direction,
    })

export const resetFilterState = () => (prevState: ISurgeryListFilters) => ({
    ...prevState,
    query: '',
    operator: undefined,
    date_range: {
        from: undefined,
        to: undefined,
    },
    procedure_types: [],
    payer_type: undefined,
    actual_state: [],
    progress_status: [],
})

export const countFilters = (filters: ISurgeryListFilters) =>
    (filters.query.length ? 1 : 0) +
    (filters.operator ? 1 : 0) +
    (filters.date_range.from && filters.date_range.to ? 1 : 0) +
    (filters.procedure_types.length ? 1 : 0) +
    (filters.payer_type ? 1 : 0) +
    (filters.actual_state.length ? 1 : 0) +
    (filters.progress_status.length ? 1 : 0)

const SurgeryListContainer: React.FC = () => {
    const surgeryListPolicyGroup = useSurgeryListPolicyGroup()
    const surgeryPolicy = useSurgeryPolicy()
    const [isExporting, setIsExporting] = useState(false)

    const [filters, setFilters] = useState(() => {
        const storedFilters = window.sessionStorage.getItem(
            SESSION_STORAGE_SURGERY_LIST_FILTER_KEY
        )

        if (storedFilters) {
            try {
                const restoredFilters = JSON.parse(storedFilters)

                const result =
                    surgeryListFilterSchema.safeParse(restoredFilters)

                if (result.success) {
                    return {
                        ...initialFilters,
                        ...result.data,
                        date_range:
                            result.data.date_range.from &&
                            result.data.date_range.to
                                ? {
                                      from: new Date(
                                          Date.parse(
                                              result.data.date_range.from
                                          )
                                      ),
                                      to: new Date(
                                          Date.parse(result.data.date_range.to)
                                      ),
                                  }
                                : {
                                      from: undefined,
                                      to: undefined,
                                  },
                    }
                }
            } catch (e) {}
        }

        return initialFilters
    })

    const setSorting = useCallback(
        (sort_by: string, sort_direction: string) => {
            setFilters(sortingState(sort_by, sort_direction))
        },
        [setFilters]
    )

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)

    const filtersCount = useMemo(() => countFilters(filters), [filters])

    useEffect(() => {
        window.sessionStorage.setItem(
            SESSION_STORAGE_SURGERY_LIST_FILTER_KEY,
            JSON.stringify(filters)
        )
    }, [filters])

    const surgeryQueryResult = useInfiniteOperations(
        transformSurgeryListFiltersToApi(filters),
        {
            cacheTime: 0,
            enabled: surgeryListPolicyGroup.canIndex,
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                    ? lastPage.meta.current_page + 1
                    : undefined
            },
        }
    )

    const handleResetFilters = () => {
        setFilters(resetFilterState())
    }

    const exportXlsx = () => {
        setIsExporting(true)

        downloadFileFactory({
            fetchFunction: () =>
                exportOperationList(
                    transformSurgeryListFiltersToApi(filters)
                ).finally(() => setIsExporting(false)),
            fileName: 'Lista operacji',
        })
    }

    return (
        <ListLayout
            title="Lista operacji"
            actions={
                <>
                    {surgeryPolicy.canExport ? (
                        <span className="ml-2">
                            <ExportButton
                                onClick={exportXlsx}
                                isExporting={isExporting}
                            />
                        </span>
                    ) : null}
                    {surgeryListPolicyGroup.canIndex ? (
                        <span className="ml-4 space-x-4">
                            <ButtonPrint />
                            <FilterButton
                                count={filtersCount}
                                onClick={() =>
                                    setFiltersExpanded(!filtersExpanded)
                                }
                                filtersExpanded={filtersExpanded}
                                handleReset={handleResetFilters}
                            />
                        </span>
                    ) : null}
                </>
            }
        >
            <>
                {!surgeryListPolicyGroup.canIndex && (
                    <AccessDenied message="Nie masz uprawnień do wyświetlania listy operacji" />
                )}
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                <SurgeryList
                    surgeryQueryResult={surgeryQueryResult}
                    filters={filters}
                    filtersCount={filtersCount}
                    setSorting={setSorting}
                />
            </>
        </ListLayout>
    )
}

export default SurgeryListContainer
