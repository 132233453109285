import { useCallback, useState } from 'react'

export const useModal = <T>(initialIsOpen = false) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen)
    const [state, setState] = useState<T>()

    const openModal = useCallback(() => setIsOpen(true), [])
    const closeModal = useCallback((onHide = () => {}) => {
        setIsOpen(false)
        /* clear the data after the modal exit animation finishes and execute external function (if exists)*/
        setTimeout(() => {
            typeof onHide === 'function' && onHide()
            setState(undefined)
        }, 300)
    }, [])
    const toggleModal = useCallback(
        () => setIsOpen((prevState) => !prevState),
        []
    )
    const getState = useCallback(() => state, [state])

    return {
        isOpen,
        getState,
        setState,
        openModal,
        closeModal,
        toggleModal,
    }
}
