import React from 'react'
import { UseQueryResult } from 'react-query'
import classNames from 'classnames'

import { IdentificationCard, ResponseList } from 'api/types'
import { Button, Spinner, TableFooter } from 'components/ui'
import Sort from 'components/Sort'
import UserAvatar from 'components/UserAvatar'

import type { IModal } from 'types'
import type { SetSortingFn } from 'components/Sort'
import type { TDoctorPatientListFilter } from 'containers/DoctorPatientList'

interface RFIDCardListProps {
    queryResult: UseQueryResult<ResponseList<IdentificationCard[]>>
    filters: TDoctorPatientListFilter
    filtersCount: number
    setSorting: SetSortingFn
    setPage: (page: number) => void
    identificationCardsEditModal: IModal
    canEditAndDelete: boolean
}

const SettingsRFIDCardList: React.FC<RFIDCardListProps> = ({
    queryResult,
    filters,
    filtersCount,
    setSorting,
    setPage,
    identificationCardsEditModal,
    canEditAndDelete,
}) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{ width: '1%' }}
                        >
                            <Sort
                                name="id"
                                filters={filters}
                                setSorting={setSorting}
                            >
                                Id
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="w-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{ width: '1%' }}
                        >
                            <Sort
                                name="number"
                                filters={filters}
                                setSorting={setSorting}
                            >
                                Numer karty
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-6 pl-24 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                            style={{ width: '1%' }}
                        >
                            Imię i nazwisko
                        </th>
                        <th
                            scope="col"
                            className="w-auto px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            &nbsp;
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{ width: '1%' }}
                        >
                            Stan
                        </th>
                        <th
                            scope="col"
                            className="w-36 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{ width: '1%' }}
                        >
                            Opcje
                        </th>
                    </tr>
                </thead>
                {queryResult.isSuccess && (
                    <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                        {queryResult.data.data.map((item, index) => {
                            const isAssigned = !!item.user
                            const isActive = item.active

                            return (
                                <tr
                                    key={item.id}
                                    className={classNames({
                                        'bg-gray-50': index % 2,
                                    })}
                                >
                                    <td className="px-6 py-6 whitespace-nowrap">
                                        <span className="text-gray-500 font-normal">
                                            {item.id}
                                        </span>
                                    </td>
                                    <td className="px-6 py-6">
                                        <span className="text-gray-500 font-medium">
                                            {item.number}
                                        </span>
                                    </td>
                                    <td className="px-6 pl-24 py-2 whitespace-nowrap">
                                        {item.user ? (
                                            <UserAvatar
                                                variant="name"
                                                data={item.user}
                                                size={8}
                                            />
                                        ) : (
                                            <span>-</span>
                                        )}
                                    </td>
                                    <td className="px-6 py-6"></td>
                                    <td className="px-6 py-6">
                                        {isAssigned ? (
                                            isActive ? (
                                                <span
                                                    className={classNames(
                                                        'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-gray-800',
                                                        {
                                                            'bg-white':
                                                                index % 2,
                                                            'bg-gray-100': !(
                                                                index % 2
                                                            ),
                                                        }
                                                    )}
                                                >
                                                    Przypisana
                                                </span>
                                            ) : (
                                                <span className="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                                    Zablokowana
                                                </span>
                                            )
                                        ) : (
                                            <span className="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                                                Nieprzypisana
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-2">
                                        <div className="flex justify-end items-center">
                                            <Button
                                                className="ml-2"
                                                size="sm"
                                                variant={
                                                    isAssigned
                                                        ? 'default'
                                                        : 'tertiary'
                                                }
                                                onClick={() => {
                                                    identificationCardsEditModal.setState(
                                                        item
                                                    )
                                                    identificationCardsEditModal.openModal()
                                                }}
                                                disabled={!canEditAndDelete}
                                            >
                                                {isAssigned
                                                    ? 'Edytuj'
                                                    : 'Przypisz'}
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                )}
            </table>
            {queryResult.isLoading && (
                <div className="divide-y divide-gray-200 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 5 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {queryResult.isSuccess && queryResult.data.meta.total === 0 && (
                <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 5 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        {filtersCount === 0 && (
                                            <span>
                                                Nie ma jeszcze żadnych kart.
                                            </span>
                                        )}
                                        {filtersCount > 0 && (
                                            <span>
                                                Nie znaleziono wyników
                                                wyszukiwania.
                                            </span>
                                        )}
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={queryResult.data?.meta}
                setFilters={(action) =>
                    action.type === 'setPage' && setPage(action.payload)
                }
            />
        </div>
    )
}

export default SettingsRFIDCardList
