import React, { useEffect } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'

import { getUsers } from 'api'
import { UserDropdownItem, SearchableDropdownField } from 'components/forms'
import UserAvatar from 'components/UserAvatar'

const EmployeePickerField: React.FC<{
    name: string
    size?: 'md' | 'lg'
    disabled?: boolean
    hasError?: boolean
    functions: string[]
    placeholder?: string
    tabIndex?: number
    messageEmptyResult?: string
    onChange?: (value: UserDropdownItem) => void
    handleRemove?: () => void
    multiChoice?: boolean
}> = ({
    name,
    size = 'md',
    disabled = false,
    hasError,
    functions,
    placeholder,
    tabIndex,
    messageEmptyResult,
    onChange,
    handleRemove,
    multiChoice,
}) => {
    const internalName = `____${name}`
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [internalField, internalFieldMeta, internalFieldHelpers] = useField<
        UserDropdownItem | UserDropdownItem[] | undefined | null
    >(internalName)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, fieldMeta, fieldHelpers] = useField<
        UserDropdownItem | UserDropdownItem[] | undefined | null
    >(name)

    useEffect(() => {
        Array.isArray(field.value)
            ? internalFieldHelpers.setValue(field.value?.at(0))
            : internalFieldHelpers.setValue(field.value)
    }, [field.value]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        !multiChoice &&
            typeof internalField.value === 'undefined' &&
            typeof field.value !== 'undefined' &&
            internalFieldHelpers.setValue(field.value)
    }, [internalField.value]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleInternalChange = (value: UserDropdownItem) => {
        if (multiChoice) {
            const currentValue = Array.isArray(field.value) ? field.value : []

            fieldHelpers.setValue(
                currentValue.some((item) => item.id === value.id)
                    ? currentValue.filter((item) => item.id !== value.id)
                    : [...currentValue, value]
            )
        } else {
            fieldHelpers.setValue(value)
        }

        onChange?.(value)
    }

    const handleInternalRemove = () => {
        fieldHelpers.setValue(multiChoice ? [] : null)
        handleRemove && handleRemove()
    }

    return (
        <SearchableDropdownField<UserDropdownItem>
            tabIndex={tabIndex}
            useSearchPhraseField={false}
            placeholder={placeholder || 'Szukaj'}
            name={internalName}
            variant={size}
            disabled={disabled}
            hasError={hasError}
            queryFn={getUsers}
            queryFilters={{
                function: functions,
            }}
            renderCurrentOption={(item, setOpen) => (
                <div
                    className={classNames(
                        'px-3 flex items-center border border-gray-300 placeholder-gray-400 text-gray-900 rounded-md',
                        {
                            'bg-white cursor-pointer': !disabled,
                            'bg-gray-100 pointer-events-none': disabled,
                            'border-red-300 text-red-900 focus:ring-red-500 focus:border-red-500 ring-1 ring-offset-0 ring-red-300':
                                hasError,
                            'py-1.5 text-sm': size === 'md',
                            'py-2 text-base': size === 'lg',
                        }
                    )}
                    onClick={() => setOpen(true)}
                >
                    {!!field.value && (
                        <>
                            {multiChoice && Array.isArray(field.value) ? (
                                field.value.map((user) => (
                                    <div className="flex mr-1" key={user.id}>
                                        <UserAvatar
                                            variant="avatar"
                                            data={user}
                                            size={6}
                                            withTooltip
                                        />
                                    </div>
                                ))
                            ) : (
                                <div
                                    className="flex items-center"
                                    style={{ width: 'calc(100% - 44px)' }}
                                >
                                    <div className="flex-shrink-0">
                                        <UserAvatar
                                            variant="avatar"
                                            data={item}
                                            size={6}
                                        />
                                    </div>
                                    <div className="ml-3 min-w-0 flex-1">
                                        <p className="truncate">
                                            {item.first_name} {item.last_name}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            renderOption={(item) => (
                <div className="flex items-center">
                    {multiChoice && Array.isArray(field.value) && (
                        <div className="flex-shrink-0 mr-4">
                            <input
                                name={name}
                                type="checkbox"
                                className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded cursor-pointer "
                                checked={field.value.some(
                                    (fieldItem) => fieldItem.id === item.id
                                )}
                                readOnly
                            />
                        </div>
                    )}
                    <div className="flex-shrink-0">
                        <UserAvatar variant="avatar" data={item} size={10} />
                    </div>
                    <div className="ml-4 min-w-0 flex-1">
                        <p className="text-sm font-medium text-gray-900">
                            {item.first_name} {item.last_name}
                        </p>
                        <p className="text-sm font-normal text-gray-500">
                            {item.role_name}
                        </p>
                    </div>
                </div>
            )}
            renderOptions={(options) => (
                <div>
                    <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                        Podpowiedzi
                    </div>
                    {options}
                </div>
            )}
            handleChange={handleInternalChange}
            handleRemove={handleInternalRemove}
            messageEmptyResult={
                messageEmptyResult || 'Nie znaleziono pracownika.'
            }
        />
    )
}

export default EmployeePickerField
