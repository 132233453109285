import React from 'react'
import { Form, Formik } from 'formik'
import { PlusIcon } from '@heroicons/react/solid'

import { Button, InputText, Label, PhoneInput } from 'components/ui'
import AvatarUploader from 'components/AvatarUploader'
import { UploaderState } from 'components/FileUploader'
import { ActionBar, TextAreaField, TextField } from 'components/forms'

import type { FormSubmitFn, ProfileForm } from 'types'
import type { UserResponse } from 'api/types'

const ProfileEdit: React.FC<{
    user: UserResponse
    handleSubmit: FormSubmitFn<ProfileForm>
}> = ({ user, handleSubmit }) => {
    const initialValues: ProfileForm = {
        avatar: undefined,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        pesel: user.pesel || '',
        phone: user.phone || '',
        address: user.address || '',
        license: user.license || '',
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors }) => (
                <Form>
                    <div className="rounded-md bg-white">
                        <div className="divide-gray-200 divide-y">
                            <div className="mb-16 pt-12 px-6">
                                <div className="text-gray-700 mb-16">
                                    <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x">
                                        <div className="col-span-1 pr-8">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Profil
                                            </div>
                                            <div className="divide-gray-200 divide-y">
                                                <section className="pb-8">
                                                    <div className="mt-6">
                                                        <Label>
                                                            Zdjęcie profilowe
                                                        </Label>
                                                        <div className="mt-6">
                                                            <AvatarUploader
                                                                url={
                                                                    user?.avatar_url
                                                                }
                                                                handleUploadSuccess={(
                                                                    value: UploaderState
                                                                ) =>
                                                                    setFieldValue(
                                                                        'avatar',
                                                                        value
                                                                            .addedFile
                                                                            .id
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Imię"
                                                            name="first_name"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Nazwisko"
                                                            name="last_name"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="PESEL"
                                                            name="pesel"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="E-mail"
                                                            name="email"
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <PhoneInput
                                                            label="Telefon"
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'phone',
                                                                    value
                                                                )
                                                            }
                                                            value={values.phone}
                                                            error={errors.phone}
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextAreaField
                                                            labelText="Adres"
                                                            name="address"
                                                            rows={3}
                                                        />
                                                    </div>
                                                    <div className="mt-6">
                                                        <TextField
                                                            labelText="Numer prawa wykonania zawodu"
                                                            name="license"
                                                        />
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="col-span-1 pl-8">
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Uprawnienia
                                            </div>
                                            <section className="mt-4 pb-8">
                                                <dl className="divide-y divide-gray-200">
                                                    <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                        <dt className="text-sm text-gray-500">
                                                            Specjalizacja
                                                        </dt>
                                                        <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            {user
                                                                .specializations
                                                                .length === 0 &&
                                                                '-'}
                                                            {user
                                                                .specializations
                                                                .length > 0 &&
                                                                user.specializations
                                                                    .map(
                                                                        (i) =>
                                                                            i.name
                                                                    )
                                                                    .join(',')}
                                                        </dd>
                                                    </div>
                                                    <div className="px-6 py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                        <dt className="text-sm text-gray-500">
                                                            Rola
                                                        </dt>
                                                        <dd className="flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            {!!user.role &&
                                                                user.role.name}
                                                            {!user.role && '-'}
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </section>
                                            <div className="text-lg leading-6 font-medium text-gray-900">
                                                Pin użytkownika
                                            </div>
                                            <section className="pb-8">
                                                <div className="mt-6">
                                                    <div className="grid grid-cols-6 gap-x-8 gap-y-8">
                                                        <div className="col-span-1">
                                                            <InputText
                                                                className="pr-0"
                                                                disabled
                                                                value={
                                                                    user?.pin
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-6 py-12">
                                <ActionBar
                                    rightSide={({ isSubmitting }) => (
                                        <>
                                            <Button as="link" to="/profile">
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={<PlusIcon />}
                                            >
                                                Zapisz
                                            </Button>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ProfileEdit
