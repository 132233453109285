import React, { useMemo, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import { FormSubmitFn, ImplantDropdownItem } from 'types'
import { Implant, ImplantSet, ResponseData } from 'api/types'
import {
    DropdownItem,
    ProcedureField,
    PriceField,
    TextField,
    SearchableDropdownField,
    ProcedureDropdownItem,
} from 'components/forms'
import { Button, CancelButton, Label } from 'components/ui'
import { formatCurrency, formatPrice } from 'helpers/currencies'
import { getDropdownImplants } from 'api'

type FormImplant = {
    id: Implant['id']
    label: Implant['label']
    name: Implant['name']
    manufacturer: string
    price: Implant['price']
}

export interface FormValues extends Omit<ImplantSet, 'id' | 'implants'> {
    implants: FormImplant[]
    _procedure_item?: ProcedureDropdownItem
    _procedure_item_phrase: ''
    _implant_item?: ImplantDropdownItem
    _implant_item_phrase: string
}

interface SettingsImplantSetFormProps {
    id?: string
    data?: ResponseData<ImplantSet>
    implants: ResponseData<Implant[]>
    handleSubmit: FormSubmitFn<FormValues>
}

const getImplantsFromQueryStringOrEmptyList = (
    searchParams: URLSearchParams,
    implants: ResponseData<Implant[]>
): FormImplant[] => {
    if (searchParams.getAll('implants').length === 0) {
        return []
    }

    return searchParams
        .getAll('implants')
        .filter((id) => implants.data.find((i) => i.id === Number(id)))
        .map((id) => implants.data.find((i) => i.id === Number(id))!)
        .map((i) => ({
            ...i,
            manufacturer: i.manufacturer?.name || '',
        }))
}

const SettingsImplantSetForm: React.FC<SettingsImplantSetFormProps> = ({
    id,
    data,
    implants,
    handleSubmit,
}) => {
    const [searchParams] = useSearchParams()

    const initialValues: FormValues = useMemo(
        () => ({
            set: true,
            name: data?.data.name || '',
            surcharge: data?.data.surcharge || '',
            procedures:
                data?.data.procedures.map((item) => ({
                    id: item.id,
                    name: item.name,
                })) || [],
            _procedure_item: undefined,
            _procedure_item_phrase: '',
            implants: data
                ? data.data.implants
                    ? data.data.implants
                    : []
                : getImplantsFromQueryStringOrEmptyList(searchParams, implants),
            _implant_item: undefined,
            _implant_item_phrase: '',
        }),
        [data, searchParams, implants]
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnChange={false}
            enableReinitialize
        >
            {({ isSubmitting, values, errors }) => {
                const implantsTotal = values.implants.reduce(
                    (acc, item) => (acc += Number(item!.price)),
                    0
                )

                const total = implantsTotal + (Number(values.surcharge) || 0)

                return (
                    <div className="py-6">
                        <div className="px-4 mb-4 sm:px-6 md:px-8">
                            <div className="flex gap-4 items-center justify-between">
                                <div className="flex-none rounded-lg flex items-center justify-center">
                                    <h2 className="text-2xl leading-8">
                                        {!id && (
                                            <span className="font-semibold">
                                                Stwórz zestaw implantów
                                            </span>
                                        )}
                                        {!!id && (
                                            <span className="font-semibold">
                                                Edytuj zestaw implantów
                                            </span>
                                        )}
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 mb-6 sm:px-6 md:px-8">
                            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                                <Form>
                                    <div className="rounded-md bg-white">
                                        <div className="pt-12 px-6">
                                            <div className="border-b">
                                                <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                                    <div className="col-span-6">
                                                        <div className="pr-8">
                                                            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                                Informacje
                                                            </div>
                                                            <div className="mt-4">
                                                                <TextField
                                                                    labelText="Nazwa"
                                                                    name="name"
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="grid grid-cols-12 gap-4">
                                                                    <div className="col-span-6">
                                                                        <Label>
                                                                            Koszty
                                                                            implantów
                                                                        </Label>
                                                                        <PriceField
                                                                            name="price"
                                                                            value={formatPrice(
                                                                                implantsTotal
                                                                            )}
                                                                            disabled={
                                                                                true
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-6">
                                                                        <Label>
                                                                            Koszty
                                                                            dodatkowe
                                                                        </Label>
                                                                        <PriceField
                                                                            name="surcharge"
                                                                            placeholder="0,00"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mt-4">
                                                                    <Label>
                                                                        Koszt
                                                                        łączny
                                                                    </Label>
                                                                    <span className="text-2xl leading-8 font-black text-gray-900 align-middle">
                                                                        {formatCurrency(
                                                                            total
                                                                        ).replace(
                                                                            'zł',
                                                                            ''
                                                                        )}
                                                                    </span>
                                                                    <span className="text-lg leading-7 font-normal text-gray-500 align-bottom">
                                                                        zł
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6">
                                                        <div className="pl-8">
                                                            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                                Występowanie
                                                            </div>
                                                            <div className="mt-4">
                                                                <Label>
                                                                    Operacja
                                                                </Label>
                                                                <ProcedureFieldArray name="procedures" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-8 px-6">
                                            <div className="border-b">
                                                <div className="mb-8">
                                                    <ImplantFieldArray />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-6 py-12 text-right">
                                            <CancelButton
                                                as="link"
                                                to="/settings/price-list/implant-sets"
                                            >
                                                Anuluj
                                            </CancelButton>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={<PlusIcon />}
                                            >
                                                Zapisz
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Formik>
    )
}

const ProcedureFieldArray: React.FC<{
    name: string
}> = ({ name }) => {
    return (
        <FieldArray
            name={name}
            render={({
                push,
                remove,
                form: { values, errors, setFieldValue },
            }) => {
                return (
                    <div>
                        <div className="flex items-center">
                            <div className="flex-grow w-10/12">
                                <ProcedureField name="_procedure_item" />
                            </div>
                            <div className="flex-shrink-0 ml-2">
                                <button
                                    type="button"
                                    className="inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none"
                                    onClick={() => {
                                        if (!values._procedure_item) {
                                            return
                                        }
                                        push(values._procedure_item)
                                        setFieldValue(
                                            '_procedure_item',
                                            undefined
                                        )
                                        setFieldValue(
                                            '_procedure_item_phrase',
                                            ''
                                        )
                                    }}
                                >
                                    Dodaj
                                    <PlusIcon
                                        className="ml-2 h-3 w-3"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                        {!!errors._procedures && (
                            <p className="mt-1 text-sm text-red-600">
                                {errors._procedures}
                            </p>
                        )}
                        <div className="mt-6">
                            {(values[name] as DropdownItem[]).map(
                                (p, index) => (
                                    <div
                                        key={index}
                                        className="mb-0.5 p-2 flex items-center justify-between bg-gray-100 text-gray-900 leading-5 font-normal"
                                    >
                                        <span className="text-sm truncate">
                                            {p.name}
                                        </span>
                                        <div
                                            className="ml-2 flex-shrink-0 flex cursor-pointer"
                                            onClick={() => remove(index)}
                                        >
                                            <span className="text-gray-500 hover:text-gray-700">
                                                <XIcon className="ml-2 h-5 w-5" />
                                            </span>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )
            }}
        />
    )
}

const ImplantFieldArray = () => {
    const [implant, setImplant] = useState<FormImplant | undefined>(undefined)

    return (
        <>
            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                Lista implantów
            </div>
            <div>
                <FieldArray
                    name="implants"
                    render={({
                        push,
                        remove,
                        form: { values, errors, setFieldValue },
                    }) => (
                        <div>
                            <Label>Dodaj implant</Label>
                            <div className="flex items-center">
                                <div className="flex-grow w-10/12">
                                    <SearchableDropdownField<ImplantDropdownItem>
                                        name="_implant_item"
                                        queryFn={getDropdownImplants}
                                        queryFilters={{
                                            is_being_manufactured: true,
                                            set: false,
                                        }}
                                        renderOption={({ name, price }) => (
                                            <div>
                                                <p className="text-sm leading-5 font-medium">
                                                    {name}
                                                </p>
                                                <p className="text-sm leading-5 font-normal text-gray-500">
                                                    Cena:{' '}
                                                    {formatCurrency(price)}
                                                </p>
                                            </div>
                                        )}
                                        renderOptions={(options) => (
                                            <div>
                                                <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                                    Podpowiedzi
                                                </div>
                                                {options}
                                            </div>
                                        )}
                                        handleChange={(value) => {
                                            setImplant({
                                                ...value,
                                                label: value.label || '',
                                                manufacturer:
                                                    value.manufacturer?.name ||
                                                    '',
                                            })
                                        }}
                                        handleRemove={() => {
                                            setImplant(undefined)
                                        }}
                                        messageEmptyResult="Nie znaleziono implantu."
                                    />
                                </div>
                                <div className="flex-shrink-0 ml-2">
                                    <button
                                        type="button"
                                        className={classNames(
                                            'inline-flex items-center px-2 py-1.5 border border-transparent bg-transparent text-xs font-medium rounded-md text-blue-500 hover:text-blue-600 focus:outline-none',
                                            {
                                                'opacity-30 pointer-events-none':
                                                    !values[`_implant_item`],
                                            }
                                        )}
                                        onClick={() => {
                                            if (!implant) {
                                                return
                                            }
                                            push(implant)
                                            setFieldValue(
                                                '_implant_item',
                                                undefined
                                            )
                                            setFieldValue(
                                                '_implant_item_phrase',
                                                undefined
                                            )
                                            setImplant(undefined)
                                        }}
                                    >
                                        Dodaj
                                        <PlusIcon
                                            className="ml-2 h-3 w-3"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </div>
                            {errors.implants && (
                                <p className="mt-1 text-sm text-red-600">
                                    {errors.implants}
                                </p>
                            )}
                            {values.implants.length > 0 && (
                                <table className="mt-4 min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Id
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Nazwa
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                            >
                                                Producent
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                            >
                                                Cena
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Opcje
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                                        {(values.implants as Implant[]).map(
                                            (item, index) => (
                                                <tr
                                                    key={item.id}
                                                    className={classNames({
                                                        'bg-gray-50': index % 2,
                                                    })}
                                                >
                                                    <td className="px-6 py-6 whitespace-nowrap">
                                                        <span className="text-gray-500 font-normal">
                                                            {item.id}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-6">
                                                        <span className="font-medium">
                                                            {item.label ||
                                                                item.name}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-6">
                                                        <div className="text-gray-500">
                                                            {item.manufacturer ||
                                                                '-'}
                                                        </div>
                                                    </td>
                                                    <td className="px-1 py-6 text-center">
                                                        <span className="text-gray-500">
                                                            {formatCurrency(
                                                                item.price
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-1 whitespace-nowrap text-right">
                                                        <Button
                                                            variant="tertiary"
                                                            type="button"
                                                            size="sm"
                                                            onClick={() => {
                                                                remove(index)
                                                            }}
                                                        >
                                                            Usuń
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                />
            </div>
        </>
    )
}

export default SettingsImplantSetForm
export { ProcedureFieldArray }
