import React from 'react'

import withUploader from 'HOC/withUploader'

const AvatarUploader = (props: {
    url?: string
    handleRemovePhoto: () => void
    uploader: { actions: any; state: any }
}) => {
    const {
        url,
        uploader: { actions, state },
    } = props

    return (
        <div className="flex items-center">
            <span className="flex-shrink-0 h-12 w-12 rounded-full overflow-hidden">
                {state.isSuccess && (
                    <img
                        className="h-full w-full rounded-full"
                        src={state.addedFile.url}
                        alt=""
                    />
                )}
                {!!url && !state.isSuccess && (
                    <img
                        className="h-full w-full rounded-full"
                        src={url}
                        alt=""
                    />
                )}
                {!url && !state.isSuccess && (
                    <svg
                        className="h-full w-full bg-gray-100 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                )}
            </span>
            {state.isUploading && (
                <span className="ml-4 text-sm text-gray-600">
                    {state.progress}%
                </span>
            )}
            {!state.isUploading && (
                <>
                    <label
                        htmlFor="file-upload"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                    >
                        Zmień
                    </label>
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        onChange={(e) => actions.handleUpload(e.target.files)}
                        className="sr-only"
                    />
                </>
            )}
        </div>
    )
}

export default withUploader(AvatarUploader, 'avatar')
