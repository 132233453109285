import React, { useMemo } from 'react'
import moment, { Moment } from 'moment'
import type { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'

import {
    DraggableEvent,
    DailyGridItem,
    DailyDropItem,
} from 'components/schedule'
import { useSchedulePlannerPolicyGroup } from 'hooks'

import type {
    OperatingRoom,
    ResponseData,
    ResponseError,
    ScheduleOperationListItem,
    SchedulePlanItem,
} from 'api/types'
import type { OnDragStart, OnDragStop, OnDrop } from 'types/SchedulePlanner'

const DailyGrid: React.FC<{
    draggable: boolean
    draggingItemId?: number
    date: Moment
    rooms: OperatingRoom[]
    operations?: ScheduleOperationListItem[]
    onSelect: (id: number) => void
    onDragStart?: OnDragStart
    onDragStop?: OnDragStop
    onDrop?: OnDrop
    planQueryResult?: UseQueryResult<
        ResponseData<SchedulePlanItem[]>,
        ResponseError
    >
    isScheduleUpdating?: boolean
}> = ({
    draggable,
    date,
    rooms,
    operations,
    onSelect,
    onDragStart,
    onDragStop,
    onDrop,
    planQueryResult,
    draggingItemId,
    isScheduleUpdating,
}) => {
    const schedulePlannerPolicyGroup = useSchedulePlannerPolicyGroup()

    const { canShow } = schedulePlannerPolicyGroup.schedule

    const unassigned = useMemo(() => {
        if (!operations) {
            return []
        }

        return operations
            .filter((item) => !item.final_operation_date)
            .filter((item) => moment(item.estimated_date).isSame(date, 'day'))
    }, [operations, date])

    const unassigned7_12H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '7-12'
            ),
        [unassigned]
    )

    const unassigned12_15H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '12-15'
            ),
        [unassigned]
    )

    const unassigned15_19H = useMemo(
        () =>
            unassigned.filter(
                (item) => item.preferred_beginning_hour === '15-19'
            ),
        [unassigned]
    )

    const assigned = useMemo(() => {
        if (!operations) {
            return []
        }

        return operations
            .filter((item) => !!item.final_operation_date)
            .filter((item) =>
                moment(item.final_operation_date).isSame(date, 'day')
            )
    }, [operations, date])

    const isDraggable = !isScheduleUpdating && draggable

    return (
        <ol
            className="daily-grid-container relative z-10 grid col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x-2 divide-gray-200"
            style={{
                gridTemplateColumns: `repeat(4, minmax(0, 1fr))`,
            }}
        >
            <li
                className={twMerge(
                    'grid-cols-1 py-1 px-2 pb-1.5 space-y-1.5 print:hidden relative overflow-hidden',
                    draggable && 'space-y-2'
                )}
            >
                {draggable && (
                    <DailyDropItem
                        onDrop={onDrop}
                        row={0}
                        col={0}
                        planQueryResult={planQueryResult}
                    />
                )}
                {unassigned7_12H
                    .concat(unassigned12_15H)
                    .concat(unassigned15_19H)
                    .map((item, itemIndex) => {
                        const isAnonymous = !item.can_see_details

                        if (
                            isDraggable &&
                            schedulePlannerPolicyGroup.canUpdate &&
                            (schedulePlannerPolicyGroup.user.isSuperAdmin ||
                                schedulePlannerPolicyGroup.user.isAdmin ||
                                schedulePlannerPolicyGroup.user.isPlanner)
                        ) {
                            if (!item.can_be_moved) {
                                return (
                                    <div
                                        key={item.id}
                                        className={twMerge(
                                            'mt-px z-10 relative',
                                            !!draggingItemId &&
                                                'pointer-events-none'
                                        )}
                                        onClick={() =>
                                            canShow &&
                                            !isAnonymous &&
                                            onSelect(item.id)
                                        }
                                    >
                                        <DailyGridItem
                                            item={item}
                                            isClickable={
                                                canShow && !isAnonymous
                                            }
                                            isDraggable={false}
                                            displayPreferredBeginningHour
                                        />
                                    </div>
                                )
                            }

                            return (
                                <DraggableEvent
                                    key={item.id}
                                    item={item}
                                    row={itemIndex + 1}
                                    colStart={1}
                                    onDragStart={onDragStart}
                                    onDragStop={onDragStop}
                                >
                                    {({ isDragging, drag }) => (
                                        <div
                                            className={twMerge(
                                                'mt-px z-10 relative',
                                                !!draggingItemId &&
                                                    draggingItemId !==
                                                        item.id &&
                                                    'pointer-events-none'
                                            )}
                                            onClick={() =>
                                                canShow &&
                                                !isAnonymous &&
                                                onSelect(item.id)
                                            }
                                        >
                                            <DailyGridItem
                                                ref={drag}
                                                item={item}
                                                isClickable={
                                                    canShow && !isAnonymous
                                                }
                                                isDragging={isDragging}
                                                isDraggable={true}
                                                displayPreferredBeginningHour
                                            />
                                        </div>
                                    )}
                                </DraggableEvent>
                            )
                        }

                        return (
                            <div
                                className="mt-px"
                                onClick={() =>
                                    canShow && !isAnonymous && onSelect(item.id)
                                }
                            >
                                <DailyGridItem
                                    item={item}
                                    isClickable={canShow && !isAnonymous}
                                    isDraggable={false}
                                    displayPreferredBeginningHour
                                />
                            </div>
                        )
                    })}
            </li>

            {rooms.map((room, index) => (
                <li
                    key={room.id}
                    className={twMerge(
                        'grid-cols-1 py-1 px-2 pb-1.5 space-y-1.5 relative overflow-hidden',
                        draggable && 'space-y-2'
                    )}
                >
                    {draggable && (
                        <DailyDropItem
                            onDrop={onDrop}
                            row={0}
                            col={index + 1}
                            roomId={room.id}
                            planQueryResult={planQueryResult}
                        />
                    )}
                    {assigned
                        .filter((item) => item.operating_room_id === room.id)
                        .map((item, itemIndex) => {
                            const isAnonymous = !item.can_see_details

                            if (
                                isDraggable &&
                                schedulePlannerPolicyGroup.canUpdate &&
                                (schedulePlannerPolicyGroup.user.isSuperAdmin ||
                                    schedulePlannerPolicyGroup.user.isAdmin ||
                                    schedulePlannerPolicyGroup.user.isPlanner)
                            ) {
                                if (!item.can_be_moved) {
                                    return (
                                        <>
                                            <div
                                                key={item.id}
                                                className={twMerge(
                                                    'mt-px z-10 relative',
                                                    !!draggingItemId &&
                                                        'pointer-events-none'
                                                )}
                                                onClick={() =>
                                                    canShow &&
                                                    !isAnonymous &&
                                                    onSelect(item.id)
                                                }
                                            >
                                                <DailyGridItem
                                                    item={item}
                                                    isClickable={
                                                        canShow && !isAnonymous
                                                    }
                                                    isDraggable={false}
                                                    colored
                                                />
                                            </div>
                                            {draggable && (
                                                <DailyDropItem
                                                    onDrop={onDrop}
                                                    row={itemIndex + 1}
                                                    col={index + 1}
                                                    roomId={room.id}
                                                    planQueryResult={
                                                        planQueryResult
                                                    }
                                                    afterOperationId={item.id}
                                                />
                                            )}
                                        </>
                                    )
                                }

                                return (
                                    <>
                                        <DraggableEvent
                                            key={item.id}
                                            item={item}
                                            row={itemIndex + 1}
                                            colStart={index + 2}
                                            onDragStart={onDragStart}
                                            onDragStop={onDragStop}
                                        >
                                            {({ isDragging, drag }) => (
                                                <div
                                                    key={item.id}
                                                    className={twMerge(
                                                        'mt-px z-10 relative',
                                                        !!draggingItemId &&
                                                            draggingItemId !==
                                                                item.id &&
                                                            'pointer-events-none'
                                                    )}
                                                    onClick={() =>
                                                        canShow &&
                                                        !isAnonymous &&
                                                        onSelect(item.id)
                                                    }
                                                >
                                                    <DailyGridItem
                                                        ref={drag}
                                                        item={item}
                                                        isClickable={
                                                            canShow &&
                                                            !isAnonymous
                                                        }
                                                        isDragging={isDragging}
                                                        isDraggable={true}
                                                        colored
                                                    />
                                                </div>
                                            )}
                                        </DraggableEvent>
                                        {draggable && (
                                            <DailyDropItem
                                                onDrop={onDrop}
                                                row={itemIndex + 1}
                                                col={index + 1}
                                                roomId={room.id}
                                                planQueryResult={
                                                    planQueryResult
                                                }
                                                afterOperationId={item.id}
                                            />
                                        )}
                                    </>
                                )
                            }

                            return (
                                <div
                                    key={item.id}
                                    className="mt-px"
                                    onClick={() =>
                                        canShow &&
                                        !isAnonymous &&
                                        onSelect(item.id)
                                    }
                                >
                                    <DailyGridItem
                                        item={item}
                                        isClickable={canShow && !isAnonymous}
                                        isDraggable={false}
                                        colored
                                    />
                                </div>
                            )
                        })}
                </li>
            ))}
        </ol>
    )
}

export default DailyGrid
