import { useIdentificationCardPolicy } from 'hooks'

import type { MenuItemGate } from './index'

export const CardListMenuItemGate: MenuItemGate = ({ children }) => {
    const { canShow } = useIdentificationCardPolicy()

    if (canShow) {
        return children
    }

    return null
}
