import React from 'react'
import { Form, Formik } from 'formik'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Button } from 'components/ui'
import { EmployeePickerField } from 'components/forms'
import type { FormSubmitFn, IModal } from 'types'

import modalStyles from 'components/Modal.module.scss'
import { IDCardUpdateForm } from 'types'

const SettingsRFIDCardUpdateModal: React.FC<{
    modal: IModal
    handleSubmit: FormSubmitFn<IDCardUpdateForm>
}> = ({ modal, handleSubmit }) => {
    const state = modal.getState()

    return (
        <Modal modal={modal}>
            <div className={modalStyles.inner}>
                <Dialog.Title as="h3" className={modalStyles.title}>
                    Edytuj kartę: {state?.number}
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        id: state?.id,
                        number: state?.number,
                        user: state?.user || undefined,
                        active: state?.active,
                        _statusActiveIsToggling: false,
                        _isDeleting: false,
                    }}
                >
                    {({ values, isSubmitting, submitForm, setFieldValue }) => (
                        <Form>
                            <div className="mt-6 w-full">
                                <EmployeePickerField
                                    name="user"
                                    placeholder="Wybierz osobę"
                                    functions={[]}
                                    messageEmptyResult="Nie znaleziono osoby."
                                />
                            </div>
                            <div className={modalStyles['action-container']}>
                                <div>
                                    {state?.user ? (
                                        <Button
                                            type="button"
                                            variant="danger"
                                            tabIndex={-1}
                                            loading={
                                                values._statusActiveIsToggling
                                                    ? isSubmitting
                                                    : false
                                            }
                                            onClick={async () => {
                                                setFieldValue(
                                                    '_statusActiveIsToggling',
                                                    true,
                                                    false
                                                )
                                                await submitForm()
                                            }}
                                        >
                                            {values.active
                                                ? 'Zablokuj kartę'
                                                : 'Odblokuj kartę'}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            variant="danger"
                                            tabIndex={-1}
                                            loading={
                                                values._isDeleting
                                                    ? isSubmitting
                                                    : false
                                            }
                                            onClick={async () => {
                                                setFieldValue(
                                                    '_isDeleting',
                                                    true,
                                                    false
                                                )
                                                await submitForm()
                                            }}
                                        >
                                            Usuń
                                        </Button>
                                    )}
                                </div>
                                <div className="flex">
                                    <Button
                                        type="button"
                                        variant="default"
                                        className="w-full mr-2"
                                        tabIndex={-1}
                                        onClick={() => modal.closeModal()}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="w-full"
                                        tabIndex={-1}
                                        loading={
                                            values._statusActiveIsToggling ||
                                            values._isDeleting
                                                ? false
                                                : isSubmitting
                                        }
                                    >
                                        Zapisz
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default SettingsRFIDCardUpdateModal
