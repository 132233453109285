export const MESSAGES = {
    SURGERY_UPDATED: 'Operacja została zaktualizowana',
    SURGERY_DIET_INFO_UPDATED: 'Dieta została zaktualizowana',
    SURGERY_PROPOSAL_CREATED: 'Wysłano zapytanie o operację',
    SURGERY_TO_SCHEDULE_ADDED: 'Operacja została dodana do planu operacyjnego',
    SURGERY_SET_STATUS_REJECTED_SUCCESS: 'Operacja została zrzucona',
    SURGERY_SET_STATUS_REJECTED_FAILED: 'Nie udało się zrzucić operacji',
    SURGERY_RESTORE_SUCCESS: 'Operacja została przywrócona',
    SURGERY_RESTORE_FAILED: 'Nie udało się przywrócić operacji',
    SURGERY_SET_STATUS_CANCELED_SUCCESS: 'Operacja została odwołona',
    SURGERY_SET_STATUS_CANCELED_FAILED: 'Nie udało się odwołać operacji',
    SURGERY_STATUS_UPDATED_SUCCESS: 'Status został zaktualizowany',
    TASK_CREATED: 'Zadanie zostało utworzone',
    TASK_UPDATED: 'Zadanie zostało zaktualizowane',
    TASK_DOCUMENT_APPENDED: 'Dokument został dodany',
    TASK_STATUS_UPDATED_SUCCESS: 'Status zadania został zaktualizowany',
    TASK_STATUS_UPDATE_FAILED: 'Nie udało się zaktualizować statusu',
    TASK_TERM_UPDATED_SUCCESS: 'Termin został zaktualizowany',
    PATIENT_CREATED: 'Pacjent został dodany',
    PATIENT_UPDATED: 'Pacjent został zaktualizowany',
    PATIENT_NOTE_UPDATED: 'Zaktualizowano notatkę',
    RFID_CARD_CREATED: 'Karta została dododana',
    RFID_CARD_UPDATED: 'Karta została zaktualizowana',
    RFID_CARD_LOCKED_SUCCESS: 'Karta została zablokowana',
    RFID_CARD_UNLOCKED_SUCCESS: 'Karta została odblokowana',
    RFID_CARD_DELETED: 'Karta została usunięta',
    RFID_CARD_DELETE_FAILED: 'Nie udało się usunąć karty',
    NO_PERMISSION_TO_CREATE_SURGERY:
        'Nie posiadasz uprawnień do dodawania operacji',
    NO_PERMISSION_TO_UPDATE_SURGERY: 'Nie masz uprawnień do edycji operacji',
}
