import React from 'react'
import moment from 'moment/moment'
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from '@heroicons/react/solid'

import {
    DATEPICKER_DEFAULT_FROM_YEAR,
    DATEPICKER_DEFAULT_TO_YEAR,
    NAMES_DAYS_OF_WEEK,
} from 'constants/index'
import { Button, DatePicker, Spinner } from 'components/ui'

import type { Moment } from 'moment'

const HeaderCenter: React.FC<{
    date: Moment
    onChangeDate: (date: Date) => void
    isLoading: boolean
}> = ({ date, isLoading, onChangeDate }) => {
    const displayDateHeader = (date: Moment) => {
        return (
            date.format('D.MM') +
            ', ' +
            NAMES_DAYS_OF_WEEK[Number(date.format('d'))].toLocaleLowerCase()
        )
    }

    return (
        <div id="schedule-header-center" className="flex justify-center">
            <div
                id="schedule-header-date"
                className="flex items-center relative text-center text-gray-700 text-xl lg:text-2xl print:text-3xl print:font-normal print:text-gray-900"
            >
                <div className="print:hidden">
                    <Button
                        type="button"
                        variant="transparent"
                        size="sm"
                        onClick={() =>
                            onChangeDate(moment(date).add(-1, 'days').toDate())
                        }
                    >
                        <ArrowNarrowLeftIcon className="w-4 h-4" />
                    </Button>
                </div>
                <div className="relative px-2 w-64 text-base">
                    <DatePicker
                        value={date.toDate()}
                        captionLayout="dropdown-buttons"
                        fromYear={DATEPICKER_DEFAULT_FROM_YEAR}
                        toYear={DATEPICKER_DEFAULT_TO_YEAR}
                        stylesPopper={{
                            width: 'max-content',
                            left: '50%',
                            transform: 'translate(-50%, 42px)',
                        }}
                        onChange={(date) => {
                            !!date && onChangeDate(date)
                        }}
                        renderSelector={({ ref, isOpen, open, close }) => {
                            return (
                                <div
                                    ref={ref}
                                    className="cursor-pointer flex items-center justify-center whitespace-nowrap text-xl lg:text-2xl print:text-3xl space-x-2"
                                    onClick={!isOpen ? open : close}
                                >
                                    <span>{displayDateHeader(date)}</span>
                                    <span className="print:hidden">
                                        {!isOpen && (
                                            <ChevronDownIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {isOpen && (
                                            <ChevronUpIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </span>
                                </div>
                            )
                        }}
                    />
                </div>
                {isLoading && (
                    <div className="absolute -right-12">
                        <Spinner size="sm" darker />
                    </div>
                )}
                <div className="print:hidden">
                    <Button
                        type="button"
                        variant="transparent"
                        size="sm"
                        onClick={() =>
                            onChangeDate(moment(date).add(1, 'days').toDate())
                        }
                    >
                        <ArrowNarrowRightIcon className="w-4 h-4" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default HeaderCenter
