import React from 'react'

import { useScheduleActions, useSurgeryPolicyGroup } from 'hooks'
import { Button3dots } from 'components/ui'
import Menu3dots from 'components/Menu3dots'
import OperationRejectModal from 'components/OperationRejectModal'
import OperationRenewModal from 'components/OperationRenewModal'
import OperationCancelModal from 'components/OperationCancelModal'

import type { OperationResponse } from 'api/types'

const OperationCardMenu3dots: React.FC<{
    item: OperationResponse
}> = ({ item }) => {
    const surgeryPolicyGroup = useSurgeryPolicyGroup()

    const scheduleActions = useScheduleActions('operation')

    const canReject =
        scheduleActions.rejectedId !== item.id &&
        item.can_be_rejected &&
        surgeryPolicyGroup.canReject

    const canRenew = surgeryPolicyGroup.canRenew
    const isMutating = scheduleActions.isMutating(item.id)

    return (
        <>
            <Menu3dots>
                {({ open }) => (
                    <>
                        <Menu3dots.Button
                            as={isMutating ? React.Fragment : 'span'}
                        >
                            <Button3dots
                                variant="default"
                                size="sm"
                                hovered={open}
                                loading={isMutating}
                            />
                        </Menu3dots.Button>
                        <Menu3dots.Items>
                            <div className="border-b border-gray-100 py-1 md:hidden">
                                <Menu3dots.Item
                                    as="link"
                                    to={`/caregiver/surgeries/${item.id}/edit`}
                                    state={{
                                        backUrl: '/caregiver',
                                        state: { id: item.id },
                                    }}
                                >
                                    Edytuj dane
                                </Menu3dots.Item>
                            </div>
                            <div className="divide-y divide-gray-200">
                                <div className="py-1">
                                    <Menu3dots.RejectOrRenew
                                        item={item}
                                        canReject={canReject}
                                        canRenew={canRenew}
                                        onReject={() =>
                                            scheduleActions.handleReject(
                                                item.id
                                            )
                                        }
                                        onRenew={() =>
                                            scheduleActions.handleRenew(item)
                                        }
                                    />
                                </div>
                            </div>
                        </Menu3dots.Items>
                    </>
                )}
            </Menu3dots>
            <OperationRejectModal
                modal={scheduleActions.modalReject}
                onSubmit={scheduleActions.handleRejectFormSubmit}
                onCancel={scheduleActions.handleResignRejecting}
                onClickOutside={scheduleActions.modalReject.closeModal}
            />
            <OperationRenewModal
                modal={scheduleActions.modalRenew}
                onSubmit={scheduleActions.handleRenewFormSubmit}
                onCancel={scheduleActions.handleResignRenewing}
                onClickOutside={scheduleActions.modalRenew.closeModal}
            />
            <OperationCancelModal
                modal={scheduleActions.modalCancel}
                onSubmit={scheduleActions.handleCancelFormSubmit}
                onCancel={scheduleActions.handleResignCancelling}
                onClickOutside={scheduleActions.modalCancel.closeModal}
            />
        </>
    )
}

export default OperationCardMenu3dots
