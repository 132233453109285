import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const SPECIALIZATION_PERMISSIONS = {
    index: PERMISSIONS.INDEX_SPECIALIZATIONS,
    show: PERMISSIONS.SHOW_SPECIALIZATIONS,
    create: PERMISSIONS.CREATE_SPECIALIZATIONS,
    update: PERMISSIONS.UPDATE_SPECIALIZATIONS,
    delete: PERMISSIONS.DELETE_SPECIALIZATIONS,
}

export const useSpecializationPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(SPECIALIZATION_PERMISSIONS),
    }
}
