import React from 'react'
import { Form, Formik } from 'formik'
import { Dialog } from '@headlessui/react'

import { getCxFromStyles } from 'helpers'
import { TextAreaField } from 'components/forms'
import { Modal } from 'components'
import { Button, Label } from 'components/ui'

import type { OperationResponse } from 'api/types'
import type { FormSubmitFn, IModal, CaregiverSurgeryForm } from 'types'

import styles from 'components/Modal.module.scss'

export type CaregiverSurgeryDescriptionFormModalProps = {
    modal: IModal
    data: OperationResponse
    onSubmit: FormSubmitFn<CaregiverSurgeryForm>
}

export default function CaregiverSurgeryDescriptionFormModal({
    modal,
    data,
    onSubmit,
}: CaregiverSurgeryDescriptionFormModalProps) {
    const cx = getCxFromStyles(styles)

    return (
        <Modal modal={modal}>
            <div className={cx('inner')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Uwagi do zabiegu
                </Dialog.Title>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        description: data.description || '',
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className="px-2 pb-2">
                            <div className="mt-6">
                                <Label className="mb-4">Opis</Label>
                                <TextAreaField
                                    tabIndex={-1}
                                    name="description"
                                    rows={12}
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
