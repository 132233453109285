import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    CreateProcedure,
    FormProcedureDurationsFilters,
    FormProcedureFilters,
    ImplantFilters,
    Procedure,
    ProcedureBase,
    ProcedureDurationsFilters,
    ProcedureFilters,
    ResponseData,
    ResponseError,
    ResponseList,
    ResponseNoContent,
    UpdateProcedure,
} from 'api/types'
import type { ListFilters } from 'helpers'

export const getProcedures = <R>(
    filters?: ProcedureFilters | { pagination: boolean } | {}
): Promise<R> =>
    axios.get('/procedures' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetProcedures = <
    R = ResponseList<Procedure[]>,
    E = ResponseError
>(
    filters?:
        | ProcedureFilters
        | ProcedureDurationsFilters
        | { pagination: boolean },
    options?: UseQueryOptions<R, E>
) =>
    useQuery<R, E>(
        ['procedures', filters],
        () => getProcedures(filters),
        options
    )

export const useGetProcedure = <T = Procedure, E = ResponseError>(
    id?: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['procedures', id],
        () => axios.get(`/procedures/${id}`),
        !id ? { ...options, enabled: false } : options
    )

export const createProcedure = <T>(data: T): Promise<ResponseData<T>> =>
    axios.post('/procedures', data)

export const useCreateProcedure = <T = CreateProcedure>(): UseMutationResult<
    ResponseData<T>,
    ResponseError,
    T
> => useMutation((data) => createProcedure<T>(data))

export const updateProcedure = <R1 extends ProcedureBase, R2 = Procedure>(
    data: R1
): Promise<ResponseData<R2>> => axios.patch(`/procedures/${data.id}`, data)

export const useUpdateProcedure = <
    R1 extends ProcedureBase = UpdateProcedure,
    R2 = Procedure
>(): UseMutationResult<ResponseData<R2>, ResponseError, R1> =>
    useMutation((data) => updateProcedure<R1, R2>(data))

export const useBatchUpdateProcedureDurations = (): UseMutationResult<
    ResponseNoContent,
    ResponseError,
    {
        duration_in_minutes: string
        anesthesia_duration_in_minutes: string
        procedures: number[] | null
        procedure_categories: number[] | null
    }
> =>
    useMutation((data) =>
        axios.post(`/procedures/batch-update-durations`, data)
    )

export const countActiveFilters = (
    filtersToApi: ProcedureFilters | ProcedureDurationsFilters | ImplantFilters,
    searchElements = [
        'name',
        'procedure_categories',
        'procedure_types',
        'procedure_payers',
    ]
): number => {
    return Object.entries(filtersToApi).reduce((acc, item) => {
        if (!searchElements.includes(item[0])) {
            return acc
        }

        acc +=
            typeof item[1] === 'string'
                ? item[1].length
                    ? 1
                    : 0
                : Array.isArray(item[1])
                ? item[1].length
                    ? 1
                    : 0
                : typeof item[1] === 'number'
                ? 1
                : 0
        return acc
    }, 0)
}

export const transformProcedureFiltersToApi = (
    filters: { filters: FormProcedureFilters } & ListFilters
): ProcedureFilters => {
    const transformedFilters: ProcedureFilters = {
        ...filters,
        name: filters.filters.name,
        procedure_categories:
            filters.filters.procedure_categories?.map((item) => item.id) || [],
        procedure_types:
            filters.filters.procedure_types.map((item) => item.id) || [],
        procedure_payers: filters.filters.procedure_payers?.map(
            (item) => item.id
        ),
    }

    if (Array.isArray(transformedFilters.procedure_payers)) {
        transformedFilters.procedure_payers = transformedFilters
            .procedure_payers.length
            ? transformedFilters.procedure_payers
            : ''
    }

    return transformedFilters
}

export const transformProcedureDurationFiltersToApi = ({
    filters,
    ...restFilters
}: {
    filters: FormProcedureDurationsFilters
} & ListFilters): ProcedureDurationsFilters => {
    return {
        ...restFilters,
        name: filters.name,
        procedure_categories:
            filters.procedure_categories?.map((item) => Number(item.id)) || [],
        editable_duration:
            filters.editable_duration.id === -1
                ? undefined
                : !!filters.editable_duration.id,
    }
}
