import React from 'react'

import { useGetPatient } from 'api'
import { Loader } from 'components/ui'
import PatientNoteForm from 'components/PatientNoteForm'
import { PatientNoteForm as TPatientNoteForm, FormSubmitFn } from 'types'

const PatientNote: React.FC<{
    patient: ReturnType<typeof useGetPatient>
    handleSubmit: FormSubmitFn<TPatientNoteForm>
}> = ({ patient, handleSubmit }) => {
    return (
        <div>
            <p className="text-lg leading-6 font-medium">
                Wykonane operacje i zabiegi
            </p>
            <div className="mt-5">
                {patient.isLoading && <Loader />}
                {patient.isSuccess && (
                    <PatientNoteForm
                        data={patient.data.data}
                        handleSubmit={handleSubmit}
                    />
                )}
            </div>
        </div>
    )
}

export default PatientNote
