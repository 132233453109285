import React from 'react'
import { TTab } from 'components/SettingsTabs'

import ListLayout, { ListLayoutProps } from 'layouts/ListLayout'

const tabs: TTab[] = [
    {
        name: 'Role',
        to: '/settings/roles',
        current: true,
    },
    {
        name: 'Specjalizacje',
        to: '/settings/specializations',
        current: false,
    },
]

const SettingsPermissionLayout: React.FC<ListLayoutProps> = ({
    children,
    ...props
}) => {
    return (
        <ListLayout {...props} tabs={tabs}>
            {children}
        </ListLayout>
    )
}

export default SettingsPermissionLayout
