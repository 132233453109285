import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ANESTHESIOLOGIST_SCHEDULE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ANESTHESIOLOGIST_SCHEDULES,
    show: false,
    create: PERMISSIONS.CREATE_ANESTHESIOLOGIST_SCHEDULES,
    update: PERMISSIONS.UPDATE_ANESTHESIOLOGIST_SCHEDULES,
    delete: false,
}

export const useAnesthesiologistSchedulePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(ANESTHESIOLOGIST_SCHEDULE_PERMISSIONS),
    }
}
