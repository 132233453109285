import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { GetProcedureListResponse } from 'api/types'
import type { ProcedureListFilterAction } from 'helpers'

interface ProcedureListProps {
    data: GetProcedureListResponse
    filtersCount: number
    setFilters: React.Dispatch<ProcedureListFilterAction>
}

export default function SettingsProcedureList({
    data,
    filtersCount,
    setFilters,
}: ProcedureListProps) {
    return (
        <>
            <div className="bg-white p-2 rounded-lg">
                {data.meta.total > 0 && (
                    <>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Id
                                    </th>
                                    <th
                                        scope="col"
                                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        ICD-9
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Nazwa
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                    >
                                        Kategoria
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                    >
                                        Rodzaj zabiegu
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                    >
                                        Hospitalizacja
                                    </th>
                                    <th
                                        scope="col"
                                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Opcje
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                                {data.data.map((item, index) => (
                                    <tr
                                        key={item.id}
                                        className={classNames('font-medium', {
                                            'bg-gray-50': index % 2,
                                        })}
                                    >
                                        <td className="px-6 py-6 whitespace-nowrap">
                                            <span className="text-gray-500 font-normal">
                                                {item.id}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6">
                                            <span className="text-gray-500">
                                                {item.icd9?.code || '-'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6">
                                            <span className="">
                                                {item.name}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6">
                                            <span className="text-gray-500">
                                                {item.procedure_category
                                                    ?.name || ''}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6">
                                            <span className="text-gray-500">
                                                {item.procedure_type?.name ||
                                                    ''}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6 text-center">
                                            <span className="text-gray-500">
                                                {item.recovery_days}
                                            </span>
                                        </td>
                                        <td className="px-6 py-1 whitespace-nowrap text-right">
                                            <span className="font-normal">
                                                <Link
                                                    to={`/settings/procedures/${item.id}`}
                                                    className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Edytuj
                                                </Link>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            meta={data.meta}
                            handleChange={(page) =>
                                setFilters({ type: 'setPage', payload: page })
                            }
                        />
                    </>
                )}
                {data.meta.total === 0 && filtersCount === 0 && (
                    <EmptyList
                        headline="Nic tu jeszcze nie ma"
                        message="Zacznij od stworzenia pierwszej operacji."
                        to="/settings/procedures/add"
                    />
                )}
                {data.meta.total === 0 && filtersCount > 0 && (
                    <EmptyList
                        headline="Nie znaleziono wyników wyszukiwania"
                        message="Możesz dodać operację."
                        to="/settings/procedures/add"
                    />
                )}
            </div>
        </>
    )
}
