import React from 'react'
import { Form, Formik } from 'formik'
import { Dialog } from '@headlessui/react'

import { Modal } from 'components'
import { Button } from 'components/ui'
import { EmployeePickerField, ErrorMessage } from 'components/forms'

import type { FormSubmitFn, IModal } from 'types'

import modalStyles from 'components/Modal.module.scss'

const EmployeeAssignModal: React.FC<{
    modal: IModal
    title: string
    functions: string[]
    placeholder: string
    messageEmptyResult: string
    handleSubmit: FormSubmitFn<any>
}> = ({
    modal,
    title,
    functions,
    placeholder,
    messageEmptyResult,
    handleSubmit,
}) => {
    return (
        <Modal modal={modal}>
            <div className={modalStyles.inner}>
                <Dialog.Title as="h3" className={modalStyles.title}>
                    {title}
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{
                        user: modal.getState() || undefined,
                        user_phrase: '',
                    }}
                >
                    {({ values, errors, isSubmitting }) => (
                        <Form>
                            <div className="mt-6 w-full">
                                <EmployeePickerField
                                    tabIndex={-1}
                                    name="user"
                                    placeholder={placeholder}
                                    functions={functions}
                                    hasError={'user' in errors}
                                    messageEmptyResult={messageEmptyResult}
                                />
                                <ErrorMessage name="user" />
                            </div>
                            <div className={modalStyles['action-container']}>
                                <Button
                                    tabIndex={-1}
                                    variant="default"
                                    type="button"
                                    onClick={() => modal.closeModal()}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default EmployeeAssignModal
