import React, { CSSProperties, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import moment from 'moment'
import uniqBy from 'lodash.uniqby'

import { displayPatientName, getCxFromStyles, isPatientName } from 'helpers'
import { Loader } from 'components/ui'
import { LiveViewTopBar } from 'components/LiveView'

import type { DelayTime, OperatingRoom, OperationTimes } from 'api/types'

import styles from 'components/LiveView.module.scss'

import { ReactComponent as IconUser } from 'assets/icon-user.svg'
import { ReactComponent as IconMedicalTeam } from 'assets/icon-medical-team.svg'
import { ReactComponent as IconTimeEstimate } from 'assets/icon-time-estimate.svg'
import { ReactComponent as IconTimePast } from 'assets/icon-time-past.svg'

type BusinessLiveViewProps = {
    date: Date
    view: 'detail' | 'business'
    isLoadingRooms: boolean
    roomsError: string
    isLoadingOperations: boolean
    operationsError: string
    rooms: OperatingRoom[]
    operations: OperationTimes[]
    canChangeView: boolean
    canMoveToNextDay: boolean
    onViewToggle: () => void
    onDateChange: (date: Date) => void
}

type OccupiedOperatingRoomProps = {
    item: OperationTimes
}

type UnoccupiedOperatingRoomProps = {
    item: DelayTime
}

export default function BusinessLiveView(props: BusinessLiveViewProps) {
    const {
        date,
        view,
        isLoadingRooms,
        roomsError,
        isLoadingOperations,
        operationsError,
        rooms,
        operations,
        canChangeView,
        canMoveToNextDay,
        onViewToggle,
        onDateChange,
    } = props

    const cxStyles = getCxFromStyles(styles)

    return (
        <div className="bg-gray-400 h-full">
            <LiveViewTopBar
                date={date}
                maxDate={moment().subtract(1, 'days').toDate()}
                view={view}
                canMoveToNextDay={canMoveToNextDay}
                canChangeView={canChangeView}
                onViewToggle={onViewToggle}
                onDateChange={onDateChange}
            />
            {isLoadingRooms ? (
                <Loader theme="darker" />
            ) : roomsError ? (
                <div>{roomsError}</div>
            ) : (
                <div className={cxStyles('content')}>
                    <div className={cxStyles('columns-container')}>
                        {rooms.map((item, index) => (
                            <div
                                key={index}
                                className={cxStyles('operating-room-header')}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                    {isLoadingOperations ? (
                        <Loader theme="darker" />
                    ) : operationsError ? (
                        <div>{operationsError}</div>
                    ) : (
                        <div className={cxStyles('columns-container')}>
                            {rooms.map((room, index) => (
                                <div
                                    key={rooms.length + index}
                                    className={cxStyles(
                                        'operating-room-column'
                                    )}
                                >
                                    <ul
                                        className={cxStyles(
                                            'operating-room-list'
                                        )}
                                    >
                                        <OccupancyList
                                            operations={operations}
                                            roomId={room.id}
                                        />
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

type OccupancyListProps = {
    operations: OperationTimes[]
    roomId: number
}

function OccupancyList(props: OccupancyListProps) {
    const { operations, roomId } = props

    const cxStyles = getCxFromStyles(styles)

    const filteredOperations = useMemo(
        () => operations.filter((item) => item.operating_room.id === roomId),
        [operations, roomId]
    )

    return (
        <>
            {filteredOperations.map((item) => (
                <React.Fragment key={item.id}>
                    {item.delay_time !== null && (
                        <li className={cxStyles('operating-room-list-item')}>
                            <UnoccupiedOperatingRoom item={item.delay_time} />
                        </li>
                    )}
                    <li className={cxStyles('operating-room-list-item')}>
                        <OccupiedOperatingRoom item={item} />
                    </li>
                </React.Fragment>
            ))}
        </>
    )
}

function OccupiedOperatingRoom(props: OccupiedOperatingRoomProps) {
    const { item } = props
    const [expanded, setExpanded] = useState(false)

    const boxShadowStyle = useBoxShadowStyle(expanded)
    const dashedTopBorderStyle = useDashedTopBorderStyle()

    const alert = useMemo(
        () => item.durations.some((item) => item.is_exceeded),
        [item]
    )

    return (
        <div
            className="rounded cursor-pointer"
            style={boxShadowStyle}
            onClick={() => setExpanded(!expanded)}
        >
            <div
                className={twMerge(
                    'flex px-2 py-2 relative rounded text-base items-center bg-gray-50 text-gray-600 leading-normal font-medium',
                    expanded && 'rounded-b-none'
                )}
            >
                {alert && (
                    <div className="absolute top-0 left-0 w-full h-1 rounded-t bg-red-600"></div>
                )}
                <span>
                    {!!item.real_operation_start &&
                        moment(item.real_operation_start).format('HH:mm')}
                    {!!item.real_operation_start &&
                        !!item.real_operation_end &&
                        ' - '}
                    {!!item.real_operation_end &&
                        moment(item.real_operation_end).format('HH:mm')}
                </span>
                <span className="ml-2 text-lg">
                    {isPatientName(item.patient)
                        ? displayPatientName(item.patient)
                        : '-'}
                    <span className="ml-2">
                        (
                        {item.patient.age || item.patient.age === 0
                            ? item.patient.age
                            : '-'}
                        )
                    </span>
                </span>
            </div>
            <div
                className={twMerge(
                    'bg-gray-50 rounded-b',
                    !expanded && 'hidden'
                )}
            >
                <div className="px-2 py-2 border-t border-gray-200">
                    <p className="text-sm text-gray-800">Rodzaj zabiegu</p>
                    <p className="text-lg text-gray-800 font-medium">
                        {item.procedure.name}
                    </p>
                </div>
                <div className="px-2 py-2 border-t border-gray-200">
                    <EventTimeline durations={item.durations} />
                </div>
                <div className="mx-2 px-2 py-2" style={dashedTopBorderStyle}>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center text-gray-600">
                            <span className="text-base">Plan:</span>
                            <div className="flex ml-2 items-center">
                                <IconTimeEstimate className="w-4 h-4 mr-2" />
                                <span className="text-sm">
                                    {item.estimated_duration_without_cleaning}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center text-gray-600">
                            <span className="text-base">Realizacja:</span>
                            <div className="flex ml-2 items-center">
                                <IconTimePast className="w-4 h-4 mr-2" />
                                <span className="text-sm">
                                    {item.real_duration_without_cleaning}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-2 py-2 border-t border-gray-200">
                    <SurgeryMedicalTeam team={item.surgical_team} />
                </div>
            </div>
        </div>
    )
}

type EventTimelineProps = {
    durations: OperationTimes['durations']
}

function EventTimeline({ durations }: EventTimelineProps) {
    const statusLabels: Record<string, string> = {
        transfer_to_block: 'Przewiezienie na blok',
        anesthesia: 'Znieczulenie pacjenta',
        waiting_for_doctor: 'Oczekiwanie na lekarza',
        operation: 'Czas zabiegu',
        cleaning: 'Sprzątanie',
        pickup_patient: 'Odebranie pacjenta ',
    }

    return (
        <>
            {durations.map((duration, index) => (
                <React.Fragment key={`event-${duration.status}-${index}`}>
                    <div
                        className={twMerge(
                            'flex px-1 py-1 mb-1 items-center justify-between font-medium rounded',
                            duration.is_exceeded && 'bg-red-50'
                        )}
                    >
                        <p
                            className={twMerge(
                                'text-lg text-gray-800',
                                duration.is_exceeded && 'text-red-600'
                            )}
                        >
                            {statusLabels[duration.status] || duration.status}
                        </p>
                        <div className="flex items-center justify-between text-sm">
                            <p
                                className={twMerge(
                                    'text-gray-700',
                                    duration.is_exceeded && 'text-red-600'
                                )}
                            >
                                {duration.real_duration}
                            </p>
                            <p className="ml-3 text-gray-500">
                                {moment(duration.created_at).format('HH:mm')}
                            </p>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </>
    )
}

type SurgeryMedicalTeamProps = {
    team: NonNullable<OperationTimes['surgical_team']>
}

function SurgeryMedicalTeam({ team }: SurgeryMedicalTeamProps) {
    const doctor = useMemo(
        () => team.find((item) => item.role === 'doctor'),
        [team]
    )
    const remainingTeam = useMemo(
        () => team.filter((item) => item.role !== 'doctor'),
        [team]
    )

    return (
        <div>
            <div className="flex items-center text-xs leading-none text-gray-800">
                <IconUser className="w-4 h-auto mr-4" />
                {!!doctor && (
                    <span>{doctor.first_name + ' ' + doctor.last_name}</span>
                )}
                {!doctor && <span>Nieprzypisany operator</span>}
            </div>
            {remainingTeam.length > 0 && (
                <div className="mt-2 text-xs text-gray-600 leading-tight font-normal">
                    <div className="flex items-start">
                        <IconMedicalTeam className="w-4 h-auto mr-4" />
                        <span className="space-y-1">
                            {uniqBy(remainingTeam, (item) => item.role).map(
                                ({ role }) => (
                                    <div key={role}>
                                        {role === 'assistant' && (
                                            <span>Asysta</span>
                                        )}
                                        {role === 'anesthesiologist' && (
                                            <span>Anestezjolog</span>
                                        )}
                                        {role === 'anesthesiologist_nurse' && (
                                            <span>Pielęgniarki anestez.</span>
                                        )}
                                        {role === 'operation_nurse' && (
                                            <span>Pielęgniarki operac.</span>
                                        )}
                                        :{' '}
                                        <span className="ml-1 text-gray-800">
                                            {team
                                                ?.filter(
                                                    (item) => item.role === role
                                                )
                                                .map(
                                                    (item) =>
                                                        `${item.first_name.charAt(
                                                            0
                                                        )}. ${item.last_name}`
                                                )
                                                .join(', ')}
                                        </span>
                                    </div>
                                )
                            )}
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

function UnoccupiedOperatingRoom(props: UnoccupiedOperatingRoomProps) {
    const { item } = props

    const alert = useMemo(() => item.is_exceeded, [item])

    return (
        <div
            className={twMerge(
                'flex px-2 py-2 text-base items-center justify-between text-white bg-gray-500 rounded leading-normal font-medium',
                alert && 'bg-red-600'
            )}
        >
            <span>
                {moment(item.from).format('HH:mm')} -{' '}
                {moment(item.to).format('HH:mm')}
            </span>
            <span className="text-lg">{item.duration}</span>
        </div>
    )
}

const useBoxShadowStyle = (expanded: boolean): CSSProperties => {
    return useMemo(
        () =>
            Object.assign(
                {},
                expanded
                    ? {
                          boxShadow:
                              '0px 8px 28px 0px #0000008F, 0px 0px 0px 1px #B4B8BE inset',
                      }
                    : {}
            ),
        [expanded]
    )
}

const useDashedTopBorderStyle = () => {
    return useMemo(
        () => ({
            backgroundImage: `
      repeating-linear-gradient(90deg, #d1d5db, #d1d5db 16px, transparent 16px, transparent 28px, #d1d5db 28px)
    `,
            backgroundSize: '100% 2px',
            backgroundPosition: '0 0',
            backgroundRepeat: 'no-repeat',
            borderTop: '2px solid transparent', // Ensures the height of the element includes the top border space
        }),
        []
    )
}
