import { useOperationMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const OperationIndexPerformedMenuItemGate: MenuItemGate = ({
    children,
}) => {
    const operationMenuItemPolicyGroup = useOperationMenuItemPolicyGroup()

    if (operationMenuItemPolicyGroup.canIndexPerformed) {
        return children
    }

    return null
}
