import { useLiveViewPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const LiveViewMenuItemGate: MenuItemGate = ({ children }) => {
    const liveViewPolicyGroup = useLiveViewPolicyGroup()

    if (liveViewPolicyGroup.canIndex) {
        return children
    }

    return null
}
