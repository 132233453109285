import React from 'react'
import { Form, Formik } from 'formik'

import { Label, RangeDatePicker } from 'components/ui'
import { AutoSubmitRow, EmployeePickerField, TextField } from 'components/forms'

import type {
    ActivityLogFilterState,
    ActivityLogFilterAction,
} from 'containers/ActivityLog'

const ActivityLogListFilters: React.FC<{
    filters: ActivityLogFilterState
    setFilters: React.Dispatch<ActivityLogFilterAction>
}> = ({ filters, setFilters }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters.form}
                onSubmit={(values) =>
                    setFilters({ type: 'change', payload: values })
                }
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form>
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
                            <div>
                                <Label>Użytkownik</Label>
                                <EmployeePickerField
                                    name="user"
                                    placeholder="Wybierz użytkownika"
                                    functions={[]}
                                    messageEmptyResult="Nie znaleziono użytkownika."
                                />
                            </div>
                            <div>
                                <Label>Data</Label>
                                <RangeDatePicker
                                    placeholderFrom="Rozpoczęcie"
                                    placeholderTo="Zakończenie"
                                    value={values.dateRange}
                                    onChange={(dateRange) => {
                                        setFieldValue('dateRange', dateRange)
                                    }}
                                />
                            </div>
                            <div>
                                <Label>ID operacji</Label>
                                <TextField
                                    style={{ width: 'auto' }}
                                    name="operation_id"
                                />
                            </div>
                            <div></div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ActivityLogListFilters
