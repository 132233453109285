import React from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { isPatientName, displayPatientName, forOperationDate } from 'helpers'
import UserAvatar from 'components/UserAvatar'

import type { ScheduleOperationListItem } from 'api/types'

const DailyGridItem = React.forwardRef<
    HTMLDivElement | null,
    {
        item: ScheduleOperationListItem
        displayPreferredBeginningHour?: boolean
        colored?: boolean
        isClickable?: boolean
        isDraggable?: boolean
        isDragging?: boolean
    }
>(
    (
        {
            displayPreferredBeginningHour,
            colored,
            item,
            isClickable,
            isDraggable,
            isDragging,
        },
        ref
    ) => {
        const isAnonymous = !item.can_see_details
        const isRejected = item.status === 'rejected'
        const isCanceled = item.status === 'canceled'
        const isScheduled = item.is_scheduled
        const isAccepted = item.accepted

        return (
            <div
                ref={ref}
                className={classNames(
                    'schedule-event relative py-1.5 px-1 xl:pl-5 xl:pr-1.5 flex flex-1 flex-col text-sm rounded print:border-gray-400 print:border-solid print:shadow-none',
                    {
                        'opacity-50': isDragging,
                        'border-dashed': !isScheduled,
                        'border border-gray-900 border-opacity-10': !isCanceled,
                        'bg-red-100':
                            !isCanceled &&
                            !isAnonymous &&
                            !isRejected &&
                            item.progress.percent === 0,
                        'bg-yellow-100':
                            !isCanceled &&
                            !isAnonymous &&
                            !isRejected &&
                            item.progress.percent > 0,
                        'bg-green-100':
                            !isCanceled && !isAnonymous && isAccepted,
                        'bg-gray-100':
                            (!isCanceled && !isAnonymous && isRejected) ||
                            (!isCanceled && isAnonymous),
                        'text-gray-900': !isCanceled,
                        'bg-gray-500 text-white': isCanceled,
                        'hover:shadow-lg': !isDraggable && !isAnonymous,
                        'cursor-pointer': isClickable,
                        'cursor-move': isDraggable,
                    }
                )}
            >
                <div className="flex items-center justify-between">
                    <div className="text-sm leading-4 space-x-1.5 whitespace-nowrap truncate">
                        {!isCanceled &&
                            !colored &&
                            !!item.estimated_duration && (
                                <span className="text-xs text-gray-500">
                                    {item.estimated_duration}
                                </span>
                            )}
                        {displayPreferredBeginningHour &&
                            !!item.preferred_beginning_hour && (
                                <span className="font-medium">
                                    {item.preferred_beginning_hour}
                                </span>
                            )}
                        {!!item.final_operation_date && (
                            <span className={classNames('font-medium')}>
                                {moment(item.final_operation_date).format(
                                    'HH:mm'
                                )}
                                {!item.optimisticUpdate &&
                                    colored &&
                                    !!item.finish_at &&
                                    `-${moment(item.finish_at).format(
                                        'HH:mm'
                                    )}`}
                            </span>
                        )}
                        <span className="text-xs bg-white text-gray-600 font-bold inline-flex px-1 items-center justify-center rounded">
                            {item.is_nfz ? 'NFZ' : 'KOM'}
                        </span>
                    </div>
                    <div className="flex items-center text-xs leading-none space-x-1">
                        {item.admission_date && (
                            <span
                                className={classNames('print:text-gray-900', {
                                    'text-gray-600': !isCanceled,
                                })}
                            >
                                {forOperationDate(item.estimated_date).isDZ(
                                    item.admission_date
                                ) ? (
                                    <span>DZ</span>
                                ) : forOperationDate(item.estimated_date).isDW(
                                      item.admission_date
                                  ) ? (
                                    <span>DW</span>
                                ) : (
                                    <span>
                                        {moment(item.admission_date).format(
                                            'DD.MM'
                                        )}
                                    </span>
                                )}
                                <span className="pl-0.5">
                                    {moment(item.admission_date).format(
                                        'HH:mm'
                                    )}
                                </span>
                            </span>
                        )}
                        <span className="px-1 py-0.5 bg-gray-500 text-white rounded-sm print:border print:bg-white print:border-gray-400 print:text-gray-900">
                            {item.room || '-'}
                        </span>
                    </div>
                </div>

                <div className="mt-1 flex items-center space-x-2 font-medium">
                    <span>
                        {isPatientName(item.patient)
                            ? displayPatientName(item.patient)
                            : '-'}
                    </span>
                    <span>
                        (
                        {item.patient?.age || item.patient?.age === 0
                            ? item.patient.age
                            : '-'}
                        )
                    </span>
                </div>

                <div className="mt-2">
                    <p
                        className={classNames(
                            'flex space-x-2 leading-5 print:text-gray-800',
                            {
                                'text-gray-500': !isCanceled,
                            }
                        )}
                    >
                        <span
                            className="flex-shrink-0 mt-1.5 w-2.5 h-2.5 rounded-full mt-px print:hidden"
                            style={{
                                backgroundColor: item.color,
                            }}
                        ></span>
                        <span>{item.name || '-'}</span>
                    </p>
                </div>

                <div className="text-xs">
                    <div className="mt-3 leading-none">
                        <div className="flex items-center space-x-2">
                            <div className="flex-shrink-0">
                                {!!item.operator && (
                                    <UserAvatar
                                        data={item.operator}
                                        size={5}
                                        variant="avatar"
                                    />
                                )}
                                {!item.operator && (
                                    <div className="flex w-5 h-5 bg-gray-500 rounded-full text-white leading-6 items-center justify-center print:text-gray-800">
                                        -
                                    </div>
                                )}
                            </div>
                            <div className="flex-1">
                                <span
                                    className={classNames(
                                        'leading-5 font-medium',
                                        {
                                            'text-gray-900': !isCanceled,
                                        }
                                    )}
                                >
                                    {!!item.operator &&
                                        item.operator.first_name}{' '}
                                    {!!item.operator && item.operator.last_name}
                                    {!item.operator && (
                                        <span>Nieprzypisany operator</span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="space-y-0.5">
                            <SurgicalTeamRoleMember
                                item={item}
                                role="assistant"
                                roleName="Asysta"
                                isCanceled={isCanceled}
                            />
                            <SurgicalTeamRoleMember
                                item={item}
                                role="anesthesiologist"
                                roleName="Anest."
                                isCanceled={isCanceled}
                            />
                            <SurgicalTeamRoleMember
                                item={item}
                                role="anesthesiologist_nurse"
                                roleName="Piel. anest."
                                isCanceled={isCanceled}
                            />
                            <SurgicalTeamRoleMember
                                item={item}
                                role="operation_nurse"
                                roleName="Piel. oper."
                                isCanceled={isCanceled}
                            />
                        </div>
                    </div>
                    <div className="mt-3">
                        <h3 className="font-medium">Sprzęt medyczny</h3>
                        <div className="flex flex-wrap gap-1 mt-1.5">
                            {item.equipment.length === 0
                                ? '-'
                                : item.equipment.map((item) => (
                                      <div
                                          className="p-1 bg-yellow-400 text-gray-900 leading-none rounded"
                                          key={item.name}
                                      >
                                          {item.name}
                                      </div>
                                  ))}
                        </div>
                    </div>
                    <div className="mt-3">
                        <h3 className="font-medium">Implanty</h3>
                        <div className="mt-1.5">
                            <span
                                className={classNames(
                                    'inline-block rounded bg-white px-2 py-0.5 leading-normal text-xs ring-1 ring-inset',
                                    {
                                        'text-green-700 ring-green-700':
                                            item.implants_approved,
                                        'text-red-700 ring-red-700':
                                            !item.implants_approved,
                                    }
                                )}
                            >
                                {item.implants_approved ? (
                                    <span>Zamówione</span>
                                ) : (
                                    <span>Niezamówione</span>
                                )}
                            </span>
                        </div>
                        <span className="inline-block mt-1.5">
                            {item.implants
                                .map((item) => item.label || item.name)
                                .join(', ') || '-'}
                        </span>
                    </div>
                    <div className="mt-3">
                        <h3 className="font-medium">Uwagi do zabiegu</h3>
                        <span className="inline-block mt-1.5 whitespace-pre-line">
                            {item.description || '-'}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
)

const SurgicalTeamRoleMember: React.FC<{
    item: ScheduleOperationListItem
    role: string
    roleName: string
    isCanceled: boolean
}> = ({ item, role, roleName, isCanceled }) => {
    return (
        <dl className="flex space-x-1">
            <dd className="flex-shrink-0">
                <span
                    className={classNames({
                        'text-gray-500': !isCanceled,
                    })}
                >
                    {roleName}:
                </span>
            </dd>
            <dt>
                {item.surgical_team
                    .filter((member) => member.role === role)
                    .map(
                        (member) =>
                            `${member.first_name[0]}. ${member.last_name}`
                    )
                    .join(', ') || '-'}
            </dt>
        </dl>
    )
}

export default DailyGridItem
