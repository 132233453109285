import React from 'react'
import { DownloadIcon } from '@heroicons/react/outline'

import { Button } from 'components/ui'

import styles from './ButtonPrint.module.scss'

export default function ExportButton({
    onClick,
    isExporting,
}: {
    onClick: () => void
    isExporting?: boolean
}) {
    return (
        <Button
            type="button"
            variant="default"
            size="sm"
            styles={styles}
            onClick={onClick}
            loading={isExporting}
        >
            <DownloadIcon className="w-4 h-4 text-gray-500" />
        </Button>
    )
}
