import React, { useMemo, useReducer, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { useGetUsers, usePostResendLink } from 'api'
import { useNotification, useUserPolicyGroup } from 'hooks'
import { listReducer } from 'helpers'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { Button, FilterButton, Loader } from 'components/ui'
import SettingsUserList from 'components/SettingsUserList'
import Filters from 'components/SettingsUserListFilters'

import type { ResponseList } from 'api/types'
import type { UserListItem } from 'types'
import type { DropdownItem } from 'components/forms'
import type { ListFilterAction } from 'helpers'
import type { ListFilters } from 'helpers'

export type ResendLinkState = {
    userId: number | null
    state: string
}

export type FilterAction =
    | {
          type: 'setPage'
          payload: number
      }
    | {
          type: 'setValues'
          payload: IFilters
      }
    | {
          type: 'resetFilters'
          payload?: never
      }
    | ListFilterAction

export interface IFilters extends ListFilters {
    query: string
    specializations: DropdownItem[]
    roles: DropdownItem[]
}

function filterReducer(state: IFilters, action: FilterAction) {
    switch (action.type) {
        case 'setPage':
            return {
                ...state,
                page: action.payload,
            }

        case 'setValues':
            return {
                ...action.payload,
                page: 1,
            }

        case 'resetFilters':
            return {
                ...state,
                query: '',
                specializations: [],
                roles: [],
                page: 1,
            }

        default:
            return listReducer(state, action) as IFilters
    }
}

const SettingsUserListContainer = () => {
    const userPolicyGroup = useUserPolicyGroup()
    const showNotification = useNotification()

    const [filters, dispatch] = useReducer(filterReducer, {
        query: '',
        specializations: [],
        roles: [],
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    } as IFilters)

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)

    const filtersCount = useMemo(
        () =>
            (filters.query.length ? 1 : 0) +
            (filters.specializations.length ? 1 : 0) +
            (filters.roles.length ? 1 : 0),
        [filters]
    )

    const users = useGetUsers<ResponseList<UserListItem[]>>(
        {
            ...filters,
            specializations: filters.specializations.map((item) => item.id),
            roles: filters.roles.map((item) => item.id),
        },
        {
            enabled: userPolicyGroup.canAll,
        }
    )

    const { mutate: resendLink } = usePostResendLink()

    const handleResetFilters = () => {
        dispatch({ type: 'resetFilters' })
        setFiltersExpanded(false)
    }

    const [resendingLink, setCurrentResendingLink] = useState<ResendLinkState>({
        userId: null,
        state: 'idle',
    })

    const handleResendLink = (id: number) => {
        setCurrentResendingLink((prevState) => ({
            state: 'loading',
            userId: id,
        }))

        resendLink(
            {
                user_id: id,
            },
            {
                onSuccess: (data) => {
                    setCurrentResendingLink((prevState) => ({
                        ...prevState,
                        state: 'success',
                    }))

                    showNotification({
                        type: 'success',
                        content: data.data.message,
                    })
                },
                onError: (error) => {
                    setCurrentResendingLink((prevState) => ({
                        ...prevState,
                        state: 'error',
                    }))

                    showNotification({
                        type: 'danger',
                        content: error.message,
                    })
                },
            }
        )
    }

    return (
        <ListLayout
            title="Lista użytkowników"
            actions={
                <>
                    <span className="ml-2">
                        <FilterButton
                            count={filtersCount}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                            filtersExpanded={filtersExpanded}
                            handleReset={handleResetFilters}
                        />
                    </span>
                    {userPolicyGroup.canAll && (
                        <Button
                            as="link"
                            to="/settings/users/add"
                            variant="tertiary"
                            iconRight={<PlusIcon />}
                            size="md"
                            className="ml-4"
                        >
                            Dodaj użytkownika
                        </Button>
                    )}
                </>
            }
        >
            <>
                {!userPolicyGroup.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania użytkownikami" />
                )}
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={dispatch} />
                )}
                {users.isLoading && <Loader />}
                {users.isError && <div>{users.error.message}</div>}
                {users.isSuccess && (
                    <SettingsUserList
                        data={users.data}
                        filtersCount={filtersCount}
                        setFilters={dispatch}
                        resendingLink={resendingLink}
                        handleResendLink={handleResendLink}
                    />
                )}
            </>
        </ListLayout>
    )
}

export default SettingsUserListContainer
