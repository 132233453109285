import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import axios from 'api/axios'

import type { Permission, ResponseData, ResponseError } from 'api/types'

export const getPermissions = <T>(filters?: {}): Promise<ResponseData<T>> =>
    axios.get('/permissions' + (filters ? '?' + qs.stringify(filters) : ''))

export const useGetPermissions = <T = Permission[]>(
    filters?: {},
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
): UseQueryResult<ResponseData<T>, ResponseError> =>
    useQuery<ResponseData<T>, ResponseError>(
        ['permissions'],
        () => getPermissions<T>(filters),
        options
    )
