import { useProcedureMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const ProcedureMenuItemGate: MenuItemGate = ({ children }) => {
    const procedureMenuItemPolicyGroup = useProcedureMenuItemPolicyGroup()

    if (procedureMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
