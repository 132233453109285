import React, { useMemo, useReducer, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { procedureFilterReducer } from 'helpers'
import {
    countActiveFilters,
    transformProcedureFiltersToApi,
    useGetProcedures,
} from 'api'
import { useProcedurePolicyGroup } from 'hooks'
import SettingsLayout from 'layouts/SettingsLayout'
import Filters from 'containers/SettingsProcedureListFilters'
import { AccessDenied } from 'components'
import { ActionButton, FilterButton, Loader } from 'components/ui'
import SettingsProcedureList from 'components/SettingsProcedureList'

export default function SettingsProcedureListContainer() {
    const procedurePolicyGroup = useProcedurePolicyGroup()
    const [filters, dispatch] = useReducer(procedureFilterReducer, {
        filters: {
            name: '',
            procedure_categories: [],
            procedure_types: [],
        },
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const filtersToApi = useMemo(
        () => transformProcedureFiltersToApi(filters),
        [filters]
    )
    const procedures = useGetProcedures(filtersToApi, {
        enabled: procedurePolicyGroup.canAll,
    })
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () => countActiveFilters(filtersToApi),
        [filtersToApi]
    )

    return (
        <SettingsLayout
            actions={
                procedurePolicyGroup.canAll && (
                    <>
                        <FilterButton
                            count={filtersCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                        />
                        <span className="ml-4">
                            <ActionButton
                                to="/settings/procedures/add"
                                rightIcon={PlusIcon}
                            >
                                Dodaj
                            </ActionButton>
                        </span>
                    </>
                )
            }
        >
            <>
                {!procedurePolicyGroup.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania procedurami" />
                )}
                {filtersExpanded && (
                    <Filters
                        filters={filters.filters}
                        setFilters={(values) =>
                            dispatch({ type: 'change', payload: values })
                        }
                        hasPayers={false}
                    />
                )}
                {procedures.isLoading && <Loader />}
                {procedures.isError && <div>{procedures.error.message}</div>}
                {procedures.isSuccess && (
                    <SettingsProcedureList
                        data={procedures.data}
                        filtersCount={filtersCount}
                        setFilters={dispatch}
                    />
                )}
            </>
        </SettingsLayout>
    )
}
