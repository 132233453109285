import React from 'react'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'

import { getCxFromStyles } from 'helpers'

import styles from './Menu3dotsItem.module.scss'

type BaseItem = {
    children: React.ReactNode
    variant?: 'regular' | 'custom' | 'danger'
    disabled?: boolean
}

type ItemAsLink = BaseItem & {
    as: 'link'
    to: string
    state?: unknown
}

type ItemAsRegular = BaseItem & {
    as?: 'regular'
    onClick: () => void
}

type Menu3dotsItemProps = ItemAsRegular | ItemAsLink

const Menu3dotsItem: React.FC<Menu3dotsItemProps> = ({
    children,
    variant,
    disabled,
    ...props
}) => {
    variant = variant || 'regular'
    const cx = getCxFromStyles(styles)

    if (props.as === 'link') {
        const { to, state } = props

        return (
            <Menu.Item>
                <Link
                    to={to}
                    state={state}
                    className={cx('root', variant, { disabled })}
                >
                    {children}
                </Link>
            </Menu.Item>
        )
    }

    const { onClick } = props
    return (
        <Menu.Item>
            <span
                onClick={onClick}
                className={cx('root', variant, { disabled })}
            >
                {children}
            </span>
        </Menu.Item>
    )
}

export default Menu3dotsItem
