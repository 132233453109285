export function peselDecode(pesel: string) {
    let year = parseInt(pesel.substring(0, 2), 10)
    let month = parseInt(pesel.substring(2, 4), 10) - 1
    let day = parseInt(pesel.substring(4, 6), 10)

    if (month >= 80) {
        year = year + 1800
        month = month - 80
    } else if (month >= 60) {
        year = year + 2200
        month = month - 60
    } else if (month >= 40) {
        year = year + 2100
        month = month - 40
    } else if (month >= 20) {
        year = year + 2000
        month = month - 20
    } else {
        year += 1900
    }

    let date = new Date()
    date.setFullYear(year, month, day)

    const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7]

    const sum =
        weight.reduce((acc, item, i) => {
            acc += parseInt(pesel.substring(i, i + 1), 10) * weight[i]
            return acc
        }, 0) % 10

    const valid = sum === parseInt(pesel.substring(10, 11), 10)

    const gender =
        parseInt(pesel.substring(9, 10), 10) % 2 === 1 ? 'male' : 'female'

    return { valid: valid, gender: gender, date: date }
}
