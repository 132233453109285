import React from 'react'

import { SlideOver } from 'components/ui'
import ScheduleOperationForm from 'components/ScheduleOperationForm'
import ScheduleOperationFormContainer from 'containers/ScheduleOperationForm'

import type { IModal } from 'types'
import type { OperationRenewModalState } from 'components/OperationRenewModal'

const ScheduleOperationSlideOver: React.FC<{
    editable?: boolean
    modal: IModal
    onSuccessChange: () => void
    isRejecting?: boolean
    isRenewing?: boolean
    onReject?: () => void
    onRenew?: (data: OperationRenewModalState) => void
    onCancel?: (id: number) => void
    onClickOutside?: () => void
    hideSMSButton?: boolean
}> = ({
    editable = true,
    modal,
    onSuccessChange,
    isRejecting,
    isRenewing,
    onReject,
    onRenew,
    onCancel,
    onClickOutside,
    hideSMSButton,
}) => {
    return (
        <SlideOver
            isOpen={modal.isOpen}
            classNameSlideOver="max-w-6xl"
            onClose={modal.closeModal}
            onClickOutside={onClickOutside}
        >
            <ScheduleOperationFormContainer
                id={modal.getState() as number}
                onSuccessChange={onSuccessChange}
            >
                {({
                    id,
                    data,
                    rooms,
                    comments,
                    handleSubmit,
                    handleChildToggleChange,
                }) => (
                    <ScheduleOperationForm
                        editable={editable}
                        id={id}
                        data={data}
                        rooms={rooms}
                        comments={comments}
                        isRejecting={isRejecting}
                        isRenewing={isRenewing}
                        onReject={onReject}
                        onRenew={onRenew}
                        onCancel={onCancel}
                        handleSubmit={handleSubmit}
                        handleChildToggleChange={handleChildToggleChange}
                        handleClose={modal.closeModal}
                        hideSMSButton={hideSMSButton}
                    />
                )}
            </ScheduleOperationFormContainer>
        </SlideOver>
    )
}

export default ScheduleOperationSlideOver
