import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import {
    ResponseList,
    ResponseError,
    ResponseData,
    PatientResponse,
    PostPatient,
    UpdatePatient,
    PatientListItem,
} from 'api/types'

export const getPatients = <T>(filters?: {}): Promise<T> =>
    axios.get('/patients' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetPatients = <T = ResponseList<PatientListItem[]>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['patients', filters],
        () => getPatients<T>(filters),
        options
    )

export const useGetPatient = <
    T extends PatientResponse = PatientResponse,
    E = ResponseError
>(
    id?: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['patients', id],
        () => axios.get(`/patients/${id}`),
        options
    )

export const createPatient = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/patients', data)

export const useCreatePatient = <
    R1 = PostPatient,
    R2 = ResponseData<PatientResponse>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createPatient<R1, R2>(data))

export const updatePatient = <R1, R2>(
    id: number | string,
    data: R1
): Promise<R2> => axios.patch('/patients/' + id, data)

export const useUpdatePatient = <
    R1 extends Pick<UpdatePatient, 'id'> = UpdatePatient,
    R2 = ResponseData<PatientResponse>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updatePatient<R1, R2>(data.id, data))

export const deletePatient = <R>(id: number): Promise<R> =>
    axios.delete(`/patients/${id}`)

export const useDeletePatient = <R = PatientResponse>(): UseMutationResult<
    R,
    ResponseError,
    number
> => useMutation((id: number) => deletePatient<R>(id))
