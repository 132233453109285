import React, { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import {
    countActiveFilters,
    defaultFormImplantFilters,
    transformImplantFiltersToApi,
    useGetImplants,
    useUpdateImplant,
} from 'api'
import {
    useModal,
    useSelectedRows,
    useNotification,
    useImplantPolicyGroup,
} from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, FilterButton, Loader } from 'components/ui'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsImplantList, {
    FormValues,
    SelectedItemActions,
} from 'components/SettingsImplantList'
import Filters from 'containers/SettingsImplantListFilters'
import SettingsImplantListAssignModal from 'containers/SettingsImplantListAssignModal'

import type { FormImplantFilters, Implant } from 'api/types'
import type { FormSubmitFn } from 'types'

const initialFilters = defaultFormImplantFilters({ set: false })

export default function SettingsImplantListContainer() {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const implantPolicyGroup = useImplantPolicyGroup()
    const assignModal = useModal()
    const [page, setPage] = useState<number>(1)
    const [filters, setFilters] = useState<FormImplantFilters>(initialFilters)
    const filtersToApi = useMemo(
        () => transformImplantFiltersToApi({ ...filters, page }),
        [filters, page]
    )
    const implants = useGetImplants(filtersToApi, {
        enabled: implantPolicyGroup.canAll,
    })
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () =>
            countActiveFilters(filtersToApi, [
                'query',
                'procedures',
                'manufacturer_id',
            ]),
        [filtersToApi]
    )
    const { mutate: updateImplant } = useUpdateImplant<FormValues>()

    const {
        selectedRows,
        unselectAllRows,
        checkIsRowSelected,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection,
        areAllRowsOnCurrentPageSelected,
    } = useSelectedRows<Implant>({
        initialSelections: [],
        currentPageRows: implants.data?.data || [],
    })

    const selectedItemIds = useMemo(
        () => selectedRows.map(({ id }) => id),
        [selectedRows]
    )

    useEffect(() => {
        unselectAllRows()
    }, [filters, unselectAllRows])

    const handleSuccess = async () => {
        unselectAllRows()
        await queryClient.invalidateQueries('implants', {
            refetchInactive: true,
        })
        assignModal.closeModal()
    }

    const handleUpdate: FormSubmitFn<FormValues> = (values, formikHelpers) => {
        updateImplant(values, {
            onSuccess: async () => {
                await handleSuccess()
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany zostały zapisane',
                    type: 'success',
                })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
                showNotification({
                    content: 'Nie udało się zapisać zmian',
                    type: 'success',
                })
            },
        })
    }

    return (
        <SettingsPriceListLayout
            actions={
                implantPolicyGroup.canAll &&
                (selectedRows.length ? (
                    <SelectedItemActions
                        handleAssign={assignModal.openModal}
                        selectedItems={selectedItemIds}
                        unselectAllRows={unselectAllRows}
                    />
                ) : (
                    <>
                        <FilterButton
                            count={filtersCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() => setFiltersExpanded(!filtersExpanded)}
                        />
                        <span className="ml-4">
                            <ActionButton
                                to="/settings/price-list/implants/add"
                                rightIcon={PlusIcon}
                            >
                                Dodaj
                            </ActionButton>
                        </span>
                    </>
                ))
            }
        >
            <>
                {!implantPolicyGroup.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania implantami" />
                )}
                {filtersExpanded && (
                    <Filters filters={filters} setFilters={setFilters} />
                )}
                {implants.isLoading && <Loader />}
                {implants.isError && <div>{implants.error.message}</div>}
                {implants.isSuccess && (
                    <SettingsImplantList
                        data={implants.data}
                        filtersCount={filtersCount}
                        setPage={setPage}
                        handleUpdate={handleUpdate}
                        selectedItems={selectedItemIds}
                        toggleAllCurrentPageRowsSelection={
                            toggleAllCurrentPageRowsSelection
                        }
                        checkIsRowSelected={checkIsRowSelected}
                        toggleRowSelection={toggleRowSelection}
                        areAllRowsOnCurrentPageSelected={
                            areAllRowsOnCurrentPageSelected
                        }
                    />
                )}
                <SettingsImplantListAssignModal
                    modal={assignModal}
                    ids={selectedItemIds}
                    handleSuccess={handleSuccess}
                />
            </>
        </SettingsPriceListLayout>
    )
}
