import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

import { getProcedureTypes } from 'api'
import { Label, RangeDatePicker } from 'components/ui'
import {
    AutoSubmitRow,
    CheckboxDropdownField,
    CheckboxDropdownLazyField,
    EmployeePickerField,
} from 'components/forms'

import type { FormSubmitFn } from 'types'
import type { SurgeryPerformedListFilters } from 'containers/DoctorSurgeryPerformedList'

const DoctorSurgeryPerformedListFilters: React.FC<{
    filters: SurgeryPerformedListFilters
    onSubmit: FormSubmitFn<SurgeryPerformedListFilters>
    showDateRange: boolean
    showOperator: boolean
}> = ({ filters, onSubmit, showDateRange, showOperator }) => {
    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ handleChange, values, setFieldValue }) => (
                    <Form>
                        <div
                            className={twMerge(
                                'grid grid-cols-4 gap-4',
                                (showDateRange || showOperator) && 'grid-cols-3'
                            )}
                        >
                            <div>
                                <Label>Szukanie po nazwie operacji</Label>
                                <div className="shadow-sm rounded-md">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name="name"
                                            className="block w-full pr-10 appearance-none px-3 py-2 focus:outline-none text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                            autoComplete="off"
                                            placeholder="Fraza"
                                            value={values.name}
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <SearchIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showOperator && (
                                <div>
                                    <Label>Wyszukaj operatora</Label>
                                    <EmployeePickerField
                                        name="operator"
                                        placeholder="Wyszukaj operatora"
                                        functions={['doctor function']}
                                        messageEmptyResult="Nie znaleziono operatora."
                                    />
                                </div>
                            )}
                            {showDateRange && (
                                <div>
                                    <Label>Zakres dat zabiegów</Label>
                                    <RangeDatePicker
                                        sideMenu={false}
                                        placeholderFrom="Rozpoczęcie"
                                        placeholderTo="Zakończenie"
                                        value={values.date_range}
                                        onChange={(dateRange) => {
                                            setFieldValue(
                                                'date_range',
                                                dateRange
                                            )
                                        }}
                                        onBlur={() => {
                                            if (
                                                !values.date_range.from ||
                                                !values.date_range.to
                                            ) {
                                                setFieldValue('date_range', {
                                                    from: undefined,
                                                    to: undefined,
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            )}

                            <div>
                                <Label>Wyszukaj pacjenta</Label>
                                <div className="shadow-sm rounded-md">
                                    <div className="relative">
                                        <input
                                            type="text"
                                            name="query"
                                            className="block w-full pr-10 appearance-none block w-full px-3 py-2 focus:outline-none text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                            autoComplete="off"
                                            placeholder="Imię, Nazwisko, PESEL, Nr dowodu..."
                                            value={values.query}
                                            onChange={handleChange}
                                        />
                                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <SearchIcon
                                                className="h-5 w-5 text-gray-500"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Label>Rodzaj zabiegu</Label>
                                <div className="shadow-sm rounded-md">
                                    <CheckboxDropdownLazyField
                                        name="procedure_types"
                                        placeholder="Wszystkie"
                                        bulkOption={true}
                                        bulkOptionName="Wszystkie"
                                        queryFn={getProcedureTypes}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label>Status</Label>
                                <div className="shadow-sm rounded-md">
                                    <CheckboxDropdownField
                                        name="statuses"
                                        placeholder="Wszystkie"
                                        items={[
                                            {
                                                id: 'to_description',
                                                name: 'Nieopisana',
                                            },
                                            {
                                                id: 'during_description',
                                                name: 'W trakcie',
                                            },
                                            {
                                                id: 'descripted',
                                                name: 'Opisana',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default DoctorSurgeryPerformedListFilters
