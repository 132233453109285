import { createContext } from 'react'

export interface MediaContext {
    width: number
    height: number
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
}

export default createContext<MediaContext>({
    width: 0,
    height: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
})
