import React from 'react'
import { Form, Formik } from 'formik'
import classNames from 'classnames'
import { Dialog } from '@headlessui/react'

import { TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS } from 'helpers'
import { Modal } from 'components'
import { Label, Button } from 'components/ui'
import {
    AdmissionTimeDropdown,
    ErrorMessage,
    TimeDropdownItem,
} from 'components/forms'

import type { FormSubmitFn, IModal } from 'types'

export interface FormValues {
    duration_in_minutes: TimeDropdownItem | undefined
    anesthesia_duration_in_minutes: TimeDropdownItem | undefined
}

const SettingsProcedureDurationListAssignModal: React.FC<{
    modal: IModal
    selectedProcedureIds: number[]
    selectedProcedureCategoryIds: number[]
    assignTo: string
    handleSubmit: FormSubmitFn<FormValues>
}> = ({
    modal,
    selectedProcedureIds,
    selectedProcedureCategoryIds,
    assignTo,
    handleSubmit,
}) => {
    return (
        <Modal modal={modal}>
            <div className={styles.modalInner}>
                <Dialog.Title as="h3" className={styles.dialogTitle}>
                    Edytuj
                </Dialog.Title>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{} as FormValues}
                >
                    {({ values, isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            {['procedures', 'procedure_categories'].includes(
                                assignTo
                            ) && (
                                <div className="mt-6">
                                    <span
                                        className={
                                            'text-sm leading-5 font-normal text-gray-600'
                                        }
                                    >
                                        Podaj czas operacji dla{' '}
                                        <b
                                            className={
                                                'font-semibold text-gray-500'
                                            }
                                        >
                                            {assignTo === 'procedures'
                                                ? selectedProcedureIds.length
                                                : assignTo ===
                                                  'procedure_categories'
                                                ? selectedProcedureCategoryIds.length
                                                : '-'}
                                        </b>{' '}
                                        {assignTo === 'procedures' && (
                                            <span>
                                                {selectedProcedureIds.length ===
                                                    1 && (
                                                    <span>
                                                        wybranej operacji.
                                                    </span>
                                                )}
                                                {selectedProcedureIds.length !==
                                                    1 && (
                                                    <span>
                                                        wybranych operacji.
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        {assignTo === 'categories' && (
                                            <span>
                                                {selectedProcedureCategoryIds.length ===
                                                    1 && (
                                                    <span>
                                                        wybranej kategorii.
                                                    </span>
                                                )}
                                                {selectedProcedureCategoryIds.length !==
                                                    1 && (
                                                    <span>
                                                        wybranych kategorii.
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            )}
                            <div className="mt-6">
                                <Label>Czas operacji</Label>
                                <AdmissionTimeDropdown
                                    name="duration_in_minutes"
                                    placeholder="--:--"
                                    items={
                                        TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS
                                    }
                                    value={values.duration_in_minutes}
                                    onChange={(value) =>
                                        setFieldValue(
                                            'duration_in_minutes',
                                            value
                                        )
                                    }
                                    hasError={'duration_in_minutes' in errors}
                                />
                                <ErrorMessage name="duration_in_minutes" />
                            </div>
                            <div className="mt-6">
                                <Label>
                                    Dodatkowy czas pracy anestezjologa
                                </Label>
                                <AdmissionTimeDropdown
                                    name="anesthesia_duration_in_minutes"
                                    placeholder="--:--"
                                    items={
                                        TIME_EVERY_15_MINUTES_ENTIRE_DAY_ITEMS
                                    }
                                    value={
                                        values.anesthesia_duration_in_minutes
                                    }
                                    onChange={(value) =>
                                        setFieldValue(
                                            'anesthesia_duration_in_minutes',
                                            value
                                        )
                                    }
                                    hasError={
                                        'anesthesia_duration_in_minutes' in
                                        errors
                                    }
                                />
                                <ErrorMessage name="anesthesia_duration_in_minutes" />
                            </div>
                            <div className={styles.actionContainer}>
                                <button
                                    type="button"
                                    className={styles.buttonCancel}
                                    onClick={() => modal.closeModal()}
                                >
                                    Anuluj
                                </button>
                                <Button
                                    variant="primary"
                                    className="col-start-2"
                                    type="submit"
                                    loading={isSubmitting}
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default SettingsProcedureDurationListAssignModal

const styles = {
    modalInner: classNames(
        'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl',
        'transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'
    ),
    dialogTitle: 'text-lg leading-6 font-medium text-gray-900 text-center',
    actionContainer:
        'mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense',
    buttonCancel: classNames(
        'mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2',
        'bg-white text-base font-medium text-gray-700 sm:mt-0 sm:col-start-1 sm:text-sm',
        'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    ),
}
