import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useQueryClient } from 'react-query'

import alertContext from './alertContext'
import Alert from 'components/Alert'

import type { AlertContext } from './alertContext'
import type { ResponseError } from 'api/types'

const AlertContextProvider: React.FC = ({ children }) => {
    const { Provider } = alertContext
    const queryClient = useQueryClient()
    const [alert, setAlert] = useState<AlertContext['alert'] | null>(null)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const timeout = useRef<NodeJS.Timeout | null>(null)

    const dismiss: AlertContext['dismiss'] = useCallback(() => {
        setShowAlert(false)
        timeout.current && clearTimeout(timeout.current)
    }, [])

    const add: AlertContext['add'] = useCallback(
        (alertToShow, dismissTime = 3000) => {
            if (!!alertToShow) {
                setAlert(alertToShow)
                setShowAlert(true)

                if (dismissTime > 0) {
                    timeout.current = setTimeout(dismiss, dismissTime)
                }
            }
        },
        [dismiss]
    )

    useEffect(() => {
        queryClient.setDefaultOptions({
            mutations: {
                onError: (error) => {
                    const responseError = error as ResponseError

                    if (responseError.response?.status === 403) {
                        return add({
                            type: 'danger',
                            content: 'Nie masz wystarczających uprawnień.',
                        })
                    }

                    if (responseError.response?.status === 422) {
                        return add({
                            type: 'danger',
                            content: 'Podane dane są nieprawidłowe.',
                        })
                    }

                    if (
                        responseError.response?.status &&
                        responseError.response.status >= 500
                    ) {
                        return add({
                            type: 'danger',
                            content: 'Wystąpił błąd serwera.',
                        })
                    }

                    return add({
                        type: 'danger',
                        content: (error as ResponseError).message,
                    })
                },
            },
        })

        return () => {
            timeout.current && clearTimeout(timeout.current)
        }

        // eslint-disable-next-line
    }, [])

    const contextValue = useMemo(
        () => ({
            alert,
            dismiss,
            add,
        }),
        [add, alert, dismiss]
    )

    return (
        <Provider value={contextValue}>
            <div className="fixed top-20 z-50 left-1/2 transform -translate-x-1/2 m-auto">
                <Alert dismiss={dismiss} show={showAlert} type={alert?.type}>
                    {alert?.content}
                </Alert>
            </div>
            {children}
        </Provider>
    )
}

export default AlertContextProvider
