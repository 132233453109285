import {
    useQuery,
    useMutation,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import {
    BlockedDay,
    BlockedDayItem,
    BlockedDaysFilters,
    ResponseData,
    ResponseError,
    ResponseNoContent,
} from 'api/types'

export const useGetBlockedDays = <
    F extends BlockedDaysFilters,
    R = ResponseData<BlockedDayItem[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['blocked-days', filters],
        () =>
            client.get(
                '/blocked-days' + (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const usePostBlockedDays = <
    R1 extends { date: string },
    R2 = ResponseData<BlockedDayItem>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/blocked-days', data))

export const useDeleteBlockedDays = <
    R1 = BlockedDay,
    R2 = ResponseNoContent
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.delete('/blocked-days/' + data))
