import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { z } from 'zod'

import {
    API_FORMAT_DATE,
    SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY,
} from 'constants/index'
import { RangeDatePicker } from 'components/ui'

import type { DateRange } from 'react-day-picker'

export const currentRangeDate = (): DateRange => {
    const storeDateRange = window.sessionStorage.getItem(
        SESSION_STORAGE_STATISTICS_DATE_RANGE_FILTER_KEY
    )

    if (storeDateRange) {
        try {
            const restoredDateRange = JSON.parse(storeDateRange)

            const result = datePickerFilterSchema.safeParse(restoredDateRange)

            if (result.success) {
                return {
                    from: new Date(Date.parse(result.data.from)),
                    to: new Date(Date.parse(result.data.to)),
                }
            }
        } catch (e) {}
    }

    return {
        from: moment().subtract(1, 'days').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'days').toDate(),
    }
}

const datePickerFilterSchema = z.object({
    from: z.string().datetime(),
    to: z.string().datetime(),
})

const StatisticsRangeDatePicker: React.FC<{
    dateRange: DateRange
    onChange: (rangeDate: DateRange) => void
}> = ({ dateRange, onChange }) => {
    const blockedDays = useMemo(() => [moment().startOf('day').toDate()], [])
    const blockedDaysAsString = useMemo(
        () => blockedDays.map((item) => moment(item).format(API_FORMAT_DATE)),
        [blockedDays]
    )
    const disabledAfter = useMemo(() => {
        return dateRange
            ? dateRange.from
                ? moment(dateRange.from).isBefore(moment(), 'date')
                    ? moment().subtract(1, 'days').toDate()
                    : undefined
                : dateRange.to
                ? moment(dateRange.to).isBefore(moment(), 'date')
                    ? moment().subtract(1, 'days').toDate()
                    : undefined
                : undefined
            : undefined
    }, [dateRange])
    const disabledBefore = useMemo(() => {
        return dateRange
            ? dateRange.from
                ? moment(dateRange.from).isSameOrAfter(moment(), 'date')
                    ? moment().add(1, 'days').toDate()
                    : undefined
                : dateRange.to
                ? moment(dateRange.to).isAfter(moment(), 'date')
                    ? moment().add(1, 'days').toDate()
                    : undefined
                : undefined
            : undefined
    }, [dateRange])
    const handleChange = useCallback(
        (dateRange) => {
            if (!dateRange) {
                return
            }

            if (
                dateRange.from &&
                blockedDaysAsString.includes(
                    moment(dateRange.from).format(API_FORMAT_DATE)
                )
            ) {
                return onChange({
                    ...dateRange,
                    from: undefined,
                })
            }

            if (
                dateRange.to &&
                blockedDaysAsString.includes(
                    moment(dateRange.to).format(API_FORMAT_DATE)
                )
            ) {
                return onChange({
                    ...dateRange,
                    to: undefined,
                })
            }

            onChange(dateRange)
        },
        [blockedDaysAsString, onChange]
    )
    const handleBlur = useCallback(() => {
        if (!dateRange.from || !dateRange.to) {
            onChange(currentRangeDate())
        }
    }, [dateRange, onChange])

    return (
        <div className="w-96">
            <RangeDatePicker
                placeholderFrom="Rozpoczęcie"
                placeholderTo="Zakończenie"
                value={dateRange}
                blockedDays={blockedDays}
                disabledBefore={disabledBefore}
                disabledAfter={disabledAfter}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </div>
    )
}

export default StatisticsRangeDatePicker
