import React from 'react'
import classNames from 'classnames'

import styles from './PlannerHorizontalLines.module.scss'

const PlannerHorizontalLines: React.FC = () => {
    return (
        <div className={classNames(styles.horizontalLines)}>
            <div className={styles.period7_12}></div>
            <div className={styles.period12_15}></div>
            <div className={styles.period15_20}></div>
            <div className={styles.period20_24}></div>
            <div
                className={classNames(styles.barPeriod, styles.barPeriod7_12)}
            ></div>
            <div
                className={classNames(styles.barPeriod, styles.barPeriod12_15)}
            ></div>
            <div
                className={classNames(styles.barPeriod, styles.barPeriod15_20)}
            ></div>
            <div
                className={classNames(styles.barPeriod, styles.barPeriod20_24)}
            ></div>
        </div>
    )
}

export default PlannerHorizontalLines
