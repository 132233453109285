import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import axios from 'api/axios'

import type { ResponseError, ResponseList, ICDCode } from 'api/types'

export const getIcdCodes = <T>(filters?: {}): Promise<T> => {
    return axios.get(
        '/icd-codes' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )
}

export const useGetIcdCodes = <T = ResponseList<ICDCode>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> => {
    return useQuery<T, ResponseError>(
        ['icd-codes', filters],
        () => getIcdCodes<T>(filters),
        options
    )
}
