import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import ScrollToTop from 'components/ScrollToTop'
import MainLayout from 'layouts/MainLayout'
import LoginLayout from 'layouts/LoginLayout'
import Maintenance from 'containers/Maintenance'
import Login from 'containers/Login'
import Logout from 'containers/Logout'
import ForgotPassword from 'containers/ForgotPassword'
import ResetPassword from 'containers/ResetPassword'
import SetPassword from 'containers/SetPassword'
import Main from 'containers/Main'
import SettingsProcedureCategoryList from 'containers/SettingsProcedureCategoryList'
import SettingsProcedureCategoryForm, {
    SettingsProcedureCategoryFormEdit,
} from 'containers/SettingsProcedureCategoryForm'
import SettingsProcedureTypeList from 'containers/SettingsProcedureTypeList'
import SettingsProcedureType from 'containers/SettingsProcedureType'
import SettingsProcedureList from 'containers/SettingsProcedureList'
import SettingsProcedureForm, {
    SettingsProcedureFormEdit,
} from 'containers/SettingsProcedureForm'
import SettingsPayerList from 'containers/SettingsPayerList'
import SettingsProcedureDurationList from 'containers/SettingsProcedureDurationList'
import SettingsProcedureCostList from 'containers/SettingsProcedureCostList'
import SettingsImplantList from 'containers/SettingsImplantList'
import SettingsImplantForm, {
    SettingsImplantFormEdit,
} from 'containers/SettingsImplantForm'
import SettingsImplantSetList from 'containers/SettingsImplantSetList'
import SettingsImplantSetForm, {
    SettingsImplantSetEditForm,
} from 'containers/SettingsImplantSetForm'
import SettingsMedicalEquipmentList from 'containers/SettingsMedicalEquipmentList'
import SettingsImplantManufactureList from 'containers/SettingsImplantManufactureList'
import SettingsExpenseList from 'containers/SettingsExpenseList'
import SettingsExpenseOtherList from 'containers/SettingsExpenseOtherList'
import DoctorPatientList from 'containers/DoctorPatientList'
import DoctorPatientForm from 'containers/DoctorPatientForm'
import DoctorSurgeryForm from 'containers/DoctorSurgeryForm'
import DoctorSurgeryDraftForm from 'containers/DoctorSurgeryDraftForm'
import DoctorSurgeryDraftList from 'containers/DoctorSurgeryDraftList'
import DoctorSurgeryPerformedList from 'containers/DoctorSurgeryPerformedList'
import DoctorSurgeryClosureContainer from 'containers/DoctorSurgeryClosureContainer'
import SurgeryList from 'containers/SurgeryList'
import ClosureTemplate from 'containers/ClosureTemplate'
import ClosureTemplateList from 'containers/ClosureTemplateList'
import CaregiverSurgeryForm from 'containers/CaregiverSurgeryForm'
import DoctorPatientDocuments from 'containers/DoctorPatientDocuments'
import DoctorSurgeryCard from 'containers/DoctorOperationCard'
import CaregiverPanel from 'containers/CaregiverPanel'
import SettingsRoleList from 'containers/SettingsRoleList'
import SettingsRoleForm from 'containers/SettingsRoleForm'
import SettingsSpecializationList from 'containers/SettingsSpecializationList'
import SettingsUser from 'containers/SettingsUser'
import SettingsUserList from 'containers/SettingsUserList'
import SettingsUserForm from 'containers/SettingsUserForm'
import Profile from 'containers/Profile'
import ProfileEdit from 'containers/ProfileEdit'
import ScheduleApproved from 'containers/ScheduleApproved'
import SchedulePlanner from 'containers/SchedulePlanner'
import LiveViewSwitcher from 'containers/LiveViewSwitcher'
import SettingsRFIDCardList from 'containers/SettingsRFIDCardList'
import DoctorPatientSurgeries from 'containers/DoctorPatientSurgeries'
import PatientStats from 'containers/PatientStats'
import ActivityLog from 'containers/ActivityLog'
import DietListContainer from 'containers/DietListContainer'
import AuthRoutes from './AuthRoutes'
import StatisticsDashboard from 'containers/StatisticsDashboard'
import StatisticsSurgeries from 'containers/StatisticsSurgeries'
import StatisticsSurgeriesRejected from 'containers/StatisticsSurgeriesRejected'
import StatisticsSurgery from 'containers/StatisticsSurgery'
import StatisticsDoctors from 'containers/StatisticsDoctors'
import StatisticsAnesthesiologists from 'containers/StatisticsAnesthesiologists'
import SettingsAnesthesiologistCostCreateForm, {
    SettingsAnesthesiologistCostEditForm,
} from 'containers/SettingsAnesthesiologistCostForm'
import SettingsAnesthesiologistCostList from 'containers/SettingsAnesthesiologistCostList'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export default function MainRoutes() {
    return (
        <ScrollToTop>
            <SentryRoutes>
                <Route element={<LoginLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/set-password" element={<SetPassword />} />
                    <Route path="/maintenance" element={<Maintenance />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<MainLayout />}>
                        <Route path="/" element={<Main />} />
                        <Route path="profile">
                            <Route index element={<Profile />} />
                            <Route path="edit" element={<ProfileEdit />} />
                        </Route>
                        <Route path="/settings">
                            <Route
                                index
                                element={
                                    <Navigate to="/settings/procedure-categories" />
                                }
                            />
                            <Route path="users">
                                <Route index element={<SettingsUserList />} />
                                <Route
                                    path="add"
                                    element={<SettingsUserForm />}
                                />
                                <Route path=":id" element={<SettingsUser />} />
                                <Route
                                    path=":id/edit"
                                    element={<SettingsUserForm />}
                                />
                            </Route>
                            <Route path="roles">
                                <Route index element={<SettingsRoleList />} />
                                <Route
                                    path="add"
                                    element={<SettingsRoleForm />}
                                />
                                <Route
                                    path=":id/edit"
                                    element={<SettingsRoleForm />}
                                />
                            </Route>
                            <Route path="specializations">
                                <Route
                                    index
                                    element={<SettingsSpecializationList />}
                                />
                            </Route>
                            <Route
                                path="procedure-categories"
                                element={<SettingsProcedureCategoryList />}
                            />
                            <Route
                                path="procedure-categories/add"
                                element={<SettingsProcedureCategoryForm />}
                            />
                            <Route
                                path="procedure-categories/:id"
                                element={<SettingsProcedureCategoryFormEdit />}
                            />
                            <Route
                                path="procedure-types"
                                element={<SettingsProcedureTypeList />}
                            />
                            <Route
                                path="procedure-types/add"
                                element={<SettingsProcedureType />}
                            />
                            <Route
                                path="procedure-types/:id"
                                element={<SettingsProcedureType />}
                            />
                            <Route
                                path="payers"
                                element={<SettingsPayerList />}
                            />
                            <Route
                                path="procedures"
                                element={<SettingsProcedureList />}
                            />
                            <Route
                                path="procedures/add"
                                element={<SettingsProcedureForm />}
                            />
                            <Route
                                path="procedures/:id"
                                element={<SettingsProcedureFormEdit />}
                            />
                            <Route
                                path="procedure-durations"
                                element={<SettingsProcedureDurationList />}
                            />
                            <Route
                                path="procedure-costs"
                                element={<SettingsProcedureCostList />}
                            />
                            <Route path="price-list">
                                <Route
                                    index
                                    element={
                                        <Navigate to="/settings/price-list/implants" />
                                    }
                                />
                                <Route
                                    path="implants"
                                    element={<SettingsImplantList />}
                                />
                                <Route
                                    path="implants/add"
                                    element={<SettingsImplantForm />}
                                />
                                <Route
                                    path="implants/:id"
                                    element={<SettingsImplantFormEdit />}
                                />
                                <Route
                                    path="implant-sets"
                                    element={<SettingsImplantSetList />}
                                />
                                <Route
                                    path="implant-sets/add"
                                    element={<SettingsImplantSetForm />}
                                />
                                <Route
                                    path="implant-sets/:id"
                                    element={<SettingsImplantSetEditForm />}
                                />
                                <Route
                                    path="medical-equipment"
                                    element={<SettingsMedicalEquipmentList />}
                                />
                                <Route
                                    path="implant-manufacturers"
                                    element={<SettingsImplantManufactureList />}
                                />
                                <Route
                                    path="expenses"
                                    element={<SettingsExpenseList />}
                                />
                                <Route
                                    path="expenses-other"
                                    element={<SettingsExpenseOtherList />}
                                />
                                <Route
                                    path="anesthesiologist-costs"
                                    element={
                                        <SettingsAnesthesiologistCostList />
                                    }
                                />
                                <Route
                                    path="anesthesiologist-costs/add"
                                    element={
                                        <SettingsAnesthesiologistCostCreateForm />
                                    }
                                />
                                <Route
                                    path="anesthesiologist-costs/:id"
                                    element={
                                        <SettingsAnesthesiologistCostEditForm />
                                    }
                                />
                            </Route>
                            <Route
                                path="cards"
                                element={<SettingsRFIDCardList />}
                            />
                        </Route>
                        <Route path="patients">
                            <Route index element={<DoctorPatientList />} />
                            <Route path="add" element={<DoctorPatientForm />} />
                            <Route path=":id">
                                <Route
                                    path="stats"
                                    element={<PatientStats />}
                                />
                                <Route
                                    path="edit"
                                    element={<DoctorPatientForm />}
                                />
                                <Route
                                    path="surgeries"
                                    element={<DoctorPatientSurgeries />}
                                />
                                <Route
                                    path="documents"
                                    element={<DoctorPatientDocuments />}
                                />
                            </Route>
                        </Route>
                        <Route path="surgeries">
                            <Route index element={<SurgeryList />} />
                            <Route
                                path="performed"
                                element={<DoctorSurgeryPerformedList />}
                            />
                            <Route path="add" element={<DoctorSurgeryForm />} />
                            <Route path="drafts">
                                <Route
                                    index
                                    element={<DoctorSurgeryDraftList />}
                                />
                                <Route
                                    path="add"
                                    element={<DoctorSurgeryDraftForm />}
                                />
                            </Route>
                            <Route path=":id">
                                <Route
                                    path="card"
                                    element={<DoctorSurgeryCard />}
                                />
                                <Route path="closures">
                                    <Route
                                        path=":closureId/edit"
                                        element={
                                            <DoctorSurgeryClosureContainer />
                                        }
                                    />
                                    <Route
                                        path="create"
                                        element={
                                            <DoctorSurgeryClosureContainer />
                                        }
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="closures">
                            <Route path="templates">
                                <Route
                                    index
                                    element={<ClosureTemplateList />}
                                />
                                <Route
                                    path="create"
                                    element={<ClosureTemplate />}
                                />
                                <Route
                                    path=":id"
                                    element={<ClosureTemplate />}
                                />
                            </Route>
                        </Route>
                        <Route path="caregiver">
                            <Route index element={<CaregiverPanel />} />
                            <Route path="surgeries">
                                <Route
                                    path=":id/edit"
                                    element={<CaregiverSurgeryForm />}
                                />
                                <Route
                                    path=":id/preview"
                                    element={<CaregiverSurgeryForm isPreview />}
                                />
                            </Route>
                        </Route>
                        <Route path="schedule">
                            <Route
                                index
                                element={<ScheduleApproved view="daily" />}
                            />
                            <Route
                                path="edit"
                                element={<SchedulePlanner view="daily" />}
                            />
                            <Route path="hourly">
                                <Route
                                    index
                                    element={<ScheduleApproved view="hourly" />}
                                />
                                <Route
                                    path="edit"
                                    element={<SchedulePlanner view="hourly" />}
                                />
                            </Route>
                        </Route>
                        <Route path="diets" element={<DietListContainer />} />
                        <Route path="activity-log" element={<ActivityLog />} />
                        <Route
                            path="live-view"
                            element={<LiveViewSwitcher />}
                        />
                        <Route path="statistics">
                            <Route
                                index
                                element={
                                    <Navigate to="/statistics/dashboard" />
                                }
                            />
                            <Route
                                path="dashboard"
                                element={<StatisticsDashboard />}
                            />
                            <Route path="surgeries">
                                <Route
                                    index
                                    element={<StatisticsSurgeries />}
                                />
                                <Route
                                    path=":id"
                                    element={<StatisticsSurgery />}
                                />
                            </Route>
                            <Route
                                path="surgeries-rejected"
                                element={<StatisticsSurgeriesRejected />}
                            />
                            <Route
                                path="doctors"
                                element={<StatisticsDoctors />}
                            />
                            <Route
                                path="anesthesiologists"
                                element={<StatisticsAnesthesiologists />}
                            />
                        </Route>
                    </Route>
                    <Route path="/logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<div>404</div>} />
            </SentryRoutes>
        </ScrollToTop>
    )
}
