import React from 'react'
import classNames from 'classnames'

import ListLayoutTabs, { TTab } from 'components/ListLayoutTabs'

export interface ListLayoutProps {
    className?: string
    renderTitle?: React.ReactElement
    renderCenter?: React.ReactElement
    title?: string
    id?: string
    tabs?: TTab[] | null
    children?: React.ReactChild | React.ReactChild[]
    actions?: React.ReactChild | boolean
}

export const ListLayout: React.FC<ListLayoutProps> = ({
    className,
    title,
    renderTitle,
    renderCenter,
    id,
    tabs,
    children,
    actions,
}) => {
    return (
        <div
            id="list-layout"
            className={classNames(
                'flex flex-col flex-1 py-3 xl:py-6 print:p-0',
                className
            )}
        >
            <div
                id="list-layout-header"
                className="px-2 mb-3 md:mb-4 md:px-4 xl:px-8 print:p-0 print:mb-8"
            >
                <div
                    id="list-layout-header-left"
                    className="flex items-center justify-between"
                >
                    <div className="flex-1">
                        <h2
                            id="list-layout-header-title"
                            className="text-2xl leading-none whitespace-nowrap print:text-3xl"
                        >
                            {!!renderTitle && renderTitle}
                            {!renderTitle && (
                                <span className="font-semibold">{title}</span>
                            )}
                        </h2>
                    </div>
                    {(!!renderCenter || !!tabs) && (
                        <div
                            id="list-layout-header-center"
                            className="flex-1 grow"
                        >
                            {!!renderCenter && renderCenter}
                            {!!tabs && <ListLayoutTabs tabs={tabs} id={id} />}
                        </div>
                    )}
                    {(!!renderCenter || !!tabs || !!actions) && (
                        <div id="list-layout-header-actions" className="flex-1">
                            <div className="flex items-center justify-end md:mt-0 md:ml-4 print:hidden">
                                {actions}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div
                id="list-layout-content"
                className="flex flex-col flex-1 px-2 md:px-4 xl:px-8 print:p-0"
            >
                {children}
            </div>
        </div>
    )
}

export default ListLayout
