import React from 'react'
import classNames from 'classnames'
import { UseQueryResult } from 'react-query'

import { formatDate } from 'helpers'
import { Button, Spinner, TableFooter } from 'components/ui'
import Sort from 'components/Sort'

import type { ListFilterAction, ListFilters } from 'helpers'
import type { OperationListItem } from 'types'
import type { ResponseList } from 'api/types'

const DoctorSurgeryDraftList: React.FC<{
    queryResult: UseQueryResult<ResponseList<OperationListItem[]>>
    filters: ListFilters
    filtersCount: number
    dispatchFilterAction: React.Dispatch<ListFilterAction>
}> = ({ queryResult, filters, filtersCount, dispatchFilterAction }) => {
    const setSorting = (sortBy: string, sortDirection: string) => {
        dispatchFilterAction({
            type: 'setSorting',
            payload: sortBy,
        })
        dispatchFilterAction({
            type: 'setSortingDirection',
            payload: sortDirection,
        })
    }

    return (
        <div className="p-2 bg-white rounded-lg">
            <table className="table-fixed min-w-full">
                <thead className="bg-gray-50 border-b border-b-gray-200 text-xs text-gray-500 uppercase h-10">
                    <tr className="h-10">
                        <th
                            scope="col"
                            className="pl-6 pr-3 text-left font-medium tracking-wider"
                            style={{ width: '5%' }}
                        >
                            <Sort
                                name="id"
                                filters={filters}
                                setSorting={setSorting}
                                active
                            >
                                ID
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-3 text-left font-medium tracking-wider"
                            style={{ width: '10%' }}
                        >
                            <Sort
                                name="created_at"
                                filters={filters}
                                setSorting={setSorting}
                            >
                                Data dodania
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-3 text-left font-medium tracking-wider"
                            style={{ width: '10%' }}
                        >
                            <Sort
                                name="estimated_date"
                                filters={filters}
                                setSorting={setSorting}
                            >
                                Data operacji
                            </Sort>
                        </th>
                        <th
                            scope="col"
                            className="px-3 text-left font-medium tracking-wider"
                        >
                            Lekarz
                        </th>
                        <th
                            scope="col"
                            className="pl-3 pr-6 text-left font-medium tracking-wider"
                            style={{ width: '5%' }}
                        >
                            Opcje
                        </th>
                    </tr>
                </thead>
                {queryResult.isSuccess && (
                    <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                        {queryResult.data.data.map((item, index) => (
                            <tr
                                key={item.id}
                                className={classNames('h-16', {
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                <td className="pl-6 pr-3 py-1">
                                    <span>{item.id}</span>
                                </td>
                                <td className="px-3 py-1">
                                    <span className="whitespace-nowrap">
                                        {formatDate(item.created_at)}
                                    </span>
                                </td>
                                <td className="px-3 py-1">
                                    <span className="whitespace-nowrap">
                                        {formatDate(item.estimated_date)}
                                    </span>
                                </td>
                                <td className="px-3 py-1">
                                    {!!item.operator ? (
                                        <span>
                                            {item.operator.first_name}{' '}
                                            {item.operator.last_name}
                                        </span>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="pl-3 pr-6 py-1">
                                    <div className="space-y-0.5">
                                        <Button
                                            as="link"
                                            to={`/caregiver/surgeries/${item.id}/edit`}
                                            state={{
                                                backUrl: '/surgeries/drafts/',
                                            }}
                                            variant="tertiary"
                                            size="sm"
                                        >
                                            Zobacz
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
            {queryResult.isLoading && (
                <div className="divide-y divide-gray-200 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 5 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {queryResult.isSuccess && queryResult.data.meta.total === 0 && (
                <div className="divide-y divide-gray-200 text-md text-gray-500 leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (item, index) => (
                            <div key={index} className="relative h-16">
                                {index === 5 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        {filtersCount === 0 && (
                                            <span>
                                                Nie ma jeszcze żadnej operacji.
                                            </span>
                                        )}
                                        {filtersCount > 0 && (
                                            <span>
                                                Nie znaleziono wyników
                                                wyszukiwania.
                                            </span>
                                        )}
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={queryResult.data?.meta}
                setFilters={dispatchFilterAction}
            />
        </div>
    )
}

export default DoctorSurgeryDraftList
