import { usePolicy, useRolePolicyGroup, useSpecializationPolicy } from 'hooks'

export const usePermissionMenuItemPolicyGroup = () => {
    const { user } = usePolicy()
    const rolePolicyGroup = useRolePolicyGroup()
    const specializationPolicy = useSpecializationPolicy()

    return {
        user,
        canAny: rolePolicyGroup.canAll || specializationPolicy.canAll,
    }
}
