import moment from 'moment'

import type { Updater } from 'react-query/types/core/utils'
import type { ResponseData, OperationTask } from 'api/types'

export function isTaskExpired(item: OperationTask) {
    return (
        item.reminder && moment().startOf('day').isAfter(moment(item.reminder))
    )
}

export function queryDataTaskUpdater(
    id: OperationTask['id'],
    values: { [Key in keyof Partial<OperationTask>]: OperationTask[Key] }
): Updater<
    ResponseData<OperationTask[]> | undefined,
    ResponseData<OperationTask[]>
> {
    return (queryData) => {
        if (!queryData) {
            return {
                data: [],
            }
        }

        return {
            ...queryData,
            data: queryData.data.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          ...values,
                      }
                    : item
            ),
        }
    }
}
