import { useOperationMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const OperationOtherCreateMenuItemGate: MenuItemGate = ({
    children,
}) => {
    const operationMenuItemPolicyGroup = useOperationMenuItemPolicyGroup()

    if (operationMenuItemPolicyGroup.canCreateOther) {
        return children
    }

    return null
}
