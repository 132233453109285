import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function SlideOver({
    children,
    isOpen,
    classNameDialog,
    classNameSlideOver = 'max-w-2xl',
    closeButton = true,
    onClose,
    onClickOutside,
}: {
    children: React.ReactNode
    isOpen: boolean
    onClose: () => void
    classNameDialog?: string
    classNameSlideOver?: string
    closeButton?: boolean
    onClickOutside?: () => void
}) {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={classNames(
                    'fixed inset-0 z-20 overflow-hidden',
                    classNameDialog
                )}
                onClose={onClickOutside ? onClickOutside : () => {}}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transform transition duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay
                            className="absolute inset-0"
                            style={{
                                background: 'rgba(107, 114, 128, 0.6)',
                            }}
                        />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-300"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div
                            className={classNames(
                                'absolute flex inset-y-0 right-0 w-full',
                                classNameSlideOver
                            )}
                        >
                            {closeButton && (
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="hidden absolute top-0 left-0 md:flex -ml-12 pt-3.5 pr-4">
                                        <button
                                            type="button"
                                            className="bg-white flex rounded-md text-blue-500 hover:text-blue-600 focus:outline-none shadow-lg justify-center items-center w-9 h-9"
                                            onClick={onClose}
                                        >
                                            <span className="sr-only">
                                                Close panel
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                            )}
                            <div className="flex flex-col w-full h-full divide-y divide-gray-200 bg-white shadow-xl">
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
