import React, { useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { PlusIcon } from '@heroicons/react/solid'

import { useGetExpenses, useUpdateExpense } from 'api'
import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useModal, useExpensePolicy } from 'hooks'
import { AccessDenied } from 'components'
import { ActionButton, Loader } from 'components/ui'
import SettingsPriceListLayout from 'layouts/SettingsPriceListLayout'
import SettingsExpenseList from 'components/SettingsExpenseList'
import SettingsExpenseModal from './SettingsExpenseModal'

import type { FormSubmitFn } from 'types'
import type { FormValues } from 'components/SettingsExpenseList'

const SettingsExpenseListContainer: React.FC = () => {
    const expensePolicy = useExpensePolicy()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })
    const queryClient = useQueryClient()
    const modal = useModal()
    const expenses = useGetExpenses(filters, {
        enabled: expensePolicy.canAll,
    })
    const { mutate: update } = useUpdateExpense()

    const handleEdit = (id: number) => {
        modal.setState(id)
        modal.openModal()
    }

    const handleSuccess = async () => {
        modal.closeModal()
        await queryClient.invalidateQueries('expenses')
    }

    const handleUpdatePrice: FormSubmitFn<FormValues> = (
        values,
        formikHelpers
    ) => {
        update(values, {
            onSuccess: async () => {
                await queryClient.invalidateQueries('expenses', {
                    refetchInactive: true,
                })
                formikHelpers.setSubmitting(false)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <SettingsPriceListLayout
            actions={
                expensePolicy.canAll && (
                    <>
                        <span className="ml-2">
                            <ActionButton
                                handleClick={() => modal.openModal()}
                                rightIcon={PlusIcon}
                            >
                                Dodaj
                            </ActionButton>
                        </span>
                    </>
                )
            }
        >
            <>
                {!expensePolicy.canAll && (
                    <AccessDenied message="Nie masz dostępu do zarządzania kosztami" />
                )}
                {expenses.isLoading && <Loader />}
                {expenses.isSuccess && (
                    <SettingsExpenseList
                        data={expenses.data}
                        modal={modal}
                        handleUpdatePrice={handleUpdatePrice}
                        handleEdit={handleEdit}
                        setFilters={setFilters}
                    />
                )}
                <SettingsExpenseModal
                    modal={modal}
                    handleSuccess={handleSuccess}
                />
            </>
        </SettingsPriceListLayout>
    )
}

export default SettingsExpenseListContainer
