import { usePatientMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const PatientMenuItemGate: MenuItemGate = ({ children }) => {
    const patientMenuItemPolicyGroup = usePatientMenuItemPolicyGroup()

    if (patientMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
