import { useUserMenuItemPolicyGroup } from 'hooks'

import type { MenuItemGate } from './index'

export const UserMenuItemGate: MenuItemGate = ({ children }) => {
    const userMenuItemPolicyGroup = useUserMenuItemPolicyGroup()

    if (userMenuItemPolicyGroup.canAny) {
        return children
    }

    return null
}
