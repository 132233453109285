import React, { useMemo } from 'react'
import { Form, useFormikContext } from 'formik'
import moment from 'moment'
import classNames from 'classnames'
import {
    ExclamationIcon,
    LockClosedIcon,
    PlusIcon,
} from '@heroicons/react/solid'

import { useDoctorSurgeryPolicyGroup } from 'hooks'
import {
    TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS,
    timeEvery15minutesPeriodGenerator,
} from 'helpers'
import {
    ActionBar,
    AdmissionTimeDropdown,
    DatePickerField,
    DropdownField,
    DropdownItem,
    EmployeePickerField,
    ErrorMessage,
    ICDCodeField,
    TextAreaField,
    TextField,
} from 'components/forms'
import { Button, Disclosure, Label, Link } from 'components/ui'
import {
    AnesthesiologistFieldArray,
    AnesthesiologistNurseFieldArray,
    AssistantFieldArray,
    OperationNurseFieldArray,
} from 'components/ScheduleOperationForm'

import type { DoctorSurgeryClosureForm } from 'types'
import type { ClosureTemplate, OperationResponse } from 'api/types'
import type {
    SectionState,
    SectionStateAction,
} from 'containers/DoctorSurgeryClosureContainer'

export const validateHour = (phrase: string) => {
    const sanitizedPhrase =
        phrase.length === 4
            ? phrase[0] + phrase[1] + ':' + phrase[2] + phrase[3]
            : phrase

    return moment(sanitizedPhrase, 'HH:mm', true).isValid()
        ? {
              id: sanitizedPhrase,
              name: sanitizedPhrase,
          }
        : false
}

const DoctorSurgeryClosure: React.FC<{
    data: OperationResponse
    closureTemplateList: ClosureTemplate[]
    sectionState: SectionState
    sectionStateDispatch: React.Dispatch<SectionStateAction>
}> = ({ data, closureTemplateList, sectionState, sectionStateDispatch }) => {
    const doctorSurgeryClosurePolicy = useDoctorSurgeryPolicyGroup()

    const { values, errors, handleChange, submitForm, setFieldValue } =
        useFormikContext<DoctorSurgeryClosureForm>()

    const hours: DropdownItem[] = useMemo(
        () =>
            timeEvery15minutesPeriodGenerator().map((minutes) => ({
                id: minutes + ':00',
                name: minutes,
            })),
        []
    )

    const disabled =
        !doctorSurgeryClosurePolicy.canCreateUpdateClosure ||
        data.status === 'descripted'

    return (
        <Form>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                <div className="rounded-md bg-white divide-gray-200 divide-y space-y-8">
                    <div className="pt-6 px-6">
                        {!data.real_operation_end ? (
                            <div className="rounded-md bg-yellow-50 mb-8 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationIcon
                                            className="h-5 w-5 text-yellow-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-800">
                                            Zabieg jeszcze się nie skończył
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : !data.real_operation_start ? (
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationIcon
                                            className="h-5 w-5 text-yellow-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-800">
                                            Zabieg jeszcze się nie rozpoczął
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="grid grid-cols-2 text-gray-700 divide-gray-200 divide-x">
                            <div className="col-span-1 pr-8">
                                <div className="divide-gray-200 divide-y space-y-6">
                                    <section>
                                        <Disclosure title="Pacjent">
                                            <div className="mt-6">
                                                <div className="mt-9 grid grid-cols-3 gap-6">
                                                    <div className="col-span-1">
                                                        <Label>PESEL</Label>
                                                        <TextField
                                                            name="_pesel"
                                                            pattern="[0-9]{0,11}"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Label>
                                                            Nr dowodu /
                                                            paszportu
                                                        </Label>
                                                        <TextField
                                                            name="_identity_card"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Label>Płeć</Label>
                                                        <DropdownField
                                                            name="_gender"
                                                            items={[]}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 grid grid-cols-3 gap-4">
                                                    <div className="col-span-1">
                                                        <Label>Imię</Label>
                                                        <TextField
                                                            name="_first_name"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Label>Nazwisko</Label>
                                                        <TextField
                                                            name="_last_name"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Label>
                                                            Data urodzenia
                                                        </Label>
                                                        <DatePickerField
                                                            name="_date_of_birth"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-4 grid grid-cols-2 gap-4">
                                                    <div className="col-span-1">
                                                        <Label>Telefon</Label>
                                                        <TextField
                                                            name="_phone"
                                                            disabled
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Label>E-mail</Label>
                                                        <TextField
                                                            name="_email"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure>
                                    </section>
                                    <section className="pt-6">
                                        <Disclosure
                                            title="Informacje"
                                            isStatic={true}
                                            defaultOpen={
                                                sectionState.information.open
                                            }
                                            onToggle={() => {
                                                sectionStateDispatch({
                                                    type: 'toggle',
                                                    payload: 'information',
                                                })
                                            }}
                                            actions={
                                                !disabled ? (
                                                    <Link
                                                        as="span"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            sectionStateDispatch(
                                                                {
                                                                    type: 'toggleEdit',
                                                                    payload:
                                                                        'information',
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        {!sectionState
                                                            .information
                                                            .editing &&
                                                            'Edytuj'}
                                                        {sectionState
                                                            .information
                                                            .editing &&
                                                            'Podgląd'}
                                                    </Link>
                                                ) : undefined
                                            }
                                        >
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <Label>Data zabiegu</Label>
                                                    <DatePickerField
                                                        name="_started_at"
                                                        disabled={
                                                            !sectionState
                                                                .information
                                                                .editing
                                                        }
                                                        hasError={
                                                            'started_at' in
                                                            errors
                                                        }
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <Label>Czas zabiegu</Label>
                                                    <AdmissionTimeDropdown
                                                        placeholder="--:--"
                                                        value={
                                                            values._started_on
                                                        }
                                                        items={
                                                            TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS
                                                        }
                                                        disabled={
                                                            !sectionState
                                                                .information
                                                                .editing
                                                        }
                                                        hasError={
                                                            'started_at' in
                                                            errors
                                                        }
                                                        validator={validateHour}
                                                        onChange={(value) =>
                                                            setFieldValue(
                                                                '_started_on',
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <Label>&nbsp;</Label>
                                                    <AdmissionTimeDropdown
                                                        tabIndex={-1}
                                                        placeholder="--:--"
                                                        value={values._ended_on}
                                                        items={
                                                            TIME_EVERY_5_MINUTES_ENTIRE_DAY_ITEMS
                                                        }
                                                        disabled={
                                                            !sectionState
                                                                .information
                                                                .editing
                                                        }
                                                        hasError={
                                                            'ended_at' in errors
                                                        }
                                                        validator={validateHour}
                                                        onChange={(value) =>
                                                            setFieldValue(
                                                                '_ended_on',
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <ErrorMessage name="started_at" />
                                            <ErrorMessage name="ended_at" />
                                            <div className="mt-6">
                                                <Label>Rozpoznanie</Label>
                                                <TextAreaField
                                                    name="diagnosis"
                                                    rows={3}
                                                    disabled={
                                                        !sectionState
                                                            .information.editing
                                                    }
                                                />
                                            </div>
                                        </Disclosure>
                                        <div className="mt-6">
                                            <Label>Nr księgi głównej</Label>
                                            <TextField
                                                name="ledger"
                                                placeholder={`xxx/${new Date().getFullYear()}`}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </section>
                                    <section className="pt-6">
                                        <div className="mb-8 text-lg leading-6 font-medium text-gray-900">
                                            Rozpoznanie
                                        </div>
                                        <div>
                                            <Label>Rozpoznanie ICD-10</Label>
                                            <ICDCodeField
                                                name="icd10"
                                                hasError={'icd10' in errors}
                                                disabled={disabled}
                                            />
                                            <ErrorMessage name="icd10" />
                                        </div>
                                        <div className="mt-6">
                                            <Label>Rozpoznanie kliniczne</Label>
                                            <TextAreaField
                                                name="icd10_description"
                                                rows={3}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="mt-6">
                                            <Label>Rodzaj operacji ICD-9</Label>
                                            <ICDCodeField
                                                name="icd9"
                                                hasError={'icd9' in errors}
                                                disabled={disabled}
                                            />
                                            <ErrorMessage name="icd9" />
                                        </div>
                                        <div className="mt-6">
                                            <Label>
                                                Rodzaj operacji opisowy
                                            </Label>
                                            <TextAreaField
                                                name="icd_description"
                                                rows={3}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className="mt-6">
                                            <Label>Diagnoza po zabiegu</Label>
                                            <TextAreaField
                                                name="diagnosis_after_surgery"
                                                rows={3}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </section>
                                    <section className="pt-6">
                                        <Disclosure
                                            title="Zespół medyczny"
                                            isStatic={true}
                                            defaultOpen={
                                                sectionState.surgeryTeam.open
                                            }
                                            onToggle={() => {
                                                sectionStateDispatch({
                                                    type: 'toggle',
                                                    payload: 'surgeryTeam',
                                                })
                                            }}
                                            actions={
                                                !disabled ? (
                                                    <Link
                                                        as="span"
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            sectionStateDispatch(
                                                                {
                                                                    type: 'toggleEdit',
                                                                    payload:
                                                                        'surgeryTeam',
                                                                }
                                                            )
                                                        }}
                                                    >
                                                        {!sectionState
                                                            .surgeryTeam
                                                            .editing &&
                                                            'Edytuj'}
                                                        {sectionState
                                                            .surgeryTeam
                                                            .editing &&
                                                            'Podgląd'}
                                                    </Link>
                                                ) : undefined
                                            }
                                        >
                                            <div>
                                                <Label>Operator</Label>
                                                {!data.surgical_team.find(
                                                    (item) =>
                                                        item.role === 'doctor'
                                                ) &&
                                                !sectionState.surgeryTeam
                                                    .editing ? (
                                                    <div className="mt-2">
                                                        <OperatorUnassigned />
                                                    </div>
                                                ) : (
                                                    <EmployeePickerField
                                                        name="doctor"
                                                        placeholder="Szukaj / wybierz lekarza"
                                                        disabled={
                                                            !sectionState
                                                                .surgeryTeam
                                                                .editing
                                                        }
                                                        functions={[
                                                            'doctor function',
                                                        ]}
                                                    />
                                                )}
                                            </div>
                                            {(values.assistant.filter(
                                                (item) => !!item
                                            ).length > 0 ||
                                                sectionState.surgeryTeam
                                                    .editing) && (
                                                <div className="mt-6">
                                                    <Label>Asysta 1</Label>
                                                    <AssistantFieldArray
                                                        disabled={
                                                            !sectionState
                                                                .surgeryTeam
                                                                .editing
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {(values.anesthesiologist.filter(
                                                (item) => !!item
                                            ).length > 0 ||
                                                sectionState.surgeryTeam
                                                    .editing) && (
                                                <div className="mt-6">
                                                    <Label>Anestezjolog</Label>
                                                    <AnesthesiologistFieldArray
                                                        disabled={
                                                            !sectionState
                                                                .surgeryTeam
                                                                .editing
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {(values.anesthesiologist_nurse.filter(
                                                (item) => !!item
                                            ).length > 0 ||
                                                sectionState.surgeryTeam
                                                    .editing) && (
                                                <div className="mt-6">
                                                    <Label>
                                                        Pielęgniarka
                                                        anestezjologiczna
                                                    </Label>
                                                    <AnesthesiologistNurseFieldArray
                                                        disabled={
                                                            !sectionState
                                                                .surgeryTeam
                                                                .editing
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {(values.operation_nurse.filter(
                                                (item) => !!item
                                            ).length > 0 ||
                                                sectionState.surgeryTeam
                                                    .editing) && (
                                                <div className="mt-6">
                                                    <Label>
                                                        Pielęgniarka operacyjna
                                                    </Label>
                                                    <OperationNurseFieldArray
                                                        disabled={
                                                            !sectionState
                                                                .surgeryTeam
                                                                .editing
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Disclosure>
                                    </section>
                                </div>
                            </div>
                            <div className="col-span-1 pl-8">
                                <div className="divide-gray-200 divide-y space-y-8">
                                    <section>
                                        <span className="text-lg leading-6 font-medium text-gray-900">
                                            Zabieg
                                        </span>
                                        <div className="flex items-center mt-1.5 mb-6 gap-x-2">
                                            <span className="text-sm text-gray-500">
                                                Szablon opisu:
                                            </span>
                                            <div className="flex-grow">
                                                <DropdownField
                                                    name="_closureTemplate"
                                                    disabled={disabled}
                                                    items={closureTemplateList.map(
                                                        ({ id, name }) => ({
                                                            id,
                                                            name,
                                                        })
                                                    )}
                                                    handleChange={(value) =>
                                                        setFieldValue(
                                                            'description',
                                                            closureTemplateList.find(
                                                                (template) =>
                                                                    template.id ===
                                                                    value.id
                                                            )?.content || ''
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <Label>Opis zabiegu</Label>
                                        <TextAreaField
                                            name="description"
                                            rows={12}
                                            disabled={disabled}
                                        />
                                    </section>
                                    <section className="pt-6">
                                        <div>
                                            <Label>
                                                Czy pobrano materiał do badania
                                                histopatologicznego?
                                            </Label>
                                            <div className="mt-2 space-y-2">
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-1-answer-1"
                                                        name="histopathology_material"
                                                        value={1}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.histopathology_material ===
                                                            1
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-1-answer-1"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Tak
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-1-answer-2"
                                                        name="histopathology_material"
                                                        value={0}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.histopathology_material ===
                                                            0
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-1-answer-2"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Nie
                                                    </label>
                                                </div>
                                                <ErrorMessage name="histopathology_material" />
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <Label>
                                                Czy pobrano materiał do badania
                                                bakteriologicznego?
                                            </Label>
                                            <div className="mt-2 space-y-2">
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-2-answer-1"
                                                        name="bacteriology_material"
                                                        value={1}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.bacteriology_material ===
                                                            1
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-2-answer-1"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Tak
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-2-answer-2"
                                                        name="bacteriology_material"
                                                        value={0}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.bacteriology_material ===
                                                            0
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-2-answer-2"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Nie
                                                    </label>
                                                </div>
                                                <ErrorMessage name="bacteriology_material" />
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <Label>Czy użyto implantów?</Label>
                                            <div className="mt-2 space-y-2">
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-3-answer-1"
                                                        name="implants_used"
                                                        value={1}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.implants_used ===
                                                            1
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-3-answer-1"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Tak
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <InputRadio
                                                        id="question-3-answer-2"
                                                        name="implants_used"
                                                        value={0}
                                                        disabled={disabled}
                                                        defaultChecked={
                                                            values.implants_used ===
                                                            0
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <label
                                                        htmlFor="question-3-answer-2"
                                                        className="ml-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Nie
                                                    </label>
                                                </div>
                                                <ErrorMessage name="implants_used" />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="pt-6">
                                        <Disclosure title="Zalecenia">
                                            <div>
                                                <Label>
                                                    Zalecenia dla lekarza
                                                    dyżurnego i pielęgniarek
                                                </Label>
                                                <TextAreaField
                                                    name="staff_recommendation"
                                                    rows={3}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mt-6">
                                                <Label>
                                                    Zalecenia pooperacyjne
                                                </Label>
                                                <TextAreaField
                                                    name="post_operation_recommendation"
                                                    rows={3}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mt-6">
                                                <Label>
                                                    Zalecenia dla rehabilitantów
                                                </Label>
                                                <TextAreaField
                                                    name="physiotherapist_recommendation"
                                                    rows={3}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mt-6">
                                                <Label>
                                                    Zalecenia do wypisu
                                                </Label>
                                                <TextAreaField
                                                    name="discharge_recommendation"
                                                    rows={3}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            <div className="mt-6">
                                                <Label>
                                                    Orientacyjny termin wizyty
                                                    kontrolnej
                                                </Label>
                                                <div className="grid grid-cols-2 gap-6">
                                                    <div className="col-span-1">
                                                        <DatePickerField
                                                            name="in_hospital_visit"
                                                            disabled={disabled}
                                                            hasError={
                                                                'in_hospital_visit' in
                                                                errors
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <AdmissionTimeDropdown
                                                            name="in_hospital_visit_time"
                                                            disabled={disabled}
                                                            placeholder="--:--"
                                                            items={hours}
                                                            hasError={
                                                                'in_hospital_visit_time' in
                                                                errors
                                                            }
                                                            value={
                                                                values.in_hospital_visit_time
                                                            }
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'in_hospital_visit_time',
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <ErrorMessage name="in_hospital_visit_time" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-6 py-12">
                        <ActionBar
                            rightSide={({ isSubmitting }) => (
                                <>
                                    {!disabled && (
                                        <>
                                            <Button
                                                as="link"
                                                to="/surgeries/performed"
                                            >
                                                Anuluj
                                            </Button>
                                            <Button
                                                type="button"
                                                variant="tertiary"
                                                className="ml-3"
                                                loading={
                                                    values._isDraft
                                                        ? isSubmitting
                                                        : false
                                                }
                                                iconRight={<PlusIcon />}
                                                onClick={() => {
                                                    setFieldValue(
                                                        '_isDraft',
                                                        true,
                                                        false
                                                    )
                                                    submitForm()
                                                }}
                                            >
                                                Zapisz wersję roboczą
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={
                                                    values._isDraft
                                                        ? false
                                                        : isSubmitting
                                                }
                                                iconRight={<LockClosedIcon />}
                                                onClick={() => {
                                                    setFieldValue(
                                                        '_isDraft',
                                                        false,
                                                        false
                                                    )
                                                    submitForm()
                                                }}
                                            >
                                                Zakończ opis
                                            </Button>
                                        </>
                                    )}
                                    {disabled && (
                                        <Button
                                            as="link"
                                            to="/surgeries/performed"
                                        >
                                            Zamknij
                                        </Button>
                                    )}
                                </>
                            )}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}

const InputRadio: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
    type,
    className,
    ...props
}) => {
    return (
        <input
            type="radio"
            className={classNames('h-4 w-4 border-gray-300', {
                'text-blue-600 focus:ring-blue-600': !props.disabled,
                'text-gray-500': props.disabled,
            })}
            {...props}
        />
    )
}

const OperatorUnassigned = () => {
    return (
        <div className="flex items-center">
            <div className="flex-shrink-0">
                <div className="flex w-6 h-6 bg-gray-500 rounded-full text-white text-sm leading-6 items-center justify-center">
                    -
                </div>
            </div>
            <div className="ml-2 min-w-0 flex-1">
                <span className="text-sm leading-5 text-gray-600">
                    <span>Nieprzypisany operator</span>
                </span>
            </div>
        </div>
    )
}

export default DoctorSurgeryClosure
