import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ICD_CODE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ICD_CODES,
    show: false,
    create: false,
    update: false,
    delete: false,
}

export const useIcdCodePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(ICD_CODE_PERMISSIONS),
    }
}
