import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useResendLinkPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        can: hasPermission(PERMISSIONS.RESEND_INVITATION_LINK),
    }
}
