import React, { useMemo } from 'react'
import { Formik } from 'formik'
import classNames from 'classnames'

import {
    OPERATION_STATUS_NEW,
    OPERATION_ALLOWED_STATUSES_TO_CHANGE,
} from 'constants/index'
import { useOperationApprovalCheckbox, useSurgeryPolicy } from 'hooks'
import { AutoSubmitRow } from 'components/forms'
import StatusBackground from 'components/SurgeryListStatusBackground'

import type { OperationListItem } from 'types'
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/solid'

const SurgeryListApprovalCheckboxForm: React.FC<{
    item: OperationListItem
}> = ({ item }) => {
    const surgeryPolicy = useSurgeryPolicy()
    const { handleOperationStatusChange } = useOperationApprovalCheckbox()

    const canChangeStatus = useMemo(
        () =>
            surgeryPolicy.canUpdate &&
            OPERATION_ALLOWED_STATUSES_TO_CHANGE.includes(item.status),
        [item, surgeryPolicy]
    )

    return (
        <Formik
            initialValues={{
                accepted: item.accepted,
            }}
            enableReinitialize
            onSubmit={(values, formikHelpers) =>
                handleOperationStatusChange(item.id, values.accepted)
            }
        >
            {({ values, setFieldValue }) => {
                return (
                    <StatusBackground
                        item={{ ...item, accepted: values.accepted }}
                    >
                        <div className="flex flex-col space-y-2">
                            <input
                                type="checkbox"
                                className={classNames(
                                    'h-4 w-4 text-green-500 border-gray-300 rounded',
                                    {
                                        'opacity-50': !canChangeStatus,
                                        'focus:ring-green-500': values.accepted,
                                        'focus:ring-red-500':
                                            item.status ===
                                                OPERATION_STATUS_NEW &&
                                            (item.progress?.percent || 0) === 0,
                                        'focus:ring-yellow-500':
                                            item.status ===
                                                OPERATION_STATUS_NEW &&
                                            (item.progress?.percent || 0) > 0,
                                    }
                                )}
                                defaultChecked={values.accepted}
                                checked={values.accepted}
                                disabled={!canChangeStatus}
                                onChange={() =>
                                    setFieldValue('accepted', !values.accepted)
                                }
                            />
                            {item.implants_approved ? (
                                <ThumbUpIcon className="inline w-4 text-green-500" />
                            ) : (
                                <ThumbDownIcon className="inline w-4 text-red-500" />
                            )}
                        </div>
                        <AutoSubmitRow />
                    </StatusBackground>
                )
            }}
        </Formik>
    )
}

export default SurgeryListApprovalCheckboxForm
