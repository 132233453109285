import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'

import { PAYER_TYPE_NFZ } from 'constants/index'
import { getProcedureTypes, useGetOperatingRooms } from 'api'
import { Label } from 'components/ui'
import {
    AutoSubmitRow,
    CheckboxDropdownField,
    CheckboxDropdownLazyField,
    DropdownField,
    EmployeePickerField,
    ProcedureField,
} from 'components/forms'

import type { StatisticsSurgeryListFilters } from 'types/StatisticsSurgeryList'

const StatisticsSurgeryFilters: React.FC<{
    filters: StatisticsSurgeryListFilters
    setFilters: React.Dispatch<
        React.SetStateAction<StatisticsSurgeryListFilters>
    >
}> = ({ filters, setFilters }) => {
    const operatingRoomResult = useGetOperatingRooms()

    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={(values) => {
                    setFilters(values)
                }}
                enableReinitialize
            >
                {({ values, setFieldValue, handleChange }) => (
                    <Form>
                        <div className="grid grid-cols-1 xl:grid-cols-5 gap-4">
                            <div className="col-span-1">
                                <Label>Wyszukaj operatora</Label>
                                <EmployeePickerField
                                    name="operator"
                                    placeholder="Wyszukaj operatora"
                                    functions={['doctor function']}
                                    messageEmptyResult="Nie znaleziono operatora."
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Wyszukaj pacjenta</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="patient"
                                        className="block w-full pl-10 appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        placeholder="Wyszukaj pacjenta"
                                        value={values.patient}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="col-span-1">
                                <Label>Operacja</Label>
                                <ProcedureField
                                    name="procedure"
                                    placeholder="Wszystkie"
                                />
                            </div>

                            <div className="col-span-1">
                                <div className="shadow-sm">
                                    <Label>Rodzaj zabiegu</Label>
                                    <CheckboxDropdownLazyField
                                        bulkOption={true}
                                        bulkOptionName="Wszystkie"
                                        placeholder="Wszystkie"
                                        name="procedure_types"
                                        queryFn={getProcedureTypes}
                                    />
                                </div>
                            </div>

                            <div className="col-span-1">
                                <Label>Sala operacyjna</Label>
                                <DropdownField
                                    name="operating_room"
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    items={
                                        operatingRoomResult.isSuccess
                                            ? operatingRoomResult.data.data
                                            : []
                                    }
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Płatnik</Label>
                                <DropdownField
                                    name="payer_type"
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    items={[
                                        { id: PAYER_TYPE_NFZ, name: 'NFZ' },
                                        {
                                            id: 'private',
                                            name: 'Prywatny',
                                        },
                                    ]}
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>Status operacji</Label>
                                <CheckboxDropdownField
                                    name="actual_state"
                                    placeholder="Wszystkie"
                                    items={[
                                        { id: 'new', name: 'Nowa' },
                                        {
                                            id: 'performed',
                                            name: 'Wykonana',
                                        },
                                        {
                                            id: 'rejected',
                                            name: 'Zrzucona',
                                        },
                                    ]}
                                />
                            </div>

                            <div className="col-span-1">
                                <Label>&nbsp;</Label>
                                <label className="flex items-center bg-white w-full border border-gray-300 rounded-md py-2 px-4 h-9 text-sm font-medium cursor-pointer">
                                    <input
                                        className="mr-2 cursor-pointer h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-transparent"
                                        type="checkbox"
                                        checked={!!values.exceeded}
                                        onChange={(event) =>
                                            setFieldValue(
                                                'exceeded',
                                                event.target.checked
                                            )
                                        }
                                    />
                                    Czas zabiegu &gt; 20% śr.
                                </label>
                            </div>

                            <div className="col-span-1">
                                <Label>&nbsp;</Label>
                                <label className="flex items-center bg-white w-full border border-gray-300 rounded-md py-2 px-4 h-9 text-sm font-medium cursor-pointer">
                                    <input
                                        className="mr-2 cursor-pointer h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-transparent"
                                        type="checkbox"
                                        checked={!!values.unclosed}
                                        onChange={(event) =>
                                            setFieldValue(
                                                'unclosed',
                                                event.target.checked
                                            )
                                        }
                                    />
                                    Pokaż niezakończone
                                </label>
                            </div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default StatisticsSurgeryFilters
