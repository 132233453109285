import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const EXPENSE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_EXPENSES,
    show: PERMISSIONS.SHOW_EXPENSES,
    create: PERMISSIONS.CREATE_EXPENSES,
    update: PERMISSIONS.UPDATE_EXPENSES,
    delete: PERMISSIONS.DELETE_EXPENSES,
}

export const useExpensePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(EXPENSE_PERMISSIONS),
    }
}
