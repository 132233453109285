import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    PostSpecialization,
    Specialization,
    ResponseData,
    ResponseList,
    ResponseError,
} from 'api/types'

export const getSpecializations = <T>(filters?: {}): Promise<T> =>
    axios.get(
        '/specializations' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useGetSpecializations = <T = ResponseList<Specialization[]>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['specializations', filters],
        () => getSpecializations<T>(filters),
        options
    )

export const useGetSpecialization = <T = Specialization>(
    id: number,
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
) =>
    useQuery<ResponseData<T>, ResponseError>(
        ['specializations', id],
        () => axios.get(`/specializations/${id}`),
        options
    )

export const useCreateSpecialization = <
    R1 = PostSpecialization,
    R2 = ResponseData<Specialization>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.post('/specializations', data))

export const useUpdateSpecialization = <
    R1 extends Pick<Specialization, 'id'> = Specialization,
    R2 = ResponseData<Specialization>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => axios.patch('/specializations/' + data.id, data))

export const useDeleteSpecialization = <
    R = Specialization
>(): UseMutationResult<R, ResponseError, number> =>
    useMutation((id) => axios.delete(`/specializations/${id}`))
