import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { Spinner, TableFooter } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { UseQueryResult } from 'react-query'
import type { GetProcedureTypesResponse } from 'api/types'
import type { ListFilterAction } from 'helpers'

const SettingsProcedureTypeList: React.FC<{
    procedureType: UseQueryResult<GetProcedureTypesResponse>
    setFilters: React.Dispatch<ListFilterAction>
}> = ({ procedureType, setFilters }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {procedureType.isLoading && (
                <div className="divide-y divide-gray-200 text-sm leading-5">
                    {Array.from(Array(15).keys()).map((item, index) => (
                        <div className="relative px-6 py-6">
                            {index === 5 && (
                                <div className="absolute inset-0 h-full flex justify-center items-center">
                                    <Spinner className="p-0" />
                                </div>
                            )}
                            <span>&nbsp;</span>
                        </div>
                    ))}
                </div>
            )}
            {procedureType.isSuccess && procedureType.data.meta.total > 0 && (
                <table className="min-w-full">
                    <thead className="bg-gray-50 border-b">
                        <tr>
                            <th
                                scope="col"
                                className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Id
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Nazwa
                            </th>
                            <th
                                scope="col"
                                className="w-1/12 px-6 py-3 w-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap text-center"
                            >
                                Liczba operacji
                            </th>
                            <th
                                scope="col"
                                className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap text-center"
                            >
                                Liczba zadań
                            </th>
                            <th
                                scope="col"
                                className="w-1/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap text-center"
                            >
                                Czas sprzątania
                            </th>
                            <th
                                scope="col"
                                className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Opcje
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                        {procedureType.data.data.map((item, index) => (
                            <tr
                                key={index}
                                className={classNames({
                                    'bg-gray-50': index % 2,
                                })}
                            >
                                <td className="px-6 py-6 whitespace-nowrap">
                                    <div className="">{item.id}</div>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap">
                                    <span className="text-gray-900 font-medium">
                                        {item.name}
                                    </span>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap text-center">
                                    <span>{item.procedures_count}</span>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap text-center">
                                    <span>{item.tasks_count}</span>
                                </td>
                                <td className="px-6 py-6 whitespace-nowrap text-center">
                                    <span>
                                        {(item.cleaning_time || '')
                                            .replace('h', ' godz.')
                                            .replace('m', ' min.')}
                                    </span>
                                </td>
                                <td className="px-6 py-1 whitespace-nowrap text-right">
                                    <Link
                                        to={`/settings/procedure-types/${item.id}`}
                                        className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Edytuj
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <TableFooter
                meta={procedureType.data?.meta}
                setFilters={setFilters}
            />
            {procedureType.isSuccess && !procedureType.data.meta.total && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego rodzaju zabiegu."
                    to="/settings/procedure-types/add"
                />
            )}
        </div>
    )
}

export default SettingsProcedureTypeList
