import React from 'react'

import { addDays, addMonths } from 'date-fns'
import { DateRange } from 'react-day-picker'

import { getCxFromStyles } from 'helpers'

import styles from './DayButtons.module.scss'

export interface DayButtonsProps {
    value?: DateRange
    onChange: (range: DateRange) => void
}

const DayButtons: React.FC<DayButtonsProps> = ({ value, onChange }) => {
    const cx = getCxFromStyles(styles)

    const handleRangeButtonClick = (
        pickedRange: '7d' | '14d' | '1m' | '2m'
    ) => {
        const from = value?.from || new Date()
        let to

        switch (pickedRange) {
            case '7d':
                to = addDays(from, 6)
                break
            case '14d':
                to = addDays(from, 13)
                break
            case '1m':
                to = addDays(addMonths(from, 1), -1)
                break
            case '2m':
                to = addDays(addMonths(from, 2), -1)
                break
            default:
                to = undefined
        }

        if (from && to) {
            const range = { from, to }

            onChange(range)
        }
    }

    return (
        <div className={cx('root')}>
            <button
                className={cx('rangeButton')}
                onClick={() => handleRangeButtonClick('7d')}
            >
                7 dni
            </button>
            <button
                className={cx('rangeButton')}
                onClick={() => handleRangeButtonClick('14d')}
            >
                14 dni
            </button>
            <button
                className={cx('rangeButton')}
                onClick={() => handleRangeButtonClick('1m')}
            >
                1 miesiąc
            </button>
            <button
                className={cx('rangeButton')}
                onClick={() => handleRangeButtonClick('2m')}
            >
                2 miesiące
            </button>
        </div>
    )
}

export default DayButtons
