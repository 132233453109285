import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const OPERATION_CLOSURE_PERMISSIONS = {
    index: false,
    show: PERMISSIONS.SHOW_OPERATION_CLOSURE,
    create: PERMISSIONS.CREATE_OPERATION_CLOSURE,
    update: PERMISSIONS.UPDATE_OPERATION_CLOSURE,
    delete: false,
}

export const useOperationClosurePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(OPERATION_CLOSURE_PERMISSIONS),
    }
}
