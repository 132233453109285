import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
    useCreateProcedure,
    useGetProcedure,
    useGetProcedureCategories,
    useGetProcedureTypes,
    useUpdateProcedure,
    useGetPayers,
} from 'api'
import { useProcedurePolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsProcedureForm from 'components/SettingsProcedureForm'

import type { FormikHelpers } from 'formik/dist/types'
import {
    ResponseData,
    Payer,
    ProcedureType,
    ProcedureCategory,
    Procedure,
    CreateProcedure,
    UpdateProcedure,
} from 'api/types'
import type { FormValues } from 'components/SettingsProcedureForm'

interface SettingsProcedureFormProps {
    id?: string
    data?: ResponseData<Procedure>
}

export default function SettingsProcedureFormContainer({
    id,
    data,
}: SettingsProcedureFormProps) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const procedurePolicyGroup = useProcedurePolicyGroup()
    const { mutate: createProcedure } = useCreateProcedure()
    const { mutate: updateProcedure } = useUpdateProcedure()
    const procedureCategories = useGetProcedureCategories<
        ResponseData<ProcedureCategory[]>
    >({ pagination: false }, { enabled: procedurePolicyGroup.canAll })
    const procedureTypes = useGetProcedureTypes<ResponseData<ProcedureType[]>>(
        {
            pagination: false,
        },
        {
            enabled: procedurePolicyGroup.canAll,
        }
    )
    const payers = useGetPayers<ResponseData<Payer[]>>(
        {
            pagination: false,
            active: true,
        },
        { enabled: procedurePolicyGroup.canAll }
    )

    const handleSubmit = (
        values: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => {
        if (id) {
            return updateProcedure(
                {
                    ...values,
                    id: Number(id),
                    procedure_category: values.procedure_category?.id,
                    procedure_type: values.procedure_type?.id,
                } as UpdateProcedure,
                {
                    onSuccess: () => {
                        formikHelpers.setSubmitting(false)
                        queryClient.invalidateQueries('procedures')
                        navigate('/settings/procedures')
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                    },
                }
            )
        }

        createProcedure(
            {
                ...values,
                procedure_category: values.procedure_category?.id,
                procedure_type: values.procedure_type?.id,
            } as CreateProcedure,
            {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    queryClient.invalidateQueries('procedures')
                    navigate('/settings/procedures')
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    if (!procedurePolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz uprawnień do zarządzania procedurami" />
        )
    }

    if (
        procedureCategories.isLoading ||
        procedureTypes.isLoading ||
        payers.isLoading
    ) {
        return <Loader />
    }

    if (
        procedureCategories.isError ||
        procedureTypes.isError ||
        payers.isError
    ) {
        return null
    }

    if (!procedureCategories.data) {
        return null
    }

    if (!procedureTypes.data) {
        return null
    }

    if (!payers.data) {
        return null
    }

    return (
        <SettingsProcedureForm
            id={id}
            data={data}
            categories={procedureCategories.data}
            types={procedureTypes.data}
            payers={payers.data}
            handleSubmit={handleSubmit}
        />
    )
}

const SettingsProcedureFormEditContainer = () => {
    const procedurePolicyGroup = useProcedurePolicyGroup()
    const { id } = useParams()
    const procedure = useGetProcedure(id!, {
        enabled: procedurePolicyGroup.canAll && !!id,
    })

    if (procedure.isLoading) {
        return <Loader />
    }

    if (procedure.isError) {
        return <div>{procedure.error.message}</div>
    }

    return <SettingsProcedureFormContainer id={id} data={procedure.data} />
}

export { SettingsProcedureFormEditContainer as SettingsProcedureFormEdit }
