import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react'
import { useQueryClient } from 'react-query'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import {
    procedureDurationFilterReducer,
    ProcedureDurationListFilterState,
} from 'helpers'
import { timeToMinutes } from 'helpers/time'
import {
    useModal,
    useNotification,
    useProcedureDurationsPolicyGroup,
    useSelectedRows,
} from 'hooks'
import SettingsLayout from 'layouts/SettingsLayout'
import {
    transformProcedureDurationFiltersToApi,
    useGetProcedures,
    useUpdateProcedure,
} from 'api'
import { AccessDenied } from 'components'
import { FilterButton, Loader } from 'components/ui'
import SettingsProcedureDurationList, {
    SelectedItemActions,
} from 'components/SettingsProcedureDurationList'
import Filters from './SettingsProcedureDurationListFilters'
import SettingsProcedureDurationListAssignModal from 'containers/SettingsProcedureDurationListAssignModal'

import type { FormSubmitFn } from 'types'
import type { Procedure, UpdateProcedureDuration } from 'api/types'
import type { ProcedureFormValues } from 'components/SettingsProcedureDurationList'

const INIT_FILTERS: ProcedureDurationListFilterState = {
    page: 1,
    length: DEFAULT_LIST_PER_PAGE,
    sort_by: 'name',
    sort_direction: 'asc',
    filters: {
        name: '',
        procedure_categories: [],
        editable_duration: { id: -1, name: 'Wszystkie' },
    },
}

const SettingsProcedureDurationListContainer = () => {
    const procedureDurationsPolicy = useProcedureDurationsPolicyGroup()
    const [filters, setFilters] = useReducer(
        procedureDurationFilterReducer,
        INIT_FILTERS
    )
    const assignModal = useModal()
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const filtersToApi = useMemo(
        () => transformProcedureDurationFiltersToApi(filters),
        [filters]
    )
    const procedures = useGetProcedures(filtersToApi, {
        enabled: procedureDurationsPolicy.canAll,
    })
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(true)
    const filtersCount = useMemo(
        () =>
            (filtersToApi.name ? 1 : 0) +
            (filtersToApi.procedure_categories.length ? 1 : 0) +
            (filtersToApi.editable_duration !== undefined ? 1 : 0),
        [filtersToApi]
    )
    const { mutate: update } = useUpdateProcedure<UpdateProcedureDuration>()

    const {
        selectedRows,
        unselectAllRows,
        checkIsRowSelected,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection,
        areAllRowsOnCurrentPageSelected,
        unselectAllCurrentPageRows,
    } = useSelectedRows<Procedure>({
        initialSelections: [],
        currentPageRows: procedures.data?.data || [],
        checkIfRowIsEnabled: (procedure) => procedure.editable_duration,
    })

    const selectedProcedureIds = useMemo(
        () => selectedRows.map(({ id }) => id),
        [selectedRows]
    )

    const selectedProcedureCategoryIds = useMemo(
        () =>
            filters.filters.procedure_categories.map((item) => Number(item.id)),
        [filters.filters.procedure_categories]
    )

    useEffect(() => {
        unselectAllRows()
    }, [filters, unselectAllRows])

    const handleAssignToProcedures = useCallback(() => {
        assignModal.setState({
            assignTo: 'procedures',
        })
        assignModal.openModal()
    }, [assignModal])

    const handleAssignToProcedureCategories = useCallback(() => {
        assignModal.setState({
            assignTo: 'procedure_categories',
        })
        assignModal.openModal()
    }, [assignModal])

    const handleSuccess = async () => {
        unselectAllRows()
        assignModal.closeModal()
    }

    const handleUpdate: FormSubmitFn<ProcedureFormValues> = (
        values,
        formikHelpers
    ) => {
        update(transformFormToApiValues(values), {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['procedures'])
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany zostały zapisane',
                    type: 'success',
                })
            },
            onError: (error) => {
                queryClient.invalidateQueries(['procedures'])
                formikHelpers.setSubmitting(false)
                showNotification({
                    content: 'Zmiany nie zostały zapisane',
                    type: 'danger',
                })
            },
        })
    }

    const handleResetFilters = () => {
        setFilters({
            type: 'reset',
            payload: INIT_FILTERS,
        })
    }

    if (!procedureDurationsPolicy.canAll) {
        return (
            <SettingsLayout>
                <AccessDenied message="Nie masz uprawnień do zarządzania kosztorysem" />
            </SettingsLayout>
        )
    }

    return (
        <SettingsLayout
            actions={
                <div className="flex justify-center items-center">
                    <FilterButton
                        count={filtersCount}
                        filtersExpanded={filtersExpanded}
                        onClick={() => setFiltersExpanded(!filtersExpanded)}
                        handleReset={handleResetFilters}
                    />
                    {filters.filters.procedure_categories.length > 0 ||
                    selectedRows.length > 0 ? (
                        <SelectedItemActions
                            assignToProceduresVisible={selectedRows.length > 0}
                            assignToProcedureCategoriesVisible={
                                selectedRows.length === 0 &&
                                filters.filters.procedure_categories.length > 0
                            }
                            assignToProceduresEnabled={selectedRows.length > 0}
                            assignToProcedureCategoriesEnabled={
                                filters.filters.editable_duration?.id !== 0
                            }
                            selectedItems={selectedProcedureIds}
                            unselectAllRows={unselectAllRows}
                            onAssignToProcedures={handleAssignToProcedures}
                            onAssignToProcedureCategories={
                                handleAssignToProcedureCategories
                            }
                        />
                    ) : null}
                </div>
            }
        >
            <>
                {filtersExpanded && (
                    <Filters
                        filters={filters.filters}
                        setFilters={(values) =>
                            setFilters({ type: 'change', payload: values })
                        }
                    />
                )}
                {procedures.isLoading && <Loader />}
                {procedures.isError && <div>{procedures.error.message}</div>}
                {procedures.isSuccess && (
                    <>
                        <SettingsProcedureDurationList
                            filters={filters}
                            filtersCount={filtersCount}
                            procedures={procedures.data}
                            handleUpdate={handleUpdate}
                            setFilters={setFilters}
                            selectedItems={selectedProcedureIds}
                            toggleAllCurrentPageRowsSelection={
                                toggleAllCurrentPageRowsSelection
                            }
                            checkIsRowSelected={checkIsRowSelected}
                            toggleRowSelection={toggleRowSelection}
                            unselectAllCurrentPageRows={
                                unselectAllCurrentPageRows
                            }
                            areAllRowsOnCurrentPageSelected={
                                areAllRowsOnCurrentPageSelected
                            }
                        />
                        <SettingsProcedureDurationListAssignModal
                            modal={assignModal}
                            selectedProcedureIds={selectedProcedureIds}
                            selectedProcedureCategoryIds={
                                selectedProcedureCategoryIds
                            }
                            handleSuccess={handleSuccess}
                        />
                    </>
                )}
            </>
        </SettingsLayout>
    )
}

function transformFormToApiValues(
    values: ProcedureFormValues
): UpdateProcedureDuration {
    return {
        id: values.id,
        duration_in_minutes: values.duration_in_minutes
            ? timeToMinutes(values.duration_in_minutes.id).toString()
            : '',
        anesthesia_duration_in_minutes: values.anesthesia_duration_in_minutes
            ? timeToMinutes(values.anesthesia_duration_in_minutes.id).toString()
            : '',
        editable_duration: values.editable_duration,
    }
}

export default SettingsProcedureDurationListContainer
