import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import type {
    SMS,
    SMSListFilters,
    ResponseList,
    ResponseError,
} from 'api/types'

export const getSMS = <T>(filters: SMSListFilters): Promise<T> => {
    return axios.get(
        '/smses' +
            (filters
                ? '?' +
                  qs.stringify(
                      {
                          ...filters,
                          pagination: false,
                      },
                      {}
                  )
                : '')
    )
}

export const useGetSMS = <T = ResponseList<SMS[]>>(
    filters: SMSListFilters,
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['smses', filters],
        () => getSMS<T>(filters),
        options
    )
