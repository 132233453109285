import React from 'react'
import { Form, Formik } from 'formik'

import { getProcedureTypes, useGetProcedureCategories } from 'api'
import { Label } from 'components/ui'
import {
    AutoSubmitRow,
    CheckboxDropdownLazyField,
    DropdownField,
    EmployeePickerField,
    ProcedureField,
} from 'components/forms'
import { StatisticsDoctorListFilters } from 'types/StatisticsDoctorList'

const StatisticsDoctorFilters: React.FC<{
    filters: StatisticsDoctorListFilters
    setFilters: React.Dispatch<
        React.SetStateAction<StatisticsDoctorListFilters>
    >
}> = ({ filters, setFilters }) => {
    const procedureCategoriesResult = useGetProcedureCategories()

    return (
        <div className="mb-4 px-3 py-4 bg-white rounded-lg">
            <Formik
                initialValues={filters}
                onSubmit={setFilters}
                enableReinitialize
            >
                <Form>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div className="col-span-1">
                            <Label>Wyszukaj operatora</Label>
                            <EmployeePickerField
                                name="operators"
                                placeholder="Wyszukaj operatora"
                                functions={['doctor function']}
                                messageEmptyResult="Nie znaleziono operatora."
                                multiChoice
                            />
                        </div>
                        <div className="col-span-1">
                            <Label>Zabieg</Label>
                            <ProcedureField
                                name="procedure"
                                placeholder="Wszystkie"
                            />
                        </div>
                        <div className="col-span-1">
                            <Label>Kategoria zabiegu</Label>
                            <DropdownField
                                bulkOption
                                bulkOptionName="Wszystkie"
                                placeholder="Wszystkie"
                                name="procedure_category"
                                items={
                                    procedureCategoriesResult.isSuccess
                                        ? procedureCategoriesResult.data.data
                                        : []
                                }
                            />
                        </div>
                        <div className="col-span-1">
                            <div className="shadow-sm">
                                <Label>Rodzaj zabiegu</Label>
                                <CheckboxDropdownLazyField
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    name="procedure_types"
                                    queryFn={getProcedureTypes}
                                />
                            </div>
                        </div>
                    </div>
                    <AutoSubmitRow />
                </Form>
            </Formik>
        </div>
    )
}

export default StatisticsDoctorFilters
