import React from 'react'
import classNames from 'classnames'

import { Button, Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { IModal } from 'types'
import type { Specialization, ResponseList } from 'api/types'
import type { ListFilterAction } from 'helpers'

const SettingsSpecializationList: React.FC<{
    data: ResponseList<Specialization[]>
    modal: IModal
    setFilters: React.Dispatch<ListFilterAction>
    handleEdit: (id: number) => void
}> = ({ data, modal, setFilters, handleEdit }) => {
    return (
        <div className="bg-white p-2 rounded-lg">
            {data.meta.total > 0 && (
                <Table data={data} handleEdit={handleEdit} />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {data.meta.total === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszej specjalizacji."
                    onClick={modal.openModal}
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<Specialization[]>
    handleEdit: (id: number) => void
}> = ({ data, handleEdit }) => (
    <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
            <tr>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                >
                    Id
                </th>
                <th
                    scope="col"
                    className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                >
                    Nazwa
                </th>
                <th
                    scope="col"
                    className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                >
                    Opcje
                </th>
            </tr>
        </thead>
        <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
            {data.data.map((item, index) => (
                <tr
                    key={item.id}
                    className={classNames({
                        'bg-gray-50': index % 2,
                    })}
                >
                    <td className="px-6 py-6">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-6">
                        <span className="text-gray-900 font-medium">
                            {item.name}
                        </span>
                    </td>
                    <td className="px-6 py-1 text-right">
                        <Button
                            variant="tertiary"
                            size="sm"
                            onClick={() => handleEdit(item.id)}
                        >
                            Zobacz
                        </Button>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default SettingsSpecializationList
