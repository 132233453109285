import React from 'react'
import { Link } from 'react-router-dom'

import styles from './CancelButton.module.scss'

import type { LinkProps } from 'react-router-dom'

type BaseProps = {
    children: React.ReactNode
    className?: string
}

type ButtonAsButton = BaseProps &
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
        as?: 'button'
    }

type ButtonAsLink = BaseProps &
    Omit<LinkProps, keyof BaseProps> & {
        as: 'link'
    }

type ButtonProps = ButtonAsButton | ButtonAsLink

const CancelButton: React.FC<
    {
        text?: string
    } & ButtonProps
> = (props) => {
    if (props.as === 'link') {
        const { as, ...rest } = props
        return <Link className={styles.root} {...rest} />
    }

    const { as, ...rest } = props
    return <button className={styles.root} {...rest} />
}

export default CancelButton
