import React, { useMemo } from 'react'
import { Dialog } from '@headlessui/react'
import classNames from 'classnames'
import moment from 'moment'

import { Modal } from 'components'
import {
    useGetOperationCaregiverCommentHistory,
    useGetOperationDescriptionHistory,
} from 'api'
import styles from './Modal.module.scss'
import UserAvatar from './UserAvatar'
import { ChatAltIcon } from '@heroicons/react/solid'
import { Spinner } from './ui'
import { IModal } from '../types'

const HistoryModal: React.FC<{
    modal: IModal
    operationId?: number
    listTypes: string[]
    onClose: () => void
}> = ({ operationId, listTypes, onClose, modal }) => {
    const isCaregiverCommentList = useMemo(
        () => listTypes.includes('caregiver-comments'),
        [listTypes]
    )

    const isDescriptionList = useMemo(
        () => listTypes.includes('descriptions'),
        [listTypes]
    )

    const {
        data: descriptionHistoryData,
        isLoading: isDescriptionHistoryLoading,
    } = useGetOperationDescriptionHistory(operationId || 0, {
        enabled: isDescriptionList && !!operationId,
    })

    const {
        data: caregiverCommentHistoryData,
        isLoading: isCaregiverCommentHistoryLoading,
    } = useGetOperationCaregiverCommentHistory(operationId || 0, {
        enabled: isCaregiverCommentList && !!operationId,
    })

    return (
        <>
            <Modal modal={modal} onClickOutside={onClose}>
                <div
                    className={classNames(
                        isDescriptionList &&
                            isCaregiverCommentList &&
                            'max-w-4xl',
                        styles.inner,
                        'p-6 my-2'
                    )}
                >
                    <Dialog.Title as="h3" className={styles.title}>
                        Historia uwag
                    </Dialog.Title>

                    <div className="flex gap-4 mt-6 flex-col md:flex-row">
                        {isDescriptionList && (
                            <div className="w-full">
                                <span className="text-base leading-none font-normal text-gray-500 w-full text-center inline-block">
                                    Uwagi do zabiegu
                                </span>

                                <ul className="flex flex-col gap-2 md:max-h-96 md:overflow-auto mt-3">
                                    {isDescriptionHistoryLoading && (
                                        <li>
                                            <Spinner />
                                        </li>
                                    )}
                                    {!descriptionHistoryData?.data.length && (
                                        <li className="italic text-center mt-4 text-gray-500">
                                            Brak danych
                                        </li>
                                    )}
                                    {descriptionHistoryData?.data.map(
                                        (item) => (
                                            <li
                                                className="flex justify-between gap-2"
                                                key={item.id}
                                            >
                                                <div className="flex flex-col gap-2 items-center">
                                                    <div className="relative">
                                                        <UserAvatar
                                                            data={{
                                                                first_name:
                                                                    item.user
                                                                        .first_name,
                                                                last_name:
                                                                    item.user
                                                                        .last_name,
                                                                avatar_url:
                                                                    item.user
                                                                        .avatar_url ||
                                                                    '',
                                                            }}
                                                            variant="avatar"
                                                        />
                                                        <ChatAltIcon className="absolute bottom-0 right-0 w-1/2 p-0 bg-white rounded-tl text-gray-400 pl-0.5" />
                                                    </div>
                                                    <div className="bg-gray-200 flex-1 w-0.5" />
                                                </div>

                                                <div className="flex-1 flex flex-col">
                                                    <span className="text-sm leading-5 font-medium text-gray-900">
                                                        {item.user.full_name}
                                                    </span>
                                                    <span className="text-xs leading-none font-normal text-gray-500">
                                                        {moment(
                                                            item.created_at
                                                        ).format('LLL')}
                                                    </span>
                                                    <span className="text-sm leading-5 font-normal text-gray-700 mt-2 mb-6">
                                                        {item.properties
                                                            .attributes
                                                            .description || '-'}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}

                        {isDescriptionList && isCaregiverCommentList && (
                            <div className="w-px bg-gray-200" />
                        )}

                        {isCaregiverCommentList && (
                            <div className="w-full">
                                <span className="text-base leading-none font-normal text-gray-500 w-full text-center inline-block">
                                    Uwagi opiekunek
                                </span>

                                <ul className="flex flex-col gap-2 md:max-h-96 md:overflow-auto mt-3">
                                    {isCaregiverCommentHistoryLoading && (
                                        <li>
                                            <Spinner />
                                        </li>
                                    )}
                                    {!caregiverCommentHistoryData?.data
                                        .length && (
                                        <li className="italic text-center mt-4 text-gray-500">
                                            Brak danych
                                        </li>
                                    )}
                                    {caregiverCommentHistoryData?.data.map(
                                        (item) => (
                                            <li
                                                className="flex justify-between gap-2"
                                                key={item.id}
                                            >
                                                <div className="flex flex-col gap-2 items-center">
                                                    <div className="relative">
                                                        <UserAvatar
                                                            data={{
                                                                first_name:
                                                                    item.user
                                                                        .first_name,
                                                                last_name:
                                                                    item.user
                                                                        .last_name,
                                                                avatar_url:
                                                                    item.user
                                                                        .avatar_url ||
                                                                    '',
                                                            }}
                                                            variant="avatar"
                                                        />
                                                        <ChatAltIcon className="absolute bottom-0 right-0 w-1/2 p-0 bg-white rounded-tl text-gray-400 pl-0.5" />
                                                    </div>
                                                    <div className="bg-gray-200 flex-1 w-0.5" />
                                                </div>

                                                <div className="flex-1 flex flex-col">
                                                    <span className="text-sm leading-5 font-medium text-gray-900">
                                                        {item.user.full_name}
                                                    </span>
                                                    <span className="text-xs leading-none font-normal text-gray-500">
                                                        {moment(
                                                            item.created_at
                                                        ).format('LLL')}
                                                    </span>
                                                    <span className="text-sm leading-5 font-normal text-gray-700 mt-2 mb-6">
                                                        {item.properties
                                                            .attributes
                                                            .caregivers_comments ||
                                                            '-'}
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>

                    <button
                        onClick={() => onClose()}
                        className="shadow-sm w-full rounded-md border border-gray-300 px-4 py-2 mt-6"
                    >
                        Zamknij
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default HistoryModal
