import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import { Dialog } from '@headlessui/react'

import { useEventListener } from 'hooks'
import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import { Button } from 'components/ui'

import styles from './Modal.module.scss'

import { ReactComponent as IconCostEstimate } from 'assets/icon-cost-estimate.svg'

import type { FormSubmitFn, IModal } from 'types'

export type OperationDownloadCostEstimateModalState = {
    id: number
}

const OperationDownloadCostEstimateModal: React.FC<{
    modal: IModal<OperationDownloadCostEstimateModalState | undefined>
    onSubmit: FormSubmitFn<OperationDownloadCostEstimateModalState>
    onCancel: () => void
    onClickOutside?: () => void
}> = ({ modal, onSubmit, onCancel, onClickOutside }) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const cx = getCxFromStyles(styles)

    const handleMouseDown = (e: Event) => {
        if (modal.isOpen) {
            e.stopImmediatePropagation()
        }
    }

    useEventListener('mousedown', handleMouseDown, [modal.isOpen])

    return (
        <Modal
            ref={modalRef}
            modal={modal}
            onClickOutside={onClickOutside ? onClickOutside : () => {}}
        >
            <div className={cx('inner', 'p-6')}>
                <div className="mb-6">
                    <IconCostEstimate className="m-auto w-10 h-10 text-blue-500" />
                </div>
                <Dialog.Title as="h3" className={cx('title')}>
                    Czy chcesz pobrać kosztorys operacji?
                </Dialog.Title>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{ id: modal.getState()?.id || 0 }}
                    validateOnChange={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mt-6 text-center">
                                <span className="text-sm leading-5 font-normal text-gray-500">
                                    Dostępny jest kosztorys operacji w formacie
                                    PDF dla zapisanej operacji. Pobierz go,
                                    wydrukuj i przekaż pacjentowi do podpisu.
                                </span>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    type="button"
                                    variant="default"
                                    onClick={onCancel}
                                    tabIndex={-1}
                                >
                                    Pomiń
                                </Button>
                                <Button
                                    variant="primary"
                                    className="col-start-2"
                                    type="submit"
                                    loading={isSubmitting}
                                    tabIndex={-1}
                                >
                                    Pobierz
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default OperationDownloadCostEstimateModal
