import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import { Dialog, RadioGroup } from '@headlessui/react'

import {
    OPERATION_STATUS_REJECTED,
    OPERATION_REJECT_REASONS,
} from 'constants/index'
import { useEventListener } from 'hooks'
import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import { Button, Label, RadioGroupLabel, RadioGroupOption } from 'components/ui'
import { TextAreaField } from 'components/forms'

import styles from './Modal.module.scss'

import type { FormSubmitFn, IModal } from 'types'
import type { OperationRejectForm } from 'types/OperationRejectForm'
export type OperationRejectModalState = {
    id: number
}

const OperationRejectModal: React.FC<{
    modal: IModal<OperationRejectModalState | undefined>
    onSubmit: FormSubmitFn<OperationRejectForm>
    onCancel: () => void
    onClickOutside?: () => void
}> = ({ modal, onSubmit, onCancel, onClickOutside }) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const cx = getCxFromStyles(styles)

    const handleMouseDown = (e: Event) => {
        if (modal.isOpen) {
            e.stopImmediatePropagation()
        }
    }

    useEventListener('mousedown', handleMouseDown, [modal.isOpen])

    return (
        <Modal
            ref={modalRef}
            modal={modal}
            onClickOutside={onClickOutside ? onClickOutside : () => {}}
        >
            <div className={cx('inner', 'p-6')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    Zrzuć operację
                </Dialog.Title>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={
                        {
                            status: OPERATION_STATUS_REJECTED,
                            reject_reason: '',
                            change_manually: true,
                        } as OperationRejectForm
                    }
                    validateOnChange={false}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className="mt-5">
                                <RadioGroup
                                    value={values.reject_type}
                                    onChange={(value) =>
                                        setFieldValue('reject_type', value)
                                    }
                                >
                                    <Label>
                                        Wybierz przyczynę odwołania operacji
                                    </Label>
                                    <div className="bg-white grid gap-4 grid-cols-2 rounded-md mt-5">
                                        {OPERATION_REJECT_REASONS.map(
                                            (item) => (
                                                <RadioGroupOption
                                                    key={item.type}
                                                    value={item.type}
                                                >
                                                    {(optionRenderProps) => (
                                                        <RadioGroupLabel
                                                            {...optionRenderProps}
                                                        >
                                                            {item.label}
                                                        </RadioGroupLabel>
                                                    )}
                                                </RadioGroupOption>
                                            )
                                        )}
                                    </div>
                                </RadioGroup>
                            </div>

                            {values.reject_type === 'other' && (
                                <div className="mt-5">
                                    <Label>Komentarz</Label>
                                    <TextAreaField
                                        name="reject_reason"
                                        cols={5}
                                    />
                                </div>
                            )}

                            <div className={cx('action-container')}>
                                <Button
                                    variant="tertiary"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    variant="default"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!values.reject_type}
                                >
                                    Zatwierdź
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default OperationRejectModal
