import React, { useContext, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import moment from 'moment'

import { API_FORMAT_DATE, API_FORMAT_DATETIME } from 'constants/index'
import {
    useGetApprovedDays,
    useGetOperatingEvents,
    useGetOperatingRooms,
} from 'api'
import { usePolicy } from 'hooks'
import PusherContext from 'contexts/PusherContext'
import { LiveView } from 'components'
import LiveViewOperationMonitor from 'containers/LiveViewOperationMonitor'

import type { OperationEventResponse } from 'api/types'
import { parseStringApiDateToDate } from '../helpers'

export type OperationEventEstimatedTime = OperationEventResponse & {
    operation_estimated_start: string
    operation_estimated_finish: string
}

export type OperationsByRoom = {
    byId: Record<number, OperationEventEstimatedTime[]>
    allIds: number[]
}

const REFETCH_INTERVAL = 1000 * 60 * 5

type TVContainerProps = {
    date: Date
    view: 'detail' | 'business'
    canChangeView: boolean
    onViewToggle: () => void
    onDateChange: (date: Date) => void
}

const TVContainer = (props: TVContainerProps) => {
    const { date, view, canChangeView, onDateChange, onViewToggle } = props

    const queryClient = useQueryClient()
    const {
        user: { isTv, isTablet },
    } = usePolicy()
    const { subscribeToChannel } = useContext(PusherContext)
    const approvedDaysQueryResult = useGetApprovedDays({ paginated: false })

    const lastApprovedDay = useMemo(
        () =>
            approvedDaysQueryResult.data?.data[0].date
                ? parseStringApiDateToDate(
                      approvedDaysQueryResult.data?.data[0].date
                  )
                : new Date(),
        [approvedDaysQueryResult.data]
    )

    const operationEventsQueryResult = useGetOperatingEvents(
        {
            date: moment(date).format(API_FORMAT_DATE),
            live_view: true,
        },
        { refetchInterval: REFETCH_INTERVAL, refetchOnReconnect: 'always' }
    )
    const operatingRoomsQueryResult = useGetOperatingRooms()

    useEffect(() => {
        if (
            moment(date).isSame(moment(new Date()), 'day') &&
            subscribeToChannel
        ) {
            const channel = subscribeToChannel({
                channelName: 'private-live-view',
                callback: () => {
                    queryClient.invalidateQueries('operation-events')
                },
                event: 'status-changed',
            })

            return () => {
                if (channel) {
                    channel.unsubscribe()
                }
            }
        }
    }, [date, subscribeToChannel, queryClient])

    const operationsByRoom =
        operationEventsQueryResult.data?.data &&
        operatingRoomsQueryResult.data?.data
            ? operationEventsQueryResult.data.data.reduce(
                  (acc, item) => {
                      acc.byId[item.operating_room.id].push({
                          ...item,
                          operation_estimated_start: item.operation_start,
                          operation_estimated_finish: moment(
                              item.operation_start
                          )
                              .add(item.duration, 'minutes')
                              .format(API_FORMAT_DATETIME),
                      })

                      return acc
                  },
                  operatingRoomsQueryResult.data.data.reduce(
                      (acc, item) => {
                          acc.byId[item.id] = []
                          acc.allIds.push(item.id)
                          return acc
                      },
                      {
                          byId: {},
                          allIds: [],
                      } as OperationsByRoom
                  )
              )
            : { byId: {}, allIds: [] }

    return (
        <LiveViewOperationMonitor operationsByRoom={operationsByRoom}>
            {({ operationsByRoom }) => (
                <LiveView
                    errorEvents={operationEventsQueryResult.error?.message}
                    errorRooms={operatingRoomsQueryResult.error?.message}
                    isLoadingRooms={operatingRoomsQueryResult.isLoading}
                    isLoadingEvents={operationEventsQueryResult.isLoading}
                    canMoveToNextDay={moment(date).isBefore(
                        lastApprovedDay,
                        'day'
                    )}
                    canChangeView={canChangeView}
                    forTv={isTv}
                    forTablet={isTablet}
                    rooms={operatingRoomsQueryResult.data?.data || []}
                    data={operationsByRoom}
                    meta={operationEventsQueryResult.data?.meta}
                    date={date}
                    view={view}
                    onViewToggle={onViewToggle}
                    onChangeDate={onDateChange}
                />
            )}
        </LiveViewOperationMonitor>
    )
}

export default TVContainer
