import { useQueryClient } from 'react-query'

import { useUpdateOperation } from 'api'
import { useNotification } from 'hooks'
import { MESSAGES } from 'constants/index'

import type { Operation } from 'api/types'

export type HandleOperationStatusChange = (
    id: number,
    accepted: Operation['accepted']
) => void

export const useOperationApprovalCheckbox = () => {
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const { mutate: update } = useUpdateOperation()

    const handleOperationStatusChange: HandleOperationStatusChange = (
        id,
        accepted
    ) => {
        update(
            { id, data: { accepted } },
            {
                onSuccess: async () => {
                    showNotification({
                        content: MESSAGES.SURGERY_STATUS_UPDATED_SUCCESS,
                        type: 'success',
                    })
                    await queryClient.invalidateQueries(['operations'])
                    await queryClient.invalidateQueries(['schedules'])
                },
                onError: (error) => {},
            }
        )
    }

    return {
        handleOperationStatusChange,
    }
}
