import React, { useState } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { StatisticsOperationResponse } from 'api/types'
import { roundedPrice } from 'helpers'

const StatisticsSurgeryExtraCosts: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const [extraCostsExpanded, setExtraCostsExpanded] = useState(true)

    return (
        <div>
            <div className="text-lg leading-6 font-medium flex items-center justify-between">
                Koszty dodatkowe
                <button
                    className="px-3"
                    onClick={() => setExtraCostsExpanded(!extraCostsExpanded)}
                >
                    <ChevronDownIcon
                        className={classNames(
                            'w-4 text-blue-500',
                            !extraCostsExpanded && 'transform rotate-180'
                        )}
                    />
                </button>
            </div>

            {extraCostsExpanded && (
                <div className="flex flex-col gap-0.5 mt-6">
                    {!data.costs || data.costs.length === 0
                        ? '-'
                        : data.costs?.map((cost) => (
                              <div
                                  key={cost.name}
                                  className="p-2 bg-gray-100 flex items-center justify-between text-gray-900 text-sm leading-5 font-normal"
                              >
                                  <span>{cost.name}</span>
                                  <span className="leading-none py-1.5 px-3 inline-block rounded-md bg-white border border-gray-300 shadow-sm">
                                      {roundedPrice(Number(cost.price))}
                                  </span>
                              </div>
                          ))}
                </div>
            )}
        </div>
    )
}

export default StatisticsSurgeryExtraCosts
