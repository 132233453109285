import React, { useEffect, useMemo } from 'react'
import moment from 'moment'

import {
    useNotificationQueue,
    TYPE_OPERATION_CANCELED,
} from 'contexts/NotificationProvider'
import { useNotificationChannel, useNotificationChannelPolicy } from 'hooks'
import Portal from 'components/Portal'
import NotificationToast from 'components/NotificationToast'

const NotificationToastContainer = () => {
    const queue = useNotificationQueue()
    const { connectToNotificationChannel } = useNotificationChannel()
    const policy = useNotificationChannelPolicy()

    useEffect(() => {
        if (!policy.canView) {
            return
        }

        const { onNotificationEvent, handleNotificationCreated, closeChannel } =
            connectToNotificationChannel()

        onNotificationEvent(handleNotificationCreated)

        return () => closeChannel()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            queue.entries
                .filter((item) =>
                    moment(item.data.receivedAt).isBefore(
                        moment().subtract(30, 'seconds')
                    )
                )
                .forEach((item) => {
                    handleRemove(item.id)
                })
        }, 1000)

        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [queue.entries])

    const items = useMemo(() => {
        if (queue.entries.length > 3) {
            queue.entries.slice(0, -3).forEach((item) => queue.remove(item.id))
        }

        return [...queue.entries].reverse()
        // eslint-disable-next-line
    }, [queue.entries])

    const handleRemove = (id: string) => {
        queue.remove(id)
    }

    return (
        <Portal id="notification-toast-container">
            <div className="fixed p-6 pt-24 z-50 inset-y-0 right-0 flex flex-col w-screen max-w-md pointer-events-none">
                <div className="space-y-2">
                    {items.map((item, index) => (
                        <NotificationToast
                            key={index}
                            id={item.id}
                            item={item.data}
                            variant={
                                item.data.type === TYPE_OPERATION_CANCELED
                                    ? 'danger'
                                    : 'regular'
                            }
                            onRemove={handleRemove}
                        />
                    ))}
                </div>
            </div>
        </Portal>
    )
}

export default NotificationToastContainer
