import { useActivityLogPolicy } from 'hooks'

import type { MenuItemGate } from './index'

export const ActivityLogMenuItemGate: MenuItemGate = ({ children }) => {
    const { canIndex } = useActivityLogPolicy()

    if (canIndex) {
        return children
    }

    return null
}
