import {
    usePolicy,
    useApprovedDayPolicy,
    useOperatingRoomPolicy,
    useBlockedDayPolicy,
    useSchedulePolicy,
    useAnesthesiologistSchedulePolicy,
    useProcedureTypePolicy,
} from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const useSchedulePlannerPolicyGroup = () => {
    const { user, hasPermission } = usePolicy()
    const operatingRoomPolicy = useOperatingRoomPolicy()
    const anesthesiologistSchedulePolicy = useAnesthesiologistSchedulePolicy()
    const blockedDayPolicy = useBlockedDayPolicy()
    const approvedDayPolicy = useApprovedDayPolicy()
    const schedulePolicy = useSchedulePolicy()
    const procedureTypePolicy = useProcedureTypePolicy()

    return {
        user,
        canIndex:
            operatingRoomPolicy.canIndex &&
            anesthesiologistSchedulePolicy.canIndex &&
            approvedDayPolicy.canIndex &&
            schedulePolicy.canIndex &&
            schedulePolicy.canUpdate &&
            procedureTypePolicy.canIndex,
        get canUpdate() {
            return this.canIndex && schedulePolicy.canUpdate
        },
        get canCancel() {
            return (
                schedulePolicy.canUpdate &&
                hasPermission(PERMISSIONS.CANCEL_OPERATIONS)
            )
        },
        get canReject() {
            return (
                schedulePolicy.canUpdate &&
                hasPermission(PERMISSIONS.REJECT_OPERATIONS)
            )
        },
        get canRenew() {
            return hasPermission(PERMISSIONS.RENEW_OPERATIONS)
        },
        get canSkipRejectModal() {
            return schedulePolicy.user.isPlanner
        },
        canChangeEvents: hasPermission(PERMISSIONS.CHANGE_EVENTS_MANUALLY),
        canRestoreStatus: hasPermission(PERMISSIONS.RESTORE_STATUS),
        canFinishOperation: hasPermission(PERMISSIONS.CREATE_FINISHED_EVENT),
        operatingRoom: operatingRoomPolicy,
        anesthesiologistSchedule: anesthesiologistSchedulePolicy,
        blockedDay: blockedDayPolicy,
        approvedDay: approvedDayPolicy,
        schedule: schedulePolicy,
        procedureType: procedureTypePolicy,
    }
}
