import React, { useContext } from 'react'
import { Form, Formik } from 'formik'

import { getProcedureTypes } from 'api'
import mediaContext from 'contexts/media/mediaContext'
import {
    AutoSubmitRow,
    CheckboxDropdownLazyField,
    DropdownField,
} from 'components/forms'
import { Label } from 'components/ui'

import type { FormSubmitFn } from 'types'
import type { OperatingRoom } from 'api/types'
import type { ScheduleFiltersForm } from 'types/ScheduleFiltersForm'

const ScheduleFilters: React.FC<{
    filters: ScheduleFiltersForm
    rooms: OperatingRoom[]
    handleChange: FormSubmitFn<ScheduleFiltersForm>
}> = ({ filters, rooms, handleChange }) => {
    const { isMobile } = useContext(mediaContext)

    return (
        <div
            id="schedule-filters"
            className="mb-4 px-3 py-4 bg-white rounded-lg print:hidden"
        >
            <Formik
                initialValues={filters}
                onSubmit={handleChange}
                enableReinitialize
            >
                {({ values, setValues }) => (
                    <Form>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            <div className="col-span-1">
                                <Label>Rodzaj operacji</Label>
                                <CheckboxDropdownLazyField
                                    name="procedure_types"
                                    placeholder="Rodzaj operacji"
                                    bulkOption={true}
                                    bulkOptionName="Wszystkie"
                                    queryFn={getProcedureTypes}
                                />
                            </div>
                            <div className="col-span-1">
                                <DropdownField
                                    label="Sala"
                                    placeholder="Wybierz salę"
                                    name="operating_room"
                                    bulkOption={!isMobile}
                                    bulkOptionName="Wszystkie"
                                    items={rooms}
                                />
                            </div>
                            <div className="col-span-1"></div>
                            <div className="col-span-1"></div>
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ScheduleFilters
