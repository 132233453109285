import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import { InputText } from 'components/ui'
import type { InputTextProps } from 'components/ui'

export type InputPriceProps = Omit<
    InputTextProps,
    'handleChange' | 'hasError'
> & {
    negative?: boolean
    currency?: string
    hasError?: boolean
    handleChange?: (value: string) => void
}

const InputPrice: React.FC<InputPriceProps> = ({
    value,
    negative,
    handleChange,
    placeholder,
    currency = 'zł',
    hasError = false,
    ...rest
}) => {
    return (
        <div className="relative">
            <InputText
                type="text"
                value={value ? value.toString().replace('.', ',') : value}
                handleChange={(e) => {
                    if (!e.target.validity.valid) {
                        return false
                    }

                    if (handleChange) {
                        handleChange(e.target.value.replace(',', '.'))
                    }
                }}
                placeholder={placeholder || '0,00'}
                pattern={`^${
                    negative ? '-$|-?' : ''
                }[0-9]\\d*[,|.]?[\\d]{0,2}$`}
                hasError={hasError}
                trailingAddOn={!hasError && currency ? currency : undefined}
                {...rest}
            />
            {hasError && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                </div>
            )}
        </div>
    )
}

export default InputPrice
