import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import classNames from 'classnames'

import { getIcdCodes } from 'api'
import { SearchableDropdownField } from 'components/forms'

import type { ICDCode } from 'api/types'

export const ICD_CODE_SEPARATOR = ' - '

const ICDCodeField: React.FC<{
    name: 'icd9' | 'icd10'
    disabled?: boolean
    hasError?: boolean
}> = ({ name, disabled, hasError }) => {
    const { setFieldValue } = useFormikContext<Record<string, unknown>>()

    const handleChange = useCallback(
        (value) => {
            setFieldValue(
                name + '_phrase',
                value.code + ICD_CODE_SEPARATOR + value.description
            )
        },
        // eslint-disable-next-line
        []
    )

    return (
        <SearchableDropdownField<ICDCode>
            name={name}
            disabled={disabled}
            hasError={hasError}
            queryFieldName="query"
            queryFn={getIcdCodes}
            queryFilters={{
                type: name.substr(3),
            }}
            renderOption={({ code, description }) => (
                <p className="text-sm leading-5">
                    <b className="font-semibold">{code}</b> - {description}
                </p>
            )}
            renderOptions={(options) => (
                <div>
                    <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                        Podpowiedzi
                    </div>
                    {options}
                </div>
            )}
            renderCurrentOption={(item, setOpen) => (
                <div
                    className={classNames(
                        'px-3 py-2 pr-14 text-sm rounded-md border shadow-sm',
                        {
                            'cursor-pointer': !disabled,
                            'bg-gray-100 pointer-events-none': disabled,
                            'border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500':
                                !hasError,
                            'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ring-1 ring-offset-0 ring-red-300':
                                hasError,
                        }
                    )}
                    onClick={() => setOpen(true)}
                >
                    {!!item && (
                        <div className="text-sm leading-5 truncate">
                            <b className="font-semibold">{item.code}</b> -{' '}
                            {item.description}
                        </div>
                    )}
                </div>
            )}
            handleChange={handleChange}
            messageEmptyResult="Nie znaleziono kodu."
        />
    )
}

export default ICDCodeField
