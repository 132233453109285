import { usePolicy, useRolePolicy, usePermissionPolicy } from 'hooks'

export const useRolePolicyGroup = () => {
    const { user } = usePolicy()
    const rolePolicy = useRolePolicy()
    const permissionPolicy = usePermissionPolicy()

    return {
        user,
        canAll: rolePolicy.canAll && permissionPolicy.canIndex,
    }
}
