import { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { RangeInputType } from './interfaces'

interface PopperStateOptionsProps {
    openedFrom?: RangeInputType
}

const useDatePickerPopper = () => {
    const [popperState, setPopperState] = useState<{
        isOpen: boolean
        options?: PopperStateOptionsProps
    }>({
        isOpen: false,
    })
    const popperRef = useRef<HTMLDivElement>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    )

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    })

    const handleOpenPopper = (options?: PopperStateOptionsProps) => {
        setPopperState({ isOpen: true, options })
    }

    const handleClosePopper = () => {
        setPopperState({ isOpen: false })
    }

    return {
        popperRef,
        setPopperElement,
        popperState,
        popper,
        handleClosePopper,
        handleOpenPopper,
    }
}

export default useDatePickerPopper
