import React, { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import {
    useCreateOperationTask,
    useGetOperationTask,
    useUpdateOperationTask,
} from 'api'
import { MESSAGES } from 'constants/index'
import { useModal, useNotification } from 'hooks'
import { Loader } from 'components/ui'
import OperationTask from 'components/OperationTask'
import EmployeeAssignModal from 'components/EmployeeAssignModal'

import type { FormikProps } from 'formik'
import type { User, FormSubmitFn, OperationTaskForm, DocumentForm } from 'types'
import type { OperationResponse, ResponseError } from 'api/types'
import type { TaskStatusTransitionState } from 'components/TaskExpiredModal'

const OperationTaskContainer: React.FC<{
    operation: OperationResponse
    id?: string | number
    onTaskChange?: () => void
    onDelete?: (id: number) => void
    handleClose: () => void
}> = ({ id, operation, handleClose, onTaskChange, onDelete }) => {
    const formRef = useRef<FormikProps<OperationTaskForm>>()
    const queryClient = useQueryClient()
    const showNotification = useNotification()
    const task = useGetOperationTask(id!, { enabled: !!id })
    const { mutate: createTask } = useCreateOperationTask()
    const { mutate: updateTask } = useUpdateOperationTask()
    const modalAssignEmployee = useModal()
    const [isEditing, setIsEditing] = useState(false)

    const handleCreateTask: FormSubmitFn<OperationTaskForm> = (
        values,
        formikHelpers
    ) => {
        createTask(
            { ...values, user: values.user?.id || '' },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    formikHelpers.setSubmitting(false)
                    formikHelpers.resetForm()
                    showNotification({
                        content: MESSAGES.TASK_CREATED,
                        type: 'success',
                    })
                    handleClose()
                    queryClient.invalidateQueries(['operations'])
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleUpdateTask: FormSubmitFn<OperationTaskForm> = (
        values,
        formikHelpers
    ) => {
        updateTask(
            {
                id: id!,
                name: values.name,
                description: values.description,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    formikHelpers.setSubmitting(false)
                    setIsEditing(false)
                    showNotification({
                        content: MESSAGES.TASK_UPDATED,
                        type: 'success',
                    })
                    queryClient.invalidateQueries(['operations'])
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    const handleSubmitFile: FormSubmitFn<DocumentForm> = (
        values,
        formikHelpers
    ) => {
        updateTask(
            {
                id: id!,
                documents: { add: [values] },
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    formikHelpers.setSubmitting(false)
                    formikHelpers.resetForm()
                    showNotification({
                        content: MESSAGES.TASK_DOCUMENT_APPENDED,
                        type: 'success',
                    })
                },
                onError: (error) => {
                    const errors = Object.keys(error.errors).reduce(
                        (acc, key) => {
                            acc[key.replace('documents.add.0.', '')] =
                                error.errors[key]
                            return acc
                        },
                        {} as ResponseError['errors']
                    )

                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(errors)
                },
            }
        )
    }

    const handleUpdateDate = (
        field: 'reminder' | 'patient_term',
        date: string
    ) => {
        updateTask(
            { id: id!, [field]: date },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    showNotification({
                        content: MESSAGES.TASK_TERM_UPDATED_SUCCESS,
                        type: 'success',
                    })
                    queryClient.invalidateQueries(['operations'])
                    !!onTaskChange && onTaskChange()
                },
                onError: (error) => {},
            }
        )
    }

    const updateStatus = (state: TaskStatusTransitionState) => {
        return updateTask(
            { id: state.item.id, status: state.nextStatus },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    showNotification({
                        content: MESSAGES.TASK_STATUS_UPDATED_SUCCESS,
                        type: 'success',
                    })
                    queryClient.invalidateQueries('operations')
                    !!onTaskChange && onTaskChange()
                },
                onError: () => {
                    showNotification({
                        content: MESSAGES.TASK_STATUS_UPDATE_FAILED,
                        type: 'danger',
                    })
                },
            }
        )
    }

    const handleUpdateStatus = async (state: TaskStatusTransitionState) => {
        formRef.current?.setFieldValue('status', state.nextStatus)
        await updateStatus(state)
    }

    const handleUpdateUser: FormSubmitFn<{ user?: User }> = (
        values,
        formikHelpers
    ) => {
        if (!id && formRef.current) {
            formRef.current.setFieldValue('user', values.user)
            formikHelpers.setSubmitting(false)
            modalAssignEmployee.closeModal()

            return
        }

        updateTask(
            {
                id: id!,
                user: values.user?.id || null,
            },
            {
                onSuccess: async () => {
                    await queryClient.invalidateQueries(['operation-tasks'])
                    formikHelpers.setSubmitting(false)
                    modalAssignEmployee.closeModal()
                    queryClient.invalidateQueries(['operations', operation.id])
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                },
            }
        )
    }

    if (task.isLoading) {
        return <Loader />
    }

    if (task.isError) {
        return <div>{task.error.message}</div>
    }

    return (
        <>
            {!id && (
                <OperationTask
                    formRef={formRef}
                    operation={operation}
                    modalAssignEmployee={modalAssignEmployee}
                    handleSubmit={handleCreateTask}
                    handleClose={handleClose}
                />
            )}
            {!!id && task.isSuccess && (
                <OperationTask
                    formRef={formRef}
                    task={task.data?.data}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    operation={operation}
                    modalAssignEmployee={modalAssignEmployee}
                    handleSubmit={handleUpdateTask}
                    handleSubmitFile={handleSubmitFile}
                    handleUpdateDate={handleUpdateDate}
                    onUpdateStatus={handleUpdateStatus}
                    onDelete={onDelete}
                    handleClose={handleClose}
                />
            )}
            <EmployeeAssignModal
                modal={modalAssignEmployee}
                title="Przypisz osobę"
                placeholder="Szukaj / wybierz osobę"
                messageEmptyResult="Nie znaleziono osoby."
                functions={[
                    'caregiver function',
                    'nurse function',
                    'anesthesiologist function',
                ]}
                handleSubmit={handleUpdateUser}
            />
        </>
    )
}

export default OperationTaskContainer
