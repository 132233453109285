import React from 'react'
import { Form, Formik } from 'formik'
import { SearchIcon } from '@heroicons/react/solid'

import { PAYER_TYPE_NFZ } from 'constants/index'
import { getProcedureTypes } from 'api'
import { Label, RangeDatePicker } from 'components/ui'
import {
    AutoSubmitRow,
    CheckboxDropdownField,
    CheckboxDropdownLazyField,
    DropdownField,
    EmployeePickerField,
} from 'components/forms'
import { usePolicy } from 'hooks'

import type { ISurgeryListFilters } from 'types/SurgeryList'

const SurgeryListFilters: React.FC<{
    filters: ISurgeryListFilters
    setFilters: React.Dispatch<React.SetStateAction<ISurgeryListFilters>>
}> = ({ filters, setFilters }) => {
    const {
        user: { isDoctor, isCaregiver },
    } = usePolicy()
    const shouldShownOperatorFilter = !isDoctor

    return (
        <div className="xl:sticky xl:top-16 xl:z-10 mb-4 px-3 py-4 bg-white rounded-lg shadow print:hidden">
            <Formik
                initialValues={filters}
                onSubmit={(values) => {
                    const date_range = {
                        from: values.date_range.from,
                        to: values.date_range.to,
                    }

                    if (filters.date_range.from && filters.date_range.to) {
                        if (
                            (!values.date_range.from && values.date_range.to) ||
                            (values.date_range.from && !values.date_range.to)
                        ) {
                            return
                        }
                    }

                    setFilters({ ...values, date_range })
                }}
                enableReinitialize
            >
                {({ values, setFieldValue, handleChange }) => (
                    <Form>
                        <div
                            className={`grid grid-cols-1 md:grid-cols-2 ${
                                shouldShownOperatorFilter
                                    ? 'xl:grid-cols-3'
                                    : 'xl:grid-cols-6'
                            } gap-4`}
                        >
                            <div className="col-span-1">
                                <Label>Wyszukaj pacjenta</Label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="query"
                                        className="block w-full pl-10 appearance-none px-3 py-2 shadow-sm focus:outline-none sm:text-sm rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        placeholder="Wyszukaj pacjenta"
                                        value={values.query}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {shouldShownOperatorFilter && (
                                <div className="col-span-1">
                                    <Label>Wyszukaj operatora</Label>
                                    <EmployeePickerField
                                        name="operator"
                                        placeholder="Wyszukaj operatora"
                                        functions={['doctor function']}
                                        messageEmptyResult="Nie znaleziono operatora."
                                    />
                                </div>
                            )}
                            <div
                                className={`${
                                    shouldShownOperatorFilter
                                        ? 'col-span-1'
                                        : 'col-span-2'
                                }`}
                            >
                                <Label>Zakres dat zabiegów</Label>
                                <RangeDatePicker
                                    sideMenu={false}
                                    placeholderFrom="Rozpoczęcie"
                                    placeholderTo="Zakończenie"
                                    value={values.date_range}
                                    onChange={(dateRange) => {
                                        setFieldValue('date_range', dateRange)
                                    }}
                                    onBlur={() => {
                                        if (
                                            !values.date_range.from ||
                                            !values.date_range.to
                                        ) {
                                            setFieldValue('date_range', {
                                                from: undefined,
                                                to: undefined,
                                            })
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-span-1">
                                <div className="shadow-sm">
                                    <Label>Rodzaj zabiegu</Label>
                                    <CheckboxDropdownLazyField
                                        bulkOption={true}
                                        bulkOptionName="Wszystkie"
                                        placeholder="Wszystkie"
                                        name="procedure_types"
                                        queryFn={getProcedureTypes}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <Label>Płatnik</Label>
                                <DropdownField
                                    name="payer_type"
                                    bulkOption
                                    bulkOptionName="Wszystkie"
                                    placeholder="Wszystkie"
                                    items={[
                                        { id: PAYER_TYPE_NFZ, name: 'NFZ' },
                                        {
                                            id: 'commercial',
                                            name: 'Komercyjny',
                                        },
                                    ]}
                                />
                            </div>
                            {isCaregiver ? (
                                <div className="col-span-1">
                                    <Label>Status operacji</Label>
                                    <CheckboxDropdownField
                                        name="progress_status"
                                        placeholder="Wszystkie"
                                        items={[
                                            {
                                                id: 'operation-accepted',
                                                name: 'Zielone',
                                                className: 'bg-green-100',
                                            },
                                            {
                                                id: 'operation-in-progress',
                                                name: 'Żółte',
                                                className: 'bg-yellow-100',
                                            },
                                            {
                                                id: 'operation-not-in-progress',
                                                name: 'Czerwone',
                                                className: 'bg-red-100',
                                            },
                                            {
                                                id: 'operation-canceled-rejected',
                                                name: 'Szare',
                                                className: 'bg-gray-100',
                                            },
                                        ]}
                                    />
                                </div>
                            ) : (
                                <div className="col-span-1">
                                    <Label>Status operacji</Label>
                                    <CheckboxDropdownField
                                        name="actual_state"
                                        placeholder="Wszystkie"
                                        items={[
                                            {
                                                id: 'awaiting',
                                                name: 'Oczekuje',
                                            },
                                            {
                                                id: 'ongoing',
                                                name: 'Trwa',
                                            },
                                            {
                                                id: 'finished',
                                                name: 'Wykonane',
                                            },
                                        ]}
                                    />
                                </div>
                            )}
                        </div>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SurgeryListFilters
