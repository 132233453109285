import { usePolicy } from 'hooks/index'
import { PERMISSIONS } from 'constants/index'

export const DIETS_PERMISSIONS = {
    index: PERMISSIONS.INDEX_DIETS,
    show: false,
    create: false,
    update: false,
    delete: false,
}

export const useDietPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(DIETS_PERMISSIONS),
    }
}
