import { useQueryClient } from 'react-query'

import { useUpdateOperation, useUpdateScheduleOperation } from 'api'
import { ResponseData, ScheduleOperation } from 'api/types'

export default function useChildToggle(
    id: number | string,
    resource: 'operation' | 'schedule-operation'
) {
    const queryClient = useQueryClient()
    const { mutate: updateOperation } = useUpdateOperation()
    const { mutate: updateScheduleOperation } = useUpdateScheduleOperation()

    const handleChildToggleChange = (additional_bed: boolean) => {
        const update =
            resource === 'schedule-operation'
                ? updateScheduleOperation
                : updateOperation

        update(
            {
                id,
                data: { additional_bed },
            },
            {
                onSuccess: async (data) => {
                    if (resource === 'schedule-operation') {
                        data = data as ResponseData<ScheduleOperation>

                        queryClient.setQueryData(
                            ['schedules', data.data.id],
                            data
                        )
                        await queryClient.resetQueries(['operations'])
                    }

                    if (resource === 'operation') {
                        await queryClient.invalidateQueries(['operations', id])
                        await queryClient.resetQueries(['schedules'])
                    }
                },
                onError: (error) => {},
            }
        )
    }

    return handleChildToggleChange
}
