import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

import { getPatients, useGetPatient } from 'api'
import {
    displayPatientName,
    displayPatientNameOrPESELOrIdentityCard,
    isPatientName,
} from 'helpers'
import { Button, Label, Loader } from 'components/ui'
import { SearchableDropdownField } from 'components/forms'
import DoctorSurgeryFormAddress from 'components/DoctorSurgeryFormAddress'
import DoctorSurgeryPatientForm from 'components/DoctorSurgeryPatientForm'
import DoctorSurgeryPatientFormContainer from 'containers/DoctorSurgeryPatientForm'

import type { Patient } from 'api/types'
import type { PatientDropdownItem } from 'types/SurgeryForm'
import type { SurgeryForm } from 'types/SurgeryForm'

const DoctorSurgeryFormPatientField: React.FC<{
    changePatient?: boolean
    createPatient?: boolean
}> = ({ changePatient = true, createPatient = true }) => {
    const { values, errors, setFieldValue } = useFormikContext<SurgeryForm>()

    const [isEditing, setIsEditing] = useState(false)

    const updatePatient = (patient: Patient) => {
        setFieldValue('patient', patient)
        setFieldValue('_patient_item', patient)
        setFieldValue(
            '_patient_item_phrase',
            patient ? displayPatientNameOrPESELOrIdentityCard(patient) : ''
        )
    }

    return (
        <div>
            {!isEditing && (
                <>
                    <div className="flex items-center justify-between gap-2">
                        <Label>Imię i nazwisko / PESEL</Label>
                        {changePatient && (
                            <Button
                                variant="link"
                                size="sm"
                                outline={false}
                                onClick={() => setIsEditing(true)}
                            >
                                Edytuj pacjenta
                            </Button>
                        )}
                    </div>
                    <SearchableDropdownField<PatientDropdownItem>
                        name="_patient_item"
                        disabled={!changePatient}
                        hasError={!!errors.patient}
                        placeholder="Wyszukaj pacjenta"
                        queryFn={getPatients}
                        valueToSearchPhrase={(item) =>
                            displayPatientNameOrPESELOrIdentityCard(item)
                        }
                        renderOption={(patient) => (
                            <div>
                                <p className="text-sm leading-5 font-medium">
                                    {isPatientName(patient)
                                        ? displayPatientName(patient)
                                        : '-'}
                                </p>
                                <p className="text-sm leading-5 font-normal text-gray-500">
                                    {patient.pesel && (
                                        <>PESEL: {patient.pesel}</>
                                    )}
                                    {patient.identity_card &&
                                        !patient.pesel && (
                                            <>
                                                Nr dowodu / paszportu:{' '}
                                                {patient.identity_card}
                                            </>
                                        )}
                                </p>
                            </div>
                        )}
                        renderOptions={(options) => (
                            <div>
                                <div className="px-7 py-2 text-xs text-gray-400 tracking-wider uppercase">
                                    Podpowiedzi
                                </div>
                                {options}
                            </div>
                        )}
                        messageEmptyResult="Nie znaleziono pacjenta."
                    />
                    <DoctorSurgeryFormPatient>
                        <DoctorSurgeryFormAddress />
                    </DoctorSurgeryFormPatient>
                </>
            )}
            {isEditing && (
                <>
                    <DoctorSurgeryPatientFormContainer
                        id={values.patient?.id}
                        onSuccess={(patient) => {
                            updatePatient(patient)
                            setIsEditing(false)
                        }}
                    >
                        {({ data, handleSubmit }) => (
                            <DoctorSurgeryPatientForm
                                data={data}
                                handleSubmit={handleSubmit}
                                handleCancel={() => setIsEditing(false)}
                            />
                        )}
                    </DoctorSurgeryPatientFormContainer>
                </>
            )}
            {createPatient &&
                (values._patient_item && !values.patient ? false : true) && (
                    <DoctorCreatePatientDisclosure disabled={!!values.patient}>
                        {({ close }) => (
                            <DoctorSurgeryPatientFormContainer
                                onSuccess={(patient) => {
                                    updatePatient(patient)
                                    close()
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <div className="mt-1">
                                        <DoctorSurgeryPatientForm
                                            handleSubmit={handleSubmit}
                                            handleCancel={close}
                                        />
                                    </div>
                                )}
                            </DoctorSurgeryPatientFormContainer>
                        )}
                    </DoctorCreatePatientDisclosure>
                )}
        </div>
    )
}

const DoctorCreatePatientDisclosure: React.FC<{
    children: ({ close }: { close: () => void }) => JSX.Element
    disabled?: boolean
}> = ({ disabled, children }) => {
    return (
        <Disclosure defaultOpen={false}>
            {({ open, close }) => (
                <div>
                    <Disclosure.Button
                        as="div"
                        className={classNames(
                            'mt-1 py-2 px-3 flex justify-between items-center w-full text-sm font-medium text-left pointer-events-none',
                            {
                                'opacity-50': disabled,
                            }
                        )}
                    >
                        <Button
                            type="button"
                            variant="tertiary"
                            className={classNames({
                                'pointer-events-auto': !disabled,
                            })}
                            outline={false}
                        >
                            Dodaj pacjenta
                        </Button>
                        <ChevronUpIcon
                            className={classNames('w-5 h-5 text-blue-500', {
                                'transform rotate-180': open,
                                'cursor-pointer pointer-events-auto': !disabled,
                            })}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel>{children({ close })}</Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    )
}

const DoctorSurgeryFormPatient = ({
    children,
}: React.PropsWithChildren<any>) => {
    const { values, setFieldValue } = useFormikContext<SurgeryForm>()

    const patient = useGetPatient(values._patient_item?.id, {
        enabled: !!values._patient_item,
    })

    useEffect(() => {
        if (patient.isSuccess && !!values._patient_item) {
            setFieldValue('patient', patient.data.data)
        }

        if (!values._patient_item && !!values.patient) {
            setFieldValue('patient', null)
        }
    }, [
        setFieldValue,
        values._patient_item,
        patient.isSuccess,
        patient.data,
        values.patient,
    ])

    if (patient.isLoading) {
        return (
            <div className="mt-6">
                <Loader size="sm" />
            </div>
        )
    }

    if (patient.isSuccess) {
        return children
    }

    return null
}

export default DoctorSurgeryFormPatientField
