import React, { useReducer } from 'react'
import { PlusIcon } from '@heroicons/react/solid'

import { DEFAULT_LIST_PER_PAGE } from 'constants/index'
import { listReducer } from 'helpers'
import { useGetRoles } from 'api'
import { useRolePolicyGroup } from 'hooks'
import SettingsPermissionLayout from 'layouts/SettingsPermissionLayout'
import { AccessDenied } from 'components'
import { Button, Loader } from 'components/ui'
import RoleList from 'components/SettingsRoleList'

const SettingsRoleListContainer = () => {
    const rolePolicyGroup = useRolePolicyGroup()
    const [filters, setFilters] = useReducer(listReducer, {
        page: 1,
        length: DEFAULT_LIST_PER_PAGE,
    })

    const roles = useGetRoles(filters, {
        enabled: rolePolicyGroup.canAll,
    })

    return (
        <SettingsPermissionLayout
            title="Role i uprawnienia"
            actions={
                rolePolicyGroup.canAll && (
                    <>
                        <Button
                            as="link"
                            to="/settings/roles/add"
                            variant="primary"
                            iconRight={<PlusIcon />}
                        >
                            Dodaj
                        </Button>
                    </>
                )
            }
        >
            <>
                {!rolePolicyGroup.canAll && (
                    <AccessDenied message="Nie masz uprawnień do zarządzania rolami" />
                )}
                {roles.isLoading && <Loader />}
                {roles.isError && <div>{roles.error.message}</div>}
                {roles.isSuccess && (
                    <RoleList data={roles.data} setFilters={setFilters} />
                )}
            </>
        </SettingsPermissionLayout>
    )
}

export default SettingsRoleListContainer
