import {
    useQuery,
    useMutation,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import { ResponseData, ResponseError } from 'api/types'

export const useGetApprovedDays = <
    F extends { paginated: boolean; page?: number; length?: number },
    R = ResponseData<{ date: string; sms_sent_to: string }[]>
>(
    filters?: F,
    options?: UseQueryOptions<R, ResponseError>
): UseQueryResult<R, ResponseError> =>
    useQuery<R, ResponseError>(
        ['approved-days', filters],
        () =>
            client.get(
                '/approved-days' + (filters ? '?' + qs.stringify(filters) : '')
            ),
        options
    )

export const usePostApprovedDays = <
    R1 extends { from?: string; to?: string },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/approved-days', data))
