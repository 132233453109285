import React from 'react'
import classNames from 'classnames'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { LockClosedIcon } from '@heroicons/react/solid'
import { twMerge } from 'tailwind-merge'

import {
    useDoctorSurgeryPolicyGroup,
    useOperationAccountancyPolicy,
    usePolicy,
    usePricePolicy,
} from 'hooks'
import {
    displayPatientName,
    formatDate,
    formatCurrency,
    isPatientName,
} from 'helpers'
import { Pagination, Button } from 'components/ui'
import Sort, { SetSortingFn } from 'components/Sort'
import OperationDescriptionStatus from 'components/OperationDescriptionStatus'
import EmptyList from 'components/EmptyList'
import DoctorSurgeryPerformedListItemMenu3dots from 'components/DoctorSurgeryPerformedListItemMenu3dots'

import type { OperationListItem } from 'types'
import type { ResponseList } from 'api/types'
import type { Operation } from 'api/types'
import type { TDoctorPatientListFilter } from 'containers/DoctorPatientList'
import type { SurgeryPerformedListFilters } from 'containers/DoctorSurgeryPerformedList'

const DoctorSurgeryPerformedList: React.FC<{
    data: ResponseList<OperationListItem[]>
    filtersCount: number
    filters: TDoctorPatientListFilter
    setFilters: React.Dispatch<
        React.SetStateAction<SurgeryPerformedListFilters>
    >
    setSorting: SetSortingFn
    downloading: number
    handleDownload: (item: OperationListItem) => void
    onUpdateStatus: (id: number, status: Operation['status']) => void
}> = ({
    data,
    filters,
    setFilters,
    filtersCount,
    setSorting,
    downloading,
    handleDownload,
    onUpdateStatus,
}) => {
    return (
        <div className="p-2 bg-white rounded-lg">
            {data.meta.total > 0 && (
                <Table
                    data={data}
                    filters={filters}
                    setSorting={setSorting}
                    downloading={downloading}
                    handleDownload={handleDownload}
                    onUpdateStatus={onUpdateStatus}
                />
            )}
            {data.meta.total > 0 && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters((prevState) => ({ ...prevState, page }))
                    }
                />
            )}
            {data.meta.total === 0 && filtersCount === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszej operacji."
                    to="/surgeries/add"
                />
            )}
            {data.meta.total === 0 && filtersCount > 0 && (
                <EmptyList
                    headline="Nie znaleziono wyników wyszukiwania"
                    message="Możesz dodać operację."
                    to="/surgeries/add"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<OperationListItem[]>
    filters: TDoctorPatientListFilter
    setSorting: SetSortingFn
    downloading: number
    handleDownload: (item: OperationListItem) => void
    onUpdateStatus: (id: number, status: Operation['status']) => void
}> = ({
    data,
    filters,
    setSorting,
    downloading,
    handleDownload,
    onUpdateStatus,
}) => {
    const {
        user: { isDoctor },
    } = usePolicy()
    const { isAllowed: isOperationAccountancy } =
        useOperationAccountancyPolicy()
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()
    const { canSee: canSeePrice } = usePricePolicy()

    return (
        <table className="table-fixed w-full border-b-2 border-gray-200 divide-y divide-gray-200">
            <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                <tr>
                    <th
                        scope="col"
                        className="w-28 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="estimated_date"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Data
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-8 text-center font-medium tracking-wider"
                    >
                        &nbsp;
                    </th>
                    <th
                        scope="col"
                        className="w-52 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="patient_name"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Pacjent
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-52 px-4 py-3 text-left font-medium tracking-wider"
                    >
                        Nazwa zabiegu
                    </th>
                    {canSeePrice && (
                        <>
                            <th
                                scope="col"
                                className="w-24 px-4 py-3 text-left font-medium tracking-wider"
                            >
                                Cena bazowa
                            </th>
                            <th
                                scope="col"
                                className="w-24 px-4 py-3 text-left font-medium tracking-wider"
                            >
                                Cena implantów
                            </th>
                            <th
                                scope="col"
                                className="w-24 px-4 py-3 text-left font-medium tracking-wider"
                            >
                                Koszty dodatkowe
                            </th>
                        </>
                    )}
                    {!isDoctor && (
                        <th
                            scope="col"
                            className="w-52 px-6 py-3 text-left font-medium tracking-wider"
                        >
                            Lekarz prowadzący
                        </th>
                    )}
                    <th
                        scope="col"
                        className="w-28 px-4 py-3 text-left font-medium tracking-wider"
                    >
                        Status
                    </th>
                    <th
                        scope="col"
                        className="w-40 px-4 text-left font-medium tracking-wider"
                    >
                        Szczegóły
                    </th>
                </tr>
            </thead>
            <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span>{formatDate(item.estimated_date)}</span>
                        </td>
                        <td className="text-center">
                            {item.status === 'descripted' && (
                                <LockClosedIcon className="w-4" />
                            )}
                        </td>
                        <td className="px-6 py-6">
                            <span className="text-gray-900 font-medium">
                                {isPatientName(item.patient)
                                    ? displayPatientName(item.patient)
                                    : '-'}
                            </span>
                        </td>
                        <td className="px-4 py-6">
                            <p className="truncate text-gray-900">
                                {item.procedure?.name || '-'}
                            </p>
                        </td>
                        {canSeePrice && (
                            <>
                                <td
                                    className={twMerge(
                                        'px-4 py-6',
                                        isOperationAccountancy && 'py-2'
                                    )}
                                >
                                    <p className="text-gray-900">
                                        {formatCurrency(item.base_cost, {
                                            minimumFractionDigits: 0,
                                        })}
                                    </p>
                                </td>
                                <td
                                    className={twMerge(
                                        'px-4 py-6',
                                        isOperationAccountancy && 'py-2'
                                    )}
                                >
                                    {isOperationAccountancy &&
                                    item.final_costs_calculations ? (
                                        <>
                                            <p className="text-gray-900">
                                                {formatCurrency(
                                                    item
                                                        .final_costs_calculations
                                                        .implants_used_sum,
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </p>

                                            <p className="text-gray-500">
                                                {formatCurrency(
                                                    item.implants_sum,
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <p className="text-gray-900">
                                            {formatCurrency(item.implants_sum, {
                                                minimumFractionDigits: 0,
                                            })}
                                        </p>
                                    )}
                                </td>
                                <td
                                    className={twMerge(
                                        'px-4 py-6',
                                        isOperationAccountancy && 'py-2'
                                    )}
                                >
                                    {isOperationAccountancy &&
                                    item.final_costs_calculations ? (
                                        <>
                                            <p className="text-gray-900">
                                                {formatCurrency(
                                                    item
                                                        .final_costs_calculations
                                                        .costs_resolved_sum,
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </p>

                                            <p className="text-gray-500">
                                                {formatCurrency(
                                                    item.costs_sum,
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <p className="text-gray-900">
                                            {formatCurrency(item.costs_sum, {
                                                minimumFractionDigits: 0,
                                            })}
                                        </p>
                                    )}
                                </td>
                            </>
                        )}
                        {!isDoctor && (
                            <td className="px-6 py-6">
                                {!!item.operator ? (
                                    <span>
                                        {item.operator.first_name}{' '}
                                        {item.operator.last_name}
                                    </span>
                                ) : (
                                    '-'
                                )}
                            </td>
                        )}
                        <td className="px-4 py-4">
                            <OperationDescriptionStatus
                                status={item.status}
                                disabled={
                                    !doctorSurgeryPolicyGroup.canUpdateStatus ||
                                    item.status === 'to_description'
                                }
                                onChange={(status) =>
                                    onUpdateStatus(Number(item.id), status)
                                }
                            />
                        </td>
                        <td>
                            <div className="h-full flex flex-row justify-end items-center gap-x-4 px-4">
                                {item.status === 'descripted' && (
                                    <Button
                                        variant="tertiary"
                                        disabled={
                                            !doctorSurgeryPolicyGroup.canShowClosure
                                        }
                                        loading={
                                            downloading === item.closure_id
                                        }
                                        size="sm"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleDownload(item)
                                        }}
                                    >
                                        <DocumentDownloadIcon className="w-4 mr-1" />
                                        Opis
                                    </Button>
                                )}
                                {item.status !== 'descripted' && (
                                    <Button
                                        variant="quaternary"
                                        size="sm"
                                        as="link"
                                        to={
                                            item.closure_id
                                                ? `/surgeries/${item.id}/closures/${item.closure_id}/edit`
                                                : `/surgeries/${item.id}/closures/create`
                                        }
                                        disabled={
                                            !doctorSurgeryPolicyGroup.canCreateUpdateClosure
                                        }
                                    >
                                        <DocumentDownloadIcon className="w-4 mr-1" />
                                        Opisz
                                    </Button>
                                )}
                                <DoctorSurgeryPerformedListItemMenu3dots
                                    item={item}
                                />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default DoctorSurgeryPerformedList
