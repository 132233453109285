import React, { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
    useCreateProcedureType,
    useGetProcedureType,
    useUpdateProcedureType,
} from 'api'
import { useProcedureTypePolicyGroup } from 'hooks'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import SettingsProcedureType from 'components/SettingsProcedureType'
import AlertContext from 'contexts/alerts/alertContext'

import type { FormSubmitFn } from 'types'
import type { ProcedureTypeForm } from 'api/types'

export default function SettingsProcedureTypeContainer() {
    const procedurePolicyGroup = useProcedureTypePolicyGroup()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const params = useParams()
    const { add: alert } = useContext(AlertContext)

    const id = params.id && !isNaN(Number(params.id)) ? Number(params.id) : 0
    const procedureType = useGetProcedureType(id!, {
        enabled: procedurePolicyGroup.canAll && !!id,
    })

    const { mutate: create } = useCreateProcedureType()
    const { mutate: update } = useUpdateProcedureType()

    const handleSubmit: FormSubmitFn<ProcedureTypeForm> = (
        values,
        formikHelpers
    ) => {
        const sanitizedValues = {
            ...values,
            type: values.type?.id || '',
            cleaning_time: values.cleaning_time?.id || '',
            tasks: values.tasks.map((task) => ({
                ...task,
                roles: task.roles.map((role) => ({ id: role.id })),
            })),
        }

        if (id) {
            update(
                {
                    id,
                    ...sanitizedValues,
                },
                {
                    onSuccess: () => {
                        formikHelpers.setSubmitting(false)
                        queryClient.invalidateQueries('procedure-types')
                        navigate('/settings/procedure-types')
                        alert({
                            content: 'Rodzaj zabiegu został zaktualizowany.',
                            type: 'success',
                        })
                    },
                    onError: (error) => {
                        formikHelpers.setSubmitting(false)
                        formikHelpers.setErrors(error.errors)
                        alert({
                            content: 'Nie udało się zapisać zmian.',
                            type: 'danger',
                        })
                    },
                }
            )
        } else {
            create(sanitizedValues, {
                onSuccess: () => {
                    formikHelpers.setSubmitting(false)
                    queryClient.invalidateQueries('procedure-types')
                    navigate('/settings/procedure-types')
                    alert({
                        content: 'Rodzaj zabiegu został utworzony.',
                        type: 'success',
                    })
                },
                onError: (error) => {
                    formikHelpers.setSubmitting(false)
                    formikHelpers.setErrors(error.errors)
                    alert({
                        content: 'Nie udało się zapisać zmian.',
                        type: 'danger',
                    })
                },
            })
        }
    }

    if (!procedurePolicyGroup.canAll) {
        return (
            <AccessDenied message="Nie masz uprawnień do zarządzania rodzajami zabiegów" />
        )
    }

    if (procedureType.isLoading) {
        return <Loader />
    }

    if (procedureType.isError) {
        return <span>{procedureType.error.message}</span>
    }

    if (!procedureType.isIdle && !procedureType.isSuccess) {
        return null
    }

    return (
        <SettingsProcedureType
            id={id}
            data={procedureType.data}
            handleSubmit={handleSubmit}
        />
    )
}
