import React, { useCallback, useContext, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { usePatientPolicy } from 'hooks'
import { MESSAGES } from 'constants/index'
import { useCreatePatient, useGetPatient, useUpdatePatient } from 'api'
import ListLayout from 'layouts/ListLayout'
import { AccessDenied } from 'components'
import { Loader } from 'components/ui'
import DoctorPatientForm, { PatientForm } from 'components/DoctorPatientForm'
import { TTab } from 'components/ListLayoutTabs'
import alertContext from 'contexts/alerts/alertContext'

import type { FormSubmitFn } from 'types'
import type { Patient } from 'api/types'

const GENDER_API_VALUES: Record<number, string> = { 1: 'female', 2: 'male' }

const tabs: TTab[] = [
    {
        name: 'Pacjent',
        to: '/patients/:id/stats',
        current: false,
    },
    {
        name: 'Dane pacjenta',
        to: '/patients/:id/edit',
        current: true,
    },
    {
        name: 'Operacje i zabiegi',
        to: '/patients/:id/surgeries',
        current: false,
    },
    { name: 'Dokumenty', to: '/patients/:id/documents', current: false },
]

const DoctorPatientFormContainer: React.FC = () => {
    const queryClient = useQueryClient()
    const { id } = useParams()
    const navigate = useNavigate()

    const { canCreate, canUpdate } = usePatientPolicy()
    const { mutate: create } = useCreatePatient()
    const { mutate: update } = useUpdatePatient()
    const patient = useGetPatient(id, { enabled: canUpdate && !!id })
    const { add: addAlert } = useContext(alertContext)

    const transformFormDataToApi = useCallback(
        (values: PatientForm) =>
            Object.assign(
                {},
                values,
                {
                    gender: values.gender
                        ? GENDER_API_VALUES[values.gender.id as number]
                        : '',
                },
                id ? { id } : {}
            ) as Patient,
        [id]
    )

    const createOrUpdate = useMemo(
        () => (id ? update : create),
        [create, update, id]
    )

    const handleSubmit: FormSubmitFn<PatientForm> = (values, formikHelpers) => {
        createOrUpdate(transformFormDataToApi(values), {
            onSuccess: async () => {
                await queryClient.invalidateQueries('patients')
                formikHelpers.setSubmitting(false)
                navigate('/patients')
                addAlert({
                    content: id
                        ? MESSAGES.PATIENT_UPDATED
                        : MESSAGES.PATIENT_CREATED,
                    type: 'success',
                })
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    if (!id && !canCreate) {
        return (
            <AccessDenied message="Nie masz uprawnień do tworzenia pacjenta" />
        )
    }

    if (id && !canUpdate) {
        return <AccessDenied message="Nie masz uprawnień do edycji pacjenta" />
    }

    if (patient.isLoading) {
        return <Loader />
    }

    if (patient.isError) {
        return <div>{patient.error.message}</div>
    }

    return (
        <ListLayout
            title={id ? 'Karta pacjenta' : 'Dodaj pacjenta'}
            id={id}
            tabs={id ? tabs : null}
        >
            <DoctorPatientForm
                data={patient.data}
                handleSubmit={handleSubmit}
            />
        </ListLayout>
    )
}

export default DoctorPatientFormContainer
