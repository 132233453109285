import React, { useContext, useState, Fragment } from 'react'
import { Form, Formik } from 'formik'
import { FilterIcon, SearchIcon } from '@heroicons/react/solid'
import { Transition } from '@headlessui/react'

import { getProcedureTypes } from 'api'
import {
    AutoSubmitRow,
    CheckboxDropdownLazyField,
    DropdownField,
    DropdownItem,
    EmployeePickerField,
} from 'components/forms'
import mediaContext from 'contexts/media/mediaContext'
import { Button } from 'components/ui'

import type { FormSubmitFn } from 'types'
import type { UserDropdownItem } from 'components/forms'

const FILTER_OPERATION_TYPE_ITEMS = [
    { id: 'all', name: 'Wszystkie' },
    {
        id: 'my_operations',
        name: 'Moje zadania',
    },
    {
        id: 'longterm_operations',
        name: 'Operacje długoterminowe',
    },
    {
        id: 'new_operations',
        name: 'Nowe operacje',
    },
]

export const FILTER_OPERATION_TYPE_DEFAULT: DropdownItem =
    FILTER_OPERATION_TYPE_ITEMS.find((item) => item.id === 'all')!

export interface CaregiverPanelFilter {
    query: string
    operator: UserDropdownItem | undefined
    procedure_types: DropdownItem[]
    operation_type: DropdownItem
}

const CaregiverPanelFilters: React.FC<{
    formRef: React.MutableRefObject<any>
    handleChange: FormSubmitFn<CaregiverPanelFilter>
}> = ({ formRef, handleChange }) => {
    const { isDesktop } = useContext(mediaContext)
    const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={
                    {
                        query: '',
                        operator: undefined,
                        procedure_types: [],
                        operation_type: FILTER_OPERATION_TYPE_DEFAULT,
                    } as CaregiverPanelFilter
                }
                enableReinitialize
                onSubmit={handleChange}
            >
                {({ handleChange }) => (
                    <Form>
                        <div className="mb-4 grid grid-cols-6 xl:grid-cols-5 gap-4">
                            <div className="col-span-5 xl:col-span-2">
                                <div className="relative rounded-md">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon
                                            className="h-5 w-5 text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="query"
                                        className="pl-10 appearance-none block w-full px-3 py-2 shadow-sm focus:outline-none text-base rounded-md border-gray-300 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500"
                                        autoComplete="off"
                                        placeholder="Wyszukaj pacjenta"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {!isDesktop ? (
                                <div className="col-span-1">
                                    <Button
                                        className="h-full w-full p-0"
                                        style={{ height: '42px' }}
                                        onClick={() => {
                                            setIsMobileFilterOpen(
                                                (state) => !state
                                            )
                                        }}
                                    >
                                        <FilterIcon className="text-gray-500 w-full h-5" />
                                    </Button>
                                </div>
                            ) : (
                                <CaregiverPanelFiltersDropdowns />
                            )}
                        </div>

                        <Transition
                            show={!isDesktop && isMobileFilterOpen}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <div className="mb-3 grid grid-cols-1 gap-4">
                                <CaregiverPanelFiltersDropdowns />
                            </div>
                        </Transition>
                        <AutoSubmitRow />
                    </Form>
                )}
            </Formik>
        </div>
    )
}

const CaregiverPanelFiltersDropdowns = () => (
    <>
        <div className="col-span-1 xl:col-span-1">
            <EmployeePickerField
                name="operator"
                size="lg"
                placeholder="Wyszukaj operatora"
                functions={['doctor function']}
                messageEmptyResult="Nie znaleziono operatora."
            />
        </div>
        <div className="col-span-1 xl:col-span-1">
            <CheckboxDropdownLazyField
                name="procedure_types"
                placeholder="Rodzaj operacji"
                variant="lg"
                bulkOption={true}
                bulkOptionName="Wszystkie"
                queryFn={getProcedureTypes}
            />
        </div>
        <div className="col-span-1 xl:col-span-1">
            <DropdownField
                name="operation_type"
                variant="lg"
                renderValue={(value) => (
                    <span className="block truncate">
                        <span className="text-gray-500">Typ:</span>
                        &nbsp;
                        {value}
                    </span>
                )}
                items={FILTER_OPERATION_TYPE_ITEMS}
            />
        </div>
    </>
)

export default CaregiverPanelFilters
