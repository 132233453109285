import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { usePatientPolicy } from 'hooks'
import { formatDate } from 'helpers'
import { Pagination } from 'components/ui'
import Sort, { SetSortingFn } from 'components/Sort'
import EmptyList from 'components/EmptyList'

import type { PatientListItem, ResponseList } from 'api/types'
import type { TDoctorPatientListFilter } from 'containers/DoctorPatientList'

const DoctorPatientList: React.FC<{
    data: ResponseList<PatientListItem[]>
    filters: TDoctorPatientListFilter
    filtersCount: number
    setSorting: SetSortingFn
    setPage: (page: number) => void
}> = ({ data, setPage, filters, filtersCount, setSorting }) => {
    return (
        <div className="p-2 bg-white rounded-lg">
            {data.meta.total > 0 && (
                <Table data={data} filters={filters} setSorting={setSorting} />
            )}
            {data.meta.total > 0 && (
                <Pagination meta={data.meta} handleChange={setPage} />
            )}
            {data.meta.total === 0 && filtersCount === 0 && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego pacjenta."
                    to="/patients/add"
                />
            )}
            {data.meta.total === 0 && filtersCount > 0 && (
                <EmptyList
                    headline="Nie znaleziono wyników wyszukiwania"
                    message="Możesz dodać pacjenta."
                    to="/patients/add"
                />
            )}
        </div>
    )
}

const Table: React.FC<{
    data: ResponseList<PatientListItem[]>
    filters: TDoctorPatientListFilter
    setSorting: SetSortingFn
}> = ({ data, filters, setSorting }) => {
    const { canUpdate } = usePatientPolicy()

    return (
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 text-xs text-gray-500 uppercase">
                <tr>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="id"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Id
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="last_name"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Nazwisko
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="first_name"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Imię
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <span>PESEL</span>
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-3 text-left font-medium tracking-wider"
                    >
                        <span>Nr dowodu</span>
                    </th>
                    <th
                        scope="col"
                        className="w-6/12 px-1 py-3 text-left font-medium tracking-wider"
                    >
                        Operacja lub zabieg
                    </th>
                    <th
                        scope="col"
                        className="w-2 px-1 py-3 text-left font-medium tracking-wider"
                    >
                        <Sort
                            name="created_at"
                            filters={filters}
                            setSorting={setSorting}
                        >
                            Data dodania
                        </Sort>
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-3 text-left font-medium tracking-wider"
                    >
                        {canUpdate && <span>Opcje</span>}
                    </th>
                </tr>
            </thead>
            <tbody className="text-gray-500 text-sm bg-white divide-y divide-gray-200 leading-5">
                {data.data.map((item, index) => (
                    <tr
                        key={item.id}
                        className={classNames({
                            'bg-gray-50': index % 2,
                        })}
                    >
                        <td className="px-6 py-6">
                            <span>{item.id}</span>
                        </td>
                        <td className="px-6 py-1">
                            <span className="text-gray-900 font-medium">
                                {item.last_name || '-'}
                            </span>
                        </td>
                        <td className="px-6 py-1">
                            <span className="text-gray-900 font-medium">
                                {item.first_name || '-'}
                            </span>
                        </td>
                        <td className="px-6 py-1">
                            <span className="text-gray-900 font-medium">
                                {item.pesel || '-'}
                            </span>
                        </td>
                        <td className="px-6 py-1">
                            <span className="text-gray-900 font-medium">
                                {item.identity_card || '-'}
                            </span>
                        </td>
                        <td className="px-1 py-1">
                            <span>
                                {item.last_operation?.procedure?.name || '-'}
                            </span>
                        </td>
                        <td className="px-1 py-6">
                            <span className="whitespace-nowrap">
                                {!!item.created_at
                                    ? formatDate(item.created_at)
                                    : '-'}
                            </span>
                        </td>
                        <td className="px-6 py-1 text-right">
                            {canUpdate && (
                                <span className="font-normal">
                                    <Link
                                        className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                                        to={`/patients/${item.id}/edit`}
                                    >
                                        Edytuj
                                    </Link>
                                </span>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default DoctorPatientList
