import React from 'react'
import { Disclosure as HeadlessDisclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'

const Disclosure = ({
    isStatic,
    children,
    title,
    actions,
    defaultOpen = true,
    className,
    onToggle,
}: React.PropsWithChildren<{
    isStatic?: boolean
    title: string
    actions?: React.ReactElement
    defaultOpen?: boolean
    className?: string
    onToggle?: () => void
}>) => {
    const Button = isStatic ? 'button' : HeadlessDisclosure.Button

    return (
        <HeadlessDisclosure defaultOpen={defaultOpen}>
            {({ open }) => (
                <div className={className}>
                    <Button
                        onClick={onToggle}
                        type="button"
                        className="mb-8 flex justify-between items-center w-full text-sm font-medium text-left"
                    >
                        <div className="w-0 flex-1 flex items-center">
                            <span className="text-lg leading-6 font-medium text-gray-900">
                                {title}
                            </span>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex space-x-4 items-center">
                            {actions}
                            <ChevronUpIcon
                                className={`${
                                    (isStatic ? defaultOpen : open)
                                        ? 'transform rotate-180'
                                        : ''
                                } w-5 h-5 text-blue-500`}
                            />
                        </div>
                    </Button>
                    {!isStatic && (
                        <HeadlessDisclosure.Panel>
                            {children}
                        </HeadlessDisclosure.Panel>
                    )}
                    {isStatic && defaultOpen && children}
                </div>
            )}
        </HeadlessDisclosure>
    )
}

export default Disclosure
