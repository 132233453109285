import React from 'react'
import { XIcon } from '@heroicons/react/outline'

import { useGetNotifications } from 'api'
import { SlideOver, Loader } from 'components/ui'
import NotificationListItem from 'components/NotificationListItem'

import type { IModal } from 'types'

const NotificationListSlideOver: React.FC<{
    modal: IModal
    queryResult: ReturnType<typeof useGetNotifications>
}> = ({ modal, queryResult }) => {
    return (
        <SlideOver
            isOpen={modal.isOpen}
            classNameSlideOver="top-16 max-w-md"
            classNameDialog="z-auto"
            closeButton={false}
            onClose={modal.closeModal}
            onClickOutside={modal.closeModal}
        >
            <div className="min-h-0 flex-1 flex flex-col divide-y divide-gray-300">
                <div className="relative px-4 sm:px-6 py-4">
                    <span className="text-lg leading-7 text-gray-900 font-medium">
                        Powiadomienia
                    </span>
                    <span
                        className="absolute top-4 right-5 p-1 text-gray-500 cursor-pointer hover:text-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        onClick={() => modal.closeModal()}
                    >
                        <XIcon className="w-5" />
                    </span>
                </div>
                <div className="min-h-0 flex-1 flex flex-col divide-y divide-gray-300 overflow-y-scroll">
                    {(queryResult.isLoading || queryResult.isFetching) && (
                        <Loader />
                    )}
                    {queryResult.isSuccess &&
                        queryResult.data.data.length === 0 && (
                            <div className="h-full flex justify-center items-center">
                                <span className="text-gray-500">
                                    Nie ma dzisaj jeszcze żadnej notyfikacji.
                                </span>
                            </div>
                        )}
                    {queryResult.isSuccess && queryResult.data.data.length > 0 && (
                        <>
                            {queryResult.data.data.map((item) => (
                                <NotificationListItem
                                    key={item.id}
                                    data={item.data}
                                    danger={item.data.type === 'canceled'}
                                    handleClose={modal.closeModal}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </SlideOver>
    )
}

export default NotificationListSlideOver
