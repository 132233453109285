import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const SCHEDULE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_SCHEDULES,
    show: PERMISSIONS.SHOW_SCHEDULES,
    create: false,
    update: PERMISSIONS.UPDATE_SCHEDULES,
    delete: false,
}

export const useSchedulePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(SCHEDULE_PERMISSIONS),
    }
}
