import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const OPERATION_TASK_PERMISSIONS = {
    index: PERMISSIONS.INDEX_OPERATION_TASKS,
    show: PERMISSIONS.SHOW_OPERATION_TASKS,
    create: PERMISSIONS.CREATE_OPERATION_TASKS,
    update: PERMISSIONS.UPDATE_OPERATION_TASKS,
    delete: PERMISSIONS.DELETE_OPERATION_TASKS,
}

export const useOperationTaskPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(OPERATION_TASK_PERMISSIONS),
    }
}
