import React from 'react'
import classNames from 'classnames'
import { Field, Formik } from 'formik'

import { Pagination } from 'components/ui'
import { PriceField, AutoSubmitRow } from 'components/forms'
import EmptyList from 'components/EmptyList'

import type {
    FormProcedureFilters,
    GetProcedureListResponse,
    Payer,
    PayerCost,
    Procedure,
    ResponseData,
} from 'api/types'
import type { FormSubmitFn } from 'types'
import type { ProcedureListFilterState } from 'helpers'

interface SettingsProcedureCostListProps {
    procedures: GetProcedureListResponse
    payers: ResponseData<Payer[]>
    filters: ProcedureListFilterState
    filtersCount: number
    setFilters: React.Dispatch<any>
    handleUpdate: FormSubmitFn<FormProcedure>
}

export type FormProcedure = Pick<Procedure, 'id' | 'base_price'> & {
    payer_costs: PayerCost[]
}

const filterVisiblePayers = (filters: FormProcedureFilters) => (payer: Payer) =>
    filters.procedure_payers!.length
        ? filters.procedure_payers!.map((i) => i.id).includes(payer.id)
        : true

const mapPayerCost =
    (payer_costs: PayerCost[]) =>
    (payer: Payer): PayerCost => {
        const payerCost = payer_costs.find((i) => i.id === payer.id)

        return payerCost
            ? {
                  id: payerCost.id,
                  price: payerCost.price || '',
              }
            : {
                  id: payer.id,
                  price: '',
              }
    }

const SettingsProcedureCostList: React.FC<SettingsProcedureCostListProps> = ({
    procedures,
    payers,
    filters,
    filtersCount,
    setFilters,
    handleUpdate,
}) => {
    return (
        <>
            <div className="bg-white p-2 rounded-lg">
                {procedures.meta.total === 0 && filtersCount === 0 && (
                    <EmptyList
                        headline="Nic tu jeszcze nie ma"
                        message="Zacznij od stworzenia pierwszej operacji."
                        to="/settings/procedures/add"
                    />
                )}
                {procedures.meta.total === 0 && filtersCount > 0 && (
                    <EmptyList
                        headline="Nie znaleziono wyników wyszukiwania"
                        message="Możesz dodać operację."
                        to="/settings/procedures/add"
                    />
                )}
                {procedures.meta.total > 0 && (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Id
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nazwa
                                </th>
                                <th
                                    scope="col"
                                    className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                >
                                    Cena Podst.
                                </th>
                                {payers.data
                                    .filter(
                                        filterVisiblePayers(filters.filters)
                                    )
                                    .map((item, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className="w-1/12 px-1 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {item.name}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                            {procedures.data.map(
                                (procedure, procedureIndex) => (
                                    <Formik
                                        key={procedureIndex}
                                        initialValues={{
                                            id: procedure.id,
                                            base_price: procedure.base_price,
                                            payer_costs: payers.data
                                                .filter(
                                                    filterVisiblePayers(
                                                        filters.filters
                                                    )
                                                )
                                                .map(
                                                    mapPayerCost(
                                                        procedure.payer_costs
                                                    )
                                                ),
                                        }}
                                        enableReinitialize
                                        onSubmit={handleUpdate}
                                    >
                                        {({ isSubmitting, errors }) => (
                                            <tr
                                                key={procedureIndex}
                                                className={classNames({
                                                    'bg-gray-50':
                                                        procedureIndex % 2,
                                                })}
                                            >
                                                <td className="px-6 py-6 whitespace-nowrap">
                                                    <div className="">
                                                        {procedure.id}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-6">
                                                    <span className="text-gray-900 font-medium">
                                                        {procedure.name}
                                                    </span>
                                                </td>
                                                <td className="px-1 py-1 whitespace-nowrap text-center">
                                                    <PriceField
                                                        name="base_price"
                                                        disabled={isSubmitting}
                                                        variant="sm"
                                                        showTextError={false}
                                                    />
                                                </td>
                                                <PayerCostInputs
                                                    filters={filters.filters}
                                                    payers={payers}
                                                />
                                                <AutoSubmitRow />
                                            </tr>
                                        )}
                                    </Formik>
                                )
                            )}
                        </tbody>
                    </table>
                )}
                {procedures.meta.total > 0 && (
                    <Pagination
                        meta={procedures.meta}
                        handleChange={(page) =>
                            setFilters({ type: 'setPage', payload: page })
                        }
                    />
                )}
            </div>
        </>
    )
}

const PayerCostInputs: React.FC<{
    payers: ResponseData<Payer[]>
    filters: FormProcedureFilters
}> = ({ payers, filters }) => (
    <>
        {payers.data
            .filter(filterVisiblePayers(filters))
            .map((payer, payerIndex) => (
                <td key={payerIndex} className="px-1 py-1 text-center">
                    <Field
                        type="hidden"
                        name={`payer_costs[${payerIndex}].id`}
                    />
                    <PriceField
                        name={`payer_costs.${payerIndex}.price`}
                        variant="sm"
                        showTextError={false}
                    />
                </td>
            ))}
    </>
)

export default SettingsProcedureCostList
