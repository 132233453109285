import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import {
    Expense,
    PostExpense,
    UpdateExpense,
    ResponseData,
    ResponseList,
    ResponseError,
} from 'api/types'

export const getExpenses = <T>(filters?: {}): Promise<T> =>
    axios.get('/expenses' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useGetExpenses = <T = ResponseList<Expense[]>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['expenses', filters],
        () => getExpenses<T>(filters),
        options
    )

export const useGetExpense = <T = Expense>(
    id: number,
    options?: UseQueryOptions<ResponseData<T>, ResponseError>
) =>
    useQuery<ResponseData<T>, ResponseError>(
        ['expenses', id],
        () => axios.get(`/expenses/${id}`),
        options
    )

export const createExpense = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/expenses', data)

export const useCreateExpense = <
    R1 = PostExpense,
    R2 = ResponseData<Expense>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createExpense<R1, R2>(data))

export const updateExpense = <R1, R2>(id: number, data: R1): Promise<R2> =>
    axios.patch('/expenses/' + id, data)

export const useUpdateExpense = <
    R1 extends Pick<UpdateExpense, 'id'> = Pick<UpdateExpense, 'id'> &
        Partial<UpdateExpense>,
    R2 = ResponseData<Expense>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateExpense<R1, R2>(data.id, data))

export const deleteExpense = <R>(id: number): Promise<R> =>
    axios.delete(`/expenses/${id}`)

export const useDeleteExpense = <R = Expense>(): UseMutationResult<
    R,
    ResponseError,
    number
> => useMutation((id) => deleteExpense<R>(id))
