import { useMutation, UseMutationResult } from 'react-query'

import axios from 'api/axios'
import { ResponseError, ForgotPassword, ResponseData } from 'api/types'

export const forgotPassword = (
    data: ForgotPassword
): Promise<ResponseData<ForgotPassword>> => axios.post('/forgot-password', data)

export const useForgotPassword = (): UseMutationResult<
    ResponseData<ForgotPassword>,
    ResponseError,
    ForgotPassword
> => useMutation((data) => forgotPassword(data))
