import React, { useMemo } from 'react'
import { useDrop } from 'react-dnd'
import moment from 'moment'
import classNames from 'classnames'

import { OPERATING_ROOM_START_HOUR } from 'constants/index'

import type { UseQueryResult } from 'react-query'
import type { ResponseData, SchedulePlanItem } from 'api/types'
import type { DragItem, OnDrop } from 'types/SchedulePlanner'

const classNameDropItemBase =
    'px-2 absolute inset-y-1 inset-x-2 border-2 border-transparent border-dashed'

const DropItem: React.FC<{
    row: number
    col: number
    date: Date
    roomId?: number
    planQueryResult?: UseQueryResult<ResponseData<SchedulePlanItem[]>>
    onDrop: OnDrop
}> = ({ row, col, date, roomId, planQueryResult, onDrop }) => {
    const canDrop = useMemo(() => {
        /* Revert the operation to the column of unassigned operations is always possible */
        if (typeof roomId === 'undefined') {
            return true
        }

        if (!planQueryResult) {
            return undefined
        }

        if (planQueryResult.isLoading || planQueryResult.isFetching) {
            return undefined
        }

        if (!planQueryResult.isSuccess) {
            return false
        }

        const formattedDate = moment(date)
            .set({
                hours: OPERATING_ROOM_START_HOUR,
                minutes: 0,
                seconds: 0,
                millisecond: 0,
            })
            .add(row * 15, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')

        return !!planQueryResult.data.data.find(
            (item) =>
                item.operating_room_id === roomId && item.date === formattedDate
        )
    }, [row, planQueryResult, date, roomId])

    const [{ isOver, item }, drop] = useDrop(
        () => ({
            accept: 'operation',
            drop: (item: DragItem) => {
                onDrop({
                    ...item,
                    row,
                    col,
                })
            },
            canDrop: () => !!canDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                item: monitor.getItem<{
                    id: number
                    estimatedDate: string
                    duration: number | null
                }>(),
            }),
        }),
        [row, col, canDrop]
    )

    const cols = useMemo(() => {
        if (col === 0) {
            return 15 * 4
        }

        if (item?.duration) {
            return Math.ceil(item.duration / 15)
        }

        return 1
    }, [col, item?.duration])

    if (isOver && !roomId && canDrop) {
        return (
            <li
                ref={drop}
                className="mt-px ml-0.5 relative flex"
                style={{
                    gridColumnStart: 1,
                    gridRow: `${row + 1} / span ${cols}`,
                }}
            >
                <div
                    className={classNames(
                        classNameDropItemBase,
                        'border-green-500'
                    )}
                ></div>
            </li>
        )
    }

    return (
        <li
            ref={drop}
            className="mt-px ml-0.5 relative flex"
            style={{
                gridColumnStart: col + 1,
                gridRow: `${row + 1} / span ${cols}`,
            }}
        >
            <div
                className={classNames(classNameDropItemBase, {
                    'border-gray-300': isOver && typeof canDrop === 'undefined',
                    'border-green-500': isOver && canDrop,
                    'border-red-400': isOver && canDrop === false,
                })}
            ></div>
        </li>
    )
}

export default DropItem
