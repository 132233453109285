import { z } from 'zod'

import { DropdownItem, UserDropdownItem } from 'components/forms'

export interface ISurgeryListFilters {
    length: number
    sort_by: string
    sort_direction: string
    query: string
    operator?: UserDropdownItem
    date_range: {
        from: Date | undefined
        to: Date | undefined
    }
    payer_type: DropdownItem | undefined
    procedure_types: DropdownItem[]
    actual_state: DropdownItem[]
    progress_status: DropdownItem[]
}

export const surgeryListFilterSchema = z.object({
    sort_by: z.union([
        z.literal('estimated_date'),
        z.literal('created_at'),
        z.literal('patient_last_name'),
    ]),
    sort_direction: z.union([z.literal('asc'), z.literal('desc')]),
    query: z.string(),
    operator: z
        .object({
            id: z.number(),
            first_name: z.string(),
            last_name: z.string(),
            avatar_url: z.string(),
        })
        .optional(),
    date_range: z.union([
        z.object({
            from: z.string().datetime(),
            to: z.string().datetime(),
        }),
        z.object({
            from: z.undefined(),
            to: z.undefined(),
        }),
    ]),
    procedure_types: z
        .array(z.object({ id: z.number(), name: z.string() }))
        .optional(),
    payer_type: z.object({ id: z.string(), name: z.string() }).optional(),
    actual_state: z
        .array(z.object({ id: z.string(), name: z.string() }))
        .optional(),
    progress_status: z
        .array(z.object({ id: z.string(), name: z.string() }))
        .optional(),
})
