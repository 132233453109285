import React from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'

import { Button, Label } from 'components/ui'
import { TextField } from 'components/forms'

import type { ResetPasswordForm, FormSubmitFn } from 'types'

import { ReactComponent as IconSuccess } from 'assets/icon-success.svg'
interface ResetPasswordProps {
    isSuccess: boolean
    token: string
    email: string
    handleSubmit: FormSubmitFn<ResetPasswordForm>
}

export default function ResetPassword({
    token,
    email,
    isSuccess,
    handleSubmit,
}: ResetPasswordProps) {
    if (isSuccess) {
        return (
            <div className="space-y-6 flex flex-col text-center items-center">
                <IconSuccess />
                <p className="mt-7 text-lg leading-6 font-medium">
                    Hasło zostało ustawione
                </p>
                <p className="mt-4 text-sm leading-5 font-normal text-gray-500">
                    Teraz możesz się zalogować, klikając w poniższy link.
                </p>
                <p className="mt-4">
                    <Link
                        to="/login"
                        className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
                    >
                        Zaloguj się
                    </Link>
                </p>
            </div>
        )
    }

    return (
        <Formik
            initialValues={{
                email: email || '',
                password: '',
                password_confirmation: '',
                token: token || '',
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors }) => (
                <Form className="space-y-6">
                    {!!errors.token && (
                        <p className="mt-1 text-sm text-red-600">
                            {errors.token}
                        </p>
                    )}
                    {!!errors.email && (
                        <p className="mt-1 text-sm text-red-600">
                            {errors.email}
                        </p>
                    )}
                    <div>
                        <Label>{email ? 'Nadaj hasło' : 'Nowe hasło'}</Label>
                        <TextField type="password" name="password" />
                    </div>

                    <div>
                        <Label>Powtórz hasło</Label>
                        <TextField
                            type="password"
                            name="password_confirmation"
                        />
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-full"
                            loading={isSubmitting}
                        >
                            Zapisz i przejdź do logowania
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
