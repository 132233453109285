import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import axios from 'api/axios'
import {
    ResponseList,
    ResponseError,
    ResponseData,
    ImplantManufacturer,
    PostImplantManufacturer,
    UpdateImplantManufacturerActive,
} from 'api/types'

export const getImplantManufacturers = <T>(filters?: {}): Promise<T> =>
    axios.get(
        '/implant-manufacturers' +
            (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useGetImplantManufacturers = <
    T = ResponseList<ImplantManufacturer[]>
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        ['implant-manufacturers', filters],
        () => getImplantManufacturers<T>(filters),
        options
    )

export const useGetImplantManufacturer = <
    T = ImplantManufacturer,
    E = ResponseError
>(
    id: number | string,
    options?: UseQueryOptions<ResponseData<T>, E>
) =>
    useQuery<ResponseData<T>, E>(
        ['implant-manufacturers', id],
        () => axios.get(`/implant-manufacturers/${id}`),
        options
    )

export const createImplantManufacturer = <R1, R2>(data: R1): Promise<R2> =>
    axios.post('/implant-manufacturers', data)

export const useCreateImplantManufacturer = <
    R1 = PostImplantManufacturer,
    R2 = ResponseData<ImplantManufacturer>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => createImplantManufacturer<R1, R2>(data))

export const updateImplantManufacturer = <R1, R2>(
    id: number,
    data: R1
): Promise<R2> => axios.patch('/implant-manufacturers/' + id, data)

export const useUpdateImplantManufacturer = <
    R1 extends ImplantManufacturer,
    R2 = ResponseData<ImplantManufacturer>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateImplantManufacturer<R1, R2>(data.id, data))

export const useUpdateImplantManufacturerActive = <
    R1 extends Pick<
        ImplantManufacturer,
        'id'
    > = UpdateImplantManufacturerActive,
    R2 = ResponseData<ImplantManufacturer>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => updateImplantManufacturer<R1, R2>(data.id, data))

export const deleteImplantManufacturer = <R>(id: number): Promise<R> =>
    axios.delete(`/implant-manufacturers/${id}`)

export const useDeleteImplantManufacturer = <
    R = ImplantManufacturer
>(): UseMutationResult<R, ResponseError, number> =>
    useMutation((id: number) => deleteImplantManufacturer<R>(id))
