/* eslint-disable */
import React from 'react'
import { DocumentTextIcon, TrashIcon } from '@heroicons/react/outline'

import { getCxFromStyles } from 'helpers'
import { Dropzone, Spinner } from 'components/ui'
import withUploader from 'HOC/withUploader'

import styles from './FileUploader.module.scss'

const cx = getCxFromStyles(styles)

export interface UploaderState {
    addedFile: {
        id: number
        url: string
    }
    response: unknown
    isUploading: boolean
    isSuccess: boolean
    progress: number
    error: string
}

const PhotoUploader = (props: {
    fileId: number
    className?: string
    hasError?: boolean
    handleRemovePhoto: () => void
    handleUploadSuccess: (state: UploaderState) => void
    uploader: {
        actions: any
        state: any
    }
}) => {
    const {
        fileId = 0,
        className,
        hasError,
        handleRemovePhoto,
        uploader: { actions, state },
    } = props

    const handleRemove = (e: React.MouseEvent) => {
        e.stopPropagation()
        actions.handleRemove()
        handleRemovePhoto()
    }

    return (
        <div className={cx('root', className)}>
            {!fileId && !state.isUploading && (
                <Dropzone
                    handleUpload={actions.handleUpload}
                    isMultiple={false}
                    hasError={hasError}
                    acceptFormats="image/jpeg,image/png,image/gif,application/pdf"
                />
            )}
            {state.isUploading && (
                <div
                    className={cx('fileUploader', {
                        uploading: true,
                    })}
                >
                    <div className="flex flex-col justify-center">
                        <div className={cx('progress')}>
                            <Spinner className="p-2 h-auto" />
                        </div>
                        <p className="mt-1 text-sm text-gray-500 text-center">
                            {state.progress}%
                        </p>
                    </div>
                </div>
            )}
            {!!fileId && (
                <div className={cx('fileUploader', 'uploaded')}>
                    <div className="self-center space-y-1 text-center">
                        <DocumentTextIcon className="mx-auto w-12 text-blue-500" />
                        <p className="text-md text-blue-600">
                            <span className="align-middle break-all">
                                {state.addedFile.title}{' '}
                            </span>
                            <TrashIcon
                                className="pl-1 inline-flex w-7 text-sm text-gray-500 align-middle hover:text-gray-700 cursor-pointer"
                                onClick={(e) => handleRemove(e)}
                            />
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default withUploader(PhotoUploader)

/* eslint-enable */
