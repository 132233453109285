import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const ANESTHESIOLOGIST_COSTS_PERMISSIONS = {
    index: PERMISSIONS.INDEX_ANESTHESIOLOGIST_COSTS,
    show: PERMISSIONS.SHOW_ANESTHESIOLOGIST_COSTS,
    create: PERMISSIONS.CREATE_ANESTHESIOLOGIST_COSTS,
    update: PERMISSIONS.UPDATE_ANESTHESIOLOGIST_COSTS,
    delete: PERMISSIONS.DELETE_ANESTHESIOLOGIST_COSTS,
}

export const useAnesthesiologistCostsPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(ANESTHESIOLOGIST_COSTS_PERMISSIONS),
    }
}
