import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

import { usePolicy } from 'hooks'
import { getCxFromStyles } from 'helpers'
import { SIDEBAR_ITEMS } from './SidebarItems'

import styles from './Sidebar.module.scss'

import type { Item, SubItem } from './SidebarItems'

interface SidebarProps {
    isSidebarOpen: boolean
}

const Sidebar = ({ isSidebarOpen }: SidebarProps) => {
    let location = useLocation()
    const navigate = useNavigate()
    const cx = getCxFromStyles(styles)
    const {
        user: { isDoctor: alwaysOpen },
        hasPermission,
    } = usePolicy()

    const [currentItem, setCurrentItem] = useState<number | null>(null)

    const isItemActive = useCallback(
        (item: Omit<SubItem, 'Gate'>) =>
            item.matches
                ? item.exact === true
                    ? item.matches.filter(
                          (match: string) => match === location.pathname
                      ).length > 0
                    : item.matches.filter((match: string) =>
                          location.pathname.startsWith(match)
                      ).length > 0
                : item.exact === true
                ? item.to === location.pathname
                : item.exclude
                ? location.pathname.startsWith(item.to) &&
                  !item.exclude.includes(location.pathname)
                : location.pathname.startsWith(item.to),
        [location]
    )

    useEffect(() => {
        setCurrentItem(null)
    }, [isSidebarOpen])

    const menu = useMemo(
        () =>
            SIDEBAR_ITEMS.filter(
                (menuItem) =>
                    !menuItem.permission || hasPermission(menuItem.permission)
            ),
        [hasPermission]
    )

    return (
        <div
            id="main-layout-sidebar"
            className={cx('root', 'print:hidden', {
                isExpanded: isSidebarOpen,
            })}
        >
            <header></header>

            <nav className={cx('nav')}>
                {menu.map((item, index) => {
                    const active = item.children.length
                        ? item.children.filter(isItemActive).length > 0
                        : isItemActive(item)

                    return (
                        <item.Gate key={index}>
                            <Disclosure
                                as="div"
                                key={item.name}
                                defaultOpen={
                                    alwaysOpen || currentItem === index
                                }
                                className={cx('item-container', 'space-y-1')}
                            >
                                {({ open }) =>
                                    item.children.length === 0 ? (
                                        <Link
                                            to={item.to}
                                            className={cx('navItem')}
                                        >
                                            {renderMenuItem({
                                                item,
                                                active,
                                            })}
                                        </Link>
                                    ) : (
                                        <>
                                            <div
                                                className={cx('navItem')}
                                                onClick={() => {
                                                    if (item.to !== '#') {
                                                        navigate(item.to)
                                                    }

                                                    if (isSidebarOpen) {
                                                        setCurrentItem(
                                                            (prevState) =>
                                                                prevState ===
                                                                index
                                                                    ? null
                                                                    : index
                                                        )
                                                    }
                                                }}
                                            >
                                                {renderMenuItem({
                                                    item,
                                                    active,
                                                })}
                                                {!alwaysOpen && (
                                                    <span
                                                        className={classNames(
                                                            'm-2 h-3 w-3 text-white'
                                                        )}
                                                    >
                                                        {currentItem ===
                                                            index && (
                                                            <ChevronDownIcon />
                                                        )}
                                                        {currentItem !==
                                                            index && (
                                                            <ChevronUpIcon />
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                            <Disclosure.Panel
                                                static
                                                className={cx('navItemPanel', {
                                                    closed:
                                                        !alwaysOpen &&
                                                        currentItem !== index,
                                                })}
                                            >
                                                {item.children.map(
                                                    ({ Gate, ...child }) => {
                                                        return (
                                                            <Gate
                                                                key={child.name}
                                                            >
                                                                {renderChild({
                                                                    index,
                                                                    child,
                                                                })}
                                                            </Gate>
                                                        )
                                                    }
                                                )}
                                            </Disclosure.Panel>
                                        </>
                                    )
                                }
                            </Disclosure>
                        </item.Gate>
                    )
                })}
            </nav>
        </div>
    )

    function renderMenuItem({ item, active }: { item: Item; active: boolean }) {
        return (
            <>
                <span
                    className={cx('icon-container', {
                        active,
                    })}
                >
                    <item.icon className={cx('icon')} />
                </span>
                <span className={cx('title')}>{item.name}</span>
            </>
        )
    }

    function renderChild({
        index,
        child,
    }: {
        index: number
        child: Omit<SubItem, 'Gate'>
    }) {
        return (
            <Link
                to={child.to}
                className={cx('navItem', 'subItem', {
                    active: isItemActive(child),
                })}
                onClick={() => {
                    setCurrentItem(index)
                }}
            >
                <span className={cx('title')}>{child.name}</span>
            </Link>
        )
    }
}

export default Sidebar
