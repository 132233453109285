import { useContext, useRef } from 'react'
import { useQueryClient } from 'react-query'

import PusherContext from 'contexts/PusherContext'
import { useNotificationQueue } from 'contexts/NotificationProvider'
import { useCurrentUser } from 'hooks'

import type { Channel } from 'pusher-js'
import type { Notification } from 'contexts/NotificationProvider'

export const useNotificationChannel = () => {
    const queryClient = useQueryClient()
    const queue = useNotificationQueue()
    const { pusher } = useContext(PusherContext)
    const user = useCurrentUser()

    const channelName = 'private-notifications-' + (user?.id || '')
    const eventName =
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
    const channel = useRef<Channel>()

    const connectToNotificationChannel = () => {
        channel.current = pusher!.current?.subscribe(channelName)

        return {
            channel,
            onNotificationEvent,
            handleNotificationCreated,
            closeChannel: () => {
                channel.current?.unbind(eventName)
                channel.current?.unsubscribe()
            },
        }
    }

    const onNotificationEvent = (callback: (payload: Notification) => void) => {
        channel.current?.bind(eventName, callback)
    }

    const handleNotificationCreated = async (payload: Notification) => {
        await queryClient.invalidateQueries(['notifications'])
        queue.add('notification-' + payload.id, {
            ...payload,
            receivedAt: new Date(),
        })
    }

    return {
        connectToNotificationChannel,
    }
}
