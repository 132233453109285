import React, { useRef } from 'react'
import { Formik, Form } from 'formik'
import { Dialog } from '@headlessui/react'

import { getCxFromStyles } from 'helpers'
import { Modal } from 'components'
import { Button } from 'components/ui'

import styles from './Modal.module.scss'

import type { FormSubmitFn, IModal, CancelDeleteModalForm } from 'types'
import { useEventListener } from 'hooks'

const ConfirmDeleteModal: React.FC<{
    modal: IModal
    title: string
    onSubmit: FormSubmitFn<CancelDeleteModalForm>
    onCancel: () => void
}> = ({ modal, title, onSubmit, onCancel }) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const cx = getCxFromStyles(styles)

    const handleMouseDown = (e: Event) => {
        if (modal.isOpen) {
            e.stopImmediatePropagation()
        }
    }

    useEventListener('mousedown', handleMouseDown, [modal.isOpen])

    return (
        <Modal ref={modalRef} modal={modal} onClickOutside={() => {}}>
            <div className={cx('inner', 'p-6')}>
                <Dialog.Title as="h3" className={cx('title')}>
                    {title}
                </Dialog.Title>
                <Formik
                    initialValues={{
                        id: modal.getState(),
                    }}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mt-10">
                                <div className={cx('action-container')}>
                                    <Button
                                        tabIndex={-1}
                                        variant="danger"
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        Usuń
                                    </Button>
                                    <Button
                                        tabIndex={-1}
                                        variant="default"
                                        type="button"
                                        onClick={onCancel}
                                    >
                                        Nie
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

export default ConfirmDeleteModal
