import React from 'react'
import { Formik, Form } from 'formik'
import classNames from 'classnames'
import { PlusIcon } from '@heroicons/react/solid'

import ListLayout from 'layouts/ListLayout'
import { Button } from 'components/ui'
import { TextAreaField, TextField } from 'components/forms'

import type { Role, Permission, ResponseData } from 'api/types'
import type { FormSubmitFn } from 'types'
import type { IRoleForm } from 'types/RoleForm'

const SettingsRoleForm: React.FC<{
    data?: ResponseData<Role>
    permissions: Permission[]
    handleSubmit: FormSubmitFn<IRoleForm>
}> = ({ data, permissions, handleSubmit }) => {
    return (
        <Formik
            initialValues={
                {
                    name: data?.data.name || '',
                    description: data?.data.description || '',
                    permissions:
                        data?.data.permissions.map((item) => item.id) || [],
                } as IRoleForm
            }
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                    <ListLayout
                        renderTitle={
                            <>
                                <span className="font-semibold">
                                    {data ? 'Edytuj:' : 'Dodaj rolę'}
                                </span>
                                {!!data && <span> {data.data.name}</span>}
                            </>
                        }
                        actions={
                            <>
                                <Button as="link" to="/settings/roles">
                                    Anuluj
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="ml-3"
                                    loading={isSubmitting}
                                    iconRight={<PlusIcon />}
                                >
                                    Zapisz
                                </Button>
                            </>
                        }
                    >
                        <div className="space-y-6">
                            <div className="mb-4 px-6 py-12 rounded-md bg-white">
                                <div>
                                    <TextField
                                        name="name"
                                        labelText="Nazwa roli"
                                    />
                                </div>
                                <div className="mt-4">
                                    <TextAreaField
                                        name="description"
                                        labelText="Opis"
                                        rows={4}
                                    />
                                </div>
                            </div>
                            <div className="mb-4 px-6 py-12 rounded-md bg-white">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Nazwa
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-2 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                            >
                                                <span className="align-middle">
                                                    Dostęp
                                                </span>
                                                <span className="pl-2 align-middle">
                                                    <input
                                                        type="checkbox"
                                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                                        checked={
                                                            values.permissions
                                                                .length ===
                                                            permissions.length
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                values
                                                                    .permissions
                                                                    .length ===
                                                                permissions.length
                                                            ) {
                                                                return setFieldValue(
                                                                    'permissions',
                                                                    []
                                                                )
                                                            }

                                                            setFieldValue(
                                                                'permissions',
                                                                permissions.map(
                                                                    (item) =>
                                                                        item.id
                                                                )
                                                            )
                                                        }}
                                                    />
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-900">
                                        {permissions.map((item, index) => (
                                            <tr
                                                key={item.id}
                                                className={classNames({
                                                    'bg-gray-50':
                                                        index % 2 === 1,
                                                })}
                                            >
                                                <td className="px-6 py-3">
                                                    <span className="font-medium">
                                                        {item.name}
                                                    </span>
                                                </td>
                                                <td className="px-1 py-1 text-center">
                                                    <input
                                                        type="checkbox"
                                                        className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
                                                        checked={values.permissions.includes(
                                                            item.id
                                                        )}
                                                        onChange={() => {
                                                            if (
                                                                values.permissions.includes(
                                                                    item.id
                                                                )
                                                            ) {
                                                                setFieldValue(
                                                                    'permissions',
                                                                    values.permissions.filter(
                                                                        (id) =>
                                                                            id !==
                                                                            item.id
                                                                    )
                                                                )
                                                            } else {
                                                                setFieldValue(
                                                                    'permissions',
                                                                    values.permissions.concat(
                                                                        item.id
                                                                    )
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ListLayout>
                </Form>
            )}
        </Formik>
    )
}

export default SettingsRoleForm
