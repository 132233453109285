import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { PlusIcon } from '@heroicons/react/outline'

import { FormSubmitFn } from 'types'
import {
    Implant,
    ImplantManufacturer,
    ImplantProcedure,
    ResponseData,
} from 'api/types'
import { DropdownField, PriceField, TextField } from 'components/forms'
import { Button, CancelButton, Label, Toggle } from 'components/ui'
import { ProcedureFieldArray } from 'components/SettingsImplantSetForm'

export interface FormValues
    extends Omit<Implant, 'id' | 'surcharge' | 'manufacturer'> {
    manufacturer?: ImplantManufacturer
}

interface SettingsImplantFormProps {
    id?: string
    data?: ResponseData<Implant>
    manufacturers: ResponseData<ImplantManufacturer[]>
    handleSubmit: FormSubmitFn<FormValues>
}

const SettingsImplantForm: React.FC<SettingsImplantFormProps> = ({
    id,
    data,
    manufacturers,
    handleSubmit,
}) => {
    const initialValues: FormValues = useMemo(
        () =>
            data?.data
                ? {
                      manufacturer: data.data.manufacturer,
                      name: data.data.name,
                      label: data.data.label,
                      price: data.data.price,
                      is_being_manufactured: data.data.is_being_manufactured,
                      procedures: data.data.procedures.map((item) => ({
                          id: item.id,
                          name: item.name,
                      })),
                      _procedure_item: undefined,
                      _procedure_item_phrase: '',
                  }
                : {
                      manufacturer: undefined,
                      name: '',
                      label: '',
                      price: 0,
                      is_being_manufactured: false,
                      procedures: [] as ImplantProcedure[],
                      _procedure_item: undefined,
                      _procedure_item_phrase: '',
                  },
        [data]
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnChange={false}
        >
            {({ isSubmitting, values, errors, setFieldValue }) => (
                <div className="py-6">
                    <div className="px-4 mb-4 sm:px-6 md:px-8">
                        <div className="flex gap-4 items-center justify-between">
                            <div className="flex-none rounded-lg flex items-center justify-center">
                                <h2 className="text-2xl leading-8">
                                    {!id && (
                                        <span className="font-semibold">
                                            Dodaj:
                                        </span>
                                    )}
                                    {!!id && (
                                        <span className="font-semibold">
                                            Edytuj:
                                        </span>
                                    )}
                                    &nbsp;
                                    <span className="font-light">Implant</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mb-6 sm:px-6 md:px-8">
                        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                            <section aria-labelledby="payment-details-heading">
                                <Form>
                                    <div className="sm:rounded-md sm:overflow-hidden">
                                        <div className="bg-white pt-12 px-6">
                                            <div className="border-b">
                                                <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                                    <div className="col-span-6">
                                                        <div className="pr-8">
                                                            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                                Operacja
                                                            </div>
                                                            <div className="mt-4">
                                                                <TextField
                                                                    labelText="Nazwa własna"
                                                                    name="label"
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <TextField
                                                                    labelText="Nazwa producenta"
                                                                    name="name"
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <DropdownField
                                                                    label="Producent"
                                                                    name="manufacturer"
                                                                    items={
                                                                        manufacturers.data
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <Label>
                                                                    Cena
                                                                </Label>
                                                                <PriceField
                                                                    placeholder="0,00"
                                                                    name="price"
                                                                />
                                                            </div>
                                                            <div className="mt-4">
                                                                <Label>
                                                                    Implant
                                                                    produkowany
                                                                </Label>
                                                                <div className="mt-3">
                                                                    <Toggle
                                                                        checked={
                                                                            values.is_being_manufactured
                                                                        }
                                                                        handleChange={(
                                                                            value
                                                                        ) =>
                                                                            setFieldValue(
                                                                                'is_being_manufactured',
                                                                                !values.is_being_manufactured
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6">
                                                        <div className="pl-8">
                                                            <div className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                                                Występowanie
                                                            </div>
                                                            <div className="mt-4">
                                                                <Label>
                                                                    Operacja
                                                                </Label>
                                                                <ProcedureFieldArray name="procedures" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white px-6 py-12 text-right">
                                            <CancelButton
                                                as="link"
                                                to="/settings/price-list/implants"
                                            >
                                                Anuluj
                                            </CancelButton>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="ml-3"
                                                loading={isSubmitting}
                                                iconRight={<PlusIcon />}
                                            >
                                                Zapisz
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </section>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    )
}

export default SettingsImplantForm
