import React, { useState } from 'react'
import classNames from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

import { StatisticsOperationResponse } from 'api/types'
import { roundedPrice } from '../helpers'

const StatisticsSurgeryMedicalEquipment: React.FC<{
    data: StatisticsOperationResponse
}> = ({ data }) => {
    const [medicalEquipmentExpanded, setMedicalEquipmentExpanded] =
        useState(true)

    return (
        <div>
            <div className="text-lg leading-6 font-medium flex items-center justify-between">
                Sprzęt medyczny
                <button
                    className="px-3"
                    onClick={() =>
                        setMedicalEquipmentExpanded(!medicalEquipmentExpanded)
                    }
                >
                    <ChevronDownIcon
                        className={classNames(
                            'w-4 text-blue-500',
                            !medicalEquipmentExpanded && 'transform rotate-180'
                        )}
                    />
                </button>
            </div>

            {medicalEquipmentExpanded && (
                <div className="flex flex-col gap-0.5 mt-6">
                    {!data.equipment || data.equipment.length === 0
                        ? '-'
                        : data.equipment?.map((item) => (
                              <div
                                  key={item.id}
                                  className="p-2 bg-gray-100 flex items-center justify-between text-gray-900 text-sm leading-5 font-normal"
                              >
                                  <span>{item.name}</span>
                                  <span className="leading-none py-1.5 px-3 inline-block rounded-md bg-white border border-gray-300 shadow-sm">
                                      {roundedPrice(item.price)}
                                  </span>
                              </div>
                          ))}
                </div>
            )}
        </div>
    )
}

export default StatisticsSurgeryMedicalEquipment
