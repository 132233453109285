import React from 'react'

import {
    useCaregiverPanelPolicy,
    useDoctorSurgeryPolicyGroup,
    useScheduleApprovedPolicyGroup,
    usePatientPolicy,
    useScheduleActions,
    useSurgeryPolicy,
    useSurgeryPolicyGroup,
    useOperationActions,
} from 'hooks'
import { Button, Button3dots } from 'components/ui'
import Menu3dots from 'components/Menu3dots'
import OperationRejectModal from 'components/OperationRejectModal'
import OperationRenewModal from 'components/OperationRenewModal'
import OperationCancelModal from 'components/OperationCancelModal'

import type { OperationListItem } from 'types'

const SurgeryListItemMenu3dots: React.FC<{
    item: OperationListItem
    showHistoryModal: () => void
}> = ({ item, showHistoryModal }) => {
    const caregiverPanelPolicy = useCaregiverPanelPolicy()
    const surgeryPolicyGroup = useSurgeryPolicyGroup()
    const surgeryPolicy = useSurgeryPolicy()
    const doctorSurgeryPolicyGroup = useDoctorSurgeryPolicyGroup()
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const patientPolicy = usePatientPolicy()
    const scheduleActions = useScheduleActions('operation')
    const operationActions = useOperationActions()

    const canView = caregiverPanelPolicy.canIndex

    const canReject =
        scheduleActions.rejectedId !== item.id &&
        item.can_be_rejected &&
        surgeryPolicyGroup.canReject

    const canRenew = surgeryPolicyGroup.canRenew

    const isMutating = scheduleActions.isMutating(item.id)

    return (
        <>
            <div className="flex align-center justify-end space-x-4">
                <Button
                    as="link"
                    to={`/caregiver`}
                    size="sm"
                    state={{ id: item.id }}
                    className="hidden md:block"
                    disabled={!canView}
                >
                    Zadania
                </Button>
                <Menu3dots>
                    {({ open }) => (
                        <>
                            <Menu3dots.Button
                                as={isMutating ? React.Fragment : 'span'}
                            >
                                <Button3dots
                                    variant="tertiary"
                                    size="sm"
                                    hovered={open}
                                    loading={isMutating}
                                />
                            </Menu3dots.Button>
                            <Menu3dots.Items>
                                <div className="border-b border-gray-100 py-1 md:hidden">
                                    <Menu3dots.Item
                                        as="link"
                                        to={`/caregiver`}
                                        state={{
                                            id: item.id,
                                        }}
                                        disabled={!canView}
                                    >
                                        Zadania
                                    </Menu3dots.Item>
                                </div>
                                <div className="divide-y divide-gray-200">
                                    <div className="py-1">
                                        <Menu3dots.Item
                                            as="link"
                                            to={`/caregiver/surgeries/${item.id}/edit`}
                                            disabled={!surgeryPolicy.canUpdate}
                                        >
                                            Edytuj operację
                                        </Menu3dots.Item>
                                    </div>
                                    <div className="py-1">
                                        <Menu3dots.Item
                                            as="link"
                                            to={`/surgeries/${item.id}/card`}
                                            disabled={
                                                !doctorSurgeryPolicyGroup.canShow
                                            }
                                        >
                                            Karta operacji
                                        </Menu3dots.Item>
                                        <Menu3dots.Item
                                            as="link"
                                            to={`/patients/${item.patient.id}/edit`}
                                            disabled={!patientPolicy.canUpdate}
                                        >
                                            Karta pacjenta
                                        </Menu3dots.Item>
                                        <Menu3dots.Item
                                            onClick={() =>
                                                operationActions.downloadEstimationPdf(
                                                    item.id
                                                )
                                            }
                                            disabled={
                                                !item.payer?.estimates_available
                                            }
                                        >
                                            Pobierz kosztorys
                                        </Menu3dots.Item>
                                        <Menu3dots.Item
                                            as="link"
                                            to="/schedule"
                                            state={{
                                                date: item.estimated_date,
                                                scheduleId: item.id,
                                            }}
                                            disabled={
                                                !scheduleApprovedPolicyGroup.canShow
                                            }
                                        >
                                            Plan operacyjny
                                        </Menu3dots.Item>
                                        {surgeryPolicy.canSeeHistory && (
                                            <Menu3dots.Item
                                                onClick={() =>
                                                    showHistoryModal()
                                                }
                                            >
                                                Historia uwag
                                            </Menu3dots.Item>
                                        )}
                                    </div>
                                    <div className="py-1">
                                        <Menu3dots.RejectOrRenew
                                            item={item}
                                            canReject={canReject}
                                            canRenew={canRenew}
                                            onReject={() =>
                                                scheduleActions.handleReject(
                                                    item.id
                                                )
                                            }
                                            onRenew={() =>
                                                scheduleActions.handleRenew(
                                                    item
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </Menu3dots.Items>
                        </>
                    )}
                </Menu3dots>
            </div>
            <OperationRejectModal
                modal={scheduleActions.modalReject}
                onSubmit={scheduleActions.handleRejectFormSubmit}
                onCancel={scheduleActions.handleResignRejecting}
                onClickOutside={scheduleActions.modalReject.closeModal}
            />
            <OperationRenewModal
                modal={scheduleActions.modalRenew}
                onSubmit={scheduleActions.handleRenewFormSubmit}
                onCancel={scheduleActions.handleResignRenewing}
                onClickOutside={scheduleActions.modalRenew.closeModal}
            />
            <OperationCancelModal
                modal={scheduleActions.modalCancel}
                onSubmit={scheduleActions.handleCancelFormSubmit}
                onCancel={scheduleActions.handleResignCancelling}
                onClickOutside={scheduleActions.modalCancel.closeModal}
            />
        </>
    )
}

export default SurgeryListItemMenu3dots
