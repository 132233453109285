import React from 'react'
import classNames from 'classnames'
import { BellIcon } from '@heroicons/react/outline'
import { BellIcon as BellSolidIcon } from '@heroicons/react/solid'

import NotificationListSlideOver from 'components/NotificationListSlideOver'
import NotificationListContainer from 'containers/NotificationList'
import { useModal, useNotificationChannelPolicy } from 'hooks'

const NotificationBellContainer = () => {
    const notificationChannelPolicy = useNotificationChannelPolicy()
    const modalNotification = useModal()

    if (!notificationChannelPolicy.canView) {
        return null
    }

    return (
        <NotificationListContainer>
            {({ queryResult }) => {
                const hasNotifications =
                    queryResult.isSuccess && queryResult.data.data.length > 0

                return (
                    <>
                        <button
                            type="button"
                            className={classNames(
                                'ml-3 bg-white p-1 rounded-full focus:outline-none',
                                {
                                    'text-gray-400 hover:text-gray-500':
                                        !hasNotifications,
                                    'text-red-500 hover:text-red-600':
                                        hasNotifications,
                                    'ring-2 ring-offset-2 ring-gray-400':
                                        modalNotification.isOpen &&
                                        !hasNotifications,
                                    'bg-gray-100':
                                        modalNotification.isOpen &&
                                        hasNotifications,
                                }
                            )}
                            onClick={() => {
                                modalNotification.openModal()
                            }}
                        >
                            <span className="sr-only">View notifications</span>
                            {!hasNotifications && (
                                <BellIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            )}
                            {hasNotifications && (
                                <BellSolidIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            )}
                        </button>

                        <NotificationListSlideOver
                            queryResult={queryResult}
                            modal={modalNotification}
                        />
                    </>
                )
            }}
        </NotificationListContainer>
    )
}

export default NotificationBellContainer
