import React from 'react'
import { Navigate } from 'react-router-dom'

import {
    useScheduleApprovedPolicyGroup,
    useSurgeryPolicy,
    useLiveViewPolicyGroup,
} from 'hooks'
import { AccessDenied } from 'components'

const Main = () => {
    const scheduleApprovedPolicyGroup = useScheduleApprovedPolicyGroup()
    const surgeryPolicy = useSurgeryPolicy()
    const liveViewPolicyGroup = useLiveViewPolicyGroup()

    if (scheduleApprovedPolicyGroup.canIndex) {
        return <Navigate to="/schedule" />
    }

    if (surgeryPolicy.canIndex) {
        return <Navigate to="/surgeries" />
    }

    if (liveViewPolicyGroup.canIndex) {
        return <Navigate to="/live-view" />
    }

    return (
        <AccessDenied message="Nie masz wystarczających uprawnień do systemu" />
    )
}

export default Main
