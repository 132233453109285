import {
    useMutation,
    UseMutationResult,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import qs from 'qs'

import client from 'api/axios'
import type {
    IdentificationCard,
    PostIdentificationCard,
    ResponseData,
    ResponseError,
    ResponseList,
} from 'api/types'

export const useGetIdentificationCards = <
    Response extends ResponseList<IdentificationCard[]>
>(
    filters?: {},
    options?: UseQueryOptions<Response, ResponseError>
): UseQueryResult<Response, ResponseError> =>
    useQuery<Response, ResponseError>(
        ['identification-cards', filters],
        () =>
            client.get(
                '/identification-cards' +
                    (filters ? '?' + qs.stringify(filters, {}) : '')
            ),
        options
    )

export const useGetIdentificationCard = <
    Request extends { id?: number },
    Response extends ResponseData<IdentificationCard>
>(
    data: Request,
    options?: UseQueryOptions<Response, ResponseError>
): UseQueryResult<Response, ResponseError> =>
    useQuery<Response, ResponseError>(
        ['identification-cards', data.id],
        () => client.get('/identification-cards/' + data.id),
        options
    )

export const usePostIdentificationCard = <
    R1 extends PostIdentificationCard,
    R2 = ResponseData<{ id: number }>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/identification-cards', data))

export const usePatchIdentificationCard = <
    R1 extends { id: number; data: Partial<PostIdentificationCard> },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id, data }) =>
        client.patch('/identification-cards/' + id, data)
    )

export const useDeleteIdentificationCard = <
    R1 extends { id: number },
    R2 = ResponseData<unknown>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation(({ id }) => client.delete('/identification-cards/' + id))
