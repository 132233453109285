import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const PROCEDURE_TYPE_PERMISSIONS = {
    index: PERMISSIONS.INDEX_PROCEDURE_TYPES,
    show: PERMISSIONS.SHOW_PROCEDURE_TYPES,
    create: PERMISSIONS.CREATE_PROCEDURE_TYPES,
    update: [
        PERMISSIONS.SHOW_PROCEDURE_TYPES,
        PERMISSIONS.UPDATE_PROCEDURE_TYPES,
    ],
    delete: PERMISSIONS.DELETE_PROCEDURE_TYPES,
}

export const useProcedureTypePolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(PROCEDURE_TYPE_PERMISSIONS),
    }
}
