import { useMutation, UseMutationResult } from 'react-query'

import client from 'api/axios'

import type { ResponseData, ResponseError } from 'api/types'

type PostResendLinkRequest = {
    user_id: number
}

export const usePostResendLink = <
    R1 extends PostResendLinkRequest,
    R2 = ResponseData<{ message: string }>
>(): UseMutationResult<R2, ResponseError, R1> =>
    useMutation((data) => client.post('/resend-link', data))
