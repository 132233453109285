import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'
export const useScheduleSMSPolicy = () => {
    const { user, hasPermission } = usePolicy()

    return {
        user,
        canSend: hasPermission(PERMISSIONS.SEND_SCHEDULE_SMS),
    }
}
