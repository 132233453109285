import React, { FC, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import type { UseQueryResult } from 'react-query'
import { twMerge } from 'tailwind-merge'

import type { ResponseData, SchedulePlanItem } from 'api/types'
import type { DragItem, OnDrop } from 'types/SchedulePlanner'

export interface DailyDropItemProps {
    row: number
    col: number
    roomId?: number
    afterOperationId?: number
    planQueryResult?: UseQueryResult<ResponseData<SchedulePlanItem[]>>
    onDrop?: OnDrop
}

export const DailyDropItem: FC<DailyDropItemProps> = ({
    roomId,
    planQueryResult,
    onDrop,
    row,
    col,
    afterOperationId,
}) => {
    const canDrop = useMemo(() => {
        if (!planQueryResult) {
            return undefined
        }

        if (planQueryResult.isLoading || planQueryResult.isFetching) {
            return undefined
        }

        if (!planQueryResult.isSuccess) {
            return false
        }

        if (typeof roomId === 'undefined') {
            return true
        }

        return (
            planQueryResult.data.data
                .filter((item) => item.operating_room_id === roomId)
                .filter(
                    (item) =>
                        !item.date.endsWith('07:00:00') &&
                        !item.date.endsWith('07:15:00') &&
                        !item.date.endsWith('07:30:00') &&
                        !item.date.endsWith('07:45:00')
                ).length > 0
        )
    }, [planQueryResult, roomId])

    const [{ isOver, dragItem }, drop] = useDrop(
        () => ({
            accept: 'operation',
            drop: (item: DragItem) => {
                onDrop?.({
                    ...item,
                    row,
                    col,
                    afterOperationId,
                })
            },
            canDrop: (item) =>
                !!canDrop &&
                (item.prev?.col !== col ||
                    (item.prev?.col === col &&
                        item.prev?.row !== row &&
                        item.prev?.row !== row - 1)),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                dragItem: monitor.getItem(),
            }),
        }),
        [canDrop, row, col]
    )

    const isLine = dragItem?.prev?.col === col && !!roomId
    const isFullHeight = !isLine && row === 0
    const isAllowed =
        isOver &&
        !!canDrop &&
        (dragItem?.prev?.col !== col ||
            (dragItem?.prev?.col === col &&
                dragItem?.prev?.row !== row &&
                dragItem?.prev?.row !== row - 1))
    const isDenied = isOver && canDrop === false

    return (
        <div className={twMerge(isLine && 'relative')}>
            <div
                ref={drop}
                className={twMerge(
                    'm-1',
                    isFullHeight &&
                        'absolute top-0 right-0 bottom-0 left-0 border-dashed border-gray-300',
                    isLine &&
                        'absolute h-40 -top-20 w-full flex items-center justify-center',
                    !!dragItem && isFullHeight && 'border-2',
                    isAllowed && 'border-green-500',
                    isDenied && 'border-red-400'
                )}
            >
                <div
                    className={twMerge(
                        isLine &&
                            'border-2 border-dashed border-gray-300 w-full mb-4 mr-1',
                        isLine && row === 0 && 'mb-1',
                        isAllowed && 'border-green-500',
                        isDenied && 'border-red-400'
                    )}
                ></div>
            </div>
        </div>
    )
}

export default DailyDropItem
