import { usePolicy } from 'hooks'
import { PERMISSIONS } from 'constants/index'

export const IDENTIFICATION_CARD_PERMISSIONS = {
    index: PERMISSIONS.INDEX_IDENTIFICATION_CARDS,
    show: PERMISSIONS.SHOW_IDENTIFICATION_CARDS,
    create: PERMISSIONS.CREATE_IDENTIFICATION_CARDS,
    update: PERMISSIONS.UPDATE_IDENTIFICATION_CARDS,
    delete: PERMISSIONS.DELETE_IDENTIFICATION_CARDS,
}

export const useIdentificationCardPolicy = () => {
    const { user, checkPermissions } = usePolicy()

    return {
        user,
        ...checkPermissions(IDENTIFICATION_CARD_PERMISSIONS),
    }
}
