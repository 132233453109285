import React from 'react'
import { Formik, Form } from 'formik'
import { PlusIcon, CalendarIcon } from '@heroicons/react/solid'

import { getDurationArray } from 'helpers'
import { useModal, useNotification } from 'hooks'
import { OPERATION_TYPES } from 'constants/index'
import { validateProcedureTypeTask } from 'api'
import { MODAL_TRANSITION_LEAVE_DURATION } from 'components'
import { DropdownField, TextField } from 'components/forms'
import { Button, CancelButton, SlideOver } from 'components/ui'
import SettingsProcedureTypeTaskForm from 'components/SettingsProcedureTypeTaskForm'
import ConfirmDeleteModal from 'components/ConfirmDeleteModal'

import { FormSubmitFn } from 'types'
import type {
    GetProcedureTypeResponse,
    ProcedureTypeForm,
    ProcedureTypeTaskForm,
} from 'api/types'

export default function SettingsProcedureType({
    id,
    data,
    handleSubmit,
}: {
    id?: number
    data?: GetProcedureTypeResponse
    handleSubmit: FormSubmitFn<ProcedureTypeForm>
}) {
    return (
        <div className="py-6">
            <div className="px-4 mb-4 sm:px-6 md:px-8">
                <div className="flex gap-4 items-center justify-between">
                    <div className="flex-none rounded-lg flex items-center justify-center">
                        <h2 className="text-2xl leading-8">
                            {!id && (
                                <span className="font-semibold">Dodaj:</span>
                            )}
                            {!!id && (
                                <span className="font-semibold">Edytuj:</span>
                            )}
                            &nbsp;
                            <span className="font-light">Rodzaj zabiegu</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="px-4 mb-6 sm:px-6 md:px-8">
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <SettingsProcedureTypeForm
                        data={data}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

function SettingsProcedureTypeForm({
    data,
    handleSubmit,
}: {
    data?: GetProcedureTypeResponse
    handleSubmit: FormSubmitFn<ProcedureTypeForm>
}) {
    const showNotification = useNotification()
    const modalTask = useModal<ProcedureTypeTaskForm & { _index: number }>()
    const modalConfirmDelete = useModal()
    const cleaningTime = getDurationArray(3, 15)

    return (
        <Formik<ProcedureTypeForm>
            initialValues={
                data
                    ? {
                          ...data.data,
                          type: OPERATION_TYPES.find(
                              (t) => t.id === data.data.type
                          ),
                          cleaning_time: cleaningTime.find(
                              (t) => t.id === data.data.cleaning_time
                          ),
                      }
                    : {
                          name: '',
                          type: undefined,
                          cleaning_time: undefined,
                          tasks: [],
                      }
            }
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <div className="sm:rounded-md">
                        <div className="bg-white pt-12 px-6">
                            <div className="border-b">
                                <div className="grid grid-cols-12 text-gray-700 divide-gray-200 divide-x mb-8">
                                    <div className="col-span-4">
                                        <div className="pr-8">
                                            <div className="mb-5 text-lg leading-6 font-medium text-gray-900">
                                                Rodzaj zabiegu
                                            </div>
                                            <div className="mb-4">
                                                <TextField
                                                    name="name"
                                                    labelText="Nazwa"
                                                />
                                            </div>
                                            <div className="divide-gray-200 divide-y">
                                                <div className="mb-12">
                                                    <DropdownField
                                                        label="Typ zabiegu"
                                                        name="type"
                                                        items={OPERATION_TYPES}
                                                    />
                                                </div>
                                                <div className="pt-8">
                                                    <DropdownField
                                                        label="Czas sprzątania sali"
                                                        name="cleaning_time"
                                                        items={cleaningTime}
                                                        bulkOption={true}
                                                        bulkOptionName="0min"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-8">
                                        <div className="pl-8">
                                            <div className="mb-5 flex justify-between">
                                                <p className="text-lg leading-6 font-medium text-gray-900">
                                                    Lista zadań
                                                </p>
                                                <p>
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        onClick={() =>
                                                            modalTask.openModal()
                                                        }
                                                    >
                                                        <span>
                                                            Dodaj zadanie
                                                        </span>
                                                        <PlusIcon
                                                            className="ml-2 -mr-0.5 h-4 w-4"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </p>
                                            </div>
                                            <TaskList
                                                tasks={values.tasks}
                                                handleOpen={(task, index) => {
                                                    modalTask.setState({
                                                        ...task,
                                                        _index: index,
                                                    })
                                                    modalTask.openModal()
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white px-6 py-12 text-right">
                            <CancelButton
                                as="link"
                                to="/settings/procedure-types"
                            >
                                Anuluj
                            </CancelButton>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                iconRight={<PlusIcon />}
                                loading={isSubmitting}
                            >
                                Zapisz
                            </Button>
                        </div>
                    </div>
                    <SlideOver
                        isOpen={modalTask.isOpen}
                        onClose={modalTask.closeModal}
                        onClickOutside={modalTask.closeModal}
                    >
                        <SettingsProcedureTypeTaskForm
                            data={modalTask.getState()}
                            handleClose={modalTask.closeModal}
                            handleDelete={() => {
                                const index = Number(
                                    modalTask.getState()?._index
                                )

                                if (!isNaN(index)) {
                                    modalConfirmDelete.setState(index)
                                    return modalConfirmDelete.openModal()
                                }
                            }}
                            handleSubmit={(task, formikHelpers) => {
                                const index = Number(
                                    modalTask.getState()?._index
                                )

                                validateProcedureTypeTask(task)
                                    .then(
                                        () => {
                                            if (!isNaN(index)) {
                                                setFieldValue(
                                                    'tasks',
                                                    values.tasks.map(
                                                        (prevTask, i) =>
                                                            i === index
                                                                ? Object.entries(
                                                                      task
                                                                  )
                                                                      .filter(
                                                                          ([
                                                                              key,
                                                                              _,
                                                                          ]) =>
                                                                              key !==
                                                                              '_index'
                                                                      )
                                                                      .reduce(
                                                                          (
                                                                              acc,
                                                                              [
                                                                                  key,
                                                                                  value,
                                                                              ]
                                                                          ) => ({
                                                                              ...acc,
                                                                              [key]: value,
                                                                          }),
                                                                          {}
                                                                      )
                                                                : prevTask
                                                    )
                                                )
                                            } else {
                                                setFieldValue(
                                                    'tasks',
                                                    values.tasks.concat(task)
                                                )
                                            }

                                            modalTask.closeModal()
                                        },
                                        (error) => {
                                            formikHelpers.setErrors(
                                                error.errors
                                            )
                                        }
                                    )
                                    .finally(() => {
                                        formikHelpers.setSubmitting(false)
                                    })
                            }}
                        />
                    </SlideOver>
                    <ConfirmDeleteModal
                        title="Czy na pewno chcesz usunąć to zadanie?"
                        modal={modalConfirmDelete}
                        onSubmit={(_, formikHelpers) => {
                            formikHelpers.setSubmitting(false)

                            setFieldValue(
                                'tasks',
                                values.tasks.filter(
                                    (_, index) =>
                                        index !== modalConfirmDelete.getState()
                                )
                            )

                            showNotification({
                                content:
                                    'Aby zachować zmiany, kliknij "Zapisz"',
                                type: 'warning',
                            })

                            modalConfirmDelete.closeModal()
                            setTimeout(
                                modalTask.closeModal,
                                MODAL_TRANSITION_LEAVE_DURATION
                            )
                        }}
                        onCancel={modalConfirmDelete.closeModal}
                    />
                </Form>
            )}
        </Formik>
    )
}

function TaskList({
    tasks,
    handleOpen,
}: {
    tasks: ProcedureTypeTaskForm[]
    handleOpen: (task: ProcedureTypeTaskForm, index: number) => void
}) {
    if (!tasks) {
        return null
    }

    return (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {tasks.map((task, index) => (
                <li
                    key={index}
                    className="col-span-1 bg-white rounded-lg border border-gray-100 divide-y divide-gray-200 hover:shadow-lg cursor-pointer"
                    onClick={() => handleOpen(task, index)}
                >
                    <div className="w-full flex items-center justify-between p-4 space-x-4">
                        <div className="flex-1">
                            <div className="flex items-center space-x-3">
                                <h3 className="text-sm leading-5 font-medium">
                                    {task.name}
                                </h3>
                            </div>
                            <div className="mt-4">
                                <span className="inline-flex text-sm leading-5 text-blue-600 font-medium">
                                    <CalendarIcon className="w-5 h-5 mr-1" />{' '}
                                    {task.reminder} dni
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}
