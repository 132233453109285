import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { Pagination } from 'components/ui'
import EmptyList from 'components/EmptyList'

import type { AnesthesiologistCostsResponse } from 'api/types'
import type { ListFilterAction } from 'helpers'

export default function SettingsAnesthesiologistCostList({
    data,
    setFilters,
}: {
    data: AnesthesiologistCostsResponse
    setFilters: React.Dispatch<ListFilterAction>
}) {
    return (
        <div className="bg-white p-2 rounded-lg">
            {!!data.meta.total && (
                <div className="flex flex-col">
                    <div className="overflow-hidden border-b border-gray-200 sm:rounded-t-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Id
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Nazwa
                                    </th>
                                    <th
                                        scope="col"
                                        className="w-2/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase whitespace-nowrap tracking-wider"
                                    >
                                        Występowanie
                                    </th>
                                    <th
                                        scope="col"
                                        className="w-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Opcje
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 text-sm leading-5 text-gray-500">
                                {data?.data.map((item, index) => (
                                    <tr
                                        className={classNames({
                                            'bg-gray-50': index % 2,
                                        })}
                                        key={item.id}
                                    >
                                        <td className="px-6 py-6 whitespace-nowrap">
                                            <div className="">{item.id}</div>
                                        </td>
                                        <td className="px-6 py-6">
                                            <span className="text-gray-900 font-medium">
                                                {item.name}
                                            </span>
                                        </td>
                                        <td className="px-6 py-6">
                                            <div className="inline-flex items-center justify-between text-gray-500">
                                                <span className="truncate">
                                                    {item.procedure_categories
                                                        .filter(
                                                            (_, index) =>
                                                                index === 0
                                                        )
                                                        .map((i) => i.name)}
                                                </span>
                                                <div className="ml-2 flex-shrink-0 flex">
                                                    <span className="text-gray-500 hover:text-gray-700">
                                                        {item
                                                            .procedure_categories
                                                            .length > 1 &&
                                                            '+' +
                                                                (item
                                                                    .procedure_categories
                                                                    .length -
                                                                    1)}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-1 whitespace-nowrap text-right">
                                            <Link
                                                to={`/settings/price-list/anesthesiologist-costs/${item.id}`}
                                                className="inline-flex items-center px-3 py-2 shadow-sm text-xs leading-4 rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Edytuj
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {!!data.meta.total && (
                <Pagination
                    meta={data.meta}
                    handleChange={(page) =>
                        setFilters({ type: 'setPage', payload: page })
                    }
                />
            )}
            {!data.meta.total && (
                <EmptyList
                    headline="Nic tu jeszcze nie ma"
                    message="Zacznij od stworzenia pierwszego kosztu."
                    to="/settings/price-list/anesthesiologist-costs/add"
                />
            )}
        </div>
    )
}
